import React, {FC, useEffect, useRef, useState} from "react";
import "./Support.css";
import "../Privacy.css";
import {SupportNav} from "./SupportNav";
import {Support} from "./Support";
import {useUserContext} from "../../context/UserContext";

export const MyTickets: FC<any> = (props) => {
    const userData = useUserContext();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [reports,setReports] = useState<any>({
        reports: [],
        page:1,
        last_page: 0
    })
    const stateRef = useRef<any>();
    useEffect(() => {
        stateRef.current = reports;
    }, [reports]);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    {!isMobile ?  <SupportNav /> : ""}
                    <div className="fl-col-9 fl-support-col">
                        <Support page={"support"} reports={reports} setReports={setReports} stateRef={stateRef} location={"my-tickets"} />
                    </div>
                </div>
            </div>
        </div>
    );
};
