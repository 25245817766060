import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import {RouteHolder} from "../routes/RouteHolder";

export const UnregisteredRoute:FC<any> = (props) => {
    const userData = useUserContext();
    return (
        <div className={"fl-main-container"}>
            <React.Fragment>
                {userData.authStatus === 'AUTHENTICATED' ? <Redirect to="/feed"/> : ''}
                {userData.authStatus === 'UNAUTHENTICATED' ? <Route {...props}/> : ''}
                {userData.authStatus === 'CHECKING' ? <RouteHolder/> : ''}
            </React.Fragment>
        </div>
    )
}
