import React, { FC } from 'react';
import "./SubscriberCard.css";
import {Link, useHistory} from 'react-router-dom';

import { useUserContext } from '../context/UserContext';
import profileHolderImg from "../assets/images/profile.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareSquare} from "@fortawesome/free-solid-svg-icons";
import {useModalContext} from "../context/ModalContext";
import ReactTooltip from "react-tooltip";

export const SubscriberCard:FC<any> = ({creatorProfile, children, type, ...props}) => {

    const modalData = useModalContext()
    const history = useHistory()

    const { backgroundImage, handle,profileImage, name, id } = creatorProfile;

    const userData = useUserContext();

    const copyProfileUrl = async (e:any,id:number,handle:any) => {
        e.stopPropagation();
        modalData.clearToasts()
        //@ts-ignore
    if (navigator.share) {
        //@ts-ignore
        navigator.share({
          text: 'Checkout this profile!',
          url: `${window.location.origin}/${handle}`,
          title: 'Fluffa'
        }).then(() => {
          modalData.pushToast("success", "Thanks for sharing!");
        }).catch ((error:any)=> {
          if (error.name === "AbortError") {
            return;
          } else {
            modalData.pushToast("error", "Something went wrong!");
          }
        }) 
      } else {
          try {
              await navigator.clipboard.writeText(
                  `${window.location.origin}/${handle}`
              );
              modalData.pushToast("success", "Link copied to clipboard");
          } catch (e) {
              modalData.pushToast("error", "Something went wrong!");
          }
      }
    };

    const redirectProfile = (e:any) => {
        e.stopPropagation();
        history.push(`/${handle}`)
    }

    return (
        <div className={`fl-subscriber-card-main fl-subscriber-card-main-${userData.currentTheme}`}>
            <div className="fl-subscriber-card-cover" onClick={(e) => redirectProfile(e)}>
                {backgroundImage ? <img src={backgroundImage.url} alt={"fluffa"}/> : ''}
                <div className="fl-subscriber-card-profile"  onClick={(e) => redirectProfile(e)}>
                    {profileImage ? <img src={profileImage.url} alt={"fluffa"}/> : <img src={profileHolderImg} alt={"fluffa"} /> }
                </div>
                <div className={"fl-subscriber-copy-profile"} data-tip data-for={"profile-copy"}>
                    <FontAwesomeIcon icon={faShareSquare}  onClick={(e) => copyProfileUrl(e,id,handle)} />
                </div>
                <ReactTooltip id="profile-copy">
                    Share
                </ReactTooltip>
            </div>
            <div className="fl-subscriber-card-content">
                <div className="fl-subscriber-card-content-name">
                    <div>
                        <Link to={`/${handle}`} className={`fl-subscriber-card-content-name-full fl-subscriber-card-content-name-full-${userData.currentTheme}`} >{ name }</Link>
                    </div>
                    <Link to={`/${handle}`} className="fl-subscriber-card-content-name-username">@{ handle }</Link>
                </div>
                {children ? <div className={`fl-subscriber-card-content-actions fl-subscriber-card-content-actions-${userData.currentTheme}`}>
                    { children }
                </div> : ''}
            </div>
        </div>
    )
}