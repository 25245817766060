import axios from "axios";
import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button } from "../components/Button";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import "./SettingsPayment.css";
import moneyBillLight from "../assets/images/icons/moneyBillLight.svg"
import moneyBillDark from "../assets/images/icons/moneyBillLight.svg"
import MasterCard from "../assets/images/cards/mastercard.jpg";
import Visa from "../assets/images/cards/visa.jpg";
import Maestro from "../assets/images/cards/maestro.png";
import AmericanExpress from "../assets/images/cards/american-express.png";
import Discover from "../assets/images/cards/discover.jpg";
import DinersClub from "../assets/images/cards/diners-club.jpg";
import Jcb from "../assets/images/cards/jcb.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCheckCircle,
  faChevronCircleLeft,
  faDollarSign, faInfoCircle,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import { MInput } from "../components/MInput";
import { useModalContext } from "../context/ModalContext";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import cvvSource from "../assets/images/cards/cvv.png";
import cvvExpl from "../assets/images/cards/cvv-expl.png";

import padLock from "../assets/images/padlock.svg";
import validator from "validator";
import { BalanceModal } from "../components/BalanceModal";

import { SegpayModal } from "../routes/Segpay/SegpayModal";
import ReactLoading from "react-loading";

const allCards: any = {
  MasterCard: MasterCard,
  Visa: Visa,
  Maestro: Maestro,
  "American Express": AmericanExpress,
  Discover: Discover,
  "Diners Club": DinersClub,
  JCB: Jcb,
};

export const SettingsPayment: FC<any> = (props) => {
  const [state, setState] = useState({
    cards: [],
    wallet_balance: 0,
    currentPage: 1,
    last_page: 0,
    card: {
      name: "",
      number: "",
      cvc: "",
      exp_month: "",
      exp_year: "",
    },
    adding: false,
    transactions: [],
    id_card: 0,
    loading: false,
    walletAsPrimary: false,
    refillAmount: 10,
    amount: 20,
    refillEnabled: false,
  });

  const history = useHistory();

  const [customSize, setCustomSize] = useState(false);
  const [invalidCardNumber, setInvalidCardNumber] = useState(false);

  const stateRef = useRef<any>(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();

  const [animation, setAnimation] = useState(true);

  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const cvcRef = useRef<HTMLInputElement>(null);

  const getCards = async () => {
    try {
      const res = await axios.get(`${envData.apiUrl}/payments/cards`);
      const cards = res.data.data;
      setState({ ...stateRef.current, cards });
      setAnimation(false);
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
      setAnimation(false);
    }
  };

  const getWallet = async () => {
    try {
      const res = await axios.get(`${envData.apiUrl}/payments/wallet`);
      const { wallet_balance } = res.data.data;
      setState({ ...stateRef.current, wallet_balance });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const getTransactions = async (page: number) => {
    try {
      const res = await axios.get(
          `${envData.apiUrl}/transactions?page=${page}&order=created_at`
      );
      setState({
        ...stateRef.current,
        transactions: [...stateRef.current.transactions, ...res.data.data],
        last_page: res.data.meta.last_page,
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  useLayoutEffect(() => {
    (async () => {
      await getCards();
      await getWallet();
    })();
  }, []);

  useLayoutEffect(() => {
    (async () => {
      await getTransactions(stateRef.current.currentPage);
    })();
  }, [state.currentPage]);

  const formRef = useRef<HTMLFormElement>(null);

  const [animationAdd, setAnimationAdd] = useState(false);
  const [errorCard,setErrorCard] = useState(false)
  const [errorCvc,setErrorCvc] = useState(false)
  const addCard = async () => {
    setAnimationAdd(true);
    try {
      const { name, number, cvc, exp_month, exp_year } = stateRef.current.card;
      const formatedNumber = number.split(" ").join("");

      const cardRes = await axios.post(`${envData.apiUrl}/payments/cards/secure`, {
        name,
        number: formatedNumber,
        cvc,
        exp_month,
        exp_year,
      });
      if(cardRes.data.protected) {
        modalData.push(() => <SegpayModal url={cardRes.data.url} addCardFunction={addCardFunction} setAnimationAdd={setAnimationAdd} header={"Add card"} loadingHeader={"ADDING CARD..."}/>)
      } else {
        addCardFunction(true)
      }
    } catch (e){
      setAnimationAdd(false);
      if(e?.response?.data?.errors?.number) {
        setErrorCard(true)
      } else if (e?.response?.data?.errors?.cvc) {
        // setErrorCvc(true)
      } else if (e?.response.data?.errors?.charge) {
        await modalData.push({
          title: "ERROR",
          description: [`${e?.response.data?.errors.charge[0]}`],
          buttons: [
            () => (
                <div className="fl-d-flex fl-justify-flex-end">
                  <Button type="normal" onClick={() => modalData.closeAll()}>
                    Dismiss
                  </Button>
                </div>
            ),
          ],
        });
      }
    }
  };

  const disableRightClick = (e: any) => {
    e.preventDefault();
  };

  const addCardFunction = async (showLoading = false) => {
   if(showLoading) {
     modalData.push(() => {
       return (
           <>
             <div className="fl-modal-title">
               Add card
               <FontAwesomeIcon
                   icon={faTimes}
                   className="fl-tipmodal__close"
                   onClick={modalData.close}
               />
             </div>
             <div className="fl-modal-description">
               <div className="loader-payment"></div>
               <p className={"loader-text"}>ADDING CARD...</p>
             </div>
           </>
       )
     })
   }
    try {
      const { name, number, cvc, exp_month, exp_year } = stateRef.current.card;
      const formatedNumber = number.split(" ").join("");

      if (name.length > 0) {
        const cardRes = await axios.post(`${envData.apiUrl}/payments/cards`, {
          name,
          number: formatedNumber,
          cvc,
          exp_month,
          exp_year,
          is_default: state.cards.length === 0 ? true : false
        });
          // if (state.cards.length === 0) {
          //   const id_card = cardRes.data.data.id;
          //   await axios.put(`${envData.apiUrl}/payments/cards/${id_card}`);
          // }
          setState({
            ...stateRef.current,
            cards: [...stateRef.current.cards, cardRes.data.data],
            id_card:
                state.cards.length === 0
                    ? cardRes.data.data.id
                    : stateRef.current.id_card,
          });
          modalData.pushToast("success", "You've added a card");
          clearCard();
          modalData.close()
          userData.addPayment(true);
          setAnimationAdd(false);
      }
      setAnimationAdd(false);
    } catch (e){
      modalData.close()
      setAnimationAdd(false);
      if(e?.response?.data?.errors?.number) {
        setErrorCard(true)
      } else if (e?.response?.data?.errors?.cvc) {
        // setErrorCvc(true)
      } else if (e?.response.data?.errors?.charge) {
        await modalData.push({
          title: "ERROR",
          description: [`${e?.response.data?.errors.charge[0]}`],
          buttons: [
            () => (
                <div className="fl-d-flex fl-justify-flex-end">
                  <Button type="normal" onClick={() => modalData.closeAll()}>
                    Dismiss
                  </Button>
                </div>
            ),
          ],
        });
      }
    }
  };

  const clearCard = () => {
    setState({
      ...stateRef.current,
      adding: false,
      card: {
        name: "",
        number: "",
        cvc: "",
        exp_month: "",
        exp_year: "",
      },
    });
  };

  // const getFunds = async (amount: Number) => {
  //   try {
  //     const checkSecure = await axios.post(`${envData.apiUrl}/payments/security/check`, {
  //      amount
  //     });
  //     if(checkSecure.data.protected) {
  //       modalData.push(() => <SegpayModal url={checkSecure.data.url} addCardFunction={getFundsFunction} amount={Number(amount)} />)
  //     } else {
  //       getFundsFunction(amount)
  //     }
  //   } catch (e) {
  //     const description = e?.response?.data?.errors.user ? e?.response?.data?.errors.user : "Something went wrong!";
  //     await modalData.push({
  //       title: "ERROR",
  //       description: [`${description}`],
  //       buttons: [
  //         () => (
  //             <div className="fl-d-flex fl-justify-flex-end">
  //               <Button type="normal" onClick={() => modalData.closeAll()}>
  //                 Dismiss
  //               </Button>
  //             </div>
  //         ),
  //       ],
  //     });
  //   }
  // };

  const getFundsFunction = async (amount:number) => {
    try {
      const res = await axios.post(`${envData.apiUrl}/payments/wallet`, {
        amount,
      });
      modalData.pushToast(
          "success",
          "You 've added $" + amount + " to your wallet"
      );
      const { wallet_balance } = res.data.data;
      setState({ ...stateRef.current, wallet_balance });
      userData.updateWalletBalance();
      userData.updateBalance("walletOut",amount)
      modalData.close();
    } catch (e) {
      const description = e?.response?.data?.errors?.user ? "Limit on this credit card is reached, maximum 4 transactions or $200 per month" : e?.response?.data?.errors?.error ? e?.response?.data?.errors?.error :
          e?.response?.data?.errors.charge ? e?.response?.data?.errors.charge : e?.response?.data?.errors.amount ? e?.response?.data?.errors.amount : e?.response?.data?.errors.creditCard ;
      await modalData.push({
        title: "ERROR",
        description: [`${description}`],
        buttons: [
          () => (
              <div className="fl-d-flex fl-justify-flex-end">
                <Button type="normal" onClick={() => modalData.closeAll()}>
                  Dismiss
                </Button>
              </div>
          ),
        ],
      });
    }
  }

  const updatePrimaryCard = async (cardId: number) => {
    try {
      await axios.put(`${envData.apiUrl}/payments/cards/${cardId}`);

      let newCards = state.cards;

      let updatedCards = newCards.map((e: any) => {
        e.id === cardId ? (e.is_default = true) : (e.is_default = false);
        return e;
      });

      //@ts-ignore
      setState({ ...state, cards: updatedCards });

      modalData.pushToast("success", "Default Card updated");
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const removeCard = async (cardId: number) => {
    modalData.push(() => <DeleteModalCard cardId={cardId} setState={setState} state={state} stateRef={stateRef} />)
  };

  const addCardValid = () => {
    const { name, number, cvc, exp_month, exp_year } = state.card;
    const formatedNumber = number.split(" ").join("");

    if (name.length > 0 && !invalidCardNumber) {
      if (Number(exp_month) > 12 || exp_month.length > 2) {
        return false;
      } else if (
          exp_year.length > 2 ||
          exp_year < moment(new Date()).format("YY") ||
          Number(exp_year) > 99
      ) {
        return false;
      } else if (state.card.cvc.length !== 3) {
        return false;
      } else if (
          [formatedNumber, cvc, exp_month, exp_year].filter(
              (x) => x.trim() === "" || !/^\d+$/.test(x.trim())
          ).length
      ) {
        return false;
      } else if (userData.user?.email_verified_at === "") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const requestMoneyModal = async (amount: number) => {
    await modalData.push(() => (
        <BalanceModal
            wallet
            funds
            amount={amount}
            header={"Add funds"}
            buttonText={`Add $${amount}`}
            description={
              <>
                <p className="fl-text-with-bold">
                  Current balance: <span>${state.wallet_balance.toFixed(2)}</span>
                </p>{" "}
                Please confirm you want to add ${amount} to your wallet balance.
              </>
            }
            action={getFundsFunction}
        />
    ));
  };

  const addCardRef = useRef<HTMLDivElement>(null);

  const formatCardNumber = (num: string) => {
    if (!customSize) {
      if (num.length <= 16) {
        let spl = num.split("");
        [4, 9, 14].forEach(
            (i) => spl[i] && spl[i] !== " " && spl.splice(i, 0, " ")
        );
        return spl.join("");
      } else if (num.length > 16) {
        return num.slice(0, 19);
      }
    } else {
      let spl = num.split("");
      [4, 9, 14, 19].forEach(
          (i) => spl[i] && spl[i] !== " " && spl.splice(i, 0, " ")
      );
      return spl.join("").slice(0, 23);
    }
  };

  const showCreditCard = (data: any) => {
    modalData.push(() => <CreditCardInfo data={data} />);
  };

  // const updateCardNumberValidation = (val: boolean) =>
  //     setInvalidCardNumber(val);

  const redirectProfile = (e: any) => {
    history.push(`/settings`);
  };

  const checkTypeAction = (transaction: any) => {
    if (transaction.product?.subscription) {
      return (
          <>
            Subscription to{" "}
            <Link
                className="fl-transaction-link"
                to={`/${transaction.creatorProfile.handle}`}
            >
              @{transaction.creatorProfile.handle}
            </Link> {transaction.transactionable_type === "App\\Models\\SecurionTransaction" ? `from card ending in ${transaction.last4}` : transaction.transactionable_type === "App\\Models\\WalletTransaction" ? "from wallet balance" : ""}
          </>
      );
    } else if (
        transaction.product?.tip_message === null ||
        transaction.product?.tip_message?.length > 0
    ) {
      return (
          <>
            Tip to{" "}
            <Link
                className="fl-transaction-link"
                to={`/${transaction.creatorProfile.handle}`}
            >
              @{transaction.creatorProfile.handle}
            </Link> {transaction.transactionable_type === "App\\Models\\SecurionTransaction" ? `from card ending in ${transaction.last4}` : transaction.transactionable_type === "App\\Models\\WalletTransaction" ? "from wallet balance" : ""}
          </>
      );
    } else if (transaction.product?.post) {
      return (
          <>
            Post unlocked from{" "}
            <Link
                className="fl-transaction-link"
                to={`/${transaction.creatorProfile.handle}`}
            >
              @{transaction.creatorProfile.handle}
            </Link>
          </>
      );
    } else if (transaction.product?.chatMessage) {
      return (
          <>
            Post unlocked from{" "}
            <Link
                className="fl-transaction-link"
                to={`/${transaction.creatorProfile.handle}`}
            >
              @{transaction.creatorProfile.handle}
            </Link>
          </>
      );
    } else  if (transaction?.type === "subscription_renewal") {
      return (
          <>
            Subscription renewal to{" "}
            <Link
                className="fl-transaction-link"
                to={`/${transaction.creatorProfile.handle}`}
            >
              @{transaction.creatorProfile.handle}
            </Link> {transaction.transactionable_type === "App\\Models\\SecurionTransaction" ? `from card ending in ${transaction.last4}` : transaction.transactionable_type === "App\\Models\\WalletTransaction" ? "from wallet balance" : ""}
          </>
      );
    } else {
      return <>Wallet top up from card ending {transaction.last4}</>;
    }
  };

  const [activeMonth,setActiveMonth] = useState(false)
  const [activeYear,setActiveYear] = useState(false)
  const [activeCard,setActiveCard] = useState(false)

  const setActiveInput = (type:string) => {
    if(type === "card") {
      setActiveCard(true)
      setActiveYear(false)
      setActiveMonth(false)
    } else if(type === "month") {
      setActiveMonth(true)
      setActiveYear(false)
      setActiveCard(false)
    } else if(type === "year") {
      setActiveYear(true)
      setActiveMonth(false)
      setActiveCard(false)
    }
  }

  useEffect(() => {
      if(state.card.number.length === 19 && !customSize && activeCard) {
        monthRef?.current?.focus()
      }
      if (state.card.exp_month.length === 2 && activeMonth) {
        yearRef?.current?.focus()
      }
      if (state.card.exp_year.length === 2 && activeYear) {
        cvcRef?.current?.focus()
      }
  },[state.card.exp_month,state.card.exp_year,state.card.number])

  const scrollCard = () => {
    if(addCardRef) {
      addCardRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }

  const focusCard = () => {
    if(errorCard) {
      setErrorCard(false)
    }
    setActiveInput("card")
  }

  return (
      <Fragment>
        {animation && (
            <div className="fl-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
        )}
        <div
            className={`fl-settings-sub-stretch ${
                animation && "fl-settings-sub-stretch-blur"
            }`}
        >
          <div className="fl-settings-sub-section">
            <div
                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
            >
            <span
                className="fl-direct-messages-title-icon"
                onClick={(e) => redirectProfile(e)}
            >
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </span>
              Wallet
            </div>
            <div className="fl-settings-sub-payment-balance fl-settings-mt-30">
              <div
                  className={`fl-settings-sub-payment-balance-single fl-settings-sub-payment-balance-single-${userData.currentTheme}`}
              >
                <div
                    className={`fl-settings-sub-payment-balance-single-bg fl-settings-sub-payment-balance-single-bg-${userData.currentTheme}`}
                >
                  <FontAwesomeIcon icon={faChartLine} />
                </div>
                <div className="fl-settings-sub-payment-balance-single-content">
                  <div
                      className={`fl-settings-sub-payment-balance-single-label fl-settings-sub-payment-balance-single-label-${userData.currentTheme} `}
                  >
                    Available Balance
                  </div>
                  <div className="fl-settings-sub-payment-balance-single-value">
                    <NumberFormat
                        value={state.wallet_balance}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                    />
                  </div>
                </div>
              </div>
              <div
                  className={`fl-settings-sub-payment-balance-single fl-settings-sub-payment-balance-single-${userData.currentTheme}`}
              >
                <div
                    className={`fl-settings-sub-payment-balance-single-bg fl-settings-sub-payment-balance-single-bg-${userData.currentTheme}`}
                >
                  <FontAwesomeIcon icon={faDollarSign} />
                </div>
                <div className="fl-settings-sub-payment-balance-single-content">
                  <div
                      className={`fl-settings-sub-payment-balance-single-label fl-settings-sub-payment-balance-single-label-${userData.currentTheme}`}
                  >
                    Currency
                  </div>
                  <div className="fl-settings-sub-payment-balance-single-value">
                    USD
                  </div>
                </div>
              </div>
              <div
                  className={`fl-settings-sub-payment-balance-single fl-settings-sub-payment-balance-single-${userData.currentTheme}`}
              >
                <div
                    className={`fl-settings-sub-payment-balance-single-bg fl-settings-sub-payment-balance-single-bg-${userData.currentTheme}`}
                >
                  {/*<FontAwesomeIcon icon={faUndoAlt} />*/}
                  <img src={userData.currentTheme === "light" ? moneyBillLight : moneyBillDark} alt={"fluffa"} />
                </div>
                <div className="fl-settings-sub-payment-balance-single-content">
                  <div
                      className={`fl-settings-sub-payment-balance-single-label fl-settings-sub-payment-balance-single-label-${userData.currentTheme}`}
                  >
                    Top Up Your Wallet Balance
                  </div>
                  <div className="fl-settings-sub-payment-balance-single-value">
                    {state.cards.length ? (
                        <div className="fl-settings-sub-payment-balance-funds-add-all">
                          <div
                              className="fl-settings-sub-payment-balance-funds-add"
                              onClick={() => requestMoneyModal(10)}
                          >
                            $10
                          </div>
                          <div
                              className="fl-settings-sub-payment-balance-funds-add"
                              onClick={() => requestMoneyModal(20)}
                          >
                            $20
                          </div>
                          <div
                              className="fl-settings-sub-payment-balance-funds-add"
                              onClick={() => requestMoneyModal(50)}
                          >
                            $50
                          </div>
                          <div
                              className="fl-settings-sub-payment-balance-funds-add"
                              onClick={() => requestMoneyModal(100)}
                          >
                            $100
                          </div>
                        </div>
                    ) : (
                        <div className="fl-settings-sub-payment-balance-funds-add-all">
                          <div
                            className="fl-settings-sub-payment-balance-funds-add"
                            onClick={scrollCard}
                          >
                            PLEASE ADD A CARD
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*<div>*/}
            {/*  <div className={`fl-settings-sub-payment-transactions-${userData.currentTheme}`}>*/}
            {/*    Make wallet primary method for rebills*/}
            {/*      <Toggle leftComponent={<FontAwesomeIcon icon={faCheck} />} rightComponent={<FontAwesomeIcon icon={faTimes}/>} value={state.walletAsPrimary} onClick={() => {*/}
            {/*        setState({...state, walletAsPrimary: !state.walletAsPrimary});*/}
            {/*      }} />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          {/*<div className="fl-settings-sub-section">*/}
          {/*  <div*/}
          {/*      className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}*/}
          {/*  >*/}
          {/*    Automatically recharge your wallet*/}
          {/*    <div className={"fl-sub-section-header-options"}>*/}
          {/*      <Toggle*/}
          {/*          leftComponent={<FontAwesomeIcon icon={faCheck} />}*/}
          {/*          rightComponent={<FontAwesomeIcon icon={faTimes} />}*/}
          {/*          value={state.refillEnabled}*/}
          {/*          onClick={() => {*/}
          {/*            setState({ ...state, refillEnabled: !state.refillEnabled });*/}
          {/*          }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  {state.refillEnabled && (*/}
          {/*      <div*/}
          {/*          className={`fl-settings-sub-payment-transactions-${userData.currentTheme} fl-settings-sub-payment-display fl-settings-mt-30`}*/}
          {/*      >*/}
          {/*        <div className="fl-settings-sub-payment-display">*/}
          {/*          <p>If my balance falls below</p>*/}
          {/*          <MInput*/}
          {/*              type="select"*/}
          {/*              className={"fl-auto-refill-input"}*/}
          {/*              label="Amount"*/}
          {/*              value={state.amount}*/}
          {/*              setValue={(amount) => {*/}
          {/*                setState({ ...state, amount });*/}
          {/*              }}*/}
          {/*              options={[*/}
          {/*                {*/}
          {/*                  options: [*/}
          {/*                    {*/}
          {/*                      label: "$5",*/}
          {/*                      value: 5,*/}
          {/*                    },*/}
          {/*                    {*/}
          {/*                      label: "$10",*/}
          {/*                      value: 10,*/}
          {/*                    },*/}
          {/*                    {*/}
          {/*                      label: "$20",*/}
          {/*                      value: 20,*/}
          {/*                    },*/}
          {/*                    {*/}
          {/*                      label: "$50",*/}
          {/*                      value: 50,*/}
          {/*                    },*/}
          {/*                  ],*/}
          {/*                },*/}
          {/*              ]}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*        <div className="fl-settings-sub-payment-display">*/}
          {/*          <p>recharge it by:</p>*/}
          {/*          <MInput*/}
          {/*              className={"fl-auto-refill-input"}*/}
          {/*              type="select"*/}
          {/*              label="Recharge amount"*/}
          {/*              value={state.refillAmount}*/}
          {/*              setValue={(refillAmount) => {*/}
          {/*                setState({ ...state, refillAmount });*/}
          {/*              }}*/}
          {/*              options={[*/}
          {/*                {*/}
          {/*                  options: [*/}
          {/*                    {*/}
          {/*                      label: "$5",*/}
          {/*                      value: 5,*/}
          {/*                    },*/}
          {/*                    {*/}
          {/*                      label: "$10",*/}
          {/*                      value: 10,*/}
          {/*                    },*/}
          {/*                    {*/}
          {/*                      label: "$20",*/}
          {/*                      value: 20,*/}
          {/*                    },*/}
          {/*                    {*/}
          {/*                      label: "$50",*/}
          {/*                      value: 50,*/}
          {/*                    },*/}
          {/*                  ],*/}
          {/*                },*/}
          {/*              ]}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*  )}*/}
          {/*</div>*/}

          <div className="fl-settings-sub-section" ref={addCardRef}>
            <div
                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
            >
              {state.cards.length === 0 ? (
                  <div style={{ color: "#ff4444" }}>
                    No cards added - please add a card
                  </div>
              ) : (
                  <div>Payment Methods</div>
              )}
            </div>
            <div className="fl-settings-sub-section">
              <div className="fl-settings-sub-payment-secured">
                <img src={padLock} alt={"fluffa"}  /> Your card details are secure
              </div>
            </div>
            <div className="fl-settings-sub-section">
              <div
                  className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme}`}
              >
                {state.cards.length > 0 ? (
                    <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-card-head fl-settings-sub-payment-transactions-single-head">
                      <div className="fl-settings-sub-payment-card-single-image"></div>
                      <div className="fl-settings-sub-payment-card-single-number">
                        Card number
                      </div>
                      <div className="fl-settings-sub-payment-card-single-brand">
                        Card type
                      </div>
                      <div className="fl-settings-sub-payment-card-single-name">
                        Cardholder Name
                      </div>
                      <div className="fl-settings-sub-payment-card-single-expire">
                        Expiry date
                      </div>
                      <div className="fl-settings-sub-payment-card-single-default"></div>
                      <div className="fl-settings-sub-payment-card-single-delete"></div>
                    </div>
                ) : (
                    ""
                )}
                {state.cards.map((x: any, y: number) => (
                    <div
                        key={y}
                        className={`fl-settings-sub-payment-single-card fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                    >
                      <div
                          className="fl-settings-card-single-image"
                          onClick={() => {
                            showCreditCard(x);
                          }}
                      >
                        <img
                            className="fl-settings-sub-payment-card-image-img"
                            src={allCards[x.brand]}
                            alt={"fluffa"}
                        />
                      </div>
                      <div className="fl-settings-card-single-number">
                    <span>
                      {x.first6}*****{x.last4}
                    </span>
                      </div>
                      <div className="fl-settings-card-single-brand">
                        <span>{x.brand}</span>
                      </div>
                      <div className="fl-settings-card-single-name">
                        <span>{x.name}</span>
                      </div>
                      <div className="fl-settings-card-single-expire">
                    <span>
                      {x.exp_month}/{x.exp_year}
                    </span>
                      </div>
                      <div className="fl-settings-card-single-default">
                        {x.is_default ? (
                            <div className={"fl-settings-sub-payment-default-card"}>
                              <span>Default Card</span>{" "}
                              <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                            </div>
                        ) : (
                            <span
                                onClick={() => updatePrimaryCard(x.id)}
                                className="fl-settings-sub-payment-card-newdefault"
                            >
                        Set as default
                      </span>
                        )}
                      </div>
                      <div className="fl-settings-card-single-remove">
                    <span
                        className="fl-settings-sub-payment-card-remove-icon"
                        onClick={() => removeCard(x.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                      </div>
                    </div>
                ))}
                {state.adding ? (
                    <div className="fl-settings-sub-payment-card-add">
                      <div
                          className={`fl-settings-sub-payment-card-add-inner fl-settings-sub-payment-card-add-inner-${userData.currentTheme}`}
                      >
                        <form
                            className="fl-settings-sub-payment-card-add-form"
                            ref={formRef}
                        >
                          <MInput
                              className={"fl-settings-mt-30"}
                              label="Cardholder name"
                              validator={[
                                {
                                  check: (v) => !validator.isEmpty(v),
                                  message: "Cardholder name is a required field",
                                },
                              ]}
                              value={state.card.name}
                              setValue={(name) => {
                                setState({
                                  ...stateRef.current,
                                  card: {
                                    ...stateRef.current.card,
                                    name: name,
                                  },
                                });
                              }}
                              type="text"
                              regex={"word"}
                          />
                          <MInput
                              className={`fl-settings-mt-30 ${errorCard ? "fl-minput-main-not-valid" : ""}`}
                              label="Card number"
                              onFocus={() => focusCard()}
                              validator={[
                                {
                                  check: (v) => !validator.isEmpty(v),
                                  message: "Card number is required",
                                },
                                {
                                  check: (v) => /^[\d\s]+$/.test(v),
                                  message: "Card number can contain only digits",
                                },
                                // {
                                //   check: (v) => {
                                //     if (customSize) {
                                //       v.length < 16
                                //           ? updateCardNumberValidation(true)
                                //           : updateCardNumberValidation(false);
                                //       return v.length >= 16;
                                //     } else {
                                //       v.length < 14
                                //           ? updateCardNumberValidation(true)
                                //           : updateCardNumberValidation(false);
                                //       return v.length >= 14;
                                //     }
                                //   },
                                //   message: "Invalid card number",
                                // },
                              ]}
                              value={formatCardNumber(state.card.number)}
                              setValue={(number) => {
                                setState({
                                  ...stateRef.current,
                                  card: {
                                    ...stateRef.current.card,
                                    number: number,
                                  },
                                });
                              }}
                              type="text"
                              size="20"
                              regex={"number"}
                          />
                          {errorCard  && (
                              <span className="fl-modal-description-row-error">
                                 <FontAwesomeIcon icon={faInfoCircle} />{" "} Invalid card number
                           </span>
                          )}
                          <div className={`fl-settings-sub-payment-card-add-form-row ${errorCard ? "fl-settings-mt-10" : ""}`}>
                        <span
                            className="fl-settings-sub-payment-customcard-text"
                            onClick={() => setCustomSize(!customSize)}
                            style={{ color: customSize ? "#ff0d72" : "" }}
                        >
                          {!customSize
                              ? "My card number is longer than 16 digits"
                              : "You can now enter a longer card number"}
                        </span>
                          </div>
                          <div className="fl-settings-sub-payment-card-add-form-row">
                            <MInput
                                className="fl-settings-sub-payment-card-add-form-row-item fl-settings-mt-30"
                                label="MM"
                                onFocus={() => setActiveInput("month")}
                                refInput={monthRef}
                                value={state.card.exp_month}
                                setValue={(exp_month) =>
                                    setState({
                                      ...stateRef.current,
                                      card: {
                                        ...stateRef.current.card,
                                        exp_month:
                                            exp_month.length > 2
                                                ? exp_month.substr(0, 2)
                                                : /[0-9]/.test(
                                                    exp_month[exp_month.length - 1]
                                                )
                                                    ? exp_month.substr(0, 2)
                                                    : exp_month.slice(0, -1),
                                      },
                                    })
                                }
                                validator={[
                                  {
                                    check: (v) => !validator.isEmpty(v),
                                    message: "Month is required",
                                  },
                                  {
                                    check: (v) => v <= 12 && v > 0,
                                    message: "Invalid month",
                                  },
                                ]}
                                type="text"
                                size="2"
                            />
                            <MInput
                                className="fl-settings-sub-payment-card-add-form-row-item fl-settings-mt-30"
                                label="YY"
                                onFocus={() => setActiveInput("year")}
                                refInput={yearRef}
                                value={state.card.exp_year}
                                setValue={(exp_year) =>
                                    setState({
                                      ...stateRef.current,
                                      card: {
                                        ...stateRef.current.card,
                                        exp_year:
                                            exp_year.length > 2
                                                ? exp_year.substr(0, 2)
                                                : /[0-9]/.test(
                                                    exp_year[exp_year.length - 1]
                                                )
                                                    ? exp_year.substr(0, 2)
                                                    : exp_year.slice(0, -1),
                                      },
                                    })
                                }
                                validator={[
                                  {
                                    check: (v) => !validator.isEmpty(v),
                                    message: "Year is required",
                                  },
                                  {
                                    check: (v) =>
                                        v <= 99 && v >= moment(new Date()).format("YY"),
                                    message: "Invalid year",
                                  },
                                ]}
                                type="text"
                                size="4"
                            />
                            <MInput
                                className="fl-settings-sub-payment-card-add-form-row-item fl-settings-mt-30"
                                label="CVC"
                                refInput={cvcRef}
                                onFocus={() => errorCard ? setErrorCvc(false) : ""}
                                value={state.card.cvc}
                                setValue={(cvc) =>
                                    setState({
                                      ...stateRef.current,
                                      card: {
                                        ...stateRef.current.card,
                                        cvc:
                                            cvc.length > 3
                                                ? cvc.substr(0, 3)
                                                : /[0-9]/.test(cvc[cvc.length - 1])
                                                    ? cvc.substr(0, 3)
                                                    : cvc.slice(0, -1),
                                      },
                                    })
                                }
                                validator={[
                                  {
                                    check: (v) => !validator.isEmpty(v),
                                    message: "CVC required",
                                  },
                                  {
                                    check: (v) => v <= 9999,
                                    message: "Invalid CVC",
                                  },
                                ]}
                                type="text"
                                size="3"
                            />
                            {errorCvc  && (
                                <span className="fl-modal-description-row-error">
                                 <FontAwesomeIcon icon={faInfoCircle} />{" "} Invalid CVC number
                           </span>
                            )}
                            <div style={{ position: "relative" }}>
                              <img
                                  className="fl-settings-sub-payment-card-add-form-row-img"
                                  src={cvvSource}
                                  onContextMenu={(e) => disableRightClick(e)}
                                  alt={"fluffa"}
                              />
                              <div className="fl-settings-sub-payment-card-add-form-row-img-popup">
                                <p>
                                  The CVC is the last three digits of the number
                                  that appears on the back of your card in the
                                  signature bar.
                                </p>
                                <div
                                    style={{
                                      backgroundImage: `url(${cvvExpl})`,
                                    }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="fl-settings-sub-payment-accept-text">Fluffa will make a one-time charge of $1.00 when adding your payment card.</div>
                          <div className="fl-settings-sub-payment-accept-text">
                            By continuing, you agree to Fluffa's{" "}
                            <Link
                                className="fl-settings-sub-payment-accept-text-link"
                                to="/terms"
                            >
                              Terms of Service
                            </Link>
                            . The{" "}
                            <Link
                                className="fl-settings-sub-payment-accept-text-link"
                                to="/privacy"
                            >
                              Privacy Notice
                            </Link>{" "}
                            describes how your data is handled.
                          </div>
                        </form>
                        <div className="fl-settings-sub-payment-card-buttons">
                          <Button
                              className="fl-settings-sub-security-button"
                              onClick={clearCard}
                          >
                            Cancel
                          </Button>
                          <Button
                              className="fl-settings-sub-security-button"
                              type="normal"
                              onClick={addCard}
                              disabled={!addCardValid() || animationAdd || errorCard}
                          >
                            {animationAdd ? "Adding" : "Add"} card
                            {animationAdd && (
                                <ReactLoading
                                    type={"spinningBubbles"}
                                    color={"#fff"}
                                    height={18}
                                    width={18}
                                    className="fl-spinningBubbles"
                                />
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                ) : (
                    <div
                        className={`fl-settings-sub-payment-card fl-settings-sub-payment-add-card  fl-settings-sub-payment-card-${userData.currentTheme}`}
                    >
                      <div
                          className="fl-settings-sub-payment-card-append"
                          onClick={() =>
                              setState({ ...stateRef.current, adding: true })
                          }
                      >
                        + ADD PAYMENT METHOD
                      </div>
                    </div>
                )}
              </div>
              <p className={`fl-settings-sub-section-p-${userData.currentTheme}`}>Note: Charges on your statement will show as "NNL UK".</p>
            </div>
          </div>
          {/*<div className="fl-settings-sub-section">
            <div
                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
            >
              Transactions
            </div>
            <div
                className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme}`}
            >
              <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-head">
                <div className="fl-settings-sub-payment-transactions-single-date">
                  Date
                </div>
                <div className="fl-settings-sub-payment-transactions-single-amount">
                  Amount
                </div>
              </div>
              {state.transactions.map((x: any, y: number) => (
                  <div
                      key={y}
                      className={`fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-action fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                  >
                    <div className="fl-settings-sub-payment-transactions-single-date">
                      <p>
                        {moment(x.created_at).format("D MMM YYYY")},{" "}
                        {moment(x.created_at).format("HH:mm")}
                      </p>
                      <div
                          className={`fl-transaction-space-${userData.currentTheme}`}
                      ></div>
                      <p>{checkTypeAction(x)}</p>
                    </div>
                    <div className="fl-settings-sub-payment-transactions-single-amount">
                      {x.amount > 0 ? "-" : x.amount === 0 ? "" : "+"}$
                      {x.amount.toFixed(2)}
                    </div>
                  </div>
              ))}
              <div
                  className="fl-settings-sub-payment-transactions-more"
                  onClick={() =>
                      stateRef.current.currentPage >= stateRef.current.last_page
                          ? null
                          : setState({
                            ...stateRef.current,
                            currentPage: stateRef.current.currentPage + 1,
                          })
                  }
              >
                {state.currentPage >= state.last_page
                    ? ""
                    : "View more transactions"}
              </div>
            </div>
          </div>*/}
          <div>
            <div className={`fl-settings-sub-payment-allowed-cards fl-settings-sub-payment-allowed-cards-${userData.currentTheme}`}>
              {Object.values(allCards).map((x: any, y: number) => (
                  <div className="fl-settings-sub-payment-allowed-card" key={y}>
                    <img src={x} alt={"fluffa"}  />
                  </div>
              ))}
            </div>
            <div className="fl-settings-sub-payment-company">
              NebNine LTD
              <br />
              United Kingdom
            </div>
            {/* <div className="fl-settings-sub-payment-terms">Please check our terms by clicking <Link to="/terms" className="fl-settings-sub-payment-terms-link">here</Link></div> */}
          </div>
        </div>
      </Fragment>
  );
};

const CreditCardInfo: FC<{ data: any }> = ({ data }) => {
  const modalData = useModalContext();

  const keyboardEvent = (e: any) => {
    if (e.key === "Escape") {
      modalData.close();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyboardEvent);
    return () => {
      document.removeEventListener("keydown", keyboardEvent);
    };
  }, []);

  return (
      <Fragment>
        <div
            className="fl-modal-description fl-settings-sub-payment-card-popup"
            style={{ backgroundImage: `url(${allCards[data.brand]})` }}
        >
          <div
              className="fl-modal-close-button"
              onClick={() => modalData.close()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <p className="fl-settings-sub-payment-card-popup-brand">{data.brand}</p>
          <p className="fl-settings-sub-payment-card-popup-digits">
            {data.first6}*****{data.last4}
          </p>
          <p className="fl-settings-sub-payment-card-popup-exp">
            {data.exp_month}/{data.exp_year}
          </p>
        </div>
      </Fragment>
  );
};

const DeleteModalCard: FC<any> = ({ ...props }) => {

  const modalData = useModalContext()
  const userData = useUserContext()
  const envData = useEnvContext()
  const [animationDelete, setAnimationDelete] = useState<boolean>(false)

  const processCardRemove = async (cardId: number) => {
    setAnimationDelete(true)
    modalData.clearToasts();
    try {
      await axios.delete(`${envData.apiUrl}/payments/cards/${cardId}`);
      if (props.stateRef.current.cards.length > 1) {
        if (
            props.state.cards.find((e: any) => e.is_default === true && e.id !== cardId)
        ) {
          props.setState({
            ...props.stateRef.current,
            cards: props.stateRef.current.cards.filter((x: any) => x.id !== cardId),
          });
          modalData.pushToast("success", "Card removed");
        } else {
          let newCards = props.stateRef.current.cards.filter(
              (x: any) => x.id !== cardId
          );
          if (cardId !== props.stateRef.current.cards[0].id) {
            await axios.put(
                `${envData.apiUrl}/payments/cards/${props.stateRef.current.cards[0].id}`
            );
            newCards[0].is_default = true;
          } else {
            await axios.put(
                `${envData.apiUrl}/payments/cards/${props.stateRef.current.cards[1].id}`
            );
            newCards[0].is_default = true;
          }
          props.setState({ ...props.stateRef.current, cards: newCards });
          modalData.pushToast(
              "success",
              "Default Card removed. New Default Card set"
          );
        }
      } else {
        props.setState({ ...props.stateRef.current, cards: [] });
        modalData.pushToast("success", "You've removed a card");
      }
      await modalData.close();
      if (props.state.cards.length === 1) {
        userData.addPayment(false);
      }
      setAnimationDelete(false)
    } catch (e) {
      setAnimationDelete(false)
      await modalData.close();
      modalData.pushToast("error", "Something went wrong!");
    }
  };


  return (
      <Fragment >
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">Remove card</div>
        </div>
        <div className="fl-modal-description">
          <div className="fl-modal-description-row">Are you sure you want to remove this card?</div>
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={() => modalData.close()}>Cancel</Button>
          <Button
              type="normal"
              onClick={() =>processCardRemove(props.cardId)}
              disabled={animationDelete}
          >
            Remov{animationDelete ? "ing" : "e"} card
            {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </Fragment>
  )}
