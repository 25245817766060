import React, { FC, useEffect, useState } from "react";
import "./SingleNotification.css";

import { useEnvContext } from "../../context/EnvContext";
import { useUserContext } from "../../context/UserContext";

import Dropdown from "./Dropdown";
import Type from "./Type";
import closeTickets from "../../assets/images/icons/closedTickets.svg"
import subscriberIcons from "../../assets/images/icons/subscriberIcons.svg"
import giftIcons from "../../assets/images/icons/giftIcons.svg"
import followerIcons from "../../assets/images/icons/followerIcons.svg"
import {
    faDollarSign,
    faQuestion,
    faThumbsUp,
    faComment,
    faTimes,
    faTimesCircle,
    faUnlockAlt, faCheckCircle, faExclamationTriangle, faUserTag, faStar, faGift, faCircle, faMoneyBill, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import {useModalContext} from "../../context/ModalContext";
import Axios from "axios";
import moment from "moment";
import {Button} from "../Button";
import ReactLoading from "react-loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SingleNotification: FC<any> = ({
                                       readNotification,
                                       type,
                                       data,
                                       read_at,
                                       id,
                                       post,
                                       user,
                                       created_at,
                                       read_before,
                                       subscription
                                     }) => {

  const userData = useUserContext();
  const modalData = useModalContext()

  const unsubscribeModal= async (plan:any,handle:any) => {
      await modalData.push(() => (
          <ProcessUnsubscribe data={plan} handle={handle} />
      ));
  }

  const notificationBody = () => {
    switch (type) {
      case "App\\Notifications\\UserTipCreated":
        return (
            <Type
                iconClass="fl-singlenotification__icon--tip"
                icon={faDollarSign}
                title="Tip Received"
                description={`You received $${data.tip.amount} from `}
                link={`/${data.tip.userProfile.handle}`}
                linkText={`${data.tip.userProfile.handle}.`}
                created_at={created_at}
            />
        );
        case "App\\Notifications\\NewSubscriber":
            return (
                <Type
                    iconClass="fl-singlenotification__icon--like"
                    iconSrc={data?.billing_method === 'free' ? followerIcons : data?.price === 0 ? giftIcons : subscriberIcons}
                    title={data?.billing_method === 'free' ? 'New Follower' : data?.price === 0 ? "NEW SUBSCRIBER via FREE TRIAL" : 'New Subscriber'}
                    descRight={true}
                    description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> {data?.billing_method === 'free' ? "is now following you." : data?.price === 0 ? `claimed your ${subscription?.subscriptionPlan.billing_period_count} day free trial.` : `subscribed to you for $${data?.price}.`}</p>}
                    created_at={created_at}
                />
            );
        case "App\\Notifications\\RebillSuccessfull":
            return (
                <Type
                    iconClass="fl-singlenotification__icon--like"
                    icon={faMoneyBill}
                    title="Rebill Successful"
                    descRight={true}
                    description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> has renewed their subscription.</p>}
                    created_at={created_at}
                />
            );
      case "App\\Notifications\\NewPostLike":
        return (
            <Type
                iconClass="fl-singlenotification__icon--like"
                icon={faThumbsUp}
                title="Post Liked"
                descRight={true}
                description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> liked your <Link className={"single-notification-link"} to={`/post/${data?.post?.id}`}>Post</Link>.</p>}
                created_at={created_at}
            />
        );

        case "App\\Notifications\\Chat\\DeletedReportedMessageImportant":
          return (
              <Type
                  iconClass="fl-singlenotification__icon--comment"
                  icon={faExclamationTriangle}
                  title="Message Removed"
                  descRight={true}
                  description={<p>We removed a message you sent for violating our terms of service.</p>}
                  created_at={created_at}
              />
          );
  

      case "App\\Notifications\\NewPostComment":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faComment}
                title="New Comment"
                descRight={true}
                description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> commented "{data.text}" on your <Link className={"single-notification-link"} to={`/post/${data?.post_id}/${data?.post_comment_id}`}>Post</Link>.</p>}
                created_at={created_at}
            />
        );

      case "App\\Notifications\\ContentUnlockedChat":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Unlocked Chat"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\CreatorUploadedContent":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Creator Uploaded Content"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\DeclinedUserVerificationEmail":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faTimesCircle}
                title={`Your account ${data?.previously_verified ? "is no longer verified" : "has been rejected"}`}
                link={"/settings/verification"}
                linkText={"Click here for more info"}
                created_at={created_at}
            />
        );

      case "App\\Notifications\\DeletedAccount":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Account Deleted"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\EmailChangeConfirmed":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Email Changed"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\EmailChangeNotification":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Email Changed"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\FluffaNotification":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Fluffa Notification"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\ManualRequestPayout":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Payout Request"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\NewPost":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="New Post"
                description="???"
                created_at={created_at}
            />
        );

      case "App\\Notifications\\NewPostCommentLike":
        return (
            <Type
                iconClass="fl-singlenotification__icon--like"
                icon={faThumbsUp}
                title="Comment Liked"
                descRight={true}
                description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> liked your <Link className={"single-notification-link"} to={`/post/${data?.post_id}`}>comment</Link>.</p>}
                created_at={created_at}
            />
        );

      case "App\\Notifications\\NewPostFavorite":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faStar}
                title="Post Favorited"
                description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> favorited your <Link className={"single-notification-link"} to={`/post/${post?.id}`}>Post</Link>.</p>}
                created_at={created_at}
            />
        );

      case "App\\Notifications\\NewUserProfileFavorite":
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faStar}
                title="Added to Favorites"
                description={<p><Link className={"single-notification-link"} to={`/${data.user_handle}`}>{data.user_handle}</Link> favorited your profile.</p>}
                created_at={created_at}
            />
        );

      case "App\\Notifications\\PayoutSentToCreator": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Payout Sent"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\PostFinishedEncoding": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Post uploaded"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\ReactivatedAccount": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Account Reactivated"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\ReportedPostDeleted": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faTrashAlt}
                title="Post Removed"
                description={<p>We removed your Post because it violated our <Link className={"single-notification-link"} to={`/terms`}>Terms of Service</Link>. Please read our Terms of Service to learn what kind of content is allowed on Fluffa.</p>}
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\RequestUserVerificationEmail": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Request Verification"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\SubscriptionRenewed": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Subscription Renewed"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\TemporaryDeactivation": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Account Deactivation"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\TipRecieved": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Tip Recieved"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\UpdateUserVerificationEmail": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Update User"
                description="???"
                created_at={created_at}
            />
        );
      }
      case "App\\Notifications\\UserActivated": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="User Activated"
                description="???"
                created_at={created_at}
            />
        );
      }
      case "App\\Notifications\\UserClosed": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="User Closed"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\UserDeactivated": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="User Deactivated"
                description="???"
                created_at={created_at}
            />
        );
      }
      case "App\\Notifications\\UserUnlockedChatMessage": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faUnlockAlt}
                title={<p><Link className={"single-notification-link"} to={`/${data.handle}`}>{data.handle}</Link> paid <span className="fl-singlenotification-unlocked-amount">${data.amount}</span> to unlock your message. You can view the transaction <Link className={"single-notification-link"} to={`/settings/earnings`}>here</Link>.</p>}
                created_at={created_at}
            />
        );
      }
      case "App\\Notifications\\VerifiedUserEmail": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                color={"green"}
                icon={faCheckCircle}
                iconSrcAlt="verified"
                title={<>Your account was approved. You’ll now see a pink verified badge on your <Link className={"single-notification-link"} to={`/${userData.user.handle}`}>profile</Link>.</>}
                created_at={created_at}
            />
        );
      }
    case "App\\Notifications\\TicketStatusChanged": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                color={"green"}
                iconSrc={closeTickets}
                title={`Your ticket ${data?.subject.length > 0 ? `[${data?.subject.toUpperCase()}]` : ""} has been closed.`}
                link={`/support/ticket/${data.ticket_id}`}
                linkText={"Click here to view your ticket"}
                created_at={created_at}
            />
        );
    }
    case "App\\Notifications\\NewTicketResponse": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faComment}
                title={<>A <Link className={"single-notification-link"} to={`/support/ticket/${data?.ticket_id}`}>new reply</Link> has been added to your ticket [{data?.subject.toUpperCase()}].</>}
                link={`/support/ticket/${data?.ticket_id}`}
                linkText=" Click here to view your ticket"
                created_at={created_at}
            />
        );
    }
    case "App\\Notifications\\NewPostTag": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faUserTag}
                title={<p><Link className={"single-notification-link"} to={`/${data?.creator_handle}`}>{data?.creator_handle}</Link> tagged you in a <Link className={"single-notification-link"} to={`/post/${data?.post}`}>Post</Link>.</p>}
                created_at={created_at}
            />
        );
    }
    case "App\\Notifications\\TrialUpgrade": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faGift}
                title="Reminder"
                description={<p>Your free trial to <Link className={"single-notification-link"} to={`/${data?.creator_handle}`}>{data?.creator_handle}</Link> will automatically convert to ${data.plan.upgrade_price}/ month in 24 hours. If you don't want to be charged, please <span onClick={() => unsubscribeModal(data?.plan,data.creator_handle)} className={"single-notification-link"}>cancel</span> within this time.</p>}
                created_at={created_at}
            />
        );
    }
      case "App\\Notifications\\EmailVerify": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment fl-singlenotification__icon--comment-verify-email"
                icon={faCheckCircle}
                color={"green"}
                title=" Your email address was verified."
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\YouAreMentionedInComment": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Mention"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\YouAreMentionedInPost": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faQuestion}
                title="Mention"
                description="???"
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\UserVerificationApproved": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faCheckCircle}
                color={"green"}
                title="Verification approved"
                description="Your account was successfully verified. You’ll now see a pink verified badge on your profile."
                created_at={created_at}
            />
        );
      }

      case "App\\Notifications\\UserVerificationRejected": {
        return (
            <Type
                iconClass="fl-singlenotification__icon--comment"
                icon={faTimes}
                title="Verification rejected"
                description="Your account was rejected. "
                link="/settings/verification"
                linkText="Click here for more info."
                created_at={created_at}
            />
        );
      }
    }
  };

  return (
      <div
          className={`fl-singlenotification__main fl-singlenotification__main--${userData.currentTheme} fl-singlenotification__main--${read_at ? "read" : `notread`}`}
          onClick={() => read_at ? "" :readNotification("read",id,read_before)}
      >
        <div className="fl-singlenotification__inner">
          {notificationBody()}
            {read_before || read_at ?
                <Dropdown
                    id={id}
                    read_at={read_at}
                    readNotification={readNotification}
                    read_before={read_before}
                /> : <span className={`fl-single-notifications-unread `}> <FontAwesomeIcon style={{fontSize: "11px"}} icon={faCircle}/></span>
            }
        </div>
      </div>
  );
};

export default SingleNotification;

export const ProcessUnsubscribe: FC<any> = ({ data,handle}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()

    useEffect(() => {
        document.body.classList.add("fl-body-fixed")
        return function cleanup() {
            document.body.classList.remove("fl-body-fixed")
        }
    }, []);


    const keyboardEvent = (event: KeyboardEvent) => {
        switch (event.code) {
            case "Escape":
                modalData.close()
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyboardEvent);
        return function cleanup() {
            document.removeEventListener('keydown', keyboardEvent);
        }
    }, [keyboardEvent]);

    const [subscribeLoading, setSubscribeLoading] = useState(false);


    const processUnSubscribe = async () => {
        modalData.clearToasts()
        setSubscribeLoading(true)
        try {
            await Axios.post(`${envData.apiUrl}/profile/${handle}/unsubscribe`);
            modalData.pushToast("success", `You have successfully cancelled your subscription.`)
            setSubscribeLoading(false)
            modalData.close()
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">
                    Cancel subscription?
                </div>
            </div>
            <div className="fl-modal-description">
                <div>
                    Your subscription will be cancelled at the end of your billing period on {moment(data.ends_at).format("D MMMM YYYY")}, and you won't be charged anymore.
                </div>
                <br/>
                <div>
                    After this date, you'll no longer have access to {handle}'s profile.
                </div>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button
                    onClick={modalData.close}>Keep subscription</Button>
                <Button disabled={subscribeLoading} type="normal"
                        onClick={() => processUnSubscribe()}>
                    {subscribeLoading ? <> Cancelling <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> </> : `Cancel subscription`}
                </Button>
            </div>
        </React.Fragment>
    );
}