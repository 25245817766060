import React, {FC, useEffect, useRef} from "react";
import "./UserCard.css";
import {Link, useParams} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCertificate, faCheck, faGift,
    faHeart as faHeartSolid,
    faImage, faShare,
    faVideo,
} from "@fortawesome/free-solid-svg-icons";

import { useUserContext } from "../../../../context/UserContext";
import { Button } from "../../../../components/Button";
import '../../../../components/CountryFlags/css/flag-icons.css'
import profileHolder from "../../../../assets/images/profile.png";
import ReactTooltip from "react-tooltip";
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import locationSource from "../../../../assets/images/icons/7.svg";
import birthdaySource from "../../../../assets/images/icons/8.svg";
import {useModalContext} from "../../../../context/ModalContext";

const UserCard: FC<{
  data: any;
  following?:any;
  followers?:any;
}> = ({ data,following,followers}) => {

  const infoRef = useRef<any>(null);
  const modalData = useModalContext()

  const userData = useUserContext();

  const { subroute } = useParams<{ subroute: string }>();

  const keyboardEvent = (e:any) => {
      if(e.key === "Escape") {
         modalData.closeImage()
      }
  }

    useEffect(() => {
        document.addEventListener('keydown', keyboardEvent);
        return () => {
            document.removeEventListener('keydown', keyboardEvent);
        }
    },[]);

    const copyProfileUrl = async (id:number,handle:any) => {
        //@ts-ignore
    if (navigator.share) {
        //@ts-ignore
        navigator.share({
          text: `${data.handle} on Fluffa`,
          url: `${window.location.origin}/${handle}`,
          title: 'Fluffa'
        }).then(() => {
          modalData.pushToast("success", "Thanks for sharing!");
        }).catch ((error:any)=> {
          if (error.name === "AbortError") {
            return;
          } else {
            modalData.pushToast("error", "Something went wrong!");
          }
        }) 
      } else {
          try {
              await navigator.clipboard.writeText(
                  `${window.location.origin}/${handle}`
              );
              modalData.pushToast("success", "Link copied to clipboard");
          } catch (e) {
              modalData.pushToast("error", "Something went wrong!");
          }
      }
    };

  return (
      <div
          className={`fl-usercard fl-usercard-full ${
              data?.meta?.is_subscribed || following  ? "fl-usercard__subscribed" : ""
          }`}
      >
            <div className={`fl-profile-card-img ${data.profileImage?.url ? "fl-profile-card-img-pointer" : "fl-profile-card-img-default"}`}
                 onClick={() => {
                     if (data.profileImage?.url)
                         modalData.toggleImageDm(
                             [data.profileImage],
                             0,
                             false
                         )
                 }}
            >
              <RenderSmoothImage
                  src={data.profileImage?.url || profileHolder}
              />
                {subroute === "online" ?
                    <div className={`fl-profile-holder-status-icon fl-profile-holder-status-icon-true`} data-tip data-for={"online"}>
                    </div> : ""
                }
            </div>
          {data.location !== null &&
              <div className={"fl-profile-card-location-user"}>
                  <img
                      src={locationSource}
                      alt="Location"
                  />
                  <span>{data.location}</span>
              </div>
          }
          {data.age !== null &&
          <div className={"fl-profile-card-age"}>
              <img
                  src={birthdaySource}
                  alt="Location"
              />
              <span>{data.age}</span>
          </div>
          }
          <div ref={infoRef} className={`fl-profile-card-info fl-usercard__info  fl-usercard__info-full`}>
              <div className={`fl-profile-card-info-row`}>
                  <div className="fl-profile-card-name fl-usercard__name">
                      <Link
                          to={`${data.handle}`}
                          className={`fl-profile-card-link fl-profile-card-link-${userData.currentTheme}`}
                      >
                          {data.name}
                      </Link>
                  </div>
              </div>
              <div className="fl-profile-card-username fl-usercard__username">
                <Link
                    to={`/${data.handle}`}
                    className={`fl-profile-card-name fl-profile-card-name-${userData.currentTheme} fl-d-flex fl-justify-flex-center`}
                >
                  @{data.handle}
                  {data.meta.verified_state === "verified" ?
                    <div className={"fl-explore-verified"}>
                        <FontAwesomeIcon
                            icon={faCertificate}
                        />
                        <FontAwesomeIcon
                            className={"fl-explore-verified-tick"}
                            data-tip
                            data-for="profile-verified"
                            icon={faCheck}
                        />
                    </div> : ""
                  }
                </Link>
                  {data?.creatorProfile?.is_new &&
                    <span className='fl-just-joined-label'>
                          new
                    </span>
                  }
              <div className={"fl-explore-copy-profile"} data-tip data-for={"profile-copy"}>
                  <FontAwesomeIcon icon={faShare}  onClick={() => copyProfileUrl(data.id,data.handle)} />
              </div>
              { data.free_subscription === 0 && data?.subscriptionPlans?.find((x:any) => x.type === "trial") &&
                  <Link  className={"fl-explore-trial-profile-link"} to={`/${data.handle}`}>
                      <div className={"fl-explore-trial-profile"}  data-tip data-for={"profile-free"}>
                          <FontAwesomeIcon icon={faGift} />
                      </div>
                  </Link>
              }
              </div>
              <div className="fl-profile-card-info-row fl-settings-pb-5">
                  <div className={"fl-profile-card-info-section"}>
                      <div>
                          <span>
                             {data.meta.posts_with_videos}
                          </span>
                          <span>
                             <FontAwesomeIcon
                                 icon={faVideo}
                             />
                          </span>
                      </div>
                      <div>
                          <span>
                             {data.meta.likes}
                          </span>
                          <span>
                               <FontAwesomeIcon
                                   icon={faHeartSolid}
                               />
                          </span>
                      </div>
                      <div>
                           <span>
                              {data.meta.posts_with_images}
                           </span>
                          <span>
                                 <FontAwesomeIcon
                                     icon={faImage}
                                 />
                          </span>
                      </div>
                  </div>
              </div>
              <div  className="fl-profile-card-buttons fl-explore-buttons">
                  <Button
                      className="fl-usercard__button"
                      to={`/${data.handle}`}
                      type="outlined-white"
                  >
                      {data.meta.is_subscribed || following || followers ? "View Profile" : data.free_subscription === 1 ? "Follow For Free" : "Subscribe Now"}
                  </Button>
              </div>
            </div>
          <ReactTooltip id="online">
              Active now
          </ReactTooltip>
          <ReactTooltip id="profile-verified">
              Verified
          </ReactTooltip>
          <ReactTooltip id="profile-copy">
              Share
          </ReactTooltip>
          <ReactTooltip id="profile-free">
              Free trial on now
          </ReactTooltip>
      </div>
  );
};

export default UserCard;
