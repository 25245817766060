import React, {FC, useState} from 'react'
//@ts-ignore
import Highlighter from "react-highlight-words";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart as faHeartSolid, faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../components/Button";
import {useModalContext} from "../../context/ModalContext";
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {MessageDropdown} from "./MessageDropdown";
import DollarBag from "../../assets/images/icons/dollarbag.svg";
import DollarBagWhite from "../../assets/images/icons/dollarbagwhite.svg";
import {useHistory} from "react-router-dom";
import ReactLoading from "react-loading";

export const MessageText:FC<any> = (props) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const checkTimeDeleteMessage = () => {
        if(moment(props.data.created_at).add(2, 'minutes') >= moment(new Date()) || props.data.restricted) {
            return true
        }else{
            return false
        }
    }
    const modalMediaDelete = async (conversation_id:number,message_id:number) => {
        const title = props.data?.attachments[0]?.type === "audio" ? "Unsend voice clip" : "Unsend message"
        const type = props.data?.attachments[0]?.type
        const description = `Are you sure you want to ${props.data.price === "0" ? `remove this message? The recipient will no longer be able to ${type === "audio" ? "play" : "view"} it.` : props.data.attachments && props.data.body ? "unsend this message?" : "remove this message? The recipient will no longer be able to unlock it."}`
        const textButton =  props.data.attachments && props.data.body ? "Yes" : "Remove"
        modalData.push(() => <UnsendModal title={title} description={description} conversation_id={conversation_id} message_id={message_id} textButton={textButton} state={props.state} setState={props.setState} setLastMessage={props.setLastMessage} lastMessage={props.lastMessage} data={props.data} />)

    };

    const deleteRestrictedMessage = (date:any) => {
        props.setLastMessage({
            ...props.lastMessage,
            messages: props.state.messages.length > 1 ? props.lastMessage.messages.map((x: any) =>
                x.id === props.data.conversation_id
                    ? { ...x, meta: { ...x, last_message:props.state.messages.find((x:any,index:number) => index === props.state.messages.length - 2)} }
                    : x
            ) : props.lastMessage.messages.filter((x: any) => x.id !== props.data.conversation_id),
        });
        props.setState({
            ...props.state,
            messages: props.state.messages.filter((x: any) => x.created_at !== date),
        });
    }

    const checkSendTips = () => {
        const regexTip = /@\[(\S+)\]/g
        const regexMessage = /tip-text\[(.*\S.*)\]/g
        const tipsAmount = props.data.body.match(regexTip)
        const tipsText = props.data.body.match(regexMessage)
        if(tipsAmount && tipsText) {
            return true
        } else {
            return false
        }
    }

    const tipPrice = () => {
        return Number((props.data.body.match(/@\[(\S+)\]/g)[0])?.match(/\((.*)\)/)?.pop()).toFixed(2)
    }

    const tipText = () => {
        return (props.data.body.match(/tip-text\[(.*\S.*)\]/g)[0])?.match(/\((.*)\)/)?.pop()
    }

    const likeMessage = async (conversation: number, message: number) => {
        const timeOut = props.like.status ? 0 : 1000;
        props.setLike({...props.like, animation:true})
        setTimeout(async () => {
            try {
                await Axios.get(`${envData.apiUrl}/conversations/${conversation}/messages/${message}/like`
                );
                props.setLike({
                    ...props.like,
                    status: !props.like.status,
                    animation: false
                });
            } catch (e) {
                modalData.pushToast("error", "Something went wrong!");
            }
        }, timeOut);
    };

    return (
        <div className={`fl-inbox-text ${props.reportedStatus > 0 ? "fl-inbox-reported" : ""} ${props.checkType() === "all" && props.direction === "right" ? "fl-d-right" : props.direction === "left" ? "fl-d-left" :  "" }`}
            onDoubleClick={() => props.direction === "left" ? likeMessage(props.data.conversation_id,props.data.id) : ""}
        >
            <div className={`fl-message-bubble-message-text ${props.data.attachments.length > 0 && "fl-settings-mt-5"} ${checkSendTips() ? "fl-message-bubble-message-text-italic" : ""}`}>
                {!checkSendTips() ?
                    <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[props.searchTextDefault]}
                        autoEscape={true}
                        textToHighlight={props.data.body}
                    /> :
                    <>
                        <span className={"fl-align-center fl-d-flex"}>
                            <img alt={`fluffa`} src={props.direction === "right" ? DollarBagWhite : DollarBag} /> {" "}
                            {props.checkWhoSend() ? "You" : "I"} sent  {props.checkWhoSend() ? "" : "you"} a ${tipPrice()} tip.
                        </span>
                        {tipText() ?
                            <span className={"fl-mt-5 fl-display-block"}>Message: {tipText()}</span> : ""
                        }
                    </>
                }
                {checkTimeDeleteMessage() && props.checkWhoSend() && !checkSendTips() && !props.uploading ?
                <div className={"fl-direct-message-option fl-direct-option-right"}>
                    <div
                        className="fl-direct-message-unsend-message"
                        onClick={() => props.data.restricted ? deleteRestrictedMessage(props.data.created_at) : checkTimeDeleteMessage() ? modalMediaDelete(props.data.conversation_id,props.data.id) : ""}
                    >
                        <FontAwesomeIcon icon={faUndoAlt}/>
                    </div>
                </div> : ""
                }
                {props.like.status ?
                    <div className={`fl-direct-message-like-message fl-direct-message-like-message-body`}>
                        <span><FontAwesomeIcon icon={faHeartSolid}/></span>
                    </div> : ""
                }
            </div>
            {props.reportedStatus === 0 && !checkSendTips() && !isMobile ?
                <MessageDropdown checkType={props.checkType} setLike={props.setLike} like={props.like} setReportedStatus={props.setReportedStatus} setState={props.setState} state={props.state} data={props.data} direction={props.direction} /> : ""
            }
        </div>
    )
}

export const UnsendModal: FC<any> = ({title,description,conversation_id,message_id,textButton,state,setState,setLastMessage,lastMessage,data}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const history = useHistory()
    const [animationDelete, setAnimationDelete] = useState(false)
    const confirmDelete = async (conversation_id:number,message_id:number) => {
        setAnimationDelete(true)
        try {
            await Axios.delete(
                `${envData.apiUrl}/conversations/${conversation_id}/messages/${message_id}/unsend`
            );
            setState({
                ...state,
                messages: state.messages.filter((x: any) => x.id !== message_id),
            });
            setLastMessage({
                ...lastMessage,
                messages: state.messages.length > 1 ? lastMessage.messages.map((x: any) =>
                    x.id === data.conversation_id
                        ? { ...x, meta: { ...x, last_message:state.messages.find((x:any,index:number) => index === state.messages.length - 2)} }
                        : x
                ) : lastMessage.messages.filter((x: any) => x.id !== data.conversation_id),
            });
            if(state.messages.length === 1) {
                history.push("/inbox");
            }
            modalData.close()
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimationDelete(false)
    };

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{title}</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                {description}
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" onClick={() => confirmDelete(conversation_id,message_id)} disabled={animationDelete}>
                    {textButton}
                    {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/> : ""}
                </Button>
            </div>
        </React.Fragment>
    );
}