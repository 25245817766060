import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart as faHeartSolid,
  faTimes,
  faChevronDown,
  faSmile
} from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import { Button } from "./Button";
import { ProfileHolder } from "./ProfileHolder";
import "./SimpleComment.css";
import { SimpleCard } from "./SimpleCard";
import Picker from "emoji-picker-react";
import { Textarea } from "./Textarea";
import PostBody from "./PostBody";
import {Tagging} from "./Tagging";
import ReactLoading from "react-loading";
import {CheckTime} from "./HandleTime/CheckTime";

export const SimpleComment: FC<any> = ({
  data,
  postId,
  setReply,
  simplified = false,
  animation,
  postComment,
  exState,
  updateState,
  currentComment,
  replyActive,
  commentQuote,
  replyId,
  setStateComments,
  stateComments,
  oldState,
  updatePost,
  postUser
}) => {
  // CONTEXT
  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const [comment, setComment] = useState<{
    oldText:string;
    text: string;
    edited: boolean;
    edit: boolean;
    dropdown: boolean;
  }>({
    text: data.text,
    edit: false,
    edited: false,
    dropdown: false,
    oldText:data.text
  });

  const [emojiState, setEmojiState] = useState({
    active: false,
  });

  const [subComsOpen, setSubComsOpen] = useState<boolean>(false);

  const likeComment = async (id: number) => {
    try {
      await Axios.post(
        `${envData.apiUrl}/posts/${postId}/comments/${id}/like`,
        { like: !data.meta.is_liked }
      );
      setStateComments({
        ...stateComments,
        comments: stateComments.comments.map((x: any) =>
            x.id === id ? { ...x, meta: {...x.meta, is_liked: !x.meta.is_liked, likes: x.meta.is_liked ? x.meta.likes - 1 : x.meta.likes + 1 } } : x
        ),
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const removeComment = async (id: number) => {
    try {
      await Axios.delete(`${envData.apiUrl}/posts/${postId}/comments/${id}`);
      setComment({
        ...comment,
        text: data.text,
        edited: true,
        dropdown: false,
        edit: false,
      });
      setStateComments({
        ...stateComments,
        comments: stateComments.comments.filter((x: any) => x.id !== id),
        total_comments:
          stateComments.total_comments - (data.meta.comments + 1),
      });
      updatePost({
        ...oldState,
        posts: oldState.posts.map((x: any) =>
            x.id === postId ? { ...x, meta: {...x.meta, total_comments: x.meta.total_comments - (data.meta.comments + 1), comments: x.meta.comments - 1} } : x
        )
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const [animationEdit,setAnimationEdit] = useState(false)
  const editComment = async (editedComment: string) => {
    setAnimationEdit(true)
    try {
       await Axios.put(
        `${envData.apiUrl}/posts/${postId}/comments/${data.id}`,
        { text: editedComment }
      );
      setComment({
        oldText:"",
        text: "",
        edited: false,
        dropdown: false,
        edit: false,
      });
      setStateComments({
        ...stateComments,
        comments: stateComments.comments.map((x: any) => x.id === data.id ? {...x, text:editedComment} : x),
      });
      setAnimationEdit(false)
    } catch (e) {
      setAnimationEdit(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const emojiRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const trackClick = (e: MouseEvent) => {
      if(emojiRef.current && e.target !== null){
        //@ts-ignore
        if(e.target !== emojiRef.current && !emojiRef.current.contains(e.target)){
          setEmojiState({ active: false });
        }
      }
    };
    window.addEventListener("click", trackClick);
    return () => {
      window.removeEventListener("click", trackClick);
    };
  }, []);

  const cancelComment = () => {
    updateState({
      ...exState,
      replying: false,
      commentQuote: null,
    });
  }

  const cancelReplyComment = () => {
    updateState({
      ...exState,
      replying: false,
      commentQuote: null,
    })
  }

  const cancelEditComment = () => {
    setComment({...comment,
      oldText:data.text,
      text: data.text,
      edit: false,
      edited: false,
      dropdown: false,
    })
  }

  const typeComment = (text:any) => {
    setComment({ ...comment, text: text })
  }

  const typeReplay = (text:any) => {
    updateState({
      ...exState,
      replyComment: text,
    })
  }

  const cancelEditCommentKey = () => {
    setComment({
      ...comment,
      edit: false,
      text: data.text,
    });
  }

  const commentRef = useRef<HTMLInputElement>(null);
  const editFunction = () => {
    setComment({
      ...comment,
      oldText:data.text,
      edit: !comment.edit,
      dropdown: false,
      text: data.text
    })
   setTimeout(() => {
     if (commentRef.current !== null) {
       commentRef.current.querySelector("textarea")?.focus();
     }
   },500)
  }

  return (
    <React.Fragment>
      {/* Comment body */}
      <div
        className={`fl-simple-comment-main ${
          simplified && "fl-simple-comment-main-simplified"
        }`}
        tabIndex={1}
        onBlur={() => setComment({ ...comment, dropdown: false })}
      >
        {(userData.user.profile.meta.verified_state === "verified" && !comment.edit && postUser === userData.user.id) || (data.userProfile.handle === userData?.user.handle && !comment.edit) ?
            <div className="fl-simple-comment-description-dropdown">
              <FontAwesomeIcon
                  className="fl-post-head-actions-icon"
                  icon={faChevronDown}
                  onClick={() =>
                      setComment({ ...comment, dropdown: !comment.dropdown })
                  }
              />
              {comment.dropdown && (
                  <div
                      className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                  >
                    <SimpleCard className="fl-post-head-actions-dropdown-inner">
                      {data.userProfile.handle === userData?.user.handle && !comment.edit &&
                      <div
                          className="fl-post-head-actions-dropdown-option"
                          onClick={() =>
                              editFunction()
                          }
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-edit-hover">
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                               width="396.000000pt" height="346.000000pt" viewBox="0 0 396.000000 346.000000"
                               preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#ff0d7290" stroke="none">
                              <path d="M1860 3399 c-452 -27 -872 -180 -1186 -433 -366 -294 -574 -696 -574-1110 0
                            -335 121 -649 354 -916 36 -41 66 -78 66 -82 0 -18 -67 -192 -97 -253-65 -127 -258 -413 -308 -455 -21 -18 -19 -64 5 -85 18 -16 32 -17 123 -11
                            142 9 263 30 385 66 150 45 392 169 527 271 l52 39 38 -16 c59 -24 297 -82
                            420 -101 153 -25 524 -24 685 1 584 89 1049 361 1337 783 218 318 286 684 198
                            1051 -64 265 -196 484 -425 705 -134 130 -253 214 -440 310 -346 180 -746 261
                            -1160 236z m738 -736 c21 -9 76 -56 124 -103 105 -104 133 -158 125 -232 -8
                            -66 -28 -96 -147 -213 l-100 -100 -210 210 -210 210 103 103 c56 56 120 111
                            142 122 49 25 124 26 173 3z m-548 -1196 l-385 -383 -223 -44 c-252 -49 -272
                            -48 -272 13 0 19 15 110 34 203 18 93 37 187 40 209 7 36 44 76 389 423 l382
                            382 210 -210 210 -210 -385 -383z"/>
                            </g>
                          </svg>
                        </div>
                        Edit comment
                      </div>
                      }
                      <div
                          className="fl-post-head-actions-dropdown-option"
                          onClick={() => removeComment(data.id)}
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                        </div>
                        Delete comment
                      </div>
                    </SimpleCard>
                  </div>
              )}
            </div> : ""
        }
        <div className="fl-simple-comment-image">
          <ProfileHolder
            src={data?.userProfile?.profileImage?.url}
            userProfile={
              data?.userProfile?.online_status ? data?.userProfile : []
            }
          />
        </div>
        <div className="fl-simple-comment-text">
          <div className={"fl-simple-comment-username-name"}>
            <Link
              to={`/${data.userProfile.handle}`}
              className={`fl-simple-comment-name fl-simple-comment-name-${userData.currentTheme}`}
            >
              {data.userProfile.name}
            </Link>
            <Link
              to={`/${data.userProfile.handle}`}
              className="fl-simple-comment-username"
            >
              @{data.userProfile.handle}
            </Link>
          </div>
          <div className="fl-simple-comment-description">
            {comment.edit ? (
              <div>
                <div className="fl-simple-comment-make-all"  ref={commentRef}>
                  {userData.user.profile.meta.verified_state !== "verified" ? (
                      <Textarea
                          onKeyDown={(e: any) => {
                            e.key === "Escape" &&
                            cancelEditCommentKey()
                          }}
                          className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                          placeholder="Edit a comment"
                          value={comment.text}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => typeComment(e.currentTarget.value)}
                          onFocus={(e:any) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                      />
                  ) : (
                      <Tagging setText={typeComment} text={comment.text} placeholder="Edit a comment" keydownFunction={cancelEditCommentKey}/>
                  )}
                  <div
                    className={`fl-simple-comment-reply-cancel fl-simple-comment-reply-cancel-${userData.currentTheme}`}
                    onClick={() => cancelEditComment()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <p className={"fl-post-comments-cancel"}>
                  Press ESC to  <span className={`fl-blue-link fl-hover-underline`} onClick={() => cancelEditComment()}>cancel.</span>
                </p>
                <div className="fl-post-comments-make-buttons">
                  <Button
                    className="fl-post-comments-make-button"
                    type="normal"
                    onClick={() => editComment(comment.text)}
                    disabled={animationEdit || comment.text === comment.oldText || comment.text.length === 0}
                  >
                    Edit
                    {animationEdit ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                  </Button>
                </div>
              </div>
            ) : (
                <PostBody body={data.text} />
            )}
          </div>

          <div className="fl-simple-comment-actions">
            <div
              className="fl-simple-comment-actions-single"
              onClick={() => likeComment(data.id)}
            >
              <div className="fl-post-action-iconholder">
                {data.meta.is_liked ? (
                  <FontAwesomeIcon
                    className="fl-post-action-single-icon-active"
                    icon={faHeartSolid}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="fl-post-action-single-icon"
                    icon={faHeart}
                  />
                )}
              </div>
              {simplified ? (
                <span>{data.meta.likes}</span>
              ) : (
                <span className="fl-simple-comment-like">Like</span>
              )}
            </div>
            {simplified ? (
              <>
                <div className="fl-post-head-info-dot"></div>
                <div
                  className="fl-simple-comment-actions-single"
                  // onClick={() => seeSubcomments(data.id)}
                >
                  <div className="fl-post-action-iconholder">
                    <FontAwesomeIcon
                      className="fl-post-action-single-icon"
                      icon={faCommentAlt}
                    />
                  </div>
                  <span>{data.meta.comments}</span>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="fl-post-head-info-dot"></div>
            <div
              className="fl-simple-comment-actions-single"
              onClick={() => setReply(data)}
            >
              <div className="fl-post-action-iconholder">
                <FontAwesomeIcon
                  className="fl-post-action-single-icon"
                  icon={faReply}
                />
              </div>
              {simplified ? (
                ""
              ) : (
                <span className="fl-simple-comment-reply">Reply</span>
              )}
            </div>
            <div className="fl-post-head-info-dot"></div>
            <div className="fl-simple-comment-actions-single">
              <span className={"fl-simple-comment-time"}>
                 <CheckTime data={data.created_at} />
              </span>
            </div>
          </div>

          {replyActive && replyId === commentQuote?.id && (
              <div className="fl-post-comments-reply">
                <div className="fl-simple-comment-make-all">
                  <div
                      className="fl-post-comment-emojis"
                      onClick={() => setEmojiState({ active: !emojiState.active })}
                      ref={emojiRef}
                  >
                    <FontAwesomeIcon icon={faSmile} />
                  </div>
                  <div
                      className="fl-post-comment-emojis-picker"
                      style={{ display: emojiState.active ? "block" : "none" }}
                      onClick={(e) => e.stopPropagation()}
                  >
                    <Picker
                        onEmojiClick={(e, emoji) => {
                          updateState({
                            ...exState,
                            replyComment: `${exState.currentComment}${emoji.emoji}`,
                          });
                        }}
                    />
                  </div>
                  {userData.user.profile.meta.verified_state !== "verified" ? (
                      <Textarea
                          onKeyDown={(e: any) => {
                            e.key === "Escape" &&
                            cancelComment()
                          }}
                          placeholder="Add a reply..."
                          className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                          value={currentComment}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => typeReplay(e.currentTarget.value)
                          }
                          onFocus={(e:any) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                      />
                  ) : (
                      <Tagging setText={typeReplay} text={currentComment}  placeholder="Add a reply..." />
                  )}
                  <div
                      className={`fl-simple-comment-reply-cancel fl-simple-comment-reply-cancel-${userData.currentTheme}`}
                      onClick={() => cancelReplyComment()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div className="fl-post-comments-make-buttons">
                  <Button
                      className="fl-post-comments-make-button"
                      type="normal"
                      onClick={() => {postComment();setSubComsOpen(true)}}
                      disabled={currentComment.trim() === "" || animation}
                  >
                    Post
                    {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                  </Button>
                </div>
              </div>
          )}

          {simplified ? (
            ""
          ) : data.replies.length > 0 ? (
            <div className="fl-gotopost-post-comment-single-data-comments">
              <span
                  onClick={() => setSubComsOpen(!subComsOpen)}
              >
                {data.replies.length > 0 && subComsOpen
                  ? `Hide replies`
                  : `View replies (${data.replies.length})`}
              </span>
            </div>
          ) : (
              ""
          )}
        </div>
      </div>

      {/* Subcomments (replies) */}
      {data.replies.length > 0 && subComsOpen ? (
        <div
          className={`fl-simple-comment-child ${
            simplified && "fl-simple-comment-child-simplified"
          }`}
        >
          {data.replies.map((x:any, y:any) => (
            <SimpleSubcomment
              key={y}
              data={x}
              postId={postId}
              simplified={simplified}
              commentId={data.id}
              setStateComments={setStateComments}
              stateComments={stateComments}
              updatePost={updatePost}
              oldState={oldState}
              postUser={postUser}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const SimpleSubcomment: FC<any> = ({
  data,
  postId,
  simplified = false,
  commentId,
  setStateComments,
  stateComments,
  updatePost,
  oldState,
  postUser
}) => {

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const [animationEdit,setAnimationEdit] = useState(false)
  const [comment, setComment] = useState<{
    oldText:string;
    dropdown: boolean;
    edit:boolean;
    text:any;
  }>({
    dropdown: false,
    edit:false,
    text: "",
    oldText:""
  });

  const likeComment = async (id: number) => {
    try {
      await Axios.post(
        `${envData.apiUrl}/posts/${postId}/comments/${id}/like`,
        { like: !data.meta.is_liked }
      );
      setStateComments({
        ...stateComments,
        comments: stateComments.comments.map((x: any) =>
            x.id === commentId ? { ...x, replies: x.replies.map((x:any) => x.id === id ? { ...x, meta: {...x.meta, is_liked: !x.meta.is_liked, likes: x.meta.is_liked ? x.meta.likes - 1 : x.meta.likes + 1 } } : x)} : x
      ),
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const removeComment = async (id: number) => {
    try {
      await Axios.delete(`${envData.apiUrl}/posts/${postId}/comments/${id}`);
      setComment({ ...comment, dropdown: false })
      setStateComments({
        ...stateComments,
        comments: stateComments.comments.map((x: any) =>
            x.id === commentId ? { ...x, meta: {...x.meta, comments: x.meta.comments - 1}, replies: x.replies.filter((x: any) => x.id !== id) } : x
        ),
      });
      updatePost({
        ...oldState,
        posts: oldState.posts.map((x: any) =>
            x.id === postId ? { ...x, meta: {...x.meta, total_comments: x.meta.total_comments - 1} } : x
        )
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const editComment = async (editedComment: string) => {
    setAnimationEdit(true)
    try {
       await Axios.put(
          `${envData.apiUrl}/posts/${postId}/comments/${data.id}`,
          { text: editedComment }
      );
      setStateComments({
        ...stateComments,
        comments: stateComments.comments.map((x: any) =>
            x.id === commentId ? { ...x, replies:  x.replies.map((x: any) => x.id === data.id ? {...x, text:editedComment} : x) } : x
        ),
      });
      setComment({
        oldText:"",
        text: "",
        dropdown: false,
        edit: false,
      });
      setAnimationEdit(false)
    } catch (e) {
      setAnimationEdit(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const cancelEditComment = () => {
    setComment({
      text: data.text,
      edit: false,
      dropdown: false,
      oldText:data.text
    })
  }

  const typeComment = (text:any) => {
    setComment({ ...comment, text: text })
  }

  const cancelEditCommentKey = () => {
    setComment({
      ...comment,
      edit: false,
      text: data.text,
      oldText:data.text
    });
  }

  const commentRef = useRef<HTMLInputElement>(null);
  const editFunction = () => {
    setComment({
      ...comment,
      edit: !comment.edit,
      dropdown: false,
      text: data.text,
      oldText:data.text
    })
    setTimeout(() => {
      if (commentRef.current !== null) {
        commentRef.current.querySelector("textarea")?.focus();
      }
    },500)
  }

  return (
    <div className="fl-simple-comment-main"
         tabIndex={1}
         onBlur={() =>   setComment({ ...comment, dropdown: false })}
    >
      {(userData.user.profile.meta.verified_state === "verified" && !comment.edit && postUser === userData.user.id) || (data.userProfile.handle === userData?.user.handle && !comment.edit) ?
          <div className="fl-simple-comment-description-dropdown">
            <FontAwesomeIcon
                className="fl-post-head-actions-icon"
                icon={faChevronDown}
                onClick={() =>
                    setComment({ ...comment, dropdown: !comment.dropdown })
                }
            />

            {comment.dropdown && (
                <div
                    className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                >
                  <SimpleCard className="fl-post-head-actions-dropdown-inner">
                    {data.userProfile.handle === userData?.user.handle && !comment.edit &&
                      <div
                          className="fl-post-head-actions-dropdown-option"
                          onClick={() =>
                              editFunction()
                          }
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-edit-hover">
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                               width="396.000000pt" height="346.000000pt" viewBox="0 0 396.000000 346.000000"
                               preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#ff0d7290" stroke="none">
                              <path d="M1860 3399 c-452 -27 -872 -180 -1186 -433 -366 -294 -574 -696 -574-1110 0
                              -335 121 -649 354 -916 36 -41 66 -78 66 -82 0 -18 -67 -192 -97 -253-65 -127 -258 -413 -308 -455 -21 -18 -19 -64 5 -85 18 -16 32 -17 123 -11
                              142 9 263 30 385 66 150 45 392 169 527 271 l52 39 38 -16 c59 -24 297 -82
                              420 -101 153 -25 524 -24 685 1 584 89 1049 361 1337 783 218 318 286 684 198
                              1051 -64 265 -196 484 -425 705 -134 130 -253 214 -440 310 -346 180 -746 261
                              -1160 236z m738 -736 c21 -9 76 -56 124 -103 105 -104 133 -158 125 -232 -8
                              -66 -28 -96 -147 -213 l-100 -100 -210 210 -210 210 103 103 c56 56 120 111
                              142 122 49 25 124 26 173 3z m-548 -1196 l-385 -383 -223 -44 c-252 -49 -272
                              -48 -272 13 0 19 15 110 34 203 18 93 37 187 40 209 7 36 44 76 389 423 l382
                              382 210 -210 210 -210 -385 -383z"/>
                            </g>
                          </svg>
                        </div>
                        Edit comment
                      </div>
                    }
                    <div
                        className="fl-post-head-actions-dropdown-option"
                        onClick={() => removeComment(data.id)}
                    >
                      <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                      </div>
                      Delete comment
                    </div>
                  </SimpleCard>
                </div>
            )}
          </div> : ""
      }
      <div className="fl-simple-comment-image">
        <ProfileHolder src={data?.userProfile?.profileImage?.url} />
      </div>
      <div className="fl-simple-comment-text">
        <div className={"fl-simple-comment-username-name"}>
          <Link
            to={`/${data?.userProfile?.handle}`}
            className={`fl-simple-comment-name fl-simple-comment-name-${userData.currentTheme}`}
          >
            {data?.userProfile?.name}
          </Link>
          <Link
            to={`/${data?.userProfile?.handle}`}
            className="fl-simple-comment-username"
          >
            @{data?.userProfile?.handle}
          </Link>
        </div>
        <div className="fl-simple-comment-description">
          {comment.edit ? (
              <div>
                <div className="fl-simple-comment-make-all" ref={commentRef}>
                  {userData.user.profile.meta.verified_state !== "verified" ? (
                      <Textarea
                          onKeyDown={(e: any) => {
                            e.key === "Escape" &&
                            cancelEditCommentKey()
                          }}
                          className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                          placeholder="Edit a comment"
                          value={comment.text}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => typeComment(e.currentTarget.value)}
                      />
                  ) : (
                      <Tagging setText={typeComment} text={comment.text} placeholder="Edit a comment" keydownFunction={cancelEditCommentKey}/>
                  )}
                  <div
                      className={`fl-simple-comment-reply-cancel fl-simple-comment-reply-cancel-${userData.currentTheme}`}
                      onClick={() => cancelEditComment()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <p className={"fl-post-comments-cancel"}>
                  Press ESC to  <span className={`fl-blue-link fl-hover-underline`} onClick={() => cancelEditComment()}>cancel.</span>
                </p>
                <div className="fl-post-comments-make-buttons">
                  <Button
                      className="fl-post-comments-make-button"
                      type="normal"
                      onClick={() => editComment(comment.text)}
                      disabled={animationEdit || comment.text === comment.oldText || comment.text.length === 0}
                  >
                    Edit
                    {animationEdit ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                  </Button>
                </div>
              </div>
          ) : (
              <PostBody body={data.text} />
          )}
        </div>
        {simplified ? (
          ""
        ) : data.meta.likes ? (
          <div className="fl-simple-comment-values">
            <div
              className={`fl-simple-comment-values-likes fl-simple-comment-values-likes-${userData.currentTheme}`}
            >
              <span>{`${data.meta.likes} like${
                data.meta.likes === 1 ? "" : "s"
              }`}</span>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="fl-simple-comment-actions">
          <div className="fl-simple-comment-actions-single">
            <span className="fl-simple-comment-time">
              <CheckTime data={data.created_at} />
            </span>
          </div>
          <div
            className="fl-simple-comment-actions-single"
            onClick={() => likeComment(data.id)}
          >
            <div className="fl-post-action-iconholder">
              {data.meta.is_liked  ? (
                <FontAwesomeIcon
                  className="fl-post-action-single-icon-active"
                  icon={faHeartSolid}
                />
              ) : (
                <FontAwesomeIcon
                  className="fl-post-action-single-icon"
                  icon={faHeart}
                />
              )}
            </div>
            {simplified ? (
              <span>{data.meta.likes}</span>
            ) : (
              <span className="fl-simple-comment-like">Like</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
