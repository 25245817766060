import React, {FC, useState} from 'react'
import {useUserContext} from "../../context/UserContext";
import {Drag} from "../../components/Drag";
import {Toggle} from "../../components/Toggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDollarSign,
    faEye,
    faEyeSlash,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {RangeModal} from "../../components/RangeModal";
import {useModalContext} from "../../context/ModalContext";

export const PreviewFiles:FC<any> = (props) => {

    const userData = useUserContext()
    const modalData = useModalContext()

    const [disableBlur, setDisableBlur] = useState<any>(false);
    const [blurred, setBlurred] = useState<boolean>(true);

    const rightClickBlur = (e:any) => {
        e.stopPropagation();
        e.preventDefault()
    }
    const blurPreview = () => {
        return props.state.blurredPercent / 40 + 1
    }
    const removeFile = (idx: number) => {
        props.setUploadState({
            ...props.uploadState,
            files: props.uploadState.files.filter((x: any, y: number) => y !== idx),
        });
    };
    const showBlur = (e:any) => {
        e.stopPropagation()
        if(e.button === 0) {
            setDisableBlur(true)
        }
    }
    const hideBlur = () => {
        setDisableBlur(false)
    }
    const openRangeModal = () => {
        modalData.push(() => <RangeModal setState={props.setState} state={props.state} uploadState={props.uploadState} />);
    }

    return (
            <div className="fl-direct-messages-box-files" onContextMenu={(e) => rightClickBlur(e)}>
                <Drag setDisableBlur={setDisableBlur} uploadState={props.uploadState} setUploadState={props.setUploadState} state={props.state} disableBlur={disableBlur} blurPreview={blurPreview} removeFile={removeFile} showBlur={showBlur} hideBlur={hideBlur} rightClickBlur={rightClickBlur}/>
                {/*{userData.user.profile.meta.verified_state === "verified" &&*/}
                <div className={`fl-direct-messages-box-files-price fl-bulk__price fl-bulk__price--${userData.currentTheme}`}>
                    {!props.free && (
                        <div className="fl-bulk__price__toggle fl-bulk__price__toggle-blurred">
                            <Toggle
                                leftComponent={
                                    <FontAwesomeIcon icon={faEye}/>
                                }
                                rightComponent={
                                    <FontAwesomeIcon icon={faEyeSlash}/>
                                }
                                value={blurred ? true : false}
                                onClick={() => {
                                    blurred
                                        ? props.setState({ ...props.state, blurred: "black" })
                                        : props.setState({ ...props.state, blurred: "blurred" })
                                    setBlurred(!blurred);
                                }}
                            />
                            {blurred ?
                                <div>
                                     <span className="fl-bulk__price__label">
                                     <span className="fl-bulk-blur" onClick={openRangeModal}>Blur thumbnail ({props.state.blurredPercent.toFixed(1).split('.')[0]}%)</span> {" "}
                                         <FontAwesomeIcon
                                             icon={faInfoCircle}
                                             data-tip
                                             data-for="attachments-blurred"
                                         />
                                     </span>
                                    <ReactTooltip id="attachments-blurred">
                                        Select the level of blur you’d like to add to the thumbnail. Less blur gives the recipient a better idea of what they’re unlocking.
                                    </ReactTooltip>
                                </div>
                                :
                                <div>
                                     <span className={`fl-bulk__price__label`}>
                                         Hide thumbnail{" "}
                                         <FontAwesomeIcon
                                             icon={faInfoCircle}
                                             data-tip
                                             data-for="m"
                                         />
                                     </span>
                                    <ReactTooltip id="m">
                                        Thumbnail will be fully hidden and only a lock icon will show.
                                    </ReactTooltip>
                                </div>
                            }
                        </div>
                    )}
                    <div className="fl-bulk__price__toggle ">
                        <div className="fl-bulk__price-set-price">
                            {!props.free &&
                            <>
                                    <span className="fl-bulk__price__label">
                                      Set unlock price{" "}
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            data-tip
                                            data-for="attachments-cost"
                                        />
                                    </span>
                                <ReactTooltip id="attachments-cost">
                                    You can set a price from $5 to $100 for PPV content
                                </ReactTooltip>
                                <span
                                    className={`fl-bulk__price__currency fl-bulk__price__currency--${userData.currentTheme}`}
                                    style={{ bottom: props.state.price < 5 ? "28px" : "" }}
                                >
                                  <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                                <input className={`fl-bulk__price__input--${userData.currentTheme}`}
                                       min="5"
                                       type="number"
                                       value={props.state.price}
                                       onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                           props.setState({
                                               ...props.state,
                                               price: e.currentTarget.value,
                                           })
                                       }}
                                />
                            </>
                            }
                        </div>
                        <div className={`${props.free && "fl-bulk__price-free-price"}`}>
                            {props.free ? (
                                <>
                                     <span className="fl-bulk__price__label">
                                       Sending for FREE{" "}
                                         <FontAwesomeIcon
                                             icon={faInfoCircle}
                                             data-tip
                                             data-for="attachments-cost"
                                         />
                                       </span>
                                    <ReactTooltip id="attachments-cost">
                                        Message will be sent unlocked
                                    </ReactTooltip>
                                </>
                            ) : (
                                <>
                                    <span className="fl-bulk__price__label">
                                      PPV Message
                                    </span>
                                </>
                            )}
                            <Toggle
                                leftComponent={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="m11.666 1043.52v-1.49c0-2.02-1.645-3.667-3.666-3.667-2.02 0-3.666 1.645-3.666 3.667v1.49c-.289.06-.507.317-.507.624v5.582c0 .352.286.638.638.638h7.07c.352 0 .638-.286.638-.638v-5.582c0-.307-.218-.563-.507-.624m-2.81 4.758c.013.069-.034.125-.104.125h-1.488c-.07 0-.117-.057-.104-.125l.237-1.256c-.179-.164-.292-.399-.292-.661 0-.494.401-.895.895-.895.494 0 .895.4.895.895 0 .255-.107.485-.278.648zm1.534-4.772h-4.782v-1.476c0-1.319 1.073-2.391 2.391-2.391 1.318 0 2.391 1.073 2.391 2.391z"
                                            fill="#fff"
                                            transform="translate(0-1036.36)"
                                        />
                                    </svg>
                                }
                                rightComponent={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                    >
                                        <path
                                            d="m299.02 161.26h-185.84v-46.28c0-41.34 33.635-74.979 74.979-74.979 33.758 0 63.51 22.716 72.36 55.24 2.898 10.657 13.888 16.946 24.547 14.05 10.659-2.898 16.949-13.889 14.05-24.548-13.57-49.896-59.2-84.74-110.96-84.74-63.4 0-114.98 51.58-114.98 114.98v46.715c-9.06 1.902-15.888 9.952-15.888 19.571v175.05c0 11.03 8.972 20 20 20h221.73c11.03 0 20-8.972 20-20v-175.05c0-11.03-8.972-20-20-20m-84 149.65c.408 2.162-1.058 3.931-3.258 3.931h-46.677c-2.2 0-3.666-1.769-3.258-3.931l7.432-39.39c-5.626-5.131-9.157-12.52-9.157-20.734 0-15.495 12.561-28.06 28.06-28.06 15.495 0 28.06 12.561 28.06 28.06 0 7.991-3.346 15.195-8.707 20.305z"
                                            fill="#fff"
                                            transform="matrix(.04241 0 0 .04241 3.047 2.918)"
                                        />
                                    </svg>
                                }
                                value={props.free ? false : true}
                                onClick={() => {
                                    props.free
                                        ? props.setState({ ...props.state, price: "5" })
                                        : props.setState({ ...props.state, price: "0" });
                                    props.setFree(!props.free);
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }} >
                        {props.state.price < 5 && !props.free && (
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: "-12px",
                                    color: "#ff3f47",
                                    display: 'contents'
                                }}
                            >
                                Minimum $5 USD
                              </span>
                        )}
                    </div>
                </div>
                 {/*}*/}
            </div>
    )
}