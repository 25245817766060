import * as React from "react";
import {FC, useState} from "react";
import spinnerIcon from "../../../src/assets/images/spinner.svg"

const PreviewVideoMessage: FC<any> = ({...props}) => {
    const [load,setLoad] = useState<boolean>(false)
    return  <video
        style={{ filter: `blur(${props.state.blurred === "blurred" && props.state.price > 0 && !props.disableBlur ? props.blurPreview() : "0"}px)` }}
        autoPlay
        loop
        muted
        poster={load ? "" : spinnerIcon}
        onLoadStart={() => setLoad(false)}
        onLoadedData={() => setLoad(true)}
    >
        <source src={props?.src !== undefined || props?.src !== "" || props?.src !== null ? props.src : ""} type="video/mp4" />
    </video>;
}

export default PreviewVideoMessage;
