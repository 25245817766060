import React, { FC, useEffect, useRef, useState } from 'react';
import "./MessageAudio.css";
import { useUserContext } from '../context/UserContext';
import {faArrowAltCircleUp, faPause, faPlay, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WaveSurfer from 'wavesurfer.js';
import momentDurationSetup from 'moment-duration-format';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

//@ts-ignore
momentDurationSetup(moment);

export const MessageAudio:FC<{src: string,recording:any,sendRecording:any,data:any,deleteRecording:any, reportedStatus:any,removeDeleteIcon?:boolean}> = ({ src,recording,sendRecording,data,deleteRecording,reportedStatus,removeDeleteIcon }) => {

    const userData = useUserContext();

    const [state, setState] = useState<any>({
        playing: false,
        waveSurfer: null,
        duration: "0:00"
    })
    const stateRef = useRef(state);
    useEffect(() => { stateRef.current = state }, [state]);

    const waveformRef = useRef<any>(null);
    const togglePlay = () => {
        if(state.waveSurfer === null)return;
        state.playing ? state.waveSurfer.pause() : state.waveSurfer.play();
        setState({...state, playing: state.playing ? false : true})
    }

    useEffect(() => {
        var waveSurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#fff',
            progressColor: '#ccc',
            barGap: 2,
            barWidth: 3,
            barHeight: 10,
            barMinHeight: 3,
            cursorColor: "#ffffff80",
            responsive: true
        });
        waveSurfer.on('finish', function () {
            waveSurfer.stop();
            setState({...stateRef.current, playing: false});
        });
        waveSurfer.on('ready', function () {
            setState({...stateRef.current, duration: waveSurfer.getDuration()})
        });
        if(src) {
            waveSurfer.load(src);
        }
        setState({...state, waveSurfer })

        return () => {
            waveSurfer.destroy();
        }
    },[])

    return (
        <>
            {recording && !removeDeleteIcon &&
            <>
                <div
                    className="fl-direct-messages-box-delete-record"
                    data-tip
                    data-for="delete-recording"
                    onClick={deleteRecording}
                >
                    <FontAwesomeIcon icon={faTimes} />
                    <ReactTooltip id="delete-recording">Delete audio</ReactTooltip>
                </div>
            </>
            }
            <div className={`fl-messageaudio-main fl-messageaudio-main-${recording && "recording"} fl-message-audio-report-${(userData.user.handle !== data?.sender?.handle && data?.reports > 0) || (userData.user.handle !== data?.sender?.handle && reportedStatus) ? ("true"):("false") }`}>
                <div className="fl-messageaudio-action fl-voice-record-cursor-enable" onClick={togglePlay}  data-tip data-for="play-recording">
                    <FontAwesomeIcon icon={state.playing ? faPause : faPlay}/>
                    {recording &&  <ReactTooltip id="play-recording">{state.playing ? "Pause" : "Play"} audio</ReactTooltip>}
                </div>
                <div className="fl-messageaudio-graph" ref={waveformRef}></div>
                <div className="fl-messageaudio-timer">
                    <span>
                        {moment().startOf('day')
                            .seconds(state.duration)
                            .format('m:ss')}
                    </span>
                </div>
            </div>
            {recording && sendRecording &&
            <>
                <div
                    className="fl-direct-messages-box-send-record"
                    data-tip
                    data-for="send-recording"
                    onClick={() => {deleteRecording();sendRecording()}}
                >
                    <FontAwesomeIcon icon={faArrowAltCircleUp} />
                    <ReactTooltip id="send-recording">Send audio</ReactTooltip>
                </div>
            </>
            }
        </>
    )
}
