import React, {FC} from "react";
import { GoToPost } from "../components/GoToPost";
import { useUserContext } from "../context/UserContext"
import './Preview.css';

export const Preview:FC<any> = () => {
	const userData = useUserContext();

	return <div className={`fl-preview-main fl-preview-main-${userData.currentTheme}`} style={{ paddingTop: userData.authStatus !== "AUTHENTICATED" ? 30 : 0 }}>
		<GoToPost />
	</div>
}