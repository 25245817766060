import React, { FC } from "react";
import {Link, useLocation} from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import "./Button.css";

export const Button: FC<any> = (props) => {
  // PROPS
  const { type, to, className } = props || { type: "normal" };

  // CONTEXT
  const userData = useUserContext();

  // LOCATION
  const location = useLocation();

  const HTMLType = to ? Link : "button";

  const isActiveLocation = (x: string) => {
    // return location.pathname === x ? "active-location" : "location";
    if (x === "/explore/all") {
      if (
        location.pathname === "/explore/all" ||
        location.pathname === "/explore/online" ||
        location.pathname === "/explore/new" ||
        location.pathname === "/explore/featured" ||
        location.pathname === "/explore/free"
      ) {
        return "active-location";
      } else {
        return "location";
      }
    } else if (x === "/inbox") {
      if(location.pathname.match(/[/]inbox/g)) {
        return "active-location";
      } else {
        return "location";
      }
    } else if (location.pathname === x) {
      return "active-location";
    } else {
      return "location";
    }
  };

  return (
    <HTMLType
      {...props}
      className={`fl-button fl-button-${
        (type === "location" ? isActiveLocation(to) : type) || "default"
      } fl-button-${userData.currentTheme} ${className}`}
    >
      {props.children}
    </HTMLType>
  );
};
