import React, { FC, useEffect, useRef, useState } from "react";
import { useUserContext } from "../context/UserContext";
import "./Feed.css";
import { SimpleCard } from "../components/SimpleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Post } from "../components/Post";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import postInterface from "../interfaces/PostInterface";
import { MyProfile } from "../components/MyProfile";
import { WritePost } from "../components/WritePost";
import { SupportBox } from "../components/SupportBox";
import { useModalContext } from "../context/ModalContext";
import { UnapprovedBox } from "../components/UnapprovedBox";
import { VerificationBox } from "../components/VerificationBox";
import { PostOptions } from "../components/PostOptions";
import moment from "moment";
import ReactLoading from "react-loading";
import { faWindowMaximize } from "@fortawesome/free-regular-svg-icons";

export const Feed: FC<any> = (props) => {
  const [state, setState] = useState({
    posts: [],
    last_loaded_id: null,
    loadingState: false,
  });

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();
  const history = useHistory();

  const stateRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const [animation, setAnimation] = useState(true);

  const getFeeds = async () => {
    try {
      setState({ ...stateRef.current, loadingState: true });
      const res = await Axios.get(
        `${envData.apiUrl}/feed${
          stateRef.current.last_loaded_id
            ? `?last_loaded_id=${stateRef.current.last_loaded_id}`
            : ""
        }`
      );
      const { data } = res.data;

      setState({
        ...stateRef.current,
        posts: [...stateRef.current.posts, ...data],
        loadingState: false,
        last_loaded_id: data.length > 0 ? data[data.length - 1].id : 0,
      });
      setAnimation(false);
    } catch (e) {
      setAnimation(false);
      if( e.response?.data.message === "Unauthenticated.") {
        userData.clearToken(false);
        history.push("/login");
      } else {
        modalData.pushToast("error", "Something went wrong!");
      }
    }
  };

  const getAfterBlock = async () => {
    try {
      setState({ ...stateRef.current, loadingState: true });
      const res = await Axios.get(`${envData.apiUrl}/feed`);
      const { data } = res.data;
      setState({
        ...stateRef.current,
        posts: data,
        loadingState: false,
        last_loaded_id: data.length > 0 ? data[data.length - 1].id : 0,
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const checkForNewFeeds = async () => {
    if (stateRef.current.last_loaded_id === 0) {
      window.removeEventListener("scroll", checkForNewFeeds);
      return;
    }
    var maxScroll =
      Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      ) - window.innerHeight;
    if (
      window.scrollY > maxScroll - window.innerHeight / 2 &&
      stateRef.current.loadingState === false
    ) {
      getFeeds();
    } else {
    }
  };
  const onScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight === scrollHeight && stateRef.current.loadingState === false && stateRef.current.last_loaded_id !== 0) {
        getFeeds();
      }
    }
  };
  useEffect(() => {
    getFeeds();
    window.addEventListener("scroll", checkForNewFeeds);
    return () => {
      window.removeEventListener("scroll", checkForNewFeeds);
    };
  }, []);
  const goToPost = (id: any) => {
    history.push(`/post/${id}`);
  };
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (userData?.signal?.type === "App\\Notifications\\PostFinishedEncoding" && userData?.signal?.post?.postAttachments?.length > 0)
    {
      modalData.clearToasts();
      const publishDate = userData?.signal?.post?.publish_date;
      const postId = userData.signal.post.id;
      if (!publishDate) {
        setState({
          ...stateRef.current,
          posts: [userData.signal.post, ...stateRef.current.posts],
          // posts: stateRef.current.posts.map((x:any) => x.id === postId ? userData.signal.post : x )
        });
      }
      if (publishDate) {
        setTimeout(() => {
          modalData.pushToast(
              "success",
              <>
                Post scheduled for{" "}
                {moment(publishDate).format("YYYY") ===
                moment(new Date()).format("YYYY")
                    ? moment(publishDate).format("ddd, D MMM, HH:mm")
                    : moment(publishDate).format("ddd, D MMM YYYY, HH:mm")}{" "}
                <span className={"toast-link"} onClick={() => goToPost(postId)}>
              View post
            </span>
              </>
          );
        },100)
      } else {
        setTimeout(() => {
          modalData.pushToast(
              "success",
              <>
                Your Post was uploaded.{" "}
                <span
                    className={"toast-link"}
                    onClick={() => goToPost(userData.signal.post.id)}
                >
              View
            </span>
              </>
          );
        },100)
      }
    }
    if (userData?.signal?.type === "App\\Notifications\\PostPublished")
    {
      modalData.clearToasts();
      setState({
        ...stateRef.current,
        posts: [userData.signal.post, ...stateRef.current.posts],
      });
      modalData.pushToast(
          "success",
          <>
            Your Post was sent.{" "}
            <span
                className={"toast-link"}
                onClick={() => goToPost(userData.signal.post.id)}
            >
              View
            </span>
          </>
      );
    }
    if(userData?.signal?.type === "App\\Notifications\\ReportedPostDeleted" || userData?.signal?.type === "App\\Notifications\\ReportedByMePostDeleted") {
      setState({
        ...stateRef.current,
        posts: stateRef.current.posts.filter((x:any) => x.id !== userData?.signal.post_id)
      });
    }
    userData.clearSignal();
  }, [userData.signal]);

  useEffect(() => {
    if (
      userData.newPost.length !== 0 &&
      window.location.pathname === userData.quickPostLocation
    ) {
      setState({
        ...stateRef.current,
        posts: [userData.newPost, ...stateRef.current.posts],
      });
      userData.newPostFunction([], "");
    }
  }, [userData.newPost]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div
      className={`fl-feed-main fl-feed-main-${userData.currentTheme}`}
      ref={containerRef}
      onScroll={() => isMobile ? onScroll() : ""}
    >
      <span
        className="fl-feed-options-view"
        onClick={() => {
          userData.changeGridView();
        }}
      >
        {userData.gridView ? (
                <>
                  <FontAwesomeIcon icon={faWindowMaximize} />
                  <span className="fl-display-options-span">Full View</span>
                </>
            ) : (
                <>
                  <FontAwesomeIcon icon={faBorderAll} />
                  <span className="fl-display-options-span">Grid View</span>
                </>
            )}
      </span>
      <div className="fl-container fl-feed-container">
        <div className="fl-d-flex fl-feed-row">
          <div className="fl-col-4 fl-feed-column fl-feed-column-sticky fl-feed-tablet-hide">
            {userData?.user?.email_verified_at === null ? (
              <VerificationBox />
            ) : (
              ""
            )}
            <UnapprovedBox />
            <PostOptions parentRef={containerRef.current} />
            <MyProfile stateRef={stateRef} setStatePost={setState} />
            {/*<Suggestions />*/}
            <SupportBox side="right" />
          </div>
          <div className="fl-col-8 fl-feed-column fl-feed-tablet-prio">
            {!isMobile && <WritePost setStatePost={setState} setStatePostRef={stateRef}  parentRef={containerRef.current} />}
            <div
              className={`fl-post-display-${
                userData.gridView ? "grid" : "full"
              }`}
            >
              {animation ? (
                  <div className={"fl-loader-animation fl-purchased-empty"}>
                    <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                  </div>
              ) : (
                <>
                  {state.posts.map((x: postInterface, y: number) => {
                    const now = new Date();
                    let isAfterPublishDate: boolean = moment(
                      x.publish_date
                    ).isAfter(moment(now.toUTCString()));

                    return (
                      (userData.gridView &&
                        !(x.postImages.length + x.postVideos.length)) ||
                      (!isAfterPublishDate && (
                        <Post
                          key={y}
                          data={x}
                          oldState={state}
                          updatePost={setState}
                          previousLocation="/feed"
                          getFeeds={getAfterBlock}
                        />
                      ))
                    );
                  })}
                </>
              )}
            </div>
            {state.last_loaded_id === 0 ? (
              <SimpleCard>
                <div className="fl-feed-no-posts">
                  <div className="fl-feed-no-posts-icon">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div
                    className={`fl-feed-no-posts-text fl-feed-no-posts-text-${userData.currentTheme}`}
                  >
                    You're all caught up
                  </div>
                </div>
              </SimpleCard>
            ) : (
              ""
            )}
          </div>
          {/* <div className="fl-col-2 fl-feed-column fl-feed-column-sticky">
                        <SupportBox/>
                    </div> */}
        </div>
      </div>
    </div>
  );
};
