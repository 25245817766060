import React, { FC } from 'react'
import { useUserContext } from '../context/UserContext';
import './Cookies.css';

// ASSETS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const Cookies:FC<any> = (props) => {

    const userData = useUserContext();
    
    return (
        <div className={`fl-cookies-main fl-cookies-main-${userData.currentTheme}`}>
            <div className="fl-container fl-cookies-inner">
                <div className={`fl-cookies-title fl-cookies-title-${userData.currentTheme}`}>Cookie Policy</div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        Our use of cookies
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>Welcome to <a href="https://www.fluffa.com">www.fluffa.com</a> (Site), a project by NebNine LTD.</div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>Here you will find information on what cookies may be set when you visit the Site and how to reject or delete those cookies. </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}><u>By continuing to use this site you are agreeing to our use of cookies in the manner described in this policy.</u> </div>
                </div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        What are cookies?
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>A cookie is a small data file that is placed on your computer or other device to allow a website to recognise you as a user when you return to the website using the same computer and web browser, either for the duration of your visit (using a ‘session cookie’) or for repeat visits (a ‘persistent cookie’).  Other similar files work in the same way and we use the word ‘cookie’ in this policy to refer to all files that collect information in this way.</div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>Please note that some cookies used on our Site may be set by third parties, e.g. google analytics over which we have no control.  The types of cookies we use are detailed below:
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Session Cookies</b> – These types of cookies are created temporarily, allowing us to link to your actions during a browser session. A browser session starts when you open your browser window and ends when you close it.</div>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Persistent Cookies</b> – These types of cookies remain on your device for a specified period of time, becoming active each time you visit our Site.</div>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Strictly Necessary Cookies</b> – These cookies are essential for using our Site, enabling the services that you have specifically requested when consenting to using our Site. These cookies will be used for basic functions such as remembering your previous actions when navigating our Site during a single session.</div>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Analytical Cookies</b> – These cookies collect aggregated information about how our visitors use our website. This enables us to improve the functionality of our Sites and monitor its efficiency.</div>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Functionality Cookies</b> – These cookies allow us to remember your preferences to provide a more personal browsing experience. These cookies may also be used to deliver specific website functions. By using our website, you agree that we can place these types of cookies on your device.</div>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Targeting and Advertising Cookies</b> - These cookies help us to deliver personalised marketing content to you based on how you use our Site and to operate, serve and track ads.</div>      
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>For detailed information on the cookies we use and the purposes for which we use them see the table at the end of this Cookie Policy.</div>
                </div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        How to control and delete cookies
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>When you accessed the Site, you were presented with a message that notified you of our use of cookies.  We had to use a cookie to present you with that notice (you can see details of this cookie under ‘Cookie Banner’ in the table below).  If you continue to use the Site, more cookies and other tracking technologies will be placed on your computer as described in this Cookie Policy in order to enhance your user experience whilst on the Site (as relevant). </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies or in the Cookie Banner when you visit our Site.  However, if you refuse all cookies you may be unable to access certain parts of the Site.  Also if you refuse essential cookies then certain parts of our Site may not operate properly or at all.  Unless you have adjusted your browser settings so that it will refuse cookies or you refuse cookies in the pop up box when you visit our Site, our system will issue cookies when you access the Site</div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>You may wish to visit <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a> which contains comprehensive information on how to do this on a wide variety of browsers.  Alternatively, the web browser you use may allow you to change your cookie preferences, including to delete and disable cookies. For more information please consult the help section of your chosen web browser(s).</div>
                </div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        Main cookies used on our site
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>These cookies are needed for our website to operate and to provide other essential functions.
                        <div className="fl-cookies-custom-spacing"></div>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>Cookie name:</b> Google Analytics</div><br/>
                        <div className={`fl-cookies-sub-row fl-cookies-sub-row-${userData.currentTheme}`}><b>More information:</b>
                            <div className="fl-cookies-custom-spacing"></div>
                            This cookie enables us to:
                            <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>
                                <div className={`fl-cookies-sub-row fl-cookies-sub-row-second-level fl-cookies-sub-row-${userData.currentTheme}`}>estimate our audience size and usage patterns; and</div>
                                <div className={`fl-cookies-sub-row fl-cookies-sub-row-second-level fl-cookies-sub-row-${userData.currentTheme}`}>anonymously track actions around the Site, such as the number of people who order a product</div>
                                <div className="fl-cookies-custom-spacing"></div>
                                <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>This is a persistent cookie, which remains on your device after your browsing session has ended. This cookie expires 2 years after it was first set. </div>
			                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>This Site uses Google Analytics; a web analytics service provided by Google, Inc. Google Analytics sets a cookie in order to evaluate your use of this Site and compile reports for us on website activity. This cookie does not collect information that identifies an individual. For how to reject or delete these cookies see <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        Email Tracking 
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>Some of the emails we send to you may contain a ‘web beacon pixel’ or tracked links which allows us to identify when you have opened the email and to verify which links contained in the email you have accessed.  We use this information to determine which parts of our emails are of most interest to you.</div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>You can delete the pixel by deleting the email.  If you do not wish to download the pixel to your computer or other device, you can ensure this by selecting to receive emails from us in plain text rather than HTML.</div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>Alternatively, you can unsubscribe from our mailing list by contacting us at <a href="mailto: support@fluffa.com">support@fluffa.com</a></div>
                </div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        Changes
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>We may make changes to this Cookie Policy at any time by sending you an e-mail with the modified terms or by posting a copy of them on the Site.  Any changes will take effect 7 days after the date of our email or the date on which we post the modified terms on the Site, whichever is the earlier.  Your continued use of the Site after that period expires means that you agree to be bound by the modified policy. </div>
                </div>
                <div className="fl-cookies-section">
                    <div className="fl-cookies-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        Contact us
                    </div>
                    <div className={`fl-cookies-row fl-cookies-row-${userData.currentTheme}`}>If you have any questions or concerns about cookies or your privacy when using this site, please contact us at <a href="mailto: support@fluffa.com">support@fluffa.com</a></div>
                </div>
            </div>
        </div>
    )
}