import React, {FC, Fragment, useEffect, useState} from 'react'
import {useUserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SimpleCard} from "../../components/SimpleCard";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {useModalContext} from "../../context/ModalContext";
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {Button} from "../../components/Button";
import ReactLoading from "react-loading";

export const MessageDropdown:FC<any> = (props) => {

    const envData = useEnvContext()
    const userData = useUserContext()
    const modalData = useModalContext()
    const [dropdown,setDropdown] = useState(false)

    const dropdownRight = [
        {
            text: `${props.like.status ? "Unlike" : "Like"} message`,
            show: ["left"],
            action: "like"
        },
        {
            text: "Report Message",
            show: ["left"],
            action: "report"
        },
        {
            text: "Copy Message",
            show: props?.data?.attachments?.length > 0 && props?.data?.body?.length === 0 ? [] : ["right","left"],
            action: "copy"
        },
        {
            text: `Remove`,
            show: props?.data?.attachments?.length > 0 && props?.checkWhoSend ? ["right"] : [],
            action: "remove"
        }
    ]

    const copyMessage = async (body:any) => {
        modalData.clearToasts()
        setDropdown(false)
        await navigator.clipboard.writeText(`${body}`);
        modalData.pushToast("success", "Message copied to clipboard");
    }

    const likeMessage = async (conversation: number, message: number) => {
        const timeOut = props.like.status ? 0 : 1000;
        props.setLike({...props.like, animation:true})
        setTimeout(async () => {
            try {
                await Axios.get(`${envData.apiUrl}/conversations/${conversation}/messages/${message}/like`
                );
                props.setLike({
                    ...props.like,
                    status: !props.like.status,
                    animation: false
                });
                setDropdown(false)
            } catch (e) {
                modalData.pushToast("error", "Something went wrong!");
            }
        }, timeOut);
    };

    const processReport = async (message: number, conversation:number, reason: any) => {
        modalData.close();
        try {
            await Axios.post(`${envData.apiUrl}/conversations/${conversation}/messages/${message}/report`, {
                reason,
            });
            props.setReportedStatus(true)
        } catch (e) {
            modalData.pushToast("error", "You've already reported this message. It's now being checked by the review team.");
        }
    };

    const reportMessage = async (message: number,conversation: number) => {
        setDropdown(false)
        const res = await Axios(`${envData.apiUrl}/posts/reports/reasons`);
        const reasons = Object.keys(res.data.reasons).map((x) => ({
            key: x,
            value: res.data.reasons[x],
        }));

        const Reason = () => {
            const [reasonState, setReasonState] = useState("");
            const keyboardEvent = (event: KeyboardEvent) => {
                if(event.code === "Escape") {
                    modalData.close()
                }
            }
            useEffect(() => {
                document.addEventListener('keydown', keyboardEvent);
                return function cleanup() {
                    document.removeEventListener('keydown', keyboardEvent);
                }
            }, [keyboardEvent]);

            return (
                <Fragment >
                    <div className="fl-modal-title">
                        <div className="fl-modal-title-text">Report message</div>
                    </div>
                    <div className="fl-modal-description">
                        <div className="fl-modal-description-row">What's the issue?</div>
                        <div>
                            {reasons.map((x, y) => (
                                <div
                                    onClick={() => setReasonState(x.key)}
                                    className={`fl-modal-report-reason fl-modal-report-reason-${
                                        userData.currentTheme
                                    } ${
                                        reasonState === x.key ? "fl-modal-report-reason-active" : ""
                                    }`}
                                    key={y}
                                >
                                    {x.value}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                        <Button onClick={() => modalData.close()}>Cancel</Button>
                        <Button
                            type="normal"
                            onClick={() => processReport(message,conversation, reasonState)}
                            disabled={
                                reasonState
                                    ? false
                                    : true
                            }
                        >
                            Submit
                        </Button>
                    </div>
                </Fragment>
            );
        };

        await modalData.push(() => <Reason />);
    };

    const modalErrorDeleteMessage = async () => {
        await modalData.push({
            title: "Can't unsend message",
            description: ["You're unable to remove this message as the recipient has already purchased it."],
            buttons: [
                () => (
                    <div className="fl-d-flex fl-justify-flex-end">
                        <Button type="normal" onClick={async () => await modalData.closeAll()}>
                            Dismiss
                        </Button>
                    </div>
                ),
            ],
        });
    }

    const modalMediaDelete = async (conversation_id:number,message_id:number) => {

        const title = props.data?.attachments[0]?.type === "audio" ? "Unsend voice clip" : "Unsend message"
        const description = props.data.price === "0" ? `Are you sure you want to remove this message? The recipient will no longer be able to ${props.data?.attachments[0]?.type === "audio" ? "play" : "view"} it.` : props.data.attachments && props.data.body ? "showpassword" : "Are you sure you want to remove this message? The recipient will no longer be able to unlock it."
        const textButton = props.data.attachments && props.data.body ? "Yes" : "Remove"
        modalData.push(() => <UnsendModal title={title} description={description} setState={props.setState} state={props.state} conversation_id={conversation_id} message_id={message_id} textButton={textButton} />)

    };

    const actionDropdown = (action:string) => {
        if(action === "copy") {
            copyMessage(props.data.body)
        } else if(action === "like") {
            likeMessage(props.data.conversation_id,props.data.id)
        } else if (action === "report") {
            reportMessage(props.data.id,props.data.conversation_id)
        } else if (action === "remove") {
            if(props.data.unlocked) {
                modalErrorDeleteMessage()
            } else {
                modalMediaDelete(props.data.conversation_id,props.data.id)
            }
        }
    }

    return (
        <div className={`fl-inbox-single-message-dropdown`} tabIndex={1} onBlur={() => setDropdown(false)}>
            <div className="fl-direct-message-option-dropdown" onClick={() => setDropdown(!dropdown)}>
                <FontAwesomeIcon icon={faEllipsisH} />
            </div>
            {dropdown ? (
                <div className={`fl-post-head-actions-dropdown-${userData.currentTheme} fl-inbox-type-${props.direction}-${props.checkType()} fl-message-option-position-${props.direction}`}>
                    <SimpleCard className="fl-post-head-actions-dropdown-inner fl-message-option">
                        {props.direction === "right" ?
                            (dropdownRight.filter((x:any) => x.show.includes("right")).map((x:any,y:number) =>
                                <div className="fl-post-head-actions-dropdown-option" key={y} onClick={() => actionDropdown(x.action)}>
                                    {x.text}
                                </div>
                            ))
                        :""
                        }
                        {props.direction === "left" ?
                            (dropdownRight.filter((x:any) => x.show.includes("left")).map((x:any,y:number) =>
                                <div className="fl-post-head-actions-dropdown-option" key={y} onClick={() => actionDropdown(x.action)}>
                                    {x.text}
                                </div>
                            ))
                            :""
                        }
                    </SimpleCard>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export const UnsendModal: FC<any> = ({title,description,state,setState,conversation_id,message_id,textButton}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const [animationDelete, setAnimationDelete] = useState(false)
    const confirmDelete = async (conversation_id:number,message_id:number) => {
        setAnimationDelete(true)
        try {
            await Axios.delete(
                `${envData.apiUrl}/conversations/${conversation_id}/messages/${message_id}/unsend`
            );
            setState({
                ...state,
                messages: state.messages.filter((x: any) => x.id !== message_id),
            });
            modalData.close()
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimationDelete(false)
    };

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{title}</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                {description}
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" onClick={() => confirmDelete(conversation_id,message_id)} disabled={animationDelete}>
                    {textButton}
                    {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/> : ""}
                </Button>
            </div>
        </React.Fragment>
    );
}