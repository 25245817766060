import React, {FC,useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import { useUserContext } from "../context/UserContext";
import validator from "validator";
import { useModalContext } from "../context/ModalContext";
import "./Register.css";
import { TwitterIcon } from "react-line-awesome";
// ASSETS
import imageSourceLight from "../assets/images/9.jpg";
import imageSourceDark from "../assets/images/10.jpg";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { MInput } from "../components/MInput";
import { additionalEmailCheck } from "./Forgot";
import { twitterLogin } from "./Login";
import ReactLoading from "react-loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

// TYPE
type registerStateType = {
    name: string;
    handle: string;
    email: string;
    password: string;
    creator_referral_link_id: string
};

export const Register: FC<any> = (props) => {
    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    const history = useHistory();

    const [state, setState] = useState<registerStateType>({
        name: "",
        handle: "",
        email: "",
        password: "",
        creator_referral_link_id: localStorage.getItem("referral_token") || ""
    });

    const [isTaken, setIsTaken] = useState({
        email: false,
        handle: false,
    });

    const [proccessing, setProccessing] = useState({login: false, twitter: false})

    const [forceErrors, setForceErrors] = useState(false);

    const disableButton = () => {
        if(state.name.length === 0 || state.handle.length === 0 || state.email.length === 0 || state.password.length === 0 || isTaken.handle || isTaken.email || state.handle.match(/[^a-zA-Z0-9_]+/)) {
            return true
        } else {
            return false
        }
    }

    const processRegister = async () => {
        setForceErrors(false);
        setProccessing({...proccessing, login: true});
        const errorsArray = [];
        try {
            await Axios.post(`${envData.apiUrl}/auth/register`, state);
            const login = await Axios.post(`${envData.apiUrl}/auth/login`, {
                email: state.email , password: state.password
            });
            const { access_token } = login.data;
           if(state.creator_referral_link_id !== "") {
               localStorage.removeItem("referral_token")
           }
            userData.setToken(access_token);
            await userData.checkAuth();
            history.push("/feed");
            
        } catch (e) {
            if(e?.response?.data?.errors?.email) {
                setIsTaken({...isTaken, email:true})
            } else if(e?.response?.data?.errors?.handle) {
                setIsTaken({...isTaken, handle:true})
            }

            setProccessing({...proccessing, login: false});
            if (
                Object.keys(state)
                    //@ts-ignore
                    .map((x: string) => state[x])
                    .filter((x: string) => x.trim().length === 0).length
            )
                errorsArray.push("Please fill in all fields.");

            if (!/^[a-zA-Z0-9]{4,}$/.test(state.handle))
                errorsArray.push(
                    "Username must contain minimum 4 characters, uppercase, lowercase letters and numbers only."
                );

            if (!validator.isEmail(state.email))
                errorsArray.push("Not a valid email format.");

            if (
                !validator.isStrongPassword(state.password, {
                    minLength: 8,
                    minLowercase: 1,
                    minUppercase: 1,
                    minNumbers: 1,
                    minSymbols: 0,
                })
            )
                errorsArray.push("Not a valid password format.");

            if (errorsArray.length) {
                setForceErrors(true);
                //modalData.pushToast("error", errorsArray[0] || "Something went wrong!");
                return;
            }
            setForceErrors(true);
            modalData.pushToast(
                "error",
                e.response.data.errors[Object.keys(e.response.data.errors)[0]] === "The email must be a valid email address." ? "Please enter a valid email address." : e.response.data.errors[Object.keys(e.response.data.errors)[0]] ||
                "Something went wrong!"
            );
        }
    };
    // const isNotTaken = async (input: string, isHandle: boolean) => {
    //     axios.get(envData.apiUrl + `/auth/register/available?email=${state.email}&handle=${state.handle}`).then((response) => {
    //         if(response.data.result === "There is no user with those credentials.") {
    //             setIsTaken({email: false, handle: false});
    //         }
    //     }).catch(err => {
    //         setForceErrors(true);
    //         let errorResult = err.response.data.errors;
    //         if('handle' in errorResult || 'email' in errorResult) {
    //             let newValue = {handle: 'handle' in errorResult ? true : false, email: 'email' in errorResult ? true : false}
    //             setIsTaken(newValue);
    //             return ;
    //         }
    //         setForceErrors(true);
    //         modalData.pushToast(
    //             "error",
    //             "Something went wrong!"
    //         );
    //     });
    // };

    const formatHandle = (handle:string) => {
        return handle.replace(/\s/g, "")
    }

    const [space,setSpace] = useState(false)
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if(e.key === " ") {
            setSpace(true)
        } else if (space && e.key !== "Meta") {
            setSpace(false)
        }
    }

    return (
        <div className="fl-fullscreen-with-footer">
            <div className="fl-fullscreen-with-footer-inner fl-container">
                <div className="fl-fullscreen-with-footer-bg">
                    <div className="fl-fullscreen-with-footer-circle-orange"></div>
                    <div className="fl-fullscreen-with-footer-circle-pink"></div>
                </div>
                <div className="fl-fullscreen-with-footer-content">
                    <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
                        <div
                            className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}
                        >
                            Sign up for Fluffa
                        </div>
                        <MInput
                            className={"fl-settings-mt-30"}
                            forceErrors={forceErrors}
                            validator={[
                                {
                                    check: (v) => !validator.isEmpty(v),
                                    message: "Enter a name",
                                },
                                // {check: (v) => validator.isLength(v, { min: 3 }), message: "Name must be at least 3 characters long"},
                            ]}
                            label="Name"
                            value={state.name}
                            setValue={(name) => {
                                setState({ ...state, name });
                            }}
                        />
                        {/*<div onBlur={() => isNotTaken(state.handle, true)}  className={"fl-settings-mt-30"}>*/}
                        <div className={"fl-settings-mt-30"} onFocus={() => isTaken.handle ? setIsTaken({...isTaken, handle:false}) : ""}>
                        <MInput
                                maxLength={20}
                                forceErrors={forceErrors}
                                validator={[
                                    {
                                        check: (v) => !validator.isEmpty(v),
                                        message: "Choose a username",
                                    },
                                    {
                                        check: (v) => !isTaken.handle,
                                        message: "That username is taken. Try another",
                                    },
                                    {
                                        check: (v) => validator.isLength(v, { min: 4, max: 20 }),
                                        message:
                                            "Sorry, your username must be between 4 and 20 characters long.",
                                    },
                                    {
                                        check: (v) => !/[^a-zA-Z0-9_]+/.test(v),
                                        message: "Your username can only contain letters, numbers and '_'",
                                    }
                                ]}
                                label="Username"
                                value={formatHandle(state.handle)}
                                setValue={(handle) => {
                                    setState({ ...state, handle })
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            {space ?
                                <div className="fl-minput-validator fl-space-validator">
                                    <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                                    Your username can't contain spaces
                                </div> : ""
                            }
                        </div>
                        {/*<div onBlur={ () => isNotTaken(state.email, false)}>*/}
                        {/*<div onBlur={ () => isNotTaken(state.email, false)}>*/}
                        <div onFocus={() => isTaken.email ? setIsTaken({...isTaken, email:false}) : ""}>
                            <MInput
                                className={`fl-settings-mt-30 fl-validate-mail-${state?.email?.split(".")[1]?.length === 2 && state.email.slice(-2) === "co" ? "delete" : "default"}`}
                                forceErrors={forceErrors}
                                validator={[
                                    {
                                        check: (v) => !validator.isEmpty(v),
                                        message: "Enter an email address",
                                    },
                                    {
                                        check: (v) => validator.isEmail(v),
                                        message: "Enter a valid email address",
                                    },
                                    {
                                        check: (v) => additionalEmailCheck(v),
                                        message: "Enter a valid email address",
                                    },
                                    {
                                        check: (v) => !isTaken.email,
                                        message: "That email address is taken. Try another",
                                    },
                                ]}
                                label="Email"
                                value={state.email}
                                setValue={(email) => {
                                    setState({ ...state, email });
                                }}
                            />
                        </div>
                        <MInput
                            className={"fl-settings-mt-30"}
                            forceErrors={forceErrors}
                            type="password"
                            visiblepassword={true}
                            validator={[
                                {
                                    check: (v) => !validator.isEmpty(v),
                                    message: "Enter a password",
                                },
                                {
                                    check: (v) =>
                                        validator.isStrongPassword(v, {
                                            minLength: 8,
                                            minLowercase: 1,
                                            minNumbers: 1,
                                            minUppercase: 1,
                                            minSymbols: 0,
                                        }),
                                    message:
                                        "Must be at least 8 characters long, with one uppercase letter, one lowercase letter and a number.",
                                },
                            ]}
                            label="Password"
                            value={state.password}
                            setValue={(password) => {
                                setState({ ...state, password });
                            }}
                        />
                        <Button
                            style={{ margin: "20px 0px", width: "calc(50% - 5px)", position: 'relative'}}
                            type="normal"
                            onClick={processRegister}
                            disabled={proccessing.login || disableButton()}
                        >
                            SIGN UP NOW
                            {proccessing.login && <ReactLoading  className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>}
                        </Button>
                        <div className="fl-fullscreen-with-footer-content-link-text">
                            By signing up you agree to our{" "}
                            <Link
                                className="fl-fullscreen-with-footer-content-link underline"
                                to="/terms"
                            >
                                Terms
                            </Link>{" "}
                            and{" "}
                            <Link
                                className="fl-fullscreen-with-footer-content-link underline"
                                to="/privacy"
                            >
                                Privacy Policy
                            </Link>
                        </div>
                        <div className="fl-fullscreen-with-footer-content-link-text">
                            Have an account?{" "}
                            <Link
                                className="fl-fullscreen-with-footer-content-link underline"
                                to="/login"
                            >
                                Log in
                            </Link>
                        </div>
                        <hr className='fl-login-button-break' />
                        <Button
                        className="fl-twitter-button"
                        style={{ margin: "10px 0px", width: "calc(50% - 5px)", position: 'relative' }}
                        type="normal"
                        onClick={() => {setProccessing({...proccessing, twitter: true}); twitterLogin(setForceErrors, forceErrors, envData)}}
                        >
                            <TwitterIcon style={{transform: 'scale(1.5)', marginRight: '5px'}}/>
                            SIGN UP WITH TWITTER
                            {proccessing.twitter &&
                            <span style={{position: 'absolute', right: '4px'}}>
                                <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> 
                            </span> }
                        </Button>
                    </div>
                    <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-fulscreen-with-footer-content-part-image">
                        {userData.currentTheme === "light" ? (
                            <img
                                className="fl-fullscreen-with-footer-content-image"
                                src={imageSourceLight}
                                alt="Register"
                            />
                        ) : (
                            ""
                        )}
                        {userData.currentTheme === "dark" ? (
                            <img
                                className="fl-fullscreen-with-footer-content-image"
                                src={imageSourceDark}
                                alt="Register"
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

