import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useUserContext } from "../context/UserContext";
import { SimpleCard } from "./SimpleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import VideoPlayer from "./video/VideoPlayer"
import {
  faBan,
  faClone,
  faExclamationCircle,
  faSmile,
  faStar as faStarSolid,
  faClock,
  faImage,
  faThumbtack,
  faUpload,
  faShare,
  faEllipsisH,
  faRocket,
  faInfoCircle,
  faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons";
import ScheduleIcon from "../assets/images/scheduled.svg";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faCommentAlt as faCommentAltSolid } from "@fortawesome/free-solid-svg-icons";
import "./Post.css";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faDollarSign,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import postInterface from "../interfaces/PostInterface";
import commentInterface from "../interfaces/CommentInterface";
import moment from "moment";
import { ProfileHolder } from "./ProfileHolder";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { SimpleComment } from "./SimpleComment";
import { Button } from "./Button";
import { useModalContext } from "../context/ModalContext";
import Picker from "emoji-picker-react";
import TipModal from "./TipModal/TipModal";
import PostLock from "./PostLock/PostLock";
import ReactTooltip from "react-tooltip";
import { DatePickerScheduled } from "../components/DatePickerScheduled";
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import { Textarea } from "./Textarea";
import greyLogo from "../assets/images/grey_logo.png";
import { GoToPostModal } from "./GoToPostModal";
import PostBody from "./PostBody";
import { CheckTime } from "./HandleTime/CheckTime";
import ReactLoading from "react-loading";
import {Tagging} from "./Tagging";
import VideoIconWhite from "../assets/images/videoWhite.svg";
import padLockGrey from "../assets/images/padlockGrey.svg";
export const Post: FC<{
  data: postInterface;
  previousLocation?: string;
  updatePost?: any;
  oldState?: any;
  refetchData?: () => void;
  subscribe?: any;
  getFeeds?: any;
  typeFavorite?:any;
}> = (props) => {
  const [state, setState] = useState<{
    pinned: boolean;
    favorited: boolean;
    dropdown: boolean;
    statusDropdown: boolean;
    likeOffset: number;
    commentQuote: commentInterface | null;
    currentComment: string;
    replyComment:string;
    currentImage: number;
    like: boolean;
    comments: Array<commentInterface>;
    commentsMax: number;
    total_comments: number;
    commenting: boolean;
    replying: boolean;
    deleted: boolean;
    reported: boolean;
  }>({
    currentImage: 0,
    like: props.data.meta.is_liked || false,
    comments: [],
    commentsMax: 3,
    total_comments: props.data.meta.total_comments,
    commenting: false,
    replying: false,
    currentComment: "",
    commentQuote: null,
    likeOffset: 0,
    dropdown: false,
    favorited: props.data.meta.is_favorited,
    pinned: props.data.pinned,
    deleted: false,
    statusDropdown: false,
    reported: props.data.meta.is_reported,
    replyComment:""
  });

  const [flushComment, setFlushComment] = useState(-1);

  const [emojiState, setEmojiState] = useState({
    active: false,
  });
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [scheduleDate, setScheduleDate] = useState<string>("");
  const [scheduleTime, setScheduleTime] = useState<string>("");
  const [viewReported, setViewReported] = useState<boolean>(false);
  const [previewReported, setPreviewReported] = useState<boolean>(true);
  const [hideReported, setHideReported] = useState<boolean>(true);
  const [focusedElement, setFocusedElement] = useState<object|null>(null);

  // CONTEXT
  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const history = useHistory();

  // useEffect(() => {
  //   if (viewReported && preview.active) {
  //     setTimeout(() => setViewReported(false), 2500);
  //   }
  // }, [viewReported, preview.active]);

  useEffect(() => {
    if (modalData?.state === "hide") {
      setShowScheduleModal(false);
    }
  }, [modalData]);



  // Navigate between images/videos
  const prevPost = () => {
    if (state.currentImage > 0) {
      setState({ ...state, currentImage: state.currentImage - 1 });
    } else {
      let lastImage =
          props?.data?.postAttachments?.length - 1;
      setState({ ...state, currentImage: lastImage });
    }
  };

  const nextPost = () => {
    if (
      state.currentImage <
      props?.data?.postAttachments?.length - 1
    ) {
      setState({ ...state, currentImage: state.currentImage + 1 });
    } else {
      setState({ ...state, currentImage: 0 });
    }
  };

  const commentRef = useRef<HTMLDivElement>(null);
  const [showComments, setShowComments] = useState<boolean>(false);
  // @ts-ignore
  const processComments = async (e?: any, postId: number,form = false) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(
        `${window.location.origin.toString()}/post/${postId}/`,
        "_blank"
      );
    } else {
      setShowComments(!showComments);
      const res = await Axios.get(`${envData.apiUrl}/posts/${postId}/comments`);
      setState({ ...state, comments: !showComments ? res.data.data : [], commenting: form ? !state.commenting : state.commenting });
    }
  };

  const [restrictedWords, setRestrictedWords] = useState<any>([]);
  const [animation,setAnimation] = useState(false)
  // Post new comment
  const postComment = async () => {
    setAnimation(true)
    if (state.commentQuote === null) {
      try {
        const res = await Axios.post(
          `${envData.apiUrl}/posts/${props.data.id}/comments`,
          { text: state.currentComment }
        );
        setState({
          ...state,
          comments: [res.data.data, ...state.comments],
          currentComment: "",
          commentQuote: null,
          commenting: false,
          total_comments: state.total_comments + 1,
        });
        props.updatePost({
          ...props.oldState,
          posts: props.oldState.posts.map((x: any) =>
              x.id === props.data.id ? { ...x, meta: {...x.meta, total_comments: x.meta.total_comments + 1, comments:  x.meta.comments + 1} } : x
          )
        });
        setAnimation(false)
      } catch (e) {
        setAnimation(false)
        if (e?.response?.data?.errors?.restricted_words?.length > 0) {
          setRestrictedWords(e?.response?.data?.errors?.restricted_words);
        }
      }
    } else {
      try {
        const res = await Axios.post(
            `${envData.apiUrl}/posts/${props.data.id}/comments/${state.commentQuote.id}`,
            { text: state.replyComment }
          );
        setState({
          ...state,
          replyComment: "",
          commentQuote: null,
          commenting: false,
          replying: false,
          total_comments: state.total_comments + 1,
          comments: state.comments.map((x: any) =>
              x.id === state?.commentQuote?.id ? { ...x, meta: {...x.meta, comments: x.meta.comments + 1}, replies: [res.data.data, ...x.replies] } : x
          )
        });
        props.updatePost({
          ...props.oldState,
          posts: props.oldState.posts.map((x: any) =>
              x.id === props.data.id ? { ...x, meta: {...x.meta, total_comments: x.meta.total_comments + 1} } : x
          )
        });
        setFlushComment(state.commentQuote.id);
        setAnimation(false)
      } catch (e) {
        setAnimation(false)
        if (e?.response?.data?.errors?.restricted_words?.length > 0) {
          setRestrictedWords(e?.response?.data?.errors?.restricted_words);
        }
      }
    }
    setShowComments(true)
  };

  // Toggle reply box
  const setReplyOnComment = (commentQuote: commentInterface) => {
    setState({ ...state, replying: !state.replying, commentQuote,commenting:false });
  };

  // Like and favorite
  const likePost = async (postId: number) => {
    if (!props.data.meta.is_reported) {
      props.updatePost({
        ...props.oldState,
        posts: props.oldState.posts.map((x: any) =>
            x.id === props.data.id ? { ...x, meta: {...x.meta, is_liked: !x.meta.is_liked, likes:  x.meta.is_liked ? x.meta.likes - 1 : x.meta.likes + 1 } } : x
        )
      });
      try {
        await Axios.post(`${envData.apiUrl}/posts/${postId}/like`, {
          like: !props.data.meta.is_liked,
        });
        if(props.data.userProfile.id === userData.user.id) {
          userData.updateCount(props.data.meta.is_liked ? "dislike" : "like")
        }
      } catch (e) {}
    }
  };

  const restoreFavorite = async (postId:number) => {
    modalData.clearToasts()
    props.updatePost({
      ...props.oldState,
      posts: props.oldState.posts.map((x: any) =>
          x.id === props.data.id ? { ...x, meta: {...x.meta, is_favorited: true} } : x
      )
    });
    try {
      await Axios.post(`${envData.apiUrl}/favorites/post/${postId}`, {
        favorite: true,
      });
      userData.updateCountFavorites("add","texts")
      modalData.pushToast("info", <>Action undone.</>);
    } catch (e) {}
  }
  const favoritePost = async (postId: number) => {
    const text = props?.data?.text?.length
    const files = props?.data?.postAttachments?.length
    const filesPhoto = props?.data?.postAttachments?.filter((x:any) => x.type === "image").length
    const filesVideo = props?.data?.postAttachments?.filter((x:any) => x.type === "video").length

    if(props.previousLocation === "/favorites") {
      props.updatePost({
        ...props.oldState,
        posts: props.oldState.posts.filter((x: any) =>
            x.id !== props.data.id
        )
      });
      modalData.pushToast(
          "success",
          <>
            <div>
              {props.typeFavorite === "all" ? "Post" : props.typeFavorite === "texts" ? "Text" : props.typeFavorite === "videos" ? "Video" : "Photo"} removed from favourites. {" "}
              <span className="fl-notifications__mark" onClick={() => restoreFavorite(postId)}>
                   Undo
              </span>
            </div>
          </>
      );
    }
    if(text > 0 && files === 0) {
      userData.updateCountFavorites(props.data.meta.is_favorited || props.previousLocation === "/favorites" ? "remove" : "add","texts")
    } else if (files > 0 && filesPhoto > 0) {
      userData.updateCountFavorites(props.data.meta.is_favorited || props.previousLocation === "/favorites" ? "remove" : "add","photos")
    } else if (files > 0 && filesVideo > 0) {
      userData.updateCountFavorites(props.data.meta.is_favorited || props.previousLocation === "/favorites" ? "remove" : "add","videos")
    }
    props.updatePost({
      ...props.oldState,
      posts: props.oldState.posts.map((x: any) =>
          x.id === props.data.id ? { ...x, meta: {...x.meta, is_favorited: !x.meta.is_favorited} } : x
      )
    });
    try {
      await Axios.post(`${envData.apiUrl}/favorites/post/${postId}`, {
        favorite: !props.data.meta.is_favorited,
      });
    } catch (e) {}
  };

  const closeDeletedPost = (postId:number) => {
    props.updatePost({
      ...props.oldState,
      posts: props.oldState.posts.map((x: any) =>
          x.id === postId ? { ...x, deleted:true,closed:true } : x
      )
    })
  }

  const blockUser = async (handle: string) => {
    await Axios.post(`${envData.apiUrl}/profile/${handle}/block`);
    modalData.close();
    await props.getFeeds();
  };

  const reportPost = async (e: any, id: number) => {
    e.stopPropagation();

    setState({ ...state, dropdown: false });

    if (!props.data.meta.is_reported) {
      const res = await Axios(`${envData.apiUrl}/posts/reports/reasons`);
      const reasons = Object.keys(res.data.reasons).map((x) => ({
        key: x,
        value: res.data.reasons[x],
      }));

      const Reason = () => {
        const [reasonState, setReasonState] = useState("");
        const [animation, setAnimation] = useState(false)
        const processReport = async (id: number, reason: any) => {
          setAnimation(true)
          try {
            await Axios.post(`${envData.apiUrl}/posts/${id}/report`, {
              reason,
            });
            modalData.pushToast("success", "Post reported");
            props.updatePost({
              ...props.oldState,
              posts: props.oldState.posts.map((x: any) =>
                  x.id === props.data.id ? {...x, meta: {...x.meta, is_reported: !x.meta.is_reported}} : x
              )
            });
            setState({...state, dropdown: false});
            modalData.close();
          } catch (e) {
            modalData.pushToast(
                "error",
                "You've already reported this post. It's now being reviewed by our Compliance Team."
            );
          }
          setAnimation(false)
        };
        return (
            <Fragment>
              <div className="fl-modal-title">
                <div className="fl-modal-title-text">Report post</div>
                <div className="fl-modal-close-button">
                  <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => modalData.close()}
                  />
                </div>
              </div>
              <div className="fl-modal-description">
                <div className="fl-modal-description-row">What's the issue?</div>
                <div>
                  {reasons.map((x, y) => (
                      <div
                          onClick={() => setReasonState(x.key)}
                          className={`fl-modal-report-reason fl-modal-report-reason-${
                              userData.currentTheme
                          } ${
                              reasonState === x.key ? "fl-modal-report-reason-active" : ""
                          }`}
                          key={y}
                      >
                        {x.value}
                      </div>
                  ))}
                </div>
              </div>
              <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={() => modalData.close()}>Cancel</Button>
                <Button
                    type="normal"
                    onClick={() => processReport(id, reasonState)}
                    disabled={reasonState && !animation ? false : true}
                >
                  Submit
                  {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"}
                                             height={20} width={20}/> : ""}
                </Button>
              </div>
            </Fragment>
        );
      };

      await modalData.push(() => <Reason/>);
    } else {
      modalData.pushToast(
          "error",
          "You've already reported this post. It's now being reviewed by our Compliance Team."
      );

      setHideReported(true);
      setViewReported(false);
    }
  };

  const emojiRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const trackClick = (e: MouseEvent) => {
      if (emojiRef.current && e.target !== null) {
        //@ts-ignore
        if (
          e.target !== emojiRef.current &&
          //@ts-ignore
          !emojiRef.current.contains(e.target)
        ) {
          setEmojiState({ active: false });
        }
      }
    };
    window.addEventListener("click", trackClick);
    return () => {
      window.removeEventListener("click", trackClick);
    };
  }, []);

  const copyLink = async (e: any, x: string) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(`${window.location.origin}${x}`);
      modalData.pushToast("success", "Link copied to clipboard");
      setState({ ...state, dropdown: false });
    } catch (e) {
      const copyEl = document.createElement("textarea");
      copyEl.value = `${window.location.origin}${x}`;
      document.body.appendChild(copyEl);
      copyEl.select();
      document.execCommand("copy");
      document.body.removeChild(copyEl);
      setState({ ...state, dropdown: false });
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const checkAuthStatus = () => {
    if (userData.authStatus === "AUTHENTICATED") {
      return true;
    } else {
      return false;
    }
  };

  const shouldUserSeeThePost = () => {
    return (
      props.data.type !== "ppv" ||
      props.data.bought ||
      userData.user.id === props.data.userProfile.id
    );
  };

  const openPinModal = async (e: any) => {
    e.stopPropagation();
    setState({ ...state, dropdown: false });
    modalData.push(() => (<PinUnpinModal data={props.data} state={props.oldState} setState={props.updatePost}/>))
  };

  const checkDisabledPost = () => {
    if (props.data.state === "encoding") {
      return true
    } else if (props.data.userProfile.id !== userData.user.id) {
      if (!props.data.bought && props.data.type === "ppv") {
        return true;
      }
    }
  };

  const handleShowScheduleModal = (e:any) => {
    e.stopPropagation()
    if(state.dropdown) {
      setState({ ...state, dropdown: false })
    }
    let formatedDate = moment(props.data.publish_date).format("DD/MM/YYYY");
    let formatedTime = moment(props.data.publish_date).format("hh:mm");

    setScheduleDate(formatedDate);
    setScheduleTime(formatedTime);
    setShowScheduleModal(true);
  };

  const handleScheduleDate = async (
    x: string | Date,
    y?: string,
    publishNow?: boolean
  ) => {
    modalData.clearToasts();
    let publishDate = moment(x).format("YYYY-MM-DD");
    try {
      const res = await Axios.put(`${envData.apiUrl}/posts/${props.data.id}`, {
        publish_date: publishNow ? null : `${publishDate} ${y}`,
      });
      modalData.close();
      setShowScheduleModal(false);
      setScheduleDate("");
      setScheduleTime("");
      setState({ ...state, dropdown: false });
      if (publishNow) {
        modalData.pushToast(
          "success",
          <>
            Your Post was uploaded.{" "}
            <span
              className={"toast-link"}
              onClick={() => goToPost(props.data.id)}
            >
              View
            </span>
          </>
        );
      } else {
        modalData.pushToast("info", `Your Post schedule was updated.`);
      }

      // Update posts through parent state
      let updatedPost = props.oldState.posts.indexOf(
        props.oldState.posts.find((post: any) => post.id === res.data.data.id)
      );
      let newPost = [...props.oldState.posts];
      newPost[updatedPost] = res.data.data;

      props.updatePost({ ...props.oldState, posts: newPost });

      if (props.refetchData) props.refetchData();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const handleScheduledPostNow = async (e:any) => {
    e.stopPropagation()
    let x = new Date();
    let y = moment().format("hh:mm");

    setState({ ...state, dropdown: false });

    await modalData.push({
      title: "Post now",
      description: ["Your Post will be sent now instead."],
      buttons: [
        () => (
          <div className="fl-d-flex fl-justify-flex-end">
            <Button onClick={async () => await modalData.close()}>
              Cancel
            </Button>
            <Button
              type="normal"
              onClick={() => handleScheduleDate(x, y, true)}
            >
              Post now
            </Button>
          </div>
        ),
      ],
    });
  };

  const handleCloseDateModal = () => {
    modalData.close();
    setShowScheduleModal(false);
    setScheduleDate("");
    setScheduleTime("");
  };

  const goToPost = (postId: number) => {
    history.push(`/post/${postId}`);
  };

  const restorePost = async (postId: number) => {
    modalData.clearToasts();
    try {
      await Axios.put(`${envData.apiUrl}/posts/${postId}`, {
        drive: false,
      });
      modalData.pushToast("info", <>Action undone.</>);
      props.updatePost({
        ...props.oldState,
        posts: props.oldState.posts.map((x: any) =>
            x.id === postId ? { ...x, moveDrive:false } : x
        )
      })
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };


  const moveToDriveModal = async (e: any) => {
    e.stopPropagation();
    setState({ ...state, dropdown: false });
    modalData.push(() => <MoveToDriveModal id={props.data.id} updatePost={props.updatePost} oldState={props.oldState} />)
  };

  const openDeleteModal = async (e: any) => {
    e.stopPropagation();
    setState({ ...state, dropdown: false });
    modalData.push(() => <DeleteModalPost postId={props.data.id} updatePost={props.updatePost} oldState={props.oldState} publishDate={props.data.publish_date}/>)
  };

  const openBlockModal = async (e: any) => {
    e.stopPropagation();
    setState({ ...state, dropdown: false });
    await modalData.push({
      title: "Block user",
      description: ["Are you sure you want to block this user?"],
      buttons: [
        () => (
          <div className="fl-d-flex fl-justify-flex-end">
            <Button onClick={async () => await modalData.close()}>
              Cancel
            </Button>
            <Button
              type="normal"
              onClick={() => blockUser(props.data.userProfile.handle)}
            >
              Block
            </Button>
          </div>
        ),
      ],
    });
  };

  const disableRightClick = (e: any) => {
    e.preventDefault();
  };

  const shareSinglePost = (e: any, post: any) => {
    e.stopPropagation();
    userData.driveFiles([post]);
    history.push("/message-create");
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const previewPost = (e: any) => {
    e.stopPropagation();
    if (
      (!props.data.meta.is_reported || (props.data.meta.is_reported)) &&
      (props.data.bought || !(props.data.type === "ppv") || props?.data?.userProfile?.handle === userData?.user?.handle) && userData.authStatus === "AUTHENTICATED" && !hideReported)
    {
      modalData.push(() => <GoToPostModal
          data={props.data}
          likePost={likePost}
          state={state}
          setState={setState}
          handleShowScheduleModal={handleShowScheduleModal}
          handleScheduledPostNow={handleScheduledPostNow}
          compareData={compareData}
          oldState={props.oldState}
          updatePost={props.updatePost}
      />)
    }
  };

  const checkVisible = () => {
    if (userData.user.id === props.data.userProfile.id) {
      if(props.data.publish_date) {
        return false
      } else {
        if (userData.user.profile.meta.verified_state !== "verified") {
          if (
              userData?.user?.email_verified_at === null ||
              (props.data.publish_date &&
                  props?.data?.publish_date >=
                  moment(new Date()).format("YYYY-MM-DD h:mm:ss"))
          ) {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      if (props.data.visible === 0 || props.data.visible === false) {
        return false;
      } else {
        return true;
      }
    }
  };

  const openDropdown = (e: any) => {
    e.stopPropagation();
    setState({ ...state, dropdown: !state.dropdown });
  };


  const compareData = () => {
    if (
      moment(new Date(), "YYYY-MM-DD h:mm:ss").isBefore(
        moment(
          moment(props?.data?.publish_date, "YYYY-MM-DD h:mm:ss").toDate()
        ).format("YYYY-MM-DD h:mm:ss")
      ) ||
      moment(new Date()).format("YYYY-MM-DD") ===
        moment(moment(props?.data?.publish_date, "YYYY-MM-DD").toDate()).format(
          "YYYY-MM-DD"
        )
    ) {
      return true;
    }
  };

  const checkRestricted = () => {
    return props.data.meta.user_restricted;
  };

  const closeComments = () => {
    setState({
      ...state,
      commenting: false,
      commentQuote: null,
      comments:[]
    });
    if (restrictedWords.length > 0) {
      setRestrictedWords([]);
    }
    if(showComments) {
      setShowComments(false)
    }
  };

  const typeComment = (text: any) => {
    setState({
      ...state,
      currentComment: text,
    });
    if (restrictedWords.length > 0) {
      setRestrictedWords([]);
    }
  };

  const cancelComment = () => {
    setState({
      ...state,
      commenting: false,
      commentQuote: null
    });
  }

  const [playing,setPlaying] = useState(false)

  const handleKeyPress = (e: any) => {
    if (props?.data?.postAttachments.length > 1) {
      if (e.key === "ArrowLeft") {
        prevPost();
      }
      if (e.key === "ArrowRight") {
        nextPost();
      }
    }
  };

  const openTipModal = async () => {
    if(userData.user.meta.can_subscribe || userData?.user.wallet_balance > 0) {
      await modalData.push(() => (
          <TipModal
              otherUser={props.data.userProfile.handle}
              conversation={
                props.data.userProfile.meta.conversation_id
              }
          />
      ))
    } else {
      history.push('/settings/payment')
    }

  }

  const openCommentForm = (e:any, id:number,comments:number) => {
    if(!state.commenting && comments > 0) {
      processComments(e,id,true)
    } else {
      setState({ ...state, commenting: !state.commenting,comments:[] })
      setShowComments(false);
    }
    commentRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  const [newPosts, setNewPosts] = useState<any>([])
  const updateNewPosts = () => {
    if(userData.newPostsCreator.length > 0) {
      setNewPosts(userData.newPostsCreator)
      userData.newPostsCreatorFunction(null)
    }
  }
  useEffect(() => {
    updateNewPosts()
  },[])

  const checkNewPost = () => {
    if(newPosts.includes(props.data.id) && props.data.userProfile.id !== userData.user.id){
      return true
    } else {
      return false
    }
  }

  const returnUploadProgress = () => {
    return userData.encodingStatus.percent
  }

  const checkLinkDisable= () => {
    if(userData.authStatus === "UNAUTHENTICATED" || props.data.state === "encoding") {
      return true
    } else {
      if(props.data.userProfile.id !== userData.user.id) {
        if(!props.subscribe) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  const profileImage = () => {
    return props.data.userProfile.profileImage
  }

  const scheduledDate = () => {
    const publish_date = props?.data?.publish_date
    if(moment(publish_date).format("DD MMM") === moment(new Date()).format("DD MMM")) {
      return "Today, " + moment(publish_date).format("HH:mm")
    } else if (moment(publish_date).subtract(1, 'days').format("DD MMM") === moment(new Date()).format("DD MMM")) {
      return "Tomorrow, " + moment(publish_date).format("HH:mm")
    } else if(moment(publish_date).format("YYYY") === moment(new Date()).format("YYYY")) {
      return moment(publish_date).format("ddd, D MMM, HH:mm")
    } else {
      return moment(publish_date).format("ddd, D MMM YYYY, HH:mm")
    }
  }

  return (
    <>
      {!props?.data?.deleted && !props?.data.moveDrive ? (
        <SimpleCard
          className={`fl-post-main fl-reported-${
              props.data.meta.is_reported && hideReported ? "1" : "0"
          } fl-post-${
            !checkVisible() ||
            (props.data.meta.is_reported && hideReported) ||
            (props?.data?.publish_date && compareData())
              ? "0"
              : "1"
          }
          fl-feed-${checkNewPost() ? "new" : "old"}-post
          `}
          tabIndex={0}
          onFocus={function (e:any) {setFocusedElement(e.currentTarget)}}
          onBlur={function(e:any) { if (!e.currentTarget.contains(e.relatedTarget)) { setHideReported(true); setViewReported(false); }}}
        >
          <div onContextMenu={(e) => disableRightClick(e)}>
            {
              !userData.gridView && (
                <div className={`fl-post-head ${props.data.meta.is_reported && hideReported ? "fl-post-disabled" : ""}`}>
                  <div className="fl-post-head-image">
                    <ProfileHolder
                      src={profileImage()?.url}
                      userProfile={props.data.userProfile}
                      array
                    />
                  </div>
                  <div className={`fl-post-head-info`}>
                    <Link
                      to={`/${props.data.userProfile.handle}`}
                      className={`fl-post-head-info-name fl-post-head-info-name-${userData.currentTheme}`}
                    >
                      {props.data.userProfile.name}
                    </Link>
                    <div className="fl-d-flex fl-align-center fl-post-info-user-time">
                      <div className={"fl-post-info-left"}>
                        <Link
                            to={`/${props.data.userProfile.handle}`}
                            className="fl-post-head-info-username"
                        >
                          @
                          {props.data.userProfile.id !== userData.user.id
                              ? props.data.userProfile.handle
                              : userData.user.handle}
                        </Link>
                      </div>
                      <div className={"fl-post-info-right"}>
                        <div className="fl-post-head-info-dot"></div>
                        {props?.data?.publish_date !== null ? (
                            <div
                                className="fl-write-post-schedule-date-single"
                                onClick={(e) => handleShowScheduleModal(e)}
                            >
                              {userData.gridView || (
                                  <span
                                      className={`fl-write-post-pending fl-blue-link fl-hover-underline fl-write-post-schedule-date ${
                                          props.data.visible === 0 ||
                                          props.data.visible === false
                                              ? ""
                                              : ""
                                      } `}
                                  >
                              <b>
                                Post scheduled for{" "} {scheduledDate()}
                              </b>
                            </span>
                              )}
                              <ReactTooltip />
                            </div>
                        ) : (
                            <div className="fl-post-head-info-time">
                              {!checkLinkDisable() ?
                                  <Link
                                      className={`fl-post-head-info-time-link`}
                                      to={`/post/${props.data.id}`}
                                  >
                                    <div
                                        className={`fl-post-head-info-username fl-post-head-info-name-${userData.currentTheme}`}
                                    >
                                      <CheckTime data={props.data.created_at}/>
                                    </div>
                                  </Link> :
                                  <div
                                      className={`fl-post-head-info-username fl-post-head-info-name-${userData.currentTheme} fl-post-head-info-time-link-disabled`}
                                  >
                                    <CheckTime data={props.data.created_at}/>
                                  </div>
                              }
                            </div>
                        )}
                        {props.data.pinned ? (
                            <>
                              <div className="fl-post-head-info-pin">
                                <FontAwesomeIcon icon={faThumbtack} /> Pinned post
                              </div>
                            </>
                        ) : (
                            ""
                        )}
                        {props.data.price > 0 ?
                            <>
                              <div className="fl-post-head-info-dot"></div>
                              <span className={"fl-settings-grey fl-post-price"}>${props.data.price} <img src={padLockGrey} alt={"fluffa"} /></span>
                            </>
                            :""
                        }
                        {props.data.type === 'public' ?
                            <>
                              <div className="fl-post-head-info-dot"></div>
                              <div className="fl-post-head-info-globe">
                                <FontAwesomeIcon icon={faGlobeAmericas} />
                              </div>
                            </>
                            : ""}
                      </div>
                    </div>
                  </div>
                  {props.data.state === "active" &&
                  <div
                      className="fl-post-head-actions"
                      tabIndex={1}
                      onBlur={() => setState({ ...state, dropdown: false })}
                  >
                    <FontAwesomeIcon
                        className="fl-post-head-actions-icon"
                        icon={faChevronDown}
                        onClick={() =>
                            setState({ ...state, dropdown: !state.dropdown })
                        }
                    />
                    {state.dropdown ? (
                        <div
                            className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                        >
                          <SimpleCard className="fl-post-head-actions-dropdown-inner">
                            {props.data.userProfile.handle !==
                            userData.user.handle &&
                            userData.authStatus === "AUTHENTICATED" &&
                            !checkDisabledPost() ? (
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={(e) => reportPost(e, props.data.id)}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                  </div>
                                  Report post
                                </div>
                            ) : (
                                ""
                            )}
                            {props.data.userProfile.handle ===
                            userData.user.handle &&
                            props.data.visible !== 0 &&
                            !props.data.publish_date ? (
                                <>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={(e) => openPinModal(e)}
                                  >
                                    {props.data.pinned ? (
                                        <>
                                          <div className="fl-post-head-actions-dropdown-option-icon">
                                            <FontAwesomeIcon icon={faThumbtack} />
                                          </div>
                                          Unpin post
                                        </>
                                    ) : (
                                        <>
                                          <div className="fl-post-head-actions-dropdown-option-icon">
                                            <FontAwesomeIcon icon={faThumbtack} />
                                          </div>
                                          Pin to top
                                        </>
                                    )}
                                  </div>
                                </>
                            ) : (
                                ""
                            )}
                            {props.data.userProfile.handle ===
                            userData.user.handle &&
                            props.data.publish_date === null &&
                            checkVisible() ? (
                                <>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={async (e) => moveToDriveModal(e)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faUpload} />
                                      </div>
                                      Move to Drive
                                    </>
                                  </div>
                                </>
                            ) : (
                                ""
                            )}
                            {props.data.userProfile.handle ===
                            userData.user.handle &&
                            props?.data?.postAttachments === [] ? (
                                <>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={(e) => shareSinglePost(e, props.data)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faShare} />
                                      </div>
                                      Share file
                                    </>
                                  </div>
                                </>
                            ) : (
                                ""
                            )}
                            {props.data.userProfile.handle ===
                            userData.user.handle && props.data.publish_date ? (
                                <>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={(e) => handleScheduledPostNow(e)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faRocket} />
                                      </div>
                                      Post now
                                    </>
                                  </div>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={(e) => handleShowScheduleModal(e)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-schedule-hover">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                             width="344.000000pt" height="392.000000pt" viewBox="0 0 344.000000 392.000000"
                                             preserveAspectRatio="xMidYMid meet">
                                          <g transform="translate(0.000000,392.000000) scale(0.100000,-0.100000)"
                                             fill="#ff0d7290" stroke="none">
                                            <path d="M926 3845 c-53 -19 -85 -44 -121 -94 -30 -43 -30 -43 -33 -207 l-3
                                              -164 -183 0 c-100 0 -209 -5 -241 -11 -103 -19 -176 -65 -229 -143 -57 -84
                                              -66 -133 -66 -363 l0 -203 1680 0 1680 0 0 173 c0 205 -10 271 -50 354 -36 73
                                              -81 115 -170 157 l-65 31 -216 3 -217 4 -4 151 c-4 169 -14 202 -79 265 -89
                                              87 -263 76 -344 -20 -49 -58 -55 -85 -55 -248 l0 -150 -479 0 -479 0 -4 153
                                              c-3 131 -7 158 -25 193 -58 110 -182 160 -297 119z" fill="#ff0d7290"/>
                                            <path d="M50 1377 c0 -1156 -3 -1101 66 -1203 54 -80 129 -126 235 -144 45 -8
                                             471 -10 1419 -8 l1355 3 65 31 c36 17 83 46 103 63 46 41 94 138 107 216 6 36
                                             10 458 10 1073 l0 1012 -1680 0 -1680 0 0 -1043z m2250 527 c64 -35 114 -98
                                             136 -173 33 -110 0 -201 -117 -317 l-69 -69 -43 45 c-24 25 -112 116 -197 203
                                             l-154 157 64 66 c38 37 90 78 125 95 54 28 67 30 131 26 53 -3 83 -11 124 -33z
                                             m-307 -641 l87 -88 -282 -282 c-169 -167 -299 -289 -323 -301 -54 -27 -343
                                             -92 -377 -85 -37 7 -88 66 -88 101 0 15 19 106 41 202 l42 175 290 292 290
                                             292 116 -109 c64 -60 155 -148 204 -197z" fill="#ff0d7290"/>
                                          </g>
                                        </svg>
                                      </div>
                                      Edit schedule
                                    </>
                                  </div>
                                </>
                            ) : (
                                ""
                            )}
                            {props.data.userProfile.handle !==
                            userData.user.handle &&
                            userData.authStatus === "AUTHENTICATED" ? (
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={(e) => openBlockModal(e)}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faBan} />
                                  </div>
                                  Block user
                                </div>
                            ) : (
                                ""
                            )}
                            <div
                                className="fl-post-head-actions-dropdown-option"
                                onClick={(e) =>
                                    copyLink(e, `/post/${props.data.id}`)
                                }
                            >
                              <div className="fl-post-head-actions-dropdown-option-icon">
                                <FontAwesomeIcon icon={faClone} />
                              </div>
                              Copy link
                            </div>
                            {props.data.userProfile.handle ===
                            userData.user.handle ? (
                                <>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={(e) => openDeleteModal(e)}
                                  >
                                    <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                                    </div>
                                    Delete post
                                  </div>
                                </>
                            ) : (
                                ""
                            )}
                          </SimpleCard>
                        </div>
                    ) : (
                        ""
                    )}
                  </div>
                  }
                </div>
              )
              /* Text */
            }
            {(props.data.meta.is_reported && hideReported) && (
                <div
                    className="fl-post-image-reported-wrapper"
                    onClick={() => {
                      setPreviewReported(false);
                      setViewReported(true);
                      if (viewReported) {
                        if (focusedElement) {
                          // @ts-ignore
                          focusedElement.focus()
                        }
                        setHideReported(false);
                      }
                    }}
                >
                  <Button
                      onClick={() => {
                        setPreviewReported(false);
                        setViewReported(true);
                        if (viewReported) {
                          if (focusedElement) {
                            // @ts-ignore
                            focusedElement.focus()
                          }
                          setHideReported(false);
                        }
                      }}
                      className={`fl-post-image-reported ${
                          viewReported && "fl-post-image-reported-button"
                      }`}
                      type="normal"
                  >
                    {viewReported ? "VIEW POST" : "REPORTED!"}
                  </Button>
                </div>
            )}
            {props.data.text && !userData.gridView ? (
              <div className="fl-post-text">
                <PostBody body={props.data.text} />
              </div>
            ) : (
              ""
            )}
            {/* Images, videos */}
            {props.data.state === "encoding" ?
                <div className={"fl-post-encoding"}
                     style={{
                       backgroundImage: `url(${greyLogo})`,
                     }}
                >
                  <div>
                    <span className={"fl-encoding-post-thumb-text"}>Your upload is currently processing...</span>
                    <div className="fl-post-spinner fl-settings-mt-10">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div> : ""
            }
            {props?.data?.postAttachments?.length > 0 && props.data.state !== "encoding" ? (
              <div
                className={`fl-post-images ${userData.gridView && "fl-post-images-grid"}`}
                style={{
                  backgroundImage: `url(${
                    props.data.type === "public" ||
                    (props.data.userProfile.id !== userData.user.id &&
                      props.data.type === "ppv" &&
                      !props.data.userProfile.meta.is_subscribed)
                      ? ""
                      : props.data.userProfile.id === userData.user.id
                      ? ""
                      : userData.authStatus === "UNAUTHENTICATED" ||
                        !props.data.userProfile.meta.is_subscribed
                      ? greyLogo
                      : ""
                  })`,
                }}
              >
                <div
                  className={`fl-post-images-row-wrapper fl-post-images-row-wrapper-${
                    (props.data.bought ||
                      !(props.data.type === "ppv") ||
                      props?.data?.userProfile?.handle ===
                        userData?.user?.handle) &&
                    checkAuthStatus()
                  } `}
                  onClick={(e) => props?.data?.postAttachments[state.currentImage]?.type !== "video" || viewReported ? (isMobile ? goToPost(props.data.id) : previewPost(e)) : ""}
                >
                  {props.data.pinned && userData.gridView ? (
                    <>
                      <div className="fl-post-head-info-dot-pin"></div>
                      <div className="fl-post-head-info-pinned">
                        <FontAwesomeIcon icon={faThumbtack} /> Pinned post
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className={`fl-post-images-row ${userData.gridView && "fl-post-images-row-grid"}`}
                    style={{
                      transform: `translateX(-${state.currentImage * 100}%)`,
                    }}
                    tabIndex={1}
                    onBlur={() => setState({ ...state, dropdown: false })}
                  >
                    {userData.gridView && !props.data.meta.is_reported && (
                      <>
                        <div
                          className={"fl-post-images-option"}
                          onClick={(e) => openDropdown(e)}
                        >
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                        {state.dropdown ? (
                          <div
                            className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-overlay fl-post-head-actions-dropdown-${userData.currentTheme}`}
                          >
                            <SimpleCard className="fl-post-head-actions-dropdown-inner">
                              {props.data.userProfile.handle !==
                                userData.user.handle &&
                              userData.authStatus === "AUTHENTICATED"
                                  ? (
                                <div
                                  className="fl-post-head-actions-dropdown-option"
                                  onClick={(e) => reportPost(e, props.data.id)}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                    />
                                  </div>
                                  Report post
                                </div>
                              ) : (
                                ""
                              )}
                              {props.data.userProfile.handle ===
                                userData.user.handle &&
                              props.data.visible !== 0 &&
                              !props.data.publish_date ? (
                                <>
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={(e) => openPinModal(e)}
                                  >
                                    {props.data.pinned ? (
                                      <>
                                        <div className="fl-post-head-actions-dropdown-option-icon">
                                          <FontAwesomeIcon icon={faThumbtack} />
                                        </div>
                                        Unpin post
                                      </>
                                    ) : (
                                      <>
                                        <div className="fl-post-head-actions-dropdown-option-icon">
                                          <FontAwesomeIcon icon={faThumbtack} />
                                        </div>
                                        Pin to top
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {props.data.userProfile.handle ===
                                userData.user.handle &&
                              props.data.publish_date === null ? (
                                <>
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={async (e) => moveToDriveModal(e)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faUpload} />
                                      </div>
                                      Move to Drive
                                    </>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {props.data.userProfile.handle ===
                                userData.user.handle &&
                              props?.data?.postAttachments === [] ? (
                                <>
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={(e) =>
                                      shareSinglePost(e, props.data)
                                    }
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faShare} />
                                      </div>
                                      Share file
                                    </>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {props.data.userProfile.handle ===
                                userData.user.handle &&
                              props.data.publish_date ? (
                                <>
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={(e) => handleScheduledPostNow(e)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faRocket} />
                                      </div>
                                      Post now
                                    </>
                                  </div>
                                  <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={(e) => handleShowScheduleModal(e)}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-schedule-hover">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                             width="344.000000pt" height="392.000000pt" viewBox="0 0 344.000000 392.000000"
                                             preserveAspectRatio="xMidYMid meet">
                                          <g transform="translate(0.000000,392.000000) scale(0.100000,-0.100000)"
                                             fill="#000000" stroke="none">
                                            <path d="M926 3845 c-53 -19 -85 -44 -121 -94 -30 -43 -30 -43 -33 -207 l-3
                                              -164 -183 0 c-100 0 -209 -5 -241 -11 -103 -19 -176 -65 -229 -143 -57 -84
                                              -66 -133 -66 -363 l0 -203 1680 0 1680 0 0 173 c0 205 -10 271 -50 354 -36 73
                                              -81 115 -170 157 l-65 31 -216 3 -217 4 -4 151 c-4 169 -14 202 -79 265 -89
                                              87 -263 76 -344 -20 -49 -58 -55 -85 -55 -248 l0 -150 -479 0 -479 0 -4 153
                                              c-3 131 -7 158 -25 193 -58 110 -182 160 -297 119z" fill="#000000"/>
                                            <path d="M50 1377 c0 -1156 -3 -1101 66 -1203 54 -80 129 -126 235 -144 45 -8
                                             471 -10 1419 -8 l1355 3 65 31 c36 17 83 46 103 63 46 41 94 138 107 216 6 36
                                             10 458 10 1073 l0 1012 -1680 0 -1680 0 0 -1043z m2250 527 c64 -35 114 -98
                                             136 -173 33 -110 0 -201 -117 -317 l-69 -69 -43 45 c-24 25 -112 116 -197 203
                                             l-154 157 64 66 c38 37 90 78 125 95 54 28 67 30 131 26 53 -3 83 -11 124 -33z
                                             m-307 -641 l87 -88 -282 -282 c-169 -167 -299 -289 -323 -301 -54 -27 -343
                                             -92 -377 -85 -37 7 -88 66 -88 101 0 15 19 106 41 202 l42 175 290 292 290
                                             292 116 -109 c64 -60 155 -148 204 -197z" fill="#000000"/>
                                          </g>
                                        </svg>
                                      </div>
                                      Edit schedule
                                    </>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {props.data.userProfile.handle !==
                                userData.user.handle &&
                              userData.authStatus === "AUTHENTICATED" ? (
                                <div
                                  className="fl-post-head-actions-dropdown-option"
                                  onClick={(e) => openBlockModal(e)}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faBan} />
                                  </div>
                                  Block user
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className="fl-post-head-actions-dropdown-option"
                                onClick={(e) =>
                                  copyLink(e, `/post/${props.data.id}`)
                                }
                              >
                                <div className="fl-post-head-actions-dropdown-option-icon">
                                  <FontAwesomeIcon icon={faClone} />
                                </div>
                                Copy link
                              </div>
                              {props.data.userProfile.handle ===
                              userData.user.handle ? (
                                <>
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={(e) => openDeleteModal(e)}
                                  >
                                    <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                                    </div>
                                    Delete post
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </SimpleCard>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {props?.data?.postAttachments?.map((x:any,y:number) => (
                        x.type === "video" ?
                            <div className={"smooth-image-wrapper"} key={y}>
                              <VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                              {/*<VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />*/}
                            </div>
                            :
                            <RenderSmoothImage src={x.url} key={y} />
                    ))}
                    {(props.data.meta.is_reported && hideReported ) && (
                      <div
                        className="fl-post-image-reported-wrapper"
                        onClick={() => {
                          setPreviewReported(false);
                          setViewReported(true);
                          if (viewReported) {
                            if (focusedElement) {
                              // @ts-ignore
                              focusedElement.focus()
                            }
                            setHideReported(false);
                            setTimeout(() => setPreviewReported(true), 2500);
                          }
                        }}
                      >
                        <Button
                          onClick={() => {
                            setPreviewReported(false);
                            setViewReported(true);
                            if (viewReported) {
                              if (focusedElement) {
                                // @ts-ignore
                                focusedElement.focus()
                              }
                              setHideReported(false);
                              setTimeout(() => setPreviewReported(true), 2500);
                            }
                          }}
                          className={`fl-post-image-reported ${
                            viewReported && "fl-post-image-reported-button"
                          }`}
                          type="normal"
                        >
                          {viewReported ? "VIEW POST" : "REPORTED!"}
                        </Button>
                      </div>
                    )}
                  </div>
                  {props?.data?.postAttachments?.length > 0 && !playing && !userData.gridView && (
                    <div className="fl-post-image-swipe-count">
                      {props?.data?.postAttachments[state.currentImage]?.type === "video" ?
                      <img src={VideoIconWhite} alt={"fluffa"}/> :
                          <FontAwesomeIcon icon={faImage}/>
                      }
                       <b>{state.currentImage + 1}{props?.data?.postAttachments?.length > 1 ? `/${props?.data?.postAttachments?.length}` : ""}</b>
                    </div>
                  )}
                </div>
                {/* <FontAwesomeIcon className='fl-post-images-row-preview' icon={faExpand} onClick={() => setPreview({active: true, id: props.data.id})}/> */}
                {!props.data.meta.is_reported &&
                props?.data?.postAttachments?.length >
                  0 ? (
                  <div
                    className={
                      state.dropdown
                        ? "fl-post-images-swipe-dropdown-opened"
                        : "fl-post-images-swipe"
                    }
                  >
                    {props?.data?.postAttachments?.length >
                      1 && (
                      <>
                        <div
                          className={`fl-post-images-swipe-button fl-post-images-swipe-button-`}
                          onClick={prevPost}
                          tabIndex={0}
                          onKeyDown={handleKeyPress}
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                        <div
                          className={`fl-post-images-swipe-button fl-post-images-swipe-button-`}
                          onClick={nextPost}
                          tabIndex={0}
                          onKeyDown={handleKeyPress}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {!shouldUserSeeThePost() ? (
                  <PostLock
                    data={props.data}
                    update={props.updatePost}
                    oldState={props.oldState}
                    subscribe={props.subscribe}
                  />
                ) : (
                  ""
                )}
                {props.data.type === "private" &&
                props.data.userProfile?.handle !== userData.user.handle &&
                //@ts-ignore
                !userData?.user?.subscribed_to?.find(
                  (user: any) => user === props.data.userProfile?.handle
                ) ? (
                  <PostLock
                    data={props.data}
                    update={props.updatePost}
                    oldState={props.oldState}
                    subscribe={props.subscribe}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {/* Counts (likes, comments) */}
            {(userData.gridView || ((props.data.meta.likes + state.likeOffset > 0 || props.data.meta.comments > 0) && !props.data.meta.is_reported && (props.data.userProfile.meta.is_subscribed || props.data.userProfile.id === userData.user.id)) ? (
                <div
                  className={`fl-post-counts  fl-post-counts-${userData.currentTheme} 
                            ${
                              props.data.meta.is_reported ||
                              (!checkVisible() && "fl-post-counts-disable")
                            }
                            fl-post-counts-${
                              userData.authStatus === "AUTHENTICATED"
                                ? "enable"
                                : "disable"
                            }
                            ${checkDisabledPost() && "fl-button-disabled"}
                            ${props.data.meta.is_reported ? "fl-post-disabled" : ""}
                            `}
                >
                  <div
                    className={`fl-post-counts-likes fl-post-counts-likes-${userData.currentTheme}`}
                  >
                    {props.data.meta.likes > 0 || userData.gridView ? (
                      <span>
                        {props.data.meta.likes}{" "}
                        {props.data.meta.likes === 1
                          ? "like"
                          : "likes"}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`fl-post-counts-comments`}
                    onClick={(e) => {
                      !props.data.meta.is_reported &&
                        checkVisible() && !checkDisabledPost() &&
                        processComments(e, props.data.id);
                    }}
                  >
                    {props.data.meta.comments || userData.gridView ? (
                      <span>
                        {props.data.meta.comments} comment
                        {props.data.meta.comments !== 1 ? "s" : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              ))}
            <div ref={commentRef}></div>
            {/* Actions (like, comment, tip, favorite) */}
            {userData.gridView || (
              <div>
                <div
                  className={`fl-post-actions fl-post-actions-${userData.currentTheme} fl-post-actions-${
                    userData.authStatus === "AUTHENTICATED" &&
                    !props.data.userProfile.meta.is_blocked_by
                      ? "enable"
                      : "disable"
                  } ${checkDisabledPost() && "fl-post-actions-disabled"} `}
                >
                  <div className="fl-post-common-actions">
                    <div
                      className={`fl-post-action-single fl-post-action-single${
                          props.data.meta.is_reported || !checkVisible() || checkRestricted() || (!props.data.userProfile.meta.is_subscribed && props.data.userProfile.id !== userData.user.id)
                          ? "-no-hover"
                          : ""
                      }`}
                      onClick={() =>
                        userData.authStatus === "AUTHENTICATED" &&
                        (state.like ||
                          !props.data.userProfile.meta.is_blocked_by) &&
                        !checkDisabledPost() &&
                        !props.data.meta.is_reported &&
                        checkVisible() &&
                        !checkRestricted() &&
                        likePost(props.data.id)
                      }
                    >
                      <div className="fl-post-action-iconholder">
                        {props.data.meta.is_liked && !props.data.meta.is_reported ? (
                          <FontAwesomeIcon
                            className="fl-post-action-single-icon-active"
                            icon={faHeartSolid}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`fl-post-action-single-icon${
                                props.data.meta.is_reported || (!props.data.userProfile.meta.is_subscribed && props.data.userProfile.id !== userData.user.id) ? "-no-hover" : ""
                            }`}
                            icon={faHeart}
                          />
                        )}
                      </div>
                      {userData.gridView || <span>Like</span>}
                    </div>
                    {userData.gridView || (
                      <div
                        className={`fl-post-action-single fl-post-action-single${
                            props.data.meta.is_reported || !checkVisible() || checkRestricted() || (!props.data.userProfile.meta.is_subscribed && props.data.userProfile.id !== userData.user.id)
                            ? "-no-hover"
                            : ""
                        }`}
                        onClick={(e) =>
                          userData.authStatus === "AUTHENTICATED" &&
                          !props.data.userProfile.meta.is_blocked_by &&
                          !checkDisabledPost() &&
                          !props.data.meta.is_reported &&
                          checkVisible() &&
                          !checkRestricted() &&
                          openCommentForm(e, props.data.id, props.data.meta.comments)
                        }
                      >
                        <div className="fl-post-action-iconholder">
                          {state.commenting ? (
                            <FontAwesomeIcon
                              className="fl-post-action-single-icon-active"
                              icon={faCommentAltSolid}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className={`fl-post-action-single-icon${
                                  props.data.meta.is_reported || !checkVisible()
                                  ? "-no-hover"
                                  : ""
                              }`}
                              icon={faCommentAlt}
                            />
                          )}
                        </div>
                        {userData.gridView || <span>Comment</span>}
                      </div>
                    )}
                    {props.data.userProfile.id !== userData.user.id && (
                      <div
                        className={`fl-post-action-single ${
                          props.data.userProfile.id === userData.user.id
                            ? "fl-post-action-single-disabled"
                            : ""
                        }
                        fl-post-action-single${
                            props.data.meta.is_reported  || !checkVisible() || checkRestricted() || (!props.data.userProfile.meta.is_subscribed && props.data.userProfile.id !== userData.user.id)
                            ? "-no-hover"
                            : ""
                        }
                        `}
                        onClick={async () =>
                          props.data.userProfile.id !== userData.user.id &&
                          userData.authStatus === "AUTHENTICATED" &&
                          !props.data.userProfile.meta.is_blocked_by &&
                          !checkDisabledPost() &&
                          !props.data.meta.is_reported &&
                          checkVisible() &&
                          !checkRestricted() &&
                          openTipModal()
                        }
                      >
                        <div className="fl-post-action-iconholder">
                          <FontAwesomeIcon
                            className={`fl-post-action-single-icon${
                                props.data.meta.is_reported &&
                              !checkVisible() &&
                              checkRestricted()
                                ? "-no-hover"
                                : ""
                            }`}
                            icon={faDollarSign}
                          />
                        </div>
                        {userData.gridView || <span>{!isMobile && "Send "}Tip</span>}
                      </div>
                    )}
                    {props.data.publish_date ? (
                      <div
                        className="fl-post-action-single fl-write-post-schedule-icon-mobile-container fl-post-scheduled-icon"
                        onClick={(e:any) => handleShowScheduleModal(e)}
                      >
                        <div className="fl-post-action-iconholder">
                          <img
                            alt="schedule_icon"
                            src={ScheduleIcon}
                            className={`fl-write-post-schedule-icon-mobile ${
                              userData.currentTheme === "light" ? "" : ""
                            }`}
                          />
                        </div>
                      </div>
                    ) : null}
                    {!checkVisible() && props?.data?.publish_date === null ? (
                      <div
                        className={`fl-post-action-single${
                            props.data.meta.is_reported ? "" : "-no-hover"
                        }`}
                        data-tip="Your Post has been submitted and is pending approval."
                      >
                        {userData.gridView || (
                          <span className="fl-write-post-pending">
                            <b>Pending approval</b>
                            <FontAwesomeIcon
                              className="fl-write-post-pending-icon"
                              icon={faClock}
                            />
                          </span>
                        )}
                        <ReactTooltip />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {props.data.userProfile.id !== userData.user.id && (
                    <div
                      className={`fl-post-action-single fl-post-action-single${
                          props.data.meta.is_reported || !checkVisible() || checkRestricted() || (!props.data.userProfile.meta.is_subscribed && props.data.userProfile.id !== userData.user.id)
                          ? "-no-hover"
                          : ""
                      }`}
                      onClick={() =>
                        userData.authStatus === "AUTHENTICATED" &&
                        !props.data.userProfile.meta.is_blocked_by &&
                        !checkDisabledPost() &&
                        !props.data.meta.is_reported &&
                        checkVisible() &&
                        !checkRestricted() &&
                        favoritePost(props.data.id)
                      }
                    >
                      <div className="fl-post-action-iconholder">
                        {props.data.meta.is_favorited && !props.data.meta.is_reported ? (
                          <FontAwesomeIcon
                            className="fl-post-action-single-icon-active"
                            icon={faStarSolid}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`fl-post-action-single-icon${
                                props.data.meta.is_reported ? "" : "-no-hover"
                            }`}
                            icon={faStar}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Add a comment */}
            {state.commenting && !userData.gridView ? (
              <div
                className={`fl-post-comments-make fl-post-comments-make-${userData.currentTheme}`}
              >
                <div
                  className="fl-post-comment-make-button-cancel"
                  onClick={() => closeComments()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="fl-post-comments-make-all">
                  {!isMobile ?
                      <>
                        <div
                            className="fl-post-comment-emojis"
                            onClick={() =>
                                setEmojiState({ active: !emojiState.active })
                            }
                            ref={emojiRef}
                        >
                          <FontAwesomeIcon icon={faSmile} />
                        </div>
                        <div
                            className="fl-post-comment-emojis-picker"
                            style={{
                              display: emojiState.active ? "block" : "none",
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                          <Picker
                              onEmojiClick={(e, emoji) => {
                                setState({
                                  ...state,
                                  currentComment: `${state.currentComment}${emoji.emoji}`,
                                });
                              }}
                          />
                        </div>
                      </>
                   :""
                  }
                  {userData.user.profile.meta.verified_state !== "verified" ? (
                      <Textarea
                        onKeyDown={(e: any) => {
                          e.key === "Escape" &&
                           cancelComment()
                        }}
                        placeholder="Add a comment..."
                        className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                        value={state.currentComment}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          typeComment(e.currentTarget.value)
                        }
                        restrictedWords={restrictedWords}
                      />
                  ) : (
                      <Tagging setText={typeComment} text={state.currentComment}  placeholder="Add a comment..." keydownFunction={cancelComment} />
                  )}
                </div>
                {restrictedWords.length > 0 && (
                  <span className="fl-modal-description-row-error fl-write-post-restricted fl-post-comment-restricted">
                    <FontAwesomeIcon icon={faInfoCircle} /> You have entered
                    restricted words: {restrictedWords.join(",")}
                  </span>
                )}
                <div className="fl-post-comments-make-buttons">
                  <Button
                    className="fl-post-comments-make-button"
                    type="normal"
                    onClick={() => postComment()}
                    disabled={state.currentComment.trim() === "" || animation}
                  >
                    Post
                    {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* Show all comments */}
            {state.comments.length > 0 &&
            !userData.gridView &&
            !props.data.userProfile.meta.is_blocked_by &&
            showComments ? (
              <div
                className={`fl-post-comments fl-post-comments-${userData.currentTheme}`}
              >
                {state.comments.map((x, y) => {
                  return (
                    <>
                      <SimpleComment
                        flushComment={flushComment}
                        setFlushComment={setFlushComment}
                        postId={props.data.id}
                        postUser={props.data.userProfile?.id}
                        key={y}
                        data={x}
                        setStateComments={setState}
                        stateComments={state}
                        setReply={setReplyOnComment}
                        // for replying
                        postComment={postComment}
                        animation={animation}
                        exState={state}
                        updateState={setState}
                        currentComment={state.replyComment}
                        replyActive={state.replying}
                        commentQuote={state.commentQuote}
                        replyId={x.id}
                        oldState={props.oldState}
                        updatePost={props.updatePost}
                      />
                    </>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
          {/* ) : (
            <>
              <div className="fl-post-head">
                <div className="fl-fl-post-head-image">
                  <img style={{ width: "50px" }} src={Padlock} />
                </div>
                <div className="fl-post-head-info">
                  Pay {} to see this post?
                </div>
              </div>
            </>
          )} */}
        </SimpleCard>
      ) : (
        !props?.data?.closed && (
          <div>
            <SimpleCard
              className={`fl-post-deleted fl-post-deleted-${userData.currentTheme}`}
            >
              {props?.data?.deleted ? (
               <div>Your Post was deleted</div>
              ) : props?.data?.moveDrive ? (
                <div>
                  Your Post was archived.{" "}
                  <span
                    className="fl-notifications__mark"
                    onClick={() => restorePost(props.data.id)}
                  >
                    Undo
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                className="fl-post-head-actions-dropdown-option-icon fl-post-deleted-close"
                onClick={() => {
                  closeDeletedPost(props.data.id)
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </SimpleCard>
          </div>
        )
      )}
      <DatePickerScheduled
        label="Edit Schedule"
        value={scheduleDate}
        scheduledTimeValue={scheduleTime}
        onSave={handleScheduleDate}
        hideInputField={true}
        showModalInitialy={showScheduleModal}
        isScheduleModal={true}
        handleCloseOther={handleCloseDateModal}
        className="fl-settings-sub-account-field-input"
        min={new Date()}
        oldDate={props.data.publish_date}
      />
    </>
  );
};

export const PinUnpinModal: FC<any> = ({data,state,setState}) => {

  const modalData = useModalContext()
  const envData = useEnvContext()
  const [animation,setAnimation] = useState(false)

  const pinUnpinFunction = async () => {
    modalData.clearToasts()
    setAnimation(true)
    try {
      await Axios.post(`${envData.apiUrl}/posts/${data.id}/pin`);
      modalData.pushToast(
          "success",
          `${data.pinned ? "Your Post was unpinned from the top of your profile." : "Your Post was pinned to the top of your profile."}`
      );
      let newPost = state.posts;

      let updatedPosts = newPost.map((e: any) => {
        e.id === data.id && (e.pinned = !e.pinned);
        return e;
      });
      //@ts-ignore
      setAnimation(false)
      modalData.close()
      setState({ ...state, posts: updatedPosts });
    } catch (e) {
      setAnimation(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  }

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">
            {data.pinned ? "Unpin Post from top?" : "Pin Post to top?"}
          </div>
        </div>
        <div className="fl-modal-description">
          { data.pinned ? "This post will no longer appear automatically at the top of your profile." : "This Post will appear at the top of your profile and replace any previously pinned Post."}
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>
           Cancel
          </Button>
          <Button
              type="normal"
              onClick={() => pinUnpinFunction()}
              disabled={animation}
          >
            {data.pinned ? "Unpin" : "Pin"}
            {animation ?
                <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> : ""
            }
          </Button>
        </div>
      </React.Fragment>
  );
};

export const DeleteModalPost: FC<any> = ({ postId,updatePost,oldState,publishDate}) => {

  const modalData = useModalContext()
  const envData = useEnvContext()
  const userData = useUserContext()
  const [animation,setAnimation] = useState(false)

  const deletePost = async (postId: number) => {
    modalData.clearToasts()
    setAnimation(true)
    try {
      await Axios.delete(`${envData.apiUrl}/posts/${postId}`);
      modalData.close()
      updatePost({
        ...oldState,
        posts: oldState.posts.map((x: any) =>
            x.id === postId ? { ...x, deleted:true,closed:false } : x
        )
      })
      userData.updateCount(publishDate ? "deletePostScheduled" : "deletePost")
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
    setAnimation(false)
  };

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          Delete post
          <div className='fl-modal-close-button' onClick={() => modalData.close()}> <FontAwesomeIcon icon={faTimes} /> </div>
        </div>
        <div className="fl-modal-description">
          Are you sure you want to delete this post?
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" disabled={animation} onClick={() => deletePost(postId)}>
            Delet{animation ? "ing" : "e"}
            {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </React.Fragment>
  );
};

export const MoveToDriveModal: FC<any> = ({ id,updatePost,oldState }) => {

  const modalData = useModalContext()
  const envData = useEnvContext()
  const userData = useUserContext()
  const history = useHistory()
  const [animation,setAnimation] = useState(false)

  const goToDrive = () => {
    history.push("/drive/posts");
  };

  const moveToDrive = async (postId: number) => {
    modalData.clearToasts();
    setAnimation(true)
    try {
      await Axios.put(`${envData.apiUrl}/posts/${postId}`, {
        drive: true,
      });
      modalData.pushToast(
          "success",
          <>
            <div>
              Post has been moved to Fluffa Drive.{" "}
              <span
                  className="fl-notifications__mark"
                  onClick={() => goToDrive()}
              >
              Go to Archive
            </span>
            </div>
          </>
      );
      userData.updateArchivePost()
      updatePost({
        ...oldState,
        posts: oldState.posts.map((x: any) =>
            x.id === postId ? { ...x, moveDrive:true } : x
        )
      })
      modalData.close();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
    setAnimation(false)
  };

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          Archive Post
          <div className='fl-modal-close-button' onClick={() => modalData.close()}> <FontAwesomeIcon icon={faTimes} /> </div>
        </div>
        <div className="fl-modal-description">
          Post will be moved from your profile to Fluffa Drive.
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" disabled={animation} onClick={() => moveToDrive(id)}>
            Mov{animation ? "ing" : "e"}
            {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </React.Fragment>
  );
};