import {
  faCheck,
  faCheckCircle,
  faChevronCircleLeft, faExclamationCircle,
  faInfoCircle,
  faLink,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { FC, Fragment, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/Button";
import { MInput } from "../components/MInput";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import ReactTooltip from "react-tooltip";
import validator from "validator";
import "./SettingsAccount.css";
import axios from "axios";
import padLock from "../assets/images/padlock.svg";
import twitterLogo from "../assets/images/twitter.png";
import { Toggle } from "../components/Toggle";
import { ExpandingContent } from "../components/ExpandingContent";
import ReactLoading from "react-loading";

export const SettingsAccount: FC<any> = (props) => {
  const [profileState, setProfileState] = useState({
    auto_post: true,
    new_subscribers: true,
    ppv_item: true,
    repeat_subscribers: true,
    tip_received: true,
    social_accounts: {
      twitter: {
        connected: "",
        name: "",
      },
    },
  });

  const [profileStateUpdate, setProfileStateUpdate] = useState({
    social_accounts: {
      twitter: {
        connected: false,
        name: "",
      },
    },
  });

  const [generalState, setGeneralState] = useState({
    name: "",
    handle: "",
  });

  const [generalStateUpdate, setGeneralStateUpdate] = useState({
    name: "",
    handle: "",
  });

  const [emailState, setEmailState] = useState({
    email: "",
  });

  const [forceErrors, setForceErrors] = useState(false);
  const [takenUsername, setTakenUsername] = useState(false);
  const [allToggle, setAllToggle] = useState(false);

  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();
  const history = useHistory();

  const closeAccount = async () => {
    modalData.push(() => (
      <PasswordConfirmation
        api="account/close"
        title="PERMANENTLY Delete Your Account"
        twitter_id={
          emailState.email ? "" : profileState.social_accounts.twitter.connected
        }
        // messages={["Your account has been closed.", "You will be logged out in next few seconds!"]}
        messages={[
          "Your account has been deleted.  We’re sorry to see you go.",
        ]}
        content="Are you sure you want to permanently delete your account? This cannot be undone."
        custom_confirm="DELETE"
      />
    ));
  };

  const deactivateAccount = async () => {
    modalData.push(() => (
      <PasswordConfirmation
        api="account/deactivate"
        title="Temporarily Deactivate Your Account"
        twitter_id={
          emailState.email ? "" : profileState.social_accounts.twitter.connected
        }
        // messages={["Your account has been deactivated.", "You will be logged out in next few seconds!"]}
        messages={[
          "Your account has been deactivated. We hope to see you again soon.",
        ]}
        content="Are you sure you want to deactivate your account? This is temporary - simply log in again to resume using Fluffa."
        custom_confirm="DEACTIVATE"
      />
    ));
  };

  const changeEmail = async () => {
    modalData.push(() =>
      userData.user.has_password ? (
        <EmailPasswordConfirmation
          handle={generalState.handle}
          email={emailState.email}
          title="Verify your password"
        />
      ) : (
        <EmailConfirmation
          handle={generalState.handle}
          contentText={
            userData.user.email
              ? `Your current email address is: ${emailState.email}`
              : "Please add an email address:"
          }
          redirectPage="/settings"
          placeholderText={
            userData.user.email ? "Enter email address" : "Enter email address"
          }
          title={
            userData.user.email ? "Change email address" : "Add email address"
          }
          email={emailState.email}
        />
      )
    );
  };

  const [restrictedWords, setRestrictedWords] = useState<any>({
    displayName: [],
    username: [],
  });

  useEffect(() => {
    if (
      generalStateUpdate?.name?.length === 0 &&
      restrictedWords?.displayName?.length > 0
    ) {
      setRestrictedWords({ ...restrictedWords, displayName: [] });
    }
    if (
      generalStateUpdate?.handle?.length === 0 &&
      restrictedWords?.username?.length > 0
    ) {
      setRestrictedWords({ ...restrictedWords, username: [] });
    }
  }, [generalStateUpdate]);

  const [animationGeneral,setAnimationGeneral] = useState(false)
  const saveGeneral = async () => {
    setAnimationGeneral(true)
    setForceErrors(false);
    setTakenUsername(false);
    setRestrictedWords({ ...restrictedWords, displayName: [], username: [] });
    const errorsArray = [];
    if (
      !validator.isLength(generalStateUpdate.handle, {
        min: 4,
        max: 20,
      })
    )
      errorsArray.push(
        "Sorry, your username must be between 4 and 20 characters long."
      );

    if (
      !validator.isLength(generalStateUpdate.name, {
        min: 1,
        max: 20,
      })
    )
      errorsArray.push(
        "Sorry, your display name must be between 1 and 20 characters long."
      );

    if (errorsArray.length > 0) {
      return;
    } else {
      try {
        let toChange = {
          handle:
            generalState.handle !== generalStateUpdate.handle
              ? generalStateUpdate.handle
              : undefined,
          name: generalStateUpdate.name,
        };
        await Axios.put(`${envData.apiUrl}/users/account`, toChange);
        userData.updateHandle(generalStateUpdate.handle);
        modalData.pushToast("success", "Profile updated");
        history.push(`/${generalStateUpdate.handle}`);
      } catch (error) {
        if (error?.response?.data?.errors?.restricted_words?.length > 0) {
          setRestrictedWords({
            ...restrictedWords,
            displayName: error?.response?.data?.errors?.restricted_words[0],
            username: error?.response?.data?.errors?.restricted_words[1],
          });
        } else {
          setForceErrors(true);
          setTakenUsername(true);
        }
      }
    }
    setAnimationGeneral(false)
  };

  const [animation, setAnimation] = useState(true);

  const getAccountData = async () => {
    try {
      const res = await axios.get(`${envData.apiUrl}/auth/profile`);
      setProfileState({
        ...profileState,
        auto_post: res.data.data.settings.auto_post ?? false,
        social_accounts: {
          twitter: {
            connected: res.data.data?.twitter_id ?? false,
            name: res.data.data?.twitter_name ?? "",
          },
        },
      });
      setProfileStateUpdate({
        ...profileStateUpdate,
      });
      setGeneralState({
        name: res.data.data.name,
        handle: res.data.data.handle,
      });
      setGeneralStateUpdate({
        name: res.data.data.name,
        handle: res.data.data.handle,
      });
      setEmailState({ email: res.data.data.email });
      setAnimation(false);
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
      setAnimation(false);
    }
  };

  const disableGeneral = () => {

    if(generalStateUpdate.name.length === 0 || generalStateUpdate.handle.length === 0 || generalStateUpdate.handle.match(/[^a-zA-Z0-9_]+/) ||
        generalState.handle === generalStateUpdate.handle ||  generalState.name !== generalStateUpdate.name || takenUsername) {
      return true
    } else {
      return false
    }
  };

  const handleTwitter = () => {
    axios
      .get(`${envData.apiUrl}/auth/login/twitter/redirect`)
      .then((result: any) => {
        window.location.replace(result.data.url);
      })
      .catch(() => {
        modalData.pushToast("error", "Something went wrong!");
      });
  };

  const allOff = () => {
    const values = ({
      tip_received,
      new_subscribers,
      ppv_item,
      repeat_subscribers,
    }: {
      tip_received: boolean;
      new_subscribers: boolean;
      ppv_item: boolean;
      repeat_subscribers: boolean;
    }) => ({ tip_received, new_subscribers, ppv_item, repeat_subscribers });

    return Object.values(values(profileState)).every((elem) => !elem);
  };

  useEffect(() => {
    getAccountData();
  }, []);

  useEffect(() => {
    if (allOff()) {
      setAllToggle(false);
    } else {
      setAllToggle(true);
    }
  }, [
    profileState.tip_received,
    profileState.new_subscribers,
    profileState.repeat_subscribers,
    profileState.ppv_item,
  ]);

  const toggleAll = () => {
    if (allOff()) {
      setProfileState((prevState) => {
        return {
          ...profileState,
          tip_received: true,
          new_subscribers: true,
          ppv_item: true,
          repeat_subscribers: true,
        };
      });
    } else {
      setProfileState((prevState) => {
        return {
          ...profileState,
          tip_received: false,
          new_subscribers: false,
          ppv_item: false,
          repeat_subscribers: false,
        };
      });
    }
  };

  const unlinkTwitter = () => {
    axios
      .post(`${envData.apiUrl}/users/twitter/delete`)
      .then((response) => {
        setProfileState((prevState) => {
          return {
            ...prevState,
            social_accounts: {
              ...prevState.social_accounts,
              twitter: { connected: "", name: "" },
            },
          };
        });
        modalData.pushToast("success", "Twitter account successfuly unlinked.");
      })
      .catch((err) => {
        if (err.response.data.error.includes("twitter only")) {
          modalData.pushToast(
            "error",
            "Please add an email address and password before unlinking your Twitter account."
          );
        }
      });
  };

  const redirectProfile = () => {
    history.goBack();
  };

  const clearGeneral = () => {
    setGeneralStateUpdate({
      name: generalState.name,
      handle: generalState.handle,
    });
    if(takenUsername) {
       setTakenUsername(false)
    }
    if(space) {
      setSpace(false)
    }
  }

  const formatHandle = (handle:string) => {
    return handle.replace(/\s/g, "")
  }

  const [space,setSpace] = useState(false)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.key === " ") {
      setSpace(true)
    } else if (space && e.key !== "Meta") {
      setSpace(false)
    }
  }

  return (
    <Fragment>
      {animation && (
        <div className="fl-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <div
        className={`fl-settings-sub-stretch ${
          animation && "fl-settings-sub-stretch-blur"
        }`}
      >
        <div className="fl-settings-sub-section">
          <div
            className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
          >
            <span
              className="fl-direct-messages-title-icon"
              onClick={() => redirectProfile()}
            >
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </span>
            General
          </div>
          <div className="fl-d-flex fl-justify-space-between fl-settings-mt-30 fl-flex-direction-column">
            <div
              className={`fl-settings-sub-account-field fl-settings-sub-account-field-half ${
                restrictedWords?.displayName?.length > 0 &&
                "fl-minput-main-not-valid"
              }`}
            >
              <MInput
                maxLength={20}
                label="Display name"
                value={generalStateUpdate.name}
                setValue={(name) => {
                  setGeneralStateUpdate({ ...generalStateUpdate, name });
                }}
                forceErrors={forceErrors}
                validator={[
                  {
                    check: (v) => !validator.isEmpty(v),
                    message: "Choose a Display name",
                  },
                  {
                    check: (v) => validator.isLength(v, { min: 4, max: 20 }),
                    message: "Your display name must be 20 characters or less.",
                  },
                ]}
              />
              {restrictedWords?.displayName?.length > 0 && (
                <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> You have entered
                  restricted words: {restrictedWords.displayName + ""}
                </span>
              )}
            </div>
            <div
              className={`fl-settings-sub-account-field fl-settings-sub-account-field-half fl-responsive-mt-20 ${
                restrictedWords?.username?.length > 0 &&
                "fl-minput-main-not-valid"
              }`}
              onFocus={() => takenUsername ? setTakenUsername(false) : ""}
            >
              <MInput
                maxLength={20}
                label="@username"
                value={formatHandle(generalStateUpdate.handle)}
                setValue={(handle) => {
                  setGeneralStateUpdate({ ...generalStateUpdate, handle });
                }}
                forceErrors={forceErrors}
                validator={[
                  {
                    check: (v) => !validator.isEmpty(v),
                    message: "Choose a username",
                  },
                  {
                    check: (v) => validator.isLength(v, { min: 4, max: 20 }),
                    message: "Your username must be 20 characters or less.",
                  },
                  {
                    check: (v) => !/[^a-zA-Z0-9_]+/.test(v),
                    message: "Your username can only contain letters, numbers and '_'",
                  }
                ]}
                onKeyDown={handleKeyDown}
              />
              {space ?
                  <div className="fl-minput-validator fl-space-validator">
                    <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                    Your username can't contain spaces
                  </div> : ""
              }
              {takenUsername && (
                <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> That username is
                  taken. Try another.
                </span>
              )}
              {restrictedWords?.username?.length > 0 && (
                <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> You have entered
                  restricted words: {restrictedWords.username + ""}
                </span>
              )}
            </div>
          </div>
          <div className={"fl-d-flex fl-flex-direction-column"}>
            <Button
                type="outlined"
                className="fl-settings-sub-security-button"
                onClick={() => clearGeneral()}
                disabled={generalState.handle === generalStateUpdate.handle && generalState.name === generalStateUpdate.name ? true : false }
            >
              Cancel
            </Button>
            <Button
                type="normal"
                className="fl-settings-sub-security-button fl-responsive-ml-10"
                onClick={saveGeneral}
                disabled={animationGeneral || disableGeneral()}
            >
              Save
              {animationGeneral && <ReactLoading  className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>}
            </Button>
          </div>
        </div>

        <div className="fl-settings-sub-section">
          <div
            className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
          >
            Email address
          </div>
          <div className="fl-d-flex fl-justify-space-between fl-settings-mt-30">
            <div className="fl-settings-sub-account-field fl-settings-sub-account-field fl-settings-update-mail-field">
              {userData.user.email ? (
                <>
                  <MInput
                    label="Current email address"
                    value={emailState.email}
                    setValue={(email) => {
                      setEmailState({ ...email, email });
                    }}
                    disabled
                  />
                  {userData?.user?.email_verified_at !== null ? (
                    <span className="fl-settings-sub-account-update-span">
                      Your email address: {userData.user.email} has been
                      verified{" "}
                      <FontAwesomeIcon
                        style={{ color: "green" }}
                        icon={faCheckCircle}
                      />
                    </span>
                  ) : (
                    <span className="fl-settings-sub-account-update-span">
                      <FontAwesomeIcon
                        style={{ color: "red" }}
                        icon={faTimesCircle}
                      />{" "}
                      Your email address has not been verified. Please click{" "}
                      <Link to="/settings/email">here</Link> to verify your
                      email address.{" "}
                    </span>
                  )}
                </>
              ) : (
                "You haven't added an email address yet."
              )}
            </div>
          </div>{" "}
          <br />
          <br />
          <span
            className="fl-settings-update-email-button"
            onClick={changeEmail}
          >
            {userData.user.email ? "Update email address" : "Add email address"}
          </span>
        </div>

        <div className="fl-settings-sub-section">
          <div
            className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
          >
            Linked accounts
          </div>

          <div className="fl-d-flex fl-justify-space-between">
            <div className="fl-social-wrapper">
              <div className="fl-social-column fl-social-icons">
                <div className="fl-social-item" onClick={handleTwitter}>
                  <img src={twitterLogo} alt="Twitter logo" />
                  <span>Twitter</span>
                  <span className={`fl-${userData.currentTheme}-text-white`}>
                    {profileState.social_accounts.twitter.connected
                      ? profileState.social_accounts.twitter.name
                      : "Not linked"}
                  </span>
                </div>
                {profileState.social_accounts.twitter.connected && (
                  <div className="fl-destroy-action" onClick={unlinkTwitter}>
                    <FontAwesomeIcon icon={faLink} /> Unlink
                  </div>
                )}
              </div>
              <p className={`fl-${userData.currentTheme}-text-white`}>
                Auto tweets are a great way to get more traffic to your Fluffa
                page. We recommend you have these turned on.
              </p>
              <div className="fl-social-column">
                <ExpandingContent
                  header="New subscribers"
                  content="Tweet when you get a new subscriber"
                  className={`fl-settings-mt-20 fl-${userData.currentTheme}-text-white`}
                />
                <div className="fl-toggle-row">
                  <Toggle
                    rightComponent={<FontAwesomeIcon icon={faTimes} />}
                    leftComponent={<FontAwesomeIcon icon={faCheck} />}
                    value={profileState.new_subscribers}
                    onClick={() => {
                      setProfileState((prevState) => {
                        return {
                          ...prevState,
                          new_subscribers: !prevState.new_subscribers,
                        };
                      });
                    }}
                  />
                  <span>{profileState.new_subscribers ? "ON" : "OFF"}</span>
                </div>
                <ExpandingContent
                  header="PPV item unlocked"
                  content="Tweet when someone unlocks a PPV post or message"
                  className={`fl-settings-mt-20 fl-${userData.currentTheme}-text-white`}
                />
                <div className="fl-toggle-row">
                  <Toggle
                    rightComponent={<FontAwesomeIcon icon={faTimes} />}
                    leftComponent={<FontAwesomeIcon icon={faCheck} />}
                    value={profileState.ppv_item}
                    onClick={() => {
                      setProfileState((prevState) => {
                        return { ...prevState, ppv_item: !prevState.ppv_item };
                      });
                    }}
                  />
                  <span>{profileState.ppv_item ? "ON" : "OFF"}</span>
                </div>
                <ExpandingContent
                  header="Repeat subscribers"
                  content="Tweet when a subscriber is rebilled"
                  className={`fl-settings-mt-20 fl-${userData.currentTheme}-text-white`}
                />
                <div className="fl-toggle-row">
                  <Toggle
                    rightComponent={<FontAwesomeIcon icon={faTimes} />}
                    leftComponent={<FontAwesomeIcon icon={faCheck} />}
                    value={profileState.repeat_subscribers}
                    onClick={() => {
                      setProfileState((prevState) => {
                        return {
                          ...prevState,
                          repeat_subscribers: !prevState.repeat_subscribers,
                        };
                      });
                    }}
                  />
                  <span>{profileState.repeat_subscribers ? "ON" : "OFF"}</span>
                </div>
                <ExpandingContent
                  header="Tip received"
                  content="Tweet when you receive a tip"
                  className={`fl-settings-mt-20 fl-${userData.currentTheme}-text-white`}
                />
                <div className="fl-toggle-row">
                  <Toggle
                    rightComponent={<FontAwesomeIcon icon={faTimes} />}
                    leftComponent={<FontAwesomeIcon icon={faCheck} />}
                    value={profileState.tip_received}
                    onClick={() => {
                      setProfileState((prevState) => {
                        return {
                          ...prevState,
                          tip_received: !prevState.tip_received,
                        };
                      });
                    }}
                  />
                  <span>{profileState.tip_received ? "ON" : "OFF"}</span>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <span  className={`fl-settings-mt-20 fl-${userData.currentTheme}-text-white`}>Turn {allOff() ? "on" : "off"} all Auto-tweets</span>
                </div>
                <div className="fl-toggle-row">
                  <Toggle
                    rightComponent={<FontAwesomeIcon icon={faTimes} />}
                    leftComponent={<FontAwesomeIcon icon={faCheck} />}
                    value={allToggle}
                    onClick={() => {
                      toggleAll();
                    }}
                  />
                </div>
                <span style={{ marginTop: "10px" }} className={`fl-settings-mt-20 fl-${userData.currentTheme}-text-white`}>
                  {allOff() || "These can be turned off anytime."}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fl-settings-sub-account-actions">
        <div
          className="fl-settings-sub-account-actions-single light-up"
          onClick={deactivateAccount}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            data-tip
            data-for="disable-account"
            style={{ marginRight: ".3rem" }}
          />
          <ReactTooltip id="disable-account">
            This is temporary, so if you’re just wanting to take <br /> a break
            you should deactivate your account. <br />
            To reactivate your account, simply log back in <br /> at any time -
            everything will be right where you left it.
          </ReactTooltip>
          <span>Temporarily Deactivate Your Account</span>
        </div>
        <div
          className="fl-settings-sub-account-actions-single light-up"
          onClick={closeAccount}
          style={{ color: "rgb(117, 117, 117)" }}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            data-tip
            data-for="delete-account"
            style={{ marginRight: ".3rem" }}
          />
          <ReactTooltip id="delete-account">
            This is PERMANENT and can't be undone. <br /> If you want to use
            Fluffa again you’ll need to create <br /> a new account and build
            your profile again.
          </ReactTooltip>
          <span className="fl-delete-account">
            PERMANENTLY Delete Your Account
          </span>
        </div>
      </div>
    </Fragment>
  );
};

const PasswordConfirmation: FC<{
  api: string;
  title: string;
  twitter_id: string;
  messages: Array<string>;
  content?: string;
  custom_confirm?: string;
}> = ({ api, title, twitter_id, messages, content = "", custom_confirm }) => {
  const history = useHistory();

  const [state, setState] = useState<{ password: string; error: any }>({
    password: "",
    error: null,
  });

  const modalData = useModalContext();
  const envData = useEnvContext();
  const userData = useUserContext();

  const [processing, setProcessing] = useState(false);

  const sendReq = async () => {
    setProcessing(true);
    let password = userData.user.has_password ? state.password : "no pass";
    if (password.length !== 0) {
      try {
        await Axios.post(`${envData.apiUrl}/${api}`, { password: password });
        await modalData.pushToast(
            // "success",
            title === "Temporarily Deactivate Your Account"
                ? "deactivated"
                : "deleted",
            messages.join(", ") || "Everything looks fine! :)"
        );
        setProcessing(false);
        await modalData.close();
        await userData.clearToken(false);
        history.push("/login");
      } catch (error) {
        setState({
          ...state,
          error: "Wrong password. Try again.",
        });
        setProcessing(false);
      }
    } else {
      setState({ ...state, error: "Enter a password" });
      setProcessing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("onKeyPress", checkPassword);
    return function cleanup() {
      document.removeEventListener("onKeyPress", checkPassword);
    };
  }, [sendReq]);

  const disabledButton = () => {
    if (state.password.length < 1 || processing) {
      return true;
    }
  };
  const checkPassword = async () => {
    try {
      const res = await Axios.post(`${envData.apiUrl}/auth/password/check`, {
        password: state.password,
      });
      await sendReq();
    } catch (error) {
      setState({
        ...state,
        error: "Wrong password. Try again.",
      });
    }
  };

  const saveEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !disabledButton()) {
     e.preventDefault()
      await checkPassword()
    }
  };

  return (
    <Fragment>
      <div className="fl-modal-title fl-modal-display-block">{title}</div>
      <div className="fl-modal-description">
        <div className="fl-modal-description-row">
          {content && !userData.user.has_password
            ? content
            : `To continue, please ${
                !userData.user.has_password
                  ? "confirm your action"
                  : "re-enter your password"
              }`}
        </div>
        {!userData.user.has_password ? (
          ""
        ) : (
          <>
            <div
              className={`fl-modal-description-row ${
                state.error && "fl-minput-main-not-valid"
              }`}
            >
              <form autoComplete="off">
                <MInput
                  onKeyPress={(e: any) => saveEnter(e)}
                  visiblepassword={true}
                  type="password"
                  label="Password"
                  value={state.password}
                  setValue={(password: string) =>
                    setState({ ...state, password: password })
                  }
                />
              </form>
              {state.error && (
                <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> {state.error}
                </span>
              )}
            </div>

            <Link
              to="/forgot-password"
              className="fl-settings-account-modal-recoverpass"
              onClick={() => modalData.close()}
            >
              Forgot password?
            </Link>
          </>
        )}
      </div>
      <div className="fl-d-flex fl-justify-flex-end fl-modal-footer-buttons">
        <Button onClick={modalData.close}>Cancel</Button>
        <Button
          type="normal"
          onClick={checkPassword}
          disabled={disabledButton()}
        >
          {custom_confirm ?? "Confirm"}{" "}
          {processing && (
            <span>
              {" "}
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fff"}
                height={18}
                width={18}
                className="fl-spinningBubbles"
              />
            </span>
          )}
        </Button>
      </div>
    </Fragment>
  );
};

export const EmailConfirmation: FC<{
  handle?: string;
  title: string;
  email?: string;
  redirectPage?: string;
  contentText?: string;
  placeholderText?: string;
}> = ({ title, email, handle, contentText, placeholderText, redirectPage }) => {
  const history = useHistory();

  const [stateEmail, setStateEmail] = useState<{ email: string; error: any }>({
    email: "",
    error: null,
  });

  const modalData = useModalContext();
  const envData = useEnvContext();
  const userData = useUserContext();

  const [forceErrors, setForceErrors] = useState(false);

  const sendReq = async () => {
    if (!stateEmail.email) {
      setStateEmail({ ...stateEmail, error: "Enter an email address" });
      setForceErrors(false);
    } else if (!validator.isEmail(stateEmail.email)) {
      setStateEmail({ ...stateEmail, error: "Enter a valid e-mail address" });
    } else {
      try {
        await Axios.put(`${envData.apiUrl}/users/profile/email`, {
          email: stateEmail.email,
        });
        // modalData.pushToast("success", "Please confirm your new email address by clicking the link we just sent you.");
        await modalData.closeAll();
        history.push(redirectPage ?? `/settings/email`);
        if (userData.user.email) {
          userData.updateMail(stateEmail.email);
        } else {
          window.location.reload();
        }
      } catch (error) {
        const errorsArray = [];
        errorsArray.push(error.response.data.errors.email[0]);
        if (
          error.response.data.errors.email[0] ===
          "Email must have valid domain name."
        ) {
          setForceErrors(true);
        } else {
          setForceErrors(false);
        }
        if (
          error.response.data.errors.email[0] ===
          "The email has already been taken."
        ) {
          setStateEmail({
            ...stateEmail,
            error: "That e-mail address is taken. Try another",
          });
        } else {
          setStateEmail({
            ...stateEmail,
            error: "",
          });
        }
      }
    }
  };

  return (
    <Fragment>
      <div className="fl-modal-title">{title}</div>
      <div className="fl-modal-description">
        <div className="fl-modal-description-row fl-settings-sub-account-new-email">
          <p>
            {contentText ?? `Your current email address is: ${email}`} <br />
            <div className="fl-modal-description-row fl-settings-sub-account-change-email fl-settings-sub-account-label fl-settings-mt-10">
              <MInput
                type="email"
                label={placeholderText ?? "Enter new email address"}
                value={stateEmail.email}
                setValue={(email: string) =>
                  setStateEmail({ ...stateEmail, email })
                }
              />
              {stateEmail.error && !forceErrors && (
                <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> {stateEmail.error}
                </span>
              )}
              {forceErrors && stateEmail.email !== "" && (
                <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> Enter a valid e-mail
                  address
                </span>
              )}
            </div>
            <span className={"fl-settings-mt-30"}>
              This information is private and will never be displayed on your
              profile <img src={padLock} alt={"fluffa"}/>
            </span>
          </p>
        </div>
      </div>
      <div className="fl-d-flex fl-justify-flex-end fl-modal-footer-buttons">
        <Button onClick={modalData.closeAll}>Cancel</Button>
        <Button
          type="normal"
          onClick={sendReq}
          disabled={stateEmail.email.length === 0 ? true : false}
        >
          Confirm
        </Button>
      </div>
    </Fragment>
  );
};

const EmailPasswordConfirmation: FC<{
  handle: string;
  email: string;
  title: string;
}> = ({ email, title, handle }) => {
  const [statePassword, setStatePassword] = useState<{
    password: string;
    error: any;
  }>({
    password: "",
    error: null,
  });

  const modalData = useModalContext();
  const envData = useEnvContext();

  const checkPassword = async () => {
    if (statePassword.password.length !== 0) {
      try {
        await Axios.post(`${envData.apiUrl}/auth/password/check`, {
          password: statePassword.password,
        });
        modalData.push(() => (
          <EmailConfirmation
            handle={handle}
            title="Change email address"
            email={email}
          />
        ));
      } catch (error) {
        setStatePassword({
          ...statePassword,
          error: "Wrong password. Try again.",
        });
      }
    } else {
      setStatePassword({ ...statePassword, error: "Enter a password" });
    }
  };

  return (
    <Fragment>
      <div className="fl-modal-title">{title}</div>
      <div className="fl-modal-description">
        <div className="fl-modal-description-row">
          Re-enter your Fluffa password to continue
        </div>
        <div className="fl-modal-description-row">
          <form autoComplete="off">
            <MInput
              onKeyPress={(e: any) => {
                e.key === "Enter" && e.preventDefault();
              }}
              visiblepassword={true}
              type="password"
              label="Password"
              value={statePassword.password}
              setValue={(password: string) =>
                setStatePassword({ ...statePassword, password: password })
              }
            />
          </form>
          {statePassword.error && (
            <span className="fl-modal-description-row-error">
              <FontAwesomeIcon icon={faInfoCircle} /> {statePassword.error}
            </span>
          )}
        </div>
      </div>
      <div className="fl-d-flex fl-justify-flex-end fl-modal-footer-buttons">
        <Button onClick={modalData.close}>Cancel</Button>
        <Button
          type="normal"
          onClick={checkPassword}
          disabled={statePassword.password.length === 0 ? true : false}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};
