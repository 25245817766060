import {
  faCommentAlt,
  faHeart,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChevronLeft,
  faHeart as faHeartSolid,
  faTimes,
  faChevronRight,
  faExpand,
  faImage,
  faChevronDown,
  faExclamationCircle,
  faThumbtack,
  faUpload,
  faClone,
  faShare,
  faArrowLeft,
  faRocket, faClock,
} from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ProfileHolder } from "./ProfileHolder";
import { SimpleCard } from "./SimpleCard";
import { useUserContext } from "../context/UserContext";
import Picker from "emoji-picker-react";
import "./GoToPost.css";
import axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import moment from "moment";
import { Link } from "react-router-dom";
import { useModalContext } from "../context/ModalContext";
import { Button } from "./Button";
import Axios from "axios";
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import ReactTooltip from "react-tooltip";
import { VerificationBox } from "./VerificationBox";
import { UnapprovedBox } from "./UnapprovedBox";
import { PostOptions } from "./PostOptions";
import { MyProfile } from "./MyProfile";
import { Suggestions } from "./Suggestions";
import { Textarea } from "./Textarea";
import ReactLoading from "react-loading";
import PostBody from "./PostBody";
import { CheckTime } from "./HandleTime/CheckTime";
import { DatePickerScheduled } from "./DatePickerScheduled";
import messagesIcon from "../assets/images/icons/19.svg";
import messagesIconUnlock from "../assets/images/icons/23.svg";
import {Tagging} from "./Tagging";
import VideoPlayer from "./video/VideoPlayer";
import PostLock from "./PostLock/PostLock";
export const GoToPost: FC<any> = ({
  id,
  previousLocation,
  preview,
  setPreview,
  ...props
}) => {
  const [state, setState] = useState<any>({
    fetched: false,
    comment: "",
    emojis: false,
    post: null,
    comments: [],
    liked: false,
    likeOffset: 0,
    numberPostItems: 0,
    postArrayIterator: 0,
    transition: true,
    dropdown: false,
    pinned: false,
    total_comments: 0,
    error: false,
    creator: 0,
    replies:[],
    editCommentText:"",
    oldText:"",
    replyComment:""
  });

  const postId = useParams<{ id: string }>().id || id;
  const { commentId } = useParams<{commentId:string}>();

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const emojiRef = useRef<HTMLDivElement>(null);
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  useEffect(() => {
    const trackClick = (e: MouseEvent) => {
      if (emojiRef.current && e.target !== null) {
        //@ts-ignore
        if (
          e.target !== emojiRef.current &&
          //@ts-ignore
          !emojiRef.current.contains(e.target)
        ) {
          setState({ ...stateRef.current, emojis: false });
        }
      }
    };
    window.addEventListener("click", trackClick);
    return () => {
      window.removeEventListener("click", trackClick);
    };
  }, []);

  const [listenComment, setListenComment] = useState<any>({
    id: 0,
    comment: null,
  });
  const [animationPost,setAnimationPost] = useState(false)
  const postComment = async () => {
    setAnimationPost(true)
    try {
        const res = await axios.post(
          `${envData.apiUrl}/posts/${postId}/comments`,
          { text: state.comment }
        );
        setState({
          ...state,
          comments: [res.data.data, ...state.comments],
          comment: "",
          post: { ...state.post, meta: {...state.post.meta, total_comments: state.post.meta.total_comments + 1} }
        });
      setAnimationPost(false)
    } catch (e) {
      setAnimationPost(false)
      let errorResponse = () => {
        switch (Object.keys(e.response.data.errors)[0]) {
          case "restricted_words":
            return (
              "You have entered restricted words: " +
              e.response.data.errors.restricted_words
            );
          default:
            return false;
        }
      };
      modalData.pushToast("error", errorResponse() || "Something went wrong!");
    }
  };

  const [animationPostReply,setAnimationPostReply] = useState(false)
  const postCommentReply = async () => {
    setAnimationPostReply(true)
    try {
        const res = await axios.post(
            `${envData.apiUrl}/posts/${postId}/comments/${replyState.id}`,
            { text: state.replyComment }
        );
        setState({
          ...state,
          replyComment: "",
          post: { ...state.post, meta: {...state.post.meta, total_comments: state.post.meta.total_comments + 1,comments:  state.post.meta.comments + 1} },
          comments: state.comments.map((x: any) =>
              x.id === replyState.id ? { ...x, meta: {...x.meta, comments: x.meta.comments + 1 }, replies: [res.data.data, ...x.replies] } : x
          ),
        });
        setListenComment({ id: replyState.id, comment: res.data.data });
        setReplyState(null);
      setAnimationPostReply(false)
    } catch (e) {
      setAnimationPostReply(false)
      let errorResponse = () => {
        switch (Object.keys(e.response.data.errors)[0]) {
          case "restricted_words":
            return (
                "You have entered restricted words: " +
                e.response.data.errors.restricted_words
            );
          default:
            return false;
        }
      };
      modalData.pushToast("error", errorResponse() || "Something went wrong!");
    }
  };

  const likeDislike = async () => {
    setState({
      ...state,
      likeOffset: state.likeOffset + (state.liked ? -1 : 1),
      liked: !state.liked,
    });
    try {
      await axios.post(`${envData.apiUrl}/posts/${postId}/like`, {
        like: !state.liked,
      });
    } catch (e) {}
  };

  const commentRef = useRef<HTMLInputElement>(null);
  const focusComment = () => {
    if (commentRef.current !== null) {
      commentRef.current.querySelector("textarea")?.focus();
    }
  };

  useEffect(() => {
    if (!state.transition) {
      setState({ ...state, transition: true });
    }
  }, [state.postItems]);

  useEffect(() => {
    const getPostData = async () => {
      try {
        // const pRes = await axios.get(`${envData.apiUrl}/posts/${postId}`).catch(error => { if (error.response.status == 403) { history.push("/login") } });
        const pRes = await axios.get(`${envData.apiUrl}/posts/${postId}`).catch(error => "");
        const cResData = []
        if(userData.authStatus === "AUTHENTICATED" ) {
          const cRes = await axios.get(
              `${envData.apiUrl}/posts/${postId}/comments`
          );
          cResData.push(cRes)
        }
        // @ts-ignore
        if(pRes.data.creator) {
          // @ts-ignore
          history.push(`${pRes.data.userProfile.handle}`)
          // @ts-ignore
        } else if (userData.authStatus === 'UNAUTHENTICATED' && pRes.data.data) {
          // @ts-ignore
          history.push(`${pRes.data.userProfile.handle}`)
        }
        // @ts-ignore
        const data = pRes?.data
        setState({
          ...state,
          fetched: true,
          post: data.data,
          liked: data.data.meta.is_liked,
          likeOffset: data.data.meta.likes,
          imageCounter:
            typeof props.currentImage !== "undefined" ? props.currentImage : 0,
          numberPostItems:
              data.data.postImages.length + data.data.postVideos.length,
          postItems: [
            ...data.data.postImages,
            ...data.data.postVideos,
          ],
          originalPostItems: [
            ...data.data.postImages,
            ...data.data.postVideos,
          ],
          pinned: data.data.pinned,
          total_comments: data.data.meta.total_comments,
          creator: data.creator,
          comments: cResData[0].data.data
        });
        if (setPreview)
          setPreview({
            ...preview,
            next: data.data.meta.links.next,
            prev: data.data.meta.links.prev,
          });
      } catch (e) {
        setState({ ...state, fetched: true, error: true });
      }
    };
    getPostData();
  }, []);

  const history = useHistory();
  const goBack = () => {
    if (setPreview) {
      setPreview({ ...preview, active: false });
    } else {
      history.push("/feed");
    }
  };

  const prevPost = (e?: any) => {
    e?.stopPropagation();
    setState({
      ...state,
      postArrayIterator:
          state.postArrayIterator - 1 < 0
              ? state?.post?.postAttachments.length - 1
              : state.postArrayIterator - 1,
    });
  };

  const nextPost = (e?: any) => {
    e?.stopPropagation();
    setState({
      ...state,
      postArrayIterator:
          state.postArrayIterator + 1 > state?.post?.postAttachments.length - 1
              ? 0
              : state.postArrayIterator + 1,
    });
  };

  const [replyState, setReplyState] = useState<any>(null);

  const keyboardEvent = (e: any) => {
    if (state.numberPostItems > 1) {
      if (e.key === "ArrowLeft") {
        prevPost();
      }
      if (e.key === "ArrowRight") {
        nextPost();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyboardEvent);
    return () => {
      document.removeEventListener("keydown", keyboardEvent);
    };
  }, [keyboardEvent]);

  const moveToDriveModal = async () => {
    setState({ ...state, dropdown: false });
    await modalData.push({
      title: "Move to Drive",
      description: ["Post will be moved from your profile to Fluffa Drive."],
      buttons: [
        () => (
          <div className="fl-d-flex fl-justify-flex-end">
            <Button onClick={async () => await modalData.close()}>
              Cancel
            </Button>
            <Button type="normal" onClick={() => moveToDrive(state.post.id)}>
              Move
            </Button>
          </div>
        ),
      ],
    });
  };

  const moveToDrive = async (postId: number) => {
    modalData.close();
    try {
      await Axios.put(`${envData.apiUrl}/posts/${postId}`, {
        drive: true,
      });
      history.push("/drive/posts");
      modalData.pushToast("success", <>Post has been moved to Fluffa Drive.</>);
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const openDeleteModal = async () => {
    setState({ ...state, dropdown: false });
    await modalData.push({
      title: `Delete ${state?.post?.drive ? "forever" : "post"}`,
      description: [
        `${
          state?.post?.drive
            ? "Your Post will be deleted forever and you won't be able to restore it."
            : "Are you sure you want to delete this post?"
        }`,
      ],
      buttons: [
        () => (
          <div className="fl-d-flex fl-justify-flex-end">
            <Button onClick={async () => await modalData.close()}>
              Cancel
            </Button>
            <Button type="normal" onClick={() => deletePost(state.post.id)}>
              Delete
            </Button>
          </div>
        ),
      ],
    });
  };

  const deletePost = async (postId: number) => {
    modalData.close();
    try {
      await Axios.delete(`${envData.apiUrl}/posts/${postId}`);
      history.push("/feed");
      modalData.pushToast("success", "Your Post was deleted");
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const copyLink = async (x: string) => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}${x}`);
      modalData.pushToast("success", "Link copied to clipboard");
      setState({ ...state, dropdown: false });
    } catch (e) {
      const copyEl = document.createElement("textarea");
      copyEl.value = `${window.location.origin}${x}`;
      document.body.appendChild(copyEl);
      copyEl.select();
      document.execCommand("copy");
      document.body.removeChild(copyEl);
      setState({ ...state, dropdown: false });
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const reportPost = async (id: number) => {
    const res = await Axios(`${envData.apiUrl}/posts/reports/reasons`);
    const reasons = Object.keys(res.data.reasons).map((x) => ({
      key: x,
      value: res.data.reasons[x],
    }));

    const Reason = () => {
      const [reasonState, setReasonState] = useState("");
      return (
        <Fragment>
          <div className="fl-modal-title">
            <div className="fl-modal-title-text">Report post</div>
          </div>
          <div className="fl-modal-description">
            <div className="fl-modal-description-row">What's the issue?</div>
            <div>
              {reasons.map((x, y) => (
                <div
                  onClick={() => setReasonState(x.key)}
                  className={`fl-modal-report-reason fl-modal-report-reason-${
                    userData.currentTheme
                  } ${
                    reasonState === x.key ? "fl-modal-report-reason-active" : ""
                  }`}
                  key={y}
                >
                  {x.value}
                </div>
              ))}
            </div>
          </div>
          <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
            <Button onClick={() => modalData.close()}>Cancel</Button>
            <Button
              type="normal"
              onClick={() => processReport(id, reasonState)}
              disabled={reasonState ? false : true}
            >
              Submit
            </Button>
          </div>
        </Fragment>
      );
    };

    await modalData.push(() => <Reason />);
  };

  const processReport = async (id: number, reason: any) => {
    modalData.close();
    try {
      await Axios.post(`${envData.apiUrl}/posts/${id}/report`, {
        reason,
      });
      modalData.pushToast("success", "Post reported");
    } catch (e) {
      modalData.pushToast(
        "error",
        "You've already reported this post. It's now being checked by the review team."
      );
    }
  };

  const openPinModal = async () => {
    setState({ ...state, dropdown: false });
    modalData.push(() => (<PinUnpinModal state={state} setState={setState} />))
  };

  const disableRightClick = (e: any) => {
    e.preventDefault();
  };

  const shareSinglePost = (post: any) => {
    userData.driveFiles([post]);
    history.push("/message-create");
  };

  const checkPostType = () => {
    if (
      state?.post?.postImages?.length === 0 &&
      state?.post?.postVideos?.length === 0 &&
      state?.post?.text?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [editComment, setEditComment] = useState<any>({
    edit: false,
    id: 0,
    action:"",
    commentId:0
  });

  const processUpdate = async (postId: number) => {
    setAnimationPost(true)
    try {
      await Axios.put(
        `${envData.apiUrl}/posts/${postId}/comments/${editComment.id}`,
        { text: state.editCommentText }
      );
      if(editComment.action === "comment") {
        setState({
          ...state,
          comments: state.comments.map((x: any) =>
              x.id === editComment.id ? { ...x, text: state.editCommentText } : x
          ),
          editCommentText: "",
          oldText:""
        });
      } else {
        setState({
          ...state,
          comments: state.comments.map((x: any) =>
              x.id === editComment.commentId ? { ...x, replies:  x.replies.map((x: any) => x.id === editComment.id ? {...x, text:state.editCommentText} : x) } : x
          ),
          editCommentText: "",
          oldText:""
        });
      }
      setEditComment({ edit: false, id: 0,action:"",commentId:0 });
      setAnimationPost(false)
    } catch (e) {
      setAnimationPost(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const openFullScreen = (e: any, url: any, index: number) => {
    e.stopPropagation();
    modalData.fullScreen(url, index);
    document.body.style.overflowY = "hidden";
  };

  const compareData = () => {
    if (
      moment(new Date(), "YYYY-MM-DD h:mm:ss").isBefore(
        moment(
          moment(state.post.publish_date, "YYYY-MM-DD h:mm:ss").toDate()
        ).format("YYYY-MM-DD h:mm:ss")
      ) ||
      moment(new Date()).format("YYYY-MM-DD") ===
        moment(moment(state.post.publish_date, "YYYY-MM-DD").toDate()).format(
          "YYYY-MM-DD"
        )
    ) {
      return true;
    }
  };

  const goToPost = (postId: number) => {
    history.push(`/post/${postId}`);
  };

  const handleScheduleDate = async (
    x: string | Date,
    y?: string,
    publishNow?: boolean
  ) => {
    modalData.clearToasts();
    let publishDate = moment(x).format("YYYY-MM-DD");
    try {
       await Axios.put(`${envData.apiUrl}/posts/${state.post.id}`, {
        publish_date: publishNow ? null : `${publishDate} ${y}`,
      });
      modalData.close();
      setShowScheduleModal(false);
      setScheduleDate("");
      setScheduleTime("");
      setState({ ...state, dropdown: false });
      if (publishDate) {
        setState({ ...state, post: { ...state.post, publish_date: x } });
      }
      if (publishNow) {
        modalData.pushToast(
          "success",
          <>
            Your Post was uploaded.{" "}
            <span
              className={"toast-link"}
              onClick={() => goToPost(state.post.id)}
            >
              View
            </span>
          </>
        );
      } else {
        modalData.pushToast("info", `Your Post schedule was updated.`);
      }
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const handleScheduledPostNow = async () => {
    let x = new Date();
    let y = moment().format("hh:mm");

    setState({ ...state, dropdown: false });

    await modalData.push({
      title: "Post now",
      description: ["Your Post will be sent now instead."],
      buttons: [
        () => (
          <div className="fl-d-flex fl-justify-flex-end">
            <Button onClick={async () => await modalData.close()}>
              Cancel
            </Button>
            <Button type="normal" onClick={() => handleScheduleDate(x, y)}>
              Post now
            </Button>
          </div>
        ),
      ],
    });
  };

  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [scheduleDate, setScheduleDate] = useState<string>("");
  const [scheduleTime, setScheduleTime] = useState<string>("");

  const handleShowScheduleModal = () => {
    if (state.dropdown) {
      setState({ ...state, dropdown: false });
    }
    let formatedDate = moment(state.post.publish_date).format("DD/MM/YYYY");
    let formatedTime = moment(state.post.publish_date).format("hh:mm");

    setScheduleDate(formatedDate);
    setScheduleTime(formatedTime);
    setShowScheduleModal(true);
  };

  const handleCloseDateModal = () => {
    modalData.close();
    setShowScheduleModal(false);
    setScheduleDate("");
    setScheduleTime("");
  };

  const typeComment = (text:any) => {
    setState({
      ...state,
      comment: text,
    })
  }

  const typeCommentEdit = (text:any) => {
    setState({
      ...state,
      editCommentText: text,
    })
  }

  const cancelComment = () => {
    if(replyState){
      setReplyState(false)
    }
    setState({
      ...state,
      commenting: false,
      commentQuote: null,
      currentComment: "",
      comment:""
    });
  }

  const cancelEditComment = () => {
    setEditComment({ edit: false, id: 0,action:"",commentId:0 });
    setState({
      ...state,
      editCommentText:"",
      oldText:""
    });
  }

  const [playing,setPlaying] = useState(false)

  const checkVisible = () => {
    if (userData.user.id !== state.post.userProfile.id) {
      if (userData.user.profile.meta.verified_state !== "verified") {
        if (
            userData?.user?.email_verified_at === null ||
            (state.post.publish_date &&
                state.post.publish_date >=
                moment(new Date()).format("YYYY-MM-DD h:mm:ss"))
        ) {
          return false;
        }
      } else {
        return true;
      }
    } else {
      if (state.post.visible === 0 || state.post.visible === false) {
        return false;
      } else {
        return true;
      }
    }
  };
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const scheduledDate = () => {
    const publish_date = state.post.publish_date
    if(moment(publish_date).format("DD MMM") === moment(new Date()).format("DD MMM")) {
      return "Today, " + moment(publish_date).format("HH:mm")
    } else if (moment(publish_date).subtract(1, 'days').format("DD MMM") === moment(new Date()).format("DD MMM")) {
      return "Tomorrow, " + moment(publish_date).format("HH:mm")
    } else if(moment(publish_date).format("YYYY") === moment(new Date()).format("YYYY")) {
      return moment(publish_date).format("ddd, D MMM, HH:mm")
    } else {
      return moment(publish_date).format("ddd, D MMM YYYY, HH:mm")
    }
  }

  const shouldUserSeeThePost = () => {
    return (
        state.post.type !== "ppv" ||
        state.post.bought ||
        userData.user.id === state.post.userProfile.id
    );
  };


  return (
    <div className={`fl-container ${id ? "fl-gotopost-container" : ""}`}>
      {!state.fetched ? (
        <div className="fl-gotopost-pd fl-pt-40">
          <SimpleCard className="fl-gotopost-empty-row">
            <div className={"fl-loader-animation"}>
              <ReactLoading
                type={"spinningBubbles"}
                height={50}
                width={50}
                className="fl-spinningBubbles"
              />
            </div>
          </SimpleCard>
        </div>
      ) : state.post === null && state.creator === 0 ? (
        <div className="fl-gotopost-pd">
          <SimpleCard className="fl-gotopost-empty-row">
            <span>Sorry, this Post doesn't exist.</span> <br />
            <span>
                <Link className="fl-not-found-post-link" to="/feed">Go home</Link>
            </span>
          </SimpleCard>
        </div>
      ) : (
        <div className="fl-d-flex fl-feed-row">
          <div
            className={`${
              checkPostType()
                ? `fl-feed-main fl-feed-main-${userData.currentTheme}`
                : ""
            }`}
          >
            <div className="fl-container">
              <div className="fl-d-flex fl-feed-row">
                {checkPostType() && userData.authStatus === 'AUTHENTICATED' ? (
                  <div className="fl-col-4 fl-feed-column fl-feed-column-sticky fl-feed-tablet-hide">
                    {userData?.user?.email_verified_at === null ? (
                      <VerificationBox />
                    ) : (
                      ""
                    )}
                    <UnapprovedBox />
                    <PostOptions />
                    <MyProfile />
                    {/*<Suggestions />*/}
                  </div>
                ) : ""}
                <div
                  className={`fl-col-${
                    !checkPostType() || userData.authStatus === 'UNAUTHENTICATED' ? "12" : "8"
                  } fl-feed-column fl-feed-tablet-prio`}
                >
                  <div
                    className={`fl-col-12 fl-gotopost-card`}
                    {...((
                      { id, onClick, key } //picking only some keys from props through object destructuring
                    ) => ({ id, onClick, key }))(props)}
                    onContextMenu={(e) => disableRightClick(e)}
                  >
                    {/*<div className="fl-modal-close-button">*/}
                    {/*  <FontAwesomeIcon*/}
                    {/*    icon={faTimes}*/}
                    {/*    onClick={() => goBack()}*/}
                    {/*  />*/}
                    {/*</div>*/}

                    <SimpleCard className={`fl-gotopost-row fl-post-${!checkVisible() || state?.post?.meta?.is_reported || (state?.post?.publish_date && compareData()) ? "0" : "1"}`}>
                      {!checkPostType() && (
                        <div className={`fl-col-8 fl-gotopost-gallery-preview" fl-post-${state.post.publish_date && compareData() ? "0" : "1"}`}>
                          {!isMobile ?
                          <div className="fl-gotopost-post-header">
                            <div
                                className="fl-gotopost-post-header-back-icon"
                                onClick={goBack}
                            >
                              <FontAwesomeIcon
                                  data-tip
                                  data-for={"back"}
                                  icon={faArrowLeft}
                              />
                              <ReactTooltip id="back">Back</ReactTooltip>
                            </div>
                          </div> : ""}
                          <div className="fl-gotopost-post-media">
                            { userData.authStatus === 'UNAUTHENTICATED' && state.post.type === "ppv" ?
                            <div className={`fl-postlock__main`}>
                                <div className="fl-postlock__text fl-postlock__text-bold">
                                  Members Only!
                                </div>
                                <div className="fl-postlock-lock fl-postlock-lock-logout">
                                  <img className="fl-card-icon-padlock-ppv" src={messagesIcon} alt="Card icon"/>
                                  <img className="fl-card-icon-padlock-ppv-unlock" src={messagesIconUnlock} alt="Card icon"/>
                                </div>
                                <Button type="normal" to="/login">
                                  LOG IN TO {userData.user.profile.free_subscription === 1 ? "FOLLOW" : "SUBSCRIBE"}
                                </Button>
                              </div> : ""
                            }
                            <div
                              className="fl-post-images fl-post-preview__images"
                              onClick={(e) => state?.post?.postAttachments[state.postArrayIterator]
                                      ?.url && state?.post?.postAttachments[state.postArrayIterator]?.type !== "video"  ?
                                  openFullScreen(
                                  e,
                                  state?.post?.postAttachments,
                                  state.postArrayIterator
                                ) : ""
                              }
                              tabIndex={0}
                            >
                              {state?.post?.postAttachments[state.postArrayIterator]
                                ?.url && state?.post?.postAttachments[state.postArrayIterator]?.type !== "video"  && (
                                <span
                                  onClick={(e) =>
                                    openFullScreen(
                                      e,
                                      state?.post?.postAttachments,
                                      state.postArrayIterator
                                    )
                                  }
                                  tabIndex={0}
                                  className="fl-fullscreen-icon"
                                >
                                  <FontAwesomeIcon icon={faExpand} />
                                </span>
                              )}
                              <div
                                className={
                                  "fl-post-images-row fl-post-images-row-" +
                                  (state.transition
                                    ? "transition"
                                    : "no-transition")
                                }
                                style={{
                                  transform: `translateX(-${
                                    state.postArrayIterator * 100
                                  }%)`,
                                }}
                                onContextMenu={(e) => disableRightClick(e)}
                              >
                                {state?.post?.postAttachments.map((x:any,y:number) => (
                                    x.type === "video" ?
                                        <div className={"smooth-image-wrapper"}>
                                          <VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                        </div>
                                        :
                                        <RenderSmoothImage src={x.url} />
                                ))}
                                {!shouldUserSeeThePost() ? (
                                    <PostLock
                                        data={state.post}
                                        update={""}
                                        oldState={""}
                                        subscribe={props.subscribe}
                                    />
                                ) : (
                                    ""
                                )}
                              </div>
                              {state.numberPostItems > 0 ? (
                                <div className="fl-post-images-swipe">
                                  {state.numberPostItems > 1 && (
                                    <>
                                      <div
                                        className={`fl-post-images-swipe-button fl-post-images-swipe-button-`}
                                        style={{ paddingRight: "3px" }}
                                        onClick={(e) => prevPost(e)}
                                      >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                      </div>
                                      <div
                                        className={`fl-post-images-swipe-button fl-post-images-swipe-button-`}
                                        style={{ paddingLeft: "3px" }}
                                        onClick={(e) => nextPost(e)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faChevronRight}
                                        />
                                      </div>
                                    </>
                                  )}
                                  {state?.post?.postAttachments?.length > 0 && !playing  && (
                                      <div className="fl-post-image-swipe-count">
                                        <FontAwesomeIcon icon={faImage}/>
                                        <b>{state.postArrayIterator + 1}{state?.post?.postAttachments.length > 1 ? `/${state?.post?.postAttachments.length}` : ""}</b>
                                      </div>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="fl-gotopost-post-footer"></div>
                        </div>
                      )}
                      <div
                        className={`fl-col-4 fl-gotopost-comments fl-gotopost-comments-${
                          checkPostType() ? "text" : "media"
                        } fl-gotopost-comments-${checkPostType()}-${
                          userData.currentTheme
                        }
                        `}
                      >
                        {userData.authStatus === 'AUTHENTICATED' ?
                        <div
                          className="fl-gotopost-post-head-actions"
                          tabIndex={1}
                          onBlur={() => setState({ ...state, dropdown: false })}
                        >
                          <FontAwesomeIcon
                            className="fl-post-head-actions-icon"
                            icon={faChevronDown}
                            onClick={() =>
                              setState({
                                ...state,
                                dropdown: !state.dropdown,
                              })
                            }
                          />
                          {state.dropdown ? (
                            <div
                              className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                            >
                              <SimpleCard className="fl-post-head-actions-dropdown-inner">
                                {state.post.userProfile.handle !==
                                  userData.user.handle &&
                                userData.authStatus === "AUTHENTICATED" ? (
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={() => reportPost(state.post.id)}
                                  >
                                    <div className="fl-post-head-actions-dropdown-option-icon">
                                      <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                      />
                                    </div>
                                    Report
                                  </div>
                                ) : (
                                  ""
                                )}
                                {state?.post?.userProfile?.handle ===
                                  userData?.user?.handle &&
                                state?.post?.visible !== 0 &&
                                !state.post?.publish_date ? (
                                  <>
                                    <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={() => openPinModal()}
                                    >
                                      {state?.pinned ? (
                                        <>
                                          <div className="fl-post-head-actions-dropdown-option-icon">
                                            <FontAwesomeIcon
                                              icon={faThumbtack}
                                            />
                                          </div>
                                          Unpin post
                                        </>
                                      ) : (
                                        <>
                                          <div className="fl-post-head-actions-dropdown-option-icon">
                                            <FontAwesomeIcon
                                              icon={faThumbtack}
                                            />
                                          </div>
                                          Pin to top
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {state.post.userProfile.handle ===
                                  userData.user.handle &&
                                !state.post.drive &&
                                state.post.publish_date === null &&
                                !checkVisible() ? (
                                  <>
                                    <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={async () => moveToDriveModal()}
                                    >
                                      <>
                                        <div className="fl-post-head-actions-dropdown-option-icon">
                                          <FontAwesomeIcon icon={faUpload} />
                                        </div>
                                        Move to Drive
                                      </>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {state.post.publish_date ? (
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={handleScheduledPostNow}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faRocket} />
                                      </div>
                                      Post now
                                    </>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {state.post.publish_date ? (
                                  <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={() => handleShowScheduleModal()}
                                  >
                                    <>
                                      <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-schedule-hover">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                             width="344.000000pt" height="392.000000pt" viewBox="0 0 344.000000 392.000000"
                                             preserveAspectRatio="xMidYMid meet">

                                          <g transform="translate(0.000000,392.000000) scale(0.100000,-0.100000)"
                                             fill="#000000" stroke="none">
                                            <path d="M926 3845 c-53 -19 -85 -44 -121 -94 -30 -43 -30 -43 -33 -207 l-3
                                                -164 -183 0 c-100 0 -209 -5 -241 -11 -103 -19 -176 -65 -229 -143 -57 -84
                                                -66 -133 -66 -363 l0 -203 1680 0 1680 0 0 173 c0 205 -10 271 -50 354 -36 73
                                                -81 115 -170 157 l-65 31 -216 3 -217 4 -4 151 c-4 169 -14 202 -79 265 -89
                                                87 -263 76 -344 -20 -49 -58 -55 -85 -55 -248 l0 -150 -479 0 -479 0 -4 153
                                                c-3 131 -7 158 -25 193 -58 110 -182 160 -297 119z" fill="#ff0d7290"/>
                                            <path d="M50 1377 c0 -1156 -3 -1101 66 -1203 54 -80 129 -126 235 -144 45 -8
                                                471 -10 1419 -8 l1355 3 65 31 c36 17 83 46 103 63 46 41 94 138 107 216 6 36
                                                10 458 10 1073 l0 1012 -1680 0 -1680 0 0 -1043z m2250 527 c64 -35 114 -98
                                                136 -173 33 -110 0 -201 -117 -317 l-69 -69 -43 45 c-24 25 -112 116 -197 203
                                                l-154 157 64 66 c38 37 90 78 125 95 54 28 67 30 131 26 53 -3 83 -11 124 -33z
                                                m-307 -641 l87 -88 -282 -282 c-169 -167 -299 -289 -323 -301 -54 -27 -343
                                                -92 -377 -85 -37 7 -88 66 -88 101 0 15 19 106 41 202 l42 175 290 292 290
                                                292 116 -109 c64 -60 155 -148 204 -197z" fill="#ff0d7290"/>
                                          </g>
                                        </svg>
                                      </div>
                                      Edit schedule
                                    </>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {state.post.userProfile.handle ===
                                  userData.user.handle && !checkPostType() ? (
                                  <>
                                    <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={() =>
                                        shareSinglePost(state.post)
                                      }
                                    >
                                      <>
                                        <div className="fl-post-head-actions-dropdown-option-icon">
                                          <FontAwesomeIcon icon={faShare} />
                                        </div>
                                        Share file
                                      </>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="fl-post-head-actions-dropdown-option"
                                  onClick={() =>
                                    copyLink(`/post/${state.post.id}`)
                                  }
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faClone} />
                                  </div>
                                  Copy link
                                </div>
                                {state.post.userProfile.handle ===
                                userData.user.handle ? (
                                  <>
                                    <div
                                      className="fl-post-head-actions-dropdown-option"
                                      onClick={() => openDeleteModal()}
                                    >
                                      <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                                      </div>
                                      Delete{" "}
                                      {state.post.drive ? "forever" : "post"}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </SimpleCard>
                            </div>
                          ) : (
                            ""
                          )}
                        </div> : "" }
                        <div className={`fl-gotopost-comments-header ${state.post.meta.is_reported ? "fl-post-disabled" : ""}`}>
                          <div className="fl-gotopost-post-header-image">
                            <ProfileHolder
                              src={state.post.userProfile.profileImage?.url}
                              userProfile={
                                state.post.userProfile.online_status
                                  ? state.post.userProfile
                                  : ""
                              }
                              profiles
                            />
                          </div>
                          <div className="fl-gotopost-post-header-text">
                            <Link
                              to={`/${state.post.userProfile.handle}`}
                              className={`fl-gotopost-post-header-name fl-gotopost-post-header-name-${userData.currentTheme}`}
                            >
                              {state.post.userProfile.name}
                            </Link>
                            <div className={`fl-gotopost-username-time fl-gotopost-pending-${state?.post?.postAttachments.length ? "attachments" : "text"}`}>
                                <div className={`fl-gotopost-time`}>
                                  <Link
                                      to={`/${state.post.userProfile.handle}`}
                                      className={`fl-gotopost-post-header-username fl-gotopost-post-header-username-${userData.currentTheme}`}
                                  >
                                    @{state.post.userProfile.handle}
                                  </Link>
                                  <div className="fl-post-head-info-dot"></div>
                                  <div className="fl-gotopost-post-header-time">
                                    <CheckTime data={state.post.created_at} />
                                  </div>
                                </div>
                                {!checkVisible() && state.post.publish_date === null ? (
                                    <div className={"fl-gotopost-pending"}>
                                      {userData.gridView || (
                                        <span className="fl-write-post-pending">
                                        <b>Pending approval</b>
                                        <FontAwesomeIcon
                                            className="fl-write-post-pending-icon"
                                            icon={faClock}
                                        />
                                      </span>
                                      )}
                                    </div>
                                ) : (
                                    ""
                                )}
                              </div>
                            {state.post.publish_date !== null ?
                                <div
                                    className="fl-write-post-schedule-date-single"
                                    onClick={() => handleShowScheduleModal()}
                                >
                                  {userData.gridView || (
                                      <span
                                          className={`fl-write-post-pending fl-write-post-schedule-date  fl-blue-link fl-hover-underline`}
                                      >
                                      <b>
                                        Post scheduled for{" "} {scheduledDate()}
                                      </b>
                                    </span>
                                  )}
                                  <ReactTooltip />
                                </div>
                                : ""
                            }
                            {state?.pinned ? (
                              <div className="fl-gotopost-pin fl-mt-5">
                                <FontAwesomeIcon icon={faThumbtack} /> Pinned
                                post
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {state.post.text ? (
                          <div
                            className={`fl-gotopost-comments-text fl-gotopost-comments-text-${userData.currentTheme}`}
                          >
                            <PostBody body={state.post.text} />
                          </div>
                        ) : (
                          ""
                        )}
                        {state.post.userProfile.meta.is_blocked_by || userData.authStatus === 'UNAUTHENTICATED' ? "" : (
                          <>
                            <div
                              className={`fl-gotopost-post-actions fl-gotopost-post-actions-${userData.currentTheme} ${state.post.meta.is_reported || !checkVisible() || !shouldUserSeeThePost() ? "fl-disable-actions" : ""}`}
                            >
                              <div
                                className={`fl-gotopost-post-action fl-gotopost-post-action-${userData.currentTheme}`}
                                onClick={() => state.post.meta.is_reported || !checkVisible() ? "" : likeDislike()}
                              >
                                <div className="fl-gotopost-post-action-icon">
                                  {state.liked ? (
                                    <FontAwesomeIcon
                                      className="fl-gotopost-post-comment-single-data-text-action-icon fl-gotopost-post-comment-single-data-text-action-icon-active"
                                      icon={faHeartSolid}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {!state.liked ? (
                                    <FontAwesomeIcon
                                      className="fl-gotopost-post-comment-single-data-text-action-icon"
                                      icon={faHeart}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <span>{state.likeOffset}</span>
                              </div>
                              <div
                                className={`fl-gotopost-post-action fl-gotopost-post-action-${userData.currentTheme}`}
                                onClick={() => state.post.meta.is_reported || checkVisible() ? "" : focusComment()}
                              >
                                <div className="fl-gotopost-post-action-icon">
                                  <FontAwesomeIcon icon={faCommentAlt} />
                                </div>
                                <span>{state.post.meta.total_comments}</span>
                              </div>
                            </div>
                            <div
                              className={`fl-gotopost-post-comment-form-${userData.currentTheme} fl-gotopost-post-comment-form ${state.post.meta.is_reported || !checkVisible() || !shouldUserSeeThePost() ? "fl-disable-actions" : ""}`}
                            >
                              <div
                                className={`fl-gotopost-post-comment-form-row`}
                              >
                                <div
                                  className="fl-gotopost-post-comment-form-picker"
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      emojis: !state.emojis,
                                    })
                                  }
                                  ref={emojiRef}
                                >
                                  <FontAwesomeIcon icon={faSmile} />
                                </div>
                                <div
                                    className="fl-gotopost-post-picker"
                                    style={{
                                      display: state.emojis ? "block" : "none",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                  <Picker
                                      onEmojiClick={(e, emoji) => {
                                        setState({
                                          ...state,
                                          comment: `${state.comment}${emoji.emoji}`,
                                        });
                                      }}
                                  />
                                </div>
                                <div
                                  className="fl-gotopost-post-comment-form-input"
                                  ref={commentRef}
                                >
                                  {userData.user.profile.meta.verified_state !== "verified" ? (
                                      <Textarea
                                          onKeyDown={(e: any) => {
                                            e.key === "Escape" &&
                                            cancelComment()
                                          }}
                                          placeholder={`Add a comment...`}
                                          className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                                          value={state.comment}
                                          onChange={(
                                              e: React.FormEvent<HTMLInputElement>
                                          ) => state.post.meta.is_reported || !checkVisible() ? "" : typeComment(e.currentTarget.value)}
                                      />
                                  ) : (
                                      <Tagging setText={typeComment} text={state.comment} placeholder={`Add a comment...`} keydownFunction={cancelComment} />
                                  )}
                                </div>
                                <div
                                  className={`fl-gotopost-post-comment-form-post ${state.comment.trim() === "" ? "fl-gotopost-post-comment-form-post-disabled" : ""}`}
                                  onClick={() =>
                                    state.comment.trim() === "" &&
                                    !editComment.edit && state.post.meta.is_reported
                                      ? ""
                                      : postComment()
                                  }
                                >
                                  Post
                                </div>
                              </div>
                            </div>
                            {state.comments.length > 0 ?
                                <div className={`fl-gotopost-post-comment-list-outer ${state.post.meta.is_reported ? "fl-post-disabled" : ""}`}>
                                  <div
                                      className={`fl-gotopost-post-comment-list ${
                                          checkPostType() &&
                                          "fl-gotopost-post-comment-list-text"
                                      }`}
                                  >
                                    {state.comments.map((x: any, y: number) => (
                                        <GoToPostComment
                                            listenComment={listenComment}
                                            replyState={replyState}
                                            setReplyState={setReplyState}
                                            key={y}
                                            {...x}
                                            pId={postId}
                                            stateComments={state}
                                            setStateComments={setState}
                                            editComment={editComment}
                                            setEditComment={setEditComment}
                                            updateComments={props.updateComments}
                                            setUpdateComments={props.setUpdateComments}
                                            idPost={id}
                                            commentRef={commentRef}
                                            cancelComment={cancelEditComment}
                                            typeCommentEdit={typeCommentEdit}
                                            processUpdate={processUpdate}
                                            animationEdit={animationPost}
                                            postCommentReply={postCommentReply}
                                            animationPostReply={animationPostReply}
                                            postUser={state?.post?.userProfile?.id}
                                            commnetId={commentId}
                                        />
                                    ))}
                                  </div>
                                </div> : ""
                            }
                          </>
                        )}
                      </div>
                    </SimpleCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DatePickerScheduled
        label="Edit Schedule"
        value={scheduleDate}
        scheduledTimeValue={scheduleTime}
        onSave={handleScheduleDate}
        hideInputField={true}
        showModalInitialy={showScheduleModal}
        isScheduleModal={true}
        handleCloseOther={handleCloseDateModal}
        className="fl-settings-sub-account-field-input"
        min={new Date()}
        oldDate={state?.post?.publish_date}
      />
    </div>
  );
};

const GoToPostComment: FC<any> = ({
  extended = true,
  commentRef,
  id,
  setReplyState,
  userProfile,
  meta,
  created_at,
  text,
  pId,
  stateComments,
  setStateComments,
  setEditComment,
  editComment,
  replies,
  cancelComment,
  typeCommentEdit,
  processUpdate,
  animationEdit,
  postCommentReply,
  replyState,
  animationPostReply,
  postUser,
  commnetId
}) => {

  const checkOpenReplies = () => {
    if(replies.find((x:any) => x.id === Number(commnetId))) {
      return true
    } else {
      return false
    }
  }

  const [state, setState] = useState<any>({
    statusDropdown: false,
    showReplies: checkOpenReplies() ? true : false
  });

  const postId = useParams<{ [key: string]: string }>().id || pId;

  const envData = useEnvContext();
  const userData = useUserContext();
  const modalData = useModalContext();

  const toggleReplies = async () => {
    setState({...state, showReplies: !state.showReplies,})
  };

  const likeDislike = async () => {
    setStateComments({
      ...stateComments,
      comments: stateComments.comments.map((x: any) =>
          x.id === id ? { ...x, meta: {...x.meta, is_liked: !x.meta.is_liked, likes: x.meta.is_liked ? x.meta.likes - 1 : x.meta.likes + 1 } } : x
      ),
    });
    await axios.post(`${envData.apiUrl}/posts/${postId}/comments/${id}/like`, {
      like: !meta.is_liked,
    });
  };

  const [comment, setComment] = useState<any>({
    dropdown: false,
  });

  const removeComment = async (commentId: number, action = "comment") => {
    try {
      await Axios.delete(`${envData.apiUrl}/posts/${postId}/comments/${commentId}`);
      if(action === "comment") {
        setStateComments({
          ...stateComments,
          comments: stateComments.comments.filter((x: any) => x.id !== id),
          post: { ...stateComments.post, meta: {...stateComments.post.meta, total_comments: stateComments.post.meta.total_comments - (meta.comments + 1)} }
        });
      } else {
        setStateComments({
          ...stateComments,
          comments: stateComments.comments.map((x: any) =>
              x.id === id ? { ...x, meta: {...x.meta, comments: x.meta.comments - 1 },  replies: x.replies.filter((x: any) => x.id !== commentId) } : x
          ),
          post: { ...stateComments.post, meta: {...stateComments.post.meta, total_comments: stateComments.post.meta.total_comments - 1} },
        });
      }
      setComment({ ...comment, dropdown: false });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const editCommentFunction = (text: string, id: number,action:string,commentId:any) => {
    setEditComment({ ...editComment, edit: true, text: text, id: id,action:action, commentId:commentId });
    setStateComments({
      ...stateComments,
      editCommentText: text,
      oldText:text
    });
    setComment({ ...comment, dropdown: false });
    setTimeout(() => {
      if (commentRef.current !== null) {
        commentRef.current.querySelector("textarea")?.focus();
      }
    },500)
  };

  const [emojiState, setEmojiState] = useState({
    active: false,
  });

  const emojiRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const trackClick = (e: MouseEvent) => {
      if(emojiRef.current && e.target !== null){
        //@ts-ignore
        if(e.target !== emojiRef.current && !emojiRef.current.contains(e.target)){
          setEmojiState({ active: false });
        }
      }
    };
    window.addEventListener("click", trackClick);
    return () => {
      window.removeEventListener("click", trackClick);
    };
  }, []);

  const typeReplay = (text:any) => {
    setStateComments({
      ...stateComments,
      replyComment: text,
    })
  }

  const cancelReplyComment = () => {
    setStateComments({
      ...stateComments,
      replyComment: "",
    })
    setReplyState(null)
  }

  return (
    <div
      className="fl-gotopost-post-comment-single"
      tabIndex={1}
      onBlur={() => setComment({ ...comment, dropdown: false })}
    >
      <div className="fl-gotopost-post-comment-single-image">
        <ProfileHolder
          src={userProfile.profileImage?.url}
          userProfile={userProfile.online_status ? userProfile : []}
        />
      </div>
      <div className="fl-gotopost-post-comment-single-data">
        <Link
          to={`/${userProfile.handle}`}
          className={`fl-gotopost-post-comment-single-data-name fl-gotopost-post-comment-single-data-name-${userData.currentTheme}`}
        >
          {userProfile.name} <span>@{userProfile.handle}</span>
        </Link>
        <div className="fl-gotopost-post-comment-single-data-text">
          <div
            className={`fl-gotopost-post-comment-single-data-text-data fl-gotopost-post-comment-single-data-text-data-${userData.currentTheme}`}
          >
            {editComment.edit && editComment.id === id ?
                <EditComment cancelComment={cancelComment} stateComments={stateComments} typeCommentEdit={typeCommentEdit} processUpdate={processUpdate} commentRef={commentRef} animationEdit={animationEdit} />
                :
                <PostBody body={text} />
            }
          </div>
        </div>
        <div className="fl-gotopost-post-comment-single-data-name-time">
          <span id="fl-gotopost-time">
            <CheckTime data={created_at} />
          </span>
          {meta.likes > 0 ? (
            <span id="fl-gotopost-likes-number">{`${meta.likes} ${
                meta.likes === 1 ? "like" : "likes"
            }`}</span>
          ) : (
            ""
          )}
          {extended ? (
            <span onClick={() => setReplyState({ id, userProfile, text })}>
              Reply
            </span>
          ) : (
            ""
          )}
          <span onClick={likeDislike}>
            <FontAwesomeIcon
              className={`fl-gotopost-post-comment-single-data-text-action-icon ${meta.is_liked ? "fl-gotopost-post-comment-single-data-text-action-icon-active" : ""}`}
              icon={meta.is_liked ? faHeartSolid : faHeart}
            />{" "}
            Like
          </span>
        </div>
        {extended ? (
          <Fragment>
            {replyState && replyState.id === id && (
                <div className="fl-post-comments-reply">
                  <div className={`fl-simple-comment-make-all fl-post-comments-${userData.currentTheme}`}>
                    <div
                        className="fl-post-comment-emojis"
                        onClick={() => setEmojiState({ active: !emojiState.active })}
                        ref={emojiRef}
                    >
                      <FontAwesomeIcon icon={faSmile} />
                    </div>
                    <div
                        className="fl-post-comment-emojis-picker"
                        style={{ display: emojiState.active ? "block" : "none" }}
                        onClick={(e) => e.stopPropagation()}
                    >
                      <Picker
                          onEmojiClick={(e, emoji) => {
                            setStateComments({
                              ...stateComments,
                              replyComment: `${stateComments.replyComment}${emoji.emoji}`,
                            });
                          }}
                      />
                    </div>
                    {userData.user.profile.meta.verified_state !== "verified" ? (
                        <Textarea
                            onKeyDown={(e: any) => {
                              e.key === "Escape" &&
                              cancelComment()
                            }}
                            placeholder="Add a reply..."
                            className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                            value={stateComments.replyComment}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => typeReplay(e.currentTarget.value)
                            }
                            onFocus={(e:any) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                        />
                    ) : (
                        <Tagging setText={typeReplay} text={stateComments.replyComment}  placeholder="Add a reply..." />
                    )}
                    <div
                        className={`fl-simple-comment-reply-cancel fl-simple-comment-reply-cancel-${userData.currentTheme}`}
                        onClick={() => cancelReplyComment()}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                  <div className="fl-post-comments-make-buttons">
                    <Button
                        className="fl-post-comments-make-button"
                        type="normal"
                        onClick={() => {postCommentReply();toggleReplies()}}
                        disabled={stateComments.replyComment.trim() === "" || animationPostReply}
                    >
                      Post
                      {animationPostReply ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                    </Button>
                  </div>
                </div>
            )}
            {meta.comments > 0 ? (
              <div className="fl-gotopost-post-comment-single-data-comments">
                <span onClick={toggleReplies}>
                  {state.showReplies
                    ? `Hide replies`
                    : `View replies (${meta.comments})`}
                </span>
              </div>
            ) : (
              ""
            )}
            {state.showReplies ? (
              <div className="fl-gotopost-post-comment-single-data-comments-replies">
                {replies.map((x: any, y: number) => (
                  <GoToPostReplyComment key={y} {...x} extended={false} state={stateComments} setState={setStateComments} postId={postId} removeComment={removeComment}
                                        editCommentFunction={editCommentFunction} commentId={id} editComment={editComment} cancelComment={cancelComment} stateComments={stateComments}
                                        typeCommentEdit={typeCommentEdit} processUpdate={processUpdate} commentRef={commentRef} animationEdit={animationEdit} postUser={postUser} />
                ))}
              </div>
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          ""
        )}
      </div>
      {(userData.user.profile.meta.verified_state === "verified" && postUser === userData.user.id) || userProfile.handle === userData.user.handle ? (
        <div className="fl-simple-comment-dropdown">
          <FontAwesomeIcon
            className="fl-post-head-actions-icon"
            icon={faChevronDown}
            onClick={() =>
              setComment({ ...comment, dropdown: !comment.dropdown })
            }
          />
          {comment.dropdown && (
            <div
              className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
            >
              <SimpleCard className="fl-post-head-actions-dropdown-inner">
                {userProfile.handle === userData.user.handle &&
                <div
                    className="fl-post-head-actions-dropdown-option"
                    onClick={() => editCommentFunction(text, id,"comment",0)}
                >
                  <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-edit-hover">
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                         width="396.000000pt" height="346.000000pt" viewBox="0 0 396.000000 346.000000"
                         preserveAspectRatio="xMidYMid meet">

                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#ff0d7290" stroke="none">
                        <path d="M1860 3399 c-452 -27 -872 -180 -1186 -433 -366 -294 -574 -696 -574-1110 0
                            -335 121 -649 354 -916 36 -41 66 -78 66 -82 0 -18 -67 -192 -97 -253-65 -127 -258 -413 -308 -455 -21 -18 -19 -64 5 -85 18 -16 32 -17 123 -11
                            142 9 263 30 385 66 150 45 392 169 527 271 l52 39 38 -16 c59 -24 297 -82
                            420 -101 153 -25 524 -24 685 1 584 89 1049 361 1337 783 218 318 286 684 198
                            1051 -64 265 -196 484 -425 705 -134 130 -253 214 -440 310 -346 180 -746 261
                            -1160 236z m738 -736 c21 -9 76 -56 124 -103 105 -104 133 -158 125 -232 -8
                            -66 -28 -96 -147 -213 l-100 -100 -210 210 -210 210 103 103 c56 56 120 111
                            142 122 49 25 124 26 173 3z m-548 -1196 l-385 -383 -223 -44 c-252 -49 -272
                            -48 -272 13 0 19 15 110 34 203 18 93 37 187 40 209 7 36 44 76 389 423 l382
                            382 210 -210 210 -210 -385 -383z"/>
                      </g>
                    </svg>
                  </div>
                  Edit comment
                </div>
                }
                <div
                  className="fl-post-head-actions-dropdown-option"
                  onClick={() => removeComment(id)}
                >
                  <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                  </div>
                  Delete comment
                </div>
              </SimpleCard>
            </div>
          )}
        </div>
      ) : ""}
    </div>
  );
};

const GoToPostReplyComment: FC<any> = ({
                                    extended = true,
                                    id,
                                    setReplyState,
                                    userProfile,
                                    meta,
                                    created_at,
                                    text,
                                    state,
                                    setState,
                                    postId,
                                    removeComment,
                                    editCommentFunction,
                                    commentId,editComment,cancelComment,stateComments,typeCommentEdit,processUpdate,commentRef,animationEdit,
                                    postUser
                                  }) => {
  const [stateReply, setStateReply] = useState<any>({
    statusDropdown: false,
  });

  const envData = useEnvContext();
  const userData = useUserContext();

  const likeDislike = async () => {
    setState({
      ...state,
      comments: state.comments.map((x: any) =>
          x.id === commentId ? { ...x, replies: x.replies.map((x:any) => x.id === id ? { ...x, meta: {...x.meta, is_liked: !x.meta.is_liked, likes: x.meta.is_liked ? x.meta.likes - 1 : x.meta.likes + 1 } } : x)} : x
      ),
    });
    await axios.post(`${envData.apiUrl}/posts/${postId}/comments/${id}/like`, {
      like: !meta.is_liked,
    });
  };

  return (
      <div
          className="fl-gotopost-post-comment-single"
          tabIndex={1}
          onBlur={() => setStateReply({ ...stateReply, dropdown: false })}
      >
        <div className="fl-gotopost-post-comment-single-image">
          <ProfileHolder
              src={userProfile.profileImage?.url}
              userProfile={userProfile.online_status ? userProfile : []}
          />
        </div>
        <div className="fl-gotopost-post-comment-single-data">
          <Link
              to={`/${userProfile.handle}`}
              className={`fl-gotopost-post-comment-single-data-name fl-gotopost-post-comment-single-data-name-${userData.currentTheme}`}
          >
            {userProfile.name} <span>@{userProfile.handle}</span>
          </Link>
          <div className="fl-gotopost-post-comment-single-data-text">
            <div
                className={`fl-gotopost-post-comment-single-data-text-data fl-gotopost-post-comment-single-data-text-data-${userData.currentTheme}`}
            >
              {editComment.edit && editComment.id === id ?
                  <EditComment cancelComment={cancelComment} stateComments={stateComments} typeCommentEdit={typeCommentEdit} processUpdate={processUpdate} commentRef={commentRef} animationEdit={animationEdit} />
                  :
                  <PostBody body={text} />
              }
            </div>
          </div>
          <div className="fl-gotopost-post-comment-single-data-name-time">
          <span id="fl-gotopost-time">
          <CheckTime data={created_at} />
          </span>
            {meta.likes > 0 ? (
                <span id="fl-gotopost-likes-number">{`${meta.likes} ${
                    meta.likes === 1 ? "like" : "likes"
                }`}</span>
            ) : (
                ""
            )}
            {extended ? (
                <span onClick={() => setReplyState({ id, userProfile, text })}>
                  Reply
                </span>
            ) : (
                ""
            )}
            <span onClick={likeDislike}>
            <FontAwesomeIcon
                className={`fl-gotopost-post-comment-single-data-text-action-icon ${meta.is_liked ? "fl-gotopost-post-comment-single-data-text-action-icon-active" : ""}`}
                icon={meta.is_liked ? faHeartSolid : faHeart}
            />{" "}
              Like
          </span>
          </div>
        </div>
        {(userData.user.profile.meta.verified_state === "verified" && postUser === userData.user.id)  || userProfile.handle === userData.user.handle ? (
            <div className="fl-simple-comment-dropdown">
              <FontAwesomeIcon
                  className="fl-post-head-actions-icon"
                  icon={faChevronDown}
                  onClick={() =>
                      setStateReply({ ...stateReply, dropdown: !stateReply.dropdown })
                  }
              />
              {stateReply.dropdown && (
                  <div
                      className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                  >
                    <SimpleCard className="fl-post-head-actions-dropdown-inner">
                      {userProfile.handle === userData.user.handle &&
                      <div
                          className="fl-post-head-actions-dropdown-option"
                          onClick={() => {setStateReply({ ...stateReply, dropdown: false });editCommentFunction(text, id,"reply",commentId)}}
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-edit-hover">
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                               width="396.000000pt" height="346.000000pt" viewBox="0 0 396.000000 346.000000"
                               preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#ff0d7290" stroke="none">
                              <path d="M1860 3399 c-452 -27 -872 -180 -1186 -433 -366 -294 -574 -696 -574-1110 0
                            -335 121 -649 354 -916 36 -41 66 -78 66 -82 0 -18 -67 -192 -97 -253-65 -127 -258 -413 -308 -455 -21 -18 -19 -64 5 -85 18 -16 32 -17 123 -11
                            142 9 263 30 385 66 150 45 392 169 527 271 l52 39 38 -16 c59 -24 297 -82
                            420 -101 153 -25 524 -24 685 1 584 89 1049 361 1337 783 218 318 286 684 198
                            1051 -64 265 -196 484 -425 705 -134 130 -253 214 -440 310 -346 180 -746 261
                            -1160 236z m738 -736 c21 -9 76 -56 124 -103 105 -104 133 -158 125 -232 -8
                            -66 -28 -96 -147 -213 l-100 -100 -210 210 -210 210 103 103 c56 56 120 111
                            142 122 49 25 124 26 173 3z m-548 -1196 l-385 -383 -223 -44 c-252 -49 -272
                            -48 -272 13 0 19 15 110 34 203 18 93 37 187 40 209 7 36 44 76 389 423 l382
                            382 210 -210 210 -210 -385 -383z"/>
                            </g>
                          </svg>
                        </div>
                        Edit comment
                      </div>
                      }
                      <div
                          className="fl-post-head-actions-dropdown-option"
                          onClick={() => {removeComment(id,"reply");setStateReply({ ...stateReply, dropdown: false })}}
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon fl-post-head-actions-dropdown-option-icon-delete-hover">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"  fill="#ff0d7290"/></svg>
                        </div>
                        Delete comment
                      </div>
                    </SimpleCard>
                  </div>
              )}
            </div>
        ) : ""}
      </div>
  );
};

export const PinUnpinModal: FC<any> = ({state,setState}) => {

  const modalData = useModalContext()
  const envData = useEnvContext()
  const [animation,setAnimation] = useState(false)

  const pinUnpinFunction = async () => {
    modalData.clearToasts()
    setAnimation(true)
    try {
      await Axios.post(`${envData.apiUrl}/posts/${state.post.id}/pin`);
      modalData.pushToast(
          "success",
          `${state.pinned ? "Your Post was unpinned from the top of your profile." : "Your Post was pinned to the top of your profile."}`
      );
      setState({ ...state, pinned: !state.pinned, dropdown: false });
      setAnimation(false)
      modalData.close()
    } catch (e) {
      setAnimation(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  }

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">
            {state.pinned ? "Unpin Post from top?" : "Pin Post to top?"}
          </div>
        </div>
        <div className="fl-modal-description">
          { state.pinned ? "This post will no longer appear automatically at the top of your profile." : "This Post will appear at the top of your profile and replace any previously pinned Post."}
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>
            Cancel
          </Button>
          <Button
              type="normal"
              onClick={() => pinUnpinFunction()}
              disabled={animation}
          >
            {state.pinned ? "Unpin" : "Pin"}
            {animation ?
                <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> : ""
            }
          </Button>
        </div>
      </React.Fragment>
  );
};

export const EditComment: FC<any> = ({cancelComment,stateComments,typeCommentEdit,processUpdate,commentRef,animationEdit}) => {

  const userData = useUserContext()


  return (
      <div>
        <div className="fl-simple-comment-make-all" ref={commentRef} >
          {userData.user.profile.meta.verified_state !== "verified" ? (
              <Textarea
                  onKeyDown={(e: any) => {
                    e.key === "Escape" &&
                    cancelComment()
                  }}
                  className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                  placeholder="Edit a comment"
                  value={stateComments.editCommentText}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => typeCommentEdit(e.currentTarget.value)}
              />
          ) : (
              <Tagging setText={typeCommentEdit} text={stateComments.editCommentText} placeholder="Edit a comment" keydownFunction={cancelComment}/>
          )}
          <div
              className={`fl-simple-comment-reply-cancel fl-simple-comment-reply-cancel-${userData.currentTheme}`}
              onClick={() => cancelComment()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <p className={"fl-post-comments-cancel"}>
          Press ESC to  <span className={`fl-blue-link fl-hover-underline`} onClick={() => cancelComment()}>cancel.</span>
        </p>
        <div className="fl-post-comments-make-buttons">
          <Button
              className="fl-post-comments-make-button"
              type="normal"
              onClick={() => processUpdate(stateComments.post.id)}
              disabled={animationEdit || stateComments.editCommentText === stateComments.oldText || stateComments.editCommentText.length === 0}
          >
            Edit
            {animationEdit ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </div>
  );
};

