import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react'
import { useHistory, useParams} from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './Verify.css';
import {Button} from "../components/Button";

export const UpdateMail:FC<{}> = () => {

    const userData = useUserContext();
    const history = useHistory();

    const { email } = useParams<{email: string}>();


    const redirectAccount = () => {
        if(userData.authStatus === 'AUTHENTICATED') {
            history.push(`/${userData.user.handle}`)
        }else {
            history.push(`/login`)
        }
    }

    return (
        <div className="fl-fullscreen-with-footer fl-fullscreen-with-height-fix">
            <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                <FontAwesomeIcon className="fl-not-found-icon fl-green-icon" icon={faCheck}/>
                <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Verification successful!</div>
                <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>
                    <div className="fl-verify-text">Congratulations, your new email address: <br/><br /> <span>{email}</span> has been verified.</div>
                    <Button
                        onClick={redirectAccount}
                        type="normal"
                        className="fl-settings-sub-security-button fl-settings-update-mail fl-verify-button"
                    >
                        DONE
                    </Button>
                </div>
            </div>
        </div>
    )
}
