import React, { FC, Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation  } from 'react-router-dom';
import { Button } from '../components/Button';
import { useUserContext } from '../context/UserContext';
import validator from 'validator';
import { useModalContext } from '../context/ModalContext';
import './Forgot.css';

// ASSETS
import imageSourceLight from '../assets/images/9.jpg';
import imageSourceDark from '../assets/images/10.jpg';
import imageSource2Light from '../assets/images/11.jpg';
import imageSource2Dark from '../assets/images/12.jpg';
import { useEnvContext } from '../context/EnvContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MInput } from '../components/MInput';
import axios from 'axios';
import ReactLoading from "react-loading";

export const hideEmail = (email: string) => {
    return email.replace(/(.{2})(.*)(?=@)/,
      function(gp1, gp2, gp3) { 
        for(let i = 0; i < gp3.length; i++) { 
          gp2+= "*"; 
        } return gp2; 
      });
};

export const Forgot:FC<any> = (props) => {

    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    const history = useHistory();

    const [email, setEmail] = useState((new URLSearchParams(useLocation().search)).get('email') ?? '');
    const [view, setView] = useState("form");
    const [proccessing, setProccessing] = useState(false);

    const [forceErrors, setForceErrors] = useState(false);

    const [postResult, setPostResult] = useState("");

    const processForgot = async () => {
        setProccessing(true)
        try {
            if(validator.isEmpty(email)) {
                setProccessing(false);
                return setForceErrors(true)
            }
            let postResponse: boolean | string = await postData();
            if(postResponse){
                setView("check");
            }
        } catch(e) {
            if(e.response?.data.errors.error) {
                modalData.pushToast("error", e.response.data.errors.error[0]);
            }
            modalData.pushToast("error", "Something went wrong!");
        }
        setProccessing(false);
    }

    const postData = async () => {

        if(!validator.isEmail(email) || !additionalEmailCheck(email)) {
            setForceErrors(true)
            setPostResult("invalid format");
            return false;
        }

        let result = false;
        await axios.post(`${envData.apiUrl}/auth/password/forgotten`, {email: email}).then((response) => {
            if(response.data.email === 'sent successfully') {
                setPostResult('sent');
                result = true;
            }
        }).catch(err => {
            setView((prevState) => {return "form"});
            if(err.response.data.errors?.email?.includes('The selected email is invalid.')) {
                setForceErrors(true);
                setPostResult('not found');
            } else if(err.response.data.errors?.error[0]?.includes('wait')) {
                setForceErrors(true);
                setPostResult('wait');
            }
        });
        return result;
        
    }

    useEffect(() => {
        if(email) {
            setView((prevState) => "check");
        }
    }, [])

    const goBack = () => {
        if(userData.authStatus === 'AUTHENTICATED') {
            history.goBack()
        } else {
            history.push('/login')
        }
    }

    return (
        <div className="fl-fullscreen-with-footer">
            <div className="fl-fullscreen-with-footer-inner fl-container">
                <div className="fl-fullscreen-with-footer-bg">
                    <div className="fl-fullscreen-with-footer-circle-orange"></div>
                    <div className="fl-fullscreen-with-footer-circle-pink"></div>
                </div>
                <div className="fl-fullscreen-with-footer-content">
                    { view === 'form' ? <Fragment>
                        <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
                            <div className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}>Password reset</div>
                            <div className="fl-forgot-mb">Please enter your email address and we’ll send you a link to reset your password.</div>
                            <MInput  className={"fl-settings-mt-30"} forceErrors={forceErrors} validator={[

                                { 
                                    check: (v) => !validator.isEmpty(v)
                                    , message: "Enter an email address"},
                                { 
                                    check: (v) => {
                                        return !(postResult === 'invalid format')
                                    }
                                    , message: "Enter a valid e-mail address."},
                                { 
                                    check: (v) => {
                                        return !(postResult === 'not found')
                                    }
                                    , message: "Couldn't find your Fluffa account."
                                },
                                {
                                    check: (v) => {
                                        return !(postResult === 'wait')
                                    }
                                    , message: "Please wait a few minutes before trying to reset your password again."
                                }

                            ]}
                             validatingMessage="Enter email" label="Email" value={email} setValue={(value) => {setEmail(value); setPostResult("")}}/>
                            {/* <div className="fl-fullscreen-with-footer-content-link-text">By signing up you agree to our <Link className="fl-fullscreen-with-footer-content-link" to="/terms">Terms</Link> and <Link className="fl-fullscreen-with-footer-content-link" to="/">Privacy Policy</Link></div> */}
                            <Button style={{margin: "20px 0px", width: "calc(50% - 5px)", position: 'relative'}} type="normal" onClick={processForgot} disabled={proccessing}>
                                SEND
                                {proccessing && <span style={{marginLeft: '5px' }}><ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> </span>}
                            </Button>
                            <div className="fl-fullscreen-with-footer-content-link-text fl-text-underline"><p className="fl-fullscreen-with-footer-content-link" onClick={() => goBack()}>Cancel</p></div>
                        </div>
                        <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-fulscreen-with-footer-content-part-image">
                            {userData.currentTheme === 'light' ? <img className="fl-fullscreen-with-footer-content-image" src={imageSourceLight} alt="Register"/> : ''}
                            {userData.currentTheme === 'dark' ? <img className="fl-fullscreen-with-footer-content-image" src={imageSourceDark} alt="Register"/> : ''}
                        </div>
                    </Fragment> : <Fragment>
                        <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
                            <div className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}>Password reset email sent <FontAwesomeIcon style={{color: "#53A451"}} icon={faCheck}/></div>
                            <div className="fl-forgot-mb">An email has been sent to your email address <span>{hideEmail(email)}</span>. Click the link in the email to reset your password{userData.authStatus === 'AUTHENTICATED' ? '.' : ' and then log in to Fluffa again.'}</div>
                            {/* <div className="fl-forgot-mb">If there’s a registered account associated with the email address provided, we’ll send you a link to reset your password.</div>
                            <div className="fl-forgot-mb">So, please update to only one of the messages based on how our backend works.</div> */}
                            <Button style={{margin: "10px 0px", width: "calc(50% - 5px)"}} type="normal" to={userData.authStatus === 'AUTHENTICATED' ? '/settings/security' : '/login'}>DONE</Button>
                            <div className="fl-fullscreen-with-footer-content-link-text">*If you can't find it, check your spam folder</div>
                        </div>
                        <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-fulscreen-with-footer-content-part-image">
                            {userData.currentTheme === 'light' ? <img className="fl-fullscreen-with-footer-content-image" src={imageSource2Light} alt="Register"/> : ''}
                            {userData.currentTheme === 'dark' ? <img className="fl-fullscreen-with-footer-content-image" src={imageSource2Dark} alt="Register"/> : ''}
                        </div>    
                    </Fragment>}
                </div>
            </div>
        </div>
    )
}

export const additionalEmailCheck = (input: string) => {
    return !/(?:\.co)[^m]?$/.test(input); //checks if the .com address is close to .com and actually invalid in top domain space
};