import React, { FC } from "react";
import { MInput } from "../../../../../components/MInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const Input: FC<any> = ({ message, setMessage, userData,state,restrictedWords,setRestrictedWords }) => {

  const typeMessage = (text:any) => {
    setMessage({ ...message, text })
    if(restrictedWords.length > 0) {
      setRestrictedWords([])
    }
  }

  return (
    <>
        {!state.recording &&
        <MInput
            className={`fl-messages-bulk-people-list-form-textarea fl-bulk__input__text--${userData.currentTheme}`}
            type="textarea"
            label="Type a message..."
            value={message.text}
            setValue={(text) => typeMessage(text)}
        />
        }
      {restrictedWords.length > 0 && (
          <span className="fl-modal-description-row-error">
            <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {restrictedWords.join(',')}
          </span>
      )}
    </>
  );
};

export default Input;
