import React, {FC} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhotoVideo, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../components/Button";

export const TitleActions:FC<any> = (props) => {

    const countSearchChat = () => {
        const res = props.state?.messages?.length
        return res;
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            props.setFindText(props.searchText);
        }
    };

    const closeSearch = async () => {
        props.setSearch(false)
        props.setSearchTextDefault("")
        props.setSearchText("")
        await props.getSingleChatMessages(props.conversationId)
    }

    const galleryActions = [
        {
            text: "all media"
        },
        {
            text: "videos"
        },
        {
            text: "photos"
        }
    ]

    return (
        <div className={`fl-direct-messages-title-actions ${props.search ? "fl-direct-messages-title-actions-show-search" : ""}`}>
            {props.search ?
            <>
                <div className={"fl-direct-messages-title-actions-search-clear"} onClick={() => closeSearch()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <input
                    autoFocus={true}
                    className={`fl-direct-messages-title-actions-search`}
                    placeholder={"Search chat"}
                    type="text"
                    value={props.searchText}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        props.setSearchText(e.currentTarget.value)
                    }
                    onKeyPress={handleKeyPress}
                    onKeyDown={(e: any) => {
                        e.key === "Escape" && closeSearch();
                    }}
                />
                <div className={"fl-direct-messages-title-actions-search-count"}>
                    {props.searchTextDefault.length > 0 ? countSearchChat()+"/"+countSearchChat() : "0/0"}
                </div>
                <Button
                    type="normal"
                    className="fl-button fl-button-normal fl-direct-messages-title-actions-search-button"
                    onClick={() => props.setFindText(props.searchText)}
                    disabled={props.searchText.length === 0 ? true : false}
                >
                    Search
                </Button>
            </>
             : props.gallery ?
                    <div className="fl-direct-messages-filter-media">
                        {galleryActions.map((x:any, y:number) =>
                            <span key={y} onClick={() => props.getFiles(x.text)} className={`${props.filesRef.current.action === x.text ? "fl-direct-messages-filter-media-active" : ""}`}>{x.text === "all media" ? "All media" : `Show ${x.text}`}</span>
                        )}
                    </div>  :
                <>
                 <span className={`${props.gallery && "fl-direct-messages-title-actions-gallery"}`} onClick={() => {props.getFiles("all media",1);props.setGallery(true)}}>
                    <FontAwesomeIcon icon={faPhotoVideo} /> {" "}
                    Media
                 </span>
                 <span onClick={() => props.setSearch(!props.search)}>
                  <FontAwesomeIcon icon={faSearch} /> {" "}
                    Search
                 </span>
               </>
            }
        </div>
    )
}