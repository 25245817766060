import React, {useEffect} from "react";
import {Redirect, Switch, useHistory, useLocation} from "react-router-dom";
import Axios from "axios";
//@ts-ignore
import { Footer } from "./components/Footer";
import { Home } from "./routes/Home";
import { Contact } from "./routes/Contact";
import { Login } from "./routes/Login";
import { Register } from "./routes/Register";
import { ComingSoon } from "./routes/ComingSoon";
import { BanUserPage } from "./routes/BanUserPage";
import { Verify } from "./routes/Verify";
import { UpdateMail } from "./routes/UpdateMail";
import { Preview } from "./routes/Preview";
import Explore from "./routes/Explore/Explore";

import "./global.css";
import "./mobileGlobal.css";
import { Feed } from "./routes/Feed";
import { Preregister } from "./routes/Preregister";
import { BackToTop } from "./components/BackToTop";
import src1 from "./assets/images/5.png";
import { ProtectedRoute } from "./addons/ProtectedRoute";
import { CreatorRoute } from "./addons/CreatorRoute";
import { UnregisteredRoute } from "./addons/UnregisteredRoute";
import { Profile } from "./routes/Profile";
import { Notifications } from "./routes/Notifications/Notifications";
import { Inbox } from "./routes/Messages/Inbox";
import BulkMessage from "./routes/Chat/Bulk/Bulk";
import { Settings } from "./routes/Settings";
import { Favorites } from "./routes/Favorites";
import { SupportFaq } from "./routes/Support/SupportFaq";
import { Terms } from "./routes/Terms";
import { Drive } from "./routes/Drive/Drive";
import { ThirdParty } from "./routes/ThirdParty";
import { Cookies } from "./routes/Cookies";
import { Privacy } from "./routes/Privacy";
import { CookieBox } from "./components/CookieBox";
import { QuickPost } from "./components/QuickPost";
import { Subscriptions } from "./routes/Subscriptions";
import { BothRoute } from "./addons/BothRoute";
import { Forgot } from "./routes/Forgot";
import { useUserContext } from "./context/UserContext";
import { PasswordReset } from "./routes/PasswordReset";
import {AlreadyVerify} from "./routes/AlreadyVerify";
import { TwitterCallback } from "./routes/TwitterCallback";
import { useModalContext } from "./context/ModalContext";
import axios from "axios";
import { useEnvContext } from "./context/EnvContext";
import { ModalNotificationMulti } from "./components/ModalNotificationMulti";
import { ModalNotificationSingle } from "./components/ModalNotificationSingle";
import { Purchased } from "./routes/Purchased/Purchased";
import {SettingsEarningsPage} from "./routes/SettingsEarningsPage";
import {FollowPage} from "./routes/follow/FollowPage";
import {SupportBug} from "./routes/Support/SupportBug";
import {SupportFeature} from "./routes/Support/SupportFeature";
import {SupportTicket} from "./routes/Support/SuportTicket";
import {SupportTerms} from "./routes/Support/SupportTerms";
import {SupportPrivacy} from "./routes/Support/SupportPrivacy";
import {MyTickets} from "./routes/Support/MyTickets";
import {SupportHome} from "./routes/Support/SupportHome";
import {SingleTicket} from "./routes/Support/SingleTicket";
import {BothRouteNoMenuMobile} from "./addons/BothRouteNoMenuMobile";
import {DefaultRoute} from "./addons/DefaultRoute";
import {RedirectOldProfile} from "./routes/RedirectOldProfile";
import {MobileNav} from "./components/MobileNav";
import {MobileTopNav} from "./components/MobileTopNav";
import {Navbar} from "./components/Navbar";

["post", "get"].forEach((x) => {
  Axios.defaults.headers[x]["accept"] = "application/json";
});

function App() {
  const history = useHistory();
  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();
  const userLocation = useLocation();
  const getImportantNotifincations = async () => {
    await userData.checkAuth(); // needs to be checked and waited for as the token is not yet set in this component as it mounted
    axios.get(`${envData.apiUrl}/notifications/important`).then(response => {
      if(response.data.data.length > 0) {
        modalData.push(() => <ModalNotificationMulti notifications={response.data.data}  />)
      }
      // if(response.data.data.filter((data:any) => data.data.removed_from_user === userData.user.handle).length > 0) {
      //   setTimeout( () => {
      //     modalData.push(() => <ModalNotificationMulti notifications={response.data.data}  />)
      //   }, 2000);
      //
      // }
    }).catch(() => {
      ""
    });
  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });

    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if(userData.authStatus === "AUTHENTICATED") {
      getImportantNotifincations();
    }
  }, [userData.authStatus]);

  useEffect(() => {
    if(
        ["App\\Notifications\\ReportedPostDeleted",
          "App\\Notifications\\Chat\\DeletedReportedMessageImportant"]
            .includes(userData.signal?.type)
    ) {
      if((userData.signal.type === "App\\Notifications\\Chat\\DeletedReportedMessageImportant" && userData?.user?.handle === userData?.signal?.removed_from_user) || userData.signal.type === "App\\Notifications\\ReportedPostDeleted"
      )
      {
        modalData.push(() => <ModalNotificationSingle key={userData.signal.id} id={userData.signal.id} reason={userData.signal.reason} headerText={userData.signal.header} contentBody={userData.signal.body} thumbnails={userData.signal.thumbnails} />)
      }
    }
    else if (["App\\Notifications\\UserTipCreated"].includes(userData.signal?.type)) {
      modalData.pushToast(
          "money_in",
          <>You've received ${userData.signal?.tip.amount} from <b className={"toast-link-user"}>{userData.signal?.tip.userProfile.handle}</b></>
      );
    } else if(
        ["App\\Notifications\\NewPostLike","App\\Notifications\\Chat\\DeletedReportedMessageImportant",
          "App\\Notifications\\DeclinedUserVerificationEmail","App\\Notifications\\NewPostFavorite","App\\Notifications\\UserUnlockedChatMessage","App\\Notifications\\VerifiedUserEmail",
          "App\\Notifications\\NewPostTag","App\\Notifications\\EmailVerify","App\\Notifications\\UserVerificationApproved", "App\\Notifications\\UserVerificationRejected", "App\\Notifications\\NewTicketResponse",
          "App\\Notifications\\TicketStatusChanged", "App\\Notifications\\NewSubscriber"
        ].includes(userData.signal?.type)
    ) {
      modalData.pushToast("info", `You have ${userData.user.meta.new_notifications > 1 ? "" : "a"} new notification${userData.user.meta.new_notifications > 1 ? "s" : ""}`);
    }   else if(
        ["App\\Notifications\\NewPostComment"].includes(userData.signal?.type) && userData?.signal?.user_id !== userData?.user.id
    ) {
      modalData.pushToast("info", `You have ${userData.user.meta.new_notifications > 1 ? "" : "a"} new notification${userData.user.meta.new_notifications > 1 ? "s" : ""}`);
    }
    else if(
        ["App\\Notifications\\UserBanned"].includes(userData.signal?.type)
    ) {
      userData.clearToken(false);
    } else if(
        ["App\\Notifications\\PostFinishedEncoding"].includes(userData.signal?.type)
    ) {
      userData.clearEncodingStatus()
    } else if(
        ["App\\Notifications\\Chat\\ChatMessageCreated"].includes(userData.signal?.type)
    ) {
      userData.newMessage()
    }
  },[userData.signal]);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const checkInboxOpen = () => {
    const name = userLocation.pathname.split("/")
    if(name[1] === "inbox" && Number(name[2]) > 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    })
  }, [userLocation.pathname])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  return (
      <>
        {userData.authStatus === 'AUTHENTICATED' && !userData.mobilePost && isMobile && !checkInboxOpen()  ?
            <MobileTopNav/> : ""
        }
        {(!isMobile || userData.authStatus === 'UNAUTHENTICATED') && userLocation.pathname.split("/")[1] !== "inbox"  ? <Navbar /> : ""}
        <div className={`fl-app ${userData.mobilePost ? "fl-app-mobile-post" : ""} fl-fluffa-${isMobile && userData.authStatus === 'AUTHENTICATED' ? "mobile" : "desktop"} ${isMobile && userData.authStatus === 'UNAUTHENTICATED' ? "fl-fluffa-mobile-unauthenticated" : ""} ${checkInboxOpen() ? "fl-open-inbox" : ""}`}>
          <Switch>
            <DefaultRoute exact path="/" render={() => <Home />} />

            <ProtectedRoute exact path="/feed" render={() => <Feed />} />
            <ProtectedRoute
                exact
                path="/favorites/:subroute"
                render={() => <Favorites key={window.location.pathname} />}
            />

            <BothRouteNoMenuMobile
                exact
                path="/post/:id/:commentId?"
                render={() => <Preview key={window.location.pathname} />}
            />

            <UnregisteredRoute exact path="/login" render={() => <Login />} />
            <BothRoute exact path="/login/twitter" render={() => <TwitterCallback />} />
            <UnregisteredRoute exact path="/register" render={() => <Register />} />
            <BothRoute exact path="/forgot-password" render={() => <Forgot />} />
            <BothRoute exact path="/forgot-password" render={() => <Forgot />} />
            <BothRoute exact path="/reset-password" render={() => <PasswordReset />} />
            <DefaultRoute exact path="/contact" render={() => <Contact />} />
            <ProtectedRoute exact path="/support/faq" render={() => <SupportFaq />} />
            <ProtectedRoute exact path="/support/report-bug" render={() => <SupportBug />} />
            <ProtectedRoute exact path="/support/suggest-feature" render={() => <SupportFeature />} />
            <ProtectedRoute exact path="/support/submit-ticket" render={() => <SupportTicket />} />
            <ProtectedRoute exact path="/support/ticket/:id?" render={() => <SingleTicket />} />
            <ProtectedRoute exact path="/support/my-tickets" render={() => <MyTickets />} />
            <ProtectedRoute exact path="/support" render={() => <SupportHome />} />
            <ProtectedRoute exact path="/support/terms" render={() => <SupportTerms />} />
            <ProtectedRoute exact path="/support/privacy" render={() => <SupportPrivacy />} />
            <ProtectedRoute
                exact
                path="/explore/:subroute?"
                render={() => <Explore />}
            />

            <ProtectedRoute
                exact
                path="/notifications"
                render={() => <Notifications />}
            />
            <ProtectedRoute
                exact
                path="/inbox/:id?"
                render={() => <Inbox />}
            />
            <ProtectedRoute
                exact
                path="/message-create/:id?"
                // render={() => <MessagesBulk />}
                render={() => <BulkMessage />}
            />
            <ProtectedRoute
                exact
                path="/settings/:subroute?"
                render={() => <Settings />}
            />
            <ProtectedRoute
                exact
                path="/subscriptions/:filter?"
                render={() => <Subscriptions />}
            />

            <CreatorRoute
                exact
                path="/drive/:location"
                render={() => <Drive key={window.location.pathname}/>}
            />
            <CreatorRoute
                exact
                path="/earnings/:location"
                render={() => <SettingsEarningsPage key={window.location.pathname}/>}
            />
            <ProtectedRoute
                exact
                path="/purchased"
                render={() => <Purchased />}
            />
            <ProtectedRoute
                exact
                path="/follow/:location"
                render={() => <FollowPage />}
            />
            <DefaultRoute exact path="/preregister" render={() => <Preregister />} />

            {/* COMING SOON ROUTES */}
            <DefaultRoute exact path="/contact" render={() => <ComingSoon src={src1} />} />
            {/*<Route exact path="/register" render={() => <ComingSoon src={src1} />} />*/}
            {/*<Route exact path="/register" render={() => <ComingSoon src={src1} />} />*/}
            {/*<Route exact path="/privacy" render={() => <ComingSoon src={src1} />} />*/}
            {/*<Route exact path="/terms" render={() => <ComingSoon src={src1} />} />*/}
            {/*<Route exact path="/cookies" render={() => <ComingSoon src={src1} />} />*/}
            <DefaultRoute exact path="/privacy" render={() => <Privacy />} />
            <DefaultRoute exact path="/terms" render={() => <Terms />} />
            <DefaultRoute exact path="/2257" render={() => <ThirdParty />} />
            <DefaultRoute exact path="/cookies" render={() => <Cookies />} />
            <DefaultRoute exact path="/verify/:id/:token" render={() => <Verify />} />
            <DefaultRoute exact path="/update/user/:email" render={() => <UpdateMail />} />
            <DefaultRoute exact path="/verify/:email" render={() => <AlreadyVerify />} />
            {/*<NotFoundRoute exact path="/not-found-user" render={() => <NotFoundUser />} />*/}
            {/*<NotFoundRoute exact path="/user-deactivate" render={() => <DeactivateUser />} />*/}
            <DefaultRoute exact path="/account-disabled" render={() => <BanUserPage />} />

            <DefaultRoute exact path="/profile/:handle/:username?" render={() => <RedirectOldProfile />} />
            <BothRoute
                exact
                path="/:handle"
                render={() => <Profile key={window.location.pathname} />}
            />
            {/*<DefaultRoute exact path="/404" render={() => <NotFound />} />*/}
            <Redirect to="/404" />
            {/*<DefaultRoute exact path="/404" render={() => <NotFound />} />*/}
            {/*<Redirect to="/404" />*/}
          </Switch>
          <div className={"fl-sidebuttons-main"}style={{ right: userData.paddingBody ? `${Number(userData.paddingBody) + 30}px` : ""}}>
            {userData.authStatus === 'AUTHENTICATED' && !isMobile ?  <QuickPost /> : ''}
          </div>
          {userData.authStatus !== "AUTHENTICATED" && ["/","/login","/register","/terms","/privacy","/2257","/contact","/forgot-password","/preregister", "/account-disabled"].includes(window.location.pathname) && <Footer /> }
          <CookieBox />
        </div>
        {!isMobile ?
            <div className={"fl-sidebuttons-main-top"}style={{ right: userData.paddingBody ? `${Number(userData.paddingBody) + 30}px` : ""}}>
              <BackToTop />
            </div> : ""
        }
        {userData.authStatus === 'AUTHENTICATED' && !userData.mobilePost && isMobile && !checkInboxOpen() ?
            <MobileNav/> : ""
        }
      </>
  );
}

export default App;

