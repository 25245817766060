import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import { Button } from "./Button";
import './ModalNotificationMulti.css'

export const ModalNotificationSingle: FC<any> = ({id, headerText, contentBody, reason, thumbnails}) => {
    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    const dismissNotification = () => {
        modalData.close();
        axios.put(`${envData.apiUrl}/notifications/read/${id}`).then(response => {
        }).catch(err => {
            modalData.pushToast('error', 'Something went wrong!');
        })
    }

    return (
    <div className={`fl-notification-content fl-notification-content-${userData.currentTheme}`}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <div>
            <p>
                {headerText}
            </p>
            <p>
                {contentBody}
            </p>
            <div>
                {thumbnails?.map((element: any,index:number) => {
                    switch(element.type) {
                        case 'image':
                            return <img width='30%' src={element.content} alt={`thumb-${index}`} />
                        case 'video': 
                            return <img width='30%' src={element.thumbnail} alt={`thumb-${index}`} />
                        case 'audio': 
                            return <audio src={element.content} />
                    }
                })}
            </div>
            <p>
                Reason: {reason}
            </p>
            <p>
                Repeated violations of our <Link className='fl-underline-none-hover' target='_blank' to='/support/terms'>terms</Link> may result in your account being restricted or disabled. 
            </p>
            <Button onClick={dismissNotification} className={`fl-dismiss-button`}>Dismiss</Button>
        </div>
    </div>
    );
}