import React, {FC, Fragment} from "react";
import "./MessageGallery.css";
import ReactPlayer from "react-player";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faPhotoVideo, faVideo} from "@fortawesome/free-solid-svg-icons";
import {useUserContext} from "../context/UserContext";
import {useModalContext} from "../context/ModalContext";
import {ProfileHolder} from "./ProfileHolder";
import {Link} from "react-router-dom";
import logoSource from "../assets/images/fluffa-logo-text.png";
import messagesIcon from "../assets/images/icons/19.svg";
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import profileHolderImg from "../assets/images/profile.png";

export const MessageGallery: FC<{
    data: any;
}> = ({ data }) => {

    const userData = useUserContext();
    const modalData = useModalContext();

    const checkExpandImage = () => {
        if( data.unlocked && userData?.user?.handle !== data?.sender?.handle ) {
            return true
        } else if (userData?.user?.handle === data?.sender?.handle ) {
            return true
        } else {
            return false
        }
    }

    const convertDuration = () => {
        const duration =     data.attachments.reduce(function(prev:any, current:any) {
            return (prev + current.duration);
        }, 0)

        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    const keyboardEventDm = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "ArrowRight") {
            userData.keyboardEvent("ArrowRight");
        }else if (e.key === "ArrowLeft") {
            userData.keyboardEvent("ArrowLeft");
        } else if (e.key === "Escape") {
            modalData.closeImage()
        }
    }

    return (
        <div
            className={`fl-message-bubble-gallery-content fl-message-bubble-gallery-content-${
                (data?.attachments || [])?.length < 5
                    ? (data?.attachments || [])?.length
                    : "more"
            }`}
        >
            <Link
                to={`/${data?.sender?.handle}`}
                className={`fl-message-bubble-gallery-content-single-avatar`}
            >
                <ProfileHolder src={data?.sender?.profileImage?.url || profileHolderImg} noUrl />
            </Link>
            {(data.attachments || []).length < 5 ? (
                (data?.attachments?.map((x: any, y: number) => (
                    <div  className={`fl-message-bubble-gallery-content-single`}
                          onClick={() =>
                              checkExpandImage() && modalData.toggleImageDm(
                                  data.attachments,
                                  y
                              )
                          }
                          onKeyDown={keyboardEventDm}
                          tabIndex={0}
                    >
                        {x.type === "image" && (
                            <RenderSmoothImage src={x.url} />
                        )}
                        {x.type === "video" && (
                        <ReactPlayer
                            url={x.url}
                            width="100%"
                            controls={true}
                            config={{
                                file: {
                                    forceHLS: true,
                                },
                            }}
                        />
                        )}
                        <>
                            {data.attachments.length > 0  && !data.unlocked && userData.user.handle !== data?.sender?.handle && (
                               <>
                                   <div className="fl-message-bubble-message-attachment-unlock">
                                       <div className="fl-message-bubble-message-attachment-unlock-logo">
                                           <img src={logoSource} alt="Fluffa"/>
                                       </div>
                                       <img className="fl-message-padlock" src={messagesIcon} alt={"message padlock"} />
                                           <div className="fl-message-bubble-message-attachment-unlock-svg">
                                               {(data.attachments || []).filter((x: any) => x.type === "image").length > 0 && (data.attachments || []).filter((x: any) => x.type === "video").length === 0 && (
                                                   <>
                                                       <FontAwesomeIcon
                                                           icon={faImage}
                                                       />
                                                       <span>{data.attachments.length}</span>
                                                   </>
                                               )}
                                               {(data.attachments || []).filter((x: any) => x.type === "video").length > 0 && (data.attachments || []).filter((x: any) => x.type === "image").length === 0 && (
                                                   <>
                                                       <FontAwesomeIcon
                                                           icon={faVideo}
                                                       />
                                                       <span>
                                                      { convertDuration() }
                                                    </span>
                                                   </>
                                               )}
                                               {(data.attachments || []).filter((x: any) => x.type === "video").length > 0 && (data.attachments || []).filter((x: any) => x.type === "image").length > 0 && (
                                                   <>
                                                       <FontAwesomeIcon
                                                           icon={faPhotoVideo}
                                                       />
                                                       <span>
                                                      ({(data.attachments || []).filter((x: any) => x.type === "image").length}) {" "}
                                                           { convertDuration() }
                                                    </span>
                                                   </>
                                               )}
                                           </div>
                                   </div>
                               </>
                            )}
                        </>
                    </div>
                )))) : (
                <Fragment>
                    {(data.attachments || [])
                        .filter((x: any, y: number) => y < 3)
                        .map((x: any, y: number) => (
                            <div
                                className={`fl-message-bubble-gallery-content-single ${data.unlocked && "fl-message-bubble-gallery-content-single-unlocked"}`}
                                key={y}
                                onClick={() =>
                                    modalData.toggleImageDm(
                                        data.attachments,
                                        y
                                    )
                                }
                                onKeyDown={keyboardEventDm}
                                tabIndex={0}
                               >
                                <RenderSmoothImage src={x.url} />
                            </div>
                        ))}
                    <div className={`fl-message-bubble-gallery-content-single ${data.attachments.length > 3 && "fl-message-bubble-message-attachment-img-blur"}`}
                         onClick={() =>
                             modalData.toggleImageDm(
                                 data.attachments,
                                 3
                             )
                         }
                         onKeyDown={keyboardEventDm}
                         tabIndex={0}
                    >
                        <RenderSmoothImage src={data.type === "FAKE" ? data.attachments.filter((x:any,index:number) => index === 3)[0].src : data.attachments.filter((x:any,index:number) => index === 3)[0].url} />
                        {data.attachments.length > 3 &&
                        <span className={"fl-message-bubble-message-attachment-img-blur-count"}> {(data.attachments || []).length - 3}+</span>
                        }
                    </div>
                </Fragment>
            )}
        </div>
    );
}
