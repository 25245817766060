import React, { FC, Fragment } from 'react';
import { useUserContext } from '../context/UserContext';
import { Button } from './Button';
import "./CookieBox.css";
import { useCookies } from 'react-cookie'

export const CookieBox:FC<any> = (props) => {

    // CONTEXT
    const userData = useUserContext();
    const [cookies, setCookie] = useCookies(['name']);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const acceptCookies = () => {
      setCookie("name","cookies")
    }
    return (
        cookies.name !== "cookies" ? <div className={`fl-cookie-box-main fl-cookie-box-main-${userData.currentTheme}`} style={{ right: userData.paddingBody ? `${Number(userData.paddingBody) + 20}px` : ""}}>
            {!isMobile ?  <div className="fl-cookie-box-title">Cookies</div> : ""}
        <div className="fl-cookie-box-description">This website uses cookies necessary for its basic functioning. By continuing browsing, you consent to the use of cookies and other technologies.</div>
        <div className="fl-cooke-box-buttons">
            <Button to="/cookies">See more</Button>
            <Button type="normal" onClick={acceptCookies}>I agree</Button>
        </div>
    </div> : <Fragment></Fragment>
    )
}
