import {faChevronCircleLeft,} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FC, Fragment, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {useUserContext} from "../../context/UserContext";
import axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {useModalContext} from "../../context/ModalContext";
import UserCard from "../Explore/Components/UserCard/UserCard";
import {useBottomScrollListener} from "react-bottom-scroll-listener";


export const FollowersPage:FC<any> = (props) => {

    const history = useHistory();
    const userData = useUserContext();
    const envData = useEnvContext()
    const modalData = useModalContext()

    const [profiles, setProfiles] =  useState<any>({
        profiles: [],
        currentPage: 1,
        last_page: false,
    })
    const stateRef = useRef<any>();

    useEffect(() => {
        stateRef.current = profiles;
    }, [profiles]);
    const [animation,setAnimation] = useState(true)

    const getProfiles = async (page = 1) => {
        try {
            const res = await axios.get(`${envData.apiUrl}/profile/followers?page=${page}`);
            setProfiles({
                ...stateRef.current,
                profiles: page === 1 ? res.data.data : [...stateRef.current.profiles, ...res.data.data],
                currentPage: page,
                last_page: res.data.data.length < 11 ? true : false
            });
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useLayoutEffect(() => {
        (async () => {
            await getProfiles();
        })();
    }, []);

    useBottomScrollListener(() => {
        if(!stateRef.current.last_page) {
            getProfiles(stateRef.current.currentPage + 1)
        }
    });

    const redirectProfile = (e:any) => {
        if (e.ctrlKey || e.metaKey) {
            history.push(`/settings`);
        } else {
            history.push(`/settings`);
        }
    }
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <Fragment>
            {animation &&
            <div className="fl-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                {isMobile ? "" :
                    <div className="fl-settings-sub-section">
                        <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                          <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                          </span>
                            Followers ({userData.user.profile.meta.followers})
                        </div>
                    </div>
                }
                <div className={`fl-settings-sub-section ${profiles.profiles.length === 0 && !animation ? "" : "fl-usercard__wrapper"}`}>
                    {profiles.profiles.length === 0 && !animation ?
                        <div className={"fl-subscription-empty"}>
                            <div className={"fl-subscription-empty"}>
                                <p>You don't have any Followers.</p>
                            </div>
                        </div> :
                        <>
                            {profiles.profiles.map((x: any, y: number) => (
                                <UserCard data={x.userProfile} key={y} following={true}/>
                            ))}
                        </>
                    }
                </div>
            </div>
        </Fragment>
    )
}
