import Axios from 'axios';
import React, { FC, Fragment, useEffect, useState } from 'react'
import { Button } from '../components/Button';
import { useEnvContext } from '../context/EnvContext';
import { useUserContext } from '../context/UserContext';
import './SettingsPrivacy.css';
import { MInput } from '../components/MInput';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '../components/Toggle';
import { faCheck, faChevronCircleLeft, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useModalContext } from '../context/ModalContext';
import ReactTooltip from 'react-tooltip';
import { CreatorDescriptions } from '../components/CreatorDescriptions';
import '../components/CountryFlags/css/flag-icons.css'
import ReactLoading from "react-loading";

export const SettingsPrivacy:FC<any> = (props) => {

    const [state, setState] = useState({
        blocked_ip_address: '',
        blocked:[],
        privateProfile: false,
        isCreator: false,
        originalPrivateProfile: false,
        show_likes: false,
        show_followers_number: false,
        profile_on_explore: false,
        explorable_via_tags: false,
        has_descriptions: false,
        activity_status: false
    })

    const [users,setUsers] = useState({
        blocked: [],
        restricted: []
    })

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory();

    const [animation,setAnimation] = useState(true)

    const getSettings = async () => {
        try {
            const res = await Axios.get(`${envData.apiUrl}/settings`);
            const { privateProfile, blocked_ip_address, show_likes, show_followers_number, profile_on_explore,
                explorable_via_tags ,activity_status} = res.data.data.settings;

            const meta = res.data.data.meta;
            setState(prevState => {
                return {...prevState, privateProfile, has_descriptions: meta.has_description, isCreator: meta.is_a_creator,
                    originalPrivateProfile: privateProfile, profile_on_explore: profile_on_explore, show_likes,activity_status,
                    show_followers_number , blocked_ip_address: (blocked_ip_address || []).join(',\n'),
                    explorable_via_tags: explorable_via_tags }
            });
            setChangesVisibility({likes: false, followers: false, explore: false,activity:false});
            setChanges(false);
            setAnimation(false)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimation(false)
        }
    }
    

    const [animationPrivacy, setAnimationPrivacy] = useState<boolean>(false)
    const savePrivacy = async () => {
        setAnimationPrivacy(true)
        const { privateProfile, show_likes,activity_status, show_followers_number, blocked_ip_address, profile_on_explore, explorable_via_tags } = state;
           await Axios.post(`${envData.apiUrl}/settings`, {
            privateProfile, 
            show_followers_number, 
            profile_on_explore,
            show_likes,
            activity_status,
            explorable_via_tags,
            blocked_ip_address: blocked_ip_address.split(',').map(x => x.trim()).filter(x => x !== '')
        }).then((response) => {
            modalData.pushToast('success', 'Privacy settings updated');
            getSettings();
            userData.changeActivity(state.activity_status)
        }).catch((err) => {
            modalData.pushToast('error', 'Something went wrong!');
        });
        setAnimationPrivacy(false)
    }

    const [ blockedCS, setBlockedCS ] = useState<any>({
        country: 0,
        state: 0
    })

    const [countries, setCountries] = useState<any>([]);
    const [states, setStates] = useState<any>([]);

    const getStates = async (id:number) => {
        const res = await Axios.get(`${envData.apiUrl}/country/${id}/states`);
        const allStates = res.data.data;

        const allOptions = allStates.map((x:{id: number, name: string, code:string}, y:number) => ({ value: x.id, label: x.name, code: x.code}));
        
        setStates([
            {category: 'All States', options: allOptions}
        ]);
    }

    const updateCountry = (id: string) => {
        const countryId = Number(id);
        if(countryId > 0) {
            getStates(countryId);
        }
        setClear2(1);
        setBlockedCS({...blockedCS, country: countryId});
    }

    const updateState = (id: string) => {
        const stateId = Number(id);
        setBlockedCS({...blockedCS, state: stateId});
    }

    const getCountries = async () => {
        const res = await Axios.get(`${envData.apiUrl}/countries`);
        const allCountries = res.data.data;
        const allOptions = allCountries.map((x:{id: number, name: string, code:string}, y:number) => ({ value: x.id, label: x.name, code: x.code}));
                                
        setCountries([
            {category: 'All Countries', options: allOptions}
        ]);
        
    }

    const getBlockedCountries = async () => {
        let data = await axios.get(`${envData.apiUrl}/settings/country/blocked`);
        setState(prevState => {
            return {...prevState, blocked: data.data.countries.concat(data.data.states)}
        });
    }

    const getUsersRestrictedBlocked = async () => {
        let data = await axios.get(`${envData.apiUrl}/profile/blocked`);
        setUsers({...users, blocked: data.data.blocked, restricted:data.data.restricted})
    }

    const [clear1, setClear1] = useState(0);
    const [clear2, setClear2] = useState(0);
    const [animationButton, setAnimationButton] = useState<any>(false)

    const pushBlock = async () => {
        setAnimationButton(true)
        try{
            const state = blockedCS.state
            const country = blockedCS.country

            let data = await axios.post(`${envData.apiUrl}/settings/country/blocked`, {
                id: state > 0 ? state : country,
                type: state > 0 ? 'state' : 'country'
            });
            setState((prevState: any) => {
                return {
                    ...prevState,
                    blocked: [...prevState.blocked,data.data.data]
                }
            })
            setAnimationButton(false)
        } catch (e) {
            setAnimationButton(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const unblockCountry = async (id: number, type: string) => {
        try {
            await axios.delete(`${envData.apiUrl}/settings/${type === "state" ? 'state' : 'country'}/blocked/${id}`).then(response => {
                setState(prevState => {
                    return {
                        ...prevState,
                        blocked: prevState.blocked.filter((elem:any) => elem.id !== id)
                    }
                });
            });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        getCountries();
        getBlockedCountries();
        getSettings();
        getUsersRestrictedBlocked()
    },[])

    const disableSave = () => {
        return !(changes || Object.values(changesVisibility).some((element: boolean) => {return element === true})) || animationPrivacy;
    }
    const [changes, setChanges] = useState(false);
    const [changesVisibility, setChangesVisibility] = useState({likes: false, followers: false, explore: false,activity:false})

    // const openDescriptions = (option:string) => {
    //     modalData.push(() =>
    //         <div>
    //             <CreatorDescriptions saveProcedure={closeModal} fetishOpen={option === "fetish" ? true : false}/>
    //         </div>
    //     );
    // }

    const closeModal = () => {
        getSettings();
        modalData.close();
    }

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    const disableButton = () => {
       if(blockedCS.country === 0 || animationButton) {
           return true
       } else {
           return false
       }
    }

    const openModal = async (handle:any,id:number,action:string) => {
        await modalData.push(() => <UserProfileRestrictBlock handle={handle} action={action} users={users} setUsers={setUsers} id={id} /> )
    }

    return (
        <Fragment>
            {animation &&
                <div className="fl-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                       <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                           <FontAwesomeIcon icon={faChevronCircleLeft} />
                       </span>
                        Privacy
                    </div>
                    <div className="fl-settings-sub-account-field fl-settings-mt-30">
                        <div className="fl-settings-sub-account-field-label">Additional visibility settings</div>
                        <div className="fl-settings-sub-privacy-options">
                            <label className={`fl-settings-sub-privacy-label fl-settings-sub-privacy-label-${userData.currentTheme}`}>
                                    <p>Show number of likes</p>
                                    <span 
                                    data-tip
                                    data-for='show_likes_description'>
                                        <FontAwesomeIcon icon={faInfoCircle} className='fl-info-text'/>
                                    </span>
                                    <ReactTooltip id='show_likes_description'>This shows the total number of likes across all of your posts.</ReactTooltip>
                                    <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.show_likes} onClick={() => {
                                        setState({...state, show_likes: !state.show_likes});
                                        setChangesVisibility({...changesVisibility, likes: !changesVisibility.likes});
                                    }}
                                    /> 
                            </label>
                                
                            <label className={`fl-settings-sub-privacy-label fl-settings-sub-privacy-label-${userData.currentTheme}`}>
                                Show number of followers
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.show_followers_number} onClick={() => {
                                    setState({...state, show_followers_number: !state.show_followers_number});
                                    setChangesVisibility({...changesVisibility, followers: !changesVisibility.followers});
                                }}/>
                            </label>
                            <label className={`fl-settings-sub-privacy-label fl-settings-sub-privacy-label-${userData.currentTheme}`}>
                                Show activity status
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.activity_status} onClick={() => {
                                    setState({...state, activity_status: !state.activity_status});
                                    setChangesVisibility({...changesVisibility, activity: !changesVisibility.activity});
                                }}/>
                            </label>
                            {userData.user.profile.meta.verified_state === "verified" &&
                                <div>
                                    <label className={`fl-settings-sub-privacy-label fl-settings-sub-privacy-label-${userData.currentTheme}`} data-tip data-for="coming-coon">
                                        Show my profile on the explore page
                                        <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={false}  style={{ opacity: ".5" }} />
                                        <ReactTooltip id="coming-coon">Coming soon...</ReactTooltip>
                                        {/*<Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.profile_on_explore} onClick={() => {*/}
                                        {/*    setState({...state, profile_on_explore: !state.profile_on_explore});*/}
                                        {/*    setChangesVisibility({...changesVisibility, explore: !changesVisibility.explore});*/}
                                        {/*}}/>*/}
                                    </label>
                                    {/*{state.profile_on_explore && <div>*/}
                                    {/*    <label className={`fl-settings-sub-privacy-label fl-settings-sub-privacy-label-${userData.currentTheme}`}>*/}
                                    {/*    Allow my content to be found via tags*/}
                                    {/*    <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.explorable_via_tags} onClick={() => {*/}
                                    {/*        setState({...state, explorable_via_tags: !state.explorable_via_tags});*/}
                                    {/*        setChangesVisibility({...changesVisibility, explore: !changesVisibility.explore});*/}
                                    {/*    }}/>*/}
                                    {/*</label>*/}
                                    {/*    <p className='fl-security-edit-authentication' onClick={() => openDescriptions("feature")}>{state.has_descriptions ? 'Change feature details' : 'Add feature details'}</p>*/}
                                    {/*    <p className='fl-security-edit-authentication' onClick={() => openDescriptions("fetish")}>{userData?.user?.profile?.descriptions?.single?.filter((x:any) => x.name === "doing")?.length > 0 ? "Update" : "Add"} fetish list</p>*/}
                                    {/*</div>*/}
                                    {/*}*/}
                                </div>
                            }
                        </div>
                    </div>
                    <Button type="normal" className="fl-settings-sub-security-button" onClick={savePrivacy} disabled={disableSave()}>
                        Save
                        {animationPrivacy && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                    </Button>
                    <div className="fl-settings-sub-account-field fl-settings-mt-30">
                        <div className="fl-settings-sub-account-field-label">Blocked Locations ({state.blocked.length})</div>
                        <div className="fl-settings-sub-privacy-blocked-cs-form fl-settings-mt-20">
                            <div className="fl-settings-sub-privacy-blocked-cs-form-part">
                                {/* <MInput type="select" label="Country" value={blockedCS.country} setValue={(country) => setBlockedCS({...blockedCS, country})} /> */}
                                <MInput clear={clear1} setClear={setClear1} preRender={x => 
                                <div className={`fl-settings-sub-verification-country-img flag-icon-background flag-icon-${x.code.toLowerCase()}`}>
                                    </div>} type="select" label="Country" value={blockedCS.country} setValue={(country) => updateCountry(country)}  options={countries}/>
                            </div>
                            <div className="fl-settings-sub-privacy-blocked-cs-form-part">
                                {/* <MInput type="select" label="State" value={blockedCS.country} setValue={(state) => setBlockedCS({...blockedCS, state})} /> */}
                                <MInput clear={clear2} setClear={setClear2} type="select" label="State / Province" value={blockedCS.state} setValue={(st) => updateState(st)} options={blockedCS.country ? states : []}/>
                            </div>
                            <div className="fl-settings-sub-privacy-blocked-cs-form-button"
                            style={{paddingTop: "5px"}}
                            >
                                <Button type="normal" onClick={()=> {pushBlock(); setClear1(1)}} disabled={disableButton()}>
                                    Add
                                    {animationButton && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                                </Button>
                            </div>
                        </div>
                        {state.blocked.length > 0 &&
                            <div className={`fl-settings-sub-privacy-blocked-cs-list fl-settings-sub-privacy-blocked-cs-list-${userData.currentTheme}`}>
                                {state.blocked.map( (elem:any) => {
                                    return (
                                        <div className='fl-blocked-countries-wrapper'>
                                            <div className={`fl-settings-sub-verification-country-img flag-icon-background flag-icon-${elem?.country?.code?.toLowerCase() || elem?.code?.toLowerCase()}`}></div>
                                            {elem.name}{elem?.country?.name ? `, ${" "}${elem?.country?.name}` : "" }
                                            <div className='fl-close-button'  onClick={() => unblockCountry(elem.id, elem?.country?.name ? 'state' : '')}>
                                                <FontAwesomeIcon icon={faTimes}/>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                    </div>
                    <div className="fl-settings-sub-account-field fl-settings-mt-30">
                        <div className="fl-settings-sub-account-field-label">Restricted Users ({users.restricted.length})</div>
                        {users.restricted.length > 0 &&
                        <div className={`fl-settings-sub-privacy-blocked-cs-list fl-blocked-users-list fl-settings-sub-privacy-blocked-cs-list-${userData.currentTheme}`}>
                            {users.restricted.map( (user:any, index:number) => {
                                return (
                                    <span onClick={() => openModal(user.blocked_user_profile.user.handle,user.id,"unrestrict")}>
                                        @{user.blocked_user_profile.user.handle}
                                    </span>
                                );
                            })}
                        </div>
                        }
                    </div>
                    <div className="fl-settings-sub-account-field fl-settings-mt-30">
                        <div className="fl-settings-sub-account-field-label">Blocked Users ({users.blocked.length})</div>
                        {users.blocked.length > 0 &&
                        <div className={`fl-settings-sub-privacy-blocked-cs-list fl-blocked-users-list fl-settings-sub-privacy-blocked-cs-list-${userData.currentTheme}`}>
                            {users.blocked.map( (user:any, index:number) => {
                                return (
                                    <span onClick={() => openModal(user.blocked_user_profile.user.handle,user.id,"unblock")}>
                                        @{user.blocked_user_profile.user.handle}
                                    </span>
                                );
                            })}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export const UserProfileRestrictBlock: FC<any> = (props) => {

    const modalData = useModalContext()
    const envData = useEnvContext()

    const [animation,setAnimation] = useState(false)

    const blockUser = async () => {
        setAnimation(true)
        try {
            await axios.post(
                `${envData.apiUrl}/profile/${props.handle}/unblock`
            );
            props.setUsers({...props.users, blocked: props.users.blocked.filter((x:any,index:number) => x.id !== props.id)})
            modalData.close();
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    const restrictUser = async () => {
        setAnimation(true)
        try {
            await axios.post(
                `${envData.apiUrl}/messaging/${props.handle}/unblock`
            );
            props.setUsers({...props.users, restricted: props.users.restricted.filter((x:any,index:number) => x.id !== props.id)})
            modalData.close();
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{props.action === "unrestrict" ? "Unrestrict" : props.action === "unblock" ? "Unblock" : ""} user</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                Are you sure you want to {props.action === "unrestrict" ? "unrestrict" : props.action === "unblock" ? "unblock" : ""} this user?
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end fl-settings-mt-30">
                <Button onClick={modalData.close}>No</Button>
                <Button type="normal" onClick={() => props.action === "unrestrict" ? restrictUser() : blockUser() } disabled={animation}>
                    Yes
                    {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </React.Fragment>
    );
}