import React, { FC } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

const CheckBox: FC<{
  active: boolean;
  id: string;
  val: string;
  text: string;
  changeFn: any;
  info?: any | null;
  single?:any;
  close?:any;
  setClose?:any;
}> = ({ active, id, val, text, changeFn, info,single,close,setClose }) => {
  return (
    <div className="fl-discover__checklist__checkbox">
        {single ?
            <input
                style={{ display: "none" }}
                type="checkbox"
                id={id}
                value={val}
                onChange={() => {changeFn();setClose(true)}}
            /> :
            <input
                style={{ display: "none" }}
                type="checkbox"
                id={id}
                value={val}
                onChange={changeFn}
            />
        }
      <span
        className={`${active && "fl-discover__checklist__checkbox--checked"}`}
      >
        <FontAwesomeIcon icon={faCheck} />
      </span>
      <label
        htmlFor={id}
        className={`${active && "fl-discover__checklist__checkbox--selected"}`}
      >
        {text}{" "}
        {info && (
          <>
            <FontAwesomeIcon
              icon={faInfoCircle}
              data-tip
              data-for={`additional-info-${id}`}
            />
            <ReactTooltip id={`additional-info-${id}`}>{info}</ReactTooltip>
          </>
        )}
      </label>
    </div>
  );
};

export default CheckBox;
