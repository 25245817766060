import React, {FC} from "react";
import "./PurchasedTable.css";
import moment from "moment";
import {useUserContext} from "../../context/UserContext";
import {useHistory} from "react-router-dom";
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import {useModalContext} from "../../context/ModalContext";

export const PurchasedTable: FC<{
    transactions:any;
    setTransactions:any
    filterType:any;
    stateRef:any
}> = (props) => {

    const userData = useUserContext()
    const history = useHistory()
    const modalData = useModalContext();

    const redirectProfile = (id:any,profile:string) => {
        history.push(`/${profile}`);
    }


    const flModalImage = (files:any,index:any) => {
      // modalData.push(() => <ModalImage files={files} currentIndex={index} />)
        modalData.fullScreen(files, index);
    };

    const urlThumbnails = (x:any) => {
        if(x.thumb_url) {
            return x.url
        } else if(x.thumbnail) {
            return x.thumbnail
        } else {
            return x?.thumbnails[0]?.url
        }
    }

    return (
        <>
            <div className="fl-purchased-sub-section">
                <div
                    className={`fl-purchased-sub-payment-transactions fl-purchased-sub-payment-transactions-${userData.currentTheme}`}
                >
                    <div className="fl-purchased-sub-payment-transactions-single fl-purchased-sub-payment-transactions-single-head">
                        <div className="fl-purchased-sub-payment-transactions-single-img">

                        </div>
                        <div className="fl-purchased-sub-payment-transactions-single-date">
                            Date
                        </div>
                        <div className="fl-purchased-sub-payment-transactions-single-seller">
                            Seller
                        </div>
                        <div className="fl-purchased-sub-payment-transactions-single-amount">
                            Amount
                        </div>
                        <div className="fl-purchased-sub-payment-transactions-single-currency">
                            Currency
                        </div>
                    </div>
                    {props.transactions.transactions.map((x: any, y: number) => (
                                    <div
                                        key={y}
                                        className={`fl-purchased-sub-payment-transactions-single fl-purchased-sub-payment-transactions-single-${userData.currentTheme}`}
                                    >
                                        <div className="fl-purchased-sub-payment-transactions-single-img">
                                            <div
                                                onClick={() => flModalImage(x.product?.chatMessage?.attachments || x?.product?.post?.postAttachments ,0)}
                                            >
                                                {x.product?.chatMessage?.attachments || x?.product?.post?.postAttachments ?
                                                    <RenderSmoothImage src={urlThumbnails(x.product?.chatMessage?.attachments[0] || x?.product?.post?.postAttachments[0])} /> : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="fl-purchased-sub-payment-transactions-single-date">
                                            <div className="fl-purchased-sub-payment-transactions-single-date-short">
                                                {moment(x.created_at).format("D MMM, YYYY")}
                                            </div>
                                            <div className="fl-purchased-sub-payment-transactions-single-date-long">
                                                {moment(x.created_at).format("D MMM, YYYY")}{" "}
                                                <span>{moment(x.created_at).format("- HH:mm:ss")}</span>
                                            </div>
                                        </div>
                                        <div className="fl-purchased-sub-payment-transactions-single-seller">
                                        <span onClick={() => redirectProfile(x?.creatorProfile?.id,x?.creatorProfile?.handle)}>
                                            {x?.creatorProfile?.handle}
                                        </span>
                                        </div>
                                        <div className="fl-purchased-sub-payment-transactions-single-amount">
                                            {x.amount >= 0 ? "-$" : "+$"}
                                            {Math.abs(x.amount)}
                                        </div>
                                        <div className="fl-purchased-sub-payment-transactions-single-currency">
                                            {x.currency}
                                        </div>
                                    </div>
                            )
                    )}
                </div>
            </div>
        </>
    );
}
