import React, { FC,Fragment } from "react";
import { Link } from "react-router-dom";
import "./UnapprovedBox.css";
import { SimpleCard } from "./SimpleCard";

export const VerificationBox: FC<any> = () => {

    return (
        <Fragment>
                <SimpleCard className="fl-unapproved-box">
                    <span>Your email address has not been verified. Please click <Link to="/settings/email">here</Link> to verify your email address.</span>
                </SimpleCard>
        </Fragment>
    );
};
