import React, { FC, ReactElement,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

import Accordion from "../../../Common/Accordion";
import CheckBox from "../../../Common/CheckBox";

const AccordionWithChecklist: FC<{
  icon: any;
  title: ReactElement | string;
  boxes: any;
  changeFn: any;
  active: any;
  titleInfo?: any | null;
  additionalProps?: any | null;
  arrows?:any;
  selected?:any;
  single?:boolean;
}> = ({ icon, title, boxes, changeFn, active, titleInfo, additionalProps,arrows,selected,single }) => {

  const [close,setClose] = useState(false)

  return (
    <Accordion
      head={
        <>
          {icon.length > 0 ?
            <FontAwesomeIcon icon={icon} /> : ""
          }
          {title} {selected ? <span className={"fl-filter__accordion-toggle-selected"}>{selected}</span> : ""} {" "}
          {titleInfo && (
            <>
              <FontAwesomeIcon icon={faInfoCircle} data-tip data-for={title} />
              <ReactTooltip id={`${title}`}>{titleInfo}</ReactTooltip>
            </>
          )}
        </>
      }
      body={
        <div className="fl-discover__checklist">
          {boxes.map((e: any, ind: number) => (
            <CheckBox
              key={ind}
              id={e.id}
              val={e.description_value}
              text={e.description_value}
              changeFn={() => changeFn(e.id)}
              active={active(e.id)}
              info={e.hasinfo && e.info}
              single={single}
              close={close}
              setClose={setClose}
            />
          ))}
          {additionalProps}
        </div>
      }
      single={single}
      close={close}
      setClose={setClose}
      arrows
    />
  );
};

export default AccordionWithChecklist;
