import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import "./QuickPost.css";
import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { useModalContext } from '../context/ModalContext';
import { WritePost } from './WritePost';


let scrollTimeout:any = null;
export const QuickPost = () => {

    // STATE
    const [state, setState] = useState<{status: string, idle: boolean}>({
        status: "invisible",
        idle: false
    })

    const history = useHistory();
    const stateRef = useRef<any>(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state])

    const buttonStyle: CSSProperties = {
        opacity: stateRef.current.status === 'visible' ? 1 : 0,
        pointerEvents: stateRef.current.status === 'visible' ? 'all' : 'none',
        transition: "opacity 300ms ease-in-out"
    }

    const buttonStyleNotificatiosMessages: CSSProperties = {
        pointerEvents: 'all',
    }

    const trackScroll = () => {
        if(scrollTimeout)clearTimeout(scrollTimeout)
        setState({...stateRef.current, idle: false})
        if(window.scrollY > 120 && stateRef.current.status === 'invisible') {
            setState({...stateRef.current, status: "visible"});
        } else if(window.scrollY <= 120 && stateRef.current.status === 'visible') {
            setState({...stateRef.current, status: "invisible"});
        }
        scrollTimeout = setTimeout(() => {
            setState({...stateRef.current, idle: true})
        }, 5000);
    }
    useEffect(() => {
        
        trackScroll();
        window.addEventListener("scroll", trackScroll);
        return () => {
            window.removeEventListener("scroll", trackScroll);
        }
    },[])
    const modalData = useModalContext();

    const postNow = async () => {
        await modalData.push(() => <WritePost mobileResponsive={true} style={{marginBottom: 0}} quickPost/>)
    }

    return (
       history.location.pathname === "/notifications" || history.location.pathname === "/messages" ?
           <div style={buttonStyleNotificatiosMessages} className={`fl-quickpost`}>
               <Button type="normal" className="fl-quickpost-button" onClick={postNow}>
                   <div className="fl-quickpost-icon">
                       {/* <img src={quickPostIcon} alt="+"/> */}
                       <FontAwesomeIcon icon={faEdit}/>
                   </div>
               </Button>
           </div> :
           <div style={buttonStyle} className={`fl-quickpost ${state.idle ? 'fl-quickpost-fade' : ''}`} onMouseOver={trackScroll}>
               <Button type="normal" className="fl-quickpost-button" onClick={postNow}>
                   <div className="fl-quickpost-icon">
                       {/* <img src={quickPostIcon} alt="+"/> */}
                       <FontAwesomeIcon icon={faEdit}/>
                   </div>
               </Button>
           </div>
    )
}