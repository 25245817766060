import React, {FC, useEffect, useState} from 'react'
import {Button} from "../../components/Button";
import {useModalContext} from "../../context/ModalContext";

export const ReportMessage:FC<any> = (props) => {

    const modalData = useModalContext()

    const [viewMessage, setViewMessage] = useState<boolean>(false)
    const [viewMessageFullScreen, setViewMessageFullScreen] = useState<boolean>(false)

    const viewReportedMessage = () => {
        setViewMessage(true)
    }

    const viewFullScreen = () => {
        setViewMessageFullScreen(true)
        modalData.toggleImageDm(
            props.data.attachments,
            0
        )
    }

    useEffect(() => {
        if (viewMessage && viewMessageFullScreen) {
            setTimeout(() => setViewMessage(false), 2000);
            setTimeout(() => setViewMessageFullScreen(false), 2000);
        }
    }, [viewMessage,viewMessageFullScreen]);

    return (
        <div className="fl-message-bubble-message-attachment-unlock">
            <Button
                className={`fl-message-bubble-message-attachment-unlock-button fl-message-bubble-message-attachment-creator-send ${viewMessage && "fl-message-reported"}`}
                type="normal"
                onClick={() => viewMessage ? viewFullScreen() : viewReportedMessage()}>
                {viewMessage ? "VIEW MESSAGE" : "REPORTED!"}
            </Button>
        </div>
    )
}