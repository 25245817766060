import React, {FC, useEffect, useRef} from "react";
import { SimpleCard } from "../../components/SimpleCard";
import FAQAccordion from "../../components/FAQAccordion";
import "./Support.css";
import "../Privacy.css";

import faq from "./SupportFAQ.json";
import { useUserContext } from "../../context/UserContext";
import {SupportNav} from "./SupportNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

export const SupportFaq: FC<any> = (props) => {
    const userData = useUserContext()
    const refScroll = useRef<HTMLDivElement>(null);
    const history = useHistory()
    const scrollBottom = () => {
        setTimeout(() => {
            if(localStorage.getItem("referral") === "referral")
            {
                refScroll?.current?.scrollIntoView();
                setTimeout(() => {
                    localStorage.removeItem("referral")
                },1000)
            }
        },500)
    }

    useEffect(() => {
        scrollBottom()
    }, [localStorage.getItem("referral")]);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            {/*{userData.authStatus === "AUTHENTICATED" ? <Subroutes /> : ""}*/}
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    {!isMobile ?  <SupportNav /> : ""}
                    <div className="fl-col-9 fl-support-col">
                        <SimpleCard>
                            {isMobile ?
                                <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                                    <span
                                        className="fl-direct-messages-title-icon"
                                        onClick={(e) => history.push('/support')}
                                    >
                                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                                </span>
                                    Creators FAQ's
                                </div>
                                :
                                <h3>Creators FAQ's</h3>
                            }
                            {faq.Creators.map((e: any, i: number) => (
                                <FAQAccordion q={e.q} a={e.a} key={i} refScroll={refScroll}/>
                            ))}
                            <h3>Followers FAQ's</h3>
                            {faq.Followers.map((e: any, i: number) => (
                                <FAQAccordion q={e.q} a={e.a} key={i} refScroll={refScroll}/>
                            ))}
                            <h3>General FAQ's</h3>
                            {faq.General.map((e: any, i: number) => (
                                <FAQAccordion q={e.q} a={e.a} key={i} refScroll={refScroll}/>
                            ))}
                            <h5>
                                We’ll be updating our FAQ's from time to time, so please check
                                back periodically.
                            </h5>
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
