import React, { FC, useState, useEffect, useRef } from 'react'
import './FAQAccordion.css'
import reactStringReplace from "react-string-replace";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

export const FAQAccordion:FC<any> = ({ q, a,refScroll }) => {
  const [state, setState] = useState<{active: boolean}>({
    active: false
  })

  useEffect(() => {
    setState({active: localStorage.getItem("referral") === "referral" && q === "Is there a referral programme?" ? true : false})
  }, [])

  const accordionRef = useRef<HTMLDivElement>(null)
  const toggleRef = useRef<HTMLDivElement>(null)

    const openFaq = () => {
        if(localStorage.getItem("referral") === "referral") {
            localStorage.removeItem("referral")
        }
        setState({...state, active: !state.active})
    }
  return (
     <>
       {q === "Can I schedule posts?" ? <div ref={refScroll}></div> : ""}
       <div className={`fl-accordion fl-accordion-${state.active ? "open" : "close"}`}
            ref={accordionRef}
            style={{
                maxHeight: state.active ? `100vh` : `calc(${toggleRef.current?.scrollHeight}px + 2rem)`
            }}
            onClick={(event:any) => {
                openFaq()
            }}
       >
         <div className="fl-accordion-toggle" ref={toggleRef}>
             {q}
             <div className="fl-accordion-arrow">
                 <FontAwesomeIcon icon={!state.active ? faChevronDown : faChevronUp}/>
             </div>
         </div>
         <div className="fl-accordion-body">
           {a.map((e:string, i:number) => <p key={i}>
               {reactStringReplace(`${e}`, /@\[(\S+)\]/g, (match, i) => (
                   <Link className={"fl-faq-link"} to={'/support/submit-ticket'}>submit a ticket</Link>
               ))}
           </p>)}
         </div>
       </div>
     </>
  )
}

export default FAQAccordion