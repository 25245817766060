import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useUserContext } from '../context/UserContext';
import "./Footer.css";

// ASSETS
import footerLogoSource from '../assets/images/fluffa-text-black.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Toggle } from './Toggle';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

interface FooterProps {
    
}

export const Footer:FC<FooterProps> = () => {
    
    // CONTEXT
    const userData = useUserContext();

    return (

        <div className={`fl-footer-main fl-footer-main-${userData.currentTheme}`}>
            <div className="fl-container fl-footer-inner">
                <div className="fl-col-6 fl-footer-pad fl-footer-part">
                    <div className="fl-footer-logo">
                        <Link className="fl-footer-logo-link" to="/">
                            <img className={`fl-footer-logo-link-img fl-footer-logo-link-img-${userData.currentTheme}`} src={footerLogoSource} alt="footer"/>
                        </Link>
                        <div className="fl-footer-social">
                            <a className={`fl-footer-social-link fl-footer-social-link-${userData.currentTheme}`} target="_blank" href="https://www.instagram.com/fluffa/">
                                <FontAwesomeIcon icon={faInstagram}/>
                            </a>
                            <a className={`fl-footer-social-link fl-footer-social-link-${userData.currentTheme}`} target="_blank" href="https://twitter.com/fluffa/">
                                <FontAwesomeIcon icon={faTwitter}/>
                            </a>
                        </div>
                    </div>
                    <div className="fl-footer-text">
                        <div className={`fl-footer-text-inner fl-footer-text-inner-${userData.currentTheme}`}>

                        </div>
                    </div>
                </div>
                <div className="fl-col-6 fl-footer-pad">
                    <div className="fl-footer-nav">
                        <Link to="/contact" className={`fl-footer-nav-link fl-footer-nav-link-${userData.currentTheme}`}>Contact</Link>
                        <Link to="/privacy" className={`fl-footer-nav-link fl-footer-nav-link-${userData.currentTheme}`}>Privacy</Link>
                        <Link to="/terms" className={`fl-footer-nav-link fl-footer-nav-link-${userData.currentTheme}`}>Terms</Link>
                        <Link to="/2257" className={`fl-footer-nav-link fl-footer-nav-link-${userData.currentTheme}`}>2257</Link>
                    </div>
                    <div className="fl-footer-settings">
                        <div className={`fl-footer-settings-link fl-footer-settings-link-${userData.currentTheme}`}>
                            <Toggle rightComponent={<FontAwesomeIcon icon={faSun}/>} leftComponent={<FontAwesomeIcon icon={faMoon}/>} value={userData.currentTheme === "light" ? false : true} onClick={() => userData.changeTheme()}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fl-footer-copyright">Copyright &copy; {moment().year()} Fluffa. All rights reserved.</div>
        </div>
    )
}
