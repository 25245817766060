import {
    faChevronCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FC, Fragment, useEffect,useState} from 'react'
import {useHistory} from 'react-router-dom';
import { Button } from '../components/Button';
import { useUserContext } from '../context/UserContext';
import './SettingsEarnings.css';
import moment from 'moment';
import axios from 'axios';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import ReactLoading from "react-loading";
import AccordionWithChecklist from "./Explore/Components/Filter/Fields/AccordionWithCheklist";
import {DatePicker} from "../components/DatePicker";
import ReactFrappeChart from "react-frappe-charts";

export const SettingsEarningsStatistics:FC<any> = (props) => {

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory()

    const [animation,setAnimation] = useState(true)
    const [clear1, setClear1] = useState(false);

    const [descriptions] = useState<any>({
        period: [{id: "daily", description_value: "Daily"}, {id: "weekly", description_value: "Weekly"}, {id: "monthly", description_value: "Monthly"}, {id: "all" , description_value: "All time"},{id: "custom" , description_value: "Custom"} ],
        type: [{id: "all", description_value: "All"},{id: "tip", description_value: "Tips"}, {id: "ppv-post", description_value: "Posts"}, {id: "chat-message", description_value: "Messages"},{id: "subscription" , description_value: "Subscriptions"} ]
    })

    const [filter, setFilter] = useState<any>({
        period: "all",
        type: "",
        dateFrom: "",
        dateTo: "",
    });

    const [periodTable,setPeriodTable] = useState("")

    const changeFnPeriod = (val: any) => {
        if (filter.period === val) {
            setFilter({
                ...filter,
                period: "",
            });
        } else {
            setFilter({ ...filter, period: val });
        }
    }

    const changeFnType = (val: any) => {
        if (filter.type === val) {
            setFilter({
                ...filter,
                type: "",
            });
        } else {
            setFilter({ ...filter, type: val });
        }
    }

    const pickDateFrom = (x: Date) => {
        setFilter({ ...filter, dateFrom: moment(x).format("YYYY-MM-DD") });
        modalData.close();
    };

    const pickDateTo = (x: Date) => {
        setFilter({ ...filter, dateTo: moment(x).format("YYYY-MM-DD") });
        modalData.close();
    };

    const [filterResult, setFilterResult] = useState<any>([])

    const [animationFilter, setAnimationFilter] = useState(false)
    const [chart, setChart] = useState(false)
    const filterFunction = async () => {
        setAnimationFilter(true)
        try {
            // const res = await axios.get(`${envData.apiUrl}/stats/earnings${filter.period !== "custom" ? `?period=${filter.period}` : ""}${filter.period === "custom" ? "?" : "&"}${filter.type !== "" && filter.type !== "all" && filter.type !== "custom" ? `&type=${filter.type}` : ""}${filter.dateFrom ? `&from=${filter.dateFrom}` : ""}${filter.dateTo ? `&to=${filter.dateTo}` : ""}${filter.type === "referrals" ? '&referrals=1' : ""}`);
            const res = await axios.get(`${envData.apiUrl}/stats/earnings`,
                filter.period === "custom" ?
                    { params: {
                        period: filter.period !== "custom" ? filter.period : "all",
                        type: filter.type !== "all" ? filter.type : "",
                        from: filter.dateFrom ? filter.dateFrom  : "" ,
                        to: filter.dateTo ? filter.dateTo  : ""
                    }} :
                    {
                        params: {
                        period: filter.period !== "custom" ? filter.period : "all",
                        type: filter.type !== "all" ? filter.type : "",
                    }}
                );
            setFilterResult(Object.values(res.data))
            setPeriodTable(filter.period)
            setAnimationFilter(false)
            setTimeout(() => {
                if(res.data.length > 0) {
                    setChart(true)
                }else {
                    setChart(false)
                }
            },100)
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            setAnimationFilter(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        filterFunction()
    },[])

    const disableFilterButton = () => {
        if(filter.period.length === 0 || animationFilter || (filter.period === "custom" && (filter.dateFrom.length === 0 || filter.dateTo.length === 0))) {
            return true
        } else {
            return false
        }
    }

    const clearFilter = () => {
        setFilter({
            ...filter,
            period: "",
            type: "",
            dateFrom: "",
            dateTo: ""
        });
    }

    const formatDate = (x:any) => {
        return x.stats_month ?  `${moment(moment(x.stats_month, "MM YYYY").toDate()).format("MMM YYYY")}` : x.start_date ? `${moment(x.start_date).format("D MMM YYYY")} - ${moment(x.end_date).format("D MMM YYYY")}` : moment(x.stats_date.match(/\"(.*)\"/)?.pop()).format("D MMM YYYY")
    }

    const redirect = (e:any) => {
        history.push(`/earnings/all`);
    }

    return (
        <Fragment>
            {animation &&
            <div className="fl-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                         <span className="fl-direct-messages-title-icon"  onClick={(e) => redirect(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                         </span>
                        Earning statistics
                    </div>
                    <div className={`fl-settings-mt-30 fl-settings-sub-earnings fl-settings-sub-earnings-title-${userData.currentTheme}`}>
                        <span>Filter earnings</span>
                    </div>
                    <div className="fl-settings-sub-payment-balance fl-settings-mt-20 fl-align-center">
                        <div className={`fl-settings-sub-payment-filter-single`}>
                            <AccordionWithChecklist
                                icon={''}
                                title={`Period`}
                                selected={filter.period ? `(${descriptions.period.filter((x:any) => x.id === filter.period)[0].description_value})` : ""}
                                boxes={descriptions.period}
                                changeFn={changeFnPeriod}
                                active={(item: any) =>  filter.period === item}
                                single
                            />
                        </div>
                        <div className={`fl-settings-sub-payment-filter-single`}>
                            <AccordionWithChecklist
                                icon={''}
                                title={`Type`}
                                selected={filter.type ? `(${descriptions.type.filter((x:any) => x.id === filter.type)[0].description_value})` : ""}
                                boxes={descriptions.type}
                                changeFn={changeFnType}
                                active={(item: any) =>  filter.type === item}
                                single
                            />
                        </div>
                        {filter.period === "custom" ?
                            <div className={`fl-settings-sub-payment-filter-single`}>
                                <div className={"fl-settings-sub-payment-filter-date"}>
                                    <DatePicker
                                        label="Date from"
                                        clear={clear1}
                                        setClear={setClear1}
                                        value={filter.dateFrom}
                                        onSave={(x) => pickDateFrom(x)}
                                        className="fl-settings-sub-account-field-input"
                                        max={new Date()}
                                    /> <br/>
                                    <DatePicker
                                        label="Date to"
                                        clear={clear1}
                                        setClear={setClear1}
                                        value={filter.dateTo}
                                        onSave={(x) => pickDateTo(x)}
                                        className="fl-settings-sub-account-field-input"
                                        max={new Date()}
                                    />
                                </div>
                            </div> : ""
                        }
                    </div>
                    <div className={"fl-d-flex"}>
                        <Button onClick={() => clearFilter()}>Reset filters</Button>
                        <Button type={"normal"} onClick={() => filterFunction()} disabled={disableFilterButton()}>
                            {animationFilter ? <>Applying {" "} <span style={{marginLeft: '5px' }}><ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> </span></> : "Apply filters"}
                        </Button>
                    </div>
                </div>
                {chart &&
                    <div className={"fl-chart"}>
                        <ReactFrappeChart
                            type="line"
                            colors={["#ff0d72"]}
                            axisOptions={{xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1}}
                            height={250}
                            data={{
                                labels: filterResult?.map((x: any) => {
                                    return formatDate(x)
                                }),
                                datasets: [{
                                    values: filterResult.map((x: any) => {
                                        return x.earnings.toFixed(2)
                                    })
                                }],
                            }}
                        />
                        <div className={`fl-statistics-cart-title fl-statistics-cart-title-${userData.currentTheme}`}>Earnings ($)</div>
                    </div>
                }
                <div className="fl-settings-sub-section">
                    {filterResult.length > 0 ?
                        <div className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme} fl-mt-20`}>
                            <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-head">
                                <div className="fl-settings-sub-payment-transactions-single-date">{periodTable === "monthly" ? "Month" : "Date"}</div>
                                <div className="fl-settings-sub-payment-transactions-single-amount">Earnings</div>
                                <div className="fl-settings-sub-payment-transactions-single-amount">Fees</div>
                                <div className="fl-settings-sub-payment-transactions-single-amount">Net</div>
                            </div>
                            {filterResult.map((x: any, y: number) => (
                                <div
                                    key={y}
                                    className={`fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-action fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                                >
                                    <div className="fl-settings-sub-payment-transactions-single-date">
                                        <p>{formatDate(x)}</p>
                                    </div>
                                    <div className="fl-settings-sub-payment-transactions-single-amount">
                                        {x.earnings > 0 ? "+" : x.earnings === 0 ? "" : "-"}$
                                        {x.earnings.toFixed(2)}
                                    </div>
                                    <div className="fl-settings-sub-payment-transactions-single-amount fl-red">
                                        -{Math.floor(x?.fee_percentage)}%
                                    </div>
                                    <div className="fl-settings-sub-payment-transactions-single-amount">
                                        ${(x.earnings-(x.earnings*x.fee_percentage/100)).toFixed(2)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <p className={"fl-no-result"}>No results.</p>
                    }
                </div>
            </div>
        </Fragment>
    )
}