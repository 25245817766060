import React, {FC, Fragment, useEffect, useState} from "react";
import "./Purchased.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faExclamationCircle, faExpand, faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {useUserContext} from "../../context/UserContext";
import {useModalContext} from "../../context/ModalContext";
import {ProfileHolder} from "../../components/ProfileHolder";
import {Link} from "react-router-dom";
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import {SimpleCard} from "../../components/SimpleCard";
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {Button} from "../../components/Button";
import VideoPlayer from "../../components/video/VideoPlayer";

export const PurchasedGallery: FC<{
    data: any;
    profile:any;
    transactions:any;
    setTransactions:any;
    key:any;
}> = ({ data,profile,transactions,setTransactions,key }) => {

    const [state, setState] = useState<{
        reported:any
    }>({
        reported:  data?.product?.chatMessage?.reports || data?.product?.post?.meta?.is_reported
    });

    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    const [viewReported, setViewReported] = useState<boolean>(false);

    const post = () => {
        if(data?.product?.post) {
            return true
        } else {
            return false
        }
    }

    const chat = () => {
        if(data?.product?.chatMessage) {
            return true
        } else {
            return false
        }
    }

    const postFiles = () => {
      return data?.product?.post?.postAttachments
    }

    const chatFiles = () => {
      return data?.product?.chatMessage?.attachments
    }

    const price = () => {
        return data.amount
    }

    const [imageDropdown, setImageDropdown] = useState<boolean> (false)
    const [optionId, setOptionId] = useState<number>(0)
    const imageOption = (e:any) => {
        e.stopPropagation()
        setOptionId(data.id)
        setImageDropdown(!imageDropdown)
    }

    const likeMessage = async () => {
        try {
             await Axios.get(`${envData.apiUrl}/conversations/${data.product.chatMessage.conversation_id}/messages/${data.product.chatMessage.id}/like`
            );
            setTransactions({
                ...transactions,
                transactions: transactions.transactions.map((x: any) =>
                    x.id === data.id ? { ...x, product: {...x.product, chatMessage: {...x.product.chatMessage,  liked: !x.product.chatMessage.liked }} } : x
                )
            });
            setImageDropdown(false)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }


    const likePost = async () => {
        try {
              await Axios.post(`${envData.apiUrl}/posts/${data.product.post.id}/like`, {
                like: !statusLikePost(),
            });
            setTransactions({
                ...transactions,
                transactions: transactions.transactions.map((x: any) =>
                    x.id === data.id ? { ...x, product: {...x.product, post: {...x.product.post,  meta: {...x.product.post.meta, is_liked: !x.product.post.meta.is_liked }}} } : x
                )
            });
            setImageDropdown(false)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    const reportMessage = async (message: number,conversation: number) => {
        setImageDropdown(false)
        const res = await Axios(`${envData.apiUrl}/posts/reports/reasons`);
        const reasons = Object.keys(res.data.reasons).map((x) => ({
            key: x,
            value: res.data.reasons[x],
        }));

        const Reason = () => {
            const [reasonState, setReasonState] = useState("");
            const keyboardEvent = (event: KeyboardEvent) => {
                if(event.code === "Escape") {
                    modalData.close()
                }
            }
            useEffect(() => {
                document.addEventListener('keydown', keyboardEvent);
                return function cleanup() {
                    document.removeEventListener('keydown', keyboardEvent);
                }
            }, [keyboardEvent]);

            return (
                <Fragment >
                    <div className="fl-modal-title">
                        <div className="fl-modal-title-text">Report message</div>
                    </div>
                    <div className="fl-modal-description">
                        <div className="fl-modal-description-row">What's the issue?</div>
                        <div>
                            {reasons.map((x, y) => (
                                <div
                                    onClick={() => setReasonState(x.key)}
                                    className={`fl-modal-report-reason fl-modal-report-reason-${
                                        userData.currentTheme
                                    } ${
                                        reasonState === x.key ? "fl-modal-report-reason-active" : ""
                                    }`}
                                    key={y}
                                >
                                    {x.value}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                        <Button onClick={() => modalData.close()}>Cancel</Button>
                        <Button
                            type="normal"
                            onClick={() => processReport(message,conversation, reasonState)}
                            disabled={
                                reasonState
                                    ? false
                                    : true
                            }
                        >
                            Submit
                        </Button>
                    </div>
                </Fragment>
            );
        };

        await modalData.push(() => <Reason />);
    };

    const processReport = async (message: number, conversation:number, reason: any) => {
        modalData.close();
        try {
            await Axios.post(`${envData.apiUrl}/conversations/${conversation}/messages/${message}/report`, {
                reason,
            });
            setState({...state, reported: true});
        } catch (e) {
            modalData.pushToast("error", "You've already reported this message. It's now being checked by the review team.");
        }
    };

    const reportPost = async (id: number) => {
        setImageDropdown(false)
        const res = await Axios(`${envData.apiUrl}/posts/reports/reasons`);
        const reasons = Object.keys(res.data.reasons).map((x) => ({
            key: x,
            value: res.data.reasons[x],
        }));

        const Reason = () => {
            const [reasonState, setReasonState] = useState("");
            return (
                <Fragment>
                    <div className="fl-modal-title">
                        <div className="fl-modal-title-text">Report post</div>
                    </div>
                    <div className="fl-modal-description">
                        <div className="fl-modal-description-row">What's the issue?</div>
                        <div>
                            {reasons.map((x, y) => (
                                <div
                                    onClick={() => setReasonState(x.key)}
                                    className={`fl-modal-report-reason fl-modal-report-reason-${
                                        userData.currentTheme
                                    } ${
                                        reasonState === x.key ? "fl-modal-report-reason-active" : ""
                                    }`}
                                    key={y}
                                >
                                    {x.value}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                        <Button onClick={() => modalData.close()}>Cancel</Button>
                        <Button
                            type="normal"
                            onClick={() => processReportPost(id, reasonState)}
                            disabled={reasonState ? false : true}
                        >
                            Submit
                        </Button>
                    </div>
                </Fragment>
            );
        };

        await modalData.push(() => <Reason />);
    };

    const processReportPost = async (id: number, reason: any) => {
        modalData.close();
        try {
            await Axios.post(`${envData.apiUrl}/posts/${id}/report`, {
                reason,
            });
            modalData.pushToast("success", "Post reported");
            setState({...state, reported: true});
        } catch (e) {
            modalData.pushToast(
                "error",
                "You've already reported this post. It's now being checked by the review team."
            );
        }
    };

    const [indexImage, setIndexImage] = useState<number>(0)

    const openFullScreen = (e: any, url: any, index: number) => {
        if(viewReported) {
            setTimeout(() => {
                setViewReported(false)
            },2500)
        }
        e.stopPropagation();
        modalData.fullScreen(url, index);
    };

    const prevPost = () => {
        const index = indexImage - 1
        setIndexImage(index === -1 ? (post() ? postFiles() : chat() ? chatFiles() : []).length - 1 : index )
    }

    const nextPost = () => {
        const index = indexImage + 1
        setIndexImage(index >  (post() ? postFiles() : chat() ? chatFiles() : []).length - 1 ? 0 : index)
    }

    const keyboardEvent = (event: KeyboardEvent) => {
        switch(event.code) {
            case "ArrowLeft":
                prevPost();
                break;
            case "ArrowRight":
                nextPost();
                break;
        }
    }

    useEffect(() => {
        if((post() ? postFiles() : chat() ? chatFiles() : []).length > 1) {
            document.addEventListener('keydown', keyboardEvent);
            return function cleanup() {
                document.removeEventListener('keydown', keyboardEvent);
            }
        }
    }, [keyboardEvent]);

    const statusLikePost = () => {
        if( data?.product?.post?.meta?.is_liked) {
            return true
        } else {
            return false
        }
    }

    const statusLikeMessage = () => {
        if(data?.product?.chatMessage?.liked) {
            return true
        } else {
            return false
        }
    }

    const [playing,setPlaying] = useState(false)

    return (
        <>
            <div
                tabIndex={1}
                onBlur={() => setImageDropdown(false)}
                className={"fl-purchased-file"}
                key={key}
            >
                <div
                    className={`fl-purchased-content fl-purchased-content-${
                        (post() ? postFiles() : chat() ? chatFiles() : []).length < 5
                            ? (post() ? postFiles() : chat() ? chatFiles() : []).length
                            : "more"
                    }
                     ${state.reported || state.reported === 1 ? " " : "fl-drive-image-hover"}
                     `
                    }
                >
                    <Link
                        to={`/${profile?.handle}`}
                        className={`fl-purchased-content-single-avatar`}
                    >
                        <ProfileHolder src={profile?.profileImage?.url} noUrl />
                    </Link>
                    {(post() ? postFiles() : chat() ? chatFiles() : []).length < 5 ? (
                        ((post() ? postFiles() : chat() ? chatFiles() : []).map((x: any, y: number) => (
                            <div  className={`fl-purchased-content-single`}
                                  onClick={(e) =>
                                      x.type !== "video" ?
                                          openFullScreen(
                                              e,
                                              post() ? postFiles() : chat() ? chatFiles() : [],
                                              y
                                          ) : ""
                                  }
                            >
                                {x.type === "video" ?
                                    <div className={"smooth-image-wrapper"}>
                                        <VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                    </div>
                                    : x.thumb_url ?
                                    <RenderSmoothImage src={x.url} /> :  <RenderSmoothImage src={x?.thumbnails[0]?.url} />
                                }
                            </div>
                        )))) : (
                        <Fragment>
                            {(post() ? postFiles() : chat() ? chatFiles() : [])
                                .filter((x: any, y: number) => y < 3)
                                .map((x: any, y: number) => (
                                    <div
                                        className={`fl-purchased-content-single fl-purchased-content-single-unlocked"}`}
                                        key={y}
                                        onClick={(e) =>
                                            x.type !== "video" ?
                                                openFullScreen(
                                                    e,
                                                    post() ? postFiles() : chat() ? chatFiles() : [],
                                                    y
                                                ) : ""
                                        }
                                    >
                                        {x.type === "video" ?
                                            <div className={"smooth-image-wrapper"}>
                                                <VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                            </div>
                                            :  x.thumb_url ?
                                                <RenderSmoothImage src={x.url} /> :  <RenderSmoothImage src={x?.thumbnails[0]?.url} />
                                        }
                                    </div>
                                ))}
                            <div className={`fl-purchased-content-single ${(post() ? postFiles() : chat() ? chatFiles() : []).length > 3 && "fl-message-bubble-message-attachment-img-blur"}`}
                                 onClick={(e) => (post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].type !== "video" ?
                                     openFullScreen(
                                         e,
                                         post() ? postFiles() : chat() ? chatFiles() : [],
                                         3
                                     ) : ""
                                 }
                            >
                                {(post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].type === "video" ?
                                    <div className={"smooth-image-wrapper"}>
                                        <VideoPlayer src={(post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].thumbnails[0].url} thumbnail={(post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].thumbnail} duration={(post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].duration} setPlaying={setPlaying} playing={playing} />
                                    </div>
                                    : (post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].url ?
                                        <RenderSmoothImage src={(post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].url} />
                                        :
                                    <RenderSmoothImage src={(post() ? postFiles() : chat() ? chatFiles() : []).filter((x:any,index:number) => index === 3)[0].thumbnails[0].url} />
                                }
                                {(post() ? postFiles() : chat() ? chatFiles() : []).length > 3 &&
                                <span className={"fl-message-bubble-message-attachment-img-blur-count"}> {(post() ? postFiles() : chat() ? chatFiles() : []).length - 3}+</span>
                                }
                            </div>
                        </Fragment>
                    )}
                    <div className={"fa-drive-option"}   onClick={(e) => imageOption(e)} >  <FontAwesomeIcon icon={faEllipsisH} /> </div>
                    {imageDropdown && optionId === data.id &&
                    <div
                        className={`fl-filter-head-actions-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                    >
                        <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                            <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={() => post() ? likePost() : likeMessage()}>
                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                    <FontAwesomeIcon icon={(post() ? statusLikePost() : statusLikeMessage()) ? faHeartSolid : faHeart} />
                                </div>
                                <span>
                                  {(post() ? statusLikePost() : statusLikeMessage()) ? "Unlike" : "Like"}
                           </span>
                            </div>
                            <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => post() ? reportPost(data?.product?.post?.id) : reportMessage(data?.product?.chatMessage?.id,data?.product?.chatMessage?.conversation_id)}>
                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                </div>
                                <span>
                                   Report {post() ? "post" : "message"}
                           </span>
                            </div>
                            <div
                                className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select"
                                onClick={(e) =>
                                    openFullScreen(
                                        e,
                                        post() ? postFiles() : chat() ? chatFiles() : [],
                                       0
                                    )
                                }
                            >
                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                    <FontAwesomeIcon icon={faExpand} />
                                </div>
                                <span>
                                   Full screen
                           </span>
                            </div>
                        </SimpleCard>
                    </div>
                    }
                    {!playing ?
                    <span className={"fl-purchased-price"}>
                        {price() > 0 ? `$${price()}` : "free"}
                    </span> : ""
                    }
                    {state.reported || state.reported === 1 ?
                        <div className="fl-message-bubble-message-attachment-unlock">
                            <Button
                                onClick={(e:any) => viewReported ?   openFullScreen(
                                    e,
                                    post() ? postFiles() : chat() ? chatFiles() : [],
                                    0
                                ) : setViewReported(true)}
                                className={`fl-post-image-reported ${
                                    viewReported && "fl-post-image-reported-button"
                                }`}
                                type="normal"
                            >
                                {viewReported ? `VIEW ${post() ? "POST" : "MESSAGE"}` : "REPORTED!"}
                            </Button>
                        </div> : ""
                    }
                </div>
            </div>
        </>
    );
}
