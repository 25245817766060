import React, { FC, useState } from "react";
import axios from "axios";

import { MInput } from "../../../components/MInput";
import { Button } from "../../../components/Button";

import { useEnvContext } from "../../../context/EnvContext";
import { useModalContext } from "../../../context/ModalContext";

import SelectWithNumberInput from "../Common/SelectWithNumberInput";
import moment from "moment";
import ReactLoading from "react-loading";

const EditPlanModal: FC<any> = ({ id, plan, oldState, updateState,checkPriceFiled }) => {
    const [state, setState] = useState<{
        price: any;
        billing_period: string;
        billing_period_type: string;
        billing_period_count: string;
    }>({
        price: "",
        billing_period: plan,
        billing_period_count: "",
        billing_period_type: "",
    });
    const [customDiscountPrice, setCustomDiscountPrice] =
        useState<boolean>(false);

    const envData = useEnvContext();
    const modalData = useModalContext();

    const [animationUpdate,setAnimationUpdate] = useState(false)

    const updateSubscriptionPlan = async () => {
        setAnimationUpdate(true)
        const { price, billing_period } = state;
        axios
            .put(`${envData.apiUrl}/subscription-plans/${id}`, {
                price: Number(price),
                billing_period: "month",
                billing_period_count: Number(billing_period),
            })
            .then(() => {
                setState({
                    price: "",
                    billing_period: "",
                    billing_period_count: "",
                    billing_period_type: "",
                });
                let x = JSON.parse(JSON.stringify(oldState.plans));
                let newPlan = x.find((e: any) => e.id === id);
                newPlan.price = price;
                newPlan.billing_period = "month";
                newPlan.updated_at = moment(new Date()).format("D MMMM, YYYY")
                updateState({ ...oldState, plans: x });
                modalData.close();
                setAnimationUpdate(false)
            })
            .catch(() => {modalData.pushToast("error", "Something went wrong!"); setAnimationUpdate(false)} );
    };

    const disableBtn = () => {
        if(state.price === "" || animationUpdate) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <div className="fl-modal-title">Edit {plan} month plan</div>
            <div className="fl-modal-description fl-modal-overflow-initial">
                <div className="fl-settings-sub-section">
                    <MInput
                        label="Billing cycle"
                        disabled={true}
                        value={`${plan} month`}
                        setValue={() => null}
                        className={"fl-settings-mt-30"}
                    />
                    <div className={"fl-settings-mt-30"}>
                        <SelectWithNumberInput
                            stateName="price"
                            inputTypeSwitched={customDiscountPrice}
                            inputTypeSwitch={setCustomDiscountPrice}
                            state={state}
                            setState={setState}
                            labelForSelect="Set new price ($)"
                            labelForNumber="Set your price"
                            value={state.price}
                            options={checkPriceFiled()}
                            min="5"
                            hasInfo={true}
                            infoId="set-your-price"
                            infoText={["You can set a price from $5 to $100"]}
                        />
                    </div>
                </div>
            </div>

            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" onClick={updateSubscriptionPlan} disabled={disableBtn()}>
                    Update plan
                    {animationUpdate ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </>
    );
};

export default EditPlanModal;

