import React, {FC, Fragment, useLayoutEffect,useState} from 'react'
import { useUserContext } from '../context/UserContext';
import './SettingsEarnings.css';
import axios from 'axios';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import {Link, useHistory} from "react-router-dom";
import AccordionWithChecklist from "./Explore/Components/Filter/Fields/AccordionWithCheklist";
import {Button} from "../components/Button";
import ReactLoading from "react-loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const SettingsReferrals:FC<any> = (props) => {

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory();

    const [referrals, setReferrals] =  useState<any>({
        referrals: [],
        currentPage: 1,
        last_page: 0,
    })

    const [animation,setAnimation] = useState(true)
    const [animationFilter,setAnimationFilter] = useState(false)
    const getReferrals = async (page = 1) => {
        setAnimationFilter(true)
        try {
            const res = await axios.get(`${envData.apiUrl}/stats/earnings/referral?period=${filter.period}`);
            setReferrals({
                ...referrals,
                referrals: res.data
                // referrals: [...referrals.referrals, ...res.data]
                // currentPage: page,
                // last_page: res.data.meta.last_page,
            });
            setAnimation(false)
            setAnimationFilter(false)
        } catch (e) {
            setAnimation(false)
            setAnimationFilter(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useLayoutEffect(() => {
        (async () => {
            await getReferrals();
        })();
    }, []);

    const [descriptions, setDescriptions] = useState<any>({
        period: [{id: "daily", description_value: "Daily"}, {id: "weekly", description_value: "Weekly"}, {id: "monthly", description_value: "Monthly"}, {id: "all" , description_value: "All time"} ]
    })

    const [filter, setFilter] = useState<any>({
        period: "monthly"
    });

    const changeFnPeriod = (val: any) => {
        if (filter.period === val) {
            setFilter({
                ...filter,
                period: "",
            });
        } else {
            setFilter({ ...filter, period: val });
        }
    }

    const clearFilter = () => {
        setFilter({
            ...filter,
            period: "",
            type: "",
            dateFrom: "",
            dateTo: ""
        });
    }

    const disableFilterButton = () => {
        if(filter.period.length === 0 || animationFilter) {
            return true
        } else {
            return false
        }
    }

    const redirectProfile = (e: any) => {
        history.push(`/settings`);
    };

    const formatDate = (x:any) => {
        return x.stats_month ?  `${moment(x.stats_month.match(/\"(.*)\"/)?.pop()).format("MMM YYYY")}` : x.start_date ? `${moment(x.start_date).format("D MMM YYYY")} - ${moment(x.end_date).format("D MMM YYYY")}` : moment(x.stats_date.match(/\"(.*)\"/)?.pop()).format("D MMM YYYY")
    }

    return (
        <Fragment>
            {animation &&
            <div className="fl-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme} fl-referral-title`}>
                          <span
                              className="fl-direct-messages-title-icon"
                              onClick={(e) => redirectProfile(e)}
                          >
                          <FontAwesomeIcon icon={faChevronCircleLeft} />
                        </span>
                        Referrals History
                        <div className={'fl-referral-title-note-div'}>
                           <span className={"fl-referral-title-note"}>
                                Note: Referral payouts are sent at the end of each month.
                           </span>
                        </div>
                    </div>
                    {referrals.referrals.length > 0 ?
                        <div className={"fl-container"}>
                            <div className="fl-col-7">
                                <div className="fl-settings-sub-payment-balance fl-settings-mt-30 fl-align-center">
                                    <div className={`fl-settings-sub-payment-filter-single`}>
                                        <AccordionWithChecklist
                                            icon={''}
                                            title={`Period`}
                                            selected={filter.period ? `(${descriptions.period.filter((x:any) => x.id === filter.period)[0].description_value})` : ""}
                                            boxes={descriptions.period}
                                            changeFn={changeFnPeriod}
                                            active={(item: any) =>  filter.period === item}
                                            single
                                        />
                                    </div>
                                </div>
                                <div className={"fl-d-flex"}>
                                    <Button onClick={() => clearFilter()}>Reset filters</Button>
                                    <Button type={"normal"} onClick={() => getReferrals()} disabled={disableFilterButton()}>
                                        {animationFilter ? <>Applying {" "} <span style={{marginLeft: '5px' }}><ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> </span></> : "Apply filters"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                         : ""
                    }
                    <div className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme} fl-settings-mt-30`}>
                        <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-head">
                            <div className="fl-settings-sub-payment-transactions-single-date">Username</div>
                            <div className="fl-settings-sub-payout-transactions-single-amount">Date Joined</div>
                            <div className="fl-settings-sub-payout-transactions-single-amount">PAYOUT AMOUNT</div>
                        </div>
                        {referrals.referrals.map((x: any, y: number) => (
                            <div
                                key={y}
                                className={`fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-action fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                            >
                                <div className="fl-settings-sub-payment-transactions-single-date">
                                    <Link to={`/${x.user_profile_handle}`}>@{x.user_profile_handle}</Link>
                                </div>
                                <div className="fl-settings-sub-payout-transactions-single-amount">
                                    <p>{formatDate(x)}</p>
                                </div>
                                <div className="fl-settings-sub-payout-transactions-single-amount">
                                    ${Number(x.earnings).toFixed(2)}
                                </div>
                            </div>
                        ))}
                        {/*{payouts.payouts.length > 0 &&*/}
                        {/*<div*/}
                        {/*    className="fl-settings-sub-payment-transactions-more"*/}
                        {/*    onClick={() => getPayouts(payouts.currentPage + 1)}*/}
                        {/*>*/}
                        {/*    {payouts.currentPage >= payouts.last_page*/}
                        {/*        ? ""*/}
                        {/*        : "View more payouts"}*/}
                        {/*</div>*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}