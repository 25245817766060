import React, { FC } from "react";
import "./PostLock.css";
import { useUserContext } from "../../context/UserContext";
import { useEnvContext } from "../../context/EnvContext";
import { useModalContext } from "../../context/ModalContext";
import axios from "axios";
import messagesIcon from '../../assets/images/icons/19.svg';
import messagesIconUnlock from '../../assets/images/icons/23.svg';

import { Button } from "../Button";
import {BalanceModal} from "../BalanceModal";
import {useHistory} from "react-router-dom";

const PostLock: FC<{ data: any; update?: any; oldState?: any,subscribe?:any }> = ({
                                                                       data,
                                                                       update,
                                                                       oldState,
                                                                       subscribe
                                                                   }) => {
    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory();

    const buyPostFunction = () => {
        update({ ...oldState });
        axios
            .post(`${envData.apiUrl}/posts/${data.id}/buy`)
            .then((resp) => {
                let boughtItem = oldState.posts.indexOf(
                    oldState.posts.find((post: any) => post.id === data.id)
                );
                let newPosts = [...oldState.posts];
                newPosts[boughtItem] = resp.data.data;

                update({ ...oldState, posts: newPosts });
                userData.updateBalance("walletOut",data.price)
                modalData.close()
            })
            .catch(async (e) =>
                {
                    const description = e?.response?.data?.errors.user ? "Limit on this credit card is reached, maximum 4 transactions or $200 per month" : e?.response?.data?.errors?.error ? e?.response?.data?.errors?.error : e?.response?.data?.errors.creditCard ;
                    await modalData.push({
                        title: "ERROR",
                        description: [`${description}`],
                        buttons: [
                            () => (
                                <div className="fl-d-flex fl-justify-flex-end">
                                    <Button type="normal" onClick={() => modalData.closeAll()}>
                                        Dismiss
                                    </Button>
                                </div>
                            ),
                        ],
                    });
                }
            );
    }

    const unlockAttachments = async () => {
        modalData.push(() => (<BalanceModal amount={data.price} header={`${data.price > 0 ? "Unlock post" : "Unlock post for free"}`} description={`${data.price > 0 ? `You will be charged $${data.price} to unlock this post.` : "Good news! You will NOT be charged to unlock this post."}`}
                                            action={buyPostFunction} data={data.price} buttonText={data.price > 0 ? `Pay $${data.price} now` : "Unlock"}/>));
    };

    const redirectPayment = () => {
        history.push('/settings/payment')
    }

    const lockType = () => {
        switch (data.type) {
            case "ppv":
                return (
                    <>
                        <div className="fl-postlock__text fl-postlock__text-bold">
                            Premium Post!
                        </div>
                        <div className="fl-postlock-lock fl-postlock-lock-logout">
                            <img className="fl-card-icon-padlock-ppv" src={messagesIcon} alt="Card icon"/>
                            <img className="fl-card-icon-padlock-ppv-unlock" src={messagesIconUnlock} alt="Card icon"/>
                        </div>
                        <Button type="normal" onClick={() =>  !userData.user.meta.can_subscribe ? redirectPayment() : !data.userProfile.meta.is_subscribed && data.type !== "ppv" ? subscribe(oldState.profileObject.subscriptionPlans[0]) : unlockAttachments()}>
                            {!userData.user.meta.can_subscribe ? "ADD A PAYMENT CARD" : !data.userProfile.meta.is_subscribed && data.limited ?
                            "SUBSCRIBE" :
                            <>
                                UNLOCK FOR ${data.price === 0 ? "FREE" : data.price}
                            </>
                            }
                        </Button>
                    </>
                );

            case "private":
                return (
                    (data.userProfile.meta.is_subscribed ? "" :
                            <>
                                <div className="fl-postlock__text">
                                    You need to be subscribed to see this user's post!
                                </div>
                                <div className="fl-postlock-lock fl-postlock-lock-logout">
                                    <img className="fl-card-icon-padlock-ppv" src={messagesIcon} alt="Card icon"/>
                                    <img className="fl-card-icon-padlock-ppv-unlock" src={messagesIconUnlock} alt="Card icon"/>
                                </div>
                                <Button type="normal">
                                    Subscribe
                                </Button>
                            </>
                    )
                );
        }
    };
    return (
        <div className={`${data?.userProfile?.meta?.is_subscribed && data.type === "private" ? "" : "fl-postlock__main"}`}>

            {userData.authStatus === "AUTHENTICATED" ? (
                <>
                    {lockType()}
                </>
            ) : (
                <>
                    <div className="fl-postlock__text fl-postlock__text-bold">
                        Members Only!
                    </div>
                    <div className="fl-postlock-lock fl-postlock-lock-logout">
                        <img className="fl-card-icon-padlock-ppv" src={messagesIcon} alt="Card icon"/>
                        <img className="fl-card-icon-padlock-ppv-unlock" src={messagesIconUnlock} alt="Card icon"/>
                    </div>
                    <Button type="normal" to="/login">
                       LOG IN TO {userData.user.profile.free_subscription === 1 ? "FOLLOW" : "SUBSCRIBE"}
                    </Button>
                </>
            )}
        </div>
    );
};

export default PostLock;
