import React, { Fragment } from "react";
import { useUserContext } from "../context/UserContext";
import { NavLink } from "react-router-dom";

import "./MobileNav.css";
import {
  faBell,
  faEnvelope,
  faHome,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

export const MobileNav = () => {
  const userData = useUserContext();

  const postNow = async () => {
    userData.mobileCreatePost(true)
  }

  return (
      <Fragment>
        <div
            className={`fl-mobile-nav-main fl-mobile-nav-main-${userData.currentTheme}`}
        >
          <NavLink
              to="/feed"
              className="fl-mobile-nav-item"
              activeClassName="fl-mobile-nav-item-active"
          >
            <FontAwesomeIcon icon={faHome} />
          </NavLink>
          <NavLink
              to="/notifications"
              className="fl-mobile-nav-item"
              activeClassName="fl-mobile-nav-item-active"
          >
            <FontAwesomeIcon icon={faBell} />
          </NavLink>
          <div className="fl-mobile-nav-item">
            <div
                className="fl-mobile-nav-photo"
                onClick={() => postNow()}
            >
            <span className="fl-mobile-nav-photo-plus">
              <FontAwesomeIcon icon={faEdit} />
            </span>

              {/* <img style={{width: 32, height: 32}} src={writePostSrc}/> */}
            </div>
          </div>
          <NavLink
              to="/inbox"
              className="fl-mobile-nav-item"
              activeClassName="fl-mobile-nav-item-active"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </NavLink>
          <span
              onClick={() => userData.toggleSidebar()}
              className="fl-mobile-nav-item"
          >
            <FontAwesomeIcon icon={faUser} />
          </span>
        </div>
      </Fragment>
  );
};

