import React, { FC, useState } from "react";
import Axios from "axios";
import moment from "moment";

import { MInput } from "../../../components/MInput";
import { Button } from "../../../components/Button";

import ConfirmDiscountModal from "./ConfirmDiscountModal";

import { useModalContext } from "../../../context/ModalContext";
import { useEnvContext } from "../../../context/EnvContext";

import SelectWithNumberInput from "../Common/SelectWithNumberInput";

const CreateDiscountModal: FC<any> = ({ subscription_id, setState, state }) => {
  const [discountState, setDiscountState] = useState<any>({
    starts_at: new Date().toISOString().split("T")[0],
    duration: null,
    ends_at: null,
    amount: null,
    number_of_discounts: null,
  });

  const [customDiscountDuration, setCustomDiscountDuration] =
    useState<any>(false);
  const [customDiscountNumber, setCustomDiscountNumber] = useState<any>(false);
  const [confirmCreation, setConfirmCreation] = useState<any>(false);

  const modalData = useModalContext();
  const envData = useEnvContext();

  const [animationDiscount, setAnimationDiscount] = useState(false)
  const createDiscount = async (ends_at: any) => {
    setAnimationDiscount(true)
    try{
      const { starts_at, amount, number_of_discounts } = discountState;
      if (starts_at && ends_at && amount > 0) {
        const res = await Axios.post(
            `${envData.apiUrl}/subscription-plan/discount/create`,
            { subscription_id:subscription_id, starts_at:starts_at, ends_at:ends_at, amount:amount, number_of_discounts: number_of_discounts === 0 ? null : number_of_discounts}
        );
        setState({
          ...state,
          plans: state.plans.map((x: any) =>
              x.id === subscription_id
                  ? { ...x, discounted_plan: res.data.data }
                  : x
          ),
        });
        await modalData.close();
      }
      setAnimationDiscount(false)
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
      setAnimationDiscount(false)
    }
  };

  return (
    <>
      {confirmCreation ? (
        <ConfirmDiscountModal
          data={state.plans?.find((x: any) => x.id === subscription_id)}
          starts_at={discountState.starts_at}
          ends_at={moment(discountState.starts_at)
            .add(discountState.duration, "d")
            .format("YYYY-MM-DD")}
          amount={discountState.amount}
          number={discountState.number_of_discounts}
          confirm={createDiscount}
          decline={() => setConfirmCreation(false)}
          animationDiscount={animationDiscount}
        />
      ) : (
        <>
          <div className="fl-modal-title">Add discount</div>
          <div className="fl-modal-description">
            <MInput
                type="date"
                label="Start discount"
                hasInfo={true}
                infoId="start-disc"
                infoText={[
                  "Select today's date if you want your discount to start immediately,",
                  "or schedule your discount to start on a future date.",
                ]}
                placeholder=""
                minIsSet={new Date().toISOString().split("T")[0]}
                value={discountState.starts_at}
                setValue={(starts_at) => {
                  if (
                      new Date() < new Date(starts_at) ||
                      new Date().toISOString().split("T")[0] === starts_at
                  ) {
                    setDiscountState({ ...discountState, starts_at });
                  }
                }}
                className={"fl-settings-mt-30"}
            />
            <div className={"fl-settings-mt-30"}>
              <SelectWithNumberInput
                  stateName="duration"
                  inputTypeSwitched={customDiscountDuration}
                  inputTypeSwitch={setCustomDiscountDuration}
                  state={discountState}
                  setState={setDiscountState}
                  labelForSelect="Discount duration"
                  labelForNumber="Custom discount duration"
                  value={discountState.duration}
                  options={[
                    { value: "1", label: "1 day" },
                    { value: "7", label: "7 days" },
                    { value: "14", label: "14 days" },
                    { value: "30", label: "30 days (recommended)" },
                    { value: "60", label: "60 days" },
                    { value: "90", label: "90 days" },
                    { value: "180", label: "180 days" },
                    { value: "365", label: "365 days" },
                    {
                      value: "custom",
                      label: "Choose a different duration",
                    },
                  ]}
                  min="0"
                  hasInfo={true}
                  infoId="discounted-duration"
                  infoText={["You can run a discount from 1 to 5000 days."]}
              />
            </div>
            <MInput
                className={"fl-settings-mt-30"}
                type="select"
                label="Discount amount %"
                subsInput={true}
                value={discountState.amount}
                setValue={(amount) =>
                    setDiscountState({ ...discountState, amount })
                }
                options={[
                  {
                    options: [
                      { value: "10", label: "10%" },
                      { value: "15", label: "15%" },
                      { value: "20", label: "20%" },
                      { value: "25", label: "25%" },
                      { value: "30", label: "30%" },
                      { value: "35", label: "35%" },
                      { value: "40", label: "40%" },
                      { value: "45", label: "45%" },
                      { value: "50", label: "50% (recommended)" },
                      { value: "55", label: "55%" },
                      { value: "60", label: "60%" },
                      { value: "65", label: "65%" },
                      { value: "70", label: "70%" },
                      { value: "75", label: "75%" },
                      { value: "80", label: "80%" },
                      { value: "85", label: "85%" },
                      { value: "90", label: "90%" },
                      { value: "100", label: "100%" },
                    ],
                  },
                ]}
            />
            <div className={"fl-settings-mt-30"}>
              <SelectWithNumberInput
                  stateName="number_of_discounts"
                  inputTypeSwitched={customDiscountNumber}
                  inputTypeSwitch={setCustomDiscountNumber}
                  state={discountState}
                  setState={setDiscountState}
                  labelForSelect={"Number of discounted subscriptions:"}
                  labelForNumber={"Number of discounted subscriptions"}
                  value={discountState.number_of_discounts}
                  options={[
                    {value: "0", label: "Unlimited"},
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                    { value: "150", label: "150" },
                    { value: "200", label: "200" },
                    { value: "custom", label: "Other amount" },
                  ]}
                  min="1"
                  hasInfo={true}
                  infoId="discounted-subs"
                  infoText={["Choose any number between 1 and 1,000,000."]}
              />
            </div>
          </div>
          <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
            <Button onClick={modalData.close}>
              Cancel{" "}
              {discountState.starts_at &&
                moment(discountState.starts_at).format("D MMMM, YYYY") !==
                  moment().format("D MMMM, YYYY") &&
                "schedule discount"}
            </Button>
            <Button
              type="normal"
              onClick={() => setConfirmCreation(true)}
              disabled={
                discountState.amount === 0 ||
                !discountState.number_of_discounts ||
                !discountState.starts_at
              }
            >
              Next
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CreateDiscountModal;
