import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useUserContext } from "../context/UserContext";
import { SimpleCard } from "./SimpleCard";
import "./UserProfile.css";
// @ts-ignore
import AvatarEditor from "react-avatar-editor";
// ASSETS
import locationSource from "../assets/images/icons/7.svg";
import birthdaySource from "../assets/images/icons/8.svg";
import { Link, useHistory } from "react-router-dom";
import { ProfileHolder } from "./ProfileHolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar, faStopCircle} from "@fortawesome/free-regular-svg-icons";
import "../components/CountryFlags/css/flag-icons.css";
//@ts-ignore
import { ReactMic } from 'react-mic';
import profileHolderImg from "../assets/images/profile.png";
import {
  faStar as faStarSolid,
  faTimes,
  faUpload,
  faArrowsAlt,
  faCamera,
  faEdit,
  faCertificate,
  faCheck,
  faVolumeUp,
  faStop,
  faMicrophoneAlt,
  faCircle,
  faUserEdit,
  faEllipsisH,
  faShare,
  faClone,
  faTrashAlt,
  faExclamationTriangle, faBan,faChevronUp, faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useModalContext } from "../context/ModalContext";
import { Button } from "./Button";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import {
  faAmazon,
  faInstagram,
  faRedditAlien,
  faSnapchatGhost,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import ReactLoading from "react-loading";
import {UserProfileRestrictBlock} from "./UserProfileRestrictBlock";
import {MessageAudio} from "./MessageAudio";

var localTimer: any = null;

export const UserProfile: FC<any> = ({
                                       data,
                                       getUserProfileData,
                                       setUpdateState,
                                       updateState,
                                       getProfilePosts,
                                     }) => {
  // CONTEXT
  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();
  const history = useHistory()
  // STATE
  const [state, setState] = useState<{
    playRecord: boolean;
    dropdown: boolean;
    profileSource: string | null;
    backgroundSource: string | null;
    profileDropdown: boolean;
    headerDropdown: boolean;
    positionX: string;
    positionY: string;
    backPositionX: string;
    backPositionY: string;
    expandBio: boolean;
    imgRemoved: boolean;
    bgRemoved: boolean;
    recRemoved: boolean;
    statusDropdown: boolean;
  }>({
    playRecord: false,
    dropdown: false,
    profileSource: null,
    backgroundSource: null,
    profileDropdown: false,
    headerDropdown: false,
    positionX: "0",
    positionY: "0",
    backPositionX: "0",
    backPositionY: "0",
    expandBio: false,
    imgRemoved: false,
    bgRemoved: false,
    recRemoved: false,
    statusDropdown: false,
  });

  const [generalState, setGeneralState] = useState({
    handle: "",
  });

  const getAccountData = async () => {
    try {
      const res = await axios.get(`${envData.apiUrl}/auth/profile`);

      setGeneralState({ handle: res.data.data.handle });
    } catch (e) {}
  };

  useEffect(() => {
    getAccountData();
  }, []);

  const getProfileImages = (user: string) => {
    if (user !== "login" && user !== undefined) {
      Axios.get(`${envData.apiUrl}/profile/${user}`)
          .then((resp) => {
            setState({
              ...state,
              profileSource: resp?.data?.data?.profileImage?.url || null,
              backgroundSource: resp?.data?.data?.backgroundImage?.url || null,
              positionX: resp?.data?.data?.profileImage?.position_x || null,
              positionY: resp?.data?.data?.profileImage?.position_y || null,
              backPositionX:
                  resp?.data?.data?.backgroundImage?.position_x || null,
              backPositionY:
                  resp?.data?.data?.backgroundImage?.position_y || null,
              profileDropdown: false,
              headerDropdown: false,
            });
          })
          .catch(() => modalData.pushToast("error", "Something went wrong!"));
    }
  };

  useEffect(() => {
    getProfileImages(data?.handle);
  }, [data]);

  const [profile, setProfile] = useState<any>({
    dropdown: false,
    backgroundDropdown: false,
    favorite: data?.meta.is_favorited,
  });

  useEffect(() => {
    setProfile({ dropdown: false, favorite: data?.meta.is_favorited });
  }, []);

  const stateRef = useRef<any>(null);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const setBackgroundSource = (
      backgroundSource: string,
      backPositionX: string,
      backPositionY: string
  ) => {
    setState({
      ...stateRef.current,
      bgRemoved: false,
      backgroundSource,
      backPositionX,
      backPositionY,
    });
  };

  const setProfileSource = (
      profileSource: string,
      positionX: string,
      positionY: string
  ) => {
    setState({
      ...stateRef.current,
      imgRemoved: false,
      profileSource,
      positionX,
      positionY,
    });
  };

  const changeProfile = async () => {
    setState({ ...stateRef.current, dropdown: false });
    await modalData.push(() => (
        <ProfileModal
            setProfileSource={setProfileSource}
            x={state.positionX}
            y={state.positionY}
            data={state.profileSource}
        />
    ));
  };

  const changeBackground = async () => {
    setState({ ...stateRef.current, dropdown: false });
    await modalData.push(() => (
        <BackgroundModal
            setBackgroundSource={setBackgroundSource}
            data={state.backgroundSource}
            x={state.backPositionX}
            y={state.backPositionY}
        />
    ));
  };

  const addProfileToFavorites = async () => {
    try {
      await Axios.post(`${envData.apiUrl}/favorites/profile/${data.handle}`, {
        favorite: !profile.favorite,
      });
      setProfile({ ...profile, favorite: !profile.favorite });
      if(!profile.favorite)
      {
        userData.updateCountFavorites("add","profiles")
      } else {
        userData.updateCountFavorites("remove","profiles")
      }
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const recordVoice = async () => {
    await modalData.push(() => <ProfileRecording data={data} setUpdateState={setUpdateState} updateState={updateState} />)
  };

  const audioRef = useRef<HTMLAudioElement | any>();

  const toggleAudio = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setState({ ...state, playRecord: !audioRef.current.paused });
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", () => {
        setState({ ...stateRef.current, playRecord: false });
      });
    }
  }, []);

  const copyLink = async () => {
    modalData.clearToasts()
    try {
      await navigator.clipboard.writeText(
          `${window.location.origin}/${data.handle}`
      );
      modalData.pushToast("success", "Link copied to clipboard");
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  }

  const shareProfile = async () => {
    modalData.clearToasts()
    //@ts-ignore
    if (navigator.share) {
      //@ts-ignore
      navigator.share({
        text: `${data.handle} on Fluffa`,
        url: `${window.location.origin}/${data.handle}`,
        title: 'Fluffa'
      }).then(() => {

      }).catch ((error:any)=> {
        if (error.name === "AbortError") {
          return;
        } else {
          modalData.pushToast("error", "Something went wrong!");
        }
      })
    }
    else {
      await navigator.clipboard.writeText(
          `${window.location.origin}/${data.handle}`
      );
      modalData.pushToast("success", "Link copied to clipboard");
    }
  };

  const [mobile, setMobile] = useState<boolean>(false);

  const handleResize = () => {
    if (window.innerWidth < 576) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const [showBio, setShowBio] = useState<boolean>(false);

  const unSubscribe = async () => {
    await modalData.push(() => (
        <OpenUnsubscribeModal
            data={data}
            updateState={updateState}
            setUpdateState={setUpdateState}
            getUserProfileData={getUserProfileData}
            getProfilePosts={getProfilePosts}
            convertDataRemaining={convertDataRemaining}
        />
    ));
  };

  const processPlan = async (planId: number, action = "") => {
    modalData.clearToasts();
    try {
      const res = await Axios.post(
          `${envData.apiUrl}/profile/${data.handle}/subscribe${
              planId > 0 ? `/${planId}` : ""
          }`
      );

      const subscription = {
        created_at: res.data.data.created_at,
        creator_profile: res.data.data.creatorProfile,
        creator_profile_id: res.data.data.creatorProfile.id,
        ends_at: res.data.data.ends_at,
        id: res.data.data.id,
        price: convertDataRemaining(res.data.data.subscriptionPlan.expires_at) < 1 ? res.data.data.subscriptionPlan.upgrade_price : res.data.data.subscriptionPlan.price,
        starts_at: res.data.data.starts_at,
        status: "ACTIVE",
        subscription_plan: res.data.data.subscriptionPlan,
        subscription_plan_id: res.data.data.subscriptionPlan.id,
      };
      setUpdateState({
        ...updateState,
        profileObject: {
          ...updateState.profileObject,
          meta: {
            ...updateState.profileObject.meta,
            is_subscribed: subscription,
          },
        },
      });
      userData.updateCount("following")
      modalData.pushToast(
          "success",
          `You have successfully restored your ${
              planId > 0 ? "subscription" : `following`
          }.`
      );
      await modalData.closeAll();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const renew = async (plan: any) => {
    const SubscribeModal = () => {
      const [renewLoading, setRenewLoading] = useState(false);
      useEffect(() => {
        document.body.classList.add("fl-body-fixed");
        return function cleanup() {
          document.body.classList.remove("fl-body-fixed");
        };
      }, []);

      const returnPricePlan = () => {
        if(plan.discounted_plan) {
          return plan.discounted_plan.new_price.toFixed(2)
        } else if (plan.subscription_plan.upgrade_price) {
          return plan.subscription_plan.upgrade_price
        } else {
          return plan.subscription_plan.price
        }
      }

      return (
          <Fragment>
            <div className="fl-modal-title">
              <div className="fl-modal-title-text">
                Resubscribe to {data.handle}?
              </div>
            </div>
            <div className="fl-modal-description">
              <div>Your original subscription will be restored.</div> <br />
              <div>
                You'll be automatically charged ${returnPricePlan()}/{" "}
                {plan.subscription_plan.upgrade_price !== null ? "month" : plan.subscription_plan.billing_period} starting on{" "}
                {moment(plan.ends_at).format(
                    "D MMM YYYY"
                )}.
              </div>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
              <Button onClick={() => modalData.close()}>No, thanks</Button>
              {userData.user.meta.can_subscribe ? (
                  <Button
                      disabled={renewLoading}
                      type="normal"
                      onClick={async () => {
                        setRenewLoading(true);
                        await processPlan(plan.subscription_plan.id, "resubscribe");
                        setRenewLoading(false);
                      }}
                  >
                    {renewLoading ? (
                        <>
                          {" "}
                          Resubscribing{" "}
                          <ReactLoading
                              className="fl-spinningBubbles"
                              type={"spinningBubbles"}
                              color={"#fff"}
                              height={20}
                              width={20}
                          />{" "}
                        </>
                    ) : (
                        "Resubscribe"
                    )}
                  </Button>
              ) : (
                  <Button
                      type="normal"
                      to="/settings/payment"
                      onClick={modalData.close}
                  >
                    Add a payment card
                  </Button>
              )}
              {}
            </div>
          </Fragment>
      );
    };

    await modalData.push(() => <SubscribeModal />);
  };

  const subscriptionPlan = () => {
    return data?.meta?.is_subscribed?.subscription_plan
  }

  const openModalBlockRestrict = (action:string) => {
    modalData.push(() => <UserProfileRestrictBlock action={action} data={data} setUpdateState={setUpdateState} updateState={updateState}/>)
  }

  const convertDataRemaining = (x:any) => {
    const ends_date = moment(x).format("X")
    const currentData = moment(new Date()).format("X")
    const days = Number(ends_date) - Number(currentData)
    return  Math.ceil(days / 86400)
  }

  const followersPage = () => {
    if(userData.authStatus === 'UNAUTHENTICATED' || userData?.user?.id !== data?.id) {
      return false
    } else {
      return true
    }
  }

  const [showStatistics,setShowStatistics] = useState<boolean>(false)

  const returnPricePlan = () => {
    if(data.meta.is_subscribed.discounted_plan) {
      return  data.meta.is_subscribed.discounted_plan.new_price.toFixed(2)
    } else if ( data.meta.is_subscribed.subscription_plan.upgrade_price) {
      return data.meta.is_subscribed.subscription_plan.upgrade_price
    } else {
      return  data.meta.is_subscribed.price
    }
  }
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
      <>
        <SimpleCard className="fl-user-profile-main">
          {data !== null ? (
              <>
                {isMobile ?
                      <span
                          className={`fl-user-profile-data-name-full fl-user-profile-data-name-full-${userData.currentTheme} fl-mobile-navigation-profile`}
                      >
                            <span className={"fl-user-profile-full-name-span"}>
                              {data.name}
                              {data.meta.verified_state === "verified" ? (
                                  <>
                                    <div
                                        className="fl-user-profile-verified"
                                        title="Verified creator"
                                    >
                                      <FontAwesomeIcon
                                          className="fl-user-profile-verified-crt"
                                          icon={faCertificate}
                                      />
                                      <FontAwesomeIcon
                                          data-tip
                                          data-for="profile-verified"
                                          className="fl-user-profile-verified-chc"
                                          icon={faCheck}
                                      />
                                    </div>
                                    <ReactTooltip id="profile-verified">
                                      Verified
                                    </ReactTooltip>
                                  </>
                              ) : (
                                  ""
                              )}
                            </span>
                            <span className={"fl-mobile-navigation-profile-count"}>{data.meta.posts} Post{data.meta.posts > 1 || data.meta.posts === 0  ? "s" : ""}</span>
                      </span>
                       : ""
                }
                <div className="fl-user-profile-images">
                  <div
                      className={`fl-user-profile-images-cover fl-user-profile-images-cover-${userData.currentTheme}`}
                  >
                    {state.backgroundSource ? (
                            <div className="smooth-image-wrapper">
                              {state.backgroundSource ?
                                  <img  alt={"background"} style={{objectPosition: `${state.backPositionX  ? state.backPositionX : 0 }% ${state.backPositionY ? state.backPositionY : 0}%`}} src={state.backgroundSource} className="smooth-image image-visible"/> :
                                  <div className="smooth-preloader">
                                    <span className="loader" />
                                  </div>
                              }
                            </div>
                    ) : (
                        ""
                    )}
                  </div>

                  {data.handle === userData.user.handle ? (
                      <div
                          // onClick={changeProfile}
                          className="fl-user-profile-image-change-icon"
                          tabIndex={1}
                          onClick={() =>
                              setState({
                                ...state,
                                profileDropdown: !state.profileDropdown,
                              })
                          }
                          onBlur={() =>
                              setState({ ...stateRef.current, profileDropdown: false })
                          }
                      >
                        <FontAwesomeIcon style={{ color: "#fff" }} icon={faCamera} />
                        {state.profileDropdown ? (
                            <div
                                className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown--right fl-post-head-actions-dropdown--profile  fl-post-head-actions-dropdown-${userData.currentTheme}`}
                            >
                              <SimpleCard className="fl-post-head-actions-dropdown-inner">
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={changeProfile}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    {state.profileSource ? (
                                        <FontAwesomeIcon icon={faEdit} />
                                    ) : (
                                        <FontAwesomeIcon icon={faUpload} />
                                    )}
                                  </div>
                                  {state.profileSource
                                      ? "Change profile photo"
                                      : "Add profile photo"}
                                </div>
                                {state.profileSource && (
                                    <div
                                        className="fl-post-head-actions-dropdown-option"
                                        onClick={() => modalData.push(() => <DeleteProfilePhoto getProfileImages={getProfileImages} setState={setState} state={state} type={"profile"}/>)}
                                    >
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faTimes} />
                                      </div>
                                      Remove profile photo
                                    </div>
                                )}
                              </SimpleCard>
                            </div>
                        ) : (
                            ""
                        )}
                      </div>
                  ) : (
                      ""
                  )}

                  {data.handle === userData.user.handle ? (
                      <div
                          className="fl-user-background-image-change-icon"
                          tabIndex={1}
                          onClick={() =>
                              setState({
                                ...state,
                                headerDropdown: !state.headerDropdown,
                              })
                          }
                          onBlur={() =>
                              setState({ ...stateRef.current, headerDropdown: false })
                          }
                      >
                        <FontAwesomeIcon icon={faCamera} />
                        {state.headerDropdown ? (
                            <div
                                className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                            >
                              <SimpleCard className="fl-post-head-actions-dropdown-inner">
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={changeBackground}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    {state.backgroundSource ? (
                                        <FontAwesomeIcon icon={faEdit} />
                                    ) : (
                                        <FontAwesomeIcon icon={faUpload} />
                                    )}
                                  </div>
                                  {state.backgroundSource ||
                                  state.backgroundSource !== null
                                      ? "Change header photo"
                                      : "Add header photo"}
                                </div>
                                {state.backgroundSource && (
                                    <div
                                        className="fl-post-head-actions-dropdown-option"
                                        // onClick={removeHeaderImage}
                                        onClick={() => modalData.push(() => <DeleteProfilePhoto state={state} setState={setState} getProfileImages={getProfileImages} type={"header"}/>)}
                                    >
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faTimes} />
                                      </div>
                                      Remove header photo
                                    </div>
                                )}
                              </SimpleCard>
                            </div>
                        ) : (
                            ""
                        )}
                      </div>
                  ) : (
                      ""
                  )}
                  <div
                      className="fl-user-profile-options"
                      tabIndex={1}
                      onBlur={() =>
                          setState({ ...stateRef.current, dropdown: false })
                      }
                      onClick={() =>
                          setState({ ...state, dropdown: !state.dropdown })
                      }
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                    {state.dropdown ? (
                        <div
                            className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
                        >
                          <SimpleCard className="fl-post-head-actions-dropdown-inner">
                            {mobile && (
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={() => shareProfile()}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faShare} />
                                  </div>
                                  Share profile
                                </div>
                            )}
                            <div
                                className="fl-post-head-actions-dropdown-option"
                                onClick={() => copyLink()}
                            >
                              <div className="fl-post-head-actions-dropdown-option-icon">
                                <FontAwesomeIcon icon={faClone} />
                              </div>
                              Copy profile link
                            </div>
                            {userData.authStatus === "AUTHENTICATED" && userData.user.id !== data.id &&
                            (profile.favorite ? (
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={() => addProfileToFavorites()}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faStarSolid} />
                                  </div>
                                  Remove from favorites
                                </div>
                            ) : (
                                <div
                                    className="fl-post-head-actions-dropdown-option"
                                    onClick={() => addProfileToFavorites()}
                                >
                                  <div className="fl-post-head-actions-dropdown-option-icon">
                                    <FontAwesomeIcon icon={faStar} />
                                  </div>
                                  Add to favorites
                                </div>
                            ))}
                            {userData.authStatus === "AUTHENTICATED" && userData.user.handle !== data.handle && !data.meta.is_blocked &&
                            <div
                                className="fl-post-head-actions-dropdown-option"
                                onClick={() => openModalBlockRestrict("restrict")}
                            >
                              <div className="fl-post-head-actions-dropdown-option-icon">
                                <FontAwesomeIcon icon={faExclamationTriangle}/>
                              </div>
                              {data.meta.restricted_by_me ? "Unrestrict" : "Restrict"} user
                            </div>
                            }
                            {userData.authStatus === "AUTHENTICATED" && userData.user.handle !== data.handle && !data.meta.restricted_by_me &&
                            <div
                                className="fl-post-head-actions-dropdown-option"
                                onClick={() => openModalBlockRestrict("block")}
                            >
                              <div className="fl-post-head-actions-dropdown-option-icon">
                                <FontAwesomeIcon icon={faBan}/>
                              </div>
                              {data.meta.is_blocked ? "Unblock" : "Block"} user
                            </div>
                            }
                          </SimpleCard>
                        </div>
                    ) : (
                        ""
                    )}
                  </div>
                  <div className="fl-user-profile-images-main">
                    <div className="fl-user-profile-images-main-inner">
                      <ProfileHolder
                          changeStatus
                          userProfile={data}
                          src={state.profileSource}
                          style={{
                            objectPosition: `${
                                //@ts-ignore
                                state.positionX || state.positionX === 0
                                    ? state.positionX
                                    : 0
                            }% ${
                                //@ts-ignore
                                state.positionY || state.positionY === 0
                                    ? state.positionY
                                    : 0
                            }%`,
                          }}
                      />
                    </div>
                  </div>
                  {data.nationality ? (
                      <div className="fl-user-profile-addons">
                 {/*       <Link
                            to={`/suggestions/country/US`}
                            className={`fl-user-profile-data-name-full-location flag-icon-background flag-icon-${data.nationality?.cca2?.toLowerCase()}`}
                            data-tip
                            data-for="user-nationality"
                        ></Link>*/}
                        <span
                            className={`fl-user-profile-data-name-full-location flag-icon-background flag-icon-${data.nationality?.cca2?.toLowerCase()}`}
                            data-tip
                            data-for="user-nationality"
                        ></span>
                        <ReactTooltip id="user-nationality">
                          {data.nationality ? `${data.nationality.name} ` : ""}
                          {data.creatorProfile ? "Creator" : "Follower"}
                        </ReactTooltip>
                      </div>
                  ) : (
                      ""
                  )}
                </div>
                <div
                    className="fl-user-profile-data"
                    onClick={() => setProfile({ ...profile, dropdown: false })}
                >
                  <div className="fl-user-profile-data-left">
                    {data.age ? (
                        <div className="fl-user-profile-data-row">
                          <div
                              className={`fl-user-profile-data-icon fl-user-profile-data-icon-${userData.currentTheme}`}
                          >
                            <img style={{ marginTop: -2 }} src={birthdaySource} alt={"birthday img"} />
                          </div>
                          <span>{data.age}</span>
                        </div>
                    ) : (
                        ""
                    )}
                    {data.location ? (
                        <div className="fl-user-profile-data-row">
                          <div
                              className={`fl-user-profile-data-icon fl-user-profile-data-icon-${userData.currentTheme}`}
                          >
                            <img style={{ marginTop: -2 }} src={locationSource} alt={"location img"} />
                          </div>
                          <span>{data.location}</span>
                        </div>
                    ) : (
                        ""
                    )}
                    {data.creatorProfile?.audio_presentation_url ? (
                        <div className="fl-user-profile-data-row">
                          <div
                              className="fl-user-profile-voice"
                              title="Voice"
                              onClick={toggleAudio}
                          >
                            <FontAwesomeIcon
                                className="fl-user-profile-voice-crt"
                                icon={faCircle}
                            />
                            <FontAwesomeIcon
                                className="fl-user-profile-voice-play"
                                icon={state.playRecord ? faStop : faVolumeUp}
                                style={{marginLeft: "-1px"}}
                            />
                            <audio
                                style={{ display: "none" }}
                                src={data.creatorProfile.audio_presentation_url}
                                ref={audioRef}
                            ></audio>
                          </div>
                        </div>
                    ) : (
                        ""
                    )}
                  </div>
                  <div className="fl-user-profile-data-right">
                    <div
                        className={`fl-user-profile-data-info fl-user-profile-data-info-${
                            userData.authStatus !== "AUTHENTICATED" && "logout"
                        } `}
                    >
                      <div className={'fl-user-profile-data-share'}  onClick={() => shareProfile()}>
                        <FontAwesomeIcon icon={faShare} data-tip data-for={"profile-copy"} />
                      </div>
                      <ReactTooltip id="profile-copy">
                        Share
                      </ReactTooltip>
                      <div className={"fl-user-profile-full-info"}>
                        <div className="fl-user-profile-full-name">
                          <Link
                              to={`/${data.handle}`}
                              className={`fl-user-profile-data-name-full fl-user-profile-data-name-full-${userData.currentTheme}`}
                          >
                            <span className={"fl-user-profile-full-name-span"}>
                              {data.name}
                              {data.meta.verified_state === "verified" ? (
                                  <>
                                    <div
                                        className="fl-user-profile-verified"
                                        title="Verified creator"
                                    >
                                      <FontAwesomeIcon
                                          className="fl-user-profile-verified-crt"
                                          icon={faCertificate}
                                      />
                                      <FontAwesomeIcon
                                          data-tip
                                          data-for="profile-verified"
                                          className="fl-user-profile-verified-chc"
                                          icon={faCheck}
                                      />
                                    </div>
                                    <ReactTooltip id="profile-verified">
                                      Verified
                                    </ReactTooltip>
                                  </>
                              ) : (
                                  ""
                              )}
                            </span>
                          </Link>
                        </div>
                        <div className="fl-user-profile-data-right-top">
                          <Link
                              to={`/${data.handle}`}
                              className={`fl-user-profile-data-name-username fl-user-profile-data-name-username-${userData.currentTheme}`}
                          >
                            @{data.handle}
                          </Link>
                        </div>
                      </div>
                      <div className="fl-user-profile-data-values">
                        <div className="fl-user-info">
                          <div className="fl-user-profile-data-key">Posts</div>
                          <div
                              className={`fl-user-profile-data-value fl-user-profile-data-value-${userData.currentTheme}`}
                          >
                            {userData.user.id === data.id ? userData.user.profile.meta.posts : data.meta.posts}
                          </div>
                        </div>
                        {userData.user.id === data.id || data.meta.show_followers_number ?
                            <React.Fragment>
                              <div className="fl-user-info">
                                <div className="fl-user-profile-data-key">
                                  Followers
                                </div>
                                <div
                                    className={`fl-user-profile-data-value fl-user-profile-data-value-${userData.currentTheme} ${followersPage() ? "fl-user-profile-data-value-clickable" : ""}`}
                                    onClick={() => followersPage() ? history.push("/follow/followers") : ""}
                                >
                                  {userData.user.id === data.id ? userData.user.profile.meta.followers : data.meta.followers}
                                </div>
                              </div>
                            </React.Fragment> : ""
                        }
                        {userData.user.id === data.id || data.meta.show_likes ?
                          <div className="fl-user-info">
                            <div className="fl-user-profile-data-key">Likes</div>
                            <div
                                className={`fl-user-profile-data-value fl-user-profile-data-value-${userData.currentTheme}`}
                            >
                              {userData.user.id === data.id ? userData.user.profile.meta.likes : data.meta.likes}
                            </div>
                          </div> : ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"fl-user-profile-data-bottom"}>
                  {data?.bio?.length > 0 || data?.website_url?.length > 0 || data?.social_links?.length > 0 ?
                      <div className="fl-user-profile-data-right-bio fl-user-profile-data-right-bio-info">
                        {data?.bio?.length > 0 ?
                            <div
                                className={`fl-user-profile-data-right-bio-default ${
                                    state.expandBio
                                        ? "fl-user-profile-data-right-bio-expand"
                                        : ""
                                }`}
                            >
                              {data?.bio?.length > 0 && (
                                  <>
                                    {data.bio.slice(0, showBio ? 1000 : 137).split("\n").map((item:any) => {
                                      return (
                                          <>
                                            {item}
                                            <br/>
                                          </>
                                      )
                                    })}
                                    {data.bio.length > 137 && !showBio && "..."}
                                  </>
                              )}
                              {data?.bio?.length > 137 && (
                                  <div className={"fl-user-profile-show-more"}>
                                  <span onClick={() => setShowBio(!showBio)}>
                                    Show {showBio ? "less" : "more"}
                                  </span>
                                  </div>
                              )}
                            </div> : ""
                        }
                        {data?.social_links ?
                            <div className="fl-user-profile-data-right-bio-social">
                              {data?.social_links?.instagram && (
                                  <a
                                      target="_blank"
                                      href={`https://instagram.com/${data.social_links.instagram}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-instagram "
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faInstagram} />
                                  </a>
                              )}
                              {data?.social_links?.twitter && (
                                  <a
                                      target="_blank"
                                      href={`https://twitter.com/${data.social_links.twitter}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-twitter "
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faTwitter} />
                                  </a>
                              )}
                              {data?.social_links?.reddit && (
                                  <a
                                      target="_blank"
                                      href={`https://reddit.com/user/${data.social_links.reddit}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-reddit "
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faRedditAlien} />
                                  </a>
                              )}
                              {data?.social_links?.snap && (
                                  <a
                                      target="_blank"
                                      href={`https://snapchat.com/${data.social_links.snap}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-snap"
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faSnapchatGhost} />
                                  </a>
                              )}
                              {data?.social_links?.youtube && (
                                  <a
                                      target="_blank"
                                      href={`${data.social_links.youtube}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-youtube"
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faYoutube} />
                                  </a>
                              )}
                              {data?.social_links?.amazon && (
                                  <a
                                      target="_blank"
                                      href={`${data.social_links.amazon}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-amazon"
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faAmazon} />
                                  </a>
                              )}
                              {data?.social_links?.tiktok && (
                                  <a
                                      target="_blank"
                                      href={`https://tiktok.com/@${data.social_links.tiktok}`}
                                      className="fl-user-profile-data-right-bio-social-item fl-user-profile-data-right-bio-social-item-tiktok"
                                      rel="noreferrer"
                                  >
                                    <FontAwesomeIcon icon={faTiktok} />
                                  </a>
                              )}
                            </div> : ""
                        }
                      </div> : ""
                  }
                  {data.meta.subscription_plan !== null && data.meta.subscription_plan !== false && data.meta.verified_state === "verified"  ?
                      <div className="fl-user-profile-data-right-bio fl-user-statistics-right">
                        <div className={"fl-user-profile-data-right-bio-default"}>
                        <span className={"fl-follower-statistics"} onClick={() => setShowStatistics(!showStatistics)}>
                          FAN STATISTICS
                          <FontAwesomeIcon className="fl-post-head-actions-icon" icon={showStatistics ? faChevronUp : faChevronDown}/>
                        </span>
                          <div className={`fl-follower-statistics-menu-${showStatistics ? 'open' : 'closed'}` }>
                            <div className={"fl-follower-statistics-full"}>
                              <span>Subscribed for:</span>
                              <span>{data.meta.subscription_plan === "free" ? "Free" : `$${data.meta.subscription_plan.price}/${data.meta.subscription_plan.billing_period}`}</span>
                            </div>
                            <div className={"fl-follower-statistics-full"}>
                              <span>Total spend:</span>
                              <span>${data.meta.total_spend.toFixed(2)}</span>
                            </div>
                          </div>
                        </div>
                      </div> : ""
                  }
                </div>
                {generalState.handle === data.handle ? (
                    <div className="fl-user-profile-data-edit">
                      <Button
                          className="fl-user-profile-data-edit-button"
                          to="/settings/profile"
                          type="normal"
                      >
                        <FontAwesomeIcon
                            className="fl-user-profile-data-button-icons"
                            icon={faUserEdit}
                        />
                        Edit profile
                      </Button>
                      {userData.user.profile.meta.verified_state === "verified" ? (
                          <>
                            <Button
                                className="fl-user-profile-data-record-button"
                                type="normal"
                                onClick={recordVoice}
                            >
                              <FontAwesomeIcon
                                  className="fl-user-profile-data-button-icons"
                                  icon={faVolumeUp}
                              />
                              {data.creatorProfile?.audio_presentation_url
                                  ? "Edit"
                                  : "Add"}{" "}
                              voice greeting
                            </Button>
                          </>
                      ) : (
                          ""
                      )}
                    </div>
                ) : (
                    ""
                )}
                {data.meta.is_subscribed.billing_method === "free" ? (
                    <div
                        className={`fl-user-profile-is-subscribed ${
                            updateState.profileObject.meta.is_subscribed.status ===
                            "ACTIVE"
                                ? "fl-user-profile-is-subscribed-active"
                                : " "
                        }`}
                        onClick={() =>
                            updateState?.profileObject?.meta.is_subscribed.status ===
                            "ACTIVE"
                                ? unSubscribe()
                                : ""
                        }
                    >
                      FOLLOWING <span>FOR FREE</span>
                    </div>
                ) : data.meta.is_subscribed ? (
                    <Fragment>
                      <div
                          className={`fl-user-profile-is-subscribed fl-user-profile-is-subscribed-active`}
                          onClick={() =>
                              updateState?.profileObject?.meta.is_subscribed.status ===
                              "ACTIVE" || (updateState?.profileObject?.meta.is_subscribed.status === "ACTIVE" && subscriptionPlan().type === 'trial')
                                  ? unSubscribe()
                                  : renew(data.meta.is_subscribed)
                          }
                      >
                        {updateState?.profileObject?.meta.is_subscribed.status === "ACTIVE" || (updateState?.profileObject?.meta.is_subscribed.status === "CANCELLED" && subscriptionPlan().type == 'trial')
                            ? updateState?.profileObject?.meta.is_subscribed.status === "CANCELLED" ? "Cancelled" : "Subscribed"
                            : "Resubscribe"}{" "}
                        <span>
                          {data.meta.is_subscribed.price === 0 && convertDataRemaining(data.meta.is_subscribed.ends_at) > 0
                              ? `${subscriptionPlan()?.type === 'trial' ? 'TRIAL' : 'FREE'} for ${subscriptionPlan()?.billing_period_count} day${subscriptionPlan().billing_period_count > 1 ? "s":""}`
                              : `$${returnPricePlan()}/ ${subscriptionPlan().billing_period}`}
                        </span>
                      </div>
                      {(updateState?.profileObject?.meta.is_subscribed.status === "ACTIVE" || updateState?.profileObject?.meta.is_subscribed.status === "CANCELLED") && convertDataRemaining(data.meta.is_subscribed.subscription_plan.ends_at) < 0 ? (
                          <div
                              className={`fl-user-profile-is-subscribed-text fl-user-profile-is-subscribed-text-${userData.currentTheme}`}
                          >
                            {updateState?.profileObject?.meta.is_subscribed.status === "ACTIVE" ? <>Next payment: ${returnPricePlan()} on{" "}</> : <> Subscribed until{" "}</>}
                            {moment(data.meta.is_subscribed.ends_at).format(
                                "D MMM YYYY"
                            )}
                          </div>
                      ) : (
                          <div
                              className={`fl-user-profile-is-subscribed-text fl-user-profile-is-subscribed-text-${userData.currentTheme}`}
                          >
                            Free until:{" "}
                            {updateState?.profileObject?.meta.is_subscribed.status === "ACTIVE" ?
                                <>
                                  {moment(data.meta.is_subscribed.ends_at).format(
                                      "D MMM YYYY"
                                  )}, {subscriptionPlan().upgrade_price > 0 ? <>then converts to ${subscriptionPlan().upgrade_price}/ month</> : <>({convertDataRemaining(data.meta.is_subscribed.ends_at)} day{convertDataRemaining(data.meta.is_subscribed.ends_at) > 1 ? "s" : ""} remaining)</>}
                                </> :
                                <>
                                  {moment(data.meta.is_subscribed.ends_at).format(
                                      "D MMM YYYY"
                                  )} ({convertDataRemaining(data.meta.is_subscribed.ends_at)} day{convertDataRemaining(data.meta.is_subscribed.ends_at) > 1 ? "s" : ""} remaining)
                                </>
                            }
                          </div>
                      )}
                    </Fragment>
                ) : (
                    ""
                )}
              </>
          ) : (
              <div className={"fl-loader-animation fl-purchased-empty"}>
                <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
              </div>
          )}
        </SimpleCard>
      </>
  );
};

const ProfileModal = ({ data, setProfileSource,x,y }: any) => {
  const envData = useEnvContext();
  const modalData = useModalContext();
  const userData = useUserContext()

  const [changes, setChanges] = useState(false);

  const [source, setSource] = useState<string>(data);
  const [file, setFile] = useState<any>(null);
  const [state, setState] = useState<{ uploading: boolean; percent: number }>({
    uploading: false,
    percent: 0,
  });
  const [reposition, setReposition] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<any>(null);

  useEffect(() => {
    stateRef.current = state;
    setFile(data);
  }, [state]);

  const updateFile = (e: React.FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length) {
      setFile(files[0]);
      var reader = new FileReader();
      reader.onload = (e: any) => setSource(e.target.result);
      reader.readAsDataURL(files[0]);
      setChanges(true);
    }
  };

  const calcXandY = (x: number, y: number, imgW: number, imgH: number) => {
    const center = 0.5;
    let perc: number = 50;
    if (imgW > imgH) {
      const left0 = center - 0.5 / (imgW / (imgW - imgH));
      const distance = center - left0;
      perc = 50 - ((0.5 - x) * 50) / distance;
    } else if (imgH > imgW) {
      const top0 = center - 0.5 / (imgH / (imgH - imgW));
      const distance = center - top0;
      perc = 50 - ((0.5 - y) * 50) / distance;
    }
    return perc < 0 ? (perc = 0) : perc > 100 ? (perc = 100) : perc;
  };

  const [animation,setAnimation] = useState(false)
  const saveChanges = async () => {
    setAnimation(true)
    let percentage;
    if (setEditorRef.current !== undefined) {
      percentage = calcXandY(
          setEditorRef.current?.state?.image.x,
          setEditorRef.current?.state?.image.y,
          setEditorRef.current?.state?.image.width,
          setEditorRef.current?.state?.image.height
      );
    } else {
      percentage = 0;
    }

    setState({ ...stateRef.current, uploading: true });

    try {
      const data = new FormData();
      if (file !== null && source !== "") {
        data.append("profile_image", file);
      }
      data.append("image_type", "profile");

      if (setEditorRef.current !== undefined) {
        if (
            setEditorRef.current.state.image.width >
            setEditorRef.current.state.image.height
        ) {
          data.append("position_x", `${percentage}`);
        } else if (
            setEditorRef.current.state.image.height >
            setEditorRef.current.state.image.width
        ) {
          data.append("position_y", `${percentage}`);
        }
      } else {
        data.append("position_x", `50`);
        data.append("position_y", `50`);
      }
      const res = await Axios.post(`${envData.apiUrl}/users/image`, data, {
        onUploadProgress: (p) => {
          return setState({
            ...stateRef.current,
            percent: Math.round(((p.loaded || 0) / p.total) * 100),
          });
        },
      });
      setProfileSource(
          res.data.data.profile.profileImage?.url,
          res.data.data.profile.profileImage?.position_x,
          res.data.data.profile.profileImage?.position_y
      );
      userData.updateProfileImage(res.data.data.profile.profileImage?.url, res.data.data.profile.profileImage?.position_x,res.data.data.profile.profileImage?.position_y)
      setReposition(false);
      modalData.close();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!")
    }
    setState({ ...stateRef.current, uploading: false, percent: 0 });
    setAnimation(false)
  };

  const setEditorRef = useRef<any>();

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">Select profile photo</div>
        </div>
        <div className="fl-modal-description">
          <div className={`fl-user-profile-modal-img-holder ${source ? "" : "fl-user-profile-click"}`} onClick={() => source ? "" : fileRef?.current?.click()}>
            {reposition ? (
                <AvatarEditor
                    ref={setEditorRef}
                    image={source}
                    width={200}
                    height={200}
                    border={50}
                    borderRadius={250}
                    color={[255, 255, 255, 0.6]}
                    rotate={0}
                />
            ) : (
                <div className="fl-user-profile-modal-img-display">
                  { source ? <ProfileHolder style={{objectPosition: `${x ? x : changes ? 50 : 0}% ${y ? y : changes ? 50 : 0}%`}} src={source} noUrl /> :
                      <img src={profileHolderImg} alt={"profile"} /> }
                </div>
            )}
          </div>
          <div className="fl-user-profile-modal-img-actions">
            <label className="fl-user-profile-modal-img-actions-single">
              <FontAwesomeIcon
                  className="fl-user-profile-modal-img-actions-single-icon"
                  icon={faUpload}
              />
              UPLOAD
              <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/jpeg, image/png"
                  ref={fileRef}
                  onChange={updateFile}
              />
            </label>
            <div
                className={`fl-user-profile-modal-img-actions-single  ${source ? "" : "fl-button-disabled"}`}
                onClick={() => {
                  setReposition(!reposition);
                  setChanges(true);
                }}
            >
              <FontAwesomeIcon
                  className="fl-user-profile-modal-img-actions-single-icon"
                  icon={faArrowsAlt}
              />
              REPOSITION
            </div>
          </div>
          <div className="fl-user-profile-modal-recommended">
            Recommended resolution is 300x300 pixels. <br />
            *Profile photo must not contain nudity.
          </div>
          {state.uploading  ? (
              <div className="fl-user-profile-modal-img-loader-main">
                <div className="fl-user-profile-modal-img-loader-holder">
                  <div
                      className="fl-user-profile-modal-img-loader-progress"
                      style={{ transform: `scaleX(${state.percent}%)` }}
                  ></div>
                  <span className="fl-user-profile-modal-img-loader-percent">
                {state.percent}%
              </span>
                </div>
              </div>
          ) : (
              ""
          )}
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" onClick={saveChanges} disabled={!changes || animation}>
            Save
            {animation && <ReactLoading  className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>}
          </Button>
        </div>
      </React.Fragment>
  );
};

const BackgroundModal = ({ data, setBackgroundSource,x,y }: any) => {
  const envData = useEnvContext();
  const modalData = useModalContext();

  const [source, setSource] = useState<string>(data);
  const [file, setFile] = useState<any>(null);
  const [state, setState] = useState<{ uploading: boolean; percent: number }>({
    uploading: false,
    percent: 0,
  });

  const [changes, setChanges] = useState(false);

  const [reposition, setReposition] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<any>(null);

  const updateFile = (e: React.FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length) {
      setFile(files[0]);
      var reader = new FileReader();
      reader.onload = (e: any) => setSource(e.target.result);
      reader.readAsDataURL(files[0]);
      setChanges(true);
    }
    if(fileRef.current) {
      fileRef.current.value = ""
    }
  };

  const calcXandY = (x: number, y: number, imgW: number, imgH: number) => {
    const center = 0.5;
    let perc: number = 50;
    if (imgH > imgW) {
      const top0 = center - 0.5 / (imgH / (imgH - imgW));
      const distance = center - top0;
      perc = 50 - ((0.5 - y) * 50) / distance;
    } else if (imgH > 200) {
      const top0 = center - 0.5 / (imgH / (imgH - 200));
      const distance = center - top0;
      perc = 50 - ((0.5 - y) * 50) / distance;
    }
    return perc < 0 ? (perc = 0) : perc > 100 ? (perc = 100) : perc;
  };

  const [animation,setAnimation] = useState(false)
  const saveChanges = async () => {
    setAnimation(true)
    let percentage;
    if (setEditorRef.current !== undefined) {
      percentage = calcXandY(
          setEditorRef.current?.state?.image.x,
          setEditorRef.current?.state?.image.y,
          setEditorRef.current?.state?.image.width,
          setEditorRef.current?.state?.image.height
      );
    } else {
      percentage = 0;
    }

    setState({ ...stateRef.current, uploading: true });

    try {
      const data = new FormData();
      if (file !== null && source !== "") {
        data.append("profile_image", file);
      }
      data.append("image_type", "background");
      data.append("position_x", `50`);

      if (setEditorRef.current !== undefined) {
        data.append("position_y", `${percentage}`);
      } else {
        data.append("position_y", `50`);
      }

      const res = await Axios.post(`${envData.apiUrl}/users/image`, data, {
        onUploadProgress: (p) => {
          return setState({
            ...stateRef.current,
            percent: Math.round(((p.loaded || 0) / p.total) * 100),
          });
        },
      });
      setBackgroundSource(
          res.data.data.profile.backgroundImage?.url,
          res.data.data.profile.backgroundImage?.position_x,
          res.data.data.profile.backgroundImage?.position_y
      );
      setReposition(false);
      modalData.close();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
    setState({ ...stateRef.current, uploading: false, percent: 0 });
    setAnimation(false)
  };

  const setEditorRef = useRef<any>();

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">Select header photo</div>
        </div>
        <div className="fl-modal-description">
          <div className="fl-user-profile-modal-bg-img-holder">
            {reposition ? (
                <div className="fl-user-profile-modal-bg-img-big">
                  <AvatarEditor
                      ref={setEditorRef}
                      image={source}
                      width={window.innerWidth > 991 ? 496 : 310}
                      height={window.innerWidth > 991 ? 93 : 58}
                      color={[255, 255, 255, 0.6]}
                  />
                </div>
            ) : (
                <div className={`fl-user-profile-modal-bg-img-display`} onClick={() => fileRef?.current?.click()}>
                  { source ? <img style={{objectPosition: `${x ? x : 0}% ${y ? y : 0}%`}} src={source} alt={"profile bacground"} /> : <h1>Select photo</h1>}
                </div>
            )}
          </div>
          <div className="fl-user-profile-modal-img-actions">
            <label className="fl-user-profile-modal-img-actions-single">
              <FontAwesomeIcon
                  className="fl-user-profile-modal-img-actions-single-icon"
                  icon={faUpload}
              />
              UPLOAD
              <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/jpeg, image/png"
                  ref={fileRef}
                  onChange={updateFile}
              />
            </label>
            <div
                className={`fl-user-profile-modal-img-actions-single ${source ? "" : "fl-button-disabled"}`}
                onClick={() => {
                  setReposition(!reposition);
                  setChanges(true);
                }}
            >
              <FontAwesomeIcon
                  className="fl-user-profile-modal-img-actions-single-icon"
                  icon={faArrowsAlt}
              />
              REPOSITION
            </div>
          </div>
          <div className="fl-user-profile-modal-recommended">
            Recommended resolution is 763x200 pixels. <br />
            *Header photo must not contain nudity.
          </div>
          {state.percent > 0 ? (
              <div className="fl-user-profile-modal-img-loader-main">
                <div className="fl-user-profile-modal-img-loader-holder">
                  <div
                      className="fl-user-profile-modal-img-loader-progress"
                      style={{ transform: `scaleX(${state.percent}%)` }}
                  ></div>
                  <span className="fl-user-profile-modal-img-loader-percent">
                {state.percent}%
              </span>
                </div>
              </div>
          ) : (
              ""
          )}
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" onClick={saveChanges} disabled={!changes || animation}>
            Save
            {animation && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
          </Button>
        </div>
      </React.Fragment>
  );
};

export const FavoriteProfile: FC<any> = ({ data,oldState,updatePost,previousLocation }) => {
  // CONTEXT
  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();

  // STATE
  const [state, setState] = useState<{
    dropdown: boolean;
    profileSource: string | null;
    backgroundSource: string | null;
    expandBio: boolean;
  }>({
    dropdown: false,
    profileSource: null,
    backgroundSource: null,
    expandBio: false,
  });
  const [profile, setProfile] = useState<any>({
    dropdown: false,
    favorite: data.meta.is_favorited,
  });

  useEffect(() => {
    setProfile({ dropdown: false, favorite: data.meta.is_favorited });
  }, []);

  const stateRef = useRef<any>(null);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const restoreFavorite = async (handle:number) => {
    modalData.clearToasts()
    updatePost({
      ...oldState,
      posts: oldState.posts.map((x: any) =>
          x.id === data.id ? { ...x, meta: {...x.meta, is_favorited: true} } : x
      )
    });
    try {
      await Axios.post(`${envData.apiUrl}/favorites/profile/${handle}`, {
        favorite: true,
      });
      modalData.pushToast("info", <>Action undone.</>);
    } catch (e) {}
  }

  const addProfileToFavorites = async () => {
    try {
      await Axios.post(`${envData.apiUrl}/favorites/profile/${data.handle}`, {
        favorite: !data.meta.is_favorited,
      });
      if (data.meta.is_favorited) {
        userData.updateCountFavorites("remove","profiles")
      } else {
        userData.updateCountFavorites("add","profiles")
      }
      updatePost({
        ...oldState,
        posts: oldState.posts.filter((x: any) =>
            x.id !== data.id
        )
      });
      modalData.pushToast(
          "success",
          <>
            <div>
              Profile removed from favourites. {" "}
              <span className="fl-notifications__mark" onClick={() => restoreFavorite(data.handle)}>
                   Undo
              </span>
            </div>
          </>
      )
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  return (
      <SimpleCard className="fl-user-profile-main">
        <div className="fl-user-profile-images">
          <Link
              className="fl-profile-favourite"
              to={`/${data.handle}`}
          >
            <div className={`fl-user-profile-images-cover fl-user-profile-images-cover-${userData.currentTheme}`}>
              {data.backgroundImage ? (
                  <div className="smooth-image-wrapper">
                    {data.backgroundImage?.url ?
                        <img  alt={"backoground"} style={{objectPosition: `${data.backgroundImage?.position_x ? data.backgroundImage?.position_x : 0}% ${data.backgroundImage?.position_y ? data.backgroundImage?.position_y : 0}%`}} src={data.backgroundImage?.url} className="smooth-image image-visible"/> :
                        <div className="smooth-preloader">
                          <span className="loader" />
                        </div>
                    }
                  </div>
              ) : (
                  ""
              )}
            </div>
          </Link>
          <div className="fl-user-profile-images-change">
            {data.meta.is_favorited ? (
                <FontAwesomeIcon
                    className="fl-user-profile-images-change-icon fl-user-profile-images-change-icon-active"
                    icon={faStarSolid}
                    onClick={() => addProfileToFavorites()}
                />
            ) : (
                <FontAwesomeIcon
                    className="fl-user-profile-images-change-icon"
                    icon={faStar}
                    onClick={() => addProfileToFavorites()}
                />
            )}
          </div>

          <div
              className="fl-user-profile-images-main"
          >
            <div className="fl-user-profile-images-main-inner fl-profile-favourite" >
              <ProfileHolder
                  src={data.profileImage?.url}
                  style={{
                    objectPosition: `${data.profileImage?.position_x || 0}% ${
                        data.profileImage?.position_y || 0
                    }%`,
                  }}
                  userProfile={data}
              />
            </div>
          </div>
        </div>
        <div className="fl-user-profile-data">
          <div className="fl-user-profile-data-left">
          </div>
          <div className="fl-user-profile-data-right">
            <div className="fl-user-profile-data-info">
              <div className="fl-user-profile-data-name fl-user-profile-data-name-favorite">
                <Link
                    to={`/${data.handle}`}
                    className={`fl-user-profile-data-name-full fl-user-profile-data-name-full-${userData.currentTheme}`}
                >
                  {data.name}
                  {data.nationality ? (
                      <div
                          className={`fl-user-profile-data-name-full-location fl-user-profile-favourite-location flag-icon-background flag-icon-${data.nationality?.cca2?.toLowerCase()}`}
                      ></div>
                  ) : (
                      ""
                  )}
                </Link>
                <Link
                    to={`/${data.handle}`}
                    className={`fl-user-profile-data-name-username fl-user-profile-data-name-username-${userData.currentTheme}`}
                >
                  @{data.handle}
                </Link>
              </div>
              <div className="fl-user-profile-data-values fl-settings-mt-15">
                <div className="fl-user-info">
                  <div className="fl-user-profile-data-key">Posts</div>
                  <div
                      className={`fl-user-profile-data-value fl-user-profile-data-value-${userData.currentTheme}`}
                  >
                    {data.meta.posts}
                  </div>
                </div>
                <React.Fragment>
                  <div className="fl-user-info">
                    <div className="fl-user-profile-data-key">
                      Followers
                    </div>
                    <div
                        className={`fl-user-profile-data-value fl-user-profile-data-value-${userData.currentTheme}`}
                    >
                      {data.meta.followers}
                    </div>
                  </div>
                </React.Fragment>
                <div className="fl-user-info">
                  <div className="fl-user-profile-data-key">Likes</div>
                  <div
                      className={`fl-user-profile-data-value fl-user-profile-data-value-${userData.currentTheme}`}
                  >
                    {data.meta.likes}
                  </div>
                </div>
              </div>
            </div>
            <div className="fl-user-profile-data-right-bio">
              <div
                  className="fl-user-profile-data-right-bio-expand-button"
              >
                <Link className="fl-link-favourite" to={`/${data.handle}`}>View profile</Link>
              </div>
            </div>
          </div>
        </div>
      </SimpleCard>
  );
};

export const OpenUnsubscribeModal: FC<any> = ({
                                                data,
                                                setUpdateState,
                                                updateState,
                                                getUserProfileData,
                                                getProfilePosts,
                                                convertDataRemaining
                                              }) => {
  const modalData = useModalContext();
  const envData = useEnvContext();
  const userData = useUserContext();

  useEffect(() => {
    document.body.classList.add("fl-body-fixed");
    return function cleanup() {
      document.body.classList.remove("fl-body-fixed");
    };
  }, []);

  const keyboardEvent = (event: KeyboardEvent) => {
    switch (event.code) {
      case "Escape":
        modalData.close();
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyboardEvent);
    return function cleanup() {
      document.removeEventListener("keydown", keyboardEvent);
    };
  }, [keyboardEvent]);

  const [subscribeLoading, setSubscribeLoading] = useState(false);

  const billingMethod = () => {
    return data.meta.is_subscribed.billing_method;
  };

  const billingStatus = () => {
    return data.meta.is_subscribed.status;
  };

  const processUnSubscribe = async () => {
    modalData.clearToasts();
    setSubscribeLoading(true);
    try {
      const res = await Axios.post(
          `${envData.apiUrl}/profile/${data.handle}/unsubscribe`
      );

      if (billingMethod() !== "free" && billingStatus() === 'CANCELLED') {
        modalData.pushToast(
            "success",
            `You have successfully cancelled your ${data.meta.is_subscribed.price === 0 ? "free trial" : "subscription"}.`
        );
      }
      if (billingMethod() === "free") {
        setUpdateState({
          ...updateState,
          profileObject: {
            ...updateState.profileObject,
            meta: { ...updateState.profileObject.meta, is_subscribed: false },
          },
        });
      } else {
        const subscription = {
          created_at: res.data.data.created_at,
          creator_profile: res.data.data.creatorProfile,
          creator_profile_id: res.data.data.creatorProfile.id,
          ends_at: res.data.data.ends_at,
          id: res.data.data.id,
          price: convertDataRemaining(res.data.data.subscriptionPlan.expires_at) < 1 ? res.data.data.subscriptionPlan.upgrade_price : res.data.data.subscriptionPlan.price,
          starts_at: res.data.data.starts_at,
          status: "CANCELLED",
          subscription_plan: res.data.data.subscriptionPlan,
          subscription_plan_id: res.data.data.subscriptionPlan.id,
        };
        setUpdateState({
          ...updateState,
          profileObject: {
            ...updateState.profileObject,
            meta: {
              ...updateState.profileObject.meta,
              is_subscribed: subscription,
            },
          },
        });
      }
      userData.updateCount("unfollowing")
      setSubscribeLoading(false);
      modalData.pushToast(
          "success",
          `${
              billingMethod() === "free"
                  ? `You have successfully unfollowed @${data.handle}.`
                  : `You have successfully cancelled your ${res.data.data.subscriptionPlan.price === 0 ? "free trial" : "subscription"}.`
          }`
      );
      modalData.close();
      await getProfilePosts();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">
            {billingMethod() === "free" ? "Stop following" : "Cancel subscription"}
            ?
          </div>
        </div>
        <div className="fl-modal-description">
          {billingMethod() === "free" || (billingMethod() === 'trial' && billingStatus() === 'CANCELLED') ? (
              <>
                <p>You’re about to unfollow {data.handle}.</p>
                <p>
                  If you unfollow now, you'll no longer have access to {data.handle}'s profile and you won’t see their posts on your feed.
                </p>
              </>
          ) : (
              <>
                <p>
                  Your subscription will be cancelled at the end of your {data.meta.is_subscribed.price > 0 ? "billing period" : "free trial period"} on{" "}
                  {moment(data.meta.is_subscribed.ends_at).format("D MMMM YYYY")}, and you won't be {" "}
                  {data.meta.is_subscribed.type === "trial" ? "converted to a paid plan" : "charged anymore"}.
                </p>
                <p>
                  After this date, you'll no longer have access to @{data.handle}'s
                  profile.
                </p>
              </>
          )}
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>
            Keep {billingMethod() === "free" || (billingMethod() === 'trial' && billingStatus() === 'CANCELLED') ? "following" : "subscription"}
          </Button>
          <Button
              disabled={subscribeLoading}
              type="normal"
              onClick={() => processUnSubscribe()}
          >
            {subscribeLoading ? (
                <>
                  {" "}
                  {billingMethod() === "free" || (billingMethod() === 'trial' && billingStatus() === 'CANCELLED') ? "Unfollowing" : "Cancelling"}{" "}
                  <ReactLoading
                      className="fl-spinningBubbles"
                      type={"spinningBubbles"}
                      color={"#fff"}
                      height={20}
                      width={20}
                  />{" "}
                </>
            ) : (
                `${
                    billingMethod() === "free" || (billingMethod() === 'trial' && billingStatus() === 'CANCELLED') ? "Unfollow now" : "Cancel subscription"
                }`
            )}
          </Button>
        </div>
      </React.Fragment>
  );
};

export const ProfileRecording: FC<any> = ({data,setUpdateState,updateState}) => {

  const modalData = useModalContext()
  const envData = useEnvContext()

  const [state, setState] = useState<any>({
    previewVoice:{},
    record:false,
    recording:false,
    blobURL:"",
    recordedBlob:null
  });
  const [timer,setTimer] = useState<any>({
    timer:10000
  })
  const [uploadAudio,setUploadAudio] = useState<any>(null)
  const [mobile,setMobile] = useState<boolean>(false)
  const [animation,setAnimation] = useState(false)
  const stateRef = useRef<any>();
  useEffect(() => {
    stateRef.current = timer;
  }, [timer]);

  const increaseTimer = () => {
    setTimer({...stateRef.current, timer:stateRef.current.timer - 1000})
    localTimer = setTimeout(increaseTimer, 1000);
  };

  const startRecordingNew = () => {
    setState({...state,record:true})
    setTimeout(() => {
      increaseTimer()
    }, 1800);
  };

  const stopRecordingNew = () => {
    setState({...state,record:false})
  };

  const onStopNew = (recordedBlob:any) => {
    var file = new File([recordedBlob.blob], "record.ogg");
    const fakeMessagePreview = {
      audio: recordedBlob.url,
      file:file
    };
    setUploadAudio(fakeMessagePreview)
    setState({...state, blobURL: recordedBlob.blobURL})
    setTimer({...timer, timer:10000})
    if (localTimer) clearTimeout(localTimer);
  };

  const deleteRecording = () => {
    setState({
      ...state,
      blobURL:"",
    });
    setUploadAudio(null)
  };

  const submitFile = async () => {
    modalData.clearToasts()
    const formData = new FormData();
    setAnimation(true)
    if(uploadAudio) {
      formData.append("audio_presentation", uploadAudio.file);
      try {
        await Axios.post(
            `${envData.apiUrl}/creator/audio-presentation`, formData
        );
        setUpdateState({...updateState, profileObject: {...updateState.profileObject, creatorProfile: {...updateState.profileObject.creatorProfile, audio_presentation_url:state.blobURL}}})
        modalData.pushToast("success", `Your voice greeting was ${data.creatorProfile?.audio_presentation_url ? "updated" : "added"}.`);
        setAnimation(false)
      } catch (e) {
        setAnimation(false)
        modalData.pushToast("error", "Something went wrong!");
      }
      modalData.close();
    }
  };

  const modalRecordDelete = async () => {
    modalData.push(() => <DeleteRecording updateState={updateState} setUpdateState={setUpdateState} />)
  };

  const handleResize = () => {
    if(window.innerWidth < 991.98) {
      setMobile(true)
    }else {
      setMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    if(stateRef.current.timer === 0) {
      stopRecordingNew()
    }
  },[timer.timer])

  return (
      <Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">
            {data.creatorProfile?.audio_presentation_url ? "Edit" : "Add"}{" "}voice greeting
          </div>
          <div className="fl-modal-close-button">
            <FontAwesomeIcon
                icon={faTimes}
                onClick={() => modalData.close()}
            />
          </div>
        </div>
        <div className="fl-modal-description">
          <div className="fl-modal-description-row fl-text-align-center">
            Introduce yourself with a 10-second voice clip. <br/>
            Press the microphone to start recording.
          </div>
          <div className="fl-modal-description-recorder">
            <div className="fl-modal-description-recorder">
              {data.creatorProfile.audio_presentation_url && state.blobURL.length === 0 && !state.record ?
                  <div className={"fl-inbox-record"}>
                    <div className={"fl-direct-messages-box-input-form-inner"}>
                      <MessageAudio
                          deleteRecording={""}
                          src={data.creatorProfile.audio_presentation_url}
                          recording={""}
                          sendRecording={""}
                          data={""}
                          reportedStatus={""}
                      />
                    </div>
                  </div>
                  : ""
              }
              {state.blobURL ?
                  <div className={"fl-inbox-record"}>
                    <div className={"fl-direct-messages-box-input-form-inner"}>
                      <MessageAudio
                          deleteRecording={deleteRecording}
                          src={state.blobURL}
                          recording={true}
                          sendRecording={""}
                          data={""}
                          reportedStatus={""}
                          removeDeleteIcon={true}
                      />
                    </div>
                  </div>
                  : "" }
              <div className={`fl-inbox-record fl-inbox-record-${state.record ? "start" : "none"}`}>
                <div className="fl-inbox-audio-recording">
                  <div className={"fl-direct-messages-record-wrapper-inner"}>
                    <div
                        className={`fl-direct-messages-box-input-action-single-voice-stop`}
                        onClick={stopRecordingNew}
                    >
                      <FontAwesomeIcon icon={faStopCircle} data-tip data-for="stop-recording"/>
                      {mobile ? "" :  <ReactTooltip id="stop-recording">Stop recording</ReactTooltip> }
                    </div>
                    <div
                        className="fl-direct-messages-record-audio-chunks"
                    >
                      <ReactMic
                          record={state.record}
                          className="sound-wave"
                          onStop={onStopNew}
                          // onData={onData}
                          strokeColor="#FFFFFF"
                          backgroundColor="#007eff"
                      />
                    </div>
                    <div className="fl-direct-messages-record-timer">
                      {timer.timer === 10000 ? "00:10" : moment(timer.timer, "x").format("mm:ss")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fl-modal-description-recorder-actions">
              <Button
                  type="normal"
                  className={`fl-modal-description-recorder-button ${
                      state.record
                          ? "fl-modal-description-recorder-button-recording"
                          : ""
                  }`}
                  onClick={() => state.blobURL ? deleteRecording() : state.record  ? stopRecordingNew() : startRecordingNew()}
              >
                <FontAwesomeIcon
                    icon={state.blobURL ? faTimes : state.record ? faStop : faMicrophoneAlt}
                />
              </Button>
              {data.creatorProfile?.audio_presentation_url && state.blobURL.length === 0 && !state.record ? (
                  <Button
                      type="normal"
                      className={`fl-modal-description-recorder-button`}
                      style={{ marginLeft: 10 }}
                      onClick={modalRecordDelete}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
              ) : (
                  ""
              )}
            </div>
          </div>
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" onClick={() => submitFile()} disabled={state.blobURL && !animation ? false : true}>
            Submit
            {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </Fragment>
  );
};

export const DeleteRecording: FC<any> = ({setUpdateState,updateState}) => {

  const modalData = useModalContext()
  const envData = useEnvContext()

  const [animation,setAnimation] = useState(false)
  const deleteRecord = async () => {
    modalData.clearToasts()
    setAnimation(true)
    try {
      await Axios.delete(`${envData.apiUrl}/creator/audio-presentation`);
      modalData.pushToast("success", "Your voice greeting was deleted.");
      setUpdateState({...updateState, profileObject: {...updateState.profileObject, creatorProfile: {...updateState.profileObject.creatorProfile, audio_presentation_url:null}}})
      modalData.closeAll();
      setAnimation(false)
    } catch (e) {
      setAnimation(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  return (
      <Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">
            Delete voice greeting
          </div>
          <div className="fl-modal-close-button">
            <FontAwesomeIcon
                icon={faTimes}
                onClick={() => modalData.close()}
            />
          </div>
        </div>
        <div className="fl-modal-description">
          <div className="fl-modal-description-row">
            Are you sure you want to delete your voice greeting?
          </div>
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" onClick={() => deleteRecord()} disabled={animation}>
            Delete
            {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </Fragment>
  );
};

export const DeleteProfilePhoto: FC<any> = ({getProfileImages,setState,state,type}) => {

  const modalData = useModalContext()
  const envData = useEnvContext()
  const userData = useUserContext()

  const [animation,setAnimation] = useState(false)
  const deleteProfile = async () => {
    setAnimation(true)
    try {
      await Axios.delete(`${envData.apiUrl}/users/image`, {
        data: { image_type:  type === "profile" ? "profile" : "background" },
      });
      getProfileImages(window.location.pathname.split("/").pop()!);
      setState({
        ...state,
        profileDropdown: false,
        statusDropdown: false,
        headerDropdown:false,
        imgRemoved: type === "profile" ? true : false,
        bgRemoved: type === "header" ? true : false,
      });
      userData.updateProfileImage("", 0,0)
      modalData.close();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
    setAnimation(false)
  };
  //
  // const removeHeaderImage = async () => {
  //   try {
  //     await Axios.delete(`${envData.apiUrl}/users/image`, {
  //       data: { image_type: "background" },
  //     });
  //     modalData.close();
  //     getProfileImages(window.location.pathname.split("/").pop()!);
  //     setState({
  //       ...state,
  //       headerDropdown: false,
  //       bgRemoved: true,
  //     });
  //     // history.go(0);
  //   } catch (e) {
  //     modalData.pushToast("error", "Something went wrong!");
  //   }
  // };
  return (
      <Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">
            Remove {type} photo
          </div>
          <div className="fl-modal-close-button">
            <FontAwesomeIcon
                icon={faTimes}
                onClick={() => modalData.close()}
            />
          </div>
        </div>
        <div className="fl-modal-description">
          <div className="fl-modal-description-row">
            Are you sure you want to remove your {type} photo?
          </div>
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}>Cancel</Button>
          <Button type="normal" onClick={() => deleteProfile()} disabled={animation}>
            Confirm
            {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </Fragment>
  );
};
