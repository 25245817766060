import React, { FC, useState } from "react";
import "./ProfileHolder.css";
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import { Link } from "react-router-dom";
import profileHolderImg from "../assets/images/profile.png";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { SimpleCard } from "./SimpleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";

export const ProfileHolder: FC<any> = (props) => {
  const modalData = useModalContext();
  const userData = useUserContext();
  const [state, setState] = useState<any>({
    statusDropdown: false,
  });

  const changeUserStatus = async (x: any) => {
    try {
      userData.changeStatus(x);
      setState({
        ...state,
        statusDropdown: false,
      });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };
  const [loaded,setLoaded] = useState(false)

  const profileX = () => {
    return props?.userProfile?.profileImage?.position_x
  }

  const profileY = () => {
    return props?.userProfile?.profileImage?.position_y
  }

  return (
    <div className="fl-profile-holder">
      {props.noUrl ? (
            <div className="smooth-image-wrapper">
              <img
                  style={props.style ? props.style : {objectPosition: `${profileX() ? profileX() : 0}% ${profileY() ? profileY() : 0}%`}}
                  src={props.src ? props.src : profileHolderImg} className="smooth-image image-visible" onLoad={() => setLoaded(true)}
                  alt={"profile"}
              />
              {loaded ? "" :
                  <div className="smooth-preloader">
                    <span className="loader" />
                  </div>
              }
            </div>
      ) : (
        <Link
          to={`/${
            props?.userProfile?.handle === userData.user.handle
              ? `${userData.user.handle}`
              : `${props?.userProfile?.handle}`
          }`}
        >
          <div className="smooth-image-wrapper">
            <img
                style={props.style ? props.style : {objectPosition: `${profileX() !== null ? profileX() : 0}% ${profileY() !== null ? profileY() : 0}%`}}
                src={props.src ? props.src : profileHolderImg}  onLoad={() => setLoaded(true)} className="smooth-image image-visible"
                alt={"profile"}
            />
            {loaded ?
               "" :
                <div className="smooth-preloader">
                  <span className="loader" />
                </div>
            }
          </div>
          {/*<RenderSmoothImage {...props} src={props.src || profileHolderImg} />*/}
        </Link>
      )}
      {props?.userProfile !== undefined ? (
        <>
          {(props?.userProfile?.handle === userData.user.handle &&
            userData.user.settings.activity_status) ||
          (props?.userProfile?.handle !== userData.user.handle &&
            props?.userProfile?.meta?.activity_status) ? (
            <>
              <div
                className={`fl-profile-holder-status ${
                  props?.userProfile?.handle === userData.user.handle &&
                  props.changeStatus &&
                  "fl-profile-holder-status-user"
                }`}
                tabIndex={1}
                onBlur={() => setState({ ...state, statusDropdown: false })}
              >
                <div
                  className={`fl-profile-holder-status-icon 
                  fl-profile-holder-status-icon-${
                    props?.userProfile?.handle === userData.user.handle
                      ? userData.user.profile.online_status
                      : userData?.signal?.type ===
                          "App\\Notifications\\Chat\\UserConnected" &&
                        userData?.signal?.data?.handle ===
                          props?.userProfile?.handle
                      ? "true"
                      : userData?.signal?.type ===
                          "App\\Notifications\\Chat\\UserDisconnected" &&
                        userData?.signal?.data?.handle ===
                          props?.userProfile?.handle
                      ? "false"
                      : props?.userProfile?.online_status
                  }
                  fl-profile-holder-status-icon-cursor-${
                    props?.userProfile?.handle === userData.user.handle &&
                    props.changeStatus &&
                    true
                  }
                  `}
                  onClick={() => {
                    return props?.userProfile?.handle ===
                      userData.user.handle && props.changeStatus
                      ? setState({
                          ...state,
                          statusDropdown: !state.statusDropdown,
                        })
                      : "";
                  }}
                  data-tip
                  data-for={`online${
                    props.array
                      ? props?.userProfile?.handle === userData.user.handle
                        ? `-${userData.user.handle}`
                        : `-${props?.userProfile?.handle}`
                      : ""
                  }`}
                >
                  {props?.userProfile?.handle === userData.user.handle &&
                    props.changeStatus && (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        onClick={(e) =>
                          props?.userProfile?.handle === userData.user.handle
                            ? setState({
                                ...state,
                                statusDropdown: !state.statusDropdown,
                              })
                            : ""
                        }
                      />
                    )}
                  {!props.tooltip && (
                    <ReactTooltip
                      id={`online${
                        props.array
                          ? props?.userProfile?.handle === userData.user.handle
                            ? `-${userData.user.handle}`
                            : `-${props?.userProfile?.handle}`
                          : ""
                      }`}
                    >
                      {props?.userProfile?.handle === userData.user.handle && (
                        <>
                          {userData.user.profile.online_status
                            ? "Active now"
                            : `Last active:  ${
                                moment
                                  .utc(userData.user.profile.last_active)
                                  .fromNow() === "in a few seconds"
                                  ? "a few seconds ago"
                                  : moment
                                      .utc(userData.user.profile.last_active)
                                      .fromNow()
                              }`}
                        </>
                      )}
                      {props?.userProfile?.handle !== userData.user.handle && (
                        <>
                          {userData?.signal?.type ===
                            "App\\Notifications\\Chat\\UserConnected" &&
                          userData?.signal?.data?.id ===
                            props?.data?.userProfile?.id ? (
                            "Active now"
                          ) : userData?.signal?.type ===
                              "App\\Notifications\\Chat\\UserDisconnected" &&
                            userData?.signal?.data?.id ===
                              props?.data?.userProfile?.id ? (
                            <>
                              {moment
                                .utc(userData.user.profile.last_active)
                                .fromNow()}
                            </>
                          ) : props?.userProfile?.online_status ? (
                            "Active now"
                          ) : (
                            <>
                              Last active:{" "}
                              {moment
                                .utc(props?.userProfile?.last_active)
                                .fromNow()}
                            </>
                          )}
                        </>
                      )}
                    </ReactTooltip>
                  )}
                </div>
                {state.statusDropdown &&
                props?.userProfile?.id === userData.user.id &&
                props.changeStatus ? (
                  <div
                    className={`fl-profile-holder-dropdown-status fl-post-head-actions-dropdown--profile fl-post-head-actions-dropdown-profile-status fl-post-head-actions-dropdown-${userData.currentTheme}`}
                  >
                    <SimpleCard className="fl-post-head-actions-dropdown-inner">
                      <div
                        className="fl-post-head-actions-dropdown-option"
                        onClick={() => changeUserStatus(1)}
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon">
                          {userData.user.profile.online_status ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : (
                            ""
                          )}
                        </div>
                        Active
                      </div>
                      <div
                        className="fl-post-head-actions-dropdown-option"
                        onClick={() => changeUserStatus(0)}
                      >
                        <div className="fl-post-head-actions-dropdown-option-icon">
                          {userData.user.profile.online_status ? (
                            ""
                          ) : (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                        </div>
                        Invisible
                      </div>
                    </SimpleCard>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
