import React, {FC} from 'react';
//@ts-ignore
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import hideImage from "../assets/images/hideimage.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import PreviewVideoMessage from "../routes/Messages/PreviewVideoMessage";

export const Drag:FC<{setDisableBlur:any,uploadState:any,setUploadState:any,state:any,disableBlur:any,blurPreview:any,removeFile:any,showBlur:any,hideBlur:any,rightClickBlur:any,}> = ({setDisableBlur,uploadState,setUploadState,state,disableBlur,blurPreview,removeFile,showBlur,hideBlur,rightClickBlur}) => {

    const reorder = (list:any, startIndex:any, endIndex:any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result:any) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            uploadState.files,
            result.source.index,
            result.destination.index
        );
        setUploadState({...uploadState, files: items})
        hideBlur()
    }

    const checkBlur = () => {
        if(state.blurred === "blurred" || state.price === 0 || disableBlur) {
            return true
        }
    }

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided:any) => (
                        <div
                            className="fl-direct-messages-box-files-inner"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {uploadState.files.map((x:any, index:number) => (
                                <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                                    {(provided:any, snapshot:any) => (
                                        <div
                                            className="fl-direct-messages-box-files-single"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onMouseUp={(e) => hideBlur()}
                                            onMouseDown={(e) => showBlur(e)}
                                            onContextMenu={(e) => rightClickBlur(e)}
                                        >
                                            <div>
                                                {x.type === "image" ? <img alt={`fluffa`} style={{ filter: `blur(${state.blurred === "blurred" && state.price > 0 && !disableBlur ? blurPreview() : "0"}px)` }} src={checkBlur() && x.src ? x.src : checkBlur() && x.url ? x.url : hideImage} /> : ""}
                                                {(x.type === "video" && state.blurred === "blurred") || (x.type === "video" && state.price === 0) || disableBlur  ? (
                                                    <PreviewVideoMessage state={state} disableBlur={disableBlur} blurPreview={blurPreview} src={x.src}/>
                                                ) : x.type === "video" && state.blurred === "black" ? (
                                                    <img src={hideImage} alt={`fluffa`} />
                                                ) : ""}
                                            </div>
                                            <div
                                                className="fl-direct-messages-box-files-single-remove"
                                                onClick={() => removeFile(index)}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
    )
}
