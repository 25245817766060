import React, {FC, useEffect, useState} from 'react'
import {useUserContext} from "../../context/UserContext";
import {ProfileHolder} from "../../components/ProfileHolder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faImage,
    faVideo,
    faPhotoVideo,
    faMicrophoneAlt,
    faCheck,
    faBan, faBellSlash
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {useHistory} from "react-router-dom";
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {useModalContext} from "../../context/ModalContext";
import DollarBagWhite from "../../assets/images/icons/dollarbagwhite.svg";
import DollarBag from "../../assets/images/icons/dollarbag.svg";
export const ListMessages:FC<any> = (props) => {

    const userData = useUserContext()
    const history = useHistory()
    const envData = useEnvContext()
    const modalData = useModalContext()
    const [notification, SetNotification] = useState({
        typing: false,
        recording: false
    })
    const usersOfMessage = () => {
        if(userData.user.handle === props.data.userProfile.handle) {
            return props.data.creatorProfile
        } else {
            return props.data.userProfile
        }
    }

    useEffect(() => {
        if (userData.signal !== null) {
            const signal = userData?.signal
            const type  = signal?.type === "App\\Notifications\\Chat\\ChatMessageTyping" ? "typing" : signal?.type === "App\\Notifications\\Chat\\ChatMessageRecording" ? "recording" : ""
            if (type !== "" && signal?.user.id === usersOfMessage().id ) {
                SetNotification ({...notification, typing: type === "typing" ? true : false,recording: type === "recording" ? true : false })
            } else if(userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageCreated" && signal?.data.sender.id === usersOfMessage().id) {
                SetNotification ({...notification, typing: false,recording: false })
            }
            userData.clearSignal();
        }
    }, [userData.signal]);

    const lastMessage = () => {
        return props?.data.meta?.last_message
    }

    const checkWhoSend = () => {
        if(userData.user.id === lastMessage().sender_id) {
            return true
        } else {
            return false
        }
    }

    const messageTime = () => {
        if(moment(lastMessage()?.created_at).format("YYYY") === moment(new Date()).subtract(1, 'years').format("YYYY")) {
            return moment(lastMessage()?.created_at).format("D MMM, YYYY")
        } else if(moment(new Date()).format("DD MMM") === moment(lastMessage()?.created_at).format("DD MMM")) {
            return moment(lastMessage()?.created_at).format("h:mm a")
        } else {
            return moment(lastMessage()?.created_at).format("D MMM")
        }
    }

    const attachmentsType = () => {
        const image = lastMessage()?.attachments?.filter((x: any) => x.type === "image").length
        const video = lastMessage()?.attachments?.filter((x: any) => x.type === "video").length
        const audio = lastMessage()?.attachments?.filter((x: any) => x.type === "audio").length
        if(image > 0 && video === 0) {
            return 1
        } else if(image === 0 && video > 0) {
            return 2
        } else if (image > 0 && video > 0) {
            return 3
        } else if (audio > 0) {
            return 4
        } else {
            return false
        }
    }

    const readMessages = async (id:any) => {
        try {
            await Axios.put(`${envData.apiUrl}/conversations/${id}/messages/seen`, {
                seen:true
            });
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const openMessage = async (e:any) => {
        e.stopPropagation()
        if(props.enableDelete && !props.added) {
            return props.addSelected()
        } else if (props.enableDelete && props.added) {
            return  props.removeSelected()
        } else {
            userData.messagesFunction(usersOfMessage().name,usersOfMessage().handle,usersOfMessage().id)
            if(lastMessage()?.sender_id !== userData.user.id && !lastMessage()?.seen) {
                userData.readMessages()
            }
            history.push(`/inbox/${props.data.id}`)
            props.setState({...props.state, messages: props.state.messages.map((x:any) => x.id === props.data.id ? {...x, meta: {...x.meta, last_message: {...x.meta.last_message, seen:true}}} : x)})
          /*  await readMessages(props.data.id)*/
            if(props.gallery) {
                props.setGallery(false)
            }
        }
    }

    const checkSendTips = () => {
        const regex = /@\[(\S+)\]/g
        const tips = props?.data?.meta?.last_message?.body?.match(regex)
        if(tips) {
            return true
        } else {
            return false
        }
    }

    const checkBlocking = () => {
        const userId = userData.user.id
        if(userId === props.data.userProfile.id && props.data.meta.restricted_by_user || userId === props.data.creatorProfile.id && props.data.meta.restricted_by_creator) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className={`fl-single-message ${props.added && "fl-single-message-added"} ${props.data.id === Number(props.messageId) && "fl-messages-single-active"} ${lastMessage()?.sender_id !== userData.user.id && lastMessage()?.seen === false && 'fl-messages-single-seen-false'}`}>
            <div
                onClick={(e) => openMessage(e)}
                className={`fl-messages-one-message fl-messages-single fl-messages-single-${userData.currentTheme}`}>
                <div className="fl-messages-single-photo">
                    <ProfileHolder
                        src={usersOfMessage().profileImage?.url}
                        userProfile={usersOfMessage()}
                        array
                    />
                </div>
                <div className="fl-messages-single-text">
                    <div className={`fl-messages-single-text-info`}>
                       <span  className={`fl-messages-single-text-username fl-messages-single-text-username-${userData.currentTheme}`}>
                           {usersOfMessage().name}
                       </span>
                        <span  className={`fl-messages-single-text-handle fl-messages-single-text-handle-${userData.currentTheme}`}>
                           @{usersOfMessage().handle}
                       </span>
                    </div>
                    <div className="fl-messages-single-info-msg">
                        <span className="fl-messages-single-text-msg">
                            {notification.typing ? "typing..." : notification.recording ? "recording..." :
                                <>
                                    {props?.data?.meta?.last_message?.body ? "" : "No message"}
                                    {!attachmentsType() ?
                                        <>
                                            {!checkSendTips() ?
                                                <>
                                                    {props?.data?.meta?.last_message?.body}
                                                </> :
                                                <span className={"fl-d-flex"}>
                                      Tip {checkWhoSend() ? "sent" : "received"} <img alt={`send img`} src={props.data.id === Number(props.messageId) ? DollarBagWhite : DollarBag } />
                                    </span>
                                            }
                                        </> : ""
                                    }
                                    {attachmentsType() &&
                                        <>
                                            {attachmentsType() === 4 ? "Audio" : "Media"}
                                            <FontAwesomeIcon icon={attachmentsType() === 1 ? faImage : attachmentsType() === 2 ? faVideo : attachmentsType() === 3 ? faPhotoVideo : attachmentsType() === 4 ? faMicrophoneAlt : faImage} />
                                        </>
                                    }
                                </>
                            }
                        </span>
                    </div>
                </div>
            </div>
            {props.enableDelete ?
                <div className={`fl-messages-list-check ${props.added && "fl-messages-list-checked"}`} onClick={(e) => openMessage(e)}>
                    {props.added &&
                    <FontAwesomeIcon
                        className="fl-bulk__user__list--user--check--added--icon"
                        icon={faCheck}
                    />
                    }
                </div> :
                <span className="fl-messages-single-date-msg">
                    <span
                        className="fl-message-list-delete"
                        onClick={() => props.deleteChat(props.data.id)}
                    >
                        <FontAwesomeIcon icon={faTimes} data-tip data-for="delete-description"/>
                        <ReactTooltip id="delete-description">Delete chat</ReactTooltip>
                    </span>
                    <span>
                        <span className={`fl-messages-single-date-msg-date fl-msg-date-${props.data?.mutedConversation ? "muted" : "default"}`}>
                           {props?.data?.meta?.last_message?.body ? messageTime() : "" }
                        </span>
                        {!lastMessage()?.seen ? <span className={`fl-messages-single-date-msg-dot fl-msg-dot-${props.data?.mutedConversation ? "muted" : "default"}`}></span> : ""}
                        {checkBlocking() || props.data.mutedConversation ?
                            <span className="fl-message-list-ban-bell">
                            {" "}
                                <FontAwesomeIcon
                                    icon={checkBlocking() ? faBan : faBellSlash}
                                    data-tip
                                    data-for={`${checkBlocking() ? "ban" : "mute"}-description`}
                                />
                              <ReactTooltip id={`${checkBlocking() ? "ban" : "mute"}-description`}>
                                 {`${checkBlocking() ? "User is restricted" : "Notifications are muted"}`}
                              </ReactTooltip>
                        </span> : ""}
                    </span>
                </span>
            }
        </div>
    )
}