import {
  faBorderAll,
  faCheck,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import ScheduleIconActive from "../assets/images/scheduled-active.svg";
import ScheduleIcon from "../assets/images/scheduled.svg";
import VideoIcon from "../assets/images/video.svg";
import VideoActiveIcon from "../assets/images/video-active.svg";
import photo from "../assets/images/photo.svg";
import photoActive from "../assets/images/photo-active.svg";
import allPostsActive from "../assets/images/all-posts-active.svg";
import allPosts from "../assets/images/all-posts.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Post } from "../components/Post";
import { ProfileActions } from "../components/ProfileActions";
import { SimpleCard } from "../components/SimpleCard";
import { SupportBox } from "../components/SupportBox";
import { UserProfile } from "../components/UserProfile";
import { Referral } from "../components/Referral";
import { WritePost } from "../components/WritePost";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import postInterface from "../interfaces/PostInterface";
import userInterface from "../interfaces/UserInterface";
import { UnapprovedBox } from "../components/UnapprovedBox";
import "./Profile.css";
import { VerificationBox } from "../components/VerificationBox";
import { PostOptions } from "../components/PostOptions";
import greyLogo from "../assets/images/grey_logo.png";
import qs from "query-string";
import ReactLoading from "react-loading";
import messagesIcon from "../assets/images/icons/19.svg";
import { Button } from "../components/Button";
import messagesIconUnlock from "../assets/images/icons/23.svg";
import { SubscriptionModal } from "../components/SubscriptionModal";
import { PlanTrialBox } from "../components/PlanTrialBox";
import moment from "moment";
import {NotFoundUser} from "./NotFoundUser";
import {DeactivateUser} from "./DeactivateUser";
import {NotFound} from "./NotFound";

export const Profile: FC<any> = (props) => {
  const [state, setState] = useState<{
    posts: Array<postInterface>;
    currentPage: number;
    loadingState: boolean;
    lastPage: number;
    profileObject: userInterface | null;
  }>({
    posts: [],
    profileObject: null,
    currentPage: 1,
    loadingState: true,
    lastPage: 0,
  });

  // PARAMS
  const { handle } = useParams<{ handle: string; }>();

  // HISTORY
  const history = useHistory();

  // CONTEXT
  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const stateRef = useRef<any>();
  const location = useLocation<any>();
  const [postType, setPostType] = useState<string>("");

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let queryParams = qs.parse(location.search);
    if (
        queryParams?.posts === "scheduled" ||
        queryParams?.posts === "SCHEDULED"
    ) {
      setPostType("scheduled");
    } else setPostType("");
  }, [location]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const [notFound,setNotFound] = useState(false)
  const [deactivatedUser,setDeactivatedUser] = useState(false)

  const getUserProfileData = async () => {
    if(handle.length > 20 || handle === "404" ) return;
    try {
      const profileRes = await Axios.get(`${envData.apiUrl}/profile/${handle}`);
      setState({ ...stateRef.current, profileObject: profileRes.data.data });
    } catch (e) {
      if (e?.response?.data?.errors?.user?.includes("User is deactivated!")) {
        setDeactivatedUser(true)
      }
    }
  };

  const [typePost, setTypePost] = useState<any>({
    animation: false,
    type: localStorage.getItem("scheduled") ? "scheduled" : "posts",
  });
  const typeRef = useRef<any>();
  useEffect(() => {
    typeRef.current = typePost;
  }, [typePost]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const checkSubscribe = () => {
    if(!state?.profileObject?.meta?.is_subscribed && handle !== userData.user.handle) {
      return false
    } else {
      return true
    }
  }

  const typeOfPosts = () => {
   return  new URLSearchParams(window.location.search).get("posts") || "posts"
  }

  const getProfilePosts = async (page = 1, type = typeOfPosts() === "all" ? "posts" : typeOfPosts()) => {
    if(handle.length > 20 || handle === "404" ) return;
    setState({ ...stateRef.current, loadingState: true });
    setTypePost({ ...typePost.current, type: type })
    if (
        (type === "scheduled" && page === 1) ||
        (type === "posts" && page === 1) ||
        (type === "images" && page === 1) ||
        (type === "videos" && page === 1)
    ) {
      window.addEventListener("scroll", checkForNewFeeds);
      setTypePost({ ...typeRef.current, animation: true, type: type });
    }
    await Axios.get(
        //@ts-ignore
        `${envData.apiUrl}/profile/${handle}/${type}${
            page ? `?page=${page}` : ""
        }`
    )
        .then((res) => {
          var { data, meta } = res.data;
          data = data.map((x: any) => ({ postVideos: [], postImages: [], ...x }));
          setState({
            ...stateRef.current,
            posts: page === 1 ? data : [...stateRef.current.posts, ...data],
            lastPage: meta.last_page,
            loadingState: false,
            currentPage: page,
          });
          if (
              (type === "scheduled" && page === 1) ||
              (type === "posts" && page === 1) ||
              (type === "images" && page === 1) ||
              (type === "videos" && page === 1)
          ) {
            window.addEventListener("scroll", checkForNewFeeds);
            setTypePost({ ...typeRef.current, animation: false });
          }
        })
        .catch((e) => {
          if (e?.response?.data?.errors?.user?.includes("User is deactivated!")) {
            setDeactivatedUser(true)
          } else {
            setNotFound(true)
          }
        });
  };

  useEffect(() => {
    (async () => {
      await getProfilePosts();
    })();
  }, [typeOfPosts()]);

  const checkForNewFeeds = async () => {
    if (stateRef.current.currentPage >= stateRef.current.lastPage) {
      window.removeEventListener("scroll", checkForNewFeeds);
      return;
    }

    var maxScroll =
        Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        ) - window.innerHeight;
    if (
        window.scrollY > maxScroll - window.innerHeight / 2 &&
        stateRef.current.loadingState === false
    ) {
      getProfilePosts(stateRef.current.currentPage + 1, typeOfPosts() === "all" ? "posts" : typeOfPosts());
    }
  };

  if (userData.scrollPost === true) {
    setTimeout(() => {
      const preRegDOM = document.querySelector(".fl-support-text");
      const getOffsetTop = (element: any) => {
        let offsetTop = 0;
        while (element) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
        return offsetTop;
      };
      window.scrollTo({
        top: getOffsetTop(preRegDOM),
        left: 0,
        behavior: "smooth",
      });
    }, 500);
  }

  useEffect(() => {
    (async () => {
      await getProfilePosts();
      await getUserProfileData();
    })();
    window.addEventListener("scroll", checkForNewFeeds);
    return () => {
      window.removeEventListener("scroll", checkForNewFeeds);
    };
  }, []);

  const goToPost = (id: any) => {
    history.push(`/post/${id}`);
  };

  const redirectToLogin = () => {
    localStorage.setItem("redirectAfterLogin", `${state?.profileObject?.handle}`)
    history.push('/login')
  }

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [userData.signal]);

  const isSubbed = state.profileObject?.meta?.is_subscribed || false;
  const allPlans = state.profileObject?.subscriptionPlans || [];
  const isCreator = state.profileObject?.creatorProfile ? true : false;

/*
  const scrollToTop = () => {
    const preRegDOM = document.querySelector(".fl-post-head-info-pin");
    if (preRegDOM) {
      const getOffsetTop = (element: any) => {
        let offsetTop = 0;
        while (element) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
        return offsetTop;
      };
      window.scrollTo({
        top: getOffsetTop(preRegDOM) - 200,
        left: 0,
        behavior: "smooth",
      });
    } else {
    }
  };
*/

  useEffect(() => {
    if (
        userData?.signal?.type === "App\\Notifications\\PostFinishedEncoding" &&
        handle === userData.user.handle && userData?.signal?.post?.postAttachments?.length > 0
    ) {
      modalData.clearToasts();
      const publishDate = userData?.signal?.post?.publish_date;
      const postId = userData.signal.post.id;
      if (!publishDate) {
        setState({
          ...stateRef.current,
          posts: [userData.signal.post, ...stateRef.current.posts],
          // posts: stateRef.current.posts.map((x:any) => x.id === postId ? userData.signal.post : x )
        });
      }
      if (publishDate) {
        setTimeout(() => {
          modalData.pushToast(
              "success",
              <>
                Post scheduled for{" "}
                {moment(publishDate).format("YYYY") ===
                moment(new Date()).format("YYYY")
                    ? moment(publishDate).format("ddd, D MMM, HH:mm")
                    : moment(publishDate).format("ddd, D MMM YYYY, HH:mm")}{" "}
                <span className={"toast-link"} onClick={() => goToPost(postId)}>
              View post
            </span>
              </>
          );
        },100)
      } else {
        setTimeout(() => {
          modalData.pushToast(
              "success",
              <>
                Your Post was uploaded.{" "}
                <span
                    className={"toast-link"}
                    onClick={() => goToPost(userData.signal.post.id)}
                >
              View
            </span>
              </>
          );
        },100)
      }
    }
    if (userData?.signal?.type === "App\\Notifications\\PostPublished")
    {
      modalData.clearToasts();
      if(typeOfPosts() === "scheduled") {
        setState({
          ...stateRef.current,
          posts: stateRef.current.posts.filter((x:any) => x.id !== userData.signal.post.id),
        });
      } else {
        setState({
          ...stateRef.current,
          posts: [userData.signal.post, ...stateRef.current.posts],
        });
      }
      modalData.pushToast(
          "success",
          <>
            Your Post was sent.{" "}
            <span
                className={"toast-link"}
                onClick={() => goToPost(userData.signal.post.id)}
            >
              View
            </span>
          </>
      );
    }
    userData.clearSignal();
  }, [userData.signal]);

  useEffect(() => {
    if (
        userData.newPost.length !== 0 &&
        handle === userData.user.handle &&
        window.location.pathname === userData.quickPostLocation
    ) {
      setState({
        ...stateRef.current,
        posts: [userData.newPost, ...stateRef.current.posts],
      });
      userData.newPostFunction([], "");
    }
  }, [userData.newPost]);

  const [freeAnimation, setFreeAnimation] = useState(false);

  const followFoFree = async () => {
    modalData.clearToasts();
    setFreeAnimation(true);
    try {
      const res = await Axios.post(
          `${envData.apiUrl}/profile/${state?.profileObject?.handle}/subscribe`
      );
      const subscription = {
        billing_method: "free",
        created_at: res.data.data.created_at,
        creator_profile: res.data.data.creatorProfile,
        creator_profile_id: res.data.data.creatorProfile.id,
        ends_at: res.data.data.ends_at,
        id: res.data.data.id,
        price: 0,
        starts_at: res.data.data.starts_at,
        status: "ACTIVE",
        subscription_plan: null,
        subscription_plan_id: null,
      };
      setState({
        ...stateRef.current,
        profileObject: {
          ...state.profileObject,
          meta: {
            ...stateRef.current.profileObject.meta,
            is_subscribed: subscription,
          },
        },
      });
      userData.updateCount("following")
      setFreeAnimation(false);
      getProfilePosts();
    } catch (e) {
      setFreeAnimation(false);
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const subscribe = async (planSelect: any, plans: any) => {
    await modalData.push(() => (
        <SubscriptionModal
            profile={state?.profileObject}
            setState={setState}
            state={state}
            plans={plans}
            plan={planSelect}
            getProfilePosts={getProfilePosts}
            typeRef={typeRef.current.type}
        />
    ));
  };

  const redirectPage = () => {
    history.push("/login");
  };

  const redirectPayment = () => {
    history.push("/settings/payment");
  };

  const subscriptionPlan = () => {
    //@ts-ignore
    return state.profileObject?.meta?.is_subscribed?.subscription_plan;
  };

  const removeStorage = () => {
    localStorage.removeItem(`trial-${state?.profileObject?.id}`);
  };

  const scrollBottom = () => {
    if(typeOfPosts() === "scheduled" && state.profileObject !== null) {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
      },100)
    }
  }

  useEffect(() => {
    scrollBottom()
  },[state.profileObject])

  const checkAuthProfile = () => {
    if(handle !== userData.user.handle) {
      if(state?.profileObject?.meta.is_subscribed) {
          return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const containerScrollRef = useRef<HTMLDivElement | null>(null);

  const onScroll = () => {
    if (containerScrollRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = containerScrollRef.current;
      if (scrollTop + clientHeight === scrollHeight && !stateRef.current.loadingState && stateRef.current.currentPage < stateRef.current.lastPage) {
        getProfilePosts(stateRef.current.currentPage + 1, typeOfPosts() === "all" ? "posts" : typeOfPosts());
      }
    }
  };
  return (
      <Fragment>
        <div
            className={`fl-profile-main fl-profile-tablet-show fl-profile-main-${userData.currentTheme}`}
            style={{ paddingTop: userData.authStatus !== "AUTHENTICATED" ? 30 : 0 }}
            ref={containerScrollRef}
            onScroll={() => isMobile ? onScroll() : ""}
        >
          <div className="fl-container">
            <div className="fl-d-flex fl-feed-row">
              <div
                  className={`fl-col-${
                      userData.authStatus !== "AUTHENTICATED" ? "12" : "8"
                  } fl-feed-column`}
              >
                {handle.length > 20 || handle === "404" ? <NotFound /> : notFound ? <NotFoundUser/> : deactivatedUser ? <DeactivateUser /> :
                    <>
                      <UserProfile
                          data={state.profileObject}
                          getUserProfileData={getUserProfileData}
                          setUpdateState={setState}
                          updateState={state}
                          getProfilePosts={getProfilePosts}
                      />

                      {state.profileObject ? (
                          <ProfileActions
                              subscriptionState={
                                state.profileObject.meta.subscription_status
                              }
                              profile={state.profileObject}
                              isCreator={isCreator}
                              plans={allPlans}
                              isSubscribed={isSubbed}
                              isBlocked={state.profileObject.meta.is_blocked}
                              isRestricted={state.profileObject.meta.restricted_by_me}
                              handle={handle}
                              setState={setState}
                              state={state}
                              getProfilePosts={getProfilePosts}
                              typeRef={typeRef.current.type}
                          />
                      ) : (
                          ""
                      )}

                      {state.profileObject ? (
                          <div className={`fl-profile-sort fl-profile-sort-${!checkSubscribe() ? "disabled" : "enabled" }`} ref={scrollRef}>
                            <div
                                className="fl-profile-sort-single"
                                onClick={() => !checkSubscribe() ? "" : userData.changeGridView() }
                            >
                              <div className={"fl-profile-count-icons fl-profile-count-icons-grid"}>
                                <FontAwesomeIcon
                                    icon={userData.gridView ? faWindowMaximize : faBorderAll}
                                    className="fl-profile-sort-single-icon"
                                />
                              </div>
                              <span className={"fl-profile-sort-single-icon-count fl-profile-icon-grid"}>{userData.gridView ? "FULL VIEW" : "GRID VIEW"}</span>
                            </div>
                            <div
                               /* onClick={() => {setTypePost({ ...typePost.current, type: "posts" });getProfilePosts(1, "posts");}}*/
                                onClick={() => window.history.replaceState(null, "", '?posts=all')}
                                className={`fl-profile-sort-single ${(typeOfPosts() === "posts" || typeOfPosts() === "all") && userData.authStatus === 'AUTHENTICATED' && checkAuthProfile() ? "fl-profile-sort-single-active" : ""}`}
                            >
                              <div className={"fl-profile-count-icons fl-profile-count-icons-posts"}>
                                <img
                                    alt="schedule_icon"
                                    src={(typeOfPosts() === "posts" || typeOfPosts() === "all") && userData.authStatus === 'AUTHENTICATED' && checkAuthProfile() ? allPostsActive : allPosts}
                                    className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                                />
                              </div>
                              <span className={"fl-profile-sort-single-icon-count-mobile"}>({state.profileObject.meta.posts})</span>
                              <span className={"fl-profile-sort-single-icon-count"} >ALL POSTS <br/>({state.profileObject.meta.posts})</span>
                            </div>
                            {handle === userData.user.handle ? (
                                <div
                                   /* onClick={() => getProfilePosts(1, "scheduled")}*/
                                    onClick={() => window.history.replaceState(null, "", '?posts=scheduled')}
                                    className={`fl-profile-sort-single ${typeOfPosts() === "scheduled" ? "fl-profile-sort-single-active" : ""}`}
                                >
                                  <div className={"fl-profile-count-icons fl-profile-count-icons-scheduled"}>
                                    <img
                                        alt="schedule_icon"
                                        src={typeOfPosts() === "scheduled" ? ScheduleIconActive : ScheduleIcon}
                                        className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                                    />
                                  </div>
                                  <span className={"fl-profile-sort-single-icon-count-mobile"}>({state.profileObject.meta.posts_scheduled})</span>
                                  <span className={"fl-profile-sort-single-icon-count"} >SCHEDULED POSTS <br/>({state.profileObject.meta.posts_scheduled})</span>
                                </div>
                            ) : null}

                            <div
                              /*  onClick={() => getProfilePosts(1, "images")}*/
                                onClick={() => window.history.replaceState(null, "", '?posts=images')}
                                className={`fl-profile-sort-single ${typeOfPosts() === "images" && userData.authStatus === 'AUTHENTICATED' ? "fl-profile-sort-single-active" : ""}`}
                            >
                              <div className={"fl-profile-count-icons"}>
                                <img
                                    alt="schedule_icon"
                                    src={typeOfPosts() == "images" && userData.authStatus === 'AUTHENTICATED' ? photoActive : photo}
                                    className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                                />
                              </div>
                              <span className={"fl-profile-sort-single-icon-count-mobile"}>({state.profileObject.meta.posts_with_images})</span>
                              <span className={"fl-profile-sort-single-icon-count"} >PHOTOS <br/>({state.profileObject.meta.posts_with_images})</span>
                            </div>
                            <div
                             /*   onClick={() => getProfilePosts(1, "videos")}*/
                                onClick={() => window.history.replaceState(null, "", '?posts=videos')}
                                className={`fl-profile-sort-single ${typeOfPosts() === "videos" && userData.authStatus === 'AUTHENTICATED' ? "fl-profile-sort-single-active" : ""}`}
                            >
                              <div className={"fl-profile-count-icons"}>
                                <img
                                    alt="schedule_icon"
                                    src={typeOfPosts() === "videos" && userData.authStatus === 'AUTHENTICATED' ? VideoActiveIcon : VideoIcon}
                                    className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                                />
                              </div>
                              <span className={"fl-profile-sort-single-icon-count-mobile"}>({state.profileObject.meta.posts_with_videos})</span>
                              <span className={"fl-profile-sort-single-icon-count"} >VIDEOS <br/>({state.profileObject.meta.posts_with_videos})</span>
                            </div>
                          </div>
                      ) : (
                          ""
                      )}
                      {handle === userData.user.handle &&
                      state.profileObject !== null ? (
                          <WritePost
                              refetchData={getProfilePosts}
                              profile
                              profilePosts={state}
                              setProfilePosts={setState}
                              profileRef={stateRef}
                              typePostProfile={typeOfPosts()}
                          />
                      ) : (
                          ""
                      )}

                      {state?.profileObject?.creatorProfile ? (
                          <>
                            {!state?.profileObject?.meta?.is_subscribed &&
                            handle !== userData.user.handle &&
                            (state?.profileObject?.subscriptionPlans?.length > 0 ||
                                state?.profileObject?.free_subscription === 1) ? (
                                <div className={"fl-profile-no-subscribe-box"}>
                                  <div className={"fl-profile-no-subscribe-box-logo"}>
                                    <img src={greyLogo} alt={`fluffa`} />
                                  </div>
                                  <div className="fl-postlock__main">
                                    <div className="fl-postlock__text fl-postlock__text-bold">
                                      {state.profileObject.free_subscription === 1
                                          ? "Follow now to get access to this Creator's profile!"
                                          : "Subscribe to get FULL access to this Creator's profile!"}
                                    </div>
                                    <div className="fl-postlock-lock fl-postlock-lock-logout">
                                      <img
                                          className="fl-card-icon-padlock-ppv"
                                          src={messagesIcon}
                                          alt="Card icon"
                                      />
                                      <img
                                          className="fl-card-icon-padlock-ppv-unlock"
                                          src={messagesIconUnlock}
                                          alt="Card icon"
                                      />
                                    </div>
                                    {state.profileObject.free_subscription === 0 ? (
                                        <Button
                                            type="normal"
                                            onClick={() =>
                                                !userData.user.meta.can_subscribe
                                                    ? redirectPayment()
                                                    : userData.authStatus === "UNAUTHENTICATED"
                                                        ? redirectPage()
                                                        : subscribe(
                                                            state?.profileObject?.subscriptionPlans.filter(
                                                                (x: any) => x.type === "regular"
                                                            )[0],
                                                            state?.profileObject?.subscriptionPlans
                                                        )
                                            }
                                        >
                                          {userData.authStatus === "UNAUTHENTICATED"
                                              ? "LOG IN TO SUBSCRIBE"
                                              : !userData.user.meta.can_subscribe
                                                  ? "ADD A PAYMENT CARD"
                                                  : "SUBSCRIBE NOW"}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="normal"
                                            onClick={() =>
                                                userData.authStatus === "UNAUTHENTICATED"
                                                    ? redirectPage()
                                                    : followFoFree()
                                            }
                                            disabled={freeAnimation}
                                        >
                                          {userData.authStatus === "UNAUTHENTICATED"
                                              ? "FOLLOW FOR FREE"
                                              : freeAnimation
                                                  ? "FOLLOWING"
                                                  : "FOLLOW FOR FREE"}
                                          {freeAnimation && (
                                              <ReactLoading
                                                  type={"spinningBubbles"}
                                                  color={"#fff"}
                                                  height={20}
                                                  width={20}
                                                  className="fl-spinningBubbles"
                                              />
                                          )}
                                        </Button>
                                    )}
                                  </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {typePost.animation ? (
                                <SimpleCard>
                                  <div className={"fl-loader-animation"}>
                                    <ReactLoading
                                        type={"spinningBubbles"}
                                        height={50}
                                        width={50}
                                        className="fl-spinningBubbles"
                                    />
                                  </div>
                                </SimpleCard>
                            ) : state.posts.filter((x:any) => x.deleted !== true).length === 0 && handle === userData.user.handle ?
                                <SimpleCard>
                                  <div className={"fl-profile-empty-post"}>
                                    <p>
                                      You haven't {typeOfPosts() === "scheduled" ? "scheduled any posts" : "Posted"} yet.
                                    </p>
                                    <p>
                                      {typeOfPosts() === "scheduled" ? "Posts you schedule will appear here." : "" }
                                    </p>
                                  </div>
                                </SimpleCard> :
                                (
                                    <>
                                      <div
                                          ref={containerRef}
                                          className={`fl-posts-view-${
                                              userData.gridView ? "grid" : "full"
                                          }`}
                                      >
                                        {state.posts
                                            .sort((a: any, b: any) =>
                                                a.pinned === false && b.pinned === true
                                                    ? 1
                                                    : a.pinned === true && b.pinned === false
                                                        ? -1
                                                        : 0
                                            )
                                            .map(
                                                (x: postInterface, y: number) =>
                                                    (x.postImages.length + x.postVideos.length === 0 &&
                                                        userData.gridView) || (
                                                        <Post
                                                            key={y}
                                                            data={x}
                                                            oldState={state}
                                                            updatePost={setState}
                                                            previousLocation={`/${handle}`}
                                                            refetchData={getProfilePosts}
                                                            subscribe={subscribe}
                                                        />
                                                    )
                                            )}
                                      </div>
                                    </>
                                )}
                          </>
                      ) : (
                          ""
                      )}
                      {state.currentPage >= state.lastPage &&
                      state.profileObject !== null &&
                      state?.profileObject?.meta?.is_subscribed ? (
                          <SimpleCard>
                            <div className="fl-feed-no-posts">
                              <div className="fl-feed-no-posts-icon">
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                              <div
                                  className={`fl-feed-no-posts-text fl-feed-no-posts-text-${userData.currentTheme}`}
                              >
                                {postType === "scheduled"
                                    ? "Posts in Scheduled will be posted at their scheduled time"
                                    : "You're all caught up"}
                              </div>
                            </div>
                          </SimpleCard>
                      ) : (
                          ""
                      )}
                    </>
                }
              </div>

              {userData.authStatus === "AUTHENTICATED" && (
                  <div className="fl-col-4 fl-feed-column fl-feed-column-sticky fl-profile-tablet-hide">
                    {userData?.user?.email_verified_at === null ? (
                        <VerificationBox />
                    ) : (
                        ""
                    )}
                    <UnapprovedBox />
                    {/*{userData.user.profile.meta.verified_state === "verified" && !notFound && !deactivatedUser && handle.length < 21 && handle !== "404" ?*/}
                    {/*    <Referral/> : ""*/}
                    {/*}*/}
                    {subscriptionPlan()?.type === "trial" &&
                    subscriptionPlan()?.billing_period_count > 2 &&
                    localStorage.getItem(`trial-${state?.profileObject?.id}`) ===
                    "show" ? (
                        <PlanTrialBox
                            upgrade={subscriptionPlan()?.upgrade_price}
                            handle={state?.profileObject?.handle}
                            remove={removeStorage}
                        />
                    ) : (
                        ""
                    )}
                    <PostOptions parentRef={containerRef.current} />
                    {/*<Suggestions />*/}
                    <SupportBox side="left" />
                  </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
  );
};
