import React, {FC, useState, useEffect, useRef} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import "./Explore.css";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useEnvContext } from "../../context/EnvContext";
import { useUserContext } from "../../context/UserContext";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";

import Navigation from "./Components/Navigation";
import Filter from "./Components/Filter/Filter";
import Section from "./Components/Sections/Section";
import {useModalContext} from "../../context/ModalContext";
import srcComing from "../../assets/images/5.png";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import src1 from "../../assets/images/5.png";
import {ComingSoon} from "../ComingSoon";


const Explore: FC<any> = () => {
  const { subroute } = useParams<{ subroute: string }>();
  const [activeTab, setActiveTab] = useState<string>(subroute || "");
  const [tabletSearchActive, setTabletSearchActive] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [filteredUsers] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<boolean>(false);
  const [creatorNumbers, setCreatorNumbers] = useState({all: 0, online: 0, new: 0});
  const [animation, setAnimation] = useState<any>(false)
  const [filterOptions, setFilterOptions] = useState<{
    creators: Array<Object>;
    price: Array<Object>;
    content_type: Array<Object>;
    term: Array<Object>;
    gender: Array<Object>;
    age: Array<Object>;
    nationality: Array<Object>;
    ethnicity: Array<Object>;
    body_type: Array<Object>;
    breast_size: Array<Object>;
    breast_type: Array<Object>;
    pubic_hair: Array<Object>;
    hair_color: Array<Object>;
    eye_color: Array<Object>;
    having: Array<Object>;
    doing: Array<Object>;
  }>({
    creators: [],
    price: [],
    content_type: [],
    term: [],
    gender: [],
    age: [],
    nationality: [],
    ethnicity: [],
    body_type: [],
    breast_size: [],
    breast_type: [],
    pubic_hair: [],
    hair_color: [],
    eye_color: [],
    having: [],
    doing: [],
  });

  const [isClean, setIsClean] = useState(false); //check if current items are searched, therefore clean/dirty type

  const [filter, setFilter] = useState<{
    creators: Array<string>;
    content_type: Array<string>,
    term: string;
    gender: Array<string>;
    age: string | null;
    nationality: Array<Number>;
    ethnicity: Array<Number>;
    body_type: Array<Number>;
    breast_size: Array<Number>;
    breast_type: Array<Number> | null;
    pubic_hair: Array<Number>;
    hair_color: Array<Number>;
    eye_color: Array<Number>;
    having: Array<Number>;
    doing: Array<Number>;
  }>({
    creators: [],
    content_type: [],
    term: "",
    gender: [],
    age: "",
    nationality: [],
    ethnicity: [],
    body_type: [],
    breast_size: [],
    breast_type: null,
    pubic_hair: [],
    hair_color: [],
    eye_color: [],
    having: [],
    doing: [],
  });

  const [filterPrice,setFilterPrice] = useState<any>({
    price:""
  })

  const priceRef = useRef(filterPrice);
  const filterRef = useRef<HTMLDivElement>(null);
  const history = useHistory()
  useEffect(() => { priceRef.current = filterPrice; }, [filterPrice]);

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  useBottomScrollListener(() => {
    if(!lastPage) {
      axios
          .get(`${envData.apiUrl}/explore`
              , activeTab === 'online' ? {params: {...filter, page: page + 1, online: 1}}
                  : activeTab === 'new' ? {params: {...filter, page: page + 1, new: 1}}
                      : {params: {...filter, page: page + 1}})
          .then((resp) => {
            setUsers([...users, ...resp.data.data]);
            setPage(page + 1);
            if(resp.data.data.length === 0) {
              setLastPage(true);
            }
          })
          .catch((e) =>  {
            if( e.response?.data.message === "Unauthenticated.") {
              userData.clearToken(false);
              history.push("/login");
            } else {
              modalData.pushToast("error", "Something went wrong!");
            }
          });
    }
  });

  useEffect(() => {
    axios.get(`${envData.apiUrl}/explore/profile-descriptions`).then(response => {
      let apiData = {
        age: [
          {
            "id": "18-24",
            "description_value": "18-24",
          },
          {
            "id": "25-32",
            "description_value": "25-32",
            "text": "25-32"
          },
          {
            "id": "33-40",
            "description_value": "33-40",
            "text": "33-40"
          },
          {
            "id": "40-99",
            "description_value": "40+",
            "text": "40+"
          }
        ],
        price: [
          {
            "id": "highest",
            "description_value": "Highest",
            "text": "More expensive creators"
          },
          {
            "id": "lowest",
            "description_value": "Lowest",
            "text": "Less expensive creators"
          },
          {
            "id": "trial",
            "description_value": "Offers a trial",
            "text": "Offers a trial"
          }
        ]
      }
      response.data.forEach((elem: any) => {
        apiData = {
          ...apiData,
          [elem.name]: elem.values,
        }
      });
      let filteredOptions: any = {...filterOptions, ...apiData};
      for(const attr in filteredOptions) {

        // Changes all Custom names to Others for display
        filteredOptions[attr] = filteredOptions[attr].map((elem: {description_value: string, id: number}) => {
          if(elem.description_value === 'Custom'){
            elem.description_value = 'Other';
          }
          return elem
        });

        filteredOptions[attr].sort((elem1: any, elem2: any) => {
          return elem1.description_value > elem2.description_value ? 1 : elem1.description_value < elem2.description_value ? -1 : 0
        });
      }

      setFilterOptions(filteredOptions);
    }).catch(e => {
      if( e.response?.data.message === "Unauthenticated.") {
        userData.clearToken(false);
        history.push("/login");
      } else {
        modalData.pushToast("error", "Something went wrong!");
      }
    });
  },[]);

  useEffect(() => {
    if(localStorage.getItem("explore") === "explore") {
      localStorage.removeItem("explore")
    }
  }, []);

  useEffect(() => {
    if (subroute) {
      setPage(1);
      setActiveTab(subroute);
    }
  }, [subroute]);

  useEffect(() => {
    filterUsers();
  }, [activeTab]);

  useEffect(() => {
    if(isClean) {
      setIsClean(false);
    }
  }, [filter.term])

  const [applyAnimation, setApplyAnimation] = useState(false)
  const [searchAnimation, setSearchAnimation] = useState(false)
  const [action,setAction] = useState("")
  const filterUsers = (items = 12,priceFilter = "", button="") => {
    setAnimation(false)
    if(button === "apply") {
      setApplyAnimation(true)
      setFilter({...filter, term: ""})
    }
    if(button === "search") {
      setSearchAnimation(true)
    }
    setAction(button)
    setPage(1);
    setUsers([])
    axios.get(`${envData.apiUrl}/explore${activeTab === "featured" ? '/featured' : activeTab === "free" ? '/free' : ""}?items_per_page=${items}&price=${priceFilter}`
        , activeTab === 'online' ? {params: {...filter, page: 1, online: 1}}
            : activeTab === 'new' ? {params: {...filter, page: 1, new: 1}}
                : {params: {...filter, page: 1}})

        .then(response => {
          setUsers(response.data.data);
          if(response.data.data.length < items ) {
            setLastPage(true);
          } else {
            setLastPage(false);
          }
          setAnimation(response.data.data.length > 0 ? 1 : 0)
          setCreatorNumbers({...response.data.meta});
          setIsClean(true);
          setIsLoaded(true);
          if(button === "apply") {
            setApplyAnimation(false)
          }
          if(button === "search") {
            setSearchAnimation(false)
          }
        }).catch(e => {
      if( e.response?.data.message === "Unauthenticated.") {
        userData.clearToken(false);
        history.push("/login");
      } else {
        modalData.pushToast("error", "Something went wrong!");
      }
      if(button === "apply") {
        setApplyAnimation(false)
      }
      if(button === "search") {
        setSearchAnimation(false)
      }
    });
  };

  if (
      tabletSearchActive === true &&
      !document.documentElement.classList.contains("fl-html-fixed")
  ) {
    disableBodyScroll(filterRef.current)
    document.documentElement.classList.add("fl-html-fixed");
  } else if (
      tabletSearchActive === false &&
      document.documentElement.classList.contains("fl-html-fixed")
  ) {
    enableBodyScroll(filterRef.current)
    document.documentElement.classList.remove("fl-html-fixed");
  }


  return (
     /* <div className={`fl-feed-main fl-feed-main-${userData.currentTheme}`}>
         <div className="fl-container">
           <div className="fl-d-flex fl-feed-row" >
             <div
               className={`fl-col-4 fl-feed-column fl-discover__filter--tablet ${
                 tabletSearchActive && "fl-discover__filter--tablet--active"
               }`}
             >
               <div ref={filterRef} className="fl-discover__filter__sticky" style={{zIndex: 2}}>
                 <Filter
                   filter={filter}
                   setFilter={setFilter}
                   setTabletSearchActive={setTabletSearchActive}
                   filterUsers={filterUsers}
                   options={filterOptions}
                   animation={applyAnimation}
                   animationSearch={searchAnimation}
                 />
               </div>
               <span
                 className="fl-discover__filter--tablet--hamburger"
                 onClick={() => setTabletSearchActive(!tabletSearchActive)}
               >
                 <FontAwesomeIcon icon={tabletSearchActive ? faTimes : faSearch} />
               </span>
             </div>
             <div className="fl-col-8 fl-feed-column fl-feed-tablet-prio fl-discover__feed--tablet">
               <Navigation activeTab={activeTab} setActiveTab={setActiveTab} creatorNumbers={creatorNumbers}/>
              <Section  action={action} setFilterPrice={setFilterPrice} priceRef={priceRef} animation={animation} lock={!isClean} show={isLoaded} term={filter.term} users={filteredUsers.length > 0 ? filteredUsers : users}   filterUsers={filterUsers} setFilter={setFilter} filter={filter}/>
             </div>
           </div>
         </div>
       </div>*/
      <div className={`fl-feed-main fl-feed-main-${userData.currentTheme}`}>
        <ComingSoon src={srcComing} />
      </div>
  );
};

export default Explore;

