import React, {FC, useEffect, useState} from 'react'
import { useUserContext } from '../../context/UserContext';
import './Drive.css';
import {SimpleCard} from "../../components/SimpleCard";
import {DriveFiles} from "../Drive/DriveFiles"
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import Axios from "axios";
import {useModalContext} from "../../context/ModalContext";
import {useEnvContext} from "../../context/EnvContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";


export const Drive:FC<({} )> = (props) => {

    const userData = useUserContext();
    const history = useHistory();
    const modalData = useModalContext();
    const envData = useEnvContext()

    const [selectedFiles, setSelectedFiles] = useState<any> ({
        files:[],
        type: "",
        count: 0
    })

    const { location } = useParams<{ location: string }>();

    const addSelected = (file:any) => {
        let newSelectedFiles = [...selectedFiles.files, file];
        setSelectedFiles({
            ...selectedFiles,
            files: newSelectedFiles,
            count: selectedFiles.count + 1
        });
    }

    const removeSelected = (id: any) => {
        let newSelectedFiles = selectedFiles.files.filter(
            (x: any) => x.id !== id
        );

        setSelectedFiles({
            ...selectedFiles,
            files: newSelectedFiles,
            count: selectedFiles.count - 1
        });

    };

    const changeType = (type:string) => {
        history.push(`/drive/${type}`)
    }

    const [countFiles, setCountFiles] = useState<any>(0)
    const [countRemovedPosts, setCountRemovedPosts] = useState<any>(0)

    const countAllFiles = async () => {
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/attachments/count?type=all`
            );
            setCountFiles(res.data.count)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        (async () => {
            await countAllFiles()
        })();
    }, []);

    const [load,setLoad] = useState<any>(false)

    const [countArchive, setCountArchive] = useState<number>(userData.user.profile.meta.posts_in_drive)

    return (
        <div
            className={`fl-drive-main fl-settings-main fl-settings-main-${userData.currentTheme} fl-notifications-drive-${userData.currentTheme}`}
        >
            <div className="fl-container">
                <div className="fl-d-flex fl-feed-row">
                    <div className="fl-col-3 fl-feed-column" fl-drive-menu>
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option-${userData.currentTheme} ${location === "files" && "fl-drive-menu-active" }`} onClick={() => changeType("files")}>
                                <span>
                                    Files  ({userData.user.profile.meta.attachments_in_drive})
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option-${userData.currentTheme} ${location === "posts" && "fl-drive-menu-active" }`} onClick={() => changeType("posts")}>
                                <span>
                                    Archive ({countArchive})
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                    </div>
                    <div className="fl-col-9 fl-feed-column fl-drive-tablet-show">
                        <SimpleCard>
                            <DriveFiles setCountArchive={setCountArchive} countArchive={countArchive} setLoad={setLoad} load={load} setCountRemovedPosts={setCountRemovedPosts} countFiles={countFiles} route={""} setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} removeSelected={removeSelected} addSelected={addSelected} type={"drive"}/>
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    )
}
