import React, { FC } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

const Checkboxes: FC<any> = ({ users, setUsers, userData }) => {
  return (
    <>
      <div
        className={`fl-bulk__users__custom--row fl-bulk__users__custom--row--${userData.currentTheme}`}
      >
        <input
          type="checkbox"
          id="rebill-on"
          value="rebill-on"
          disabled={users.rebill_on.length === 0}
          checked={users.customOptionsSelected.includes("rebill-on")}
          onChange={() => {
            if (
              users.customOptionsSelected.includes("rebill-on") &&
              users.customOptionsSelected.includes("rebill-off") &&
              users.customOptionsSelected.includes("expired")
            ) {
              setUsers({
                ...users,
                selectedUsers: [...users.rebill_on, ...users.expired],
                customOptionsSelected: ["expired", "rebill-on"],
              });
            } else if (
              users.customOptionsSelected.includes("rebill-on") &&
              users.customOptionsSelected.includes("rebill-off")
            ) {
              setUsers({
                ...users,
                selectedUsers: [...users.selectedUsers].filter(
                  (user: any) => !users.rebill_off.includes(user)
                ),
                customOptionsSelected: users.customOptionsSelected.filter(
                  (opt: any) => opt !== "rebill-off"
                ),
              });
            } else if (!users.customOptionsSelected.includes("rebill-on")) {
              let addedItems = [...users.selectedUsers];
              users.rebill_on.map(
                (user: any) =>
                  !users.selectedUsers.includes(user) && addedItems.push(user)
              );
              setUsers({
                ...users,
                selectedUsers: addedItems.filter(
                  (user: any) => !users.rebill_off.includes(user)
                ),
                customOptionsSelected: [
                  ...users.customOptionsSelected,
                  "rebill-on",
                ].filter((x: any) => x !== "rebill-off"),
              });
            } else {
              let filteredUsers = [...users.selectedUsers].filter(
                (e: any) => !users.rebill_on.includes(e)
              );
              setUsers({
                ...users,
                selectedUsers: filteredUsers,
                customOptionsSelected: users.customOptionsSelected.filter(
                  (x: any) => x !== "rebill-on"
                ),
              });
            }
          }}
        />
        <label htmlFor="rebill-on">
          Send only to followers who have their rebill turned ON (
          {users.rebill_on.length})
        </label>
      </div>

      <div
        className={`fl-bulk__users__custom--row fl-bulk__users__custom--row--${userData.currentTheme}`}
      >
        <input
          type="checkbox"
          id="rebill-off"
          value="rebill-off"
          disabled={users.rebill_off.length === 0}
          checked={users.customOptionsSelected.includes("rebill-off")}
          onChange={() => {
            if (
              users.customOptionsSelected.includes("rebill-on") &&
              users.customOptionsSelected.includes("rebill-off") &&
              users.customOptionsSelected.includes("expired")
            ) {
              setUsers({
                ...users,
                selectedUsers: [...users.rebill_off, ...users.expired],
                customOptionsSelected: ["expired", "rebill-off"],
              });
            } else if (
              users.customOptionsSelected.includes("rebill-on") &&
              users.customOptionsSelected.includes("rebill-off")
            ) {
              setUsers({
                ...users,
                selectedUsers: [...users.selectedUsers].filter(
                  (user: any) => !users.rebill_on.includes(user)
                ),
                customOptionsSelected: users.customOptionsSelected.filter(
                  (opt: any) => opt !== "rebill-on"
                ),
              });
            } else if (!users.customOptionsSelected.includes("rebill-off")) {
              let addedItems = [...users.selectedUsers];
              users.rebill_off.map((user: any) => {
                !users.selectedUsers.includes(user) && addedItems.push(user);
              });
              setUsers({
                ...users,
                selectedUsers: addedItems.filter(
                  (item: any) => !users.rebill_on.includes(item)
                ),
                customOptionsSelected: [
                  ...users.customOptionsSelected,
                  "rebill-off",
                ].filter(
                  (x: any) => x !== "rebill-on" && x !== "rebill-on/off"
                ),
              });
            } else {
              let filteredUsers = [...users.selectedUsers].filter(
                (e: any) => !users.rebill_off.includes(e)
              );
              setUsers({
                ...users,
                selectedUsers: filteredUsers,
                customOptionsSelected: users.customOptionsSelected.filter(
                  (x: any) => x !== "rebill-off"
                ),
              });
            }
          }}
        />
        <label htmlFor="rebill-off">
          Send only to followers who have their rebill turned OFF (
          {users.rebill_off.length})
        </label>
      </div>

      <div
        className={`fl-bulk__users__custom--row fl-bulk__users__custom--row--${userData.currentTheme}`}
      >
        <input
          type="checkbox"
          id="rebill-on/off"
          value="rebill-on/off"
          disabled={
            users.rebill_on.length === 0 && users.rebill_off.length === 0
          }
          checked={
            users.customOptionsSelected.includes("rebill-on") &&
            users.customOptionsSelected.includes("rebill-off")
          }
          onChange={() => {
            if (
              users.customOptionsSelected.includes("rebill-on") &&
              users.customOptionsSelected.includes("rebill-off") &&
              users.customOptionsSelected.includes("expired")
            ) {
              setUsers({
                ...users,
                selectedUsers: [...users.expired],
                customOptionsSelected: ["expired"],
              });
            } else if (
              !users.customOptionsSelected.includes("rebill-on") ||
              !users.customOptionsSelected.includes("rebill-off")
            ) {
              let addedItems = [...users.selectedUsers];
              users.rebill_on.map((user: any) => {
                !users.selectedUsers.includes(user) && addedItems.push(user);
              });
              users.rebill_off.map((user: any) => {
                !users.selectedUsers.includes(user) && addedItems.push(user);
              });

              setUsers({
                ...users,
                selectedUsers: addedItems,
                customOptionsSelected: [
                  ...users.customOptionsSelected,
                  !users.customOptionsSelected.includes("rebill-on") &&
                    "rebill-on",
                  !users.customOptionsSelected.includes("rebill-off") &&
                    "rebill-off",
                ],
              });
            } else {
              let filteredUsers = [...users.selectedUsers]
                .filter((e: any) => !users.rebill_off.includes(e))
                .filter((e: any) => !users.rebill_on.includes(e));
              setUsers({
                ...users,
                selectedUsers: filteredUsers,
                customOptionsSelected: users.customOptionsSelected.filter(
                  (x: any) => x !== "rebill-on" && x !== "rebill-off"
                ),
              });
            }
          }}
        />
        <label htmlFor="rebill-on/off">Send only to active followers</label>
        <FontAwesomeIcon
          icon={faInfoCircle}
          data-tip
          data-for="disable-account"
          className="fl-bulk__users__custom--i"
        />
        <ReactTooltip id="disable-account">
          This sends your message to followers who have their rebill turned ON
          or OFF (385 recipients)
        </ReactTooltip>
      </div>

      <div
        className={`fl-bulk__users__custom--row fl-bulk__users__custom--row--${userData.currentTheme}`}
      >
        <input
          type="checkbox"
          id="expired"
          value="expired"
          disabled={users.expired.length === 0}
          checked={users.customOptionsSelected.includes("expired")}
          onChange={() => {
            if (
              users.customOptionsSelected.includes("rebill-on") &&
              users.customOptionsSelected.includes("rebill-off") &&
              users.customOptionsSelected.includes("expired")
            ) {
              setUsers({
                ...users,
                selectedUsers: [...users.rebill_on, ...users.rebill_off],
                customOptionsSelected: ["rebill-on", "rebill-off"],
              });
            } else if (!users.customOptionsSelected.includes("expired")) {
              let addedItems = [...users.selectedUsers];
              users.expired.map((user: any) => {
                !users.selectedUsers.includes(user) && addedItems.push(user);
              });
              setUsers({
                ...users,
                selectedUsers: addedItems,
                customOptionsSelected: [
                  ...users.customOptionsSelected,
                  "expired",
                ],
              });
            } else {
              let filteredUsers = [...users.selectedUsers].filter(
                (e: any) => !users.expired.includes(e)
              );
              setUsers({
                ...users,
                selectedUsers: filteredUsers,
                customOptionsSelected: users.customOptionsSelected.filter(
                  (x: any) => x !== "expired"
                ),
              });
            }
          }}
        />
        <label htmlFor="expired">
          Send only to expired followers ({users.expired.length})
        </label>
      </div>

      <div
        className={`fl-bulk__users__custom--row fl-bulk__users__custom--row--${userData.currentTheme}`}
      >
        <input
          type="checkbox"
          id="all-followers"
          value="all-followers"
          disabled={
            users.rebill_on.length === 0 &&
            users.rebill_off.length === 0 &&
            users.expired.length === 0
          }
          checked={
            users.customOptionsSelected.includes("rebill-on") &&
            users.customOptionsSelected.includes("rebill-off") &&
            users.customOptionsSelected.includes("expired")
          }
          onChange={() => {
            if (
              !(
                users.customOptionsSelected.includes("rebill-on") &&
                users.customOptionsSelected.includes("rebill-off") &&
                users.customOptionsSelected.includes("expired")
              )
            ) {
              setUsers({
                ...users,
                selectedUsers: [
                  ...users.rebill_on,
                  ...users.rebill_off,
                  ...users.expired,
                ],
                customOptionsSelected: ["rebill-on", "rebill-off", "expired"],
              });
            } else {
              setUsers({
                ...users,
                selectedUsers: [],
                customOptionsSelected: [],
              });
            }
          }}
        />
        <label htmlFor="all-followers">
          Send to all followers (
          {users.rebill_on.length +
            users.rebill_off.length +
            users.expired.length}
          )
        </label>
      </div>
    </>
  );
};

export default Checkboxes;
