import React, { FC, useState } from "react";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { ProfileHolder } from "./ProfileHolder";
import { useUserContext } from "../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faCheck } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

export const PostBody: FC<any> = (props) => {
  const envData = useEnvContext();
  const userData = useUserContext();
  const [dataUser, setDataUser] = useState<any>("");
  const getDataUser = async (id: number) => {
    if (id > 0) {
      try {
        const profileRes = await Axios.get(`${envData.apiUrl}/profile/${id}`);
        setDataUser(profileRes.data.data);
      } catch (e) {}
    } else {
      setDataUser("");
    }
  };

  return (
    <div>
      <p className="d-inline comment-paragraph-text">
        {reactStringReplace(`${props.body}`, /@\[(\S+)\]/g, (match, i) => (
          <span
            className={"fl-tag-body"}
            onMouseEnter={() =>
              getDataUser(Number(match?.match(/\((.*)\)/)?.pop()))
            }
            onMouseLeave={() => getDataUser(0)}
          >
            <Link
              className={"fl-tag-body-link"}
              key={match + i}
              to={`/${match.split("(")[0]}`}
            >
              @{match.split("(")[0]}
            </Link>
            {dataUser?.handle === match.split("(")[0] ? (
              <div className={`fl-tag-body-profile fl-tag-body-profile-${userData.currentTheme}`}>
                <div className="fl-d-flex fl-align-center fl-mb-20">
                  <div className="fl-my-profile-img">
                    <ProfileHolder
                      src={dataUser?.profileImage?.url}
                      userProfile={dataUser}
                      changeStatus
                    />
                  </div>
                  <div className="fl-my-profile-info">
                    <Link
                      to={`/${dataUser.handle}`}
                      className={`fl-my-profile-name fl-my-profile-name-${userData.currentTheme}`}
                    >
                       <span className={"fl-user-profile-full-name-span"}>
                         {dataUser.name}
                         {userData.user.profile.meta.verified_state ===
                         "verified" ? (
                             <>
                               <div
                                   className="fl-user-profile-verified"
                                   title="Verified creator"
                               >
                                 <FontAwesomeIcon
                                     className="fl-user-profile-verified-crt"
                                     icon={faCertificate}
                                 />
                                 <FontAwesomeIcon
                                     data-tip
                                     data-for="profile-verified"
                                     className="fl-user-profile-verified-chc"
                                     icon={faCheck}
                                 />
                               </div>
                               <ReactTooltip id="profile-verified">
                                 Verified
                               </ReactTooltip>
                             </>
                         ) : (
                             ""
                         )}
                       </span>
                    </Link>
                    <Link
                      to={`/${userData.user.handle}`}
                      className="fl-my-profile-username"
                    >
                      @{dataUser.handle}
                    </Link>
                  </div>
                </div>
                <div className="fl-d-flex">
                  <div className="fl-col-4 fl-text-align-center ">
                    <div className="fl-my-profile-key">All Posts</div>
                    <div
                      className={`fl-my-profile-value fl-my-profile-value-${userData.currentTheme}`}
                    >
                      {dataUser?.meta?.posts}
                    </div>
                  </div>
                  <div className="fl-col-4 fl-text-align-center ">
                    <div className="fl-my-profile-key">Photos</div>
                    <div
                      className={`fl-my-profile-value fl-my-profile-value-${userData.currentTheme}`}
                    >
                      {dataUser?.meta?.posts_with_images}
                    </div>
                  </div>
                  <div className="fl-col-4 fl-text-align-center">
                    <div className="fl-my-profile-key">Videos</div>
                    <div
                      className={`fl-my-profile-value fl-my-profile-value-${userData.currentTheme}`}
                    >
                      {dataUser?.meta?.posts_with_videos}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
          </span>
        ))}
      </p>
    </div>
  );
};

export default PostBody;
