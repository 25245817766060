import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {FC} from "react";
import {Link} from "react-router-dom";
import { SimpleCard } from "../../components/SimpleCard";
import "./Support.css";
import "../Privacy.css";
import {SupportNav} from "./SupportNav";
import {useUserContext} from "../../context/UserContext";

export const SupportTerms: FC<any> = (props) => {
    const userData = useUserContext()
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    {!isMobile ?  <SupportNav /> : ""}
                    <div className="fl-col-9 fl-support-col">
                        <SimpleCard>
                            <div className={`fl-privacy-title fl-privacy-title-${userData.currentTheme}`}>Terms of Service</div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    What we do
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Fluffa.com (hereinafter referred to as “Fluffa” or the “Site”) is a social media platform which allows Creators to create a profile, add Creator Content onto their profile, set a monthly subscription price payable by Followers who wish to view their content and so generate revenue.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>In these Terms of Service, when we refer to “we”, “us” or “our”, we mean NebNine LTD (hereinafter referred to as “NNL”); and when we refer to “you” or “your” we mean Users accessing or using the Site.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>These Terms govern your use of this Site, including any content, functionality, and services offered on or through the Site. By registering with and using Fluffa.com you hereby accept and agree to be bound by and abide by these Terms. If you do not want to agree to these Terms of Service, you must not access or use the Site.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>This Site is only offered and available to Users who are 18 years of age or older. By using the Site, you represent and warrant that you are 18 years of age. If you are not, you must not access or use the Site. Please see our Privacy Notice for more information about how we verify your age.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Definitions
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>In these Terms of Service, the following terms have the following meanings:</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Balance”</b> means the Charges less the Fee (plus any applicable VAT);</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Charges”</b> means the charges payable by a Follower to view Creator Content;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Creator”</b> means a User who uploads content to the Site to be purchased and viewed by a Follower;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Creator Content”</b> means any and all photos, videos and other material uploaded onto the Site by a Creator;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Fee”</b> means the amount that we may deduct from the Charges as set out on the ‘Fees’ section of the Site when a Creator registers a User Account (plus any applicable VAT), and as amended from time to time;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Follower”</b> means a User who follows a Creator on the Site and purchases and views their uploaded content;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Referral Payment”</b> means the payments that are made by NNL to Users who introduce new Users to Fluffa, which shall be equal to 5% of all the Charges earned by the new User for a period of 12 months starting after the new User creates a User Account;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“Subscription”</b> means a subscription taken out by a Follower to access Creator Content from a Creator;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“User”</b> means either a Creator or Follower;</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>“User Account”</b> means a registered Fluffa account used to access certain features of the Site;</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    How we may use your personal information
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You agree that all information you provide as a User or otherwise, including but not limited to information provided through the use of any interactive features on the Site, is governed by our privacy notice which can be found here and you proceed on the basis that you are aware of how and why we process your personal data. We also use cookies on our Site. For more information please see our <Link to="/cookies">cookie policy</Link>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Who we are
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>This Site is a project of NebNine LTD, a company registered in Scotland with company number SC676804.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Our registered office address is 272 Bath Street, Glasgow, Scotland, G2 4JR, but if you would like to contact us, for example if you have any questions about the Site, you should email us at <a href="mailto:support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Registration
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>To become a User you must register and create a User Account on Fluffa.com. You must provide a valid email address, a username, and a password. It is a condition of your use of the Site that all the information you provide on the Site is correct, current, and complete.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>There are a number of accounts that you can create on our Site.  These include free accounts, paid accounts and/or couples accounts.  </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Creators - valid government issued identification (“ID”) (such as a passport or driving licence, which includes your address) will be required to create an account with us, along with a selfie verification photo so that we can match your ID.  A release form will be required for any other individual(s) appearing in the Creator’s Content. Please note, if creating a couples’ account valid government issued identification will be required from each respective Creator.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Amounts due to you will be paid by us in accordance with the method of payment that you have selected.  </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You will need to provide certain details (e.g. bank or card details) depending on the payment method selected, to enable payments to be made to you.  You may also need to provide additional information if you are resident in certain countries, such as a <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">W-9 form</a> if resident in the United States of America.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Followers - if you wish to view paid Creator Content you will need to provide your card payment details, which will be stored by our secure third-party payment processor(s).</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Payments and fees
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><i>Creators:</i></div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>
                                    In consideration for the operation by us of the Site and the opportunity to upload Creator Content, the Creator authorises us to:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>act as payment processing agent for any Charges due from Followers and to accept payment on the Creator’s behalf; and</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>retain from such Charges the Fee as notified to the Creator from time to time plus any applicable VAT.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Following payment by a Follower of the Charges, we shall deduct the Fee and the Balance on the Creator’s Account will be made available to the Creator. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><b>We shall not be liable for any failure of the Follower to pay the Charges to Creators. We are solely providing an intermediary service to Creators by facilitating Subscriptions that Followers may wish to purchase from Creators.</b></div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We may set off any liability of the Creator to us against their Balance and make a deduction from the Balance accordingly (including, for example, to account for any refunds, chargebacks, fraudulent activity and/or money laundering).</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We have the right to make changes to the Fee from time to time, although we shall provide the Creator with at least one month’s notice of such a change</div>

                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}><i>Followers:</i></div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Details of the Charges are as set out during the Subscription order process with the Creator you wish to follow and are determined by the Creator in their discretion. Payment of Charges can be made on our Site by credit or debit card, or other payment method accepted by us.  Once a Follower’s order has been confirmed their credit or debit card will be authorised by our secure payment processor(s). </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Follower’s Subscription shall run on a one month, three month or six month basis and automatically renew for the same time period selected until terminated pursuant to these terms. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Creator has the right to make changes to the Charges from time to time, although any price changes to your Subscription shall apply no earlier than 30 days following notice to you. If these changes result in an increase in the Charges payable by the Follower, the Follower will have the option to terminate their Subscription.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Creator takes reasonable care to ensure that the Charges stated for the Subscription are correct at the time when the relevant information was entered into the system. However, it is always possible that, despite the Creator’s reasonable efforts, some of the subscription options on the Site may be incorrectly priced. If the correct price for the Subscription is higher than the price stated on the Site the Creator will contact NNL who will in turn contact the Follower as soon as possible to inform the Follower of this error and will give the Follower the option of continuing to purchase the Subscription at the correct price or cancelling the Subscription. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>All payments made to view Creator Content are non-refundable. Furthermore, any chargebacks will be contested with your bank and/or credit card institution which may require us to submit your personal information and chat history. Any Followers who chargeback will receive a permanent ban from the Site. Should a chargeback be successful the affected Creators will therefore not receive their income and they will be notified which Follower filed the chargeback. By making a purchase on the Site you confirm that you have read, understood and agreed to this policy.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Right to Cancel Subscriptions and termination
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Followers can cancel their Subscription at any time and will continue to have access to the Subscription through to the end of the billing period. So, if a Follower purchases a monthly Subscription on the 10th of a calendar month, then the billing period shall run from that date to (and including) the 9th of the following calendar month. Payments are non-refundable and NNL will not provide refunds or credits for any partial Subscription period or unwatched Creator Content. We reserve the right to suspend or terminate both a Creator and a Follower’s account and their use of the Site at any time for any reason, including if:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>you provide any information that we reasonably believe is untrue, inaccurate, not current or incomplete, or that we are unable to verify as being true, accurate, current and complete having made reasonable efforts to do so;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>we have reasonable grounds to suspect that any of the information that you have provided is untrue, inaccurate, not current or incomplete;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>you commit any breach of these terms;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>you do anything that we, in our absolute discretion, believe may damage our reputation or that of the Site, or give rise to legal risk or liability to us or any of our other Users;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>we receive a complaint about you from any other User;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>we become aware that you have become bankrupt or otherwise being unable to pay your debts as they fall due; and/or</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>your account is inactive for an extended period of time.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Creator may also cancel a Subscription at any time by blocking a Follower’s profile. If the Creator (or us on behalf of the Creator) blocks or terminates a Subscription, we will (on behalf of the Creator) refund any money the Follower has paid in respect of any Balance following such blocking or termination, but where the Creator (or us on behalf of the Creator) is terminating because of a breach of these terms by the Follower, we may deduct or charge the Follower reasonable compensation for the net costs we and/or the Creator will incur as a result of the Follower breaching these terms (and, for the avoidance of doubt, where termination is due to the Follower’s fraud or money-laundering, or a charge-back, no refund shall be paid).</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Any provision of these Terms that expressly or by implication is intended to come into or continue in force on or after termination will remain in full force and effect.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If one or more of your accounts has been suspended or terminated by us your other accounts may also be suspended or terminated by us at our discretion depending on the violation.</div>

                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Account Deactivation
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Should you wish to temporarily deactivate your Fluffa account then you may do so in your “my account” section of the Site</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>If you are a Creator and wish to deactivate your account you can only do so once your last Follower subscription has expired, and you have withdrawn any remaining Balance on your Account.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>If you are a Follower and wish to deactivate your account any deactivation will take place as soon as reasonably possible after your Subscription for that month has expired. After deactivation, you will no longer be charged and your Subscriptions will be deleted. You will also no longer have access to Creator Content.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>If a User is both a Creator and a Follower the account will be deactivated in two stages, as outlined above.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>You will receive an email confirmation upon the successful deletion of your account.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Except where a User Account is terminated by us for a breach of these terms, we will transfer to the Creator any outstanding Balance within forty-five (45) days of the effective date of termination.</div>
                                </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Account Deletion
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Notwithstanding our data retention obligations in accordance with our Privacy Notice, should you wish to permanently close and delete your Fluffa account (as well as all content if a Creator) then you may do so in your “my account” section of the Site.</div>
                                </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    You must keep your account details safe
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you choose, or you are provided with, a User identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party and password sharing is strictly forbidden.  Any User found to be password sharing will have their User Account suspended immediately, pending investigation.  If found to be intentional (as decided by us) the User Account will be terminated and no refunds will be issued.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We have the right to disable any User identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you know or suspect that anyone other than you knows your User identification code or password, you must promptly notify us at <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    How you may use material on our Site
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Apart from the content you upload, we are the owner or the licensee of all intellectual property rights in our Site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You may print off one copy, and may download extracts, of any page(s) from our Site for your personal use.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you print off, copy or download any part of our Site in breach of these terms of use, your right to use our Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You must not screenshot, screen record or download Creator Content in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You must not use any part of the content on our Site for commercial purposes without obtaining a licence to do so from us or our licensors.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you copy or download any part of our Site in breach of these terms of use, your right to use our Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Twitter
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Users can connect a Twitter account to their Fluffa account to post and share certain content. By using this feature you must fully comply with Twitter's <a href="https://twitter.com/en/tos">terms of service</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Creator Content
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The views expressed by Creators and Users on our Site do not represent our views or values. If you wish to make a complaint in this respect please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>, or use the report post function.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>By creating and publishing Creator Content on Fluffa, you authorize other Users to access and view (without downloading or copying) your content on Fluffa for their own lawful and personal use. You also represent, warrant and undertake that for each submission:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>you own, have a valid licence to, or otherwise control all rights in and to your content;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>to the extent your content includes or utilises any third-party property, you have secured rights, licenses, written consents and releases that are necessary for the use of such third-party property in your content and you will reimburse us if we suffer any claims as a result of your submission of any content in contravention of this paragraph;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>you will not post any content depicting any person under 18-years old,</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>you have inspected and are maintaining written documentation sufficient to confirm that all subjects of your submission are in fact 18-years old or older; and</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>your content is non-confidential and will be made available to other Users on Fluffa.com.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You understand and acknowledge that you are responsible for any content you submit or contribute, and you have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You retain all of your ownership rights in your Creator Content, but you are required to grant us and other Users of our Site a worldwide, non-exclusive, royalty-free, transferable licence to use the content in accordance with the functionality of the Site to expire when the User deletes the content from the Site.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We have the right to remove any Creator Content if, in our opinion, it does not comply with the content standards set out in these terms.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Referral program
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>NNL offers a referral program which incentivises Users to introduce others to Fluffa who are interested in also becoming Users. This clause sets out the terms on which NNL will pay a Referral Payment. NNL reserves the right to change the process of Referral Payments but any Referral Payments earned prior to the changes coming into effect will be honoured.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The following steps outline how the referral program works:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Users with a valid User Account with Fluffa can participate in the referral program;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Individual User Accounts all have a unique referral URL which allows Users to earn Referral Payments;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>The referred User must use the referring User’s unique URL and then register with Fluffa using the same browser that they used to click the referral link;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>The referred User must be someone who has never held a User Account before. If the referred User has been an existing User, NNL will not pay Referral Payments to the referring User;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>NNL will not pay Referral Payments if the referred User does not join Fluffa using the correct unique referral link;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>If the referred User then sets up more than one new User Account then the obligation to pay Referral Payments shall only apply to applicable earnings generated from the first User Account. The referred User will only be a new User with respect to the first User Account;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Users may not use confusing business practices to impersonate Fluffa with the intention to refer other Users to receive Referral Payments.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If it transpires that Referral Payments have been made erroneously then NNL reserves the right to recover said payments from the referring User.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>NNL reserves the right to verify the identity of Users and referring Users claiming to have introduced referred Users. This allows us to ensure that the referral program is fair and not abused.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Referral Payment will be processed on or around the first calendar business day of each month and paid in accordance with the Payment Provider’s terms.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Referral Payment is deducted from the monetary amount that NNL would retain from the transaction and not from the monies earned by the referred User.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    User indemnification
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Creators are responsible for compliance with all applicable tax and regulatory obligations in respect of the Subscriptions and payments received from Followers. Therefore, we recommend that Creators take independent legal and tax advice in this respect.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    No waiver
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>No waiver of any term or condition set out in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Severability
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If any provision of these Terms of is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Entire agreement
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>These Terms of Service constitute the entire agreement and understanding of the parties and supersede any previous agreement or understanding between the parties.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Third party rights
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The Contracts (Rights of Third Parties) Act 1999 is excluded.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    DMCA
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Fluffa complies with the Digital Millennium Copyright Act (DMCA).  We will terminate the account of Users who repeatedly infringe the copyright of others.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Users should be aware, that under the DMCA, any person who intentionally infringes the copyright of others may be liable for damages.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Acceptable Use
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The rules described in this paragraph have been designed to protect the Site and its Users and to ensure that the Site remains functional, accessible and secure for all Users. These rules apply to any person accessing the Site, whether or not a registered User.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You must:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>use the Site in accordance with these terms, in good faith and in the manner in which is intended;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>read any instructions, guidance and policies displayed on the Site and ensure that you use the Site in accordance with those instructions; and</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>let us know if you become aware of anything which concerns you regarding the Site, including any security issues or any breach or potential breach of these terms by you or any other User.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You may not use the Site:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>in any way that breaches any applicable local, national or international law or regulation;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>in any way that may damage our reputation, that of the Site, or the reputation of any other User;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect; and/or</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>in any way that is designed to frustrate or bypass any safeguards or restrictions built into the Site or any rules described in these terms;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>for the purpose of harming or attempting to harm minors in any way or to solicit personal information from anyone under 18 years of age or solicit passwords or personally identifying information for commercial or unlawful purposes;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>to harvest or collect email addresses or other contact information of other Users of the Site by electronic means for the purposes of sending unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>to transmit, or procure the sending of spam; and/or</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>to send, knowingly receive, upload, download, use or re-use any material which:
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>is inaccurate;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>expresses an opinion which is not genuinely held;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>contains any material which is defamatory or derogatory of any person;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>contains any material which is obscene, offensive, hateful or inflammatory (for example - any material which depicts (or may depict) non consensual sexual activity, such as people under the influence of alcohol; individuals sleeping and unaware that they are being filmed or photographed), or illegal acts such as incest or beastiality, or in any way which incites violence or violates any of the aforementioned prohibitions;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>promotes or advertises escort services;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>promotes or advertises firearms or other weapons, drugs, or drug paraphernalia;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>involves 3rd party commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter, or advertising;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>infringes any copyright, database right, trade mark or other intellectual property right of any other person;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>is likely to deceive any person;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>is made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>is threatening, abusive or invade another’s privacy, causes annoyance, inconvenience or needless anxiety, is likely to harass, upset, embarrass, alarm or annoy any other person; and/or</div>
                                        <div className={`fl-terms-sub-row fl-terms-sub-row-second-level fl-terms-sub-row-${userData.currentTheme}`}>advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    We are not responsible for the websites we link to
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Where our Site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We have no control over the contents of those sites or resources.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Our responsibility for loss or damage suffered by you
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Whether you are a consumer or a business User:</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you are a business User:</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We exclude all implied conditions, warranties, representations or other terms that may apply to our Site or any content on it.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>use of, or inability to use, our Site; or</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>use of or reliance on any content displayed on our Site.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>In particular, we will not be liable for:</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>loss of profits, sales, business, or revenue;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>business interruption;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>loss of anticipated savings;</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>loss of business opportunity, goodwill or reputation; or</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>any indirect or consequential loss or damage.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you are a consumer:</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You agree not to use our Site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    We are not responsible for viruses and you must not introduce them
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We do not guarantee that our Site will be secure or free from bugs or viruses.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You are responsible for configuring your information technology, computer programmes and platform to access our Site. You should use your own virus protection software. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You must not misuse our Site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Site, the server on which our Site is stored or any server, computer or database connected to our Site. You must not attack our Site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Site will cease immediately.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Rules about linking to our Site
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our Site in any website that is not owned by you. Our Site must not be framed on any other Site, nor may you create a link to any part of our Site other than the home page. We reserve the right to withdraw linking permission without notice. If you wish to link to or make any use of content on our Site other than that set out above, please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    We may make changes to these Terms
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We amend these Terms from time to time. Every time you wish to use our Site, please check these Terms to ensure you understand the terms that apply at that time. </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    We may make changes to our Site
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We may update and change our Site from time to time to reflect changes to our service, our Users’ needs and our business priorities. </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Law and disputes
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you are a consumer, please note that these terms, their subject matter and their formation, are governed by and construed in accordance with the laws of England and Wales. You and we both agree that the courts of England and Wales will have exclusive jurisdiction in respect of any disputes between you and NNL concerning Fluffa or arising out of or related to these Terms of Service. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you are a business, these terms, their subject matter and their formation (and any non-contractual disputes or claims) are governed by and construed in accordance with the laws of England and Wales. We both agree to the exclusive jurisdiction of the courts of England and Wales. </div>
                            </div>
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
