import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import Picker from "emoji-picker-react";

const Emoji: FC<any> = ({ message, setMessage }) => {
  const [pickerActive, setPickerActive] = useState(false);
  return (
    <div
      className="fl-direct-messages-box-input-action-single"
      onClick={() => setPickerActive(!pickerActive)}
    >
      <FontAwesomeIcon icon={faSmile} data-tip data-for="emoji" />
      <ReactTooltip id="emoji">Choose an emoji</ReactTooltip>
      <div
        className="fl-bulk__media__emoji"
        style={{
          display: pickerActive ? "block" : "none",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Picker
          pickerStyle={{ right: "-85%" }}
          onEmojiClick={(e, emoji) => {
            setMessage({
              ...message,
              text: `${message.text}${emoji.emoji}`,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Emoji;
