import React, { FC, useState } from "react";
import "../Bulk.css";
import { useHistory } from "react-router-dom";
import { SimpleCard } from "../../../../components/SimpleCard";
import Actions from "./Common/Actions";
import Checkboxes from "./Common/Checkbox";
import SelectedUser from "./Common/SelectedUser";

import { useUserContext } from "../../../../context/UserContext";

const UserSelection: FC<any> = ({ changeScreen, users, setUsers }) => {
  const [filteredUser, setFilteredUser] = useState("");

  const history = useHistory();
  const userData = useUserContext();

  const addSelected = (user: any, type: string) => {
    let newSelectedUsers = [...users.selectedUsers, user];
    switch (type) {
      case "rebill_on":
        if (users.rebill_on.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.includes(
              "rebill-on"
            )
              ? users.customOptionsSelected
              : [...users.customOptionsSelected, "rebill-on"],
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "rebill_off":
        if (users.rebill_off.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.includes(
              "rebill-off"
            )
              ? users.customOptionsSelected
              : [...users.customOptionsSelected, "rebill-off"],
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "expired":
        if (users.expired.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.includes(
              "expired"
            )
              ? users.customOptionsSelected
              : [...users.customOptionsSelected, "expired"],
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;
    }
  };

  const removeSelected = (id: any, type: string) => {
    let newSelectedUsers = users.selectedUsers.filter(
      (x: any) => x.user_id !== id
    );
    switch (type) {
      case "rebill_on":
        if (!users.rebill_on.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.filter(
              (e: any) => e !== "rebill-on"
            ),
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "rebill_off":
        if (!users.rebill_off.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.filter(
              (e: any) => e !== "rebill-off"
            ),
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "expired":
        if (!users.expired.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.filter(
              (e: any) => e !== "expired"
            ),
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;
    }
  };

  return (
    <SimpleCard className={"fl-bulk-select-users"}>
      <div
        className={`fl-bulk__title fl-bulk__title--${userData.currentTheme}`}
      >
        Send message to:
      </div>
      <div className="fl-bulk__top">
        <Checkboxes users={users} setUsers={setUsers} userData={userData} />
        <p className="fl-bulk__top__info">
          Note: You can select one or multiple options
        </p>
        <input
          className={`fl-bulk__users__search fl-bulk__users__search--${userData.currentTheme}`}
          type="text"
          value={filteredUser}
          onChange={(e) => setFilteredUser(e.target.value)}
          placeholder="Search @user"
        />
      </div>
      <div className="fl-bulk__users__list">
        {filteredUser.length === 0
          ? users.rebill_on.map((user: any) => (
              <SelectedUser
                added={users.selectedUsers.includes(user) || false}
                addSelected={() => addSelected(user, "rebill_on")}
                removeSelected={() => removeSelected(user.user_id, "rebill_on")}
                name={user.name || ""}
                handle={user.handle || ""}
                img={user.profile_image || null}
                key={user.user_id}
                userData={userData}
              />
            ))
          : users.rebill_on.map((user: any) => {
              if (
                user.handle.includes(filteredUser) ||
                user.name.includes(filteredUser)
              ) {
                return (
                  <SelectedUser
                    added={users.selectedUsers.includes(user) || false}
                    addSelected={() => addSelected(user, "rebill_on")}
                    removeSelected={() =>
                      removeSelected(user.user_id, "rebill_on")
                    }
                    name={user.name || ""}
                    handle={user.handle || ""}
                    img={user.profile_image || null}
                    key={user.user_id}
                    userData={userData}
                  />
                );
              }
            })}
        {filteredUser.length === 0
          ? users.rebill_off.map((user: any) => (
              <SelectedUser
                added={users.selectedUsers.includes(user) || false}
                addSelected={() => addSelected(user, "rebill_off")}
                removeSelected={() =>
                  removeSelected(user.user_id, "rebill_off")
                }
                name={user.name || ""}
                handle={user.handle || ""}
                img={user.profile_image || null}
                key={user.user_id}
                userData={userData}
              />
            ))
          : users.rebill_off.map((user: any) => {
              if (
                user.handle.includes(filteredUser) ||
                user.name.includes(filteredUser)
              ) {
                return (
                  <SelectedUser
                    added={users.selectedUsers.includes(user) || false}
                    addSelected={() => addSelected(user, "rebill_off")}
                    removeSelected={() =>
                      removeSelected(user.user_id, "rebill_off")
                    }
                    name={user.name || ""}
                    handle={user.handle || ""}
                    img={user.profile_image || null}
                    key={user.user_id}
                    userData={userData}
                  />
                );
              }
            })}
        {filteredUser.length === 0
          ? users.expired.map((user: any) => (
              <SelectedUser
                added={users.selectedUsers.includes(user) || false}
                addSelected={() => addSelected(user, "expired")}
                removeSelected={() => removeSelected(user.user_id, "expired")}
                name={user.name || ""}
                handle={user.handle || ""}
                img={user.profile_image || null}
                key={user.user_id}
                userData={userData}
              />
            ))
          : users.expired.map((user: any) => {
              if (
                user.handle.includes(filteredUser) ||
                user.name.includes(filteredUser)
              ) {
                return (
                  <SelectedUser
                    added={users.selectedUsers.includes(user) || false}
                    addSelected={() => addSelected(user, "expired")}
                    removeSelected={() =>
                      removeSelected(user.user_id, "expired")
                    }
                    name={user.name || ""}
                    handle={user.handle || ""}
                    img={user.profile_image || null}
                    key={user.user_id}
                    userData={userData}
                  />
                );
              }
            })}
      </div>
      <Actions
        backFn={() => history.goBack()}
        backTitle="Cancel"
        nextFn={() => changeScreen("message")}
        nextTitle="Next"
        disableButton={!users.selectedUsers.length}
      />
    </SimpleCard>
  );
};

export default UserSelection;
