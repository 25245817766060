import React, {FC} from 'react';
import { Route } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import {RouteHolder} from "../routes/RouteHolder";

export const DefaultRoute:FC<any> = (props) => {
    const userData = useUserContext();
    return (
        <React.Fragment>
            {userData.authStatus !== 'CHECKING' ? <Route {...props}/> : ''}
            {userData.authStatus === 'CHECKING' ? <RouteHolder/> : ''}
        </React.Fragment>
    )
}