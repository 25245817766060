import React, {FC, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom';
import {SimpleCard} from "../components/SimpleCard";
import ReactLoading from "react-loading";

export const RedirectOldProfile:FC<{}> = () => {

    const history = useHistory()
    const { username } = useParams<{username: string}>();
    useEffect(() => {
        history.push(username ? `/${username}` : '/404')
    },[username])
    return (
        <SimpleCard>
            <div
                className={"fl-loader-animation fl-drive-loader-animation-padding"}
            >
                <ReactLoading
                    type={"spinningBubbles"}
                    height={50}
                    width={50}
                    className="fl-spinningBubbles"
                />
            </div>
        </SimpleCard>
    )
}
