import React from 'react';
import { useUserContext } from '../context/UserContext';

import './RouteHolder.css';
import fluffaLogo from '../assets/images/fluffa-logo.png';

export const RouteHolder = () => {

    const userData = useUserContext();

    return <div className={`fl-route-holder-main fl-route-holder-main-${userData.currentTheme}`}>
        <div className="fl-route-holder-bg">
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 320">
                <path fill="#FF0D72" fillOpacity="1" d="M0,96L9.2,117.3C18.5,139,37,181,55,213.3C73.8,245,92,267,111,256C129.2,245,148,203,166,170.7C184.6,139,203,117,222,144C240,171,258,245,277,240C295.4,235,314,149,332,96C350.8,43,369,21,388,48C406.2,75,425,149,443,160C461.5,171,480,117,498,112C516.9,107,535,149,554,186.7C572.3,224,591,256,609,266.7C627.7,277,646,267,665,272C683.1,277,702,299,720,304C738.5,309,757,299,775,282.7C793.8,267,812,245,831,234.7C849.2,224,868,224,886,192C904.6,160,923,96,942,106.7C960,117,978,203,997,213.3C1015.4,224,1034,160,1052,154.7C1070.8,149,1089,203,1108,213.3C1126.2,224,1145,192,1163,170.7C1181.5,149,1200,139,1218,144C1236.9,149,1255,171,1274,154.7C1292.3,139,1311,85,1329,53.3C1347.7,21,1366,11,1385,58.7C1403.1,107,1422,213,1431,266.7L1440,320L1440,320L1430.8,320C1421.5,320,1403,320,1385,320C1366.2,320,1348,320,1329,320C1310.8,320,1292,320,1274,320C1255.4,320,1237,320,1218,320C1200,320,1182,320,1163,320C1144.6,320,1126,320,1108,320C1089.2,320,1071,320,1052,320C1033.8,320,1015,320,997,320C978.5,320,960,320,942,320C923.1,320,905,320,886,320C867.7,320,849,320,831,320C812.3,320,794,320,775,320C756.9,320,738,320,720,320C701.5,320,683,320,665,320C646.2,320,628,320,609,320C590.8,320,572,320,554,320C535.4,320,517,320,498,320C480,320,462,320,443,320C424.6,320,406,320,388,320C369.2,320,351,320,332,320C313.8,320,295,320,277,320C258.5,320,240,320,222,320C203.1,320,185,320,166,320C147.7,320,129,320,111,320C92.3,320,74,320,55,320C36.9,320,18,320,9,320L0,320Z"></path>
            </svg>
        </div>
        <div className="fl-route-holder-content">
            <img className="fl-route-holder-content-img" src={fluffaLogo} alt="Fluffa"/>
            <div className="fl-route-holder-content-tick">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
}