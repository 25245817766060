import React, {FC, useState} from "react";
import { Button } from "../../../components/Button";
import { useUserContext } from "../../../context/UserContext";
import { useModalContext } from "../../../context/ModalContext";
import moment from "moment";
import EditPlanModal from "../Modals/EditPlanModal";
import {useEnvContext} from "../../../context/EnvContext";
import Axios from "axios";
import ReactLoading from "react-loading";

const ListPlans: FC<any> = ({
                                           state,
                                           setState,
                                           requestRemoveDiscount,
                                           requestCreateDiscount,
                                           removeSubscriptionPlan,
                                           deleteSubscriptionPlan,
                                           plans,
                                           type,
                                           setAnotherButton,
                                           anotherButton,
                                           checkPriceFiled
                                       }) => {
    const userData = useUserContext();
    const modalData = useModalContext();

    return (
        <div
            className="fl-settings-sub-plans-all"
            style={{ alignItems: "start" }}
        >
            {plans.length > 0 ? (
                plans
                    .sort((a: any, b: any) =>
                        a.billing_period_count > b.billing_period_count
                            ? 1
                            : b.billing_period_count > a.billing_period_count
                                ? -1
                                : 0
                    ).sort((a: any, b: any) =>
                    a.type > b.type
                        ? 1
                        :  b.type > a.type
                            ? -1
                            : 0
                )
                    .map((x: any, y: number) => {
                        return !x.discounted_plan ? (
                            <div
                                className={`fl-settings-sub-plans-card fl-settings-sub-plans-card-${userData.currentTheme}`}
                                key={y}
                            >
                                <div
                                    className={`fl-settings-sub-plans-card-billing-period fl-settings-sub-plans-card-billing-period-${userData.currentTheme}`}
                                >
                                    {x.billing_period === "month"  ?
                                        <>
                                            {x.billing_period_count}{" "}
                                            {x.billing_period_count !== 1 ? "months" : "month"}
                                        </>
                                        :
                                        <>
                                            {x.billing_period_count}{" "} DAY TRIAL
                                        </>
                                    }
                                </div>
                                {!x.expires_at &&
                                <>
                                    <div className={`fl-settings-sub-plans-card-property`}>
                                        Price:{" "}
                                        {x.type ===  "regular" ?
                                            <span>
                                              ${x.price} (billed every{" "}
                                                {x.billing_period_count !== 12
                                                    ? x.billing_period_count * 30
                                                    : 365}{" "}
                                                days)
                                            </span>
                                            :
                                            <span className="fl-settings-sub-plans-card-trial">FREE</span>
                                        }
                                    </div>
                                    <div className="fl-settings-sub-plans-card-property">
                                        Created on:{" "}
                                        <span>{moment(x.created_at).format("D MMMM, YYYY")}</span>
                                    </div>
                                </>
                                }
                                { moment(moment(x.created_at, "DD-MM-YYYY hh:mm:ss").toDate()).format(
                                    "DD-MM-YYYY"
                                ) !== moment(moment(x.updated_at, "DD-MM-YYYY hh:mm:ss").toDate()).format(
                                    "DD-MM-YYYY"
                                ) ? (
                                    <div className="fl-settings-sub-plans-card-property">
                                        Updated on:{" "}
                                        <span>{moment(x.updated_at).format("D MMMM, YYYY")}</span>
                                    </div>
                                ) :""}
                                {x.type === "regular" &&
                                <Button
                                    type="normal"
                                    className="fl-settings-sub-security-button "
                                    style={{ width: "100%" }}
                                    onClick={() =>
                                        x.discounted_plan
                                            ? requestRemoveDiscount(
                                                Number(x.id),
                                                x.discounted_plan.id
                                            )
                                            : requestCreateDiscount(Number(x.id))
                                    }
                                >
                                    {x.discounted_plan ? "Stop discount" : "Add discount"}
                                </Button>
                                }
                                <div style={{ display: "flex" }}>
                                    <Button
                                        type="outlined"
                                        className="fl-settings-sub-security-button"
                                        style={{ width: "100%" }}
                                        onClick={() =>
                                            removeSubscriptionPlan(x.discounted_plan, x.id,x.type)
                                        }
                                    >
                                        Delete
                                    </Button>
                                    {x.type === "regular" &&
                                    <Button
                                        type="outlined"
                                        style={{width: "100%", marginLeft: "5px"}}
                                        className="fl-settings-sub-security-button"
                                        onClick={() =>
                                            modalData.push(() => (
                                                <EditPlanModal
                                                    id={x.id}
                                                    plan={x.billing_period_count}
                                                    currentPrice={x.price}
                                                    updateState={setState}
                                                    oldState={state}
                                                    checkPriceFiled={checkPriceFiled}
                                                />
                                            ))
                                        }
                                    >
                                        Edit
                                    </Button>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className={`fl-settings-sub-plans-card fl-settings-sub-plans-card-${userData.currentTheme}`} key={y}>
                                < div
                                    className={`fl-settings-sub-plans-card-billing-period fl-settings-sub-plans-card-billing-period-${userData.currentTheme}`}
                                    style={{ flexWrap: "wrap", paddingBottom: "0px" }}
                                >
                                    <p>
                                        {x.billing_period_count}{" "}
                                        {x.billing_period +
                                        (x.billing_period_count !== 1 ? "s" : "")}
                                    </p>
                                </div>
                                < div
                                    className="fl-settings-sub-plans-card-billing-period"
                                    style={{ flexWrap: "wrap" }}
                                >
                                    <p
                                        style={{
                                            margin: "0",
                                            fontSize: "10px",
                                            textAlign: "center",
                                            color:
                                                moment(x.discounted_plan?.starts_at) > moment()
                                                    ? "#ffa500"
                                                    : "#18ab18",
                                        }}
                                    >
                                        {moment(x.discounted_plan?.starts_at) > moment()
                                            ? `${
                                                x.discounted_plan.amount
                                            }% discount scheduled for ${moment(
                                                x.discounted_plan?.starts_at
                                            ).format("D MMMM, YYYY")}`
                                            : `${x.discounted_plan.amount}% discount currently active`}
                                    </p>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    DISCOUNT STARTS:{" "}
                                    <span>
                                      {moment(x.discounted_plan?.starts_at).format(
                                          "D MMMM, YYYY"
                                      )}
                                    </span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    DISCOUNT ENDS:{" "}
                                    <span>
                                      {moment(x.discounted_plan?.ends_at).format(
                                          "D MMMM, YYYY"
                                      )}
                                    </span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Duration:{" "}
                                    <span>
                                      {moment(x.discounted_plan.ends_at).diff(
                                          x.discounted_plan.starts_at,
                                          "days"
                                      )}{" "}
                                        {moment(x.discounted_plan.ends_at).diff(
                                            x.discounted_plan.starts_at,
                                            "days"
                                        ) > 1
                                            ? "days"
                                            : "day"}
                                 </span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Original price: <span>${x.price}</span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Discount: <span>{x.discounted_plan.amount}%</span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Current discounted price:{" "}
                                    <span>${x.discounted_plan.new_price.toFixed(2)}</span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Created on:{" "}
                                    <span>
                                  {moment(x.discounted_plan?.created_at).format(
                                      "D MMMM, YYYY"
                                  )}
                                 </span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Available:{" "}
                                    <span>{x.discounted_plan?.discounts_available === 0 || x.discounted_plan?.discounts_available === null ||  x.discounted_plan?.discounts_available === undefined  ? "UNLIMITED" : x.discounted_plan?.discounts_available}</span>
                                </div>
                                <div className="fl-settings-sub-plans-card-property">
                                    Claimed:{" "}
                                    <span>
                                  {x.discounted_plan?.number_of_discounts -
                                  x.discounted_plan?.discounts_available || 0}
                                </span>
                                </div>
                                <Button
                                    type="normal"
                                    className="fl-settings-sub-security-button"
                                    style={{ width: "100%" }}
                                    onClick={() =>
                                        requestRemoveDiscount(Number(x.id), x.discounted_plan?.id)
                                    }
                                >
                                    Stop discount
                                </Button>
                                <Button
                                    type="outlined"
                                    className="fl-settings-sub-security-button"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        x.discounted_plan
                                            ? modalData.push(() => <DeleteDiscountedPlanModal deleteSubscriptionPlan={deleteSubscriptionPlan} idPlan={Number(x.id)} /> )
                                            : deleteSubscriptionPlan(Number(x.id));
                                    }}
                                >
                                    Delete plan
                                </Button>
                            </div>
                        );
                    })
            ) : (
                <p>
                    You haven't created any paid plans yet. Please create a new plan by
                    following the steps below.
                </p>
            )}
            {plans.length > 0 && plans.length < 3 && !anotherButton &&
                <div className={`fl-settings-sub-plans-card-new-button`}>
                    <Button
                        type="normal"
                        className="fl-settings-sub-security-button"
                        onClick={() => setAnotherButton(true)}
                    >
                        Create another plan +
                    </Button>
                </div>
            }
        </div>
    );
};

export default ListPlans;

export const RemoveSubscribeModal: FC<any> = ({ id,type,setState,state}) => {

    const userData = useUserContext();
    const envData = useEnvContext()
    const [animationDelete,setAnimationDelete] = useState(false)

    const removeFunction = async (id: number) => {
        setAnimationDelete(true)
        try {
            await Axios.delete(
                `${envData.apiUrl}/subscription-plans/${id}`
            );
            setState({
                ...state,
                plans: state.plans.filter((x: any) => x.id !== id),
            });
            userData.setSubscriptions("remove", id)
            await modalData.close();
            setAnimationDelete(false)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimationDelete(false)
        }
    };

    const modalData = useModalContext()
    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{type === "trial" ? "End free trial" : "Delete paid plan"}</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                Are you sure you want to {type === "trial" ? "end" : "delete"} this {type === "trial" ? "free trial" : "paid plan"}?
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}> {type === "trial" ? "No" : "Cancel"}</Button>
                <Button type="normal" onClick={() => removeFunction(Number(id))} disabled={animationDelete}>
                    {type === "trial" ? "Yes" :
                        <>
                            Delet{animationDelete ? "ing" : "e"}
                        </>
                    }
                    {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </React.Fragment>
    );
};

export const DeleteDiscountedPlanModal: FC<any> = ({deleteSubscriptionPlan,idPlan}) => {

    const modalData = useModalContext()
    const [animationDelete, setAnimationDelete] = useState(false)

    const deleteFunction = async () => {
        setAnimationDelete(true)
        await deleteSubscriptionPlan(idPlan)
        setAnimationDelete(false)
    }

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Delete discounted plan</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                There's currently an active discount on this plan, if you delete the plan it will end your discount now.
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}> Keep plan</Button>
                <Button type="normal" onClick={() => deleteFunction()} disabled={animationDelete}>
                    Delet{animationDelete ? "ing" : "e"} plan
                    {animationDelete ?
                        <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"}
                                      height={20} width={20}/> : ""}
                </Button>
            </div>
        </React.Fragment>
    );
}