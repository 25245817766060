import React, { FC, Fragment, useState } from 'react'
import { Button } from '../components/Button';
import { useUserContext } from '../context/UserContext';
import './Contact.css';

// ASSETS
import imageSource from '../assets/images/5.png'
import { MInput } from '../components/MInput';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import validator from 'validator';
import { additionalEmailCheck} from './Forgot';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactLoading from 'react-loading';
import axios from "axios";

export type stringKeys<Type> = { // we can use this in other components if we need to use something like  state[property] when state is an object
    [key: string]: Type
}

export const Contact:FC<any> = (props) => {

    const [state, setState] = useState<stringKeys<string>>({
        name: '',
        email: '',
        subject: '',
        body: '',
    })

    const [form, setForm] = useState(true);
    const [proccessing, setProccessing] = useState(false);
    const [forcedErrors, setForcedErrors] = useState(false);

    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext()

    const processContact = async() => {
        setProccessing(true)
        axios.post(`${envData.apiUrl}/contact`, {
            name:state.name,
            email:state.email,
            subject:state.subject,
            body:state.body
        }).then((res: any) => {
            setProccessing(false)
            setForm(false)
        }).catch((error) => {
            setProccessing(false)
            modalData.pushToast("error",  `Something went wrong!`)
        })
    }

    const checkForm = async (field:string) => {
        if(validator.isEmpty(state[field])) {
            setForcedErrors(true);
        }
        else if(!validator.isEmail(state.email) && !additionalEmailCheck(state.email)) {
            setForcedErrors(true);
        } else {
            setForcedErrors(false);
        }
    }

    const disableButton = () => {

        for(const property in state) {
            if(validator.isEmpty(state[property])) {
                return true;
            }
        }
        if(!(validator.isEmail(state.email) && additionalEmailCheck(state.email))) {
            return true;
        }
        return false;
    }

    const clearForm = () => {
        setForm(true)
        setState({...state,name:"",email:"",body:"",subject:""})
    }
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={`${!isMobile ? "fl-fullscreen-with-footer" : ""} fl-contact-form  ${userData.authStatus === 'UNAUTHENTICATED' ? "fl-contact-form-guest" : ""}`}>
            <div className="fl-fullscreen-with-footer-inner fl-container">
                <div className="fl-fullscreen-with-footer-bg">
                    <div className="fl-fullscreen-with-footer-circle-orange"></div>
                    <div className="fl-fullscreen-with-footer-circle-pink"></div>
                </div>
                <div className="fl-fullscreen-with-footer-content">
                    <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
                        {form ? <Fragment>
                            <div className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}>Got a question?<br/>Contact Us.</div>
                            
                                <div onBlur={() => checkForm('name')}>
                                    <MInput forceErrors={forcedErrors} label="Name" value={state.name} setValue={(name) => setState({...state, name})}
                                        validator={[
                                            {check: (v) => !validator.isEmpty(v), message: 'Enter a name'},
                                        ]}
                                    />
                                </div>
                                <div onBlur={() => checkForm('email')} className={"fl-settings-mt-30"}>
                                    <MInput forceErrors={forcedErrors}  label="Email" value={state.email} setValue={(email) => setState({...state, email})}
                                        validator={[
                                            {check: (v) => !validator.isEmpty(v), message: 'Enter an e-mail address'},
                                            {check: (v) => { return validator.isEmail(v) && additionalEmailCheck(v)}, message: 'Enter a valid e-mail address'},

                                        ]}
                                    />
                                </div>
                            
                            <div onBlur={() => checkForm('subject')} className={"fl-settings-mt-30"}>
                                <MInput forceErrors={forcedErrors} label="Subject" value={state.subject} setValue={(subject) => setState({...state, subject})}
                                    validator={[
                                        {check: (v) => !validator.isEmpty(v), message: 'Enter a subject'},
                                    ]}
                                />
                            </div>
                            <div onBlur={() => checkForm('body')} className={"fl-settings-mt-30 fl-contact-form"}>
                                <MInput forceErrors={forcedErrors} type="textarea" label="Message" value={state.body} setValue={(body) => setState({...state, body})}
                                    validator={[
                                        {check: (v) => !validator.isEmpty(v), message: 'Enter a message'},
                                    ]}
                                />
                            </div>
                            
                            <Button disabled={disableButton() || proccessing} style={{display: 'inline-block', margin: "10px 0px", width: "calc(50% - 10px)", position: 'relative'}} inputType="normal" type="normal" onClick={processContact}>
                                SEND MESSAGE
                                {!proccessing ||
                                    <span style={{position: 'absolute', marginLeft: '10px'}}>
                                        <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>
                                    </span>
                                }
                            </Button>
                            

                        </Fragment> :
                        <Fragment>
                        <div className="fl-col-12 fl-fullscreen-with-footer-content-part">
                            <div className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}>Message sent<FontAwesomeIcon style={{color: "#53A451", marginLeft: '15px'}} icon={faCheck}/></div>
                            <div className="fl-forgot-mb">Thanks for getting in touch. You will receive an e-mail reply ASAP.</div>
                            <Button style={{margin: "10px 0px", width: "calc(50% - 5px)"}} type="normal" onClick={() => clearForm()}>DONE</Button>
                            <div className="fl-fullscreen-with-footer-content-link-text">*If you can't find it, check your spam folder</div>
                        </div> 
                    </Fragment>}
                    </div>
                    <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-fulscreen-with-footer-content-part-image">
                        <img className="fl-fullscreen-with-footer-content-image" src={imageSource} alt="Contact"/>
                    </div>
                </div>
            </div>
        </div>
    )
}