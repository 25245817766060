import React, { FC } from "react";
import {Link, useHistory} from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import "./SupportBox.css";
import { SimpleCard } from "./SimpleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

export const SupportBox: FC<{ side: "left" | "right" }> = (props) => {
  // CONTEXT
  const userData = useUserContext();
  const history = useHistory()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
    <SimpleCard className="fl-support-box">
      <FontAwesomeIcon onClick={() => history.push('/support')} className={`fl-support-icon fl-support-icon-${userData.currentTheme}`} icon={faHeadset} />
      <Link
        to="/support"
        className={`fl-support-text fl-support-text-${userData.currentTheme}`}
      >
        Get Support
      </Link>
      {!isMobile ?
          <Link
              to="/support/report-bug"
              className={`fl-support-bug fl-support-bug-${props.side} fl-support-bug-${userData.currentTheme}`}
              data-place="left"
              data-tip
              data-for="bug"
              style={{ right: userData.paddingBody ? `${Number(userData.paddingBody) + 5}px` : ""}}
          >
            <FontAwesomeIcon className="fl-support-bug-icon" icon={faBug} />
            <ReactTooltip id="bug">Report a bug</ReactTooltip>
          </Link> : ""
      }
    </SimpleCard>
  );
};
