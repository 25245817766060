import React, { FC } from "react";
import "./SingleNotification.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { useUserContext } from "../../context/UserContext";
import {CheckTime} from "../HandleTime/CheckTime";
import moment from "moment";

const Type: FC<any> = ({
  iconClass,
  icon,
  color,
  iconSrc,
  iconSrcAlt,
  withImg = false,
  title,
  description,
  descRight = false,
  link = null,
  linkText = null,
  created_at
}) => {
  const userData = useUserContext();

  const year = () => {
    if( moment(created_at).format("YYYY") ===  moment(new Date()).subtract(1, "years").format("YYYY")) {
        return false
    } else {
        return true
    }
  }

  return (
    <>
      <div
        className={`fl-singlenotification__icon ${iconClass} fl-singlenotification__icon-${year() ? "center" : "start"}`}>
        <FontAwesomeIcon icon={icon} color={color} />
        {iconSrc ? (<img width="30" src={iconSrc} alt={iconSrcAlt} />) : ("")}
      </div>
       <span className={"fl-notification-date"}><CheckTime data={created_at} /></span>
      <div
        className={
          withImg
            ? "fl-singlenotification__content__withImg"
            : "fl-singlenotification__content"
        }
      >
        <div
          className={`fl-singlenotification__content__title fl-singlenotification__content__title--${userData.currentTheme}`}
        >
          {title}
        </div>
        <div className="fl-singlenotification__content__description">
          {!descRight && description}
          {link && (
            <Link onClick={(e) => e.stopPropagation()} to={link}>
              {linkText}
            </Link>
          )}{" "}
          {descRight && description}
        </div>
      </div>
    </>
  );
};

export default Type;
