import React, {FC, useEffect, useRef} from "react";
import { useUserContext } from "../../../context/UserContext";
import ListTrialPlans from "./ListTrialPlans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGift} from "@fortawesome/free-solid-svg-icons";

const AllSubscriptionPlansTrial: FC<any> = ({
                                           state,
                                           setState,
                                           requestRemoveDiscount,
                                           requestCreateDiscount,
                                           removeSubscriptionPlan,
                                           deleteSubscriptionPlan,
                                           expired
                                       }) => {
    const userData = useUserContext();

    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollBottom = () => {
        if(localStorage.getItem("scrollPage") === "promotions")
        {
            scrollRef?.current?.scrollIntoView();
            localStorage.removeItem("scrollPage")
        }
    }

    useEffect(() => {
        scrollBottom()
    }, [localStorage.getItem("scrollPage")]);

    return (
       <>
           <div className="fl-settings-sub-section fl-settings-mt-15">
               <div
                   className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
               >
                   Free trials {" "}
                   <FontAwesomeIcon className={"fl-settings-plan-gift"} icon={faGift} />
               </div>
               <ListTrialPlans  scrollRef={scrollRef} type="live" plans={state.plans.filter((x:any) => x.type === "trial")} state={state} setState={setState} requestRemoveDiscount={requestRemoveDiscount} requestCreateDiscount={requestCreateDiscount} removeSubscriptionPlan={removeSubscriptionPlan} deleteSubscriptionPlan={deleteSubscriptionPlan}/>
           </div>
       </>
    );
};

export default AllSubscriptionPlansTrial;

