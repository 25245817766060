import { faChevronCircleLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React, { FC, Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import { MInput } from "../components/MInput";
import validator from "validator";
import axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { faAmazon, faInstagram, faRedditAlien, faSnapchatGhost, faTiktok, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { ObjectHelper } from "../Helpers/ObjectHelper";
import '../components/CountryFlags/css/flag-icons.css'
import ReactLoading from "react-loading";

export const SettingsProfile: FC<any> = (props) => {
    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory();

    const [countries, setCountries] = useState<
        Array<{ category: string; options: Array<any> }>
        >([]);
    const [nationalities, setNationalities] = useState<
        Array<{ category: string; options: Array<any> }>
        >([]);

    const [profileState, setProfileState] = useState({
        website_url: '',
        bio: '',
        nationality_id: '',
        location: '',
        age: '',
        social_accounts: {
            twitter: {
                connected: '',
                name: '',
            }
        },
        social_links: {
            instagram: " ",
            twitter: "",
            amazon: "",
            reddit: "",
            youtube: "",
            tiktok: "",
            snap: ""
        },
    });

    const [profileStateUpdate, setProfileStateUpdate] = useState({
        website_url: "",
        bio: "",
        nationality_id: "",
        location: '',
        age: "",
        social_accounts: {
            twitter: {
                connected: false,
                name: '',
            }
        },
        social_links: {
            instagram: "",
            twitter: "",
            amazon: "",
            reddit: "",
            youtube: "",
            tiktok: "",
            snap: ""
        },
    });

    const [generalState, setGeneralState] = useState({
        name: "",
        handle: "",
    });

    const [validUrl, setValidUrl] = useState(false);
    const [validUrlYoutube, setValidUrlYoutube] = useState(false);
    const [validUrlAmazon, setValidUrlAmazon] = useState(false);
    const [bioResrticted, setBioRestricted] = useState(false);
    const [bioRestrictedArray, setBioRestrictedArray] = useState([])
    const [webResrticted, setWebRestricted] = useState(false);
    const [youtubeResrticted, setYoutubeRestricted] = useState(false);
    const [amazonResrticted, setAmazonRestricted] = useState(false);



    useEffect(() => {
        getAccountData();
        getNationalities();
    }, []);


    const deleteAfter = (event: any,max: Number) => {
        if(event.target.value.length > max) {
            setProfileState({...profileState, location: event.target.value.slice(0,-1)});
        }
    }

    const disableProfile = () => {
        return ObjectHelper.deepEqual(profileState, profileStateUpdate);
    }

    const disableSaveButton = () => {
        if(profileState?.bio?.replace(/ /g, '').length !== profileStateUpdate?.bio?.replace(/ /g, '').length ||
            profileState?.location?.replace(/ /g, '').length !== profileStateUpdate?.location?.length ||
            profileState?.social_links?.instagram?.replace(/ /g, '').length !== profileStateUpdate?.social_links?.instagram?.replace(/ /g, '').length ||
            profileState?.social_links?.twitter?.replace(/ /g, '').length !== profileStateUpdate?.social_links?.twitter?.replace(/ /g, '').length ||
            profileState?.social_links?.reddit?.replace(/ /g, '').length !== profileStateUpdate?.social_links?.reddit?.replace(/ /g, '').length ||
            profileState?.social_links?.youtube?.replace(/ /g, '').length !== profileStateUpdate?.social_links?.youtube?.replace(/ /g, '').length ||
            profileState?.social_links?.tiktok?.replace(/ /g, '').length !== profileStateUpdate?.social_links?.tiktok?.replace(/ /g, '').length ||
            profileState?.social_links?.snap?.replace(/ /g, '').length !== profileStateUpdate?.social_links?.snap?.replace(/ /g, '').length
        ) {
            return false
        } else {
            return true
        }
    }

    const [animation,setAnimation] = useState(true)

    const getAccountData = async () => {
        try {
            const res = await axios.get(`${envData.apiUrl}/auth/profile`);
            setProfileState((prevState) => {return {
                ...prevState,
                age: res.data.data.profile.age ?? '',
                bio: res.data.data.profile.bio ?? '',
                website_url: res.data.data.profile.website_url ?? '',
                location: res?.data?.data?.profile?.location ?? '',
                social_accounts: {
                    twitter: {
                        connected: res.data.data?.twitter_id ?? false,
                        name: res.data.data?.twitter_name ?? '',
                    }
                },
                nationality_id: res?.data?.data?.profile?.nationality?.id ?? '',
                social_links: {
                    instagram: res?.data?.data?.profile?.social_links?.instagram ?? "",
                    twitter: res?.data?.data?.profile?.social_links?.twitter ?? "",
                    amazon: res?.data?.data?.profile?.social_links?.amazon ?? "",
                    reddit: res?.data?.data?.profile?.social_links?.reddit ?? "",
                    youtube: res?.data?.data?.profile?.social_links?.youtube ?? "",
                    tiktok: res?.data?.data?.profile?.social_links?.tiktok ?? "",
                    snap: res?.data?.data?.profile?.social_links?.snap ?? ""
                },

            }});
            setGeneralState({ name: res.data.data.name, handle: res.data.data.handle });
            setProfileStateUpdate((prevState) => {return {
                ...prevState,
                age: res.data.data.profile.age ?? '',
                bio: res.data.data.profile.bio ?? '',
                website_url: res.data.data.profile.website_url ?? '',
                location: res?.data?.data?.profile?.location ?? '',
                social_accounts: {
                    twitter: {
                        connected: res.data.data?.twitter_id ?? false,
                        name: res.data.data?.twitter_name ?? '',
                    }
                },
                nationality_id: res?.data?.data?.profile?.nationality?.id ?? '',
                social_links: {
                    instagram: res?.data?.data?.profile?.social_links?.instagram ?? "",
                    twitter: res?.data?.data?.profile?.social_links?.twitter ?? "",
                    amazon: res?.data?.data?.profile?.social_links?.amazon ?? "",
                    reddit: res?.data?.data?.profile?.social_links?.reddit ?? "",
                    youtube: res?.data?.data?.profile?.social_links?.youtube ?? "",
                    tiktok: res?.data?.data?.profile?.social_links?.tiktok ?? "",
                    snap: res?.data?.data?.profile?.social_links?.snap ?? ""
                }
            }});
            setAnimation(false)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimation(false)
        }
    };

    const getNationalities = async () => {
        const res = await axios.get(`${envData.apiUrl}/nationalities`);
        const all = res.data.data;

        const mostPopular1 = all
            .filter((x: { id: number; name: string; cca2: string}, y: number) =>
                ["British", "American"].includes(x.name)
            )
            .map((x: { id: number; name: string; cca2: string}, y: number) => ({
                value: x.id,
                label: x.name,
                code: x.cca2
            }));

        const mostPopular2 = all
            .filter((x: { id: number; name: string; cca2: string}, y: number) =>
                ["Australian", "Canadian", "French", "German", "Italian", "Spanish", "Swedish", "Russian"].includes(x.name)
            )
            .map((x: { id: number; name: string; cca2: string}, y: number) => ({
                value: x.id,
                label: x.name,
                code: x.cca2,
            }));

        const allOptions = all.map(
            (x: { id: number; name: string; cca2: string}, y: number) => ({
                value: x.id,
                label: x.name,
                code: x.cca2,
            })
        );
        setNationalities([
            { category: "Most Popular", options: [...mostPopular1, ...mostPopular2] },
            { category: "All Nationalities", options: allOptions }
        ]);
    }

    const [animationSave,setAnimationSave] = useState(false)
    const saveProfile = async () => {
        setAnimationSave(true)
        const errorsArray = [];

        if(profileState?.website_url?.includes("onlyfans")) {
            errorsArray.push("Restricted URL");
            setWebRestricted(true);
        }

        if(profileState?.social_links?.amazon?.includes("onlyfans")) {
            errorsArray.push("Restricted URL");
            setAmazonRestricted(true);
        }


        if(profileState?.social_links?.youtube?.includes("onlyfans")) {
            errorsArray.push("Restricted URL");
            setYoutubeRestricted(true);
        }

        if(profileState.website_url !== null  && profileState.website_url !== "" && !validator.isURL(profileState.website_url) )  {
            errorsArray.push("Invalid Website URL");
            setValidUrl(true);
        }else {
            setValidUrl(false);
        }

        if(profileState.social_links.youtube !== null && profileState.social_links.youtube !== "" &&  !validator.isURL(profileState.social_links.youtube)) {
            errorsArray.push("Invalid Website URL");
            setValidUrlYoutube(true);
        }else {
            setValidUrlYoutube(false);
        }

        if(profileState.social_links.amazon !== null && profileState.social_links.amazon !== "" &&  !validator.isURL(profileState.social_links.amazon)) {
            errorsArray.push("Invalid Website URL");
            setValidUrlAmazon(true);
        }else {
            setValidUrlAmazon(false);
        }

        if (errorsArray.length > 0) {
            return;
            setAnimationSave(false)
        } else {
            await axios.put(`${envData.apiUrl}/users/profile`, profileState).then( async (res) => {
                setValidUrl(false);
                modalData.pushToast("success", "Profile updated");
                localStorage.setItem("removeAnimation", "remove");
                history.push(`/${userData.user.handle}`);
                setAnimationSave(false)
            }).catch((err) => {
                setAnimationSave(false)
                const errorsArrayUrl = [];

                errorsArrayUrl.push(err?.response?.data?.errors);
                if(errorsArrayUrl.some((element) => {return (element?.website_url?.includes("Invalid domain name!"))})) {
                    setValidUrl(true);
                }else {
                    setValidUrl(false);
                }

                if(errorsArrayUrl.some((element) => {return (element?.youtube?.includes("Invalid domain name!"))})) {
                    setValidUrlYoutube(true);
                }else {
                    setValidUrlYoutube(false);
                }

                if(errorsArrayUrl.some((element) => {return (element?.amazon?.includes("Invalid domain name!"))})) {
                    setValidUrlAmazon(true);
                }else {
                    setValidUrlAmazon(false);
                }

                if(errorsArrayUrl.some((element) => {return (element?.bio?.includes("Contains invalid words."))})) {
                    setBioRestricted(true);
                }else {
                    setBioRestricted(false);
                }
                if (err?.response?.data?.errors?.restricted_words?.length > 0) {
                    setBioRestricted(true);
                    setBioRestrictedArray(err?.response?.data?.errors?.restricted_words)
                } else {
                    setBioRestricted(false)
                    setBioRestrictedArray([])
                }
            })
        }
    };

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    const clearProfile = () => {
        // @ts-ignore
        setProfileState(profileStateUpdate)
    }

    return (
        <>
            {animation &&
            <div className="fl-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                          <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                          </span>
                        Profile
                    </div>
                </div>
                <div className="fl-settings-sub-section fl-settings-mt-30">
                    <Fragment>
                        <MInput
                            type="select"
                            className={"fl-settings-mt-30"}
                            label="Age"
                            value={profileState.age}
                            setValue={(age) => {
                                setProfileState({ ...profileState, age });
                            }}
                            options={[
                                {
                                    category: "Age",
                                    options: Array.from({ length: 82 }, (_, i) => i + 18).map(
                                        (x, y) => ({ label: `${x}`, value: x })
                                    ),
                                },
                            ]}
                        />
                        <div className="fl-file-picker-label regular-text">
                            <span>NOTE: This is the age which will show on your profile</span>
                        </div>
                    </Fragment>
                    <MInput
                        preRender={(x) => (
                            <div className={`fl-settings-sub-verification-country-img flag-icon-background flag-icon-${x.code.toLowerCase()}`}>
                            </div>
                        )}
                        className={"fl-settings-mt-20"}
                        type="select"
                        label="Nationality"
                        value={profileState.nationality_id}
                        setValue={(nationality_id) => {
                            setProfileState({ ...profileState, nationality_id });
                        }}
                        options={nationalities}
                    />
                    {/*<div onChange={(event: React.FormEvent<HTMLInputElement>) => {deleteAfter(event, 30)}}>*/}
                    <MInput
                        className={"fl-settings-mt-30"}
                        label="Location"
                        maxLength={30}
                        validator={[{
                            check: (v) =>
                                validator.isLength(v, {min: 0, max: 30}),
                            message: 'Location must be 30 or less characters long.'
                        }]}
                        value={profileState.location}
                        setValue={(location) => {
                            // updateCountry(location);
                            setProfileState({ ...profileState, location: location });
                        }}
                        options={countries}
                    />
                    <MInput
                        type="textarea"
                        label="Bio"
                        value={profileState.bio}
                        setValue={(bio) => {
                            setProfileState({ ...profileState, bio: bio });
                        }}
                        className={"fl-settings-mt-30 fl-settings-account-textarea"}
                        maxLength={1000}
                        validator={[{
                            check: (v) =>
                                validator.isLength(v, {min: 0, max: 1000}),
                            message: 'Bio must be 1000 or less characters long.'
                        }]}
                    />
                    {bioResrticted && (
                        <span className="fl-modal-description-row-error">
                        <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {bioRestrictedArray + ""}
                      </span>
                    )}
                    <div className="fl-settings-sub-account-field fl-settings-mt-30">
                        <div className="fl-settings-sub-account-field-label">
                            Social Channels
                        </div>
                        <div className="fl-settings-sub-account-field-row fl-settings-mt-15 ">
                            <a
                                href={profileState?.social_links?.instagram}
                                className="fl-settings-sub-account-field-row-item"
                                style={{
                                    background:
                                        "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                                }}
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <Input
                                className="fl-settings-sub-account-field-input "
                                value={profileState?.social_links?.instagram || ""}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setProfileState({
                                        ...profileState,
                                        social_links: {
                                            ...profileState.social_links,
                                            instagram: e.currentTarget.value,
                                        },
                                    });
                                }}
                                placeholder="@username"
                            />
                        </div>
                        <div className="fl-settings-sub-account-field-row fl-settings-mt-15 ">
                            <a
                                href={profileState?.social_links?.twitter}
                                className="fl-settings-sub-account-field-row-item"
                                style={{ background: "linear-gradient(#47baff, #74c7ff)" }}
                            >
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <Input
                                className="fl-settings-sub-account-field-input"
                                value={profileState?.social_links?.twitter || ""}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setProfileState({
                                        ...profileState,
                                        social_links: {
                                            ...profileState.social_links,
                                            twitter: e.currentTarget.value,
                                        },
                                    });
                                }}
                                placeholder="@username"
                            />
                        </div>
                        <div className="fl-settings-sub-account-field-row fl-settings-mt-15 ">
                            <a
                                href={profileState?.social_links?.reddit}
                                className="fl-settings-sub-account-field-row-item"
                                style={{ background: "linear-gradient(rgb(255, 113, 0), rgb(212, 71, 71))" }}
                            >
                                <FontAwesomeIcon icon={faRedditAlien} />
                            </a>
                            <Input
                                className="fl-settings-sub-account-field-input"
                                value={profileState?.social_links?.reddit || ""}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setProfileState({
                                        ...profileState,
                                        social_links: {
                                            ...profileState.social_links,
                                            reddit: e.currentTarget.value,
                                        },
                                    });
                                }}
                                placeholder="@username"
                            />
                        </div>
                        <div className="fl-settings-sub-account-field-row fl-settings-mt-15 ">
                            <a
                                href={profileState?.social_links?.snap}
                                className="fl-settings-sub-account-field-row-item"
                                style={{ background: "linear-gradient(#ffeb3b, #c7b82e)" }}
                            >
                                <FontAwesomeIcon icon={faSnapchatGhost} />
                            </a>
                            <Input
                                className="fl-settings-sub-account-field-input"
                                value={profileState?.social_links?.snap || ""}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setProfileState({
                                        ...profileState,
                                        social_links: {
                                            ...profileState.social_links,
                                            snap: e.currentTarget.value,
                                        },
                                    });
                                }}
                                placeholder="@username"
                            />
                        </div>
                        <div className="fl-settings-sub-account-field-row fl-settings-mt-15 ">
                            <a
                                href={profileState?.social_links?.youtube}
                                className="fl-settings-sub-account-field-row-item"
                                style={{ background: "linear-gradient(rgb(255 0 0), rgb(199 18 18))" }}
                            >
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <Input
                                className="fl-settings-sub-account-field-input"
                                value={profileState?.social_links?.youtube || ""}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setProfileState({
                                        ...profileState,
                                        social_links: {
                                            ...profileState.social_links,
                                            youtube: e.currentTarget.value,
                                        },
                                    });
                                }}
                                placeholder="https://www.youtube.com/channel/UU..."
                            />
                        </div>
                        {validUrlYoutube && (
                            <>
                              <span className="fl-modal-description-row-error">
                                   <FontAwesomeIcon icon={faInfoCircle} /> Must be a valid URL
                              </span> <br />
                            </>
                        )}
                        {youtubeResrticted && (
                           <span className="fl-modal-description-row-error">
                            <FontAwesomeIcon icon={faInfoCircle} /> Restricted URL
                          </span>
                        )}
                        <div className="fl-settings-sub-account-field-row fl-settings-mt-15 ">
                            <a
                                href={profileState?.social_links?.amazon}
                                className="fl-settings-sub-account-field-row-item"
                                style={{ background: "linear-gradient(#ff7100, #d44747)" }}
                            >
                                <FontAwesomeIcon icon={faAmazon} />
                            </a>
                            <Input
                                className="fl-settings-sub-account-field-input"
                                value={profileState?.social_links?.amazon || ""}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    setProfileState({
                                        ...profileState,
                                        social_links: {
                                            ...profileState.social_links,
                                            amazon: e.currentTarget.value,
                                        },
                                    });
                                }}
                                placeholder="https://a.co/..."
                            />
                        </div>
                        {validUrlAmazon && (
                            <>
                             <span className="fl-modal-description-row-error">
                                <FontAwesomeIcon icon={faInfoCircle} /> Must be a valid URL
                              </span> <br />
                            </>
                        )}
                        {amazonResrticted && (
                            <span className="fl-modal-description-row-error">
                <FontAwesomeIcon icon={faInfoCircle} /> Restricted URL
              </span>
                        )}
                    </div>
                    <div className="fl-settings-sub-account-field-row fl-settings-mt-15">
                        <a
                            href={profileState?.social_links?.tiktok}
                            className="fl-settings-sub-account-field-row-item"
                            style={{ background: "linear-gradient(rgb(7 15 56), rgb(0 10 47))" }}
                        >
                            <FontAwesomeIcon icon={faTiktok} />
                        </a>
                        <Input
                            className="fl-settings-sub-account-field-input"
                            value={profileState?.social_links?.tiktok || ""}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                setProfileState({
                                    ...profileState,
                                    social_links: {
                                        ...profileState.social_links,
                                        tiktok: e.currentTarget.value,
                                    },
                                });
                            }}
                            placeholder="@username"
                        />
                    </div>
                    <div className={"fl-d-flex fl-flex-direction-column"}>
                        <Button
                            type="outlined"
                            className="fl-settings-sub-security-button"
                            onClick={() => clearProfile()}
                            disabled={disableProfile() || animationSave}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="normal"
                            className="fl-settings-sub-security-button fl-responsive-ml-10"
                            onClick={saveProfile}
                            disabled={disableSaveButton() || animationSave}
                        >
                            Save
                            {animationSave && <ReactLoading  className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
