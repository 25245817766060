import {
  faChevronLeft,
  faChevronRight,
  faExpand,
  faSearchMinus,
  faSearchPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Toast } from "../components/Toast";
import { useAsyncState } from "../hooks/useAsyncState";
import { useUserContext } from "./UserContext";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactPlayer from "react-player";
import {Button} from "../components/Button";
import ReactLoading from "react-loading";
import VideoPlayer from "../components/video/VideoPlayer";


type modalType =
  | {
      title: string;
      description: Array<string>;
      buttons: Array<() => React.ReactNode>;
      warnings?: Array<string>;
      copyButton?:string;
    }
  | (() => React.ReactNode);

type toastType = {
  type:
    | "warning"
    | "success"
    | "error"
    | "info"
    | "deleted"
    | "disabled"
    | "reactivated"
    | "deactivated"
    | "resend"
    | "money_out"
    | "money_in";
  text: string;
};

export interface ModalContextData {
  toggledImage: string | null;
  windows: Array<modalType>;
  disableKeyDown:boolean;
  toasts: Array<toastType>;
  state: string;
  push: (x: modalType, disableKey?:any) => void;
  pushToast: (
    type:
      | "warning"
      | "success"
      | "error"
      | "info"
      | "deleted"
      | "disabled"
      | "reactivated"
      | "deactivated"
      | "resend"
      | "money_out"
      | "money_in",
    text: string | ReactNode
  ) => void;
  clearToasts: () => void;
  close: () => void;
  closeImage: () => void;
  closeAll: () => void;
  disableScroll: (disable: boolean) => void;
  expandedImage: null | string;
  expandImage: (x: string) => void;
  closeExpandedImage: () => void;
  toggleImage: (x: string | null) => void;
  toggleImageDm: (x: any, y: number, fullScreen?:boolean) => void;
  fullScreen: (x: string | null, index: number | null) => void;
  closeFullScreen: () => void;
  prevFullScreenImage: () => void;
  nextFullScreenImage: () => void;
  fullScreenStatus: boolean;
}

export const modalContextDefaultValues: ModalContextData = {
  toggledImage: null,
  windows: [],
  disableKeyDown:false,
  toasts: [],
  state: "closed",
  push: () => <React.Fragment></React.Fragment>,
  pushToast: () => void 1,
  clearToasts: () => void 1,
  close: () => void 0,
  closeImage: () => void 0,
  closeAll: () => void 0,
  disableScroll: (disable: boolean) => void 0,
  expandedImage: null,
  fullScreenStatus:false,
  expandImage: (x) => void 0,
  closeExpandedImage: () => void 0,
  toggleImage: () => void 0,
  toggleImageDm: () => void 0,
  fullScreen: () => void 0,
  closeFullScreen: () => void 0,
  prevFullScreenImage: () => void 0,
  nextFullScreenImage: () => void 0,
};

const ModalContext = React.createContext<ModalContextData>(
  modalContextDefaultValues
);

export const useModalContext = () => useContext(ModalContext);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: FC<ModalProviderProps> = (props) => {
  const [currentModalData, setCurrentModalData] = useAsyncState<modalType>({
    title: "",
    description: [],
    warnings: [],
    buttons: [],
    copyButton:""
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const lockRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useAsyncState<any>({
    toggledImage: null,
    windows: [],
    disableKeyDown:false,
    toasts: [],
    state: "closed",
    expandedImage: null,
    fullScreenStatus:false

  });
  
  const [stateFilesDm, setStateFilesDm] = useAsyncState<any>({
    files: [],
    currentIndex: null,
    fullScreen:true
  });

  const [playing,setPlaying] = useState(false)

  const [stateFilesFullScreen, setStateFilesFullScreen] = useAsyncState<any>({
    files: null,
    currentIndex: null,
    animation: "",
  });

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const userData = useUserContext();
// stateFilesDm = image; stateFilesFullScreenAnimation = fullscreen image;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const disableBodyFunction = () => {
    const scrollWidth = window.innerWidth - document.body.clientWidth
    if(window.innerWidth > document.body.clientWidth) {
      document.body.classList.add("fl-body-lock-scroll");
      document.body.style.paddingRight = `${scrollWidth}px`
      userData.paddingBodyFunction(`${scrollWidth}`)
    } else {
      document.body.classList.add("fl-body-lock")
    }
  }

  const removeDisableBodyFunction = () => {
    document.body.classList.remove("fl-body-lock");
    document.body.classList.remove("fl-body-lock-scroll");
    document.body.style.paddingRight = `0px`
    userData.paddingBodyFunction("")
  }

  useEffect(() => {
    if(isMobile && !userData.mobilePost) {
      if (stateFilesDm.currentIndex !== null || stateFilesFullScreen.animation === "fade" || stateFilesFullScreen.animation === "fullscreen" || state.state === "show") {
        disableBodyScroll(lockRef.current)
        disableBodyFunction()
      }
      else {
        clearAllBodyScrollLocks()
        removeDisableBodyFunction()
      }
    }
  }, [stateFilesDm, stateFilesFullScreen.animation, state.state,isMobile]);

  const expandImage = (x: string) => {
    setState({ ...stateRef.current, expandedImage: x });
  };

  const closeExpandedImage = () => {
    setState({ ...stateRef.current, expandedImage: null });
  };

  const destoryToast = (id: number) => {
    setState({
      ...stateRef.current,
      toasts: stateRef.current.toasts.filter(
        (x: toastType, y: number) => y !== id
      ),
    });
  };

  const pushToast = (
    type:
      | "warning"
      | "success"
      | "error"
      | "info"
      | "deleted"
      | "disabled"
      | "reactivated"
      | "deactivated"
      | "resend"
      | "money_out"
      | "money_in",
    text: string
  ) => {
    setState({
      ...stateRef.current,
      toasts: [...stateRef.current.toasts, { type, text }],
    });
  };

  const clearToasts = () => {
    setState({ ...stateRef.current, toasts: [] });
  };

  const push = async (x: () => React.ReactNode, disableKey = false) => {
    if(!isMobile){
      disableBodyFunction()
    }
    setTimeout(async () => {
      await setState({
        ...stateRef.current,
        windows: [x, ...stateRef.current.windows],
        disableKeyDown:disableKey
      });
    },100)
  };

  const close = async () => {
    await setState({
      ...stateRef.current,
      windows: stateRef.current.windows.slice(1),
    });
    if(!isMobile && stateRef.current.windows.length  === 0){
     removeDisableBodyFunction()
    }
  };

  const closeAll = async () => {
    await setState({
      ...stateRef.current,
      windows: stateRef.current.windows.slice(2),
    });
    if(!isMobile && stateRef.current.windows.length  === 0){
      removeDisableBodyFunction()
    } else {
      clearAllBodyScrollLocks()
    }
    // document.body.classList.remove("fl-body-fixed");
    // document.documentElement.classList.remove("fl-html-fixed");
  };

  const closeImage = async () => {
    setStateFilesDm({ ...stateFilesDm, files: [], currentIndex: null });
  };

  const [disableScrollBody, setDisableScrollBody] = useState<boolean>(false);


  const disableScroll = (disable: boolean) => {
    setDisableScrollBody(disable);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [disableScrollBody]);

  const animationFunction = (e: AnimationEvent) => {
    wrapperRef.current?.removeEventListener("animationend", animationFunction);
    setState({ ...stateRef.current, state: "closed" });
  };

  const toggleImage = (x: string | null) => {
    setState({ ...stateRef.current, toggledImage: x });
  };

  const fullScreen = (x: string | null, index: number | null) => {
    setState({ ...state, fullScreenStatus: true });
    setStateFilesFullScreen({
      ...stateFilesFullScreen,
      animation: "fade",
      currentIndex: index,
    });
    setTimeout(function () {
      setStateFilesFullScreen({
        ...stateFilesFullScreen,
        files: x,
        animation: "fullscreen",
        currentIndex: index,
      });
    }, 1000);
  };

  const closeFullScreen = () => {
    setState({ ...state, fullScreenStatus: false });
    setStateFilesFullScreen({ ...stateFilesFullScreen, animation: "close"});
    document.body.style.overflowY = "scroll";
    setTimeout(function () {
      setStateFilesFullScreen({
        ...stateFilesFullScreen,
        files: null,
        animation: "",
        fullScreenStatus:false
      });
    }, 1000);
  };

  const keyboardCancelFullScreen = (e: any) => {
    if (e.key === "Escape") {
      closeFullScreen();
    }
  };
  useEffect(() => {
    if (stateFilesFullScreen.files !== null) {
      document.addEventListener("keydown", keyboardCancelFullScreen);
      return () => {
        document.removeEventListener("keydown", keyboardCancelFullScreen);
      };
    }
  }, [stateFilesFullScreen]);

  const toggleImageDm = (x: any, y: number,fullScreen = true) => {
    disableBodyFunction()
    setStateFilesDm({ ...stateFilesDm, files: x, currentIndex: y, fullScreen:fullScreen });
  };

  useEffect(() => {
    if (stateRef.current.windows.length > 0) {
      setState({ ...stateRef.current, state: "show" });
      setCurrentModalData((p) => stateRef.current.windows[0]);
    } else if (
      stateRef.current.windows.length === 0 &&
      stateRef.current.state !== "closed"
    ) {
      setState({ ...stateRef.current, state: "hide" });
    }
  }, [state.windows]);

  useEffect(() => {
    if (stateRef.current.state === "hide") {
      wrapperRef.current?.addEventListener("animationend", animationFunction);
    }
  }, [state.state]);

  useEffect(() => {}, [currentModalData]);

  useEffect(() => {
    if (stateRef.current.expandedImage || stateFilesFullScreen.files) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [state.expandedImage,stateFilesFullScreen.files]);

  const prevImageDm = () => {
    const index = stateFilesDm.currentIndex - 1;
    setStateFilesDm({
      ...stateFilesDm,
      currentIndex: index === -1 ? stateFilesDm.files.length - 1 : index,
    });
  };

  const nextImageDm = () => {
    const index = stateFilesDm.currentIndex + 1;
    setStateFilesDm({
      ...stateFilesDm,
      currentIndex: index > stateFilesDm.files.length - 1 ? 0 : index,
    });
  };

  useEffect(() => {
    if (stateFilesDm.currentIndex !== null) {
      if (userData.keyName === "ArrowRight") {
        nextImageDm();
      } else if (userData.keyName === "ArrowLeft") {
        prevImageDm();
      } else {
      }
    }
  }, [userData]);

  const keyboardEventDm = (e: any) => {
    if (e.key === "ArrowRight") {
      userData.keyboardEvent("ArrowRight");
    } else if (e.key === "ArrowLeft") {
      userData.keyboardEvent("ArrowLeft");
    } else if (e.key === "Escape") {
      closeImage();
    }
  };
  useEffect(() => {
    if (stateFilesDm.files.length > 1) {
      document.addEventListener("keydown", keyboardEventDm); // Adds event listener to modal for escape key to close it
      return () => {
        document.removeEventListener("keydown", keyboardEventDm); // Removes event listener on unmount
      };
    }
  }, [stateFilesDm.files]);

  const nextFullScreenImage = () => {
    const index = stateFilesFullScreen.currentIndex + 1;
    setStateFilesFullScreen({
      ...stateFilesFullScreen,
      currentIndex: index > stateFilesFullScreen.files.length - 1 ? 0 : index,
    });
  };

  const prevFullScreenImage = () => {
    const index = stateFilesFullScreen.currentIndex - 1;
    setStateFilesFullScreen({
      ...stateFilesFullScreen,
      currentIndex: index === -1 ? stateFilesFullScreen.files.length - 1 : index,
    });
  };

  const closeDmImage = () => {
    setStateFilesDm({ ...stateFilesDm, files: [], currentIndex: null });
    document.body.classList.remove("fl-body-fixed");
    removeDisableBodyFunction()
  };

  const [fullScreenSize, setFullScreenSize] = useState<any>(false);
  const [clickZoom, setClickZoom] = useState<any>(false);
  const [cursorType, setCursorType] = useState<string>("zoomIn");

  const customZoomFunction = (e: any) => {
    setFullScreenSize(!fullScreenSize);
    if (cursorType === "zoomIn") {
      setCursorType("zoomOut");
    } else {
      setCursorType("zoomIn");
    }
  };

  const keyboardEvent = (e: any) => {
    if (e.key === "Escape" && !state.disableKeyDown) {
      closeDmImage();
      close();
    }
  };

  useEffect(() => {
   if(state.state === "show") {
     document.addEventListener("keydown", keyboardEvent);
     return () => {
       document.removeEventListener("keydown", keyboardEvent);
     };
   }
  }, [state.state]);

  const [copy,setCopy] = useState(false)
  const copyToClipboard = async (iban:string) => {
    setCopy(true)
    setTimeout(async () => {
      try {
        await navigator.clipboard.writeText(iban);
        pushToast('success', 'SWIFT/BIC copied to clipboard.')
      } catch (e) {

      }
      setCopy(false)
    },1000)
  }

  return (
    <ModalContext.Provider
      value={{
        ...stateRef.current,
        push,
        close,
        closeAll,
        closeImage,
        pushToast,
        clearToasts,
        expandImage,
        closeExpandedImage,
        toggleImage,
        toggleImageDm,
        disableScroll,
        fullScreen,
        closeFullScreen,
        prevFullScreenImage,
        nextFullScreenImage,
      }}
    >
      {props.children}
      {state.expandedImage ? (
        <div className="fl-user-profile-fullscreen">

        </div>
      ) : (
        ""
      )}
      {state.toggledImage ? (
        <div
          className="fl-toggled-image-outer"
          onClick={() => toggleImage(null)}
        >
          <div className="fl-toggled-image-inner">
            <img src={state.toggledImage} alt={"fluffa"} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="fl-toasts-column">
        {state.toasts.map((x: toastType, y: number) => (
          <Toast
            type={x.type}
            text={x.text}
            time={5000}
            destroy={() => destoryToast(y)}
            key={y}
          />
        ))}
      </div>
      {/*!== "closed"*/}
      {state.state === "show" ? (
        <div
          className={`fl-modal-wrapper fl-modal-wrapper-${state.state}`}
          ref={wrapperRef}
          // onClick={close}
        >
          <div
            className={`fl-modal-window`}
            onClick={(e) => e.stopPropagation()}
            ref={lockRef}
          >
            {typeof currentModalData === "function" ? (
              currentModalData()
            ) : (
              <React.Fragment>
                <div className="fl-modal-close-button" onClick={close}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="fl-modal-title">
                  {currentModalData.title.toLowerCase().includes("success") ? (
                    <div className="fl-modal-title-icon fl-modal-title-icon-success"></div>
                  ) : (
                    ""
                  )}
                  {currentModalData.title.toLowerCase().includes("error") ? (
                    <div className="fl-modal-title-icon fl-modal-title-icon-error"></div>
                  ) : (
                    ""
                  )}
                  <div className="fl-modal-title-text">
                    {currentModalData.title}
                  </div>
                </div>
                <div className="fl-modal-description">
                  {currentModalData.description.map((x, y) => (
                    <div className="fl-modal-description-row" key={y}>
                      {x}
                    </div>
                  ))}
                  {currentModalData.warnings?.map((x, y) => (
                      <>
                        <div className="fl-modal-description-row fl-modal-warnings-row" key={y} dangerouslySetInnerHTML={{__html:x}} />
                        {currentModalData.copyButton !== undefined && currentModalData.copyButton.length > 0 ?
                        //   <span className={`fl-modal-description-copy-button ${copy ? " fl-modal-description-copy-button-disable" : ""}`} onClick={() => copyToClipboard(currentModalData?.copyButton !== undefined ? currentModalData?.copyButton?.toString() : "")}>
                        //   {copy ? "Copying" : "Copy"}
                        // </span>
                            <Button className="fl-settings-mt-20 fl-modal-copy-button" type="normal" onClick={() => copyToClipboard(currentModalData?.copyButton !== undefined ? currentModalData?.copyButton?.toString() : "")} disabled={copy}>
                              Copy
                              {copy ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                            </Button>
                            : ""
                        }
                      </>
                  ))}
                </div>
                <div className="fl-modal-buttons">
                  {currentModalData.buttons.map((x, y) => (
                    <div key={y}>
                      {x()}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {stateFilesDm.currentIndex !== null ? (
        <div
          className={`fl-modal-wrapper fl-modal-wrapper-${state.state}`}
          ref={wrapperRef}
        >
          <div
          ref={lockRef}
            className="fl-image-modal-window"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="fl-modal-close-button fl-image-close"
              onClick={() => closeDmImage()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <React.Fragment>
              {stateFilesDm.files.length > 1 && (
                <>
                  <div
                    className={`fl-image-modal-window-prev`}
                    onClick={prevImageDm}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                </>
              )}
              <div className="fl-modal-image">
                {stateFilesDm.files.find((x: any, index: number) => index === stateFilesDm.currentIndex).type === "video" ?
                    <VideoPlayer src={stateFilesDm.files.filter((x: any, index: number) => index === stateFilesDm.currentIndex)[0].url} thumbnail={stateFilesDm.files.filter((x: any, index: number) => index === stateFilesDm.currentIndex)[0].thumb_url} duration={stateFilesDm.files.filter((x: any, index: number) => index === stateFilesDm.currentIndex)[0].duration} setPlaying={setPlaying} playing={playing} />
                    :
                    <img
                        src={stateFilesDm.files.filter((x: any, index: number) => index === stateFilesDm.currentIndex)[0].url}
                        alt={"fluffa"}
                    />
                }
              </div>
              {stateFilesDm.files.length > 1 && (
                <>
                  <div
                    className={`fl-image-modal-window-next`}
                    onClick={nextImageDm}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </>
              )}
            </React.Fragment>
            {stateFilesDm.fullScreen ?
                <span
                    className="fl-fullscreen-icon"
                    onClick={() => {
                      closeDmImage();
                      fullScreen(stateFilesDm.files, stateFilesDm.currentIndex);
                    }}
                >
                  <FontAwesomeIcon icon={faExpand} />
                </span>
                : ""
            }
          </div>
        </div>
      ) : (
        ""
      )}

      {stateFilesFullScreen.animation === "fade" ||
      stateFilesFullScreen.animation === "fullscreen" ||
      stateFilesFullScreen.animation === "close" ? (
        <div
          className={`fl-full-screen fl-full-screen-animation-${stateFilesFullScreen.animation}  fl-full-screen-preview`}
          onContextMenu={(e) => e.preventDefault()}
        >
          {stateFilesFullScreen.files !== null &&
            stateFilesFullScreen.currentIndex !== null && (
              <>
                <div
                  className={"fl-fullscreen__close"}
                  onClick={() => closeFullScreen()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                {stateFilesFullScreen.files.length > 1 && (
                  <>
                    <div
                      className={`fl-image-modal-window-prev`}
                      onClick={() => prevFullScreenImage()}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                  </>
                )}
                <div className={`fl-full-screen-image`}>
                  <TransformWrapper
                    doubleClick={{
                      disabled: true,
                    }}
                    wheel={{
                      disabled: true,
                    }}
                    panning={{
                      disabled: clickZoom ? false : true,
                    }}
                    maxPositionX={100}
                    minPositionX={20}
                    maxPositionY={100}
                    minPositionY={20}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        {stateFilesFullScreen.files[stateFilesFullScreen.currentIndex]?.type !== "video" ?
                            <div className="fl-full-screen-tools">
                          <span
                              onClick={() => {
                                setFullScreenSize(false);
                                resetTransform();
                                setClickZoom(false);
                                setCursorType("zoomIn");
                              }}
                          >
                            <FontAwesomeIcon icon={faExpand} />
                          </span>
                              <span
                                  onClick={() => {
                                    setFullScreenSize(true);
                                    resetTransform();
                                    setClickZoom(false);
                                    setCursorType("zoomOut");
                                  }}
                              >
                            <p>1:1</p>
                          </span>
                              {fullScreenSize ? (
                                  <>
                              <span
                                  onClick={() => {
                                    zoomIn();
                                    setClickZoom(true);
                                    setCursorType("pointer");
                                  }}
                              >
                                <FontAwesomeIcon icon={faSearchPlus} />
                              </span>
                                    <span
                                        onClick={() => {
                                          resetTransform();
                                          setFullScreenSize(false);
                                          setCursorType("zoomIn");
                                        }}
                                    >
                                <FontAwesomeIcon icon={faSearchMinus} />
                              </span>
                                  </>
                              ) : (
                                  <>
                              <span
                                  onClick={() => {
                                    setFullScreenSize(true);
                                    setCursorType("zoomOut");
                                  }}
                              >
                                <FontAwesomeIcon icon={faSearchPlus} />
                              </span>
                                    <span
                                        onClick={() => {
                                          resetTransform();
                                          setClickZoom(false);
                                          setFullScreenSize(false);
                                          setCursorType("zoomIn");
                                        }}
                                    >
                                <FontAwesomeIcon icon={faSearchMinus} />
                              </span>
                                  </>
                              )}
                            </div>
                            : ""
                        }
                        <TransformComponent>
                          <div className={`fl-full-screen-defualt`}>
                            {stateFilesFullScreen.files[stateFilesFullScreen.currentIndex]?.type === "video" ?
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                    class={"smooth-image img-visible"}
                                    url={stateFilesFullScreen.files[stateFilesFullScreen.currentIndex]?.url}
                                    config={{ file: { attributes: { controlsList: "nodownload" } } }}
                                    className={`fl-full-screen-size-${fullScreenSize} fl-full-screen-cursor-pointer`}
                                /> :
                                <img
                                    onClick={(e) =>
                                        clickZoom ? "" : customZoomFunction(e)
                                    }
                                    onDoubleClick={(e) => {
                                      resetTransform();
                                      setClickZoom(false);
                                      setFullScreenSize(false);
                                      setCursorType("zoomIn");
                                    }}
                                    alt="Full screen image"
                                    src={
                                      stateFilesFullScreen.files[
                                          stateFilesFullScreen.currentIndex
                                          ]?.url
                                    }
                                    className={`fl-full-screen-size-${fullScreenSize} fl-full-screen-cursor-${cursorType}`}
                                />
                            }
                          </div>
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
                {stateFilesFullScreen.files.length > 1 && (
                  <>
                    <div
                      className={`fl-image-modal-window-next`}
                      onClick={() => nextFullScreenImage()}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </>
                )}
              </>
            )}
        </div>
      ) : (
        ""
      )}
    </ModalContext.Provider>
  );
};
