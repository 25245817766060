import React, {FC} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { useModalContext } from '../context/ModalContext';
import { useUserContext } from '../context/UserContext';
import { Button } from './Button';
import "./MyProfile.css";
import { ProfileHolder } from './ProfileHolder';
import { SimpleCard } from './SimpleCard';
import { WritePost } from './WritePost';
import ReactTooltip from "react-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCertificate, faCheck} from "@fortawesome/free-solid-svg-icons";

export const MyProfile: FC<{
    setStatePost?:any,
    stateRef?:any
}> = ({ setStatePost,stateRef }) =>  {

    // CONTEXT
    const modalData = useModalContext();
    const userData = useUserContext();
    const history = useHistory()

    const postNow = async () => {
        await modalData.push(() => <WritePost  mobileResponsive={true} style={{marginBottom: 0}} setStatePost={setStatePost} setStatePostRef={stateRef}/>,true)
    }

    return (
        <SimpleCard>
            <div className="fl-d-flex fl-align-center fl-mb-20">
                <div className="fl-my-profile-img">
                    <ProfileHolder src={userData.user.profile.profileImage?.url} userProfile={userData.user.profile} changeStatus />
                </div>
                <div className="fl-my-profile-info">
                    <Link to={`/${userData.user.handle}`} className={`fl-my-profile-name fl-my-profile-name-${userData.currentTheme}`}>
                        <span className={"fl-user-profile-full-name-span"}>
                            {userData.user.name}
                            {userData.user.profile.meta.verified_state === "verified" ? (
                                <>
                                    <div
                                        className="fl-user-profile-verified"
                                        title="Verified creator"
                                    >
                                        <FontAwesomeIcon
                                            className="fl-user-profile-verified-crt"
                                            icon={faCertificate}
                                        />
                                        <FontAwesomeIcon
                                            data-tip
                                            data-for="profile-verified"
                                            className="fl-user-profile-verified-chc"
                                            icon={faCheck}
                                        />
                                    </div>
                                    <ReactTooltip id="profile-verified">
                                        Verified
                                    </ReactTooltip>
                                </>
                            ) : (
                                ""
                            )}
                        </span>
                    </Link>
                    <Link to={`/${userData.user.handle}`} className="fl-my-profile-username">@{userData.user.handle}</Link>
                </div>
            </div>
            <div className="fl-d-flex">
                <div className="fl-col-4 fl-text-align-center ">
                    <div className="fl-my-profile-key">Posts</div>
                    <div className={`fl-my-profile-value fl-my-profile-value-${userData.currentTheme}`}>{userData.user.profile.meta.posts}</div>
                </div>
                <div className="fl-col-4 fl-text-align-center ">
                    <div className="fl-my-profile-key">Follower{userData.user.profile.meta.followers > 1 || userData.user.profile.meta.followers === 0 ? "s" : ""}</div>
                    <div className={`fl-my-profile-value fl-my-profile-value-followers fl-my-profile-value-${userData.currentTheme}`} onClick={() => history.push("/follow/followers")} >{userData.user.profile.meta.followers}</div>
                </div>
                <div className="fl-col-4 fl-text-align-center">
                    <div className="fl-my-profile-key">Likes</div>
                    <div className={`fl-my-profile-value fl-my-profile-value-${userData.currentTheme}`}>{userData.user.profile.meta.likes}</div>
                </div>
            </div>
            <div className={`fl-my-profile-post fl-my-profile-post-${userData.currentTheme}`}>
                <Button className={`fl-my-profile-post-button`} type="normal" onClick={postNow}>Create post</Button>
            </div>
        </SimpleCard>
    )
}
