import React, {FC, useEffect, useState} from "react";
import { useUserContext } from "../../context/UserContext";
import { SimpleCard } from "../../components/SimpleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faImage,
    faVideo,
    faTrashAlt,
    faCheck,
    faShare,
    faEllipsisH,
    faExpand
} from "@fortawesome/free-solid-svg-icons";
import "../../components/Post.css";
import {
    faChevronLeft,
    faChevronRight,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import postInterface from "../../interfaces/PostInterface";
import moment from "moment";
import { ProfileHolder } from "../../components/ProfileHolder";
import {Link, useHistory} from "react-router-dom";
import {Button} from "../../components/Button";
import {useModalContext} from "../../context/ModalContext";
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import restoreSvg from "../../assets/images/icons/restore.svg"
import restoreSvgPink from "../../assets/images/icons/restore-pink.svg"
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import {GoToPostModal} from "../../components/GoToPostModal";
import deleteIconLight from "../../assets/images/icons/deleteIconLight.svg"
import VideoPlayer from "../../components/video/VideoPlayer";
export const DrivePost: FC<{
    data: postInterface;
    post:any;
    setPost:any;
    deletePost:any;
    selectedPosts:any;
    addSelected:any,
    removeSelected:any,
    setCountArchive:any,
    countArchive:any
}> = (props) => {
    const [state, setState] = useState<{
        currentImage: number;
        visible:boolean;
        deleted:boolean;
        dropdown:boolean;
        likeOffset:number;
        like:boolean;
    }>({
        currentImage: 0,
        visible:false,
        deleted:false,
        dropdown:false,
        likeOffset:0,
        like:false
    });
    const [closed, setClosed] = useState(false);

    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();
    const history = useHistory();

    const [playing,setPlaying] = useState(false)

    const prevPost = () => {
        if (state.currentImage > 0) {
            setState({ ...state, currentImage: state.currentImage - 1 });
        } else {
            let lastImage =
                props.data.postImages.length + props.data.postVideos.length - 1;
            setState({ ...state, currentImage: lastImage });
        }
    };

    const nextPost = () => {
        if (
            state.currentImage <
            props.data.postImages.length + props.data.postVideos.length - 1
        ) {
            setState({ ...state, currentImage: state.currentImage + 1 });
        } else {
            setState({ ...state, currentImage: 0 });
        }
    };

    const setCloseTimer = () => {
        setTimeout(() => setClosed(true), 5000);
    };

    useEffect(() => {
        if(state.visible || state.deleted) {
            setCloseTimer()
        }
    }, [state.visible,state.deleted]);

    const checkAuthStatus = () => {
        if (userData.authStatus === "AUTHENTICATED") {
            return true;
        } else {
            return false;
        }
    };

    const moveToDrive = async (postId:number) => {
        modalData.close();
        modalData.clearToasts()
        try {
            await Axios.put(`${envData.apiUrl}/posts/${postId}`,{
                drive: true
            });
            props.setCountArchive(props.countArchive)
            modalData.pushToast("info", <>Action undone.</>);
            setState({ ...state, visible: false });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const goToPost = (postId:number) => {
        history.push(`/post/${postId}`)
    }

    const restorePost = async (postId:number) => {
        modalData.clearToasts()
        setClosed(false)
        modalData.close();
        try {
            await Axios.put(`${envData.apiUrl}/posts/${postId}`,{
                drive: false
            });
            props.setCountArchive(props.countArchive - 1)
            modalData.pushToast(
                "success",
                <>
                    <div>
                        Your Post has been restored. {" "}
                        <span className="fl-notifications__mark" onClick={() => moveToDrive(postId)}>
                             Undo
                        </span>
                    </div>
                </>
            );
            setState({ ...state, visible: true });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const deletePost = async (postId: number) => {
        modalData.clearToasts()
        modalData.close();
        try {
            await Axios.delete(`${envData.apiUrl}/posts/${postId}`);
            modalData.pushToast(
                "success",
                "Post deleted forever"
            );
            props.setCountArchive(props.countArchive - 1)
            setState({ ...state, deleted: true });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    const checkSelect = () => {
        if(props?.selectedPosts?.posts?.includes(props.data)) {
            return true
        }
    }

    const linkProfile = (id:number,handle:any) => {
        history.push(`/${handle}`)
    }

    const shareSinglePost = (post:any) => {
        userData.driveFiles([post])
        history.push("/message-create")
    }

    const [imageDropdownGrid, setImageDropdownGrid] = useState<boolean> (false)
    const [optionId, setOptionId] = useState<number>(0)
    const imageOption = (e:any,id:any) => {
        e.stopPropagation()
        setOptionId(id)
        setImageDropdownGrid(!imageDropdownGrid)
    }

    const [hovered, setHovered] = useState({
        id: 0
    });

    const hideHover = () => {
        setHovered({...hovered, id:0})
        setOptionId(0)
        setImageDropdownGrid(false)
    }

    const deleteSinglePost = async (postId:number) => {
        if(state.dropdown) {
            setState({...state, dropdown:false})
        }
        await modalData.push({
            title: "Delete forever",
            description: [
                "Your Post will be deleted forever and you won't be able to restore it.",
            ],
            buttons: [
                () => (
                    <div className="fl-d-flex fl-justify-flex-end">
                        <Button
                            onClick={async () =>
                                await modalData.close()
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="normal"
                            onClick={() =>
                                deletePost(postId)
                            }
                        >
                            Delete forever
                        </Button>
                    </div>
                ),
            ],
        })
    }

    const restoreSinglePost = async (postId:number) => {
        await modalData.push({
            title: "Restore to profile",
            description: [
                "Posts you restore to your profile will be put back to their original location.",
            ],
            buttons: [
                () => (
                    <div className="fl-d-flex fl-justify-flex-end">
                        <Button
                            onClick={async () =>
                                await modalData.close()
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="normal"
                            onClick={() =>
                                restorePost(postId)
                            }
                        >
                            Restore
                        </Button>
                    </div>
                ),
            ],
        })
    }

    const likePost = async (postId: number) => {
        setState({
            ...state,
            like: !state.like,
            likeOffset: state.likeOffset + (!state.like ? 1 : -1),
        });
        try {
            await Axios.post(`${envData.apiUrl}/posts/${postId}/like`, {
                like: !state.like,
            });
        } catch (e) {}
    };

    const restoreOptions = () => {
        return (
            <div className={"fl-drive-restore-post-option"}>
                {props.data.postImages.length > 0 || props.data.postVideos.length > 0 ?
                    <div className={"fl-drive-restore-post"}
                         onClick={async () => shareSinglePost(props.data)}>
                        <span><FontAwesomeIcon icon={faShare}/></span>
                        <span>Share file</span>
                    </div>
                    :""
                }
                <div className={"fl-drive-restore-post"}
                     onClick={async () => restoreSinglePost(props.data.id)}>
                    <span><img src={restoreSvg} alt={`restore post`}/></span>
                    <span>Restore post</span>
                </div>
                <div className={"fl-drive-restore-post"}
                     onClick={async () => deleteSinglePost(props.data.id)}>
                    <span><img style={{marginTop: "-1px"}}src={deleteIconLight} alt={`delete post`} /></span>
                    <span>Delete forever</span>
                </div>
            </div>
        )
    }

    const openPost = () => {
        modalData.push(() =>  <GoToPostModal data={props.data} likePost={likePost} state={state} setState={setState}/>)
    }

    return (
        <>
            {!state.visible && !state.deleted ? (
                <SimpleCard className={`fl-post-main fl-post-0 fl-post-main-drive  ${userData.gridView && "fl-drive-removed-post-hover"}`} onClick={() => props.deletePost ? (checkSelect() ? props.removeSelected(props.data.id) : props.addSelected(props.data)) : ""}
                            onMouseEnter={() => userData.gridView && !props.deletePost  ? setHovered({...hovered, id:props.data.id}) : ""}
                            onMouseLeave={() => userData.gridView && !props.deletePost ? hideHover() : ""}
                >
                    <>
                        {!userData.gridView && (
                            <div className="fl-post-head fl-removed-post-head fl-post-display-block-sm">
                                <div className="fl-post-image-info-holder">
                                    <Link
                                        to={`/${props.data.userProfile.handle}`}
                                        className="fl-post-head-image"
                                    >
                                        <ProfileHolder
                                            src={props.data.userProfile.profileImage?.url}
                                        />
                                    </Link>
                                    <div className="fl-post-head-info-padding">
                                        <span
                                            onClick={() => props.deletePost ? "" : linkProfile(props.data.userProfile.id,props.data.userProfile.handle)}
                                            className={`fl-post-head-info-name fl-post-head-info-name-${userData.currentTheme}`}
                                        >
                                            {props.data.userProfile.name}
                                        </span>
                                        <div className="fl-d-flex fl-align-center fl-dot-none">
                                            <span
                                                onClick={() => props.deletePost ? "" : linkProfile(props.data.userProfile.id,props.data.userProfile.handle)}
                                                className="fl-post-head-info-username-width"
                                            >
                                                @{props.data.userProfile.handle}
                                            </span>
                                            <div className="fl-post-head-info-dot"></div>
                                            <div className="fl-post-head-info-time-width">
                                                {moment.utc(props.data.created_at).fromNow()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!props.deletePost || state.dropdown ? restoreOptions() :
                                    <div className={`fl-drive-post-checked fl-drive-post-checked-${props?.selectedPosts?.posts?.includes(props.data) ? "enable" : "disable"}`} >
                                        {props?.selectedPosts?.posts?.includes(props.data) &&
                                        <FontAwesomeIcon icon={faCheck}/>}</div> }
                            </div>
                        )
                        }
                        {props.data.text && !userData.gridView ? (
                            <div className="fl-post-text fl-settings-mt-30" >{props.data.text}</div>
                        ) : (
                            ""
                        )}
                        {props.data.postImages.length > 0 ||
                        props.data.postVideos.length > 0 ? (
                            <div className={`fl-post-images  fl-settings-mt-30`}
                                 tabIndex={1}
                                 onBlur={() => setImageDropdownGrid(false)}
                            >
                                {userData?.gridView &&
                                <>
                                    {props.deletePost &&
                                    <div className={`fl-drive-post-checked fl-drive-post-checked-${props?.selectedPosts?.posts?.includes(props.data) ? "enable" : "disable"}`} >
                                        {props?.selectedPosts?.posts?.includes(props.data) &&
                                        <FontAwesomeIcon icon={faCheck}/>
                                        }
                                    </div>
                                    }
                                </>
                                }
                                <div className={"fa-drive-option"}  onClick={(e) => imageOption(e,props.data.id)}> <FontAwesomeIcon icon={faEllipsisH} /> </div>
                                {userData.gridView && imageDropdownGrid  ?
                                    <div
                                        className={`fl-filter-head-actions-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                                    >
                                        <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                                            <div className="fl-dropdown-filter-option fl-dropdown-filter-option-share fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => shareSinglePost(props.data)}>
                                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                    <FontAwesomeIcon icon={faShare} />
                                                </div>
                                                <span>
                                                  Share file
                                            </span>
                                            </div>
                                            <div className="fl-dropdown-filter-option fl-dropdown-filter-option-restore fl-dropdown-filter-option-share fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => restoreSinglePost(props.data.id)}>
                                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                    <img src={restoreSvg} alt={`restore post`} />
                                                    <img src={restoreSvgPink} alt={`restore active post`} />
                                                </div>
                                                <span>
                                                  Restore post
                                            </span>
                                            </div>
                                            <div className="fl-dropdown-filter-option fl-dropdown-filter-option-share fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={() => modalData.fullScreen(props.data.postAttachments, state.currentImage)}>
                                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                    <FontAwesomeIcon icon={faExpand} />
                                                </div>
                                                <span>
                                                  Full screen
                                            </span>
                                            </div>
                                            <div className="fl-dropdown-filter-option fl-dropdown-filter-option-share fl-dropdown-filter-option-delete fl-drive-delete-select"     onClick={async () => deleteSinglePost(props.data.id)}>
                                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </div>
                                                <span>
                                                  Delete forever
                                            </span>
                                            </div>
                                        </SimpleCard>
                                    </div> : ""
                                }
                                <div
                                    className={`fl-post-images-row-wrapper fl-post-images-row-wrapper-${
                                        (props.data.bought || !(props.data.type === "ppv") || props?.data?.userProfile?.handle === userData?.user?.handle) &&
                                        checkAuthStatus()
                                    } `}
                                    onClick={() =>
                                        props.deletePost ? "" :
                                            (props.data.bought || !(props.data.type === "ppv") || props?.data?.userProfile?.handle === userData?.user?.handle) &&
                                            userData.authStatus === "AUTHENTICATED" //checks if post is not behind the paywall and displays it if it bought or public
                                                ? openPost()
                                                : ""
                                    }
                                >
                                    <div
                                        className="fl-post-images-row fl-post-images-drive-row"
                                        style={{
                                            transform: `translateX(-${state.currentImage * 100}%)`,
                                        }}
                                    >  {userData.gridView && (
                                        <div
                                            className={`fl-images-overlayer fl-images-overlayer-${
                                                userData.authStatus === "AUTHENTICATED"
                                                    ? "true"
                                                    : "false"
                                            }`}
                                        >

                                        </div>
                                    )}
                                        {props?.data?.postAttachments?.map((x:any,y:number) => (
                                            x.type === "video" ?
                                                <div className={"smooth-image-wrapper"}>
                                                    <VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                                </div>
                                                :
                                                <RenderSmoothImage src={x.url} />
                                        ))}
                                    </div>
                                </div>
                                {props.deletePost ? "" :
                                    <>
                                        {props.data.postImages.length + props.data.postVideos.length >
                                        0 ? (
                                            <div className="fl-post-images-swipe">
                                                {props.data.postImages.length + props.data.postVideos.length >
                                                1 &&
                                                <>
                                                    <div
                                                        className={`fl-post-images-swipe-button fl-post-images-swipe-button-`}
                                                        onClick={prevPost}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                    </div>
                                                    <div
                                                        className={`fl-post-images-swipe-button fl-post-images-swipe-button-`}
                                                        onClick={nextPost}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronRight} />
                                                    </div>
                                                </>
                                                }
                                                {props.data.postImages.length + props.data.postVideos.length >
                                                0 &&
                                                <div className="fl-post-image-swipe-count">
                                                    {props.data.postImages.length > 0 && (
                                                        <FontAwesomeIcon
                                                            icon={faImage}
                                                            style={{
                                                                opacity:
                                                                    state.currentImage < props.data.postImages.length
                                                                        ? "1"
                                                                        : ".5",
                                                            }}
                                                        />
                                                    )}
                                                    {state.currentImage + 1}
                                                    { props.data.postImages.length + props.data.postVideos.length > 1 &&
                                                    <>
                                                        {"/"}
                                                        {props.data.postImages.length}
                                                        {props.data.postVideos.length > 0 && (
                                                            <FontAwesomeIcon
                                                                icon={faVideo}
                                                                style={{
                                                                    opacity:
                                                                        state.currentImage >= props.data.postImages.length
                                                                            ? "1"
                                                                            : ".5",
                                                                }}
                                                            />
                                                        )}
                                                    </>
                                                    }
                                                </div>
                                                }
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                }
                            </div>
                        ) : (
                            ""
                        )}
                    </>
                </SimpleCard>
            ) : (
                !closed &&
                <>
                    <div>
                        <SimpleCard
                            className={`fl-post-deleted fl-post-deleted-${userData.currentTheme}`}
                        >
                            {state.deleted ? "Post deleted forever" : state.visible ?
                                <>
                                    Your Post has been restored. {" "}
                                    <span className="fl-notifications__mark" onClick={() => goToPost(props.data.id)}>
                                             Go to Post
                                        </span>
                                </> : ""
                            }
                            <div
                                className="fl-post-head-actions-dropdown-option-icon fl-post-deleted-close"
                                onClick={() => {
                                    setClosed(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </SimpleCard>
                    </div>
                </>
            )}
        </>
    );
};
