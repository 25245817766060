import React, {FC} from 'react'
import postInterface from "../../interfaces/PostInterface";
import {DrivePost} from "./DrivePost";
import ReactLoading from "react-loading";
import {useUserContext} from "../../context/UserContext";

export const RemovedPosts:FC<({setCountArchive:any, countArchive:any,deletePost:any,setDeletePost:any,state:any,setState:any, selectedPosts:any, addSelected:any,removeSelected:any})> = (props) => {

    const userData = useUserContext()

    return (
        <div className={`fl-post-display-${!userData.gridView ? 'full' : props.state.empty === 0 || props.state.empty === false ? "full" : 'grid'}`}>
            {props.state.empty === false ?
                <div className={"fl-loader-animation fl-purchased-empty"}>
                    <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                </div> : ""
            }
            {props.state.empty === 0 &&
            <div className={"fl-purchased-empty"}>
                <div className={"fl-purchased-empty"}>
                    <p>You haven't archived any posts yet.</p>
                    <p>Posts you archive will appear here.</p>
                </div>
            </div>
            }
            {userData.gridView ?
                <>
                    {props.state.empty === 1 &&
                    <>
                        {props.state.posts.filter((file:any) => file.postImages.length > 0 || file.postVideos.length > 0).map((x: postInterface, y: number) => (
                            <DrivePost
                                key={y}
                                data={x}
                                post={props.state}
                                setPost={props.setState}
                                deletePost={props.deletePost}
                                selectedPosts={props.selectedPosts}
                                addSelected={props.addSelected}
                                removeSelected={props.removeSelected}
                                setCountArchive={props.setCountArchive}
                                countArchive={props.countArchive}
                            />
                        ))}
                    </>
                    }
                </>
                :
                <>
                    {props.state.empty === 1 &&
                    <>
                        {props.state.posts.map((x: postInterface, y: number) => (
                            <DrivePost
                                key={y}
                                data={x}
                                post={props.state}
                                setPost={props.setState}
                                deletePost={props.deletePost}
                                selectedPosts={props.selectedPosts}
                                addSelected={props.addSelected}
                                removeSelected={props.removeSelected}
                                setCountArchive={props.setCountArchive}
                                countArchive={props.countArchive}
                            />
                        ))}
                    </>
                    }
                </>
            }
        </div>
    )
}
