import React, { FC } from "react";

import Attach from "./Files/Attach";
import Voice from "./Files/Voice";
import Video from "./Files/Video";
import Emoji from "./Files/Emoji";
import Price from "./Files/Price";

const Media: FC<any> = ({ message, setMessage, users, progress,setStateBlurPercent,stateBlurPercent,setState,state }) => {
  return (
    <div className="fl-bulk__media">
      <div className={`fl-bulk__media__icons ${state.recording && "fl-bulk__media__icons-recording"}`}>
         {!state.recording &&
            <Attach state={state} message={message} setMessage={setMessage} type="icon" />
         }

        <Voice setState={setState} state={state} message={message} setMessage={setMessage} users={users} />

        {!state.recording &&
           <Emoji message={message} setMessage={setMessage} />
        }

        {!state.recording &&
           <Video message={message} setMessage={setMessage} />
        }

      </div>

      {message.files.length && !progress.uploading ? (
        <Price message={message} setMessage={setMessage} setStateBlurPercent={setStateBlurPercent} stateBlurPercent={stateBlurPercent}/>
      ) : (
        ""
      )}
    </div>
  );
};

export default Media;
