import { useUserContext } from "../context/UserContext";
import { SimpleCard } from "./SimpleCard";
import React, { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faMoon,
  faSun,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import "./PostOptions.css";
import {Line} from "rc-progress";

export const createScrollStopListener = (
  callback: () => void,
  timeout: number
) => {
  let removed = false;
  let handle: any = null;
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout || 200);
  };
  window.addEventListener("scroll", onScroll);
  return () => {
    if (removed) {
      return;
    }
    removed = true;
    if (handle) {
      clearTimeout(handle);
    }
    window.removeEventListener("scroll", onScroll);
  };
};

export const PostOptions: FC<any> = (props) => {
  const userData = useUserContext();
  const [clicked, setClicked] = useState(false);
  const [scroll, setScroll] = useState<boolean>(false);
  const [shouldHide, setShouldhide] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const windowScroll = () => {
    const element: any = containerRef.current;
    const elementPositionY = element.getBoundingClientRect().top;
    const shouldScroll = elementPositionY < 105;
    setScroll(shouldScroll);
    if (shouldScroll) {
      setShouldhide(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", windowScroll);
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, [props.postsNumber]);

  useEffect(() => {
    const destroyListener = createScrollStopListener(() => {
      if (scroll) {
        setShouldhide(true);
      }
    }, 2000);
    return () => destroyListener();
  }, [scroll]);

  const returnUploadProgress = () => {
      return userData.encodingStatus.percent
  }

  return (
    <>
      <div ref={containerRef}>
        <SimpleCard
            // id={scroll ? `fl-options-fixed-${userData.currentTheme}` : null}
            // className={shouldHide ? `fl-options-hidden` : null}
        >
          <div
              className={`fl-options-wrapper fl-options-wrapper-${userData.currentTheme}`}
          >
            <p>Options</p>
            <div className="fl-options-container">
              {
                <div
                    onClick={() => {
                      userData.changeGridView();
                      // if (props.parentRef) {
                      //   const feedScrollPosition =
                      //       props.parentRef.getBoundingClientRect().top;
                      //   if (feedScrollPosition !== 0) {
                      //     window.scrollTo({
                      //       top: feedScrollPosition + window.pageYOffset + 20,
                      //       behavior: "smooth",
                      //     });
                      //   }
                      //   setClicked(!clicked);
                      // }
                    }}
                >
                  {userData.gridView ? (
                      <>
                        <FontAwesomeIcon icon={faWindowMaximize} />
                        <span className="fl-options-span">Full View</span>
                      </>
                  ) : (
                      <>
                        <FontAwesomeIcon icon={faBorderAll} />
                        <span className="fl-options-span">Grid View</span>
                      </>
                  )}
                </div>
              }

              <div
                  onClick={() => {
                    userData.changeTheme();
                    setClicked(!clicked);
                  }}
              >
                {userData.currentTheme === "dark" ? (
                    <>
                      <FontAwesomeIcon icon={faSun} />
                      <span className="fl-options-span">Light Mode</span>
                    </>
                ) : (
                    <>
                      <FontAwesomeIcon icon={faMoon} />
                      <span className="fl-options-span">Dark Mode</span>
                    </>
                )}
              </div>
            </div>
          </div>
        </SimpleCard>
      </div>
      {scroll && userData.encodingStatus.status ?
          <SimpleCard
              id={scroll ? `fl-posting-fixed-${userData.currentTheme}` : null}
              className={shouldHide ? `fl-posting-hidden` : null}
              onContextMenu={(e:any) => e.preventDefault()}
          >
            <div className={`fl-encoding-post`}>
              <div className={"fl-encoding-post-thumb"}>
                {userData.encodingStatus.type === "image" ?
                    <img src={userData.encodingStatus.thumb} alt={"upload thumb"}/> :
                    <video>
                      <source src={userData.encodingStatus.thumb} type="video/mp4"/>
                    </video>
                }
              </div>
              <span className={"fl-encoding-post-thumb-text"}>
                  Processing
                    <div className="fl-post-spinner">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
              </span>
              <Line percent={returnUploadProgress()} strokeWidth={1} strokeColor="#3e98c7"/>
              <span className={"fl-encoding-post-thumb-text"}>
                 {returnUploadProgress() < 1 ? 1 : returnUploadProgress().toFixed(0) }%
              </span>
            </div>
          </SimpleCard> : ""
      }
    </>
  );
};
