import React, { FC } from "react";
import { Link } from "react-router-dom";
import {ProfileHolder} from "../../components/ProfileHolder";
import {useUserContext} from "../../context/UserContext";
import supportLogo from '../../assets/images/supportLogo.png';
import {CheckTime} from "../../components/HandleTime/CheckTime";
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import {useModalContext} from "../../context/ModalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCertificate, faCheck} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

export const SingleComment: FC<any> = ({...props}) => {
    const userData = useUserContext()
    const modalData = useModalContext()
    const checkAdmin = () => {
        if(props.data.is_admin_response) {
            return true
        } else {
            return false
        }
    }

    const images = () => {
        if(props.data.images && props.data.images !== null && props.data.images !== undefined) {
            try {
                return JSON.parse(props.data.images)
            }catch (e) {
                return props.data.images
            }
        } else {
            return ""
        }
    }

    const formatImages = () => {
        const formatImages:any = []
        if(images().length > 0) {
            images().map((x:any) => {
                formatImages.push({url:x})
            })
        }
        return formatImages
    }

    return (
        <React.Fragment>
            <div className={`fl-simple-comment-main fl-simple-comment-problem-main fl-support-no-hover`}>
                <div className="fl-simple-comment-image">
                    <ProfileHolder
                        src={checkAdmin() ? supportLogo : userData.user.profile.profileImage?.url}
                        noUrl
                    />
                </div>
                <div className="fl-simple-comment-text">
                    <div className={"fl-simple-comment-username-name"}>
                        {checkAdmin() ?
                            <span  className={`fl-simple-comment-name fl-problem-comment fl-simple-comment-name-${userData.currentTheme} ${checkAdmin() ? "fl-simple-comment-name-no-hover" : ""}`} >
                                Fluffa Support
                                <div className="fl-user-profile-data-row fl-user-profile-data-row-verified">
                                <div
                                    className="fl-user-profile-verified"
                                >
                                    <FontAwesomeIcon
                                        className="fl-user-profile-verified-crt"
                                        icon={faCertificate}
                                    />
                                    <FontAwesomeIcon
                                        data-tip
                                        data-for="profile-verified"
                                        className="fl-user-profile-verified-chc"
                                        icon={faCheck}
                                    />
                                </div>
                                <ReactTooltip id="profile-verified">
                                    Administrator
                                </ReactTooltip>
                            </div>
                            </span> :
                            <Link
                                to={`/${userData.user.handle}`}
                                className={`fl-simple-comment-name fl-simple-comment-name-${userData.currentTheme}`}
                            >
                                {userData.user.name}
                            </Link>
                        }
                        <div className="fl-post-head-info-dot"></div>
                        <span className="fl-simple-comment-time">
                            <CheckTime data={props.data.created_at} />
                        </span>
                    </div>
                    {images().length > 0 ? (
                        <div className={`fl-file-ticket-comment-main`}>
                            {images().map((e:any,y:any) => (
                                <div className="fl-file-ticket-comment-image"
                                     onClick={() => modalData.toggleImageDm(formatImages(), y, false)}
                                >
                                    <RenderSmoothImage src={e} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="fl-simple-comment-description">
                        {props.data.body}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};