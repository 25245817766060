import React, {FC, useState} from 'react'
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import {CircularProgressbar} from "react-circular-progressbar";
import {Button} from "../../components/Button";
import {LockMessage} from "./LockMessage";
import {useModalContext} from "../../context/ModalContext";
import {MessageDropdown} from "./MessageDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faHeart as faHeartSolid, faRedoAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ReportMessage} from "./ReportMessage";
import VideoPlayer from "../../components/video/VideoPlayer";

export const MessageFiles:FC<any> = (props) => {

    const modalData = useModalContext()
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const countFiles = () => {
        return props.files.length
    }

    const [playing,setPlaying] = useState(false)

    return (
        <div className={`fl-inbox-files fl-inbox-files-${props.direction}`}>
            <div className={`fl-message-bubble-message-attachment fl-message-bubble-message-attachment-${props.direction} fl-message-bubble-message-attachment-${countFiles() < 5 ? countFiles() : "more"}`}>
                {countFiles() < 5 ?
                    <>
                        {props.files.map((x:any,y:any) =>
                            <>
                                <div className={`fl-message-bubble-message-attachment-img`} key={y} onClick={() => x.type !== "video" ? modalData.toggleImageDm(props.files, y) : ""} >
                                    {x.type === "image" &&
                                    <RenderSmoothImage src={x.url || x.src} />
                                    }
                                    {x.type === "video" &&
                                    <VideoPlayer src={x.src} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                    }
                                </div>
                                <div  key={y} className={`fl-message-bubble-message-attachment-unlock fl-message-bubble-message-attachment-unlock-creator fl-message-bubble-animation  fl-message-bubble-animation-${props.like.animation}`}>
                                    {!props.like.status && (
                                        <FontAwesomeIcon
                                            className=""
                                            icon={faHeartSolid}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </> :
                    <>
                        {(props.files || []).filter((x: any, y: number) => y < 3).map((x: any, y: number) => (
                            <div className="fl-message-bubble-message-attachment-img" key={y} onClick={() => modalData.toggleImageDm(props.files, y)}>
                                <RenderSmoothImage src={x.url || x.src} />
                            </div>
                        ))}
                        <div className={`fl-message-bubble-message-attachment-img ${countFiles() > 3 && "fl-message-bubble-message-attachment-img-blur"}`}>
                            <RenderSmoothImage src={props.files.filter((x:any,index:number) => index === 3)[0].url || props.files.filter((x:any,index:number) => index === 3)[0].src} />
                            {countFiles() > 3 &&
                            <span className={"fl-message-bubble-message-attachment-img-blur-count"}> {countFiles()- 3}+</span>
                            }
                        </div>
                    </>
                }
                {props.checkWhoSend() && props.data.fake ?
                    <div className="fl-message-bubble-message-attachment-unlock fl-bubble-progress">
                        {props.cancel ?
                            <div
                                className="fl-direct-messages-box-files-single-remove"
                                onClick={() => props.deleteCancelledUpload(props.data)}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </div> : ""
                        }
                        <CircularProgressbar
                            value={props.progress.percent}
                            text={`${props.progress.percent}%`}
                        /><br/>
                        <span className="circural-progress-bar-span">
                            {props.cancel ? (
                                <>
                                    <text>
                                        {countFiles()} upload{countFiles() > 1 && "s"} cancelled
                                    </text> {" "}
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                </>
                            ) : (
                                <text>
                                    Sending {countFiles()} file{countFiles() > 1 && "s"}
                                </text>
                            )}
                        </span>
                        <span className="fl-message-bubble-message-attachment-cancel">
                            <Button
                                className="fl-message-bubble-message-attachment-unlock-button"
                                type="normal"
                                onClick={() => props.cancel ? props.submitMessage() : props.cancelUpload()}
                            >
                              {props.cancel ? "RETRY" : "CANCEL"}
                              {props.cancel ? <FontAwesomeIcon className="fl-retry-message-svg" icon={faRedoAlt} /> : ""}
                            </Button>
                        </span>
                    </div> : props.checkWhoSend() ?
                    <div className="fl-dm-sent-box">
                        <div className="fl-dm-sent-box-angle">
                            <span>
                              {props.data.price === 0 || props.data.price === "0" ? (
                                  "SENT FOR FREE"
                              ) : (
                                  `SENT FOR $${props.data.price}`
                              )}
                            </span>
                        </div>
                    </div> : ""
                }
                {props.data.unlocked || props.checkWhoSend() ? "" : <LockMessage price={props.data.price} conversationId={props.data.conversation_id} messageId={props.data.id} state={props.state} setState={props.setState} />}
                {props.reportedStatus && !props.checkWhoSend() ? <ReportMessage data={props.data} /> : ""}
                {props.like.status ?
                    <div className={`fl-direct-message-like-message fl-direct-message-like-message-body`}>
                        <span><FontAwesomeIcon icon={faHeartSolid}/></span>
                    </div> : ""
                }
            </div>
            {(props.cancel || props.progress.uploading ) && props.data.fake && isMobile ? "" :
                <>
                    {((props.reportedStatus === 0 && props.data.unlocked) || props.checkWhoSend()) && !isMobile ?
                        <MessageDropdown checkWhoSend={props.checkWhoSend} checkType={props.checkType} setLike={props.setLike} like={props.like} setReportedStatus={props.setReportedStatus} setState={props.setState} state={props.state} data={props.data} direction={props.direction} /> : ""
                    }
                </>
            }
        </div>
    )
}