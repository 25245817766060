import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import "./BackToTop.css";
import { Button } from './Button';



export const BackToTop = () => {

    // STATE
    const [state, setState] = useState<{status: string}>({
        status: "invisible"
    })

    const statusRef = useRef<any>(null);

    useEffect(() => {
        statusRef.current = state.status;
    }, [state.status])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    const buttonStyle: CSSProperties = {
        opacity: state.status === 'visible' ? 1 : 0,
        pointerEvents: state.status === 'visible' ? 'all' : 'none',
        transition: "opacity 300ms ease-in-out"
    }

    useEffect(() => {
        const trackScroll = () => {
            if(window.scrollY > 120 && statusRef.current === 'invisible') {
                setState({status: "visible"});
            } else if(window.scrollY <= 120 && statusRef.current === 'visible') {
                setState({status: "invisible"});
            }
        }
        trackScroll();
        window.addEventListener("scroll", trackScroll);
        return () => {
            window.removeEventListener("scroll", trackScroll);
        }
    },[])

    return (
        <div className="fl-back-to-top" style={buttonStyle}>
            <Button className="fl-back-to-top-button" type="normal" onClick={scrollToTop}>
                <div className="fl-back-to-top-icon">
                    <FontAwesomeIcon icon={faChevronUp}/>
                </div>
            </Button>
        </div>
    )
}