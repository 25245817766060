import {
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faMinusCircle,
  faTimes,
  faChevronCircleLeft,
  faClock, faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { Button } from "../components/Button";
import { FilePicker } from "../components/FilePicker";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { v4 as uuid } from "uuid";
import "./SettingsVerification.css";
import padLock from "../assets/images/padlock.svg";
import '../components/CountryFlags/css/flag-icons.css'
import image1 from "../assets/images/verification/1.png";
import image2 from "../assets/images/verification/2.png";
import { MInput } from "../components/MInput";
import { DatePicker } from "../components/DatePicker";
import { useHistory } from "react-router-dom";
import validator from "validator";
import { SearchMInput } from "../components/SearchMInput";
import ReactLoading from "react-loading";

export const SettingsVerification: FC<any> = (props) => {
  const [state, setState] = useState<{
    twitter: string;
    instagram: string;
    other: Array<string>;
    release_forms: Array<{ file: File | null; id: string }>;
    document_type: string;
    face_id_image: string | null;
    id_image: string | null;
    proof_image: string | null;
    firstname: string;
    lastname: string;
    country: number;
    address: string;
    city: number;
    state: number;
    zip: string;
    id_expire_date: string;
    no_expire_date: string;
    birthday: string;
    reject_reason: string
  }>({
    face_id_image: null,
    id_image: null,
    proof_image: null,
    firstname: "",
    lastname: "",
    country: 0,
    address: "",
    city: 0,
    state: 0,
    zip: "",
    birthday: "",
    id_expire_date: "",
    no_expire_date: "false",
    document_type: "",
    twitter: "",
    instagram: "",
    other: [],
    release_forms: [],
    reject_reason: ""
  });

  const stateRef = useRef<any>(state);

  // CLEAR
  const [clear1, setClear1] = useState(false);
  const [clear2, setClear2] = useState(false);
  const [clear3, setClear3] = useState(0);

  const [countries, setCountries] = useState<
    Array<{ category: string; options: Array<any> }>
  >([]);
  const [cities, setCities] = useState<
    Array<{ category: string; options: Array<any> }>
  >([]);

  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();

  const history = useHistory();

  const onIdPick = (x: any) => {
    setState({ ...state, id_image: x[0] || null });
  };

  const onFaceIdPick = (x: any) => {
    setState({ ...state, face_id_image: x[0] || null });
  };

  const onFaceProofPick = (x: any) => {
    setState({ ...state, proof_image: x[0] || null });
  };

  const [forceErrors, setForceErrors] = useState(false);

  const getCountries = async () => {
    const res = await Axios.get(`${envData.apiUrl}/countries`);
    const allCountries = res.data.data;
    const mostPopular1 = allCountries
      .filter((x: { id: number; name: string; code: string }, y: number) =>
        ["GB", "US"].includes(x.code)
      )
      .map((x: { id: number; name: string; code: string }, y: number) => ({
        value: x.id,
        label: x.name,
        code: x.code,
      }));

    const mostPopular2 = allCountries
      .filter((x: { id: number; name: string; code: string }, y: number) =>
        ["AU", "CA", "FR", "DE", "IT", "ES", "SE", "RU"].includes(x.code)
      )
      .map((x: { id: number; name: string; code: string }, y: number) => ({
        value: x.id,
        label: x.name,
        code: x.code,
      }));

    const allOptions = allCountries.map(
      (x: { id: number; name: string; code: string }, y: number) => ({
        value: x.id,
        label: x.name,
        code: x.code,
      })
    );

    setCountries([
      { category: "Most Popular", options: [...mostPopular1, ...mostPopular2] },
      { category: "All Countries", options: allOptions },
    ]);
  };

  const getCities = async (allOptions: Array<any>) => {

    setCities([{ category: 'All cities', options: allOptions }])
  }
  
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    getCountries();
  }, []);


  const [animation,setAnimation] = useState(false)
  const [sendVerification,setSendVerification] = useState(false)
  const submitVerification = async () => {
    setAnimation(true)
    const stateKeys = Object.keys(state);

    const data = new FormData();
    //@ts-ignore
    //prettier-ignore
    stateKeys.map((x:string) => data.append(x, ['birthday', 'id_expire_date'].includes(x) ? moment(state[x], 'DD/MM/YYYY').format("YYYY-MM-DD") : state[x]))

    try {
      setForceErrors(false);

      await Axios.post(`${envData.apiUrl}/face_id/store`, data);
      setAnimation(false)
      setSendVerification(true)
      userData.updateVerificationStatus("pending")
    } catch (e) {
      setAnimation(false)
      setForceErrors(true);
    }
  };

  const updateCountry = (id: string) => {
    const countryId = Number(id);
    setClear3(1);
    setState({ ...state, country: countryId });
  };

  const addReleaseFormField = () => {
    setState({
      ...state,
      release_forms: [...state.release_forms, { file: null, id: uuid() }],
    });
  };

  const pickDob = async (x: any) => {
    const diff = moment().diff(moment(x, "DD/MM/YYYY"), "years");

    if (diff < 18) {
      await modalData.push({
        title: "Error",
        description: [`You must be aged 18 or older to use Fluffa!`],
        buttons: [
          () => (
            <div className="fl-d-flex fl-justify-flex-end">
              <Button
                type="normal"
                onClick={async () => {
                  setClear1(true);
                  setState({ ...state, birthday: "" });
                  await modalData.close();
                }}
              >
                I understand
              </Button>
            </div>
          ),
        ],
      });
      return;
    }
    if (diff > 99) {
      await modalData.push({
        title: "Error",
        description: [`You must be aged 99 or under to use Fluffa!`],
        buttons: [
          () => (
            <div className="fl-d-flex fl-justify-flex-end">
              <Button
                type="normal"
                onClick={async () => {
                  setClear1(true);
                  setState({ ...state, birthday: "" });
                  await modalData.close();
                }}
              >
                I understand
              </Button>
            </div>
          ),
        ],
      });
      return;
    }

    await modalData.push({
      title: "Confirm age",
      description: [`You're ${diff} years old. Is this correct?`],
      buttons: [
        () => (
          <div className="fl-d-flex fl-justify-flex-end">
            <Button
              onClick={async () => {
                setClear1(true);
                setState({ ...state, birthday: "" });
                await modalData.close();
              }}
            >
              Cancel
            </Button>
            <Button
              type="normal"
              onClick={async () => {
                setState({
                  ...state,
                  birthday: moment(x).format("DD/MM/YYYY"),
                });
                await modalData.closeAll();
              }}
            >
              Confirm
            </Button>
          </div>
        ),
      ],
    });
  };

  const pickExpDate = (x: Date) => {
    setState({ ...state, id_expire_date: moment(x).format("DD/MM/YYYY") });
    modalData.close();
  };

  const disableBtn = () => {
    const {
      firstname,
      lastname,
      country,
      address,
      city,
      zip,
      document_type,
      birthday,
      id_expire_date,
      no_expire_date,
      face_id_image,
      id_image,
      proof_image
    } = state;
    if (
      firstname.length > 0 &&
      lastname.length > 0 &&
      country > 0 &&
      address.length > 0 &&
      city > 0 &&
      zip.length > 0 &&
      document_type.length > 0 &&
      birthday.length > 0 &&
      (id_expire_date.length > 0 || no_expire_date === 'true') &&
      face_id_image != null &&
      id_image != null &&
      proof_image !== null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [mobile,setMobile] = useState<boolean>(false)

  const handleResize = () => {
    if(window.innerWidth < 576) {
      setMobile(true)
    }else {
      setMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const redirectProfile = (e:any) => {
    history.push(`/settings`);
  }

  const [showError,setShowError] = useState(false)

  return (
    <Fragment>
      <div className="fl-settings-sub-stretch">
        <div className={`fl-settings-sub-section ${userData.user.profile.meta.verified_state === "verified" && "fl-pb-0"}`}>
          <div
            className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
          >
             <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
               <FontAwesomeIcon icon={faChevronCircleLeft} />
             </span>
            Verification
          </div>
          {userData.user.profile.meta.verified_state === "pending" || sendVerification ? (
              <Fragment>
                <p className={"pending-p"}>
                  Verification under review {""}
                  <span style={{ color: "orange" }}>
                  <FontAwesomeIcon
                      style={{ color: "orange" }}
                      icon={faClock}
                  />
                </span>
                </p>
                <p className={"pending-p"}>
                  We’re currently reviewing your verification request and will get back to you shortly!
                </p>
              </Fragment>
          ) : userData.user.profile.meta.verified_state === "verified" ? (
            <Fragment>
                <span className="fl-settings-sub-account-update-span">
                    Your account was approved{" "}
                  <FontAwesomeIcon
                      style={{ color: "green" }}
                      icon={faCheckCircle}
                  />
                </span>
            </Fragment>
          ) : (

            <div className="fl-settings-sub-section">
              {userData.user.profile.meta.verified_state === "rejected" ? (
                 <div className={"fl-settings-reject"}>
                   {userData.user.profile.was_verified_before === 0 || !userData.user.profile.was_verified_before ? "Your account was rejected" : "Your account verification has been removed "} {" "}
                   <span style={{ color: "red" }}>
                    <FontAwesomeIcon
                        style={{ color: "red" }}
                        icon={faTimesCircle}
                    />
                  </span>
                   <br />
                   <br />
                   Reason: <b>{userData.user.profile.reject_reason}</b>
                   <br /> <br />
                   Please fix the issue and request verification again.
                 </div>
              ) : ("") }
              <div className="fl-settings-verification-info  fl-settings-mt-30">
                Start earning on Fluffa by verifying your identify.
                <br />
                <br />
                Please add your legal name, address and government issued photo
                ID.
              </div>
              <div className="fl-settings-sub-section">
                <div className="fl-settings-sub-payment-secured">
                  <img src={padLock} alt={`fluffa`}  /> Your personal info is private and safe
                </div>
              </div>
              <div className="fl-d-flex fl-justify-space-between fl-settings-mt-30">
                <MInput
                  forceErrors={forceErrors}
                  validator={[
                    {
                      check: (v) => !validator.isEmpty(v),
                      message: "Enter First name",
                    },
                  ]}
                  type="text"
                  style={{ width: "calc(50% - 10px)" }}
                  label="First name"
                  value={state.firstname}
                  setValue={(firstname) => setState({ ...state, firstname })}
                />
                <MInput
                  forceErrors={forceErrors}
                  validator={[
                    {
                      check: (v) => !validator.isEmpty(v),
                      message: "Enter Last name",
                    },
                  ]}
                  type="text"
                  style={{ width: "calc(50% - 10px)" }}
                  label="Last name"
                  value={state.lastname}
                  setValue={(lastname) => setState({ ...state, lastname })}
                />
              </div>
              <MInput
                forceErrors={forceErrors}
                validator={[
                  {
                    check: (v) => v > 0,
                    message: "Country is required",
                  },
                ]}
                preRender={(x) => (
                  <div className={`fl-settings-sub-verification-country-img flag-icon-background flag-icon-${x.code.toLowerCase()}`}>
                  </div>
                )}
                type="select"
                label="Country"
                value={state.country}
                setValue={(country) => updateCountry(country)}
                options={countries}
                className={"fl-settings-mt-30"}
              />
              <div  className={"fl-settings-mt-30"}>
                <SearchMInput
                    searchUrl={`${envData.apiUrl}/country/${state.country}/cities/search`}
                    forceErrors={forceErrors}
                    validator={[
                      {
                        check: (v) => v > 0,
                        message: "Town/City/State is required",
                      },
                    ]}
                    type="select"
                    label="Type to search for your Town/City/State"
                    category='All cities'
                    value={state.city}
                    options={cities}
                    setOptions={getCities}
                    clear={clear3}
                    setClear={setClear3}
                    setValue={(city) => setState({ ...state, city })}
                    hideArrow
                />
              </div>
              <MInput
                forceErrors={forceErrors}
                validator={[
                  {
                    check: (v) => !validator.isEmpty(v),
                    message: "Address is required",
                  },
                ]}
                type="text"
                label="Address"
                value={state.address}
                setValue={(address) => setState({ ...state, address })}
                className={"fl-settings-mt-30"}
              />
              
              <MInput
                forceErrors={forceErrors}
                validator={[
                  {
                    check: (v) => !validator.isEmpty(v),
                    message: "Postal/Zip is required",
                  },
                ]}
                type="text"
                label="Postal/Zip"
                value={state.zip}
                setValue={(zip) => setState({ ...state, zip })}
                className={"fl-settings-mt-30"}
              />
              <MInput
                type="text"
                label="Twitter (optional)"
                value={state.twitter}
                setValue={(twitter) => setState({ ...state, twitter })}
                className={"fl-settings-mt-30"}
              />
              <MInput
                type="text"
                label="Instagram (optional)"
                value={state.instagram}
                setValue={(instagram) => setState({ ...state, instagram })}
                className={"fl-settings-mt-30"}
              />

              {state.other.map((x, y) => (
                <div className="fl-settings-sub-verification-other">
                  <MInput
                    className="fl-settings-sub-verification-other-input fl-settings-mt-30"
                    key={y}
                    type="text"
                    label={`Other social links (optional) ${
                      state.other.length > 1 ? `#${y + 1}` : ""
                    }`}
                    value={x}
                    setValue={(other) =>
                      setState({
                        ...state,
                        other: state.other.map((a, b) => (b === y ? other : a)),
                      })
                    }
                  />
                  <div
                    className="fl-settings-sub-verification-other-remove"
                    onClick={() =>
                      setState({
                        ...state,
                        other: state.other.filter((a, b) => y !== b),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              ))}
              <div
                className="fl-settings-sub-verification-add-other fl-hover-underline"
                onClick={() =>
                  setState({ ...state, other: [...state.other, ""] })
                }
              >
                Add another social network
              </div>
              <div className="fl-settings-sub-account-field">
                <DatePicker
                  forceErrors={forceErrors}
                  label="Date of birth"
                  clear={clear1}
                  setClear={setClear1}
                  value={state.birthday}
                  onSave={pickDob}
                  className="fl-settings-sub-account-field-input  fl-settings-mt-30"
                  max={
                    new Date(
                      new Date().getFullYear() - 18,
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  }
                />
                {state.birthday &&
                moment(new Date()).diff(
                  moment(state.birthday, "DD/MM/YYYY"),
                  "years"
                ) > 18 ? (
                  <span
                    className={`fl-settings-sub-verification-age fl-settings-sub-verification-age-${userData.currentTheme}`}
                  >
                    Age:{" "}
                    {moment(new Date()).diff(
                      moment(state.birthday, "DD/MM/YYYY"),
                      "years"
                    )}{" "}
                    <FontAwesomeIcon
                      style={{ color: "green" }}
                      icon={faCheck}
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>
              <MInput
                className={" fl-settings-mt-30"}
                forceErrors={forceErrors}
                validator={[
                  {
                    check: (v) => !validator.isEmpty(v),
                    message: "Select Document type",
                  },
                ]}
                type="select"
                label="Document type"
                value={state.document_type}
                setValue={(document_type) =>
                  setState({ ...state, document_type })
                }
                options={[
                  {
                    category: "Document Types",
                    options: [
                      { value: "driving_licence", label: "Driving licence" },
                      { value: "passport", label: "Passport" },
                      { value: "passport_card", label: "Passport Card" },
                      { value: "id_card", label: "National ID Card" },
                    ],
                  },
                ]}
                onClick={() => setShowError(true)}
              />
              {state.document_type.length === 0 && showError &&
                  <span className="fl-modal-description-row-error">
                     <FontAwesomeIcon icon={faInfoCircle} /> Document type is required
                  </span>
              }
              <div className="fl-settings-note">
                NOTE: Please ensure your document is in-date and does not expire
                within 30 days{" "}
              </div>
              <div className="fl-settings-verification-file-with-image">
                <div
                  className={`fl-settings-verification-top-text fl-settings-verification-top-text-${userData.currentTheme}`}
                >
                  Please upload a photo of your ID.
                </div>
                <div className="fl-settings-verification-file">
                  <FilePicker
                    accept="image/x-png,image/jpeg"
                    preview="image"
                    required={true}
                    requiredText={
                      "Your account will be rejected if we are unable to verify your identity"
                    }
                    label="Photo of your ID"
                    description={mobile ? "" : "Please upload a photo of your ID."}
                    onPick={onIdPick}
                    disable={state.document_type.length > 0 ? false : true}
                  />
                </div>
                <div className="fl-settings-verification-image">
                  <span
                    className="fl-settings-verification-image-event"
                    onClick={() => modalData.toggleImage(image2)}
                  >
                    <img
                      className="fl-settings-verification-image-src"
                      src={image2}
                      alt="ID Card"
                    />
                  </span>
                  <div className="fl-settings-verification-image-description">
                    <div className="fl-settings-verification-image-title">
                      GOOD EXAMPLE <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="fl-settings-verification-image-text">
                      Make sure that your ID images:
                      <ul>
                        <li>Are taken in good light</li>
                        <li>Are large enough to clearly see the text</li>
                        <li>Are clear, in colour, with no glare</li>
                        <li>Are in JPEG or PNG format</li>
                        <li>Are unedited and don't have added filters</li>
                        <li>Shows the full document with all four corners</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fl-settings-verification-file-with-image">
                <div
                  className={`fl-settings-verification-top-text fl-settings-verification-top-text-${userData.currentTheme}`}
                >
                  Please upload a selfie photo of you holding your ID.
                </div>
                <div className="fl-settings-verification-file">
                  <FilePicker
                    accept="image/x-png,image/jpeg"
                    preview="image"
                    required={true}
                    requiredText={
                      "We must be able to verify that your photo ID and selfie photo are of the same person"
                    }
                    label="Photo of you holding your ID"
                    description={mobile ? "" : "Please upload a selfie photo of you holding your ID."}
                    onPick={onFaceIdPick}
                    disable={state.document_type.length > 0 ? false : true}
                  />
                </div>
                <div className="fl-settings-verification-image">
                  <span
                    className="fl-settings-verification-image-event"
                    onClick={() => modalData.toggleImage(image1)}
                  >
                    <img
                      className="fl-settings-verification-image-src"
                      src={image1}
                      alt="Face ID"
                    />
                  </span>
                  <div className="fl-settings-verification-image-description">
                    <div className="fl-settings-verification-image-title">
                      GOOD EXAMPLE <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="fl-settings-verification-image-text">
                      As well as a clear photo of the uploaded ID, we require a
                      selfie photo of you holding the ID.
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fl-settings-sub-account-field fl-settings-mt-20"
                style={{ marginBottom: 15 }}
              >
                {state.release_forms.map(
                  (x: { file: File | null; id: string }, y: number) => (
                    <div
                      key={x.id}
                      className="fl-d-flex fl-justify-space-between fl-align-center"
                    >
                      <div className="fl-settings-verification-forms-file">
                        <FilePicker
                          accept="image/x-png,image/jpeg"
                          preview="image"
                          label=""
                          description=""
                          onPick={(file) =>
                            setState({
                              ...state,
                              release_forms: state.release_forms.map(
                                (a: any, b: number) =>
                                  b !== y ? a : { ...a, file: file[0] }
                              ),
                            })
                          }
                        />
                      </div>
                      <div className="fl-settings-verification-forms-remove">
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          onClick={() =>
                            setState({
                              ...state,
                              release_forms: state.release_forms.filter(
                                (a, b) => a.id !== x.id
                              ),
                            })
                          }
                        />
                      </div>
                    </div>
                  )
                )}
                {state.release_forms.length > 0 ? (
                  <Button
                    className="fl-settings-verification-add-files-button"
                    type="normal"
                    onClick={addReleaseFormField}
                  >
                    {state.release_forms.length
                      ? "Add more files"
                      : "Add release forms"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <div className="fl-settings-sub-account-field">
                <DatePicker
                  label="ID expiration date"
                  clear={clear2}
                  setClear={setClear2}
                  value={state.id_expire_date}
                  onSave={(x) => pickExpDate(x)}
                  className="fl-settings-sub-account-field-input"
                  min={new Date()}
                />
                <div className="fl-settings-sub-verification-oxox" style={{ marginTop:15 }}>
                  <label className="fl-settings-sub-verification-checkbox-input">
                    <input
                        value="true"
                        type="checkbox"
                        onChange={() => {
                         if(state.no_expire_date === "false")
                         {
                           setState({...state, no_expire_date: "true"})
                         }
                          else if(state.no_expire_date === "true")
                          {
                            setState({...state, no_expire_date: "false"})
                          }
                        }
                        }
                    />
                    <span></span>
                  </label>
                  <span className="fl-settings-sub-verification-checkbox-text">
                    No expiration date
                  </span>
                  <div
                      style={{ cursor: "pointer" }}
                      className={"fl-settings-mt-30"}
                  >
                  <span
                      onClick={() =>
                          state.release_forms.length === 0
                              ? addReleaseFormField()
                              : setState({ ...state, release_forms: [] })
                      }
                      className={"fl-blue-link fl-hover-underline"}
                  >
                    Add release forms
                  </span> {" "}
                    <FontAwesomeIcon
                        className="fl-file-picker-label-icon fl-grey"
                        icon={faInfoCircle}
                        data-tip
                        data-for="release_forms"
                    />
                    <ReactTooltip id="release_forms">
                      If any participants appearing <br/>
                      in your content don’t have <br/>
                      verified Fluffa accounts, <br/>
                      we require a signed release form for each participant.  <br/>
                      This ensures the safety of anyone appearing<br/>
                      in the content, and allows us to  <br/>
                      help if your post is reported,  <br/>
                      or if there is a DMCA complaint.<br/>
                      <br />
                      If any of the participants are  <br/>
                      already verified on Fluffa,<br/>
                      please tag/link them in your posts.
                    </ReactTooltip>
                  </div>
                </div>
              </div>
              <div
                style={{marginTop:'20px'}}
                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                  Final step:
              </div>
              <p className={"fl-settings-verification-title fl-settings-verification-forms-header"}>Please attach a photo of yourself holding a sheet of paper with the following information:</p>
              <ol className='fl-settings-verification-forms-list'>
                <li className={"fl-settings-mt-10"}>1. <span>Your Full name</span></li>
                <li className={"fl-settings-mt-10"}>2. Your Fluffa <span style={{fontWeight:'bold'}}>@username</span></li>
                <li className={"fl-settings-mt-10"}>3. Today's date</li>
              </ol>
              <div className="fl-settings-verification-file-with-image">
                <div className="fl-settings-verification-file">
                  <FilePicker
                    accept="image/x-png,image/jpeg"
                    preview="image"
                    required={true}
                    requiredText={ mobile ?
                         <>
                           Please upload a selfie photo <br/> of you holding the sheet of paper.
                         </>
                        : "Please attach the file in JPEG or PNG format."
                    }
                    label={"Photo of you holding the sheet of paper"}
                    description={mobile ? "" : "Please upload a selfie photo of you holding the sheet of paper."}
                    onPick={onFaceProofPick}
                  />
                </div>
                </div>
                <div className={"fl-settings-mt-20"}>
                      <span className='fl-settings-verification-forms-header fl-settings-verification-title'>Please, make sure that the photo you upload:</span>
                      <br />
                      <ul className='fl-settings-verification-forms-list-gray'>
                      <li className={"fl-settings-mt-5"}>Includes the above information, handwritten on a clean sheet of paper</li>
                      
                      <li className={"fl-settings-mt-5"}>Includes both the hand that is holding the sheet of paper and your entire face</li>
                      
                      <li className={"fl-settings-mt-5"}>Is well-lit and is not too small, dark or blurry</li>
                      </ul>
              </div>
              <div className="fl-d-flex fl-justify-flex-end">
                <Button
                  disabled={!disableBtn() || animation}
                  style={{ marginTop: 50 }}
                  type="normal"
                  onClick={submitVerification}
                  className="fl-settings-sub-security-button"
                >
                  Request Verification
                  {animation && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
