import React, { FC, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneAlt,
  faStopCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment, {defaultFormat} from "moment";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import { useEnvContext } from "../../../../../../context/EnvContext";
import Axios from "axios";
import {MessageAudio} from "../../../../../../components/MessageAudio";
import {useUserContext} from "../../../../../../context/UserContext";
import {useModalContext} from "../../../../../../context/ModalContext";
import {useHistory} from "react-router-dom";
//@ts-ignore
import { ReactMic } from 'react-mic';

var localAudioStream: MediaStream | null = null;
var localTimer: any = null;
var mediaRecorder: any = null;
var mediaRecorderChunks: any = [];

const Voice: FC<any> = ({ users,state,setState }) => {

  const envData = useEnvContext();

  const stateRef = useRef<any>();
  const waveformRef = useRef<any>();

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const userData = useUserContext();
  const modalData = useModalContext();
  const history = useHistory();

  const [record,setRecording] = useState(false);

  const [recordingWave, setRecordingWave] = useState<boolean>(false);

  const sendRecording = async () => {
    setRecording(false)
    setState({...state, recording:false,previewVoice: {}})
    setTimeout(async () => {
      try {
        if (progress.uploading === true) return;
        setProgress({ percent: 0, uploading: true });

        const fakeId = uuidv4();

        // Faking message
        const fakeMessage = {
          audio: state.previewVoice.fakeMessagePreview.fakeAudio,
          type: "FAKE_VOICE",
          fakeId,
          sender: userData.user.profile,
          created_at: moment(new Date()).format(defaultFormat)
        };
        setState({
          ...stateRef.current,
          body: "",
          messages: [...stateRef.current.messages, fakeMessage],
        });
        const targets = users.selectedUsers.map((e: any) => e.user_id);
        const formData = new FormData();
        formData.append("attachments[]", state.previewVoice.fakeMessagePreview.file);
        for (let i = 0; i < targets.length; i++) {
           formData.append("user_ids[" + i + "]", targets[i]);
        }
        const res = await Axios.post(
            `${envData.apiUrl}/conversations/bulk-deliver`,
            formData,
            {
              headers: {"content-type": "multipart/form-data"},
              onUploadProgress: (progressEvent) => {
                setProgress({
                  uploading: true,
                  percent: Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100 - 1
                  ),
                });
              },
            }
        );
        setProgress({ percent: 0, uploading: false });
        history.push("/inbox");
        modalData.pushToast(
            "success",
            `Your messsage was sent to ${
                users.selectedUsers.length
            } ${`recipient${users.selectedUsers.length !== 1 ? "s" : ""}`}`
        );
        setState({
          ...stateRef.current,
          messages: [
            ...stateRef.current.messages.filter(
                (x: any) => x.fakeId !== fakeId
            ),
            res.data.data,
          ],
          recording: false,
          deleting: false,
          timer: 0,
          chunks: [],
          previewVoice: {}
        });
      }catch (e) {
        setProgress({ percent: 0, uploading: false });
        modalData.pushToast("error", "Something went wrong!");
      }
    }, 10)
  }


  const handleRecordStop = () => {
    try {
      if (progress.uploading === true) return;
      setProgress({ percent: 0, uploading: true });

      mediaRecorder.onstop = async () => {
        const blob = new Blob(mediaRecorderChunks, {
          type: "audio/ogg; codecs=opus",
        });
        const audioURL = window.URL.createObjectURL(blob);
        mediaRecorderChunks = [];
        var file = new File([blob], "record.ogg");

        mediaRecorder = null;
        if (!stateRef.current.deleting) {
          // SEND TO SERVER

          const formData = new FormData();
          formData.append("attachments[]", file);

          const fakeIdPreview = uuidv4();

          // Faking preview message
          const fakeMessagePreview = {
            audio: audioURL,
            type: "FAKE_VOICE_PREVIEW",
            fakeIdPreview,
            sender: userData.user.profile,
            created_at: moment(new Date()).format(defaultFormat),
            file:file,
            fakeAudio: audioURL
          };
          setState({
            ...stateRef.current,
            previewVoice: {...stateRef.current.messages, fakeMessagePreview},
          });
          setProgress({ percent: 0, uploading: false });
          if (localTimer) clearTimeout(localTimer);
          if (localAudioStream)
            localAudioStream.getTracks().forEach((x) => x.stop());
        } else {
          setState({
            ...stateRef.current,
            recording: false,
            deleting: false,
            timer: 0,
            chunks: [],
          });
          if (localTimer) clearTimeout(localTimer);
          if (localAudioStream)
            localAudioStream.getTracks().forEach((x) => x.stop());
        }
      };
      if (mediaRecorder) mediaRecorder.stop();
    }catch (e) {
      setProgress({ percent: 0, uploading: false });
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const increaseTimer = () => {
    setState({
      ...stateRef.current,
      timer: stateRef.current.timer + 1000,
      chunks: [...stateRef.current.chunks, Math.round(Math.random() * 100)],
    });
    localTimer = setTimeout(increaseTimer, 1000);
  };

  const startRecording = () => {
    if (!localAudioStream) return;
    increaseTimer();
    //@ts-ignore
    mediaRecorder = new MediaRecorder(localAudioStream);
    mediaRecorderChunks = [];

    mediaRecorder.ondataavailable = function (e: any) {
      mediaRecorderChunks.push(e.data);
    };
    mediaRecorder.start();
  };

  const handleRecordStart = async () => {
    // await Axios.post(
    //     `${envData.apiUrl}/conversations/${id}/recording`
    // );
    // if (!recordingState) {
    //   recordingState = true;
    //   setTimeout(() => {
    //     recordingState = false;
    //   }, 10000);
    // }
    setRecordingWave(true);
    localAudioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    if (localAudioStream) {
      setState({ ...stateRef.current, recording: true });
      setTimeout(() => {
        startRecording();
      }, 1000);
    } else {
      alert("Audio not enabled!");
    }
  };

  const deleteRecording = () => {
    setState({ ...stateRef.current, deleting: true });
    setState({...state, recording: false,previewVoice: {} ,chunks: [],timer: 0})
    // handleRecordStop();
  };

  const [progress, setProgress] = useState<{
    uploading: boolean;
    percent: number;
  }>({
    uploading: false,
    percent: 0,
  });

  return (
    <>
      {!state?.previewVoice?.fakeMessagePreview?.audio ?
          <div
              draggable={true}
              className={`fl-direct-messages-box-input-action-single fl-direct-messages-box-input-action-single-voice ${state.recording && "fl-direct-messages-box-input-action-single-voice-record"} ${!state.recording && "fl-voice-record-cursor-enable"}`}
              onClick={handleRecordStart}
              onContextMenu={(e) => e.preventDefault()}
          >
            <FontAwesomeIcon
                icon={
                  faMicrophoneAlt
                }
                data-tip
                data-for="voice"
            />
            < ReactTooltip id="voice">
              {!state.recording ? "Send audio" : "Recording..."}
            </ReactTooltip>
          </div>
          :
          <>
            <div
                className="fl-direct-messages-box-input-action-single fl-voice-bulk-recording"
                onClick={deleteRecording}
            >
              <FontAwesomeIcon
                  icon={faTrashAlt}
                  data-tip
                  data-for="delete"
              />
              <ReactTooltip id="delete">Delete audio</ReactTooltip>
            </div>
          </>
      }
      {!progress.uploading &&
      (state?.previewVoice?.fakeMessagePreview?.audio ?
              <MessageAudio
                  reportedStatus={""}
                  deleteRecording={""}
                  src={
                    state?.previewVoice?.fakeMessagePreview?.audio
                  }
                  recording={state.recording}
                  sendRecording={sendRecording}
                  data={""}
              />
              :
              <div
                  className={`fl-direct-messages-record-wrapper ${
                      state.deleting
                          ? "fl-direct-messages-record-wrapper-deleting"
                          : ""
                  } ${
                      state.recording
                          ? "fl-direct-messages-record-wrapper-active"
                          : ""
                  }`}
              >
                <div className="fl-direct-messages-record-wrapper-inner">
                  <div
                      className={`fl-direct-messages-box-input-action-single-voice-stop ${state.recording && "fl-voice-record-cursor-enable"}`}
                      onClick={handleRecordStop}
                      // onClick={deleteRecording}
                  >
                    <FontAwesomeIcon icon={faStopCircle} data-tip data-for="stop-recording"/>
                    <ReactTooltip id="stop-recording">Stop recording</ReactTooltip>
                  </div>
                  <div
                      className="fl-direct-messages-record-audio-chunks"
                      ref={waveformRef}
                  >
                    <ReactMic
                        record={recordingWave}
                        className="sound-wave"
                        strokeColor="#ffff"
                        backgroundColor="#007eff"
                        noiseSuppression={true}
                    />
                  </div>
                  <div className="fl-direct-messages-record-timer">
                    {moment(state.timer, "x").format("mm:ss")}
                  </div>
                </div>
              </div>
      )
      }
    </>
  );
}

export default Voice;
