import { faInfoCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import "./FilePicker.css";
import { Button } from './Button';
import ReactTooltip from 'react-tooltip';

const getBase64 = (file:File) => {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = (ev:any) => {
        resolve(ev.target.result)
      }
      reader.readAsDataURL(file)
    })
  }

export const FilePicker:FC<{setShowError?:any;multifile?:boolean, preview?: string, accept?: string, label?: string, requiredText?: any, required?: boolean, description?: string, onPick: (f: any) => void, disable?:any}> = ({setShowError,multifile, preview, requiredText, accept, label, required,description, onPick,disable}) => {

    const [state, setState] = useState<{names: Array<string>}>({
        names: []
    })

    const [source, setSource] = useState<Array<{url: string, file: File}>>([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const pickFile = async (e:React.FormEvent<HTMLInputElement>) => {
        if(!e.currentTarget?.files)return;

        const iterList = [];
        for await (let x of e.currentTarget.files)iterList.push(x);

        const retFileList:any = [];
        const sourcesList:any = [];
        for await(let [i,x] of iterList.entries()){
            retFileList.push(x);

            if(i === 0 || multifile === true){
                const result = await getBase64(x);
                sourcesList.push({url: result, file: x});
            }
        }
        setSource([...(multifile ? source : []), ...sourcesList]);
        setState({...state, names: retFileList.map((x:File) => x.name)});
        
        e.currentTarget.value = '';
    }

    useEffect(() => {
        onPick(source.map(x => x.file));
        
    },[source])

    return (
        <div className={`fl-file-picker-main`}>
            {label ? <div className="fl-file-picker-label">
                <span>{label}</span>
                {required === true ? <Fragment>
                    <FontAwesomeIcon className="fl-file-picker-label-icon" data-tip data-for={requiredText} icon={faInfoCircle}/>
                    <ReactTooltip id={requiredText}>{requiredText}</ReactTooltip>    
                </Fragment> : ''}
            </div> : ''}
            { source.length ? <div className="fl-file-picker-images">
                { source.map((x,y) => <div className="fl-file-picker-image">
                    <img key={y} src={x.url} alt={"fluffa"} />
                    <div className="fl-file-picker-image-remove" onClick={() => setSource(source.filter((a,b) => y !== b))}>
                        <FontAwesomeIcon icon={faMinusCircle}/>
                    </div>
                </div>) }
            </div> : ''}
            <Button className="fl-file-picker-button" type="outlined" disabled={disable} onClick={() => inputRef?.current?.click()} >
                <span className="fl-file-picker-names" data-tip data-for={`${disable && "document"}`}>
                    { source.length ? multifile ? "Add More Files" : "Choose a Different File" : "Choose File" }
                </span>
                <input ref={inputRef} multiple={multifile ? true : false} type="file" style={{display: "none"}} onChange={pickFile} accept={accept || '*'}/>
                {disable &&  <ReactTooltip id="document">Document type is required</ReactTooltip>}
            </Button>
            {description ? <div className="fl-file-picker-description">{description}</div> : ''}
        </div>
    )
}