import React, { FC,Fragment } from "react";
import { Link } from "react-router-dom";
import "./UnapprovedBox.css";
import { SimpleCard } from "./SimpleCard";
import {useUserContext} from "../context/UserContext";

export const UnapprovedBox: FC<any> = ({props,state,setState}) => {

    const userData = useUserContext();

    return (
    <Fragment>
        {userData.user.profile.meta.verified_state !== "verified" && localStorage.getItem('messageForUnapprovedPost') === 'show' ? (
            <SimpleCard className="fl-unapproved-box">
                <span>Your posts won't go live until your account has been approved.</span> <br /><br />
                <span>Please click <Link to="/settings/verification">here</Link> to request verification.</span>
            </SimpleCard>
        ) : "" }
        {userData.user.subscriptionPlans.length === 0 && userData.user.profile.free_subscription === 0 && userData.user.profile.meta.verified_state === "verified" ?
            <SimpleCard className="fl-unapproved-box">
                <span>Your profile won't be visible until you've set it to Free or Paid.</span> <br /><br />
                <span>Please click <Link to="/settings/plans">here</Link> to complete set up.</span>
            </SimpleCard> : ""
        }
    </Fragment>
    );
};
