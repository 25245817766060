import React, { FC, useRef, useEffect } from "react";
import { useUserContext } from "../context/UserContext";
import "./Textarea.css";

export const Textarea: FC<any> = (props) => {
  const inputEl = useRef<any>(null);

  // CONTEXT
  const userData = useUserContext();

  const filteredProps = Object.keys(props)
    .filter((key) => !["className", "style", "type"].includes(key))
    .reduce((obj: any, key: any) => {
      obj[key] = props[key];
      return obj;
    }, {});

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.style.height = "0px";
      const scrollHeight = inputEl.current.scrollHeight;
      inputEl.current.style.height = scrollHeight + "px";
    }
  }, [props.value]);

  return (
    <div
      className={`fl-textarea-outter ${props.className}`}
      style={props.style}
    >
      <textarea
        {...filteredProps}
        ref={inputEl}
        className={`fl-textarea fl-textarea-${userData.currentTheme} ${
          props?.restrictedWords?.length > 0 ? "fl-restricted-box" : ""
        }`}
      />
    </div>
  );
};
