import React, { FC } from "react";
import { useUserContext } from "../context/UserContext";
import "./SimpleCard.css";

export const SimpleCard: FC<any> = (props) => {
  const { children } = props;
  const userData = useUserContext();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <div className="fl-simple-card-outter">
      <div
        {...props}
        className={`fl-simple-card-main fl-simple-card-main-${userData.currentTheme} ${props.className} ${
          props.style
        } ${isMobile ? "fl-simple-card-main-mobile" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};
