
export class ObjectHelper {
    static deepEqual = (object1: any, object2: any) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
  
        const isObject = (object: any) => {
          return object !== null && typeof object === 'object';
        }
  
        if (keys1.length !== keys2.length) {
          return false;
        }
  
        for (const key of keys1) {
          const val1 = object1[key];
          const val2 = object2[key];
          const areObjects = isObject(val1) && isObject(val2);
            if (
                // @ts-ignore
            (areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)
          ) {
            return false;
          }
        }
        return true;
    }
}
