import React, { FC, useState, useEffect, useRef } from "react";
import "./Accordion.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {useUserContext} from "../../../context/UserContext";
import {useEnvContext} from "../../../context/EnvContext";

export const Accordion: FC<any> = ({ head, body, closeOnBlur, controlActive,arrows,noHover,close,setClose,single,bodySearch,activeSearch }) => {
  const [state, setState] = useState<{ active: boolean }>({
    active: false,
  });

  const userData = useUserContext()
  const envData = useEnvContext();

  useEffect(() => {
    setState({ active: controlActive ?? false });
  }, [controlActive]);


  const accordionRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //@ts-ignore
    if (!accordionRef.current?.contains(envData.clickedElement)) {
      setState({active:false})
    }
  }, [envData.clickedElement]);

  return (
    <div
      className={`fl-filter__accordion ${!state.active && !noHover && `fl-filter__accordion-hover-${userData.currentTheme}`}`}
      ref={accordionRef}
    >
      <div
        className={`fl-filter__accordion-toggle`}
        ref={toggleRef}
        onClick={(event: any) => {
          event.preventDefault();
          if(typeof controlActive === 'undefined') { // check if the active state is controlled outside
            if(single) {
              setState({ ...state, active: !state.active });
              setClose(false)
            } else {
              setState({ ...state, active: !state.active });
            }
          }
        }}
      >
        {head}
      </div>
      {state.active && !close && body &&
        <div className="fl-filter__accordion-body">{body}</div>
      }
      {bodySearch ?  <div className="fl-filter__accordion-body">{bodySearch}</div> : ""}
      {arrows &&
        <>
        <div
            className={`fl-minput-input-clear fl-minput-input-clear-${userData.currentTheme} `}
            onClick={(event: any) => {
              event.preventDefault();
              if(typeof controlActive === 'undefined') { // check if the active state is controlled outside
                setState({ ...state, active: !state.active });
              }
            }}
        >
          <FontAwesomeIcon icon={state.active && body ? faChevronUp : faChevronDown} />
        </div>
        </>
      }
    </div>
  );
};

export default Accordion;
