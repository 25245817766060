import React, {FC, useRef, useState} from "react";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHdd, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {useModalContext} from "../../../../../../context/ModalContext";
import {FluffaDrive} from "../../../../../../components/FluffaDrive";

const Attach: FC<any> = ({ message, setMessage, type }) => {

  const modalData = useModalContext();

  const attachRef = useRef<any>(null);

  const handleFile = () => async (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) return;

    const pFileReader = (file: any) => {
      return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = resolve;
        fr.readAsDataURL(file);
      });
    };

    const uplodedFiles: any = e.currentTarget.files || [];
    const filesArray: any = [...uplodedFiles];
    const newFiles = await Promise.all(
      filesArray.map(async (file: any) => {
        const res: any = await pFileReader(file);
        return {
          type: file.type.split("/")[0],
          file,
          src: res.srcElement.result,
        };
      })
    );
    setMessage({
      ...message,
      files: [...message.files, ...newFiles],
    });
  };

  const attachRefFile = useRef<any>(null);
  const flDrive = () => {
    modalData.push(() => <FluffaDrive  route={"files"} setUploadState={setMessage} uploadState={message} />)
  };

  return (
    <>
      {type === "icon" ? (
          <>
            <input
                ref={attachRefFile}
                id="upload_image"
                multiple
                accept="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
                type="file"
                style={{ display: "none" }}
                onChange={handleFile()}
            />
            <div className="fl-direct-messages-box-input-action-single"   onClick={() => attachRefFile.current.click()}>
              <FontAwesomeIcon
                  icon={faPaperclip}
                  data-tip
                  data-for="attach"
                  style={{ cursor: "pointer" }}
              />
              <ReactTooltip id="attach">
                Attach files
              </ReactTooltip>
            </div>
            <div className="fl-direct-messages-box-input-action-single">
              <FontAwesomeIcon
                  icon={faHdd}
                  data-tip
                  data-for="drive"
                  style={{ cursor: "pointer" }}
                  onClick={() => flDrive()}
              />
              <ReactTooltip id="drive">Insert files from Drive</ReactTooltip>
            </div>
          </>
      ) : (
        <>
          <div
            className="fl-bulk__input__files--line--add"
            onClick={() => attachRef.current.click()}
            data-tip
            data-for="add-media"
          >
            <input
              ref={attachRef}
              id="upload_image"
              multiple
              accept="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
              type="file"
              style={{ display: "none" }}
              onChange={handleFile()}
            />
            +
          </div>
          <ReactTooltip id="add-media">Add more</ReactTooltip>
        </>
      )}
    </>
  );
};

export default Attach;
