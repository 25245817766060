import React, {FC, Fragment} from "react";
import { useUserContext } from "../context/UserContext";
import { Button } from "./Button";
import "./Subroutes.css";

// ICONS
import homeIcon from "../assets/images/icons/9.svg";
import notificationsIcon from "../assets/images/icons/10.svg";
import messagesIcon from "../assets/images/icons/11.svg";
import {faCircle, faCompass, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ProfileHolder} from "./ProfileHolder";

export const Subroutes: FC<any> = (props) => {

  // CONTEXT
  const userData = useUserContext();

  const items: any = [
    { label: "Home", to: "/feed", type: "image", icon: homeIcon},
    { label: "Explore", to: "/explore/all", type: "icon", icon: faCompass },
    {
      label: "Notifications",
      to: "/notifications",
      type: "image",
      icon: notificationsIcon,
      counter: userData.user.meta.new_notifications,
    },
    {
      label: "Purchased",
      to: "/purchased",
      type: "icon",
      icon: faShoppingCart
    },
    {
      label: "Messages",
      to: "/inbox",
      type: "image",
      icon: messagesIcon,
      counter: userData.user.meta.unread_messages,
    },
    {
      label: `${userData.user.handle}`,
      to: `/${userData.user.handle}`,
      profile: true
    },
  ];

  const authProfileImage = () => {
    return userData.user.profile.profileImage
  }

  return (
    <Fragment>
      <div
        className={`fl-subroutes-main fl-subroutes-main-${userData.currentTheme}`}
      >
        <div className="fl-container">
          <div className="fl-d-flex fl-subroutes-navbar-items">
            {items.map((x: any, y: number) => (
              <div key={y} className="fl-subroutes-navbar-item">
                <Button
                  className="fl-subroutes-link"
                  type="location"
                  {...{ to: x.to }}
                >
                  <div className="fl-subroutes-navbar-icon">
                    {x.profile ?
                      <ProfileHolder src={authProfileImage()?.url} userProfile={userData.user.profile} tooltip /> :
                      <>
                        {x.type === "image" ? (
                            <img src={x.icon} alt={x.label} />
                        ) : x.icon !== null ?(
                            <FontAwesomeIcon icon={x.icon} />
                        ) : ""}
                      </>
                    }
                    {x.counter ? (
                      <div className="fl-subroute-counter">
                        {x.counter < 100 ? x.counter : "99+"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className={`${x.label.length > 17 ? "fl-subroute-size" : ""}`}>{x.label}</span>
                  {x.label === "Home" && userData.newPostsCreator.length > 0 && <span className={`fl-subroutes-new-post-${userData.currentTheme}`}> <FontAwesomeIcon  style={{fontSize: "11px"}} icon={faCircle} /></span>}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
