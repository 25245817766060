import React, { FC, useContext, useEffect, useRef, useState } from "react";

export interface EnvContextData {
  apiUrl: string;
  clickedElement?: EventTarget | null;
  pusher: {
    key: string;
    authEndpoint: string;
  };
}

export const envContextDefaultValues: EnvContextData = {
  apiUrl: "https://api.fluffa.com/api",
  clickedElement: null,
  pusher: {
    key: "1d3645b974dd09df535e",
    authEndpoint: "https://api.fluffa.com/broadcasting/auth",
  },
};

const EnvContext = React.createContext<EnvContextData>(envContextDefaultValues);

export const useEnvContext = () => useContext(EnvContext);

interface EnvProviderProps {
  children: React.ReactNode;
}

export const EnvProvider: FC<EnvProviderProps> = (props) => {
  const [state, setState] = useState({
    clickedElement: null,
  });

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    const trackClick = (e: MouseEvent) => {
      //@ts-ignore
      setState({ ...stateRef.current, clickedElement: e.target });
    };
    document.addEventListener("click", trackClick);
    return () => {
      document.removeEventListener("click", trackClick);
    };
  }, []);

  return (
    <EnvContext.Provider value={{ ...envContextDefaultValues, ...state }}>
      {props.children}
    </EnvContext.Provider>
  );
};
