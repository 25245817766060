import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import "./Drive.css";
import Axios from "axios";
import { useEnvContext } from "../../context/EnvContext";
import { Button } from "../../components/Button";
import { useModalContext } from "../../context/ModalContext";
import ReactLoading from "react-loading";
import { searchList } from "./SearchFilter.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faInfoCircle,
  faPlus,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export const LabelModal: FC<{
  id: any;
  attachments: any;
  setAttachments: any;
  labels: any;
  url: any;
  flModalImage: any;
  index: any;
  keyboardEventDrive: any;
  labelExample: any;
  setLabelExample: any;
}> = (props) => {
  const envData = useEnvContext();
  const modalData = useModalContext();

  const [selectedLabels, setSelectedLabels] = useState<any>({
    labels: props.labels || [],
  });

  const [selectedLabelsCompare, setSelectedLabelsCompare] = useState<any>({
    labels: props.labels || [],
  });

  const [focus, setFocus] = useState<boolean>(false);

  const addSelected = (label: any) => {
    let newSelectedLabels = [...selectedLabels.labels, { label: label }];
    setSelectedLabels({
      ...selectedLabels,
      labels: newSelectedLabels,
    });
  };
  const removeSelected = (id: any) => {
    let newSelectedLabels = selectedLabels.labels.filter(
        (x: any) => x.label.id !== id
    );

    setSelectedLabels({
      ...selectedLabels,
      labels: newSelectedLabels,
    });
  };

  const [processing, setProcessing] = useState(false);

  const [labelExampleOthers, setLabelExampleOthers] = useState<any>({
    labels: [],
  });

  const [search, setSearch] = useState<any>({
    value: "",
    data: searchList,
  });

  const [errorLabel, setErrorLabel] = useState<string>("");

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      createLabel(newTagText);
    }
  };

  const focusRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<boolean>(false);
  const [restrictedWords, setRestrictedWords] = useState<any>([])

  const createLabel = async (label: string) => {
    if (label.length < 2 || label.length > 30) {
      setError(true);
    } else {
      if (activeTag > -1) {
        setActiveTag(-1);
      }
      setError(false);
      if (
          props.labelExample.labels.filter(
              (x: any) => x.name === label.toLowerCase()
          ).length > 0
      ) {
        let existSelectedLabel = selectedLabels.labels.filter(
            (x: any) => x.label.name === label
        ).length;

        if (existSelectedLabel === 0) {
          addSelected(
              props.labelExample.labels.filter((x: any) =>
                  x.name.includes(label.toLowerCase())
              )[0]
          );
        }
        if(restrictedWords.length > 0) {
          setRestrictedWords([])
        }
        setErrorLabel("You've already added this tag.");
      } else {
        const formData = new FormData();
        formData.append("name", label);
        try {
          const res = await Axios.post(`${envData.apiUrl}/labels`, formData);
          let newTag = [...props.labelExample.labels, res.data.data];
          props.setLabelExample({
            ...props.labelExample,
            labels: newTag,
          });
          addSelected(res.data.data);
          setNewTagText("");
          setSearch({ ...search, value: "" });
          focusRef?.current?.focus();
          if (enableCreateTag) {
            setEnableCreateTag(false);
          }
        } catch (e) {
          if (e?.response?.data?.errors?.restricted_words?.length > 0) {
            setRestrictedWords(e?.response?.data?.errors?.restricted_words)
          } else {
            modalData.pushToast("error", "Something went wrong!");
          }
        }

        setErrorLabel("");
      }
    }
  };

  const deleteLabel = async (e: any, label: any) => {
    e.stopPropagation();

    let existSelectedLabel = selectedLabels.labels.filter(
        (x: any) => x.label.id === label.id
    ).length;

    try {
      await Axios.delete(`${envData.apiUrl}/labels/${label.id}`);
      props.setLabelExample({
        ...props.labelExample,
        labels: [
          ...props.labelExample.labels.filter((x: any) => x.id !== label.id),
        ],
      });
      if (existSelectedLabel > 0) {
        let removeSelectedLabel = selectedLabels.labels.filter(
            (x: any) => x.label.id !== label.id
        );
        setSelectedLabels({
          ...selectedLabels,
          labels: removeSelectedLabel,
        });
      }
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const getLabels = async () => {
    try {
      const res = await Axios.get(`${envData.apiUrl}/labels/all`);
      setLabelExampleOthers({ ...labelExampleOthers, labels: res.data });
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  const processUpdate = async (id: number) => {
    const formData = new FormData();
    selectedLabels.labels.forEach((x: any) =>
        formData.append("labels[]", x.label.id)
    );
    setProcessing(true);
    setTimeout(async () => {
      try {
        await Axios.post(`${envData.apiUrl}/attachments/${id}/label`, formData);
        let updatedLabel = props.attachments.files.map((e: any) => {
          e.id === id
              ? (e.labels = selectedLabels.labels)
              : (e.labels = e.labels);
          return e;
        });
        //@ts-ignore
        props.setAttachments({ ...props.attachments, files: updatedLabel });
        setProcessing(false);
        modalData.closeAll();
      } catch (e) {
        modalData.pushToast("error", "Something went wrong!");
        setProcessing(false);
      }
    }, 500);
  };

  const [newTag, setNewTag] = useState<boolean>(false);
  const [newTagText, setNewTagText] = useState<string>("");

  const createNewTag = (e: any) => {
    e.stopPropagation();
    setFocus(true);
    setNewTag(!newTag);
    if (searchTags) {
      setSearchTags(false);
    }
  };

  const checkSelected = (x: any) => {
    if (
        selectedLabels?.labels?.filter((label: any) => label?.label?.id === x.id)
            .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const closeNewTag = () => {
    if(restrictedWords.length > 0) {
      setRestrictedWords([])
    }
    setNewTag(false);
    setNewTagText("");
  };

  const [statusSearch, setStatusSearch] = useState<boolean>(false);

  const onChange = (value: any) => {
    setSearch({ ...search, value: value.target.value });
    setStatusSearch(true);
  };

  const [searchTags, setSearchTags] = useState<boolean>(false);

  const openSearchTags = (e: any) => {
    e.stopPropagation();
    setFocus(true);
    setSearchTags(!searchTags);
    if (newTag) {
      setNewTag(false);
    }
  };

  const closeSearch = () => {
    setSearchTags(false);
    setActiveTag(-1);
    setSearch({ ...search, value: "" });
  };

  const countSelected = () => {
    return selectedLabels.labels.length;
  };

  const selectSearchLabel = (label: any) => {
    createLabel(label);
    setSearch({ ...search, value: "" });
  };

  const checkIfAllAreCreated = () => {
    let options = search.data.concat(
        labelExampleOthers.labels.filter(
            (item: any) => search.data.indexOf(item) < 0
        )
    );

    if (props.labelExample.labels) {
      props.labelExample.labels.map((e: any) => {
        if (options.findIndex((x: any) => x === e.name) >= 0) {
          options.splice(
              options.findIndex((x: any) => x === e.name),
              1
          );
        }
      });
    }

    return options;
  };

  const checkCountSearch = () => {
    return checkIfAllAreCreated().filter((label: any) =>
        label?.includes(search?.value)
    ).length;
  };

  const [activeTag, setActiveTag] = useState<any>(-1);

  const searchResult = () => {
    return checkIfAllAreCreated()
        .sort((a: any, b: any) => (a > b ? 1 : b > a ? -1 : 0))
        .filter((label: any) => label?.includes(search?.value));
  };

  const [enableCreateTag, setEnableCreateTag] = useState<boolean>(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    setActiveItem(-1);
    if (e.key === "ArrowDown") {
      const index = activeTag + 1;
      setActiveTag(index > searchResult().length - 1 ? 0 : index);
      scrollList();
    }
    if (e.key === "ArrowUp") {
      const index = activeTag - 1;
      setActiveTag(index === -1 ? searchResult().length - 1 : index);
      scrollList();
    }
    if (e.key === "Enter") {
      if (search.value.length > 0) {
        if (searchResult().length === 1) {
          createLabel(search.value.toLowerCase());
        }
        let label = searchResult().filter(
            (x: any, index: number) => index === activeTag
        )[0];
        let added = props.labelExample.labels.filter(
            (x: any) => x.name === search.value.toLowerCase()
        ).length;
        if (label === undefined && searchResult().length === 0 && added === 0) {
          setEnableCreateTag(true);
        } else {
          selectSearchLabel(
              label != undefined ? label : search.value.toLowerCase()
          );
          addSelected(
              props.labelExample.labels.filter((x: any) =>
                  x.name.includes(search.value.toLowerCase())
              )[0]
          );
        }
      }
    }
    if (e.key === "Escape") {
      closeSearch();
    }
  };

  const createTagFocus = (e: any) => {
    e.stopPropagation();
    setErrorLabel("");
    setError(false);
  };

  const [activeItem, setActiveItem] = useState<number>(-1);

  const hoverEnter = (index: any) => {
    setActiveTag(-1);
    setActiveItem(index);
  };

  const hoverLeave = () => {
    setActiveItem(-1);
  };

  const scrollUpRef = useRef<any>(null);
  const scrollNoneRef = useRef<any>(null);
  const scrollList = () => {
    scrollUpRef?.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const lengthResult = () => {
    if (checkCountSearch() === 1) {
      if (searchResult()[0].length === search.value.length) {
        return true;
      }
    } else {
      return false;
    }
  };

  const closeInput = (e: any) => {
    e.stopPropagation();
    closeNewTag();
    closeSearch();
    closeCreateAgain();
  };

  const disableClick = (e: any) => {
    e.stopPropagation();
  };

  const closeCreateAgain = () => {
    if (enableCreateTag) {
      setEnableCreateTag(false);
    } else {
      closeSearch();
    }
  };

  const disableAddTagsButton = () => {
    const selected = selectedLabels.labels;
    const compare = selectedLabelsCompare.labels;
    if (
        (selected.every((e: any) => compare.includes(e)) === true &&
            compare.every((e: any) => selected.includes(e)) === true) ||
        processing
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    document.body.classList.add("fl-body-fixed");
    return function cleanup() {
      document.body.classList.remove("fl-body-fixed");
    };
  }, []);

  const typeNewTag = (text:any) => {
    setNewTagText(text)
    if(restrictedWords.length > 0) {
      setRestrictedWords([])
    }
  }

  return (
      <Fragment>
        <div className="fl-modal-title">
          {props.labels.length > 0 ? "Edit" : "Add"} tags
          <div
              className="fl-modal-close-button"
              onClick={() => modalData.closeAll()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div
            className="fl-modal-description fl-modal-description-range fl-drive-update-label"
            onClick={(e) => closeInput(e)}
        >
          <div
              className={`fl-drive-modal-thumb`}
              onClick={() =>
                  modalData.toggleImageDm(
                      props.attachments.files.filter(
                          (x: any, index: number) => index === props.index
                      ),
                      0
                  )
              }
          >
            <img
                src={props?.url}
                tabIndex={0}
                onKeyDown={props.keyboardEventDrive}
                alt={"fluffa"}
            />
          </div>
          <div
              className={`fl-drive-example-label-search ${
                  search.value.length > 0 && "fl-drive-search-hide-background"
              }`}
          >
            {searchTags ? (
                <>
                  <div
                      className={`fl-drive-search-result fl-drive-search-result-${
                          lengthResult() ? "match" : "no-match"
                      }`}
                      onClick={(e) => disableClick(e)}
                  >
                    {!enableCreateTag ? (
                        <>
                          <input
                              onFocus={createTagFocus}
                              onKeyDown={handleKeyDown}
                              ref={focusRef}
                              autoFocus={focus}
                              placeholder={"Type to search..."}
                              type="text"
                              value={search.value.toLowerCase()}
                              onChange={(value) => onChange(value)}
                          />
                          {search.value.length > 0 &&
                          statusSearch &&
                          errorLabel?.length === 0 &&
                          !error && (
                              <ul
                                  onClick={() =>
                                      checkCountSearch() === 0 && setEnableCreateTag(true)
                                  }
                              >
                                {checkCountSearch() > 0 ? (
                                    <>
                                      {searchResult().map((x: any, index: number) => (
                                          <li
                                              ref={
                                                index === activeTag + 1
                                                    ? scrollUpRef
                                                    : scrollNoneRef
                                              }
                                              onMouseEnter={() => hoverEnter(index)}
                                              onMouseLeave={() => hoverLeave()}
                                              className={`${
                                                  index === activeTag
                                                      ? "fl-drive-search-result-active"
                                                      : "fl-drive-search-result-single"
                                              } ${
                                                  index === activeItem &&
                                                  "fl-drive-search-result-hover"
                                              }`}
                                              onClick={() => selectSearchLabel(x)}
                                          >
                                            {x}
                                          </li>
                                      ))}
                                    </>
                                ) : (
                                    <li className={"fl-drive-search-no-result"}>
                                      Tag doesn't exist.
                                    </li>
                                )}
                              </ul>
                          )}
                        </>
                    ) : (
                        <span>
                    Do you want to create tag: {search.value.toLowerCase()}?
                  </span>
                    )}
                    <div
                        className={"fl-drive-tag-close-button"}
                        onClick={closeCreateAgain}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    {enableCreateTag && (
                        <div
                            className={"fl-drive-tag-create-button"}
                            onClick={() => createLabel(search.value.toLowerCase())}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                    )}
                  </div>
                  {error && (
                      <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> Tags must be between 2
                  and 30 characters long
                </span>
                  )}
                  {errorLabel?.length > 0 && (
                      <div>
                  <span className="fl-modal-description-row-error">
                    <FontAwesomeIcon icon={faInfoCircle} /> {errorLabel}
                  </span>
                      </div>
                  )}
                </>
            ) : (
                <span onClick={(e) => openSearchTags(e)}>
              {" "}
                  Search tags <FontAwesomeIcon icon={faSearch} />
            </span>
            )}
          </div>
          <div className={"fl-drive-example-label-create"}>
            {newTag ? (
                <>
                  <div
                      className={"fl-drive-create-form"}
                      onClick={(e) => disableClick(e)}
                  >
                    <input
                        ref={focusRef}
                        // onKeyDown={tagKeyDown}
                        onKeyPress={handleKeyPress}
                        placeholder={"Create new tag"}
                        autoFocus={true}
                        value={newTagText}
                        onChange={(e) => typeNewTag(e.currentTarget.value)}
                        onFocus={(e) => createTagFocus(e)}
                        maxLength={30}
                    />
                    <div className={"fl-drive-count-character"}>
                      {newTagText.length}/30
                    </div>
                    <div
                        className={"fl-drive-tag-close-button"}
                        onClick={() => closeNewTag()}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div
                        className={"fl-drive-tag-create-button"}
                        onClick={() =>
                            newTagText.length > 0 ? createLabel(newTagText) : ""
                        }
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  </div>
                  {restrictedWords.length > 0 && (
                      <span className="fl-modal-description-row-error">
                    <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {restrictedWords.join(',')}
                  </span>
                  )}
                  {error && (
                      <span className="fl-modal-description-row-error">
                  <FontAwesomeIcon icon={faInfoCircle} /> Tags must be between 2
                  and 30 characters long
                </span>
                  )}
                  {errorLabel?.length > 0 && (
                      <div>
                  <span className="fl-modal-description-row-error">
                    <FontAwesomeIcon icon={faInfoCircle} /> {errorLabel}
                  </span>
                      </div>
                  )}
                </>
            ) : (
                <span onClick={(e) => createNewTag(e)}>
              Create new tag <FontAwesomeIcon icon={faPlus} />
            </span>
            )}
          </div>
          <div className={"fl-drive-example-label"}>
            {props.labelExample.labels.map((x: any) => (
                <span
                    className={`fl-drive-example-single-label ${
                        checkSelected(x) && "fl-drive-label-selected"
                    }`}
                    onClick={() =>
                        checkSelected(x) ? removeSelected(x.id) : addSelected(x)
                    }
                >
              {x.name}
                  <div
                      className={"fa-label-delete"}
                      onClick={(e) => deleteLabel(e, x)}
                  >
                {" "}
                    <FontAwesomeIcon icon={faTimes} />{" "}
              </div>
            </span>
            ))}
          </div>
          <span className={"fl-drive-update-label-note"}>
          NOTE: Adding tags will help you easily locate specific content.
        </span>
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={() => modalData.closeAll()}>Cancel</Button>
          <Button
              disabled={disableAddTagsButton()}
              type="normal"
              onClick={() => processUpdate(props.id)}
          >
            {props.labels.length > 0 ? (
                <>
                  Update {countSelected() > 0 && countSelected()} tag
                  {countSelected() > 1 && "s"}
                </>
            ) : (
                <>
                  Add {countSelected() > 0 && countSelected()} tag
                  {countSelected() > 1 && "s"}
                </>
            )}{" "}
            {processing && (
                <ReactLoading
                    className="fl-spinningBubbles"
                    type={"spinningBubbles"}
                    color={"#ffffff"}
                    height={20}
                    width={20}
                />
            )}
          </Button>
        </div>
      </Fragment>
  );
};
