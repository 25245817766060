import React, {FC, Fragment, useEffect, useState} from "react";
import {useModalContext} from "../context/ModalContext";
import {useEnvContext} from "../context/EnvContext";
import {useUserContext} from "../context/UserContext";
import Axios from "axios";
import watermarkpreview from "../assets/images/watermark.jpeg";
import {Button} from "./Button";

export const Watermark: FC<{
    title: string;
    setAnimation:any;
}> = ({title,setAnimation}) => {

    const modalData = useModalContext();
    const envData = useEnvContext();
    const userData = useUserContext();

    const [changes2, setChanges2] = useState(false);

    useEffect(() => {
        getSettings();
    },[])


    const getSettings = async () => {
        try {
            const res = await Axios.get(`${envData.apiUrl}/settings`);
            const {watermark_color, watermark_size , watermark_position,watermark_border_color} = res.data.data.settings;

            setStateWatermark({...stateWatermark, watermark_color,watermark_size, watermark_position,watermark_border_color});
            setAnimation(false)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimation(false)
        }
    }

    const [stateWatermark, setStateWatermark] = useState({
        watermark_color: '',
        watermark_size: '',
        watermark_position: '',
        watermark_border_color:''
    })
    
    const updateWatermark = async () => {
        modalData.close()
        const { watermark_color, watermark_position,watermark_size,watermark_border_color } = stateWatermark;
           await Axios.post(`${envData.apiUrl}/settings`, { watermark_position, watermark_color, watermark_size,watermark_border_color }).then((response) => {
            setChanges2(false);
            modalData.pushToast('success', 'Watermark updated');
        }).catch((err) => {
            modalData.pushToast('error', 'Something went wrong!');
        });
    }

    const positionWatermark = () => {
        if(stateWatermark.watermark_position === "top_left") {
            return "top-left"
        }
        if(stateWatermark.watermark_position === "top_right") {
            return "top-right"
        }
        if(stateWatermark.watermark_position === "middle") {
            return "middle"
        }
        if(stateWatermark.watermark_position === "bottom_left") {
            return "bottom-left"
        }
        if(stateWatermark.watermark_position === "bottom_right") {
            return "bottom-right"
        }
    }

    const watermarkColor = () => {
        if(stateWatermark.watermark_border_color === '#ffffff' && stateWatermark.watermark_color === '#ffffff') {
            return "white"
        }
        if(stateWatermark.watermark_border_color === '#FFFFFF' && stateWatermark.watermark_color === '#FFFFFF') {
            return "white"
        }
        if(stateWatermark.watermark_border_color === '#000000' && stateWatermark.watermark_color === '#000000') {
            return "black"
        }
        if(stateWatermark.watermark_border_color === '#FFFFFF' && stateWatermark.watermark_color === '#000000') {
            return "black-white"
        }
        if(stateWatermark.watermark_border_color === '#000000' && stateWatermark.watermark_color === '#FFFFFF') {
            return "white-black"
        }
    }

    const sizeWatermark = () => {
        if(stateWatermark.watermark_size === "small") {
            return "small"
        }
        if(stateWatermark.watermark_size === "medium") {
            return "medium"
        }
        if(stateWatermark.watermark_size === "large") {
            return "large"
        }
    }

    return (
        <Fragment>
            <div className={"fl-settins-security-watermark-specification"}>
                <div className="fl-settings-sub-account-field-label">Position</div>
                <select className={`fl-select-main fl-select-main-${userData.currentTheme}`} value={stateWatermark.watermark_position} onChange={(e:React.FormEvent<HTMLSelectElement>) => {setStateWatermark({...stateWatermark, watermark_position: e.currentTarget.value}); setChanges2(true)}}>
                    <option value="top_left">Top left</option>
                    <option value="top_right">Top right</option>
                    <option value="middle">Middle</option>
                    <option value="bottom_left">Bottom left</option>
                    <option value="bottom_right">Bottom right</option>
                </select> <br />
                <div className="fl-settings-sub-account-field-label">Color</div>
                <select className={`fl-select-main fl-select-main-${userData.currentTheme}`}
                        value={stateWatermark.watermark_color === '#FFFFFF' && stateWatermark.watermark_border_color === '#FFFFFF' ? "white"
                            : stateWatermark.watermark_color === '#000000' && stateWatermark.watermark_border_color === '#000000' ? "black"
                                : stateWatermark.watermark_color === '#FFFFFF' && stateWatermark.watermark_border_color === '#000000' ? "white_black"
                                    : stateWatermark.watermark_color === '#000000' && stateWatermark.watermark_border_color === '#FFFFFF' ? "black_white" : ""}
                        onChange={(e:React.FormEvent<HTMLSelectElement>) => {setStateWatermark({...stateWatermark, watermark_color: e.currentTarget.value === 'white' ? '#FFFFFF'
                                : e.currentTarget.value === 'black' ? '#000000' : e.currentTarget.value === 'white_black' ? '#FFFFFF' : e.currentTarget.value === 'black_white' ? '#000000' : "",
                            watermark_border_color: e.currentTarget.value === 'white' ? '#FFFFFF'
                                : e.currentTarget.value === 'black' ? '#000000' : e.currentTarget.value === 'white_black' ? '#000000' : e.currentTarget.value === 'black_white' ? '#FFFFFF' : ""
                        });
                            setChanges2(true)}}>
                    <option value="white">White</option>
                    <option value="black">Black</option>
                    <option value="white_black">White with black outline</option>
                    <option value="black_white">Black with white outline</option>
                </select> <br />
                <div className="fl-settings-sub-account-field-label">Size</div>
                <select className={`fl-select-main fl-select-main-${userData.currentTheme}`} value={stateWatermark.watermark_size} onChange={(e:React.FormEvent<HTMLSelectElement>) => {setStateWatermark({...stateWatermark, watermark_size: e.currentTarget.value}); setChanges2(true)}}>
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                </select>
                <br />
            </div>
            <div className={"fl-settins-security-watermark-preview"}>
                <div className="fl-settings-sub-account-field fl-settings-img-watermark">
                    <img src={watermarkpreview} alt={"watermark"}/>
                    <span className={`fl-settings-span-position-${positionWatermark()} fl-settings-span-color-${watermarkColor()} fl-settings-span-size-${sizeWatermark()}`}>fluffa.com/{userData.user.handle}</span>
                </div>
            </div>
            <Button disabled={!changes2} type="normal" className="fl-settings-sub-security-button" onClick={updateWatermark}>Save</Button>
        </Fragment>
    );
};
export default Watermark;