import React, {FC, useState} from 'react'
import {MessageAudio} from "../../components/MessageAudio";
import {MessageDropdown} from "./MessageDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart as faHeartSolid, faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Axios from "axios";
import {Button} from "../../components/Button";
import {useEnvContext} from "../../context/EnvContext";
import {useModalContext} from "../../context/ModalContext";
import ReactLoading from "react-loading";

export const MessageAudioPreview:FC<any> = (props) => {

    const envData = useEnvContext()
    const modalData = useModalContext()
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const checkTimeDeleteMessage = () => {
        if(moment(props.data.created_at).add(2, 'minutes') >= moment(new Date())) {
            return true
        }else{
            return false
        }
    }

    const modalMediaDelete = async (conversation_id:number,message_id:number) => {
        const title = "Unsend voice clipp"
        const description = "Are you sure you want to remove this message? The recipient will no longer be able to play it."
        const textButton = "Yes"
        modalData.push(() => <UnsendModal title={title} description={description} setState={props.setState} state={props.state} conversation_id={conversation_id} message_id={message_id} textButton={textButton} />)
    };

    return (
        <>
            <div className={"fl-inbox-audio-preview"}>
                <div>
                    <MessageAudio
                        deleteRecording={""}
                        src={props.data.attachments.filter((x: any) => x.type === "audio")[0].url}
                        recording={""}
                        sendRecording={""}
                        data={props.data}
                        reportedStatus={props.reportedStatus}
                    />
                    {props.like.status ?
                        <div className={`fl-direct-message-like-message fl-direct-message-like-message-body`}>
                            <span><FontAwesomeIcon icon={faHeartSolid}/></span>
                        </div> : ""
                    }
                </div>
                {checkTimeDeleteMessage() && props.checkWhoSend() &&
                    <div className={"fl-direct-message-option fl-direct-option-right"}>
                        <div
                            className="fl-direct-message-unsend-message"
                            onClick={() => checkTimeDeleteMessage() && modalMediaDelete(props.data.conversation_id,props.data.id)}
                        >
                            <FontAwesomeIcon icon={faUndoAlt}/>
                        </div>
                    </div>
                }
                {props.reportedStatus === 0 && props.direction === "left" && !isMobile ?
                    <MessageDropdown checkType={props.checkType} setLike={props.setLike} like={props.like} setReportedStatus={props.setReportedStatus} setState={props.setState} state={props.state} data={props.data} direction={props.direction} /> : ""
                }
            </div>
        </>
    )
}

export const UnsendModal: FC<any> = ({title,description,state,setState,conversation_id,message_id,textButton}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const [animationDelete, setAnimationDelete] = useState(false)
    const confirmDelete = async (conversation_id:number,message_id:number) => {
        setAnimationDelete(true)
        try {
            await Axios.delete(
                `${envData.apiUrl}/conversations/${conversation_id}/messages/${message_id}/unsend`
            );
            setState({
                ...state,
                messages: state.messages.filter((x: any) => x.id !== message_id),
            });
            modalData.close()
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimationDelete(false)
    };

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{title}</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                {description}
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" onClick={() => confirmDelete(conversation_id,message_id)} disabled={animationDelete}>
                    {textButton}
                    {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/> : ""}
                </Button>
            </div>
        </React.Fragment>
    );
}