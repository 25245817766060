import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import { Button } from "./Button";
import './ModalNotificationMulti.css'

export const ModalNotificationMulti: FC<any> = (props) => {
    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    const [notifications, setNotifications] = useState<Array<any>>([]);
    const [loaded, setLoaded] = useState(false);
    const [currNotification, setCurrNotification]= useState(
        {
            id: '',
            data: {
                header: '', 
                body:'', 
                thumbnails: [], 
                reason: ''
            }
        });

    useEffect(() => {
        setNotifications(props.notifications);
    }, []);

    useEffect(() => {
        if(notifications.length > 0 ) {
            let copyNotifications = [...notifications];
            setCurrNotification(copyNotifications.pop());
        } else if(loaded) {
            modalData.close();
            setLoaded(false);
        }
    }, [notifications]);

    const dismissNotification = () => {
        if(!loaded) {
            setLoaded(true);
        }

        if(notifications.length > 0) {
            axios.put(`${envData.apiUrl}/notifications/read/${currNotification.id}`).then(response => {
                let newNotifications = [...notifications];
                setCurrNotification(newNotifications.pop());
                setNotifications(newNotifications);
            }).catch(err => {
                modalData.pushToast('error', 'Something went wrong!');
            })
        }
    }

    return (
    <div className={`fl-notification-content fl-notification-content-${userData.currentTheme}`}>  
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <div>
            <p>
                {currNotification.data.header}
            </p>
            <p>
                {currNotification.data.body}
            </p>
            <div>
                {currNotification.data.thumbnails.map((element: any, index) => {
                    switch(element.type) {
                        case 'image':
                            return <img width='30%' src={element.content} alt={`fluffa`} />
                        case 'video': 
                            return <img width='30%' src={element.thumbnail} alt={`fluffa`} />
                        case 'audio': 
                            return <audio controls src={element.content} />
                    }
                })}
            </div>
            <p>
                Reason: {currNotification.data.reason}
            </p>
            <p>
                Repeated violations of our <Link className='fl-underline-none-hover' target='_blank' to='/support/terms'>terms</Link> may result in your account being restricted or disabled. 
            </p>
            <Button onClick={dismissNotification} className={`fl-dismiss-button`}>Dismiss</Button>
        </div>
    </div>
    );
}