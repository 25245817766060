import React, { useState, FC } from "react";
import "./Tagging.css";
//@ts-ignore
import { Mention, MentionsInput } from "react-mentions";
//@ts-ignore
import { StylesViaJss } from "substyle-jss";
import axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import profileHolderImg from "../assets/images/profile.png";
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";

export const Tagging: FC<any> = (props) => {
  const envData = useEnvContext();
  const modalData = useModalContext();
  const userData = useUserContext();

  const [usersResponse, setUsersResponse] = useState([]);

  const handleChange = (event: any) => {
    // let text = colonsToUnicode(event.target.value);
    props.setText(event.target.value);
  };

  // const colonsToUnicode = (text: any) => {
  //   const colonsRegex = new RegExp("(^|\\s):([)|D|(|P|O|o])+", "g");
  //   let newText = text;
  //
  //   let match = colonsRegex.exec(text);
  //
  //   if (match !== null) {
  //     let offset = match.index + match[1].length;
  //
  //     newText = newText.slice(0, offset) + newText.slice(offset + 2);
  //   }
  //   return newText;
  // };

  const filterUsers = async (term: string) => {
    try {
      const res = await axios.get(
        `${envData.apiUrl}/explore/all-creators?term=${term}`
      );
      const redesign = res.data.data?.map((user: any) => {
        return {
          id: user.id,
          display: user.handle,
          username: user.name,
          avatar: user.profile.profileImage,
        };
      });
      setUsersResponse(redesign);
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const userTest = (search: any) => {
    filterUsers(search);
    return usersResponse;
  };

  const renderSuggestion = (entry: any) => {
    return (
      <>
        <div className={"fl-tag-list"}>
          <div className={"fl-tag-list-image"}>
            <RenderSmoothImage src={entry?.avatar?.url || profileHolderImg} />
          </div>
          <div className={"fl-tag-list-info"}>
            <span>{entry.display}</span>
            <span className={"fl-grey"}>{entry.username}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    //@ts-ignore
    <StylesViaJss>
      <div className="col-12 py-3 fl-tag-box">
        <div className="comment-input-container px-2 row col-12 mx-auto">
          <div className="col-11 px-0">
            <MentionsInput
              value={props.text}
              onChange={handleChange}
              className={`mentions-${userData.currentTheme}`}
              placeholder={props.placeholder}
              allowSuggestionsAboveCursor={true}
              onFocus={(e:any) => props?.openControls ? props.openControls() : e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
              markup={"@[__display__]"}
              onKeyDown={(e: any) => {
                e?.key === "Escape" && props?.keydownFunction() &&
                props?.keydownFunction()
              }}
            >
              <Mention
                data={userTest}
                className={"mentions__mention"}
                renderSuggestion={renderSuggestion}
                markup={"@[__display__(__id__)]"}
              />
            </MentionsInput>
          </div>
        </div>
      </div>
    </StylesViaJss>
  );
};
