import {FC, Fragment, useEffect, useState } from "react";
import { Button } from "./Button";
import React from "react";
import { useModalContext } from "../context/ModalContext";
import { Range, getTrackBackground } from "react-range";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useUserContext} from "../context/UserContext";

export const RangeModal: FC<any> = ({setState,state,uploadState}) => {
    const modalData = useModalContext();
    const userData = useUserContext();


    const [blurredPercent, setBlurredPercent] = useState<any>({
        percent: [state.blurredPercent]
    });

    const [disableBlur, setDisableBlur] = useState<any>(false);

    const setPercent = () => {
        setState({...state, blurredPercent: blurredPercent.percent[0]})
        setBlurredPercent({...blurredPercent, parcent: blurredPercent.percent})
        modalData.close()
    }

    const blurPreview = () => {
        if(blurredPercent.percent[0] < 10) {
            return blurredPercent.percent[0] / 40 + 3
        } else {
            return blurredPercent.percent[0] / 40 + 3
        }
    }

    const [disableClickDown, setDisableClickDown] = useState<any>(false);

    const showBlur = () => {
        if(disableClickDown === false) {
            setDisableBlur(false)
        }
    }

    const hideBlur = () => {
        if(disableClickDown === false) {
            setDisableBlur(true)
        }
    }

    const disablePointer = (e: React.MouseEvent) => {
        modalData.disableClick(1)
    }

    const enablePointer = (e: React.MouseEvent) => {
        modalData.disableClick(0)
    }

    const [sliderImage, setSliderImage] = useState<any>({
        index: 0
    });

    const keyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "ArrowRight") {
            userData.keyboardEvent("ArrowRight");
        }else if (e.key === "ArrowLeft") {
            userData.keyboardEvent("ArrowLeft");
        } else {

        }
    }

    const prevSlider = () => {
        const index = sliderImage.index - 1
        setSliderImage({...sliderImage, index: index === -1 ? uploadState.files.length - 1 : index })
    }

    const nextSlider = () => {
        const index = sliderImage.index + 1
        setSliderImage({...sliderImage, index: index > uploadState.files.length - 1 ? 0 : index  })
    }

    const rightClickBlur = (e:any) => {
        if(disableClickDown === false) {
            setDisableBlur(false)
            setDisableClickDown(false)
        } else {
            setDisableBlur(false)
            setDisableClickDown(false)
        }
        e.preventDefault()
    }

    useEffect(() => {
        if (userData.keyName === "ArrowRight") {
            nextSlider()
        } else if (userData.keyName === "ArrowLeft") {
            prevSlider()
        } else {

        }
    }, [userData]);

    const checkDriveLocal = () => {
        if (uploadState?.files?.filter((x: any,index:number) => index === sliderImage.index)[0]?.src?.length > 0) {
            return uploadState.files.filter((x: any,index:number) => index === sliderImage.index)[0]?.src
        } else {
            return uploadState?.files?.filter((x: any,index:number) => index === sliderImage.index)[0]?.url
        }
    }

    return (
        <Fragment >
            <div className="fl-modal-title">Select blur level</div>
            <div className="fl-modal-description fl-modal-description-range"
                 onKeyDown={keyboardEvent}
                 tabIndex={0}
            >
                <div className="fl-modal-range">
                    <Range
                        values={blurredPercent.percent}
                        step={0.1}
                        min={1}
                        max={100}
                        onChange={(values) => setBlurredPercent({...blurredPercent, percent:values })}
                        renderTrack={({ props, children }) => (
                            <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                    ...props.style,
                                    height: "36px",
                                    display: "flex",
                                    width: "100%"
                                }}
                            >
                                <div
                                    ref={props.ref}
                                    style={{
                                        height: "5px",
                                        width: "100%",
                                        borderRadius: "4px",
                                        background: getTrackBackground({
                                            values: blurredPercent.percent,
                                            colors: ["#548BF4", "#ccc"],
                                            min: 1,
                                            max: 100
                                        }),
                                        alignSelf: "center"
                                    }}
                                >
                                    {children}
                                </div>
                            </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                            <div
                                onMouseUp={disablePointer} onMouseDown={enablePointer}
                                {...props}
                                style={{
                                    ...props.style,
                                    height: "42px",
                                    width: "42px",
                                    borderRadius: "50%",
                                    backgroundColor: "#FFF",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    boxShadow: "0px 2px 6px #AAA"
                                }}
                            >
                                <output style={{ marginTop: "-68px", marginLeft: "0px", color: "#ff0d72", fontWeight: 500 }} id="output">
                                    {blurredPercent.percent[0].toFixed(1).split('.')[0]}%
                                </output>
                            </div>
                        )}
                    />
                </div>
                <div>
                    <span className="fl-direct-message-range-minimum">Minimum: 1%</span>
                    <span className="fl-direct-message-range-maximum">Maximum: 100%</span>
                </div>
                <div className="fl-direct-message-blur-preview">
                    {uploadState.files.length > 1 &&
                    <>
                        <div
                            className={`fl-post-images-swipe-button fl-post-images-swipe-button-preview-prev`}
                            onClick={prevSlider}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                    </>
                    }
                    <div className={`fl-direct-message-blur-preview-file`} onMouseUp={showBlur} onMouseDown={hideBlur} onContextMenu={(e) => rightClickBlur(e)}>
                        {uploadState.files.filter((x: any,index:number) => index === sliderImage.index && x.type === "image")[0] &&
                        <img alt="fluffa" src={checkDriveLocal()} style={{ filter: `blur(${disableBlur ? 0 : blurPreview()}px)` }} />
                        }
                        {uploadState.files.filter((x: any,index:number) => index === sliderImage.index && x.type === "video")[0] &&
                            <video  src={uploadState.files.filter((x: any,index:number) => index === sliderImage.index)[0]?.src} style={{ filter: `blur(${disableBlur ? 0 : blurPreview()}px)` }} muted autoPlay loop />
                        }
                    </div>
                    {uploadState.files.length > 1 &&
                    <>
                        <div
                            className={`fl-post-images-swipe-button fl-post-images-swipe-button-preview-next`}
                            onClick={nextSlider}
                            onKeyDown={keyboardEvent}
                            tabIndex={0}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </>
                    }
                </div>
                <p className="fl-range-modal-align-center">  {sliderImage.index + 1}/{uploadState.files.length}{" "}</p>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end" >
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" onClick={setPercent} disabled={blurredPercent.percent[0] === 50 ? true : false}>
                    Confirm
                </Button>
            </div>

        </Fragment>
    );
};
