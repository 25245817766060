import React, {FC, Fragment, useEffect, useState} from 'react'
import profileHolderImg from "../assets/images/profile.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faChevronDown, faChevronUp, faGift, faLock, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Button} from "./Button";
import {useModalContext} from "../context/ModalContext";
import Axios from "axios";
import {useEnvContext} from "../context/EnvContext";
import ReactLoading from "react-loading";
import {useUserContext} from "../context/UserContext";
import axios from "axios";
import {SegpayModal} from "../routes/Segpay/SegpayModal";
import moment from "moment";

export const SubscriptionModal:FC<({profile:any, state:any, setState:any, plans:any, plan:any,getProfilePosts:any,typeRef:any})> = (props) => {

    const modalData = useModalContext()
    const userData = useUserContext()

    const keyboardEvent = (event: KeyboardEvent) => {
        switch(event.code) {
            case "Escape":
                modalData.close()
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyboardEvent);
        return function cleanup() {
            document.removeEventListener('keydown', keyboardEvent);
        }
    }, [keyboardEvent]);

    const confirmSubscription = async (plan:any) => {
        await modalData.push(() => (
            <OpenConfirmationModal upgradePrice={props.plan.upgrade_price} billingPeriod={props.plan.billing_period_count} id={plan.id} price={plan.discounted_plan ? plan.discounted_plan.new_price.toFixed(2) : plan.price} profile={props.profile} state={props.state} setState={props.setState} getProfilePosts={props.getProfilePosts} typeRef={props.typeRef}/>
        ));
    };

    const [showMoreModal, setShowMoreModal] = useState<boolean>(false)

    const plansSort = () => {
        return props.plans.sort((a: any, b: any) => a.id === props.plan.id ? -1 : 1)
    }

    return (
        <Fragment>
            <div className="fl-profile-actions-subscribe-modal-cover">
                {props.profile.backgroundImage ? (
                    <img src={props.profile.backgroundImage.url} alt={"fluffa"} />
                ) : (
                    ""
                )}
                <div className="fl-profile-actions-subscribe-modal-profile">
                    {props.profile.profileImage ? (
                        <img src={props.profile.profileImage.url} alt={"fluffa"} />
                    ) : (
                        <img src={profileHolderImg} alt={"profile"} />
                    )}
                </div>
                <div className="fl-modal-back-button" onClick={modalData.close}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className="fl-modal-close-button" onClick={modalData.close}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
            <div className="fl-profile-actions-subscribe-modal-description">
                <div className="fl-profile-actions-subscribe-modal-description-name">
                    <Link to={`/${props.profile.handle}`}>{props.profile.name}</Link>
                </div>
                <div className="fl-profile-actions-subscribe-modal-description-username">
                    <Link to={`/${props.profile.handle}`}>@{props.profile.handle}</Link>
                </div>
                <div className="fl-profile-actions-subscribe-modal-description-list">
                    <p className="fl-profile-actions-subscribe-modal-description-list-title">
                        Get access to exclusive content when you subscribe to this Creator.
                    </p>
                    <p>
                        SUBSCRIPTION INCLUDES:
                    </p>
                    <div className="fl-profile-actions-subscribe-modal-description-list-item">
                        Full access to <span>@{props.profile.handle}'s</span> feed
                    </div>
                    <div className="fl-profile-actions-subscribe-modal-description-list-item">
                        Send private messages to <span>@{props.profile.handle}</span>
                    </div>
                    <div className="fl-profile-actions-subscribe-modal-description-list-item">
                        Request custom content from <span>@{props.profile.handle}</span>
                    </div>
                    <div className="fl-profile-actions-subscribe-modal-description-list-item">
                        Save <span>@{props.profile.handle}'s</span> posts to your favorites
                    </div>
                    <div className="fl-profile-actions-subscribe-modal-description-list-item">
                        And much more...
                    </div>
                    <p className={"fl-profile-actions-subscribe-small-info"}><FontAwesomeIcon icon={faLock} />{" "} Recurring payment. Cancel at any time.</p>
                    <div className={"fl-profile-actions-plans"}>
                        {plansSort().slice(0, showMoreModal ? 4 : 1).map((x:any,index:number) => (
                            <div className={"fl-modal-description-single-button"}>
                                <Button
                                    type={index === 0 ? "normal" : "outlined"}
                                    className="fl-profile-actions-button"
                                    onClick={() => confirmSubscription(props.plans.find((x:any) => x.id === props.plan.id)) }
                                >
                                    {x.type === "trial" &&
                                    <div className={"fl-profile-trial-gift"}>
                                        <FontAwesomeIcon icon={faGift} />
                                    </div>
                                    }
                                    SUBSCRIBE - {" "}
                                    <>
                                        {x.price === 0 ? "FREE" : x.discounted_plan !== null ? `$${x.discounted_plan.new_price.toFixed(2)}` : `$${x.price}`}
                                        {x.price === 0 ? `${" "} for ${" "}` : `/ `}
                                        {x.billing_period_count > 1 || x.price === 0 ? x.billing_period_count : ""} {" "}
                                        {x.billing_period}{x.billing_period_count !== 1 ? "s" : ""}
                                    </>
                                    {x.discounted_plan !== null || x.type === "trial" ?
                                        <>
                                            <div className={`fl-profile-actions-${x.discounted_plan !== null ? `discount` : "limited"}`}>
                                                {x.discounted_plan !== null ?
                                                    <>
                                                        <span>Save</span>
                                                        <span>{x.discounted_plan.amount}%</span>
                                                    </> :
                                                    <>
                                                        <span>Limited</span>
                                                        <span>Deal</span>
                                                    </>
                                                }
                                            </div>
                                            <div className={`fl-profile-actions-limited-shadow fl-profile-actions-${x.discounted_plan !== null ? "discount" : "limited"}-shadow-${userData.currentTheme}`}></div>
                                        </>
                                        : ""
                                    }
                                </Button>
                                {x.discounted_plan !== null &&
                                    <span>
                                        Regular price ${x.price}/  {x.billing_period_count > 1 ? x.billing_period_count : ""} {x.billing_period}{x.billing_period_count !== 1 ? "s" : ""}
                                    </span>
                                }
                                {x.expires_at ?
                                    <span className="fl-profile-actions-available">
                                          Offer ends: {moment(x.expires_at).format("D MMM YYYY")} ({x.limit > 10 ? `${x.limit - x.claimed} offers remaining` : x.limit > 1 ? `ONLY ${x.limit - x.claimed} offers remaining` : 'ONLY ONE LEFT'})
                                        </span> : ""
                                }
                            </div>
                        ))}
                    </div>
                    {plansSort().length > 1 &&
                    <div className={`fl-profile-actions-modal-more ${showMoreModal && "fl-profile-actions-more-open"} fl-settings-mt-10`} >
                        <div></div>
                        <span onClick={() => setShowMoreModal(!showMoreModal)}>
                                   MORE JOIN OPTIONS
                                   <FontAwesomeIcon className="fl-post-head-actions-icon" icon={showMoreModal ? faChevronUp : faChevronDown}/>
                                </span>
                        <div></div>
                    </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export const OpenConfirmationModal: FC<any> = ({ id,profile,price,state,setState,getProfilePosts,typeRef,billingPeriod,upgradePrice }) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const userData = useUserContext()

    const [subscribeLoading, setSubscribeLoading] = useState(false);

    const processPlan = async (planId: number, action = "",free = false) => {
        setSubscribeLoading(true);
        try {
            const checkSecure = await axios.post(`${envData.apiUrl}/payments/security/check`, {
                amount:price,
                force:1
            });
            if(checkSecure.data.protected) {
                modalData.push(() => <SegpayModal url={checkSecure.data.url} addCardFunction={processPlanFunction} planId={planId} action={action} free={free} header={"Confirm subscription"}/>)
            } else {
                processPlanFunction(planId, action ,free )
            }
            userData.updateBalance("walletOut",price)
        } catch (e) {
            const description = e?.response?.data?.errors.user ? e?.response?.data?.errors.user : "Something went wrong!";
            await modalData.push({
                title: "ERROR",
                description: [`${description}`],
                buttons: [
                    () => (
                        <div className="fl-d-flex fl-justify-flex-end">
                            <Button type="normal" onClick={() => modalData.closeAll()}>
                                Dismiss
                            </Button>
                        </div>
                    ),
                ],
            });
        }
        // setSubscribeLoading(false);
    };

    const processPlanFunction = async (planId: number, action = "",free = false) => {
        setSubscribeLoading(true);
        modalData.clearToasts()
        try {
            const res = await Axios.post(
                `${envData.apiUrl}/profile/${profile.handle}/subscribe/${planId}`
            );
            const subscription = {
                created_at: res.data.data.created_at,
                creator_profile:  res.data.data.creatorProfile,
                creator_profile_id:  res.data.data.creatorProfile.id,
                ends_at:  res.data.data.ends_at,
                id:  res.data.data.id,
                price:  res.data.data.subscriptionPlan.price,
                starts_at:  res.data.data.starts_at,
                status:  "ACTIVE",
                subscription_plan:  res.data.data.subscriptionPlan,
                subscription_plan_id:  res.data.data.subscriptionPlan.id,
            };
            localStorage.setItem(`trial-${res.data.data.creatorProfile.id}`,"show")
            setState({ ...state, profileObject:{...state.profileObject, meta: {...state.profileObject.meta, is_subscribed: subscription}}});
            if(action === "resubscribe") {
                modalData.pushToast("success", " You have successfully restored your subscription.");
            } else {
                modalData.pushToast("success", `${free ? `You have subscribed FREE to @${profile.handle} for ${billingPeriod} day${billingPeriod > 1 ? "s" : ""}.` : `You have successfully subscribed to  @${profile.handle}`}`);
            }
            userData.updateCount("following")
            await  modalData.closeAll()
            await getProfilePosts()
            if(document.body.classList.contains("fl-body-lock")) {
                document.body.classList.remove("fl-body-lock")
            } else if(document.body.classList.contains("fl-body-lock-scroll")) {
                document.body.classList.remove("fl-body-lock-scroll")
                document.body.style.paddingRight = `0px`
                userData.paddingBodyFunction("")
            }
        } catch (e) {
            const description = e?.response?.data?.errors.user ? "Limit on this credit card is reached, maximum 4 transactions or $200 per month" : e?.response?.data?.errors?.error ? e?.response?.data?.errors?.error : e?.response?.data?.errors.subscription ? e?.response?.data?.errors.subscription[0] : e?.response?.data?.errors.creditCard ;
            await modalData.push({
                title: "ERROR",
                description: [`${description}`],
                buttons: [
                    () => (
                        <div className="fl-d-flex fl-justify-flex-end">
                            <Button type="normal" onClick={() => modalData.closeAll()}>
                                Dismiss
                            </Button>
                        </div>
                    ),
                ],
            });
        }
        setSubscribeLoading(false);
    };

    const keyboardEvent = (event: KeyboardEvent) => {
        switch(event.code) {
            case "Escape":
                modalData.close()
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyboardEvent);
        return function cleanup() {
            document.removeEventListener('keydown', keyboardEvent);
        }
    }, [keyboardEvent]);

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Confirm subscription</div>
            </div>
            <div className={`fl-modal-description ${!subscribeLoading ? "fl-modal-description-subscription" : ""}`}>
                {!subscribeLoading ?
                    <>
                        <p>
                            Subscribe to {profile.handle}{price === 0 ? ` - FREE for ${billingPeriod} day${billingPeriod > 1 ? "s" : ""}` : ""}.
                        </p>
                        <p>
                            {price === 0 ? "You will not be charged for this subscription." : `Your card will be charged $${price}` }
                        </p>
                        {price === 0 && billingPeriod > 2 ?
                            <p className={"fl-red fl-bold-500 fl-font-13"}>
                                Note: Subscription automatically converts to ${upgradePrice}/ month unless you cancel before the free trial period ends.
                            </p> :""
                        }
                    </> :
                    <>
                        <div className="loader-payment"></div>
                        <p className={"loader-text"}>{price === 0 ? "SUBSCRIBING NOW..." : "PROCESSING PAYMENT..."}</p>
                    </>
                }
            </div>
            {!subscribeLoading ?
                <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                    <Button onClick={modalData.close}>Cancel</Button>
                    <Button type="normal" onClick={() => processPlan(id, "", price === 0 ? true : false)}
                            disbled={subscribeLoading} disabled={subscribeLoading}>
                        {subscribeLoading ?
                            <>
                                {price === 0 ? "Subscribing" : "Processing"}
                            </>
                            :
                            <>
                                Subscribe for {price === 0 ? "free" : `$${price}`}
                            </>
                        }
                        {subscribeLoading ?
                            <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"}
                                          height={20} width={20}/> : ""}
                    </Button>
                </div> : ""
            }
        </React.Fragment>
    );
};