import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react'
import {Link , useParams} from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './Verify.css';

export const AlreadyVerify:FC<{}> = () => {

    const userData = useUserContext();

    const { email } = useParams<{email: string}>();

    return (
        <div className="fl-fullscreen-with-footer fl-fullscreen-with-height-fix">
            <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                <FontAwesomeIcon className="fl-not-found-icon fl-green-icon" icon={faCheck}/>
                <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Already verified!</div>
                <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>
                    <div className="fl-verify-text">Your email address: <br /> <span>{email}</span> has already been verified.</div>
                    Click <Link className="fl-not-found-redirection-link" to={`/${userData.user.handle}`}>here</Link> to go to your profile.
                </div>
            </div>
        </div>
    )
}