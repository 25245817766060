import React, {FC} from "react";
import "./UnapprovedBox.css";
import { SimpleCard } from "./SimpleCard";
import {Button} from "./Button";

export const PlanTrialBox: FC<any> = (props) => {

    return (
        <SimpleCard className="fl-referral-card fl-trial-box">
            <div className="fl-referral-text">Unless you cancel before the free trial period ends, your subscription to @{props.handle} will automatically convert to ${props.upgrade}/ month.</div>
            <Button className="fl-referral-button" type="normal" onClick={() => props.remove()}>I understand</Button>
        </SimpleCard>
    );
};