import React, { FC } from "react";

const Actions: FC<any> = ({
  backTitle,
  backFn,
  nextTitle,
  nextFn,
  disableButton,
  state
}) => {
  return (
    <>
        {!state?.recording &&
        <div className="fl-bulk__actions">
            <div className="fl-bulk__actions--cancel" onClick={backFn}>
                {backTitle}
            </div>
            <div
                className={`fl-bulk__actions--next ${
                    disableButton ? "fl-bulk__actions--next--disabled" : ""
                }`}
                onClick={nextFn}
            >
                {nextTitle}
            </div>
        </div>
        }
    </>
  );
};

export default Actions;
