import React, { FC, useEffect, useState } from "react";
import "./Notifications.css";
import Axios from "axios";

import { MyProfile } from "../../components/MyProfile";
import { SimpleCard } from "../../components/SimpleCard";
import { useUserContext } from "../../context/UserContext";
import { useEnvContext } from "../../context/EnvContext";
import {Link, useHistory} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBug, faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import SingleNotification from "../../components/SingleNotification/SingleNotification";
import { useModalContext } from "../../context/ModalContext";
import ReactTooltip from "react-tooltip";
import moment, {defaultFormat} from "moment";
import axios from "axios";
import ReactLoading from "react-loading";

export const Notifications: FC<any> = (props) => {
  const [state, setState] = useState<any>({
    notifications: [],
    currentPage: 1,
    maxPage: 0,
  });

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();
  const history = useHistory()

  const [animation, setAnimation] = useState(true)
  const readBeforeFunction = async () => {
    try {
         await Axios.post(
          `${envData.apiUrl}/notifications/read_before`
      );
    }catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  }

  const [loader,setLoader] = useState(false)
  const getNotifications = async (page = state.currentPage) => {
    if(loader) return;
   setLoader(true)
   try {
     const res = await Axios.get(
         `${envData.apiUrl}/notifications?page=${page}`
     );
     setState({
       ...state,
       notifications: [...state.notifications, ...res.data.data],
       currentPage: page,
       maxPage: res.data.meta.last_page,
     });
     await readBeforeFunction()
     setAnimation(false)
   }catch (e) {
     if( e.response?.data.message === "Unauthenticated.") {
       userData.clearToken(false);
       history.push("/login");
     } else {
       modalData.pushToast("error", "Something went wrong!");
     }
   }
    setLoader(false)
  };

  useEffect(() => {
    getNotifications();
  }, []);


    // const unreadNotifications = async () => {
    //   await modalData.clearToasts();
    //   Axios.put(`${envData.apiUrl}/notifications/read`, {
    //     cancel_read: "stop",
    //   })
    //     .then(() => {
    //       modalData.pushToast("info", <>Action undone.</>);
    //       let updatedNotifications = JSON.parse(
    //         JSON.stringify(state.notifications)
    //       );
    //       let unreadNots = updatedNotifications.map((e: any) => {
    //         if (e.read_at) {
    //           e.read_at = null;
    //         }
    //         return e;
    //       });
    //       setState({
    //         ...state,
    //         notifications: unreadNots,
    //       });
    //       userData.readNotifications(unreadNots.filter((e:any) => e.read_at === null).length)
    //     })
    //     .catch(() => modalData.pushToast("error", "Something went wrong!"));
    // };

  const readAll = async () => {
    modalData.clearToasts();
    try {
          await Axios.put(`${envData.apiUrl}/notifications/read`);
          setState({...state, notifications: state.notifications.map((x:any) => x.read_at === null ? {...x, read_at:moment(new Date()).format(defaultFormat) } : x)})
          userData.readNotifications("all")
          modalData.pushToast(
            "success",
            <>
              All notifications marked as read.{" "}
              {/*<span className="fl-notifications__mark" onClick={unreadNotifications}>*/}
              {/*  Undo*/}
              {/*</span>*/}
            </>
          );
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!")
    }
  }
  const readNotification = async (action:string,id:any,read_before:boolean) => {
    setState({...state, notifications: state.notifications.map((x:any) => x.id === id ? {...x, read_at: action === "read" ? moment(new Date()).format(defaultFormat) : null, read_before:true } : x)})
    await axios.put(`${envData.apiUrl}/notifications/${action === "unread"?"unread":"read"}/${id}`);
  };

  const realTimeNotification = (type:any) => {
    if(
        ["App\\Notifications\\NewPostLike","App\\Notifications\\UserTipCreated","App\\Notifications\\Chat\\DeletedReportedMessageImportant","App\\Notifications\\NewPostComment",
          "App\\Notifications\\DeclinedUserVerificationEmail","App\\Notifications\\NewPostFavorite","App\\Notifications\\UserUnlockedChatMessage","App\\Notifications\\VerifiedUserEmail",
          "App\\Notifications\\NewPostTag","App\\Notifications\\EmailVerify","App\\Notifications\\UserVerificationApproved", "App\\Notifications\\UserVerificationRejected","App\\Notifications\\NewTicketResponse",
          "App\\Notifications\\TicketStatusChanged"
        ].includes(userData.signal?.type)
      // "App\\Notifications\\Chat\\ChatMessageCreated"
    ) {
      const fakeNotifications = {
        created_at: moment(new Date()).format(defaultFormat),
        data: userData.signal,
        type: type,
        read_at: null
      }
      setState({
        ...state,
        notifications: [fakeNotifications,...state.notifications],
      })
      userData.clearSignal();
    }
  }

  useEffect(() => {
    realTimeNotification(userData.signal?.type)
  },[userData?.signal])

  useEffect(() => {
    userData.readNotifications("all")
  },[])

  const countUnread = () => {
    return state.notifications.filter((x:any) => x.read_at === null).length
  }

  // const readAllOnLoad = async () => {
  //   modalData.clearToasts();
  //   try {
  //     await Axios.put(`${envData.apiUrl}/notifications/read`);
  //   } catch (e) {
  //     modalData.pushToast("error", "Something went wrong!")
  //   }
  // }
  //
  //
  // useEffect(() => {
  //   readAllOnLoad()
  // },[])
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div
      className={`fl-notifications__main fl-notifications__main--${userData.currentTheme}`}
    >
      <div className="fl-container">
        <div className="fl-d-flex fl-feed-row">
          <div className="fl-col-4 fl-feed-column fl-feed-column-sticky fl-notifications__tablet--hide">
            <MyProfile />
            {/*<Suggestions />*/}
          </div>

          <div className="fl-col-8 fl-feed-column fl-notifications__tablet--show">
            <SimpleCard className={"fl-notifications-main"}>
              {!isMobile || isMobile && countUnread() > 0  ?
                  <div
                      className={`fl-notifications__title fl-notifications__title--${userData.currentTheme}`}
                  >
                    {!isMobile ? <h3>Notifications</h3> : ""}
                    {countUnread() > 0 ?
                        <span
                            onClick={() => {
                              readAll();
                            }}
                        >
                    Mark all as read
                  </span> : ""
                    }
                  </div> : ""
              }
              {state.notifications.length === 0 && !animation && (
                <div>No new notifications.</div>
              )}
              {animation ?
                  <div className={"fl-loader-animation fl-purchased-empty"}>
                    <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                  </div> :
                  <>
                    <div
                        className={`fl-notifications__all fl-notifications__all-${userData.currentTheme}`}
                    >
                      {state.notifications.map((x: any, y: number) => (
                          <SingleNotification
                              key={y}
                              {...x}
                              readNotification={readNotification}
                              read_at={x.read_at}
                              read_before={x.read_before}
                          />
                      ))}
                    </div>
                    {state.currentPage < state.maxPage ? (
                        <div
                            className={`fl-notifications__show-more__link ${loader ? "fl-notifications__show-more-loading" : "" }`}
                            onClick={() => getNotifications(state.currentPage + 1)}
                        >
                          See more notifications {" "}
                          {loader ? <ReactLoading type={"spinningBubbles"} height={12} width={12} className="fl-spinningBubbles" color={"#ff0d72"}/> : ""}
                        </div>
                    ) : (
                        ""
                    )}
                  </>
              }
            </SimpleCard>

            <div
              id="fl-notifications__container"
              className={`fl-notifications__container--${userData.currentTheme}`}
            >
              .
            </div>
          </div>
          <Link
            to="/support/report-bug"
            className={`fl-support-bug fl-support-bug-${props.side} fl-support-bug-${userData.currentTheme}`}
            data-place="left"
            data-tip
            data-for="bug"
          >
            <FontAwesomeIcon className="fl-support-bug-icon" icon={faBug} />
            <ReactTooltip id="bug">Report a bug</ReactTooltip>
          </Link>
        </div>
      </div>
    </div>
  );
};
