import React, {FC, useEffect, useRef, useState} from "react";
import "./Purchased.css";
import {useUserContext} from "../../context/UserContext";
import {SimpleCard} from "../../components/SimpleCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown, faArrowUp,
    faBorderAll,
    faCheck,
    faSlidersH, faWindowMaximize
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {useModalContext} from "../../context/ModalContext";
import {useEnvContext} from "../../context/EnvContext";
import axios from "axios";
import {PurchasedGallery} from "./PurchasedGallery";
import {PurchasedTable} from "./PurchasedTable";
import {useHistory} from "react-router";
import ReactLoading from "react-loading";

export const Purchased: FC<any> = () => {

    const userData = useUserContext()
    const envData = useEnvContext()
    const modalData = useModalContext()
    const history = useHistory();

    const [expandFilter, setExpandFilter] = useState<boolean>(false)
    const [filterType] = useState<any>("all")
    const [gridView, setGridView] = useState<boolean>(false)
    const [activeFilter, setActiveFilter] = useState<string>("default")

    const [state, setState] = useState<any>({
        transactions: [],
        empty: true,
        page: 0,
        loadingState: false,
        lastPage:false
    });
    const stateRef = useRef<any>();
    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const [filterOptions, setFilterOptions] = useState<any>({
        type: "all",
        sort:""
    })
    const stateRefFilter = useRef<any>();
    useEffect(() => {
        stateRefFilter.current = filterOptions;
    }, [filterOptions]);

    const getTransaction = async (page = 1,type:string,filter:boolean,sort:string) => {
        if(filter) {
            setState({ ...stateRef.current, loadingState: true,transactions:[], empty:true,last_page:false});
        } else {
            setState({ ...stateRef.current, loadingState: true});
        }
       try {
           const res = await axios.get(`${envData.apiUrl}/transactions?page=${page}&type=${type}&number_of_pages=12${sort === "amount" ? `&order=amount&direction=desc`: ""}${sort === "amount_asc" ? `&order=amount&direction=asc`: ""}${sort === "date" ? `&order=created_at&direction=desc`: ""}${sort === "date_asc" ? `&order=created_at&direction=asc`: ""}${sort === "seller" ? `&order=creator_profile_id&direction=desc`: ""}${sort === "seller_asc" ? `&order=creator_profile_id&direction=asc`: ""}`);
           const data = res.data.data
           if(data.length > 0) {
               setState({
                   ...stateRef.current,
                   transactions: [...stateRef.current.transactions, ...data],
                   empty: false,
                   loadingState:false,
                   page: page,
                   last_page:false
               });
           } else if (data.length === 0 && filter) {
               setState({
                   ...stateRef.current,
                   empty: false,
                   last_page: true
               });
           } else {
               setState({
                   ...stateRef.current,
                   last_page: true,
                   empty: false
               });
           }
       } catch (e) {
           if( e.response?.data.message === "Unauthenticated.") {
               userData.clearToken(false);
               history.push("/login");
           } else {
               modalData.pushToast("error", "Something went wrong!");
           }
           setState({ ...stateRef.current, loadingState: false,transactions:[], empty:false,last_page:false});
       }
    }

    useEffect(() => {
        getTransaction(1,stateRefFilter.current.type,false,"")
    }, []);

    const selectType = (type:string) => {
        setFilterOptions({...stateRefFilter.current,type:type})
        getTransaction(1,type,true,"")
    }

    const selectSort = (type:string) => {
        if(type === "default") {
            setFilterOptions({ ...stateRefFilter.current, type:"all",sort:"",label:0 });
            getTransaction(1,"all",true,"")
        } else {
            setFilterOptions({...stateRefFilter.current,sort:type})
            getTransaction(1,"all",true,type)
        }
        setExpandFilter(false)
    }

    const checkForNewFiles = async () => {

        var maxScroll =
            Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            ) - window.innerHeight;
        if (
            window.scrollY > maxScroll - window.innerHeight / 2 &&
            stateRef.current.loadingState === false
        ) {
            getTransaction(stateRef.current.page + 1,stateRefFilter.current.type,false,stateRefFilter.current.sort);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkForNewFiles);
        return () => {
            window.removeEventListener("scroll", checkForNewFiles);
        };
    }, []);

    const redirectExplore = () => {
        history.push('/explore/all')
    }

    const changeView = () => {
        setGridView(!gridView)
        setExpandFilter(false)
    }

    const  sortName = () => {
        if(filterOptions.sort === "amount" || filterOptions.sort === "amount_asc" ) {
            return "amount"
        }
        if(filterOptions.sort === "default" || filterOptions.sort === "") {
            return "default"
        }
        if(filterOptions.sort === "date" || filterOptions.sort === "date_asc" ) {
            return "date"
        }
        if(filterOptions.sort === "seller" || filterOptions.sort === "seller_asc" ) {
            return "seller"
        }
    }

    return (
        <div
            className={`fl-drive-main fl-settings-main fl-settings-main-${userData.currentTheme}  fl-notifications-drive-${userData.currentTheme}`}
        >
            <div className="fl-container">
                <div className="fl-d-flex fl-feed-row">
                    <div className="fl-col-12 fl-feed-column fl-purchased-tablet-show">
                        <SimpleCard className={"fl-purchased-page"}>
                            <div className="fl-settings-sub-section">
                                <div
                                    className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme} fl-purchased-title`}
                                >
                                    Purchased
                                </div>
                            </div>
                            <div className={"fl-drive-sort-option"}
                                 tabIndex={1}
                                 onBlur={() => setExpandFilter(false)}
                            >
                                <div className="fl-drive-sort-info fl-drive-sort-type">
                                    <span className={`${filterOptions.type === "all" && "active"}`} onClick={() => selectType("all")}>All ({userData?.user?.profile?.meta?.transactions})</span>
                                    <span className={`${filterOptions.type === "image" && "active"}`} onClick={() => selectType("image")}>Photos ({userData?.user?.profile?.meta?.transactions_with_images})</span>
                                    <span className={`${filterOptions.type === "video" && "active"}`} onClick={() => selectType("video")}>Videos ({userData?.user?.profile?.meta?.transactions_with_videos})</span>
                                </div>
                                <div className={"fl-drive-option-sort"}>
                                    {filterOptions.sort !== "default" && filterOptions.sort !== ""  ?
                                        <div className="fl-drive-sort-info">
                                            <div className={`fl-drive-example-label ${activeFilter !== "default " && activeFilter !== "labels" ? "fl-drive-example-label-function" : ""}`}
                                                 onClick={() => filterOptions.sort === "amount" ?  selectSort("amount_asc") : filterOptions.sort === "amount_asc" ? selectSort("amount") : filterOptions.sort === "date" ?  selectSort("date_asc") : filterOptions.sort === "date_asc" ? selectSort("date") : filterOptions.sort === "seller" ?  selectSort("seller_asc") : filterOptions.sort === "seller_asc" ? selectSort("seller"): "" }
                                            >
                                                {filterOptions.sort !== "default" ?
                                                    <div className={"fl-drive-example-label"}>
                                                        Sort by {sortName()} <FontAwesomeIcon icon={filterOptions.sort === "amount_asc" || filterOptions.sort === "date_asc" || filterOptions.sort === "seller_asc" ? faArrowDown : faArrowUp}/>
                                                    </div> : "Sort by"
                                                }
                                            </div>
                                        </div> : ""
                                    }
                                    <div className="fl-drive-sort-single-option"
                                         onClick={() => setExpandFilter(!expandFilter)}
                                         data-tip data-for={"show-more"}
                                    >
                                        <FontAwesomeIcon icon={faSlidersH}/>
                                        {!expandFilter &&
                                        <ReactTooltip id="show-more">Show more options</ReactTooltip>
                                        }
                                    </div>
                                    {expandFilter &&
                                    <div
                                        className={`fl-filter-head-actions-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                                    >
                                        <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                                            <div className="fl-dropdown-filter-list-option">
                                                <div className="fl-dropdown-filter-option fl-dropdown-filter-option-title">
                                                    <span>Sort by</span>
                                                </div>
                                                <div className="fl-dropdown-filter-option"  onClick={() => selectSort("default")}>
                                                    <div className={`fl-messages-filter-check ${sortName() === "default" && "fl-messages-filter-active"}`}>
                                                        {sortName() === "default" &&
                                                        <FontAwesomeIcon
                                                            className="fl-bulk__user__list--user--check--added--icon"
                                                            icon={faCheck}
                                                        />
                                                        }
                                                    </div>
                                                    <span>Default</span>
                                                </div>
                                                <div className="fl-dropdown-filter-option" onClick={() => selectSort("amount")}>
                                                    <div className={`fl-messages-filter-check ${sortName() === "amount"  && "fl-messages-filter-active"}`}>
                                                        {sortName() === "amount" &&
                                                        <FontAwesomeIcon
                                                            className="fl-bulk__user__list--user--check--added--icon"
                                                            icon={faCheck}
                                                        />
                                                        }
                                                    </div>
                                                    <span>Price</span>
                                                </div>
                                                <div className="fl-dropdown-filter-option" onClick={() => selectSort("date")}>
                                                    <div className={`fl-messages-filter-check ${sortName() === "date" && "fl-messages-filter-active"}`}>
                                                        {sortName() === "date" &&
                                                        <FontAwesomeIcon
                                                            className="fl-bulk__user__list--user--check--added--icon"
                                                            icon={faCheck}
                                                        />
                                                        }
                                                    </div>
                                                    <span>Date of purchase</span>
                                                </div>
                                                <div className="fl-dropdown-filter-option" onClick={() => selectSort("seller")}>
                                                    <div className={`fl-messages-filter-check ${sortName() === "seller" && "fl-messages-filter-active"}`}>
                                                        {sortName() === "seller" &&
                                                        <FontAwesomeIcon
                                                            className="fl-bulk__user__list--user--check--added--icon"
                                                            icon={faCheck}
                                                        />
                                                        }
                                                    </div>
                                                    <span>Seller</span>
                                                </div>
                                            </div>
                                        </SimpleCard>
                                    </div>
                                    }
                                    <div className="fl-drive-sort-single-option"
                                         onClick={() => {
                                             changeView()
                                         }}
                                    >
                                        <FontAwesomeIcon
                                            icon={gridView ? faBorderAll : faWindowMaximize}
                                        />
                                    </div>
                                </div>
                            </div>
                            {state.empty ?
                                <div className={"fl-loader-animation fl-purchased-empty"}>
                                    <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                                </div> :
                                <>
                                    {state.transactions.length > 0 ?
                                    <>
                                        {!gridView ?
                                            <div className={`fl-purchased-files`}>
                                                {state?.transactions?.map((x:any,y:number) => (
                                                    <PurchasedGallery key={y} data={x} profile={x?.creatorProfile} transactions={state} setTransactions={setState} />
                                                ))}
                                            </div>
                                            :
                                            <PurchasedTable stateRef={stateRef} transactions={state} setTransactions={setState} filterType={filterType} />
                                        }
                                    </> :
                                        <div className={"fl-purchased-empty"}>
                                            <div className={"fl-purchased-empty"}>
                                                {filterOptions.type === "all" ?
                                                    <>
                                                        <p className={`fl-no-results-${userData.currentTheme}`}>You
                                                            haven't made any purchases yet.</p>
                                                        <p className={`fl-no-results-${userData.currentTheme}`}>Content
                                                            you purchase from <span onClick={redirectExplore}
                                                                                    style={{cursor: 'pointer'}}>Creators</span> will
                                                            appear here.</p>
                                                    </> :
                                                    <p className={`fl-no-results-${userData.currentTheme}`}>No results.</p>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Purchased;
