import React, {FC, useState} from 'react'
import {useUserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBell, faBellSlash,
    faChevronCircleLeft,
    faDollarSign,
    faEllipsisH,
    faExclamationTriangle,
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory} from "react-router-dom";
import {SimpleCard} from "../../components/SimpleCard";
import TipModal from "../../components/TipModal/TipModal";
import ReactTooltip from "react-tooltip";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {useModalContext} from "../../context/ModalContext";
import axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {TitleActions} from "./TitleActions"
import {RestrictUser} from "./RestrictUser";

export const MessageTitle:FC<any> = (props) => {

    const userData = useUserContext()
    const history = useHistory()
    const modalData = useModalContext()
    const envData = useEnvContext()
    const [dropdown, setDropdown] = useState(false)

    const returnBack = () => {
        if(props.gallery) {
            props.setGallery(false)
            props.setPageFiles({...props.pageFiles, current: 1,last:0,files:[]})
            setTimeout(() => props.scrollBottom(), 100);
        } else {
            history.push(`/inbox`)
        }
    }
    const otherUserInfo = (type:string) => {
        if(type === "handle") {
            return props.state.otherUser.handle || userData.messageUsername
        } else if (type === "id") {
            return props.state.otherUser.id || userData.messageUserId
        } else {
            return props.state.otherUser.name || userData.messageName
        }
    }

    const arrayDropdown = [
        {
            text: "Tip user",
            icon: faDollarSign,
            action: "tip",
            tooltip: false,
            tooltipText: "This prevents the user from messaging you and interacting with your posts. They will still be able to view your profile."
        },
        {
            text: `${props.state.restricted_by_me ? "Unrestrict" : "Restrict"} user`,
            icon: faExclamationTriangle,
            action: "restrict",
            tooltip: true,
            tooltipText: ""
        },
        {
            text: `${props.state.is_muted ? "Unmute" : "Mute"} notifications`,
            icon: props.state.is_muted ? faBell : faBellSlash,
            action: "mute",
            tooltip: false,
            tooltipText: ""
        },
        {
            text: `Delete chat`,
            icon: faTrashAlt,
            action: "delete",
            tooltip: false,
            tooltipText: ""
        }
    ]

    const blockUnblock = async (action:string) => {
        const unblockBlock = async () => {
            try {
                await axios.post(
                    `${envData.apiUrl}/messaging/${props.state.otherUser.handle}/${action === "block" ? "block" : "unblock"}`
                );
                await modalData.close();
                props.setState({ ...props.stateRef.current, restricted_by_me: action === "unblock" ? false : true});
                props.setUpdateList({...props.updateList, messages: props.updateList.messages.map((x:any) => x.id === props.state.conversation.id ? {...x, meta: {...x.meta, restricted_by_creator: x.creatorProfile.id === userData.user.id ?  action === "unblock" ? false : true : x.meta.restricted_by_creator,
                            restricted_by_user: x.userProfile.id === userData.user.id ?  action === "unblock" ? false : true : x.meta.restricted_by_user
                        }} : x)})
            } catch (e) {
                modalData.pushToast("error", "Something went wrong!");
            }
        };
        modalData.push(() => <RestrictUser action={action} unblockBlock={unblockBlock}/>)
    };

    const muteNotification = async (id:number) => {
        try {
            await axios.post(
                `${envData.apiUrl}/conversations/${id}/mute`
            );
            props.setState({ ...props.stateRef.current,  is_muted:  !props.state.is_muted});
            props.setUpdateList({...props.updateList, messages: props.updateList.messages.map((x:any) => x.id === props.state.conversation.id ? {...x, mutedConversation: !x.mutedConversation } : x)})
        } catch (e) {}
    }

    const actionArray = (action:string) => {
        setDropdown(false)
        if(action === "tip") {
            modalData.push(() => <TipModal otherUser={props.state.otherUser.handle} conversation={props.state.otherUser.meta.conversation_id} />)
        } else if (action === "restrict") {
            blockUnblock(props.state.restricted_by_me ? "unblock" : "block")
        } else if (action === "mute") {
            muteNotification(props.state.conversation.id)
        } else if (action === "delete") {
            props.deleteChat(props.state.conversation.id)
        }else {

        }
    }

    return (
        <div className={`fl-direct-messages-title fl-direct-messages-title-${userData.currentTheme}`}>
            <div className={`fl-direct-messages-title-info`}>
                <span className="fl-direct-messages-title-icon" onClick={() => returnBack()}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                </span>
                <Link
                    className={"fl-direct-messages-sender-profile"}
                    to={`/${otherUserInfo("handle")}`}
                >
                    {otherUserInfo("name")}
                </Link>{" "}
                <Link
                    className={`fl-direct-messages-sender-profile-handle-${userData.currentTheme}`}
                    to={`/${otherUserInfo("handle")}`}
                >
                    @{otherUserInfo("handle")}
                </Link>
                <div
                    tabIndex={1}
                    onBlur={() => setDropdown(false)}
                >
                    <div
                        className="fl-direct-messages-title-options"
                        onClick={() => setDropdown(true)}
                    >
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                    {dropdown? (
                        <div className={`fl-post-head-actions-dropdown fl-inbox-title-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}>
                            <SimpleCard className="fl-post-head-actions-dropdown-inner">
                                {arrayDropdown.map((x:any, y:number) =>
                                    <div
                                        className="fl-post-head-actions-dropdown-option"
                                        onClick={() => actionArray(x.action) }
                                        key={y}
                                    >
                                        <div className="fl-post-head-actions-dropdown-option-icon">
                                            <FontAwesomeIcon icon={x.icon} />
                                        </div>
                                        {x.text}
                                        {x.tooltip ?
                                            <>
                                                <ReactTooltip id={x.action}>This prevents the user from messaging you and interacting with your posts. They will still be able to view your profile. </ReactTooltip>
                                                <span style={{marginLeft:'5px'}} data-tip data-for={x.action}>
                                                    <FontAwesomeIcon icon={faInfoCircle} className='fl-info-text'/>
                                                </span>
                                            </> : ""
                                        }
                                    </div>
                                )}
                            </SimpleCard>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <TitleActions getFiles={props.getFiles} filesRef={props.filesRef} setType={props.setType} setGallery={props.setGallery} gallery={props.gallery} conversationId={props.conversationId} getSingleChatMessages={props.getSingleChatMessages} setSearchTextDefault={props.setSearchTextDefault} state={props.state} setSearch={props.setSearch} search={props.search} searchText={props.searchText} setSearchText={props.setSearchText} searchTextDefault={props.searchTextDefault} setFindText={props.setFindText}/>
        </div>
    )
}