import React, { FC } from "react";
import { useUserContext } from "../../../context/UserContext";
import ListPlans from "./ListPlans";
import DollarBag from '../../../assets/images/icons/dollarbag.svg';

const AllSubscriptionPlans: FC<any> = ({
                                           state,
                                           setState,
                                           requestRemoveDiscount,
                                           requestCreateDiscount,
                                           removeSubscriptionPlan,
                                           deleteSubscriptionPlan,
                                           setAnotherButton,
                                           anotherButton,
                                           checkPriceFiled
                                       }) => {
    const userData = useUserContext();

    return (
        <div className="fl-settings-sub-section fl-pb-0">
            <div
                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme} fl-settings-paid-plans`}
            >
                Paid plans {" "}
                <img src={DollarBag} alt={`fluffa`}  />
            </div>
            <ListPlans checkPriceFiled={checkPriceFiled} type="regular" plans={state.plans.filter((x:any) => x.type === "regular")}  setAnotherButton={setAnotherButton} anotherButton={anotherButton} state={state} setState={setState} requestRemoveDiscount={requestRemoveDiscount} requestCreateDiscount={requestCreateDiscount} removeSubscriptionPlan={removeSubscriptionPlan} deleteSubscriptionPlan={deleteSubscriptionPlan}/>
        </div>
    );
};

export default AllSubscriptionPlans;

