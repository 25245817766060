import React, {FC, Fragment, useEffect, useState} from "react";
import "./ProfileActions.css";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { SimpleCard } from "../components/SimpleCard";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import { useModalContext } from "../context/ModalContext";
import profileHolderImg from "../assets/images/profile.png";
import { Button } from "./Button";
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faEnvelope, faChevronDown, faChevronUp, faBan, faExclamationTriangle, faGift,
} from "@fortawesome/free-solid-svg-icons";
import userInterface from "../interfaces/UserInterface";
import moment from "moment";
import ReactLoading from 'react-loading';
import TipModal from "./TipModal/TipModal";
import {SubscriptionModal} from "./SubscriptionModal";
import {UserProfileRestrictBlock} from "./UserProfileRestrictBlock";

export const ProfileActions: FC<{
  profile?: userInterface;
  subscriptionState?: string;
  isCreator: boolean;
  plans: Array<any>;
  isSubscribed: boolean;
  isBlocked: boolean;
  handle: string;
  state:any;
  setState:any;
  getProfilePosts:any;
  typeRef:any;
  isRestricted?:any;
}> = ({
  profile,
  handle,
  isCreator,
  plans,
  isSubscribed,
  subscriptionState,
  isBlocked,
  state,
  setState,
  getProfilePosts,
  typeRef,
  isRestricted
}) => {

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const history = useHistory();

  // const unblockUser = async (handle: string) => {
  //   await Axios.post(`${envData.apiUrl}/profile/${handle}/unblock`);
  //   modalData.pushToast("success", "User unblocked successfully!");
  //   setState({...state, profileObject: {...state.profileObject, meta: {...state.profileObject.meta, is_blocked:false}}})
  // };

  const openModalBlockRestrict = (action:string) => {
    modalData.push(() => <UserProfileRestrictBlock action={action} data={profile} setUpdateState={setState} updateState={state}/>)
  }

  // const unSubscribe = async () => {
  //   await modalData.push({
  //     title: "Unsubscribe",
  //     description: [
  //       "Are you sure you want to unsubscribe?",
  //       `Your subscription will expire on ${moment("2021/05/08").format(
  //         "D MMMM, YYYY"
  //       )}`,
  //     ],
  //     buttons: [
  //       () => (
  //         <div className="fl-d-flex fl-justify-flex-end">
  //           <Button onClick={async () => await modalData.close()}>No</Button>
  //           <Button type="normal" onClick={() => processUnSubscribe()}>
  //             Yes
  //           </Button>
  //         </div>
  //       ),
  //     ],
  //   });
  // };
/*
  const processUnSubscribe = async () => {
    try {
      await Axios.post(`${envData.apiUrl}/profile/${handle}/unsubscribe`);
      modalData.pushToast("success", "You have been unsubscribed!");
      userData.updateCount("unfollowing")
      history.go(0);
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };*/

  const [freeAnimation, setFreeAnimation] = useState(false)

  const followFoFree = async () => {
    modalData.clearToasts()
    setFreeAnimation(true)
    try {
      const res = await Axios.post(
          `${envData.apiUrl}/profile/${profile?.handle}/subscribe`
      );
      const subscription = {
        billing_method : "free",
        created_at: res.data.data.created_at,
        creator_profile:  res.data.data.creatorProfile,
        creator_profile_id:  res.data.data.creatorProfile.id,
        ends_at:  res.data.data.ends_at,
        id:  res.data.data.id,
        price:  0,
        starts_at:  res.data.data.starts_at,
        status:  "ACTIVE",
        subscription_plan:  null,
        subscription_plan_id:  null,
      };
      userData.updateCount("following")
      setState({ ...state, profileObject:{...state.profileObject, meta: {...state.profileObject.meta, is_subscribed: subscription}}});
      setFreeAnimation(false)
      getProfilePosts()
    } catch (e) {
      setFreeAnimation(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const subscribe = async (planSelect: any) => {
    await modalData.push(() => <SubscriptionModal profile={profile} setState={setState} state={state} plans={plans} plan={planSelect} getProfilePosts={getProfilePosts} typeRef={typeRef}/>);
  }


  const redirectToLogin = () => {
    if(userData.authStatus === "AUTHENTICATED") {
      history.push('/settings/payment')
    } else {
      localStorage.setItem("profile","profile")
      history.push('/login')
    }
  }

  const [showMore, setShowMore] = useState<boolean>(false)

  const redirectMessage = (id:any,name:any,username:any,userId:any) => {
    userData.messagesFunction(name,username,userId)
    if(id !== null) {
      history.push(`/inbox/${id}`)
    } else {
      history.push(`/message-create/${userId}`)
    }
  }
  useEffect(() => {
    if(localStorage.getItem("profile") === "profile") {
      localStorage.removeItem("profile")
    }
  }, []);
  const sortPlan = () => {
    return plans.sort((a: any, b: any) => a.type < b.type ? 1 : b.type < a.type ? -1 : 0)
  }

  return (
      <>
        {/*
// @ts-ignore*/}
        <div className={`${isBlocked ? "fl-profile-actions-main" : state.profileObject.meta.is_subscribed ? `fl-profile-actions-main-default ${profile?.meta?.is_subscribed?.billing_method === "free" ? "fl-user-following" : ""}` : "fl-profile-actions-main" } ${isBlocked || isRestricted  ? "fl-blocked-user" : ""}`}>
          {handle === userData.user.handle ? (
              userData.user.profile.meta.verified_state !== "verified" ? (
                  // ME
                  <Button
                      type="normal"
                      className="fl-profile-actions-button"
                      to="/settings/verification"
                  >
                    Start Earning
                  </Button>
              ) : ""
              //       (
              //   <Button
              //     type="normal"
              //     className="fl-profile-actions-button"
              //     to="/settings/earnings"
              //   >
              //     <FontAwesomeIcon style={{ marginRight: 5 }} icon={faUniversity} />{" "}
              //     Add Bank
              //   </Button>
              // )
          ) : isBlocked || isRestricted ? (
              //Blocked by me
              true ? (
                  <Button
                      type="outlined-white"
                      className="fl-profile-actions-button"
                      style={{ color: "#888", borderColor: "#888" }}
                      onClick={() => openModalBlockRestrict(isBlocked ? "block" : "restrict")}
                  >
                    <FontAwesomeIcon className={"fl-profile-restrict-text-icon"} icon={isBlocked ? faBan : faExclamationTriangle}/> {" "}
                    <span className={"fl-profile-restrict-text"}>USER IS {isBlocked ? "BLOCKED" : "RESTRICTED" }</span>
                  </Button>
              ) : (
                  ""
              )
          ) : isCreator ? (
              isSubscribed === false ? (
                  <>
                    {plans.length > 0 && profile?.free_subscription === 0  ?
                          <SimpleCard>
                            {sortPlan().slice(0, showMore ? 4 : 1).map((x:any,index:number) => (
                                <>
                                  {x.description &&
                                    <div  className="fl-profile-actions-description">
                                      <RenderSmoothImage
                                          src={profile?.profileImage?.url || profileHolderImg}
                                      />
                                      <span className={`fl-profile-actions-description-${userData.currentTheme}`}>{sortPlan()[0].description}</span>
                                    </div>
                                  }
                                  <div className={"fl-profile-sub-actions fl-settings-pb-10"}>
                                    <Button
                                        type={index === 0 ? "normal" : "outlined"}
                                        className="fl-profile-actions-button"
                                        onClick={() => userData.authStatus === "AUTHENTICATED" && userData.user.meta.can_subscribe ? subscribe(x) : redirectToLogin() }
                                    >
                                      {x.type === "trial" &&
                                        <div className={"fl-profile-trial-gift"}>
                                          <FontAwesomeIcon icon={faGift} />
                                        </div>
                                      }
                                      SUBSCRIBE - {" "}
                                      <>
                                        {x.price === 0 ? "FREE" : x.discounted_plan !== null ? `$${x.discounted_plan.new_price.toFixed(2)}` : `$${x.price}`}
                                        {x.price === 0 ? `${" "} for ${" "}` : `/ `}
                                        {x.billing_period_count > 1 || x.price === 0 ? x.billing_period_count : ""} {" "}
                                        {x.billing_period}{x.billing_period_count !== 1 ? "s" : ""}
                                      </>
                                      {x.discounted_plan !== null || x.type === "trial" ?
                                          <>
                                            <div className={`fl-profile-actions-${x.discounted_plan !== null ? `discount` : "limited"}`}>
                                              {x.discounted_plan !== null ?
                                                  <>
                                                    <span>Save</span>
                                                    <span>{x.discounted_plan.amount}%</span>
                                                  </> :
                                                  <>
                                                    <span>Limited</span>
                                                    <span>Deal</span>
                                                  </>
                                              }
                                            </div>
                                            <div className={`fl-profile-actions-limited-shadow fl-profile-actions-${x.discounted_plan !== null ? "discount" : "limited"}-shadow-${userData.currentTheme}`}></div>
                                          </>
                                          : ""
                                      }
                                    </Button>
                                    {x.discounted_plan !== null &&
                                       <span>
                                          Regular price ${x.price}/ {x.billing_period_count > 1 ? x.billing_period_count : ""} {x.billing_period}{x.billing_period_count !== 1 ? "s" : ""}
                                       </span>
                                    }
                                    {x.expires_at ?
                                        <span className="fl-profile-actions-available">
                                          Offer ends: {moment(x.expires_at).format("D MMM YYYY")} ({x.limit > 10 ? `${x.limit - x.claimed} offers remaining` : x.limit > 1 ? `ONLY ${x.limit - x.claimed} offers remaining` : 'ONLY ONE LEFT'})
                                        </span> : ""
                                    }
                                  </div>
                                </>
                            ))}
                            {sortPlan().length > 1 &&
                              <div className={`fl-profile-actions-modal-more ${showMore && "fl-profile-actions-more-open"} fl-settings-mt-10`} >
                                <div></div>
                                <span onClick={() => setShowMore(!showMore)}>
                                   MORE JOIN OPTIONS
                                   <FontAwesomeIcon className="fl-post-head-actions-icon" icon={showMore ? faChevronUp : faChevronDown}/>
                                </span>
                                <div></div>
                              </div>
                            }
                          </SimpleCard>
                          : profile?.free_subscription === 1 && !isSubscribed ?
                            <SimpleCard>
                              <Button
                                  type={"normal"}
                                  className="fl-profile-actions-button-free"
                                  onClick={() => userData.authStatus === "AUTHENTICATED" ? followFoFree() : redirectToLogin() }
                                  disabled={freeAnimation}
                              >
                                {freeAnimation ? "FOLLOWING" : "FOLLOW FOR FREE"}
                                {freeAnimation && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                              </Button>
                            </SimpleCard> : ""
                    }
                  </>
              ) : (
                  <Fragment>
                    <Button
                        type="normal"
                        className="fl-profile-actions-button fl-profile-actions-button-message"
                        onClick={() => redirectMessage(profile?.meta.conversation_id,profile?.name,profile?.handle,profile?.id)}
                    >
                      <FontAwesomeIcon icon={faEnvelope} /> Send Message
                    </Button>
                    <Button
                        type="normal"
                        className="fl-profile-actions-button fl-profile-actions-button-send-tip"
                        onClick={async () =>  userData.user.meta.can_subscribe || userData?.user.wallet_balance > 0 ?
                            await modalData.push(() => (
                                <TipModal otherUser={profile?.handle} conversation={profile?.meta.conversation_id}/>
                            )) : history.push('/settings/payment')
                        }
                    >
                      <FontAwesomeIcon icon={faDollarSign} /> Send Tip
                    </Button>
                  </Fragment>
              )
          ) : (
              ""
          )}
        </div>
      </>
  );
};
