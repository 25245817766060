import React, {FC, useEffect, useState} from "react";
import "./Support.css";
import "../Privacy.css";
import { useEnvContext } from "../../context/EnvContext";
import axios from "axios";
import {SingleProblem} from "./SingleProblem";
import {SimpleCard} from "../../components/SimpleCard";
import ReactLoading from "react-loading";
import {useHistory, useParams} from "react-router-dom";
import {VerificationBox} from "../../components/VerificationBox";
import {UnapprovedBox} from "../../components/UnapprovedBox";
import {PostOptions} from "../../components/PostOptions";
import {MyProfile} from "../../components/MyProfile";
import {useUserContext} from "../../context/UserContext";

export const SingleTicket: FC<any> = (props) => {
    const envData = useEnvContext();
    const history = useHistory()
    const userData = useUserContext();

    const { id } = useParams<{ id: string }>();
    const [reports,setReports] = useState<any>({
        reports: [],
    })

    const [animation,setAnimation] = useState(false)
    const getTicket = () => {
        if (Number(id) < 1) return;
        setAnimation(true)
        axios.get(`${envData.apiUrl}/problem/reports/${id}`).then(response => {
            setReports({
                ...reports,
                reports: [response.data.data],
            })
            setAnimation(false)
        }).catch(() => {
            history.push("/404")
            setAnimation(false)
        });
    }

    useEffect(() => {
        getTicket()
    },[])

    return (
        <div className={`fl-preview-main fl-single-ticket fl-preview-main-${userData.currentTheme}`} style={{ paddingTop: userData.authStatus !== "AUTHENTICATED" ? 30 : 0 }}>
            <div className={`fl-container`}>
                <div className="fl-d-flex fl-feed-row">
                    <div className="fl-container">
                        <div className="fl-d-flex fl-feed-row">
                            <div className="fl-col-4 fl-feed-column fl-feed-column-sticky fl-feed-tablet-hide">
                                {userData?.user?.email_verified_at === null ? (
                                    <VerificationBox />
                                ) : (
                                    ""
                                )}
                                <UnapprovedBox />
                                <PostOptions />
                                <MyProfile />
                                {/*<Suggestions />*/}
                            </div>
                            <div className="fl-col-8">
                                {animation ?
                                    <SimpleCard>
                                        <div className={"fl-loader-animation"}>
                                            <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                                        </div>
                                    </SimpleCard>
                                    :
                                    <>
                                        {reports.reports.map((x:any,y:number) => (
                                                <SingleProblem data={x} key={y} reports={reports} setReports={setReports} singleProblem />
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
