import React, {FC, useState} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import { useUserContext } from "../../../../../context/UserContext";

import profileHolderImg from "../../../../../assets/images/profile.png";

import { SimpleCard } from "../../../../../components/SimpleCard";
import ReactTooltip from "react-tooltip";
import SelectedUser from "./SelectedUser";

const MessageTags: FC<any> = ({ users, setUsers, changeScreen }) => {
  const userData = useUserContext();

  const configSelectedOptions = (user: any) => {
    if (users.rebill_on.find((e: any) => e === user)) {
      return users.customOptionsSelected.filter((e: any) => e !== "rebill-on");
    } else if (users.rebill_off.find((e: any) => e === user)) {
      return users.customOptionsSelected.filter((e: any) => e !== "rebill-off");
    } else if (users.expired.find((e: any) => e === user)) {
      return users.customOptionsSelected.filter((e: any) => e !== "expired");
    } else return users.customOptionsSelected;
  };

  const [state, setState] = useState<{
    dropdown: boolean;
  }>({
    dropdown: false,
  });

  const [filteredUser] = useState("");
  const addSelected = (user: any, type: string) => {
    let newSelectedUsers = [...users.selectedUsers, user];
    switch (type) {
      case "rebill_on":
        if (users.rebill_on.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.includes(
                "rebill-on"
            )
                ? users.customOptionsSelected
                : [...users.customOptionsSelected, "rebill-on"],
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "rebill_off":
        if (users.rebill_off.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.includes(
                "rebill-off"
            )
                ? users.customOptionsSelected
                : [...users.customOptionsSelected, "rebill-off"],
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "expired":
        if (users.expired.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.includes(
                "expired"
            )
                ? users.customOptionsSelected
                : [...users.customOptionsSelected, "expired"],
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;
    }
  };

  const removeSelected = (id: any, type: string) => {
    let newSelectedUsers = users.selectedUsers.filter(
        (x: any) => x.user_id !== id
    );
    switch (type) {
      case "rebill_on":
        if (!users.rebill_on.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.filter(
                (e: any) => e !== "rebill-on"
            ),
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "rebill_off":
        if (!users.rebill_off.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.filter(
                (e: any) => e !== "rebill-off"
            ),
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;

      case "expired":
        if (!users.expired.every((e: any) => newSelectedUsers.includes(e))) {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
            customOptionsSelected: users.customOptionsSelected.filter(
                (e: any) => e !== "expired"
            ),
          });
        } else {
          setUsers({
            ...users,
            selectedUsers: newSelectedUsers,
          });
        }
        break;
    }
  };

  return (
    <>
      <div className="fl-bulk__tags">
        {users.selectedUsers.map((x: any, y: number) => (
          <>
            <div
                key={y}
                className="fl-bulk__tags__tag"
                style={{ paddingRight: "24px" }}
            >
              <div
                  className="fl-bulk__user__list--user--image"
                  style={{
                    backgroundImage: `url(${x?.profile_image || profileHolderImg})`,
                  }}
              />
              <div className="fl-bulk__user__list--user--info">
                <span>{x?.name}</span>
                <span>@{x?.handle}</span>
              </div>
              <div
                  className="fl-bulk__tags__tag--remove"
                  onClick={() =>
                      setUsers({
                        ...users,
                        selectedUsers: users.selectedUsers.filter(
                            (addedUser: any) => addedUser.user_id !== x.user_id
                        ),
                        customOptionsSelected: configSelectedOptions(x),
                      })
                  }
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </>
        ))}
        <div
            className="fl-mass-dm-add-recipients"
             tabIndex={1}
             onBlur={() => setState({ ...state, dropdown: false })}
        >
              {users.selectedUsers.length > 0 && (
                  <div
                      className="fl-bulk__input__files--line--add"
                      onClick={() =>
                          setState({ ...state, dropdown: !state.dropdown })}
                      data-tip
                      data-for="add-recipients"
                  >
                    +
                  </div>
              )}
              <ReactTooltip id="add-recipients">Add recipients</ReactTooltip>
              {state.dropdown  && (
              <div
                  className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme} fl-mass-dm-dropdown`}
              >
                <SimpleCard className="fl-post-head-actions-dropdown-inner">
                  {/*<div*/}
                  {/*    className="fl-post-head-actions-dropdown-option"*/}
                  {/*>*/}
                  {/*  <div className="fl-post-head-actions-dropdown-option-icon">*/}
                  {/*    <FontAwesomeIcon icon={faExclamationCircle} />*/}
                  {/*  </div>*/}
                  {/*  Report*/}
                  {/*</div>*/}
                  <div className="fl-bulk__users__list">
                    {/*<div className="fl-bulk__user__list--user ">*/}
                    {/*  <input*/}
                    {/*      className={`fl-bulk__users__search fl-bulk__users__search--${userData.currentTheme}`}*/}
                    {/*      type="text"*/}
                    {/*      value={filteredUser}*/}
                    {/*      onChange={(e) => setFilteredUser(e.target.value)}*/}
                    {/*      placeholder="Search @user"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    {filteredUser.length === 0
                        ? users.rebill_on.map((user: any) => (
                            <SelectedUser
                                added={users.selectedUsers.includes(user) || false}
                                addSelected={() => addSelected(user, "rebill_on")}
                                removeSelected={() => removeSelected(user.user_id, "rebill_on")}
                                name={user.name || ""}
                                handle={user.handle || ""}
                                img={user.profile_image || null}
                                key={user.user_id}
                                userData={userData}
                            />
                        ))
                        : users.rebill_on.map((user: any) => {
                          if (
                              user.handle.includes(filteredUser) ||
                              user.name.includes(filteredUser)
                          ) {
                            return (
                                <SelectedUser
                                    added={users.selectedUsers.includes(user) || false}
                                    addSelected={() => addSelected(user, "rebill_on")}
                                    removeSelected={() =>
                                        removeSelected(user.user_id, "rebill_on")
                                    }
                                    name={user.name || ""}
                                    handle={user.handle || ""}
                                    key={user.user_id}
                                    userData={userData}
                                />
                            );
                          }
                        })}
                    {filteredUser.length === 0
                        ? users.rebill_off.map((user: any) => (
                            <SelectedUser
                                added={users.selectedUsers.includes(user) || false}
                                addSelected={() => addSelected(user, "rebill_off")}
                                removeSelected={() =>
                                    removeSelected(user.user_id, "rebill_off")
                                }
                                name={user.name || ""}
                                handle={user.handle || ""}
                                key={user.user_id}
                                userData={userData}
                            />
                        ))
                        : users.rebill_off.map((user: any) => {
                          if (
                              user.handle.includes(filteredUser) ||
                              user.name.includes(filteredUser)
                          ) {
                            return (
                                <SelectedUser
                                    added={users.selectedUsers.includes(user) || false}
                                    addSelected={() => addSelected(user, "rebill_off")}
                                    removeSelected={() =>
                                        removeSelected(user.user_id, "rebill_off")
                                    }
                                    name={user.name || ""}
                                    handle={user.handle || ""}
                                    key={user.user_id}
                                    userData={userData}
                                />
                            );
                          }
                        })}
                    {filteredUser.length === 0
                        ? users.expired.map((user: any) => (
                            <SelectedUser
                                added={users.selectedUsers.includes(user) || false}
                                addSelected={() => addSelected(user, "expired")}
                                removeSelected={() => removeSelected(user.user_id, "expired")}
                                name={user.name || ""}
                                handle={user.handle || ""}
                                key={user.user_id}
                                userData={userData}
                            />
                        ))
                        : users.expired.map((user: any) => {
                          if (
                              user.handle.includes(filteredUser) ||
                              user.name.includes(filteredUser)
                          ) {
                            return (
                                <SelectedUser
                                    added={users.selectedUsers.includes(user) || false}
                                    addSelected={() => addSelected(user, "expired")}
                                    removeSelected={() =>
                                        removeSelected(user.user_id, "expired")
                                    }
                                    name={user.name || ""}
                                    handle={user.handle || ""}
                                    key={user.user_id}
                                    userData={userData}
                                />
                            );
                          }
                        })}
                  </div>
                </SimpleCard>
              </div>
              )}
        </div>
        {!users.selectedUsers.length ? (
          <div
            className={`fl-bulk__tags--empty fl-bulk__tags--empty--${userData.currentTheme}`}
          >
            <span onClick={() => changeScreen("users")}>Add recipients</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MessageTags;
