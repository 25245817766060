import React, {FC, Fragment, useEffect, useMemo, useRef, useState} from 'react'
import {SimpleCard} from "../../components/SimpleCard";
import {useUserContext} from "../../context/UserContext";
import {PreviewFiles} from "./PreviewFiles";
import {CreateForm} from "./CreateForm";
import Axios from "axios";
import {Link, useParams} from "react-router-dom";
import {useEnvContext} from "../../context/EnvContext";
import {useModalContext} from "../../context/ModalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSmileWink} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {MessageBox} from "./MessageBox";
import {MessageTitle} from "./MessageTitle";
import moment, {defaultFormat} from "moment";
import { ConversationGallery } from "./ConversationGallery"

export const Message:FC<any> = (props) => {

    const { id } = useParams<{ id: string }>();
    const modalData = useModalContext()
    const envData = useEnvContext()
    const userData = useUserContext()
    const [refresh, setRefresh] = useState<boolean>(false);
    const CancelToken = Axios.CancelToken;
    const source = useMemo(() => CancelToken.source(), [refresh]);
    const messageBoxRef = useRef<HTMLDivElement>(null);
    const createBoxRef = useRef<HTMLDivElement>(null);
    const [state, setState] = useState<any>({
        blurred: "blurred",
        blurredPercent: 50,
        body: "",
        price: "5",
        otherUser: {meta: {is_blocked_by: false, is_blocked: false}},
        conversation: {},
        messages:[],
        is_muted: false,
        is_restricted: false,
        currentPage: 1,
        lastPage: 0,
        firstEnter: true,
        sender: "",
    });
    const [uploadState, setUploadState] = useState<any>({
        files: []
    });
    const [progress, setProgress] = useState<any>({
        uploading: false,
        percent: 0,
    });

    const [uploadAudio,setUploadAudio] = useState<any>(false)
    const [animation, setAnimation] = useState(false)
    const [animationLoad, setAnimationLoad] = useState(false)
    const [cancel, setCancel] = useState<any>(false);
    const [retry, setRetry] = useState<any>(false);
    const [free, setFree] = useState<boolean>(false);
    const [search, setSearch] = useState<boolean> (false)
    const [searchText, setSearchText] = useState<string> ("")
    const [searchTextDefault, setSearchTextDefault] = useState<string> ("")
    const [typingFirst, setTypingFirst] = useState(false)
    const [restrictedWords,setRestrictedWords] = useState([])
    const stateRef = useRef<any>();

    const dragOver = (e:any) => {
        e.preventDefault();
        let element = e.currentTarget.parentNode as Element;
        element.classList.add('fl-direct-message-drop');
    }


    const dragLeave = (e:any) => {
        e.preventDefault();
        let element = e.currentTarget.parentNode as Element;
        element.classList.remove('fl-direct-message-drop');
    }

    const dragEnter = (e:any) => {
        e.preventDefault();
    }

    const dropFiles = async (file:any) => {
        let element = file.currentTarget.parentNode as Element;
        element.classList.remove('fl-direct-message-drop');
        file.preventDefault();
        const uploadedFiles: any = file.dataTransfer.files  || [];
        const filesArray: any = [...uploadedFiles];
        const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
            const fileType = file.type.split("/")[0]
            return {
                type: fileType,
                file,
                src: URL.createObjectURL(file),
            };
        });
        setUploadState({
            ...uploadState,
            files: [...uploadState.files, ...newFiles],
        });
    }

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        scrollBottom()
    }, [state.messages]);

    const [animationSend,setAnimationSend] = useState(false)

    const submitMessage = async () => {
        setAnimationSend(true)
        setCancel(false);
        setProgress({ percent: 0, uploading: true });
        try {
            const { files } = uploadState;
            const { price,body } = state;
            const formData = new FormData();
            formData.append("body", body);
            if(files?.length > 0) {
                files.forEach((x: any) => {
                    if(x.file) {
                        formData.append("attachments[]", x.file);
                    }
                    if(x.id) {
                        formData.append("drive_attachments[]", x.id);
                    }
                });
            }
            if(free) {
                formData.append("blurred", "black");
            }else {
                if (state.blurred === "blurred") {
                    formData.append("blurred", state.blurredPercent)
                } else {
                    formData.append("blurred", "black")
                }
            }
            if(userData.user.profile.meta.verified_state === "verified") {
                formData.append("price", price);
            } else {
                formData.append("price", "0");
            }
            if(uploadAudio) {
                formData.append("attachments[]", uploadAudio.file);
            }
            setTypingFirst(false)
            if(!retry) {
                const last_message = {
                    body: state.body,
                    attachments: uploadAudio ? [{type:"audio", url: uploadAudio.audio}] : files || [],
                    sender: userData.user.profile,
                    created_at: moment(new Date()).format(defaultFormat),
                    price: price,
                    fake: true,
                    seen:false,
                    sender_id: userData.user.id,
                    conversation_id: Number(props.messageId)
                };
                setState({
                    ...stateRef.current,
                    body: "",
                    messages: [...stateRef.current.messages, last_message]
                });
                props.setState({
                    ...props.state,
                    messages: props.state.messages.map((x: any) =>
                        String(x.id) === props.messageId
                            ? { ...x, meta: { ...x, last_message} }
                            : x
                    ),
                });
            }
            const res = await Axios.post(
                `${envData.apiUrl}/conversations/${props.messageId}/messages`,
                formData,
                {
                    cancelToken: source.token,
                    headers: { "content-type": "multipart/form-data" },
                    onUploadProgress: (progressEvent) => {
                        setProgress({
                            uploading: true,
                            percent: Math.round(
                                (progressEvent.loaded / progressEvent.total) * 100 - 1
                            ),
                        });
                    },
                }
            );
            if(files.length > 0) {
                setUploadState({ files: []});
            }
            setState({
                ...stateRef.current,
                blurred: "blurred",
                blurredPercent: 50,
                body: "",
                price: "5",
                messages: [
                    ...stateRef.current.messages.filter((x: any) => x.fake !== true),
                    res.data.data,
                ],
            });
            setProgress({ uploading: false, percent: 0 });
            setFree(false)
            setRetry(false)
            setUploadAudio(false)
        }catch (e) {
            setProgress({ percent: 0, uploading: false });
            setRefresh(!refresh);
            if (e?.response?.data?.errors?.restricted_words?.length > 0) {
                setRestrictedWords(e?.response?.data?.errors?.restricted_words)
                setState({
                    ...stateRef.current,
                    messages: [
                        ...stateRef.current.messages.map((x:any) => x.fake ? {...x, restricted: true} : x),
                    ],

                });
            }
        }
        setAnimationSend(false)
    }

    const checkRestrictedWord = async () => {
        await submitMessage()
    };

    const cancelUpload = async () => {
        setProgress({ uploading: false, percent: 0 });
        setCancel(true);
        setRetry(true)
        source.cancel();
    };

    const deleteCancelledUpload = (data:any) => {
        const findIndex = state.messages.indexOf(data)
        setState({
            ...stateRef.current,
            blurred: "blurred",
            blurredPercent: 50,
            body: "",
            price: "5",
            messages: state.messages.filter((x:any,y:any) => y !== findIndex)
        });
        setUploadState({ files: []});
        setCancel(false)
        setRetry(false)
    }

    useEffect(() => {
        if (userData.signal !== null) {
            if (userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageCreated" && userData?.signal?.data.conversation_id === state.conversation.id) {
                const {
                    id,
                    sender,
                    body,
                    created_at,
                    attachments,
                    price,
                    conversation_id
                } = userData.signal.data;
                const message = {
                    id:id,
                    conversation_id:conversation_id,
                    body: body,
                    attachments: attachments,
                    sender: sender,
                    created_at: created_at,
                    price: price,
                    seen: userData.signal.data.seen === null ? false : userData.signal.data.seen,
                    sender_id: sender.id
                };
                setState({ ...stateRef.current, messages:[...stateRef.current.messages, message] })
            }
            if (userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageUnlocked") {
                setState({...state, messages: state.messages.map((x:any) => x.id === userData?.signal?.message.id ? {...x, unlocked: true} : x)})
            }
        }
    }, [userData.signal]);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const scrollBottom = () => {
        messageBoxRef?.current?.scrollIntoView({ block: 'end', inline: 'nearest'});
        if(isMobile) {
            createBoxRef?.current?.scrollIntoView({ block: 'end', inline: 'nearest'});
        }
    }

    const singleMessageRef = useRef([]);
    const scrollSingle = (id:number) => {
        if(singleMessageRef.current[id]) {
            // @ts-ignore
            singleMessageRef.current[id]?.scrollIntoView({ block: "start"})
        }
    }
    const getSingleChatMessages = async (id: string, page = 1, search = "",firstEnter = true) => {
        if(animation || animationLoad) {
            return
        }
        if(firstEnter) {
            setAnimation(true)
        } else{
            setAnimationLoad(true)
        }
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/conversations/${id}/messages${search.length > 0 ? `?search=${search}` : `?page=${page}`}`
            );
            setState({
                ...state,
                messages: search.length > 0 || firstEnter === true ? res.data.data.reverse() : [...res.data.data.reverse(), ...state.messages],
                conversation: res.data.meta.conversation,
                sender: res.data.meta.conversation.creatorProfile,
                otherUser:
                    res.data.meta.conversation.creatorProfile.handle !==
                    userData.user.handle
                        ? res.data.meta.conversation.creatorProfile
                        : res.data.meta.conversation.userProfile,
                is_muted: res.data.meta.conversation.mutedConversation,
                restricted_me:  res.data.meta.conversation.creatorProfile.id === userData.user.id
                    ? res.data.meta.conversation.meta.restricted_by_user
                    : res.data.meta.conversation.meta.restricted_by_creator,
                restricted_by_me: res.data.meta.conversation.creatorProfile.id === userData.user.id
                    ? res.data.meta.conversation.meta.restricted_by_creator
                    : res.data.meta.conversation.meta.restricted_by_user,
                currentPage: page,
                lastPage: res.data.meta.last_page,
                firstEnter: firstEnter
            });
            setAnimation(false)
            setAnimationLoad(false)
            if(firstEnter) {
                scrollBottom()
            } else {
                scrollSingle(res.data.data.reverse()[0].id)
            }
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimation(false)
        }
    };

    useEffect(() => {
        if(id) {
            getSingleChatMessages(id);
        }
    }, [id]);

    const setFindText = (text:string) => {
        setSearchTextDefault(text)
        getSingleChatMessages(id,1,text);
    }

    const [animationGallery, setAnimationGallery] = useState(false)
    const [pageFiles,setPageFiles] = useState<any>({
        current: 0,
        last:0,
        files:[],
        action: "all media"
    })
    const filesRef = useRef<any>();
    useEffect(() => {
        filesRef.current = pageFiles;
    }, [pageFiles]);

    const getFiles = async (action:string, page = 1) => {
        if(page === 1) {
            setAnimationGallery(true)
        }
        setPageFiles({ ...pageFiles, action:action });
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/conversations/${id}/messages-with-files?page=${page}${action === "videos" ? "&type=video" : action === "photos" ? "&type=image" : ""}`
            );
            setPageFiles({...pageFiles, current: page,last:res.data.meta.last_page,files: page === 1 ? res.data.data : [...pageFiles.files, ...res.data.data],action:action})
            setAnimationGallery(false)
        } catch (e) {
            setAnimationGallery(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    return (
        <Fragment>
            <SimpleCard
                className={`fl-direct-messages-box`}
                onDragOver={(e:any) => props.messageId && !progress.uploading && dragOver(e)}
                onDragLeave={(e:any) => props.messageId && !progress.uploading && dragLeave(e)}
                onDragEnter={(e:any) => props.messageId && !progress.uploading && dragEnter(e)}
                onDrop={(files:any) => props.messageId && !progress.uploading && dropFiles(files)}
                style={{position: "relative"}}
            >
                <div className={"fl-direct-message-drop-files"}
                     onDragOver={(e:any) => props.messageId && !progress.uploading && dragOver(e)}
                     onDragLeave={(e:any) => props.messageId && !progress.uploading && dragLeave(e)}
                     onDragEnter={(e:any) => props.messageId && !progress.uploading && dragEnter(e)}
                     onDrop={(files:any) => props.messageId && !progress.uploading && dropFiles(files)}
                >
                    <span>Drop files here</span>
                </div>
                {props.messageId ?
                    <>
                        <MessageTitle scrollBottom={scrollBottom} setPageFiles={setPageFiles} pageFiles={pageFiles} getFiles={getFiles}
                                      filesRef={filesRef} setGallery={props.setGallery} gallery={props.gallery} conversationId={id}
                                      getSingleChatMessages={getSingleChatMessages} setSearchTextDefault={setSearchTextDefault} searchText={searchText}
                                      setSearchText={setSearchText} searchTextDefault={searchTextDefault} setFindText={setFindText} search={search}
                                      setSearch={setSearch} state={state} stateRef={stateRef} setState={setState} updateList={props.state} setUpdateList={props.setState}
                                      deleteChat={props.deleteChat}
                        />
                        {props.gallery ? <ConversationGallery filesRef={filesRef} getFiles={getFiles} pageFiles={pageFiles} animationGallery={animationGallery} conversationId={id} /> :
                            <>
                                <MessageBox cancel={cancel} submitMessage={checkRestrictedWord} messageBoxRef={messageBoxRef} animation={animation} conversationId={id}
                                            getSingleChatMessages={getSingleChatMessages} searchTextDefault={searchTextDefault} setState={setState} state={state}
                                            messageId={props.messageId} progress={progress} cancelUpload={cancelUpload} deleteCancelledUpload={deleteCancelledUpload}
                                            lastMessage={props.state} setLastMessage={props.setState}  singleMessageRef={singleMessageRef} animationLoad={animationLoad}
                                            scrollBottom={scrollBottom}
                                />
                                <div className={`fl-direct-messages-box-input fl-direct-messages-box-input-${userData.currentTheme}`}>
                                    {uploadState.files.length > 0 && !cancel && !progress.uploading && !retry ?
                                        <PreviewFiles setUploadState={setUploadState} uploadState={uploadState} free={free} state={state} setState={setState} setFree={setFree} /> : ""
                                    }
                                    {state.otherUser.handle ?
                                        <CreateForm cancel={cancel} conversationId={id} submitMessage={checkRestrictedWord} setUploadState={setUploadState} uploadState={uploadState} setState={setState} messageId={props.messageId}
                                                    state={state} stateRef={stateRef} progress={progress} setUploadAudio={setUploadAudio} uploadAudio={uploadAudio} lastMessage={props.state} setLastMessage={props.setState}
                                                    setTypingFirst={setTypingFirst} typingFirst={typingFirst} setRestrictedWords={setRestrictedWords} restrictedWords={restrictedWords} animationSend={animationSend} retry={retry}
                                                    scrollBottom={scrollBottom} animation={animation}
                                        /> : "" }
                                    <div  ref={createBoxRef}></div>
                                </div>
                            </>
                        }
                    </>
                    :
                    <Fragment>
                        <div className={`fl-direct-messages-box-empty fl-direct-messages-box-empty-${userData.currentTheme}`}>
                            <FontAwesomeIcon
                                className="fl-direct-messages-box-empty-icon"
                                icon={faSmileWink}
                            />
                            You don't have a message selected
                            <span className="fl-direct-messages-box-empty-sub">
                              Choose one from your existing messages, or create a new one.
                           </span>
                            <div className={"fl-direct-messages-box-empty-button"}>
                                <Link to="/message-create" className="fl-messages-all-convs-new">
                                    <div className="fl-messages-all-convs-new-icon">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                    New message
                                </Link>
                            </div>
                        </div>
                    </Fragment>
                }
            </SimpleCard>
        </Fragment>
    )
}
