import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react'
import { useHistory} from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './NotFound.css';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

export const BanUserPage:FC<any> = (props) => {

    const userData = useUserContext()
    const history = useHistory()

    const redirect = () => {
        history.push("/contact")
    }

    return (
        <div className="fl-fullscreen-with-footer fl-fullscreen-with-height-fix">
            <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                <FontAwesomeIcon className="fl-not-found-icon" icon={faExclamationTriangle}/>
                <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>
                    Your account has been disabled for violating our terms.
                </div>
                <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>
                    We understand your account is important to you. So if you think this was a mistake, please <span className="fl-not-found-redirection-link" onClick={() => redirect()}>contact us</span>.
                </div>
            </div>
        </div>
    )
}