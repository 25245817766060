import React, { FC, useEffect, useRef, useState } from 'react'
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { useModalContext } from '../context/ModalContext';
import { useUserContext } from '../context/UserContext';
import './Preregister.css';

//@ts-ignore
import videoSrc from '../assets/videos/preregister.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faCreditCard,faUsers } from '@fortawesome/free-solid-svg-icons';
import { Videobanner } from '../components/Videobanner';
import Axios from 'axios';
import { useEnvContext } from '../context/EnvContext';

import iphoneSrcBlack from '../assets/images/iphone_black.png';
import iphoneSrcWhite from '../assets/images/iphone_white.png';
import iphoneSrcBlackSec from '../assets/images/iphone_black_2.png';
import iphoneSrcWhiteSec from '../assets/images/iphone_white_2.png';

// ICONS
import ico1 from '../assets/images/icons/4.svg';
import ico2 from '../assets/images/icons/2.svg';
import ico3 from '../assets/images/icons/5.svg';

export const Preregister:FC<any> = (props) => {

    // CONTEXT
    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    // REFS
    const formRef = useRef<HTMLDivElement>(null);

    // STATE
    const [currentPhone, setCurrentPhone] = useState(0);
    const [state, setState] = useState({
        email: "",
        name: ""
    })

    const preregister = async () => {
        if(!state.email && !state.name){
            modalData.pushToast("error", "Your details are required!");
            return 0;
        }
        try {
            await Axios({method: "POST", url: `${envData.apiUrl}/pre-subscribed`, data: state});
            modalData.pushToast("success", "Please click the link that was just sent to your email account to verify your email address and complete pre-registration. \n\n*Remember to check your spam/junk folder if you didn’t receive it.");
        } catch(e) {
            modalData.pushToast("error", e.response.data.errors[Object.keys(e.response.data.errors)[0]] || "Something went wrong!");
        }
    }

    const scrollToForm = () => {
        const node = formRef.current;
        const getOffsetTop = (element:any) => {
            let offsetTop = 0;
            while(element) {
              offsetTop += element.offsetTop;
              element = element.offsetParent;
            }
            return offsetTop;
        }
        window.scrollTo({
            top: getOffsetTop(node) - 100,
            left: 0,
            behavior: "smooth"
        })
    }

    useEffect(() => {
        setCurrentPhone(Math.floor(Math.random() * 2));
    },[userData.currentTheme])
    
    return (
        <div className="fl-preregister-main">
            <Videobanner title="fluffa" description="The new social platform for content creators and influencers to maximise their income." button={<Button type="normal" onClick={scrollToForm}>PRE-REGISTER NOW</Button>} src={videoSrc}/>
            <div className={`fl-preregister-icons-main fl-preregister-icons-main-${userData.currentTheme}`}>
                <div className="fl-container">
                    <div className="fl-preregister-icons">
                        <div className="fl-preregister-icon">
                            <img alt={`preregister`} className="fl-preregister-icon-img" src={ico1}/>
                            <span className={`fl-preregister-icon-${userData.currentTheme}`}>Robust DMCA Protection</span>
                        </div>
                        <div className="fl-preregister-icon">
                            <FontAwesomeIcon className="fl-preregister-icon-fa" icon={faBolt}/>
                            <span className={`fl-preregister-icon-${userData.currentTheme}`}>Lightning-fast Servers</span>
                        </div>
                        <div className="fl-preregister-icon">
                            <FontAwesomeIcon className="fl-preregister-icon-fa" icon={faUsers}/>
                            <span className={`fl-preregister-icon-${userData.currentTheme}`}>Built by Professionals</span>
                        </div>
                        <div className="fl-preregister-icon">
                            <FontAwesomeIcon className="fl-preregister-icon-fa" icon={faCreditCard}/>
                            <span className={`fl-preregister-icon-${userData.currentTheme}`}>Multiple Payout Options</span>
                        </div>
                        <div className="fl-preregister-icon">
                            <img alt={`preregister`} className="fl-preregister-icon-img" src={ico2}/>
                            <span className={`fl-preregister-icon-${userData.currentTheme}`}>Discreet Billing</span>
                        </div>
                        <div className="fl-preregister-icon">
                            <img alt={`preregister`} className="fl-preregister-icon-img" src={ico3}/>
                            <span className={`fl-preregister-icon-${userData.currentTheme}`}>24/7 Support</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fl-preregister-content fl-fullscreen-with-footer-swap">
                <div className="fl-fullscreen-with-footer-inner fl-container fl-preregister-form">
                    <div className="fl-fullscreen-with-footer-bg">
                        <div className="fl-fullscreen-with-footer-circle-orange"></div>
                        <div className="fl-fullscreen-with-footer-circle-pink"></div>
                    </div>
                    <div className="fl-fullscreen-with-footer-content">
                        <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-d-flex fl-justify-flex-end">
                            {userData.currentTheme === 'light' ? <img className="fl-fullscreen-with-footer-content-image fl-preregister-img" src={[iphoneSrcBlack,iphoneSrcBlackSec][currentPhone]} alt="Login"/> : ''}
                            {userData.currentTheme === 'dark' ? <img className="fl-fullscreen-with-footer-content-image fl-preregister-img" src={[iphoneSrcWhite,iphoneSrcWhiteSec][currentPhone]} alt="Login"/> : ''}
                        </div>
                        <div className="fl-col-6 fl-fullscreen-with-footer-content-part" ref={formRef}>
                            <div className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}>Pre-register now</div>
                            <div className="fl-fullscreen-with-footer-content-description">Please fill out the pre-registration form to be notified when we launch!</div>
                            <Input style={{width: "100%"}} inputType="text" placeholder="Your name" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState({...state, name: e.target.value})}/>
                            <Input style={{width: "100%"}} inputType="email" placeholder="Your email address" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState({...state, email: e.target.value})}/>
                            <Button style={{margin: "10px 0px", width: "100%"}} type="normal" onClick={() => preregister()}>SIGN ME UP!</Button>
                            <div className="fl-fullscreen-with-footer-content-link-text">*We won’t send you any spam – pinky promise.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
