import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import App from './App';
import { UserProvider } from './context/UserContext';
import { EnvProvider } from './context/EnvContext';
import { ModalProvider } from './context/ModalContext';

const CacheProvider:FC<{children: React.ReactNode}> = ({children}) => {

  // QUERY CACHE
  const queryCache = new QueryCache();

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
        {children}
        {/* <ReactQueryDevtools /> */}
    </ReactQueryCacheProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider>
      <BrowserRouter>
        <UserProvider>
          <ModalProvider>
              <EnvProvider>
                  <App/>
              </EnvProvider>
          </ModalProvider>
        </UserProvider>
      </BrowserRouter>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);