import {
  faUserCircle,
  faStar,
  faChevronRight,
  faComments,
  faCog,
  faTimes,
  faCreditCard,
  faUniversity,
  faSignOutAlt,
  faHome,
  faEnvelope,
  faBell,
  faQuestionCircle,
  faUser,
  faTimesCircle,
  faClock,
  faGrinHearts,
  faSun,
  faDollarSign,
  faBug,
  faTasks,
  faHdd,
  faShoppingCart,
  faWallet,
  faFileInvoiceDollar,
  faGift,
  faTicketAlt, faCompass, faHandHoldingUsd
} from "@fortawesome/free-solid-svg-icons";
import featureIcon from "../assets/images/icons/feature-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {FC, Fragment, useEffect, useRef} from "react";
import { Link, useHistory } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import { ProfileHolder } from "./ProfileHolder";
import "./Sidebar.css";
import subscriberIconsGrey from "../assets/images/icons/subscriberIconsGrey.svg"

import verifiedSrc from "../assets/images/icons/verified.svg";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import ScheduleIcon from "../assets/images/scheduled.svg";

export const Sidebar: FC<any> = ({ state, setState }) => {
  // HISTORY
  const history = useHistory();

  // CONTEXT
  const userData = useUserContext();

  if (
    state.active === true &&
    !document.body.classList.contains("fl-body-lock")
  ) {
    document.body.classList.add("fl-body-lock");
  } else if (
    state.active === false &&
    document.body.classList.contains("fl-body-lock")
  ) {
    if( document.body.classList.contains("fl-body-lock-scroll")) {
      document.body.classList.remove("fl-body-lock-scroll");
      document.body.style.paddingRight = `0px`
      userData.paddingBodyFunction("")
    }
    document.body.classList.remove("fl-body-lock");
  }

  const logOut = async () => {
    setState({ ...state, active: false });
    history.push('/login')
    await userData.stopPusher()
    await userData.clearToken();
  };

  const keyboardEvent = (e: any) => {
    if (e.key === "Escape") {
      setState({ ...state, active: false });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyboardEvent);
    return () => {
      document.removeEventListener("keydown", keyboardEvent);
    };
  }, []);

  const scrollFreeTRial = () => {
    setState({ ...state, active: false });
    localStorage.setItem("scrollPage", "promotions")
  }
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollTop = () => {
    scrollRef?.current?.scrollTo(0, 0)
  }

  useEffect(() => {
    if(state.active) {
      scrollTop()
    }
  },[state.active])

  return (
    <Fragment>
      <div
        className={`fl-sidebar-wrapper ${
          state.active ? "fl-sidebar-wrapper-show" : ""
        }`}
        onClick={() => setState({ ...state, active: false })}
      >
        <div
          className={`fl-sidebar-main fl-sidebar-main-${userData.currentTheme}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`fl-sidebar-title fl-sidebar-title-${userData.currentTheme}`}
          >
            <div
              className={`fl-sidebar-back fl-sidebar-back-${userData.currentTheme}`}
              onClick={() => setState({ ...state, active: false })}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="fl-sidebar-title-label">Menu</div> <br />
          </div>
          <div className="fl-sidebar-scrollable" ref={scrollRef}>
            <div
              className={`fl-sidebar-profile fl-sidebar-profile-${userData.currentTheme}`}
              onClick={() => {
                setState({ ...state, active: false });
              }}
            >
              <div className="fl-sidebar-profile-image">
                <ProfileHolder
                  src={userData.user.profile.profileImage?.url}
                  userProfile={userData.user.profile}
                  profiles
                />
              </div>
              <div className="fl-sidebar-profile-text">
                <div className="fl-sidebar-profile-fullname">
                  {userData.user.name}
                </div>
                <div className="fl-sidebar-profile-username">
                  @{userData.user.handle}
                </div>
              </div>
              <div className="fl-sidebar-profile-icon">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div className="fl-sidebar-profile-follow">
              <Link
                 to={'/follow/followers'}
                 onClick={() => setState({ ...state, active: false })}
                 className={`fl-sidebar-profile-follow-value fl-sidebar-profile-follow-value-${userData.currentTheme}`}
              >
                {userData.user.profile.meta.followers} follower{userData.user.profile.meta.followers > 1 || userData.user.profile.meta.followers === 0  ? "s" : ""}
              </Link>
              <Link
                to={'/follow/following'}
                onClick={() => setState({ ...state, active: false })}
                className={`fl-sidebar-profile-follow-value fl-sidebar-profile-follow-value-${userData.currentTheme}`}
              >
                {userData.user.profile.meta.following} following
              </Link>
            </div>
            {[
              {
                category: "Main",
                users:"both",
                items: [
                  {
                    label: "Profile",
                    postLabel: () =>
                        userData.user.profile.free_subscription === 1 || userData.user.subscriptionPlans.length > 0 ? (
                            <Link to="/settings/plans" className={"fl-sidebar-free-account"}>(You're set to { userData.user.profile.free_subscription === 1  ? "FREE" : `PAID`})</Link>
                        ) : (<Link to="/settings/plans" className={"fl-sidebar-free-account"}>(Complete set up)</Link>),
                    to: `/${userData.user.handle}`,
                    icon: faUserCircle,
                    creatorOnly: false
                  },
                  // {
                  //   label: `Referrals`,
                  //   to: `/settings/referrals`,
                  //   icon: faUserFriends,
                  //   creatorOnly: true,
                  // },
                  { label: "Home", to: `/feed`, icon: faHome, creatorOnly: false },
                  { label: "Explore", to: `/explore`, icon: faCompass, creatorOnly: false },
                  { label: "Favourites", to: `/favorites/profiles`, icon: faStar, creatorOnly: false },
                  {
                    label: `Subscriptions (${(userData.user.profile.meta.subscriptions_count.active)})`,
                    to: `/subscriptions`,
                    icon: faGrinHearts,
                    creatorOnly: false
                  },
                  {
                    label: `Subscribers (${userData.user.profile.meta.followers})`,
                    to: `/follow/followers`,
                    svg: subscriberIconsGrey,
                    icon:null,
                    creatorOnly: true
                  },
                  // { label: "Suggestions", to: `/suggestions`, icon: faUsers },
                ],
              },
              {
                category: "Account",
                users:"both",
                items: [
                  {
                    label: "Messages",
                    to: `/inbox`,
                    icon: faEnvelope,
                    postLabel: () =>
                      userData.user.meta.unread_messages === 0 ? (
                        ""
                      ) : (
                        <div className="fl-sidebar-counter">
                          {userData.user.meta.unread_messages < 100
                            ? userData.user.meta.unread_messages
                            : "99+"}
                        </div>
                      ),
                    creatorOnly: false
                  },
                  {
                    label: "Notifications",
                    to: `/notifications`,
                    icon: faBell,
                    postLabel: () =>
                      userData.user.meta.new_notifications === 0 ? (
                        ""
                      ) : (
                        <div className="fl-sidebar-counter">
                          {userData.user.meta.new_notifications < 100
                            ? userData.user.meta.new_notifications
                            : "99+"}
                        </div>
                      ),
                    creatorOnly: false
                  },
                  { label: "Settings", to: `/settings`, icon: faCog, creatorOnly: false },
                ],
              },
              {
                category: "Verification",
                users: "both",
                items: [
                  //@ts-ignore
                  {
                    //@ts-ignore
                    label: {
                      verified: "Verified",
                      not_verified: "Get Verified (to earn)",
                      pending: "In review",
                      rejected: "Failed",
                    }[userData?.user?.profile?.meta?.verified_state],
                    to: `/settings/verification`,
                    icon: faUser,
                    postLabel: () => {
                      //@ts-ignore
                      // Verification Rejected - times circle
                      // Verification Pending - clock
                      // Get Verified (to earn) - questionmark
                      // Account Verified - shield with check icon
                      return {
                        verified: (
                          <div>
                            <div className="fl-sidebar-verified-icon">
                              <div data-tip data-for="verified-success">
                                <img src={verifiedSrc} alt="VERIFIED" />
                                <ReactTooltip id="verified-success">
                                  Congrats! Your profile has been verified.
                                </ReactTooltip>
                              </div>
                            </div>
                          </div>
                        ),
                        not_verified: (
                          <div>
                            <ReactTooltip id="verified-info">
                              You'll be able to create Price Plans once your
                              account has been approved.
                            </ReactTooltip>
                            <FontAwesomeIcon
                              data-tip
                              data-for="verified-info"
                              style={{ marginLeft: 4 }}
                              icon={faQuestionCircle}
                            />
                          </div>
                        ),
                        pending: (
                          <FontAwesomeIcon
                            style={{ marginLeft: 4 }}
                            icon={faClock}
                          />
                        ),
                        rejected: (
                          <FontAwesomeIcon
                            style={{ marginLeft: 4, color: "red" }}
                            icon={faTimesCircle}
                          />
                        ),
                      }[userData?.user?.profile?.meta?.verified_state];
                    },
                    creatorOnly: false
                  },
                ],
              },
              {
                category: "Payments",
                users: "both",
                items: [
                    {
                      label: "Transactions",
                      to: `/settings/transactions`,
                      icon: faFileInvoiceDollar,
                      creatorOnly: true
                    },
                    {
                      label: `Earnings Balance ($${userData?.user.profile?.creatorProfile?.total_earnings ? userData?.user.profile?.creatorProfile?.total_earnings.toFixed(
                          2
                      ) : '0.00'})`,
                      to: `/earnings/all`,
                      icon: faDollarSign,
                      creatorOnly: true
                    },
                    {
                      label: `${userData.user.has_payout_account ? "Manage" : "Add"} Bank`,
                      to: `/earnings/all`,
                      icon: faUniversity,
                      creatorOnly: true
                    },
                    {
                      label: `Wallet Balance ($${userData?.user.wallet_balance?.toFixed(
                        2
                      )})`,
                      to: `/settings/payment`,
                      icon: faWallet,
                      creatorOnly: false
                    },
                    {
                      label: `${userData.user.meta.can_subscribe ? "Payment Methods" : "Add Payment Method"}`,
                      to: `/settings/payment`,
                      icon: faCreditCard,
                      creatorOnly: false
                    },
                  {
                    label: `Payouts`,
                    to: `/settings/payouts`,
                    icon: faHandHoldingUsd,
                    creatorOnly: true
                  }
                ],
              },
              {
                category: "Price Plans",
                users: "admin",
                items: [
                  {
                    label: `${
                      userData?.user?.subscriptionPlans?.length > 0
                        ? "Manage"
                        : "Create"
                    } Price Plans`,
                    to: `/settings/plans`,
                    icon: faTasks,
                  },
                  {
                    label: `${
                      userData?.user?.subscriptionPlans?.filter(
                        (x: any) => x.type === "trial"
                      ).length > 0
                        ? "Promotions"
                        : "Create Free Trial"
                    }`,
                    to: `/settings/plans`,
                    icon: faGift,
                    onClick: scrollFreeTRial,
                  },
                ],
              },
              {
                category: "Content",
                users:"both",
                items: [
                  {
                    label: "Fluffa Drive",
                    to: `/drive/files`,
                    icon: faHdd,
                    creatorOnly: true,
                  },
                  {
                    label: `Scheduled Posts (${userData.user.profile.meta.posts_scheduled})`,
                    to: `/${userData.user.handle}?posts=scheduled`,
                    svg: ScheduleIcon,
                    icon:null,
                    creatorOnly: true,
                  },
                  {
                    label: "Purchased",
                    to: `/purchased`,
                    icon: faShoppingCart,
                    creatorOnly: false,
                  }
                ],
              },
              {
                category: "Other",
                users: "both",
                items: [
                  {
                    label: `${
                      userData.currentTheme === "dark" ? "Light" : "Dark"
                    } Mode`,
                    icon: userData.currentTheme === "dark" ? faSun : faMoon,
                    onClick: userData.changeTheme,
                    creatorOnly: false
                  },
                  {
                    label: "Help and Support",
                    to: `/support`,
                    icon: faComments,
                    creatorOnly: false
                  },
                  {
                    label: "Report a bug",
                    to: `/support/report-bug`,
                    icon: faBug,
                    creatorOnly: false
                  },
                  {
                    label: "Suggest a feature",
                    to: `/support/suggest-feature`,
                    icon: null,
                    svg: featureIcon,
                    creatorOnly: false
                  },
                  {
                    label: "Submit a ticket",
                    to: `/support/submit-ticket`,
                    icon: faTicketAlt,
                    creatorOnly: false
                  },
                ],
              },
              {
                category: "Actions",
                users: "both",
                items: [
                  {
                    label: "Log out",
                    icon: faSignOutAlt,
                    onClick: logOut,
                    customClass: "rotate",
                    creatorOnly: false
                  },
                ],
              },
            ]
              .filter((x) => x !== null)
              .map((a, b) => {
                if (
                  userData.user.profile.meta.verified_state !== "verified" &&
                  a.users === "admin"
                )
                  return;
                return (
                  <div className="fl-sidebar-category" key={b}>
                    <div
                      className={`fl-sidebar-category-title fl-sidebar-category-title-${userData.currentTheme}`}
                    >
                      {a.category}
                    </div>
                    <div className="fl-sidebar-category-items">
                      {
                        //@ts-ignore
                        a.items.map(
                          //@ts-ignore
                          (
                            x: {
                              label: string;
                              customClass?: string;
                              to?: string;
                              onClick?: () => void;
                              postLabel?: () => JSX.Element;
                              icon: any;
                              svg: any;
                              altIcon: any;
                              creatorOnly:boolean;
                            },
                            y: number
                          ) => {
                            if (
                                userData.user.profile.meta.verified_state !== "verified" &&
                                x.creatorOnly === true
                            )
                              return;
                            const Element: FC<any> = (props) =>
                              x.to ? (
                                <Link {...props}>{props.children}</Link>
                              ) : (
                                <div {...props}>{props.children}</div>
                              );
                            return (
                              <Element
                                key={y}
                                className={`fl-sidebar-navitem fl-sidebar-navitem-${userData.currentTheme}`}
                                {...{
                                  to: x.to,
                                  onClick: () => {
                                    if (x.onClick) x.onClick();
                                    else setState({ ...state, active: false });
                                  },
                                }}
                              >
                                <div className="fl-sidebar-navitem-icon">
                                  {x.icon !== null ? (
                                    <FontAwesomeIcon
                                      icon={x.icon}
                                      className={`fl-sidebar-navitem-icon-${
                                        x.customClass || "default"
                                      }`}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {x.svg ? <img src={x.svg}  alt={"fluffa"} /> : ""}
                                </div>
                                {x.label}
                                {x.postLabel ? <x.postLabel /> : ""}
                              </Element>
                            );
                          }
                        )
                      }
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
