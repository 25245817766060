import React, {FC, useState} from "react";
import "../Bulk.css";

import "react-circular-progressbar/dist/styles.css";

import { SimpleCard } from "../../../../components/SimpleCard";
import MessageTags from "./Common/MessageTags";
import Actions from "./Common/Actions";

import List from "./Message/Files/List";
import Input from "./Message/Input";
import Media from "./Message/Media";

import { useUserContext } from "../../../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedoAlt} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from 'react-loading';

const Message: FC<any> = ({
                              changeScreen,
                              users,
                              setUsers,
                              message,
                              setMessage,
                              sendMessage,
                              progress,
                              setProgress,
                              cancel,
                              setCancel,
                              source,
                              retry,
                              setRetry,
                              setStateBlurPercent,
                              stateBlurPercent,
                              setRestrictedWords,
                              restrictedWords
                          }) => {
    const userData = useUserContext();

    const cancelUpload = async () => {
        setProgress({ uploading: false, percent: 0 });
        setCancel({ ...cancel, cancel: true });
        setRetry({ ...retry, retry: true });

        source.cancel();
    };

    const [state, setState] = useState<any>({
        messages: [],
        recording: false,
        deleting: false,
        timer: 0,
        chunks: [],
        previewVoice:{}
    });

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <SimpleCard className={`fl-bulk-main`}>
            {!isMobile ?
                <div
                    className={`fl-bulk__title fl-bulk__title--${userData.currentTheme}`}
                >
                    New message
                </div> : ""
            }
            <div className="fl-bulk__top">
                <p
                    className={`fl-bulk__top__recipients fl-bulk__top__recipients--${userData.currentTheme}`}
                >
                    {message.files.length > 0 ? (
                        `Sending ${message.files.length} file${
                            message.files.length !== 1 ? "s" : ""
                        } to ${users.selectedUsers.length} recipient${
                            users.selectedUsers.length !== 1 ? "s" : ""
                        }`
                    ) : ("")}
                </p>
                <MessageTags
                    users={users}
                    setUsers={setUsers}
                    changeScreen={changeScreen}
                />
            </div>
            <div className={`fl-messages-bulk-people-list-form ${state.recording && "fl-messages-bulk-people-list-form-pb"}`}>
                <List setStateBlurPercent={setStateBlurPercent} stateBlurPercent={stateBlurPercent} message={message} setMessage={setMessage} progress={progress} cancel={cancel} setCancel={setCancel} retry={retry} setRetry={setRetry} cancelUpload={cancelUpload}/>
                <Input state={state} message={message} setMessage={setMessage} userData={userData} setRestrictedWords={setRestrictedWords} restrictedWords={restrictedWords}/>
                <Media
                    state={state}
                    setState={setState}
                    setStateBlurPercent={setStateBlurPercent}
                    stateBlurPercent={stateBlurPercent}
                    message={message}
                    setMessage={setMessage}
                    users={users}
                    progress={progress}
                />
            </div>
            <Actions
                state={state}
                backTitle={
                        "Back"
                }
                backFn={() => changeScreen("users")}
                nextTitle={
                    <div className="fl-bulk__sending">
                        {progress.uploading === false && retry.retry === false ? (
                            "Send"
                        ) : retry.retry === true ? (
                           <>
                               Retry
                               <FontAwesomeIcon className="fl-write-post-actions-retry-svg" icon={faRedoAlt} />
                           </>
                        )  : (
                         <div className="fl-bulk__sending-title">
                             {message.files.length > 0 ?
                                    <>
                                        Sending {message.files.length} file
                                        {message.files.length > 1 && "s"}
                                    </> :
                                 "Sending"
                             }
                             <ReactLoading type={"spinningBubbles"} color={"#ff0d72"} height={10} width={30} />
                         </div>
                            )}
                    </div>
                }
                nextFn={ retry.retry === true && progress.uploading === false ?  (sendMessage): progress.uploading === false ? (sendMessage) : ("")}
                disableButton={
                    !users.selectedUsers.length ||
                    (message.text.length === 0 && message.files.length === 0) ||
                    (message.price > 0 && message.price < 5) ||
                    message.price.length === 0 || progress.uploading
                }
            />
        </SimpleCard>
    );
}

export default Message
