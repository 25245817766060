import React, { FC } from "react";

import profileHolderImg from "../../../../../assets/images/profile.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SelectedUser: FC<any> = ({
  added,
  addSelected,
  removeSelected,
  name,
  handle,
  img,
  userData,
}) => {
  return (
    <div
      className={`fl-bulk__user__list--user ${
        added ? "fl-bulk__user__list--user--added" : ""
      }`}
      onClick={() => (added ? removeSelected() : addSelected())}
    >
      <div
        className="fl-bulk__user__list--user--image"
        style={{ backgroundImage: `url(${img || profileHolderImg})` }}
      />
      <div className="fl-bulk__user__list--user--text">
        <div
          className={`fl-bulk__user__list--user--text--name fl-bulk__user__list--user--text--name--${userData.currentTheme}`}
        >
          {name}
        </div>
        <div className="fl-bulk__user__list--user--text--handle">@{handle}</div>
      </div>
      <div
        className={`fl-bulk__user__list--user--check fl-bulk__user__list--user--check--${
          userData.currentTheme
        } ${added ? "fl-bulk__user--list--user--check--added" : ""}`}
      >
        <FontAwesomeIcon
          className="fl-bulk__user__list--user--check--added--icon"
          icon={faCheck}
        />
      </div>
    </div>
  );
};

export default SelectedUser;
