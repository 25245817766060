import {faTimes, faTrashAlt, faBan, faPauseCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, FC, useEffect, useRef } from "react";
import "./Toast.css";
import DollarBagWhite from "../assets/images/icons/dollarbagwhite.svg";

export const Toast: FC<{
  type: "warning" | "success" | "error" | "info" | "deleted" | "disabled" | "reactivated" | "deactivated" | "resend" | "money_out" | "money_in";
  time: number;
  text: string;
  destroy: () => void;
}> = ({ type, text, time, destroy }) => {
  // STATE
  const [state, setState] = useState<any>({
    toastState: "show",
  });

  let toastTimeout: any = null;

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    toastTimeout = setTimeout(() => {
      setState({ ...stateRef.current, toastState: "hide" });
    }, time);
  }, []);

  const removeToast = () => {
    if (state.toastState === "hide") {
      destroy();
    }
  };

  const closeToast = () => {
    if (state.toastState === "hide") return;

    if (toastTimeout) clearTimeout(toastTimeout);
    setState({ ...stateRef.current, toastState: "hide" });
  };

  const configTitle = (type: string) => {
    switch (type) {
      case "deleted":
        return "Account deleted";

      case "disabled":
        return "Account disabled";

      case "reactivated":
        return "Account Reactivated";

      case "deactivated":
        return "Account Deactivated";

      case "resend":
        return "Resend code";
      case "warning":
        return "ERROR";
      case "money_out":
        return "MONEY OUT";
      case "money_in":
        return "MONEY IN";
      default:
        return type;
    }
  };

  return (
    <div
      className={`fl-toast-main fl-toast-main-${state.toastState} fl-toast-main-${type}`}
      onAnimationEnd={removeToast}
    >
      <div className="fl-toast-icon-holder">
        <span className={`fl-toast-icon fl-toast-icon-${type}`}>
          {type === "deleted" && (
            <FontAwesomeIcon className="fl-toast-icon-svg" icon={faTrashAlt} />
          )}
          {type === "disabled" && (
            <FontAwesomeIcon className="fl-toast-icon-svg" icon={faBan} />
          )}
          {type === "deactivated" && (
              <FontAwesomeIcon className="fl-toast-icon-svg" icon={faBan} />
          )}
          {type === "resend" && (
              <FontAwesomeIcon className="fl-toast-icon-svg" icon={faPauseCircle} />
          )}
          {type === "money_out" || type === "money_in" ? (
              <img src={DollarBagWhite} alt={"toast img"}/>
          ) : ""}
        </span>
      </div>
      <div className="fl-toast-text">
        <div className="fl-toast-text-title">
          <div className="fl-toast-text-title-type">
            {/* {type} */}
            {configTitle(type)}
          </div>
          <FontAwesomeIcon
            className="fl-toast-text-title-action"
            icon={faTimes}
            onClick={closeToast}
          />
        </div>
        <div className="fl-toast-text-description">{text}</div>
      </div>
    </div>
  );
};
