import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import './ExpandingContent.css'
export const ExpandingContent: FC<any> = ({content, header, className, ...props}) => {
    const [active, setActive] = useState(false);
    return (
        <div className={`fl-expanding-content fl-expanding-content-${active ? 'expand' : 'shrink'} ${className}`} onClick={() => setActive(!active)}>
            <div className={`fl-expanding-content-header ${active && 'fl-expanding-content-header-active'}`}>
                <span>{header}</span>
                <FontAwesomeIcon icon={faCaretDown} />
            </div>
            <span>{content }</span>
        </div>
    );
}