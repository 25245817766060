import React, { FC } from "react";
import { useUserContext } from "../../../context/UserContext";
import ListTrialPlans from "./ListTrialPlans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGift} from "@fortawesome/free-solid-svg-icons";

const AllSubscriptionPlansTrialComplete: FC<any> = ({
                                                state,
                                                setState,
                                                requestRemoveDiscount,
                                                requestCreateDiscount,
                                                removeSubscriptionPlan,
                                                deleteSubscriptionPlan,
                                                expired
                                            }) => {
    const userData = useUserContext();

    return (
        <>
            <div className="fl-settings-sub-section">
                <div
                    className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
                >
                    Completed free trials {" "}
                    <FontAwesomeIcon className={"fl-settings-plan-gift"} icon={faGift} />
                </div>
                <ListTrialPlans type="completed" plans={expired} state={state} setState={setState} requestRemoveDiscount={requestRemoveDiscount} requestCreateDiscount={requestCreateDiscount} removeSubscriptionPlan={removeSubscriptionPlan} deleteSubscriptionPlan={deleteSubscriptionPlan}/>
            </div>
        </>
    );
};

export default AllSubscriptionPlansTrialComplete;

