import React, { FC, useState } from "react";
import "./TipModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUndo, faTimes, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

import { useEnvContext } from "../../context/EnvContext";
import { useUserContext } from "../../context/UserContext";
import { useModalContext } from "../../context/ModalContext";
import { MInput } from "../MInput";
import { Button } from "../Button";
import validator from "validator";
import Axios from "axios";
import ReactLoading from "react-loading";
import moment, {defaultFormat} from "moment";
import axios from "axios";
import {SegpayModal} from "../../routes/Segpay/SegpayModal";
import {useHistory} from "react-router-dom";

const TipModal: FC<{ otherUser: any,conversation:any, setState?:any,stateRef?:any, fromMessage?:any,lastMessage?:any,setLastMessage?:any,messageId?:any }> = ({ otherUser,conversation,setState,stateRef,fromMessage,lastMessage,setLastMessage,messageId }) => {
  const [amountValue, setAmountValue] = useState(1);
  const [customAmountValue, setCustomAmountValue] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const userData = useUserContext();
  const modalData = useModalContext();
  const envData = useEnvContext();
  const history = useHistory();

  const [proccessing, setProccessing] = useState(false);

    const createMessage = async (message:any,amount:any) => {
        try {
            const text = `@[tip(${amount})] tip-text[(${message})]`
            const formData = new FormData();
            formData.append("body", text);
            formData.append("price", "0");
            await Axios.post(`${envData.apiUrl}/conversations/${conversation}/messages`, formData);
            if(fromMessage){
                const last_message = {
                    body: text,
                    attachments: [],
                    sender: userData.user.profile,
                    created_at: moment(new Date()).format(defaultFormat),
                    price: 0,
                    seen:false,
                    sender_id: userData.user.id
                };
                setState({
                    ...stateRef.current,
                    body: "",
                    messages: [...stateRef.current.messages, last_message]
                });
                setLastMessage({
                    ...lastMessage,
                    messages: lastMessage.messages.map((x: any) =>
                        String(x.id) === messageId
                            ? { ...x, meta: { ...x, last_message} }
                            : x
                    ),
                });
            }
        }catch (e) {

        }
    }

    const [restrictedWords, setRestrictedWords] = useState<any>([])

    const sendFunds = async () => {
        setProccessing(true)
        try {
            const checkSecure = await axios.post(`${envData.apiUrl}/payments/security/check`, {
                amount:amountValue
            });
            if(checkSecure.data.protected) {
                modalData.push(() => <SegpayModal url={checkSecure.data.url} addCardFunction={sendFundsFunction} header={"Send tip"}/>)
            } else {
                sendFundsFunction()
            }
        } catch (e) {
            const description = e?.response?.data?.errors.user ? e?.response?.data?.errors.user : "Something went wrong!";
            await modalData.push({
                title: "ERROR",
                description: [`${description}`],
                buttons: [
                    () => (
                        <div className="fl-d-flex fl-justify-flex-end">
                            <Button type="normal" onClick={() => modalData.closeAll()}>
                                Dismiss
                            </Button>
                        </div>
                    ),
                ],
            });
        }
        // setProccessing(false)
  };

    const sendFundsFunction = async () => {
        const formData = new FormData();
        const redirectPayment = () => {
            modalData.closeAll()
            history.push('/settings/payment')
        }
        //@ts-ignore
        formData.append("amount", amountValue);
        formData.append("tip_message", message);
        setProccessing(true)
        try {
            await Axios.post(
                `${envData.apiUrl}/profile/${otherUser}/tip`, {
                    amount: amountValue,
                    tip_message: message,
                }
            );
            modalData.pushToast(
                "money_out",
                `You've sent  $${Number(amountValue)} to ${otherUser}`
            );
            userData.updateBalance("walletOut",amountValue)
            if(conversation) {
                await createMessage(message,amountValue)
            }
            modalData.close();
            setProccessing(false);
        }catch (e) {
            // setProccessing(false)
            if (e?.response?.data?.errors?.restricted_words?.length > 0) {
                setRestrictedWords(e?.response?.data?.errors?.restricted_words)
            } else {
                const description = e?.response?.data?.errors.user ? "Limit on this credit card is reached, maximum 4 transactions or $200 per month" : e?.response?.data?.errors?.error ? e?.response?.data?.errors?.error : e?.response?.data?.errors.creditCard ;
                await modalData.push({
                    title: "ERROR",
                    description: [`${description[0] === "No available credit cards" ? "Please add a payment method before sending a tip." : description}`],
                    buttons: [
                        () => (
                            <div className="fl-d-flex fl-justify-flex-end">
                                <Button type="normal" onClick={() => description[0] === "No available credit cards" ? redirectPayment() : modalData.closeAll()}>
                                    {description[0] === "No available credit cards" ? "Add card" : "Dismiss"}
                                </Button>
                            </div>
                        ),
                    ],
                });
            }
        }
    }

  const disableBtn = () => {
        if(amountValue > 0 && !proccessing && restrictedWords.length === 0) {
            return false
        } else {
            return true
        }
  }

  return (
    <>
      <div className="fl-modal-title">
        Send tip
        <FontAwesomeIcon
          icon={faTimes}
          className="fl-tipmodal__close"
          onClick={modalData.close}
        />
      </div>
      <div className="fl-modal-description">
          {!proccessing ?
              <div className="fl-modal-description-row">
                  <div>{!customAmountValue && "Select tip amount:"}</div>
                  <div className="fl-sendmoney-row">
                      {!customAmountValue ? (
                          [5, 10, 20, 50].map((x, y) => (
                              <div
                                  key={y}
                                  onClick={() => setAmountValue(x)}
                                  className={`fl-sendmoney-single ${
                                      amountValue === x ? "fl-sendmoney-single-selected" : ""
                                  } fl-sendmoney-single-${userData.currentTheme}`}
                              >
                                  ${x}
                              </div>
                          ))
                      ) : (
                          <MInput
                              minIsSet="1"
                              style={{marginTop: "0"}}
                              label="Enter custom amount"
                              type="number"
                              value={amountValue}
                              setValue={(tip) => {
                                  if ((tip <= 100 && tip !== 0) || tip === "") {
                                      setAmountValue(tip);
                                  }
                              }}
                              hasInfo={true}
                              infoId="tip-modal-info"
                              infoText={["You can send tips between $1 and $100"]}
                          />
                      )}
                      <div
                          onClick={() => setCustomAmountValue(!customAmountValue)}
                          className={`fl-sendmoney-single ${
                              customAmountValue
                                  ? "fl-sendmoney-single-selected fl-tipmodal__custom"
                                  : ""
                          } fl-sendmoney-single-${userData.currentTheme}`}
                      >
                          {customAmountValue ? <FontAwesomeIcon icon={faUndo}/> : "Custom"}
                      </div>
                  </div>
                  <MInput
                      onFocus={() => restrictedWords.length > 0 ? setRestrictedWords([]) : ""}
                      className={"fl-settings-mt-30"}
                      label="Add message (optional)"
                      maxLength={240}
                      validator={[{
                          check: (v) =>
                              validator.isLength(v, {min: 0, max: 240}),
                          message: 'Message must be 240 or less characters long.'
                      }]}
                      type="textarea"
                      value={message}
                      setValue={(msg) => {
                          if (msg.length <= 240) setMessage(msg);
                      }}
                  />
                  {restrictedWords.length > 0 ? (
                      <span className="fl-modal-description-row-error">
                      <FontAwesomeIcon
                          icon={faInfoCircle}/> You have entered restricted words: {restrictedWords.join(',')}
                </span>
                  ) : ""}
              </div> :
              <>
                  <div className="loader-payment"></div>
                  <p className={"loader-text"}>SENDING TIP...</p>
              </>
          }
      </div>
        {proccessing ? "" :
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button
                    type="normal"
                    onClick={sendFunds}
                    disabled={disableBtn()}
                >
                    {proccessing ?
                        <>
                            Sending
                            <span>
                          <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>
                       </span>
                        </>
                        : "Send"
                    }
                </Button>
            </div>
        }
    </>
  );
};

export default TipModal;
