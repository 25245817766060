import React, {FC, useState} from 'react'
import {Button} from "../../components/Button";
import {useModalContext} from "../../context/ModalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";

export const ListTags:FC<({labels:any,id:number, url:string, index:number,updateFile:any, deleteLabel:any})> = (props) => {

    const modalData = useModalContext()

    const countTags = () => {
        return labels.length
    }

    const [labels, setLabels] = useState<any> (props.labels)

    const [disable, setDisable] = useState(true)
    const [animation, setAnimation] = useState(false)

    const deleteTag = (e:any,label:any,id:number, labelId:any) => {
        props.deleteLabel(e,label,id)
        let updateTag = labels.filter(
            (x: any) => x.label.id !== labelId
        );
        setLabels(updateTag)
        setDisable(false)
        setAnimation(false)
    }

    const saveFunction = () => {
        setAnimation(true)
        setTimeout(async () => {
            modalData.close()
            setAnimation(false)
        }, 500);
    }

    return (
        <>
            <div className="fl-modal-title">
                {countTags()} tag{countTags() > 1 && "s"}
            </div>
            <div className="fl-modal-description">
                {labels.map((label:any) =>
                    <span
                        className={`fl-drive-image-label-name` }
                    >
                        {label.label.name}
                        <div className={"fa-label-delete"} onClick={(e) => deleteTag(e,label?.label,props.id,label.label.id)}>  <FontAwesomeIcon icon={faTimes} /> </div>
                    </span>
                )}
            </div>
            <div className={"fl-list-tags-link"}>
                <span className={"fl-blue-link fl-hover-underline"} onClick={(e:any) => props.updateFile(e,props.id,props.labels,props.url, props.index)}>Add more tags</span>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={() => modalData.close()}>Cancel</Button>
                <Button
                    type="normal"
                    onClick={(e:any) => saveFunction()}
                    disabled={disable || animation ? true : false}
                >
                    Save
                    {animation && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                </Button>
            </div>
        </>
    )
}
