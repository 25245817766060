import React, {FC, Fragment, useEffect, useRef, useState} from 'react'
import {useUserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophoneAlt, faShieldAlt} from "@fortawesome/free-solid-svg-icons";
import {SingleMessage} from "./SingleMessage";
import moment from "moment";
import {ProfileHolder} from "../../components/ProfileHolder";
import {Link} from "react-router-dom";
import ReactLoading from "react-loading";
import ReactTooltip from "react-tooltip";

export const MessageBox:FC<any> = (props) => {

    const userData = useUserContext()
    const messageScrollRef = useRef<HTMLDivElement | null>(null);
    const [notification, SetNotification] = useState({
        typing: false,
        recording: false
    })

    const groupMessages = () => {
        const groups = props.state?.messages?.reduce((groups:any, message:any) => {
            const date = message?.created_at?.split('T')[0];
            if (!groups[date]) {
                groups[date] = [{date:date}];
            }
            groups[date].push(message);
            return groups;
        }, {});
        if(groups) {
            const groupArrays = Object.keys(groups)?.map((date) => {
                return { messages: groups[date] }
            });
            // return groupArrays.sort((a: any, b: any) => moment(a.messages[0].date).format("DD MM YYYY") > moment(b.messages[0].date).format("DD MM YYYY") ? 1 : moment(b.messages[0].date).format("DD MM YYYY") > moment(a.messages[0].date).format("DD MM YYYY") ? -1 : 0)
            return groupArrays
        }
    }

    useEffect(() => {
        if (userData.signal !== null) {
            const signal = userData?.signal
            const type  = signal?.type === "App\\Notifications\\Chat\\ChatMessageTyping" ? "typing" : signal?.type === "App\\Notifications\\Chat\\ChatMessageRecording" ? "recording" : ""
            if (type !== "" && signal?.user.id === props?.state?.otherUser?.id ) {
                SetNotification ({...notification, typing: type === "typing" ? true : type === "recording" ? false : false,recording: type === "recording" ? true : false })
                setTimeout(()=> {
                    props.scrollBottom()

                },1)
            } else if(userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageCreated" && signal?.data.sender.id === props?.state?.otherUser?.id) {
                SetNotification ({...notification, typing: false,recording: false })
            } else if (userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageDeleted" && userData?.signal?.data?.delete_from !== userData.user.handle) {
                const message_id = userData?.signal?.data?.message_id
                props.setState({
                    ...props.state,
                    messages: props.state.messages.filter((x: any) => x.id !== message_id),
                });
            }
            userData.clearSignal();
        }
    }, [userData.signal]);

    const scrollMessage = () => {
        if (messageScrollRef.current) {
            const {scrollTop} = messageScrollRef.current;
            if (scrollTop < 100 && props.state.currentPage < props.state.lastPage && !props.animation) {
                props.getSingleChatMessages(props.conversationId,props.state.currentPage + 1,"",false)
            }
        }
    }

    return (
        <Fragment>
            <div className="fl-direct-messages-box-content">
                <div className={`fl-direct-messages-box-content-inner ${props.animation ? "fl-direct-messages-box-content-inner-animation" : ""}`}  ref={messageScrollRef} onScroll={() => scrollMessage()}>
                    {props.animation ?
                        <div className={"fl-loader-animation"}>
                            <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                        </div> :
                        <>
                            <div className={`fl-secure-message-header fl-secure-message-${userData.currentTheme}`}>
                                <div />
                                <h4 className={"fl-message-header-back"}>
                                    We have your back
                                    <FontAwesomeIcon icon={faShieldAlt} style={{marginLeft: '3%'}}/>
                                </h4>
                                <div />
                            </div>
                            <p className={`fl-secure-message-${userData.currentTheme}`}>
                                Stay safe and protected by keeping payments and communications within Fluffa.
                            </p>
                            {props.animationLoad ?
                                <div className={"fl-message-load-more"}>
                                    <ReactLoading type={"spinningBubbles"} color={"#888"} height={35} width={35} />
                                </div> : ""
                            }
                            {groupMessages()?.map((c: any, d: number) => (
                                (c.messages.map((x: any, y: number) => (
                                    x.date ?
                                        <div key={y} className={`fl-secure-message-header fl-message-bubble-new-day fl-secure-message-${userData.currentTheme}`}>
                                            <div />
                                            <h4>
                                                {moment(x.date).format("YYYY") === moment(new Date()).subtract(1, 'years').format("YYYY") ?  moment(x.date).format("D MMM, YYYY")  : moment(new Date()).format("DD MMM") === moment(x.date).format("DD MMM") ? ("Today") : moment(x.date).format("DD MMM") === moment(new Date()).subtract(1, 'days').format("DD MMM")? "Yesterday" : (moment(x.date).format("D MMM")) }
                                            </h4>
                                            <div />
                                        </div>
                                        :
                                        <SingleMessage
                                            singleMessageRef={props.singleMessageRef}
                                            key={x.id}
                                            data={x}
                                            direction={x?.sender?.handle === userData?.user?.handle ? "right" : "left"}
                                            progress={props.progress}
                                            cancelUpload={props.cancelUpload}
                                            setState={props.setState}
                                            state={props.state}
                                            searchTextDefault={props.searchTextDefault}
                                            submitMessage={props.submitMessage}
                                            cancel={props.cancel}
                                            deleteCancelledUpload={props.deleteCancelledUpload}
                                            lastMessage={props.lastMessage} setLastMessage={props.setLastMessage}
                                        />
                                )))
                            ))}
                            {notification.typing || notification.recording ?
                                <div className={`fl-message-bubble-main fl-message-bubble-main-left ${notification.recording ? "fl-message-bubble-main-left-recording" : ""}`}>
                                    {notification.typing ?
                                        <div className={`fl-message-bubble-message`}>
                                            <div className="fl-message-bubble-message-typing">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div> : ""
                                    }
                                    <span className="fl-message-bubble-typing-text" >{notification.typing ? "typing..." : notification.recording ?
                                        <>
                                           <span className={`fl-direct-messages-box-input-action-single-voice-record`}>
                                            <FontAwesomeIcon
                                                icon={faMicrophoneAlt}
                                                data-tip
                                                data-for="voice"
                                            /> Recording...
                                        </span>
                                        </> : ""}</span>
                                    <Link
                                        to={`/${props?.state?.otherUser?.handle}`}
                                        className={`fl-message-bubble-avatar fl-message-bubble-avatar-left`}
                                    >
                                        <ProfileHolder src={props?.state?.otherUser?.profileImage?.url} userProfile={props?.state?.otherUser} />
                                    </Link>
                                </div> : ""
                            }
                            <div  ref={props.messageBoxRef}></div>
                        </>
                    }
                </div>
            </div>
        </Fragment>
    )
}
