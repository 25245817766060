import React, {FC, useEffect, useRef, useState} from "react";
import { Button } from "../../components/Button";
import { SimpleCard } from "../../components/SimpleCard";
import "./Support.css";
import "../Privacy.css";
import { MInput } from "../../components/MInput";
import axios from "axios";
import { useEnvContext } from "../../context/EnvContext";
import { useUserContext } from "../../context/UserContext";
import { useModalContext } from "../../context/ModalContext";
import ReactLoading from "react-loading";
import {SupportNav} from "./SupportNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

export const SupportFeature: FC<any> = (props) => {
    const [feature, setFeature] = useState<any>("");
    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory()
    const [reports,setReports] = useState<any>({
        reports: [],
        page:1,
        last_page: 0
    })
    const [subject,setSubject] = useState("feature")
    const stateRef = useRef<any>();
    useEffect(() => {
        stateRef.current = reports;
    }, [reports]);

    const [animation,setAnimation] = useState(false)
    const submitBugReport = async () => {
        modalData.clearToasts()
        if (feature.length > 0) {
            setAnimation(true)

            const formData = new FormData();
            formData.append("body", feature);
            formData.append("subject", subject);

            axios
                .post(`${envData.apiUrl}/problem/reports?type=feature`, formData)
                .then((res) => {
                    // const fakeFeature = {
                    //     body: res.data.data.body,
                    //     created_at: res.data.data.created_at,
                    //     id: res.data.data.id,
                    //     comments:[],
                    //     status: "in_progress"
                    // }
                    // setReports({...reports, reports:[fakeFeature, ...reports.reports]})
                    setFeature("")
                    // setSubject("")
                    modalData.pushToast("success", `Thanks for suggesting a feature! We'll see what we can do...`);
                    setAnimation(false)
                })
                .catch((err) => {
                    modalData.pushToast("error", "Something went wrong!");
                    setAnimation(false)
                });
        }
    };

    const clearForm = () => {
        setSubject("")
        setFeature("")
    }
    const inputEl = useRef<any>(null);

    useEffect(() => {
        if (inputEl && inputEl.current) {
            inputEl.current.style.height = "0px";
            const scrollHeight = inputEl.current.scrollHeight;
            inputEl.current.style.height = scrollHeight + "px"
        }
    }, [props.value]);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            {/*{userData.authStatus === "AUTHENTICATED" ? <Subroutes /> : ""}*/}
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    {!isMobile ?  <SupportNav /> : ""}
                    <div className="fl-col-9 fl-support-col">
                        <SimpleCard>
                            {isMobile ?
                                <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                                    <span
                                        className="fl-direct-messages-title-icon"
                                        onClick={(e) => history.push('/support')}
                                    >
                                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                                </span>
                                    Suggest a feature
                                </div>
                                :
                                <h3>Suggest a feature</h3>
                            }
                            <MInput
                                ref={inputEl}
                                type="textarea"
                                label="Please tell us what you'd like to see on Fluffa..."
                                value={feature}
                                setValue={(feature) => setFeature(feature)}
                                className={"fl-settings-mt-30 "}
                                maxLength={2000}
                            />
                            <div className={"fl-d-flex"}>
                                <Button
                                    type="outlined"
                                    className="fl-settings-sub-security-button"
                                    onClick={() => clearForm()}
                                    disabled={feature.length === 0 || animation  ? true : false}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="normal"
                                    className="fl-settings-sub-security-button fl-ml-10"
                                    onClick={() => submitBugReport()}
                                    disabled={feature.length === 0 || animation ? true : false}
                                >
                                    Submit
                                    {animation &&
                                    <span>
                                      <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}
                                                    className="fl-spinningBubbles"/>
                                  </span>
                                    }
                                </Button>
                            </div>
                        </SimpleCard>
                        {/*<Support page={"feature"} reports={reports} setReports={setReports} stateRef={stateRef} />*/}
                    </div>
                </div>
            </div>
        </div>
    );
};
