import React, {FC, Fragment, useState} from "react";
import {useModalContext} from "../context/ModalContext";
import {useEnvContext} from "../context/EnvContext";
import Axios from "axios";
import smsAuthentificator from "../assets/images/smsAuthentificator.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {MInput} from "./MInput";
import {Button} from "./Button";
import ReactLoading from "react-loading";
// @ts-ignore
import PhoneInput from 'react-phone-input-2'
// @ts-ignore
import startsWith from 'lodash.startswith';
import 'react-phone-input-2/lib/material.css'
import moment from 'moment'
import axios from "axios";

export const TwoFactorSms: FC<{
    title: string;
    setState2fa:any;
    state2fa:any;
}> = ({ title,setState2fa,state2fa}) => {


    const modalData = useModalContext();
    const envData = useEnvContext();

    const [proccessing, setProccessing] = useState(false);
    const [proccessingResend, setProccessingResend] = useState(false);

    const [state, setState] = useState<{ number: string;confirm: boolean }>({
        number: "",
        confirm: false
    });
    const [stateCountry, setStateCountry] = useState<{ country:any }>({
        country:[]
    });

    const [code, setCode] = useState<{ code:any }>({
        code:""
    });

    const [validNumberError, setValidNumberError] = useState<{ error:boolean }>({
        error: false
    });
    const [validCodeError, setValidCodeError] = useState<{ error:boolean }>({
        error: false
    });

    const numberConfirmation = async () => {
        try {
            await Axios.post(`${envData.apiUrl}/auth/2fa/enable`, {
                "auth_type": "2fa_sms",
                "mobile_phone": state.number
            });
            setProccessing(true);
            setTimeout(async () => {
                setState({...state, confirm: true})
                setState2fa(true)
                setProccessing(false);
            }, 500);
        } catch (e) {
            setValidNumberError({...validNumberError, error: true})
        }
    }

    const codeConfirmation = async () => {
        setProccessing(true);
        if(state2fa) {
            axios.get(`${envData.apiUrl}/auth/2fa/disable`)
            setState2fa({...state2fa, type: "regular", enable: false});
        }
        setTimeout(async () => {
            try {
                await Axios.post(`${envData.apiUrl}/auth/2fa/phone/verify`, {
                    "code": code.code
                });
                setState2fa({...state2fa, type: "2fa_sms",enable:true})
                modalData.closeAll()
                setProccessing(false);
            } catch (e) {
                setProccessing(false);
                setValidCodeError({...validCodeError, error: true})
            }
        }, 700);
    }

    const [resendCodeStatus, setResendCodeStatus] = useState({
        status: false,
        nextResendTime:""
    });

    const resendCode = async () => {
        if(!resendCodeStatus.status || resendCodeStatus.nextResendTime < moment(new Date()).format()) {
            try {
                setProccessingResend(true);
                setTimeout(async () => {
                    await Axios.post(`${envData.apiUrl}/auth/2fa/enable`, {
                        "auth_type": "2fa_sms",
                        "mobile_phone": state.number
                    });
                    setResendCodeStatus({...resendCodeStatus, nextResendTime:moment().add(5, 'minutes').format(), status: true})
                    setProccessingResend(false);
                }, 500);
            } catch (e) {
                modalData.pushToast("error", "Something went wrong!");
            }
        } else {
            await modalData.pushToast(
                // "success",
                "resend",
                "Please wait a few minutes before you try again."
            );
        }
    }

    const disableButton = () => {
        if(state.confirm && !proccessing) {
            if (code.code.length > 0 && code.code.length < 7) {
                return false
            } else {
                return true
            }
        }else {
            if(state.number === undefined || state.number === "" || proccessing)
            {
                return true
            }else {
                return false
            }
        }
    }

    const handleChange = (value:string,data:any) => {
        if(value.length >= 9 && value.startsWith("0")) {
            setState({...state, number: data.dialCode + value.slice(1) })
        }else if(state.number.slice(data.dialCode.length).startsWith("0") && value.length >= 11) {
            setState({...state, number: data.dialCode + value.slice(1+data.dialCode.length) })
        }else {
            setState({...state, number: value })
        }
        setStateCountry({...stateCountry, country: data.countryCode})
    }

    return (
        <Fragment>
            <div className="fl-modal-title">{!state.confirm ? title : "Try the confirmation code now"}</div>
            <div className="fl-modal-description">
                {!state.confirm ? (
                    <>
                        <div className="fl-modal-description-row">
                            <div className={"fl-security-sms"}>
                                <img src={smsAuthentificator} alt={"2factor sms"} />
                                <p>Phone number verification</p>
                            </div>
                        </div>
                        <div className="fl-modal-description-row">
                            <p>Verify your mobile phone number and receive a text message with a confirmation code.</p><br />
                            <form autoComplete="off" style={{paddingBottom: "5px"}}>
                                <PhoneInput
                                    prefix={state.number.startsWith("0") ? "" : "+"}
                                    autoFormat={false}
                                    country={stateCountry.country !== "" ? stateCountry.country : "us"}
                                    value={state.number}
                                    onChange={(value,data,event,formattedValue) =>handleChange(value,data)}
                                    inputProps={{
                                        autoFocus: true
                                    }}
                                />
                            </form>
                            {validNumberError.error  && (
                                <span className="fl-modal-description-row-error fl-sms-request-error">
                                 <FontAwesomeIcon icon={faInfoCircle} /> {" "} Enter a valid phone number
                                </span>
                            )}
                        </div>
                    </>
                ):(
                    <>
                        <div className="fl-modal-description-row">
                            Enter the confirmation code we sent to +****{state.number.slice(-4)}. If you didn't get it, we can <span onClick={resendCode} className="fl-security-resend">resend</span> {" "}{!proccessingResend || <ReactLoading className='fl-loader-animation-resend' type={"spinningBubbles"} color={"#ff0d72"} height={10} width={10}/>}
                            {" "} the code.
                        </div>
                        <div className="fl-modal-description-row">
                            <form autoComplete="off">
                                <MInput
                                    type="text"
                                    label="Enter confirmation code"
                                    value={code.code}
                                    setValue={(code) => setCode({code:/[0-9]/.test(code[code.length - 1])
                                            ? code
                                            : code.slice(0, -1)})}
                                />
                            </form>
                            {validCodeError.error  && (
                                <span className="fl-modal-description-row-error">
                                 <FontAwesomeIcon icon={faInfoCircle} />{" "} The code you entered is incorrect, please try again.
                                </span>
                            )}
                            <p>Didn't enter the right phone number? <span className="fl-security-change-number" onClick={()=>setState({...state, confirm:false})}>Change it.</span></p>
                        </div>
                    </>
                ) }
            </div>
            <div className="fl-d-flex fl-justify-flex-end fl-modal-footer-buttons fl-security-footer">
                <Button onClick={()=> !state.confirm ? modalData.close() : setState({...state,confirm:false})}>Back</Button>
                <Button
                    type="normal"
                    onClick={!state.confirm ? numberConfirmation : codeConfirmation}
                    disabled={disableButton()}
                >
                    {!state.confirm ?
                        <>
                            Next
                            {!proccessing || <ReactLoading className='fl-loader-animation' type={"spinningBubbles"} color={"#ffffff"} height={20} width={20}/>}
                        </>
                        :
                        <>
                            Confirm
                            {!proccessing || <ReactLoading className='fl-loader-animation' type={"spinningBubbles"} color={"#ffffff"} height={20} width={20}/>}
                        </>
                    }
                </Button>
            </div>
        </Fragment>
    );
}
export default TwoFactorSms;
