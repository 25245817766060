import React, { FC } from 'react';
import { useUserContext } from '../context/UserContext';
import "./ImageSection.css";
import { Button } from './Button';

// SHAPES
import firstShape from '../assets/images/shapes/1.svg';
import secondShape from '../assets/images/shapes/2.svg';
import thirdShape from '../assets/images/shapes/3.svg';
import fourthShape from '../assets/images/shapes/4.svg';

interface ImageSectionProps {
    title: string;
    description: string;
    image: string;
    invert?: boolean;
}

export const ImageSection:FC<ImageSectionProps> = (props) => {

    // PROPS
    const { title, description, image, invert } = props || {};
    
    // CONTEXT
    const userData = useUserContext();

    return (
        <div className={`fl-image-section ${invert ? 'fl-image-section-invert' : ''}`}>
            <div className=" fl-col-5 fl-image-section-text">
                <div className={`fl-image-section-title fl-image-section-title-${userData.currentTheme}`}>{title}</div>
                <div className={`fl-image-section-separator fl-image-section-separator-${userData.currentTheme}`}></div>
                <div className={`fl-image-section-description fl-image-section-description-${userData.currentTheme}`}>{description}</div>
                <Button to="/register" type="outlined">Get Started</Button>
            </div>
            <div className="fl-col-7 fl-image-section-image">
                {invert ? (
                    <div className="fl-image-section-image-shapes">
                        <img className="fl-image-section-image-shape fl-image-section-image-shape-4" src={fourthShape} alt="Fourth Shape"/>
                        <img className="fl-image-section-image-shape fl-image-section-image-shape-3" src={thirdShape} alt="Third Shape"/>
                        <img className="fl-image-section-main-image" src={image} alt="Main"/>
                    </div>
                ) : (
                    <div className="fl-image-section-image-shapes">
                        <img className="fl-image-section-image-shape fl-image-section-image-shape-2" src={secondShape} alt="Second Shape"/>
                        <img className="fl-image-section-image-shape fl-image-section-image-shape-1" src={firstShape} alt="First Shape"/>
                        <img className="fl-image-section-main-image" src={image} alt="Main"/>
                    </div>
                )}
            </div>
        </div>
    )
}