import React, { FC, useState, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import {
  faInfoCircle,
  faTimes,
  faSmile,
  faPhotoVideo,
  faGlobeEurope,
  faDollarSign,
  faUserFriends,
  faExclamationCircle,
  faRedoAlt,
  faCaretDown,
  faClock,
  faCaretUp,
  faEye,
  faEyeSlash,
  faHdd, faWindowMinimize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { useUserContext } from "../context/UserContext";
import { Button } from "./Button";
import { SimpleCard } from "./SimpleCard";
import { Textarea } from "./Textarea";
import Picker from "emoji-picker-react";
import "./WritePost.css";
import ReactTooltip from "react-tooltip";
import { MInput } from "./MInput";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Line } from 'rc-progress';
import { Toggle } from "./Toggle";
//@ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RangeModal } from "./RangeModal";
import hideImage from "../assets/images/hideimage.png";
import { FluffaDrive } from "./FluffaDrive";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { DatePickerScheduled } from "./DatePickerScheduled";
import { Tagging } from "./Tagging";
import PreviewVideo from "./video/PreviewVideo";
export const WritePost: FC<{
  onClear?: () => any;
  mobileResponsive?: boolean;
  refetchData?: () => void;
  setStatePost?: any;
  setStatePostRef?: any;
  profile?: any;
  setProfilePosts?: any;
  profilePosts?: any;
  profileRef?: any;
  modal?: any;
  quickPost?: any;
  [key: string]: any;
  parentRef?:any;
  typePostProfile?:any
}> = ({
        mobileResponsive,
        onClear,
        className,
        style,
        refetchData,
        setStatePostRef,
        setStatePost,
        profilePosts,
        setProfilePosts,
        profile,
        profileRef,
        modal,
        quickPost,
        parentRef,
        typePostProfile = "default"
      }) => {
  // STATE
  const [showScheduleButton, setShowScheduleButton] = useState<boolean>(false);
  const [scheduleDate] = useState<string>("");
  const [forceErrors] = useState<boolean>(false);
  const [clear1, setClear1] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [state, setState] = useState<{
    images: any;
    videos: any;
    type: string;
    locked: number;
    price: any;
    limited: boolean;
    imagesUpload: any;
    videosUpload: any;
    blurred: string;
    blurredPercent: any;
  }>({
    images: [],
    videos: [],
    type: "private",
    locked: 1,
    price: 5,
    limited: false,
    imagesUpload: [],
    videosUpload: [],
    blurred: "blurred",
    blurredPercent: 50,
  });
  const [progress, setProgress] = useState<{
    uploading: boolean;
    percent: number;
  }>({
    uploading: false,
    percent: 0
  });

  const [cancel, setCancel] = useState<{
    cancel: boolean;
  }>({
    cancel: false,
  });

  const [retry, setRetry] = useState<{
    retry: boolean;
  }>({
    retry: false,
  });

  const [uploadState, setUploadState] = useState<any>({
    files: [],
  });

  const [text, setText] = useState<string>("");
  const [typing, setTyping] = useState<boolean>(false);

  const [postType, setPostType] = useState<boolean>(false);

  // CONTEXT
  const envData = useEnvContext();
  const userData = useUserContext();
  const modalData = useModalContext();

  const attachRef = useRef<any>(null);
  const attachRefMain = useRef<any>(null);

  const [refresh, setRefresh] = useState<boolean>(false);
  const CancelToken = Axios.CancelToken;
  const source = useMemo(() => CancelToken.source(), [refresh]);
  const history = useHistory();

  const goToPost = (id: any) => {
    history.push(`/post/${id}`);
  };

  const sendNotification = async (id: number, text: string) => {
    let regex = /@\[(\S+)\]/g;
    const users = text.match(regex);
    const formData = new FormData();
    users?.forEach((x: any) => {
      formData.append("profiles[]", x.match(/\((.*)\)/)?.pop());
    });
    await Axios.post(`${envData.apiUrl}/posts/${id}/tag`, formData);
  };
  const typeOfPosts = () => {
    return  new URLSearchParams(window.location.search).get("posts") || "posts"
  }
  const [restrictedWords, setRestrictedWords] = useState<any>([]);
  const [wrongTypeFile, setWrongTypeFile] = useState<any>(false);
  const processPost = async (publishDate = "") => {
    modalData.clearToasts();
    setCancel({ ...cancel, cancel: false });
    setRetry({ ...retry, retry: false });
    if (progress.uploading === true) return;
    setProgress({ percent: 0, uploading: true});

    if (userData.user.profile.meta.verified_state !== "verified") {
      localStorage.setItem("messageForUnapprovedPost", "show");
    }
    if (userData.user.profile.meta.verified_state === "verified") {
      localStorage.removeItem("messageForUnapprovedPost");
    }

    try {
      const data = new FormData();
      if (uploadState?.files?.length > 0) {
        uploadState.files.forEach((x: any) => {
          if (x.id) {
            data.append("fluffa_drive_files[]", x.id);
          }
          if (x.type === "image") {
            data.append("post_images[]", x.file);
          }
          if (x.type === "video") {
            data.append("post_videos[]", x.file);
          }
        });
        if (state.type === "ppv") {
          if (free) {
            data.append("blurred", "black");
          } else {
            if (state.blurred === "blurred") {
              data.append("blurred", state.blurredPercent);
            } else {
              data.append("blurred", "black");
            }
          }
        }
      }
      data.append("text", text);
      if (publishDate) data.append("publish_date", moment(publishDate).toISOString());
      data.append("type", String(state.type));
      state.type === "ppv" && data.append("price", String(state.price));
      state.type === "ppv" ||
      (state.type === "private" &&
          data.append("locked", String(state.locked)));
      state.limited ? data.append("limited", "0") : data.append("limited", "1");
      const res = await Axios.post(`${envData.apiUrl}/posts`, data, {
        cancelToken: source.token,
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (p) => {
          setProgress({
            uploading: true,
            percent: Math.round(((p.loaded || 0) / p.total) * 100 - 1),
          });
        },
      });
      let regex = /@\[(\S+)\]/g;
      const users = text.match(regex);
      if (users) {
        await sendNotification(res.data.data.id, text);
      }
      if(uploadState.files.length === 0 && text) {
        const scheduledDate = () => {
          const publish_date = publishDate
          if(moment(publish_date).format("DD MMM") === moment(new Date()).format("DD MMM")) {
            return "Today, " + moment(publish_date).format("HH:mm")
          } else if (moment(publish_date).subtract(1, 'days').format("DD MMM") === moment(new Date()).format("DD MMM")) {
            return "Tomorrow, " + moment(publish_date).format("HH:mm")
          } else if(moment(publish_date).format("YYYY") === moment(new Date()).format("YYYY")) {
            return moment(publish_date).format("ddd, D MMM, HH:mm")
          } else {
            return moment(publish_date).format("ddd, D MMM YYYY, HH:mm")
          }
        }
        modalData.pushToast(
            "success",
            <>
              {publishDate ?
                  <>
                    Post scheduled for{" "} {scheduledDate()} {" "}
                    <span className={"toast-link"} onClick={() => goToPost(res.data.data.id)}>
                      View post
                    </span>
                  </> :
                  <>
                  Your Post was uploaded.{" "}
                  <span className={"toast-link"} onClick={() => goToPost(res.data.data.id)}>
                      View
                  </span>
                  </>
              }
            </>
        );
      }
     if(publishDate?.length === 0 && uploadState.files.length === 0 || (publishDate?.length > 0 && uploadState.files.length === 0 && typeOfPosts() === "scheduled") ) {
       if (profile) {
         setProfilePosts({
           ...profileRef.current,
           posts: [res.data.data, ...profileRef.current.posts],
         });
       } else if (quickPost) {
         userData.newPostFunction(res.data.data, window.location.pathname);
       } else {
         setStatePost({
           ...setStatePostRef.current,
           posts: [res.data.data, ...setStatePostRef.current.posts],
         });
       }
     }
      userData.updateCount(publishDate ? "createPostScheduled" : "createPost")

      closeControl();
      if(mobileResponsive) {
        if (onClear) {
          onClear()
        }
        if (uploadState.files.length > 0) {
          userData.encoding(true,1,uploadState.files[0].src,uploadState.files[0].type)
          setUploadState({
            ...uploadState,
            files: [],
          });
        }
        modalData.closeAll();
      } else {
        modalData.closeAll();
        clearPost();
      }
      setProgress({ uploading: false, percent: 0});
    } catch (e) {
      setProgress({ uploading: false, percent: 0});
      setRefresh(!refresh);
      if (e?.response?.data?.errors?.restricted_words?.length > 0) {
        setRestrictedWords(e?.response?.data?.errors?.restricted_words);
      }
    }
  };

  const clearError = () => {
    if (restrictedWords.length > 0) {
      setRestrictedWords([]);
    }
    if(wrongTypeFile) {
      setWrongTypeFile(false)
    }
  }

  const openControls = () => {
    setTyping(true);
    clearError()
  };

  const clearPost = () => {
    setText("");
    setTyping(false);
    setState({
      images: [],
      videos: [],
      type: "private",
      locked: 1,
      price: 5,
      limited: false,
      imagesUpload: [],
      videosUpload: [],
      blurredPercent: 50,
      blurred: "blurred",
    });
    if (uploadState.files.length > 0) {
      userData.encoding(true,1,uploadState.files[0].src,uploadState.files[0].type)
      setUploadState({
        ...uploadState,
        files: [],
      });
    }
    clearError()
    const textArea = document.getElementById(
        "write-post-text"
    ) as HTMLInputElement;
    textArea.value = "";
  };

  const removeFile = (e: any, idx: number) => {
    e.stopPropagation();
    setUploadState({
      ...uploadState,
      files: uploadState.files.filter((x: any, y: number) => y !== idx),
    });

    if (uploadState.files.length === 1) {
      setCancel({ ...cancel, cancel: false });
      setRetry({ ...retry, retry: false });
    }
  };

  const [emojiState, setEmojiState] = useState({
    active: false,
  });
  const emojiRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (emojiRef.current && !emojiRef.current.contains(e.target as Node)) {
        setEmojiState({ active: false });
      }
    };
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.addEventListener("click", clickOutside, true);
    };
  }, []);

  useEffect(() => {
    const keyDownWritePost = (e: KeyboardEvent) => {
      if (emojiState && e.key === "Escape") {
        setEmojiState({ active: false });
      } else if (e.key === "Escape")   {
        modalData.close()
      }
    };
    document.addEventListener("keydown", keyDownWritePost);
    return () => {
      document.removeEventListener("keydown", keyDownWritePost);
    };
  }, []);

  const handleFile = async (files:any) => {
    if (!files) return;
    const uploadedFiles: any = files || [];
    const filesArray: any = [...uploadedFiles];
    if(filesArray.find((x:any) => x.type === "")) {
      setWrongTypeFile(true)
    }
    const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
      const fileType = file.type.split("/")[0]
      return {
        type: fileType,
        file,
        src: URL.createObjectURL(file),
      };
    });
    setUploadState({
      ...uploadState,
      files: [...uploadState.files, ...newFiles],
    });
    if(attachRef.current) {
      attachRef.current.value = ""
    }
    if(attachRefMain.current) {
      attachRefMain.current.value = ""
    }
  };

  const cancelUpload = async () => {
    setProgress({ uploading: false, percent: 0});
    setCancel({ ...cancel, cancel: true });
    setRetry({ ...retry, retry: true });
    source.cancel();
  };

  useEffect(() => {
    if (modalData?.state === "hide") setShowScheduleModal(false);
  }, [modalData]);

  const handleScheduleDate = async (x: string | Date, y?: string) => {
    let publishDate = moment(x).format("YYYY-MM-DD");

    // if (!validateSchedule(new Date(`${publishDate} ${y}`)) && !isMobile) {
    //   modalData.pushToast(
    //       "error",
    //       "You have entered an invalid date, please try again."
    //   );
    //   return;
    // }
    processPost(`${publishDate} ${y}`);
    // modalData.close();
    setShowScheduleModal(false);
    setShowScheduleButton(false);
  };

  const validateSchedule = (date:any) => {
    return (date ?? "") > new Date(); // returns false if undefined or earlier than current time
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    hideBlurFunction();
    if (!result.destination) {
      return;
    }
    const items = reorder(
        uploadState.files,
        result.source.index,
        result.destination.index
    );
    setUploadState({ ...uploadState, files: items });
    setFocusFileIndex(result.destination.index)
  };
  const keyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowRight") {
      userData.keyboardEvent("ArrowRight");
    } else if (e.key === "ArrowLeft") {
      userData.keyboardEvent("ArrowLeft");
    } else {
    }
  };
  const openRangeModal = () => {
    modalData.push(() => (
        <RangeModal setState={setState} state={state} uploadState={uploadState} />
    ));
  };

  const [free] = useState<boolean>(false);
  const [blurred, setBlurred] = useState<boolean>(true);

  const rightClickBlur = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const blurPreview = () => {
    if (state.blurredPercent < 10) {
      return state.blurredPercent / 40 + 1;
    } else {
      return state.blurredPercent / 40 + 1;
    }
  };

  const flDrive = () => {
    modalData.push(() => (
        <FluffaDrive
            route={"files"}
            setUploadState={setUploadState}
            uploadState={uploadState}
        />
    ));
  };

  const dropFiles = async (file: any) => {
    let element = file.currentTarget.parentNode as Element;
    file.preventDefault();
    const uploadedFiles: any = file.dataTransfer.files || [];
    const filesArray: any = [...uploadedFiles];
    if(filesArray.find((x:any) => x.type === "")) {
      setWrongTypeFile(true)
    }
    const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
      const fileType = file.type.split("/")[0]
      return {
        type: fileType,
        file,
        src: URL.createObjectURL(file),
      };
    });
    setUploadState({
      ...uploadState,
      files: [...uploadState.files, ...newFiles],
    });

    element.classList.remove("fl-write-post-drop");
    if(!typing){
      setTyping(true)
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      setState({
        ...state,
        price:
            parseInt(state.price) + 1 > 100 ? 100 : parseInt(state.price) + 1,
      });
    }
    if (e.key === "ArrowDown") {
      setState({ ...state, price: state.price - 1 < 0 ? 0 : state.price - 1 });
    }
  };

  const [hideBlur, setHideBlur] = useState<any>(false);
  const [focusFileIndex,setFocusFileIndex] = useState<any>(isMobile ? 0 : 0)

  const showBlurFunction = (e?: any,index?:any) => {
    e.stopPropagation();
    if (e.button === 0) {
      setHideBlur(true);
    }
    setFocusFileIndex(index)
  };

  const hideBlurFunction = () => {
    setHideBlur(false);
  };

  const dragOver = (e: any) => {
    e.preventDefault();
    let element = e.currentTarget.parentNode as Element;
    element.classList.add("fl-write-post-drop");
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
    let element = e.currentTarget.parentNode as Element;
    element.classList.remove("fl-write-post-drop");
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const closeControl = () => {
    setState({
      images: [],
      videos: [],
      type: "private",
      locked: 1,
      price: 5,
      limited: false,
      imagesUpload: [],
      videosUpload: [],
      blurredPercent: 50,
      blurred: "blurred",
    });
    if (uploadState.files.length > 0) {
      setUploadState({
        ...uploadState,
        files: [],
      });
    }
    clearError()
    setRetry({ ...retry, retry: false });
    setCancel({ ...cancel, cancel: false });
    setText("");
    setShowScheduleButton(false);
    setTyping(!typing);
    onClear && onClear();
  };

  const typePost = (text: any) => {
    setText(text);
    clearError()
  };

  const openSchedulerModal = () => {
    setShowScheduleModal(true)
    localStorage.setItem("controls", "controls")
  }

  const returnUploadProgress = () => {
    return userData.encodingStatus.percent
  }

  useEffect(() => {
    if(userData.encodingStatus.status || progress.uploading)
    {
      window.addEventListener("beforeunload", onLoadPage);
      return () => {
        window.removeEventListener("beforeunload", onLoadPage);
      };
    }
  }, [userData.encodingStatus.status,progress.uploading]);

  const onLoadPage = (event:any) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  const closeWritePost = () => {
    if(userData.mobilePost && progress.uploading) {
      userData.mobileCreatePost(false)
    } else {
      setTyping(false);
      setShowScheduleButton(false);
      modalData.close();
      onClear?.();
      clearError();
    }
  }

  useEffect(() => {
    if (userData?.signal?.type === "App\\Notifications\\PostFinishedEncoding" && userData?.signal?.post?.postAttachments?.length > 0 && mobileResponsive) {
       modalData.close()
    }
  }, [userData.signal]);

  const [audienceHover,setAudienceHover] = useState(state.type)

  return (
      <>
        {!userData.encodingStatus.status ?
            <SimpleCard
                className={`fl-write-post-main ${
                    mobileResponsive ? "fl-write-post-main-mobile" : ""
                } ${userData.mobilePost ? "fl-main-mobile-create-post" : ""} ${className}`}
                style={style}
                onDragOver={(e: any) => dragOver(e)}
                onDragLeave={(e: any) => dragLeave(e)}
                onDragEnter={(e: any) => dragEnter(e)}
                onDrop={(files: any) => dropFiles(files)}
            >
              {userData.mobilePost ?
                  <div className={"fl-write-mobile-post"}>
                    Create post
                  </div> : ""
              }
              {typing || mobileResponsive ? (
                  <div
                      className={`fl-write-post-cancel fl-write-post-cancel-${userData.currentTheme} ${progress.uploading && !userData.mobilePost ? "fl-button-disabled" : ""}`}
                      onClick={() => progress.uploading && userData.mobilePost ? userData.mobileCreatePost(false) : closeWritePost()}
                  >
                    <FontAwesomeIcon icon={progress.uploading && userData.mobilePost ? faWindowMinimize : faTimes}/>
                  </div>
              ) : (
                  ""
              )}
              <div
                  ref={controlsRef}
                  className={`fl-write-post-all-${userData.currentTheme} ${
                      restrictedWords.length > 0 || wrongTypeFile ? "fl-restricted-box" : ""
                  }`}
              >
                <div
                    className={"fl-write-post-drop-files"}
                    onDragOver={(e: any) => dragOver(e)}
                    onDragLeave={(e: any) => dragLeave(e)}
                    onDragEnter={(e: any) => dragEnter(e)}
                    onDrop={(files: any) => dropFiles(files)}
                >
                  <span>Drop files here</span>
                </div>
                <div
                    className={`fl-write-post-all  ${
                        typing || mobileResponsive
                            ? ""
                            : "fl-write-post-all-hover"
                    }
                    ${progress.uploading ? "fl-button-disabled" : ""}
                    `}
                >
                  {userData.user.profile.meta.verified_state !== "verified" ? (
                      <Textarea
                          id="write-post-text"
                          className={`fl-write-post-textarea  ${progress.uploading ? "fl-button-disabled" : ""}`}
                          placeholder="Got something cool to share?"
                          value={text}
                          onFocus={() => {
                            openControls();
                          }}
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                              progress.uploading ? "" : typePost(e.currentTarget.value)
                          }
                          onKeyDown={(e:any) => e?.key === "Escape" ? closeControl() : ""}
                      />
                  ) : (
                      <Tagging
                          openControls={openControls}
                          setText={typePost}
                          text={text}
                          typing={typing}
                          placeholder="Got something cool to share?"
                          keydownFunction={closeControl}
                      />
                  )}
                </div>
                {restrictedWords.length > 0 && (
                  <span className="fl-modal-description-row-error fl-write-post-restricted">
                    <FontAwesomeIcon icon={faInfoCircle}/> You have entered restrictedwords: {restrictedWords.join(",")}
                  </span>
                )}
                {wrongTypeFile && (
                    <span className="fl-modal-description-row-error fl-write-post-restricted">
                    <FontAwesomeIcon icon={faInfoCircle}/> You have uploaded wrong type of file
                  </span>
                )}
                {(uploadState.files.length > 0 && typing) || mobileResponsive ? (
                    <div
                        className={`fl-write-post-preview`}
                        onContextMenu={(e) => rightClickBlur(e)}
                    >
                      {progress.uploading === true && uploadState?.files?.length > 2 && (
                          <div
                              className={`fl-write-post-progress-bar fl-write-post-progress-bar-right fl-write-post-progress-bar-${userData.currentTheme}`}
                          >
                            <CircularProgressbar
                                value={progress.percent < 0 ? 0 : progress.percent}
                                text={`${progress.percent < 0 ? 0 : progress.percent}%`}
                            />
                            <span className="circural-progress-bar-span">
                            {uploadState?.files?.length > 1
                                ? `Uploading ${uploadState?.files?.length} files`
                                : `Uploading ${uploadState?.files?.length} file`}
                          </span>
                          </div>
                      )}
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                          {(provided: any) => (
                              <div
                                  className={`fl-write-post-preview-drag fl-write-post-preview-drag-${mobileResponsive ? "mobile" : "default"} ${progress.uploading ? "fl-button-disabled" : ""}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                              >
                                {uploadState.files.map((x: any, index: number) => (
                                    <Draggable
                                        key={`item-${index}`}
                                        draggableId={`item-${index}`}
                                        index={index}
                                    >
                                      {(provided: any, snapshot: any) => (
                                          <div
                                              className={`fl-write-post-preview-img ${focusFileIndex === index ? "fl-write-post-preview-img-focus" : "" }`}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              onMouseUp={(e) => hideBlurFunction()}
                                              onMouseDown={(e) => showBlurFunction(e,index)}
                                              onContextMenu={(e) => rightClickBlur(e)}
                                              onTouchStart={(e) => setFocusFileIndex(index)}
                                          >
                                            {x.type === "image" ? (
                                                <img
                                                    alt={"fluffa"}
                                                    style={{
                                                      filter: `blur(${
                                                          state.blurred === "blurred" &&
                                                          state.type === "ppv" &&
                                                          !hideBlur
                                                              ? blurPreview()
                                                              : "0"
                                                      }px)`,
                                                    }}
                                                    src={state?.blurred === "black" && state.type === "ppv" && !hideBlur
                                                          ? hideImage
                                                          : x.src || x.url
                                                    }
                                                />
                                            ) : x.type === "video" ? (
                                                <>
                                                  {state?.blurred === "black" && state.type === "ppv" && !hideBlur ?
                                                      <img src={hideImage} alt={"fluffa"}/> :
                                                      <PreviewVideo state={state} hideBlur={hideBlur} blurPreview={blurPreview} mobileResponsive={mobileResponsive} src={x.src}/>
                                                  }
                                                </>
                                            ) : "" }

                                            {/*{(x.type === "video" && state.blurred == "blurred") ||*/}
                                            {/*(x.type === "video" && state.price === 0) ||*/}
                                            {/*(x.type === "video" && hideBlur) ? (*/}
                                            {/*     <PreviewVideo state={state} hideBlur={hideBlur} blurPreview={blurPreview} mobileResponsive={mobileResponsive} src={x.src}/>*/}
                                            {/*    ) : x.type === "video" && state.blurred === "black" ? (*/}
                                            {/*      <img src={hideImage}/>*/}
                                            {/*    ) : ("")}*/}
                                            {progress.uploading ? "" :
                                                <div
                                                    className={`fl-write-post-preview-img-remove`}
                                                    onClick={(e) => removeFile(e, index)}
                                                >
                                                  <FontAwesomeIcon icon={faTimes}/>
                                                </div>
                                            }
                                          </div>
                                      )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {uploadState.files.length > 0 && (
                          <>
                            {!progress.uploading &&
                              <div
                                  className={`fl-bulk__input__files--line--add fl-bulk__input__files--line--add-${mobileResponsive ? "mobile" : "default"}`}
                                  onClick={() => progress.uploading ? "" :  attachRef.current.click()}
                                  data-tip
                                  data-for="add-media"
                              >
                                <input
                                    ref={attachRef}
                                    id="upload_image"
                                    multiple
                                    accept="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
                                    type="file"
                                    style={{display: "none"}}
                                    onChange={(e) => handleFile(e.currentTarget.files)}
                                />
                                +
                              </div>
                            }
                            <ReactTooltip id="add-media">Add more</ReactTooltip>
                            {progress.uploading && uploadState?.files?.length < 3 && !mobileResponsive && (
                                <div
                                    className={`fl-write-post-progress-bar fl-write-post-progress-bar-left fl-write-post-progress-bar-${userData.currentTheme}`}
                                >
                                  <CircularProgressbar
                                      value={progress.percent < 0 ? 0 : progress.percent}
                                      text={`${progress.percent < 0 ? 0 : progress.percent}%`}
                                  />
                                  <span className="circural-progress-bar-span">
                                  {uploadState?.files?.length > 1
                                      ? `Uploading ${uploadState?.files?.length} files`
                                      : `Uploading ${uploadState?.files?.length} file`}
                                </span>
                                </div>
                            )}
                          </>
                      )}
                    </div>
                ) : (
                    ""
                )}
                {typing || mobileResponsive ? (
                    <>
                      <div
                          className={`fl-write-post-privacy fl-write-post-privacy-${userData.currentTheme}`}
                          style={{marginTop: "8px"}}
                          onContextMenu={(e) => rightClickBlur(e)}
                      >
                        <div
                            className={`fl-write-post-audience ${
                                state.type === "ppv" && "fl-write-post-audience-ppv"
                            }
                            ${progress.uploading ? "fl-button-disabled" : ""}
                            `}
                        >
                          <span>Select audience: </span>
                          <div
                              className={`fl-write-post-select-bar fl-write-post-select-bar-${userData.currentTheme}`}
                              tabIndex={1}
                              onClick={() => setPostType(!postType)}
                              onBlur={() => setPostType(false)}
                              onKeyDown={handleKeyDown}
                          >
                            {state.type === "private" && (
                                <>
                                  <FontAwesomeIcon icon={faUserFriends}/> Subscribers{" "}
                                  <FontAwesomeIcon icon={faCaretDown}/>
                                </>
                            )}
                            {state.type === "public" && (
                                <>
                                  <FontAwesomeIcon icon={faGlobeEurope}/> Public{" "}
                                  <FontAwesomeIcon icon={faCaretDown}/>
                                </>
                            )}
                            {state.type === "ppv" && (
                                <>
                                  <FontAwesomeIcon icon={faDollarSign}/> PPV{" "}
                                  <FontAwesomeIcon icon={faCaretDown}/>
                                </>
                            )}
                            {postType ? (
                                <div
                                    className={`fl-write-post-select-bar--option fl-post-head-actions-dropdown-${userData.currentTheme}`}
                                >
                                  <SimpleCard className="fl-post-head-actions-dropdown-inner">
                                    <div
                                        className={`fl-post-head-actions-dropdown-option ${audienceHover === "private" && "fl-create-post-option-active"}`}
                                        onClick={() =>
                                            setState({...state, type: "private"})
                                        }
                                        data-tip
                                        data-for="private-post"
                                        onMouseEnter={() => setAudienceHover("private")}
                                        onMouseLeave={() => setAudienceHover(state.type)}
                                    >
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faUserFriends}/>
                                      </div>
                                      Subscribers
                                    </div>
                                    <ReactTooltip id="private-post">
                                      Only paying subscribers can view (included in
                                      subscription price)
                                    </ReactTooltip>
                                    <div
                                        className={`fl-post-head-actions-dropdown-option  ${audienceHover === "ppv" && "fl-create-post-option-active"}`}
                                        onClick={() => setState({...state, type: "ppv"})}
                                        data-tip
                                        data-for="ppv-post"
                                        onMouseEnter={() => setAudienceHover("ppv")}
                                        onMouseLeave={() => setAudienceHover(state.type)}
                                    >
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faDollarSign}/>
                                      </div>
                                      PPV
                                    </div>
                                    <ReactTooltip id="ppv-post">
                                      Premium post which subscribers need to pay to unlock
                                      (not included in subscription price)
                                    </ReactTooltip>
                                    <div
                                        className={`fl-post-head-actions-dropdown-option  ${audienceHover === "public" && "fl-create-post-option-active"}`}
                                        onClick={() => setState({...state, type: "public"})}
                                        data-tip
                                        data-for="public-post"
                                        onMouseEnter={() => setAudienceHover("public")}
                                        onMouseLeave={() => setAudienceHover(state.type)}
                                    >
                                      <div className="fl-post-head-actions-dropdown-option-icon">
                                        <FontAwesomeIcon icon={faGlobeEurope}/>
                                      </div>
                                      Public
                                    </div>
                                    <ReactTooltip id="public-post">
                                      Visible to anyone on or off Fluffa
                                    </ReactTooltip>
                                  </SimpleCard>
                                </div>
                            ) : (
                                ""
                            )}
                          </div>
                        </div>
                        {state.type === "ppv" && (
                            <div className={`fl-write-post-blur  ${progress.uploading ? "fl-button-disabled" : ""}`}>
                              {!free && (
                                  <div className={`fl-write-post-blur-range`}>
                                    {!free && (
                                        <div className="fl-bulk__price__toggle fl-bulk__price__toggle-blurred">
                                          <Toggle
                                              leftComponent={<FontAwesomeIcon icon={faEye}/>}
                                              rightComponent={
                                                <FontAwesomeIcon icon={faEyeSlash}/>
                                              }
                                              value={blurred ? true : false}
                                              onClick={() => {
                                                blurred
                                                    ? setState({...state, blurred: "black"})
                                                    : setState({...state, blurred: "blurred"});
                                                setBlurred(!blurred);
                                              }}
                                          />
                                          {blurred ? (
                                              <div>
                                <span
                                    className={`fl-bulk__price__label fl-bulk__price__label__${
                                        uploadState?.files?.length > 0
                                            ? "enable"
                                            : "disable"
                                    }`}
                                >
                                  <span
                                      onKeyDown={keyboardEvent}
                                      tabIndex={0}
                                      className={`fl-write-post-thumbnail`}
                                      onClick={() =>
                                          uploadState?.files?.length > 0
                                              ? openRangeModal()
                                              : ""
                                      }
                                  >
                                    Blur thumbnail (
                                    {
                                      state.blurredPercent
                                          .toFixed(1)
                                          .split(".")[0]
                                    }
                                    %)
                                  </span>{" "}
                                  <FontAwesomeIcon
                                      icon={faInfoCircle}
                                      data-tip
                                      data-for="attachments-blurred"
                                  />
                                </span>
                                                <ReactTooltip id="attachments-blurred">
                                                  Select the level of blur you’d like to add to
                                                  the thumbnail. Less blur gives the recipient a
                                                  better idea of what they’re unlocking.
                                                </ReactTooltip>
                                              </div>
                                          ) : (
                                              <>
                                                <div>
                                  <span className={`fl-bulk__price__label`}>
                                    Hide thumbnail{" "}
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        data-tip
                                        data-for="m"
                                    />
                                  </span>
                                                </div>
                                                <ReactTooltip id="m">
                                                  Thumbnail will be fully hidden and only a lock
                                                  icon will show.
                                                </ReactTooltip>
                                              </>
                                          )}
                                        </div>
                                    )}
                                  </div>
                              )}
                              <div
                                  className={`fl-write-post-blur-price fl-write-post-price`}
                              >
                                <div className={"fl-write-post-price-label"}>
                                  <FontAwesomeIcon
                                      icon={faInfoCircle}
                                      data-tip
                                      data-for={"fl-post-price-label"}
                                  />
                                  <ReactTooltip id={"fl-post-price-label"}>
                                    You can set a price from $5 to $100 for PPV content
                                  </ReactTooltip>
                                  <span>Set unlock price ($)</span>
                                </div>
                                <div>
                                  {!free && (
                                      <>
                                        <MInput
                                            style={{
                                              maxWidth: "130px",
                                              display: "inline-block",
                                              marginTop: "-4px",
                                              marginLeft: ".5rem",
                                            }}
                                            minIsSet="5"
                                            label=""
                                            type="number"
                                            hasInfo={false}
                                            infoId="posts-price"
                                            infoText={[
                                              "You can set a price from $5 to $100 for PPV content",
                                            ]}
                                            value={state.price}
                                            setValue={(price) => {
                                              if ((price <= 100 && price !== 0) || price === "") {
                                                setState({...state, price});
                                              }
                                            }}
                                            onKeyDown={handleKeyDown}
                                        />
                                      </>
                                  )}
                                </div>
                                {state.price < 5 && !free && state.type === "ppv" && (
                                    <span className={"fl-write-post-price-error"}>
                          Minimum $5 USD
                        </span>
                                )}
                                <div className={"fl-write-post-unlock-anyone"}>
                                  {!free && (
                                      <>
                                        <label>
                                          <input
                                              type="checkbox"
                                              onChange={() =>
                                                  setState({...state, limited: !state.limited})
                                              }
                                          />{" "}
                                          <span>
                                Allow anyone to unlock this Post even if they're
                                not subscribed to your profile.{" "}
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                data-tip
                                                data-for={"fl-post-unlock"}
                                            />
                                <ReactTooltip id={"fl-post-unlock"}>
                                Users will still be charged to unlock your Post.
                                </ReactTooltip>
                              </span>
                                        </label>
                                      </>
                                  )}
                                </div>
                              </div>
                            </div>
                        )}
                      </div>
                      <div
                          className={`fl-write-post-controls fl-write-post-controls-${userData.currentTheme} ${progress.uploading && uploadState?.files?.length > 0 && mobileResponsive ? "fl-write-controls-end" : ""}`}
                          onContextMenu={(e) => rightClickBlur(e)}
                      >
                        {progress.uploading && uploadState?.files?.length > 0 && mobileResponsive  ?
                            ""
                            :
                            <div
                                className={`fl-write-post-add  ${progress.uploading ? "fl-button-disabled" : ""}`}
                            >
                              <input
                                  className="fl-write-post-add-item-input"
                                  type="file"
                                  accept="image/jpeg, image/png, video/*"
                                  multiple
                                  onChange={(e) => handleFile(e.currentTarget.files)}
                                  ref={attachRefMain}
                              />
                              <label className="fl-write-post-add-item" onClick={() => attachRefMain.current.click()}>
                                <ReactTooltip id="upload-files" disable={isMobile}>
                                  Attach files (you can also drag & drop)
                                </ReactTooltip>
                                <FontAwesomeIcon
                                    icon={faPhotoVideo}
                                    data-tip
                                    data-for="upload-files"
                                />
                              </label>
                              <label
                                  className="fl-write-post-add-item"
                                  onClick={() => progress.uploading ? "" : flDrive()}
                              >
                                <ReactTooltip id="drive" disable={isMobile}>
                                  Insert files from Drive
                                </ReactTooltip>
                                <FontAwesomeIcon icon={faHdd} data-tip data-for="drive"/>
                              </label>
                              {!userData.mobilePost ?
                                  <label
                                      className="fl-write-post-add-item"
                                      onClick={() => progress.uploading ? "" : setEmojiState({active: !emojiState.active})}
                                  >
                                    <ReactTooltip id="choose-emoji" disable={isMobile}>
                                      Choose emoji
                                    </ReactTooltip>
                                    <FontAwesomeIcon
                                        icon={faSmile}
                                        data-tip
                                        data-for="choose-emoji"
                                    />
                                  </label> : ""
                              }
                            </div>
                        }
                        <div className="fl-write-post-actions">
                          {uploadState?.files?.length > 0
                              ? (progress.uploading === true ||
                              cancel.cancel === true) && (
                              <div className="fl-write-post-cancel-actions">
                                <a onClick={cancelUpload}>
                                  {cancel.cancel === true ? (
                                      <>
                                        {uploadState.files.length > 1
                                            ? `${uploadState.files.length} uploads cancelled`
                                            : `${uploadState.files.length} upload cancelled`}
                                        <FontAwesomeIcon icon={faExclamationCircle}/>
                                      </>
                                  ) : (
                                      "CANCEL"
                                  )}
                                </a>
                              </div>
                          )
                              : ""}
                          <div className="fl-write-post-action-buttons-container">
                            <Button
                                className={`fl-write-post-actions-button ${
                                    showScheduleButton ? "schedule-btn-style" : ""
                                } ${progress.uploading === true ? "fl-button-disabled" : ""}
                                ${retry.retry && "fl-button-radius"}
                                `}
                                type="normal"
                                onClick={
                                  showScheduleButton
                                      ? () => openSchedulerModal()
                                      : () => processPost()
                                }
                                disabled={
                                  (state.type === "ppv" && uploadState.files.length === 0) ||
                                  (uploadState.files.length === 0 && text.replace(/\s/g, "").length === 0)
                                }
                            >
                              {retry.retry ? (
                                  <>
                                  <span className="fl-write-post-actions-retry">
                                    Retry
                                  </span>
                                    <FontAwesomeIcon
                                        className="fl-write-post-actions-retry-svg"
                                        icon={faRedoAlt}
                                    />
                                  </>
                              ) : showScheduleButton ? (
                                  <>
                                    <FontAwesomeIcon
                                        className="fl-write-post-actions-retry-svg"
                                        icon={faClock}
                                    />{" "}
                                    <span className="fl-write-post-actions-icon-right-text">
                                    Schedule Post
                                  </span>
                                  </>
                              ) : (
                                  <>
                                    {progress.uploading ? (
                                        <>
                                          Posting
                                           <span>
                                            <ReactLoading
                                                type={"spinningBubbles"}
                                                color={"#fff"}
                                                height={18}
                                                width={18}
                                                className="fl-spinningBubbles"
                                            />
                                           </span>
                                        </>
                                    ) : (
                                        "Post Now"
                                    )}
                                  </>
                              )}
                            </Button>
                            {!cancel.cancel && (
                                <Button
                                    className={`fl-write-post-actions-button-drop-arrow ${
                                        showScheduleButton ? "schedule-btn-style" : ""
                                    } ${
                                        progress.uploading === true ? "fl-button-disabled" : ""
                                    }`}
                                    type="normal"
                                    onClick={() => setShowScheduleButton((v) => !v)}
                                    disabled={
                                      (state.type === "ppv" &&
                                          uploadState.files.length === 0) ||
                                      (uploadState.files.length === 0 && text.replace(/\s/g, "").length === 0)
                                    }
                                >
                                  <FontAwesomeIcon
                                      className="fl-write-post-actions-retry-svg"
                                      icon={showScheduleButton ? faCaretUp : faCaretDown}
                                  />
                                </Button>
                            )}
                          </div>
                          {progress.uploading && uploadState?.files?.length > 0 && mobileResponsive ?
                              <span className={"fl-write-post-mobile-progress-bar"}>{progress.percent < 0 ? 0 : progress.percent}%</span> : ""
                          }
                        </div>
                        {emojiState.active ? (
                            <div
                                className="fl-post-comment-emojis-picker"
                                onClick={(e) => e.stopPropagation()}
                                ref={emojiRef}
                            >
                              <Picker
                                  onEmojiClick={(e, emoji) => {
                                    setText(`${text}${emoji.emoji}`);
                                    setTyping(true);
                                  }}
                              />
                            </div>
                        ) : (
                            ""
                        )}
                      </div>
                    </>
                ) : (
                    ""
                )}
                <DatePickerScheduled
                    forceErrors={forceErrors}
                    label="Schedule post"
                    clear={clear1}
                    setClear={setClear1}
                    value={scheduleDate}
                    onSave={handleScheduleDate}
                    hideInputField={true}
                    showModalInitialy={showScheduleModal}
                    isScheduleModal={true}
                    handleCloseOther={() => {
                      setShowScheduleModal(false);
                      localStorage.removeItem("controls")
                    }}
                    className="fl-settings-sub-account-field-input"
                    min={new Date()}
                    setShowScheduleButton={setShowScheduleButton}
                />
              </div>
            </SimpleCard> :
            <>
              {userData.mobilePost ?
                  <>
                    <div className={"fl-write-mobile-post"}>
                      Create post
                    </div>
                    <div
                        className={`fl-write-post-cancel fl-write-post-cancel-${userData.currentTheme} ${progress.uploading && !userData.mobilePost ? "fl-button-disabled" : ""}`}
                        onClick={() =>  userData.mobileCreatePost(false)}
                        style={{ top:"20px"}}
                    >
                      <FontAwesomeIcon icon={faWindowMinimize}/>
                    </div>
                  </>
                  : ""
              }
              <SimpleCard className={`${mobileResponsive ? "fl-encoding-quick-post-modal" : ""}`} onContextMenu={(e:any) => e.preventDefault()}>
                <div className={`fl-encoding-post ${mobileResponsive ? "fl-encoding-quick-post" : ""}`}>
                  {mobileResponsive && !userData.mobilePost ?
                      <div
                          className={`fl-write-post-cancel fl-write-post-cancel-${userData.currentTheme}`}
                          onClick={() => modalData.close()}
                      >
                        <FontAwesomeIcon icon={faTimes}/>
                      </div> : ""
                  }
                  <div className={"fl-encoding-post-thumb"}>
                    {userData.encodingStatus.type === "image" ?
                        <img src={userData.encodingStatus.thumb} alt={"fluffa"}/> :
                        <video>
                          <source src={userData.encodingStatus.thumb} type="video/mp4"/>
                        </video>
                    }
                  </div>
                  <span className={"fl-encoding-post-thumb-text"}>
                    Your upload is processing
                    <div className="fl-post-spinner">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </span>
                  <Line percent={returnUploadProgress()} strokeWidth={1} strokeColor="#3e98c7"/>
                  <span className={"fl-encoding-post-thumb-text"}>
                  {returnUploadProgress() < 1 ? 1 : returnUploadProgress().toFixed(0) }%
                </span>
                </div>
              </SimpleCard>
            </>
        }
      </>
  );
};

