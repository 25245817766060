import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useUserContext } from '../context/UserContext';
import "./Input.css";

export const Input:FC<any> = (props) => {

    // CONTEXT
    const userData = useUserContext();

    const [passwordState, setPasswordState] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const filteredProps = Object.keys(props)
        .filter(key => !['className','style','type'].includes(key))
        .reduce((obj:any, key:any) => {
            obj[key] = props[key];
            return obj;
        }, {});

    const validationCheck = () => {
        if(props.checker){
            const checker = props.checker();
            setInvalid(!checker);
        }
    }

    useEffect(() => {
        if(props.checkerDependencies){
            validationCheck();
        }
    },props.checkerDependencies || [])

    return (
        <div className={`fl-input-outter ${props.className}`} style={props.style}>
            <input 
                className={`fl-input fl-input-${userData.currentTheme} ${props.inputType === "password" ? "fl-input-password" : ""} ${invalid ? 'fl-input-invalid' : ''}`}
                type={props.forcePasswordType ? (props.forcePasswordType) : (props.inputType === "password" ? (passwordState ? "text" : "password") : (props.inputType))} 
                {...filteredProps} 
                onBlur={validationCheck}
                onFocus={() => setInvalid(false)}
            />
            {props.inputType === 'password' && props.forcePasswordType === undefined ? <div className={`fl-input-show-password fl-input-show-password-${userData.currentTheme}`} onClick={() => setPasswordState(!passwordState)}>
                {passwordState ? <FontAwesomeIcon icon={faEye}/> : <FontAwesomeIcon icon={faEyeSlash}/>}
            </div> : ''}
        </div>
    )
}
