import React, {FC, Fragment, useState} from "react";
import {useModalContext} from "../context/ModalContext";
import {useEnvContext} from "../context/EnvContext";
import Axios from "axios";
import {MInput} from "./MInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Button} from "./Button";
import ReactLoading from "react-loading";
import axios from "axios";

export const TwoFactorGoogle: FC<{
    title: string;
    qr: string;
    secret:string;
    setState2fa:any;
    state2fa:any;
}> = ({ title,qr,secret,setState2fa,state2fa}) => {

    const modalData = useModalContext();
    const envData = useEnvContext();

    const [proccessing, setProccessing] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const [copySecretCode, setCopySecretCode] = useState(false);

    const [showSecretCode, setShowSecretCode] = useState(false);

    const [code, setCode] = useState<{ code:any }>({
        code:""
    });
    const [validCodeError, setValidCodeError] = useState<{ error:boolean }>({
        error: false
    });

    const codeConfirmation = async () => {
            setProccessing(true);

            if(state2fa === true) {
                axios.get(`${envData.apiUrl}/auth/2fa/disable`)
                setState2fa({...state2fa, type: "regular", enable: false});
            }
            setTimeout(async () => {
                try {
                    await Axios.post(`${envData.apiUrl}/auth/2fa/google/verify`, {
                        "code": code.code
                    });
                    modalData.closeAll()
                    setState2fa({...state2fa, type: "2fa_google",enable:true})
                    setProccessing(false);
                } catch (e) {
                    setValidCodeError({...validCodeError, error: true})
                    setProccessing(false);
                }
            }, 700);
    }

    const showConfirmationCodeModal = () => {
        setShowConfirmation(true)
    }

    const closeCodeModal = () => {
        if(showConfirmation) {
            setShowConfirmation(false)
        } else if (showSecretCode) {
            setShowSecretCode(false)
        }
    }

    const disableButton = () => {
        if (code.code.length > 0 && code.code.length < 7 && !proccessing) {
            return false
        } else {
            return true
        }
    }

    const copySecret = async (secret:string) => {
        try {
            await navigator.clipboard.writeText(`${secret}`);
            setCopySecretCode(true);
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const tryScan = () => {
        setShowSecretCode(false)
        setShowConfirmation(false)

    }

    return (
        <Fragment>
            <div className="fl-modal-title">{!showConfirmation ? title : "Try the authentication code now"}</div>
            <div className="fl-modal-description fl-google-authenticate-modal">
                {showConfirmation === false && showSecretCode === false  && (
                    <>
                        <p className="fl-authentication-tittle">Link the app to your Fluffa account</p>
                        <p>Open the authentication app on your mobile device to link it to your Fluffa account. When the app prompts you, scan the following QR code with your camera.</p>
                        <div className="fl-google-authenticate-modal-image">
                            <img className="fl-qr-code" src={qr} alt={"2factor"}/> <br />
                            <span className="fl-security-secret-code " onClick={()=>  setShowSecretCode(true)}>Can't scan it?</span>
                        </div>
                    </>
                )}
                {showConfirmation === true && (
                    <>
                        <div className="fl-modal-description-row">
                            <p>From the authentication app, get a code and enter it below to complete the verification process. If you don’t have an authentication app on your device, you’ll need to install one now.</p>
                            <form autoComplete="off">
                                <MInput
                                    type="text"
                                    className={"fl-settings-mt-30"}
                                    label="Enter authentication code"
                                    value={code.code}
                                    setValue={(code) => setCode({code:/[0-9]/.test(code[code.length - 1])
                                            ? code
                                            : code.slice(0, -1)})}
                                />
                            </form>
                            {validCodeError.error  && (
                                <span className="fl-modal-description-row-error">
                                 <FontAwesomeIcon icon={faInfoCircle} />{" "} The code you entered is incorrect, please try again.
                                </span>
                            )}
                        </div>
                    </>
                )}
                {showSecretCode && showConfirmation === false && (
                    <>
                        <div className="fl-modal-description-row">
                            <p className="fl-authentication-tittle">Can't scan QR code?</p>
                            <p>If you can’t scan the QR code with your camera, enter the following code into the authentication app to link it to your Fluffa account.</p>
                            <p className="fl-authentication-tittle fl-secret-code-copy" onClick={()=>copySecret(secret)}>{secret.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()}</p>
                            {copySecretCode && (
                                <span className="fl-secret-code-copy-message">Secret code copied to clipboard</span>
                            )} <br /> <br />
                            <span className="fl-security-secret-code " onClick={tryScan}>Try scanning the QR code again</span>
                        </div>
                    </>
                )}
            </div>
            <div className="fl-d-flex fl-justify-flex-end fl-modal-footer-buttons fl-security-footer" >
                <Button onClick={showConfirmation || showSecretCode ? closeCodeModal : modalData.close}>Back</Button>
                <Button
                    type="normal"
                    onClick={showConfirmation ? codeConfirmation : showConfirmationCodeModal }
                    disabled={showConfirmation ? disableButton() : ""}
                >
                    {showConfirmation ? "Confirm" : "Next"}
                    {!proccessing || <ReactLoading className='fl-loader-animation' type={"spinningBubbles"} color={"#ffffff"} height={20} width={20}/>}
                </Button>
            </div>
        </Fragment>
    );
};

export default TwoFactorGoogle;
