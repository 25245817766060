import { FC, Fragment, useState } from "react";
import { Button } from "./Button";
import React from "react";
import { useModalContext } from "../context/ModalContext";
import ReactLoading from "react-loading";
import './BalanceModal.css';
import axios from "axios";
import {SegpayModal} from "../routes/Segpay/SegpayModal";
import {useEnvContext} from "../context/EnvContext";

export const BalanceModal: FC<any> = ({data,description, header, buttonText, action,funds,amount,...props}) => {
    const modalData = useModalContext();
    const envData = useEnvContext();

    const [loading, setLoading] = useState(false);

    const checkSecure = async () => {
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append("amount", amount);
            if(props.wallet) {
                formData.append("force", "1");
            }
            const checkSecure = await axios.post(`${envData.apiUrl}/payments/security/check`, formData);
            if(checkSecure.data.protected) {
                modalData.close()
                modalData.push(() => <SegpayModal url={checkSecure.data.url} addCardFunction={action} amount={Number(amount)} header={header} loadingHeader={header === "Add funds" ? "ADDING FUNDS..." : header === "Unlock message for free" ? "UNLOCKING MESSAGE..." : "PROCESSING PAYMENT..."}/>)
            } else {
                action(amount)
            }
        } catch (e) {
            const description = e?.response?.data?.errors.user ? e?.response?.data?.errors.user : "Something went wrong!";
            await modalData.push({
                title: "ERROR",
                description: [`${description}`],
                buttons: [
                    () => (
                        <div className="fl-d-flex fl-justify-flex-end">
                            <Button type="normal" onClick={() => modalData.closeAll()}>
                                Dismiss
                            </Button>
                        </div>
                    ),
                ],
            });
            setLoading(false)
        }
    };

    return (
      <Fragment>
        <div className="fl-modal-title">{header}</div>
        <div className="fl-modal-description">
            {!loading ?
                <>{description}</> :
                <>
                    <div className="loader-payment"></div>
                    <p className={"loader-text"}>{header === "Add funds" ? "ADDING FUNDS..." : header === "Unlock message for free" ? "UNLOCKING MESSAGE..." :  "PROCESSING PAYMENT..."}</p>
                </>
            }
        </div>
          {!loading ?
              <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                  <Button onClick={modalData.close}>Cancel</Button>
                  <Button type="normal" onClick={async () => checkSecure()} disabled={loading}>
                      {loading ? <>{funds ? "Processing" : data > 0 ? "Processing" : "Unlocking"} {" "}<ReactLoading
                          type={"spinningBubbles"} color={"#fff"} height={20} width={20}
                          className="fl-spinningBubbles"/></> : buttonText}
                  </Button>
              </div> : ""
          }
      </Fragment>
    );
  };