import React, {FC, useEffect, useMemo, useState} from "react";
import "./Bulk.css";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../../../context/UserContext";
import { useEnvContext } from "../../../context/EnvContext";
import { useModalContext } from "../../../context/ModalContext";
import axios from "axios";

import UserSelection from "./Screens/UserSelection";
import Message from "./Screens/Message";
import Axios from "axios";
import {useParams} from "react-router";

const BulkMessage: FC<any> = () => {
  const { id } = useParams<{ id: string;}>();
  const [screen, setScreen] = useState("users");
  const [users, setUsers] = useState({
    rebill_on: [] as any,
    rebill_off: [] as any,
    expired: [] as any,
    selectedUsers: [] as any,
    customOptionsSelected: [] as any,
  });
  const [message, setMessage] = useState({
    text: "",
    price: "5",
    files: [],
    blurred:"blurred"
  });
  const [progress, setProgress] = useState({
    uploading: false,
    percent: 0,
  });

  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();
  const history = useHistory();

  const [cancel, setCancel] = useState<{
    cancel: boolean;
  }>({
    cancel: false,
  });

  const [retry, setRetry] = useState<{
    retry: boolean;
  }>({
    retry: false,
  });

  const [stateBlurPercent, setStateBlurPercent] = useState<any>({
    blurredPercent: 50,
  });

  useEffect(() => {
    const getAllRecipients = async () => {
      const res = await axios.get(
        `${envData.apiUrl}/profile/get-subscriptions`
      );
      setUsers({
        ...users,
        rebill_on: res.data.rebill_on,
        rebill_off: res.data.rebill_off,
        expired: res.data.expired,
        selectedUsers: id ? [res.data.rebill_on.find((user:any) => user.user_id === Number(id))] : []
      });
    };
    getAllRecipients();
  }, []);

  const [refresh, setRefresh] = useState<boolean>(false);
  const CancelToken = Axios.CancelToken;
  const source = useMemo(() => CancelToken.source(), [refresh]);
  const [restrictedWords, setRestrictedWords] = useState<any>([])

  const sendMessage = () => {
     setCancel({ ...cancel, cancel: false });
     setRetry({ ...retry, retry: false });


     const targets = users.selectedUsers.map((e: any) => e.user_id);

     const { text, price } = message;

     const formData = new FormData();

     formData.append("body", text);
     formData.append("price", price);
     if(price === "0") {
       formData.append("blurred", "black");
     }else {
       if (message.blurred === "blurred") {
         formData.append("blurred", stateBlurPercent.blurredPercent)
       } else {
         formData.append("blurred", "black")
       }
     }
     formData.append("blurred", "black");

     for (let i = 0; i < targets.length; i++) {
       formData.append("user_ids[" + i + "]", targets[i]);
     }

     if(message?.files?.length > 0) {
       message.files.forEach((x: any) => {

         if(x?.postImages !== undefined && x?.postImages?.length > 0) {
             x.postImages.forEach((file:any) => {
               formData.append("drive_attachments[]", file.id);
             })
         }
           if(x.file) {
             formData.append("attachments[]", x.file);
           }
           if(x.id && x?.postImages === undefined ) {
             formData.append("drive_attachments[]", x.id);
           }
       });
     }

     if(userData?.selectedDriveFiles.length > 0) {
       userData.driveFiles("")
     }

     axios
       .post(`${envData.apiUrl}/conversations/bulk-deliver`, formData, {
         cancelToken: source.token,
         headers: { "content-type": "multipart/form-data" },
         onUploadProgress: (progressEvent) => {
           setProgress({
             uploading: true,
             percent: Math.round(
               (progressEvent.loaded / progressEvent.total) * 100 - 1
             ),
           });
         },
       })
       .then((resp) => {
         if(restrictedWords.length > 0) {
           setRestrictedWords([])
         }
         setProgress({ uploading: false, percent: 0 });
         history.push("/inbox");
         modalData.pushToast(
           "success",
           `Your messsage was sent to ${
             users.selectedUsers.length
           } ${`recipient${users.selectedUsers.length !== 1 ? "s" : ""}`}`
         );
       })
       .catch((err) => {
         setRefresh(!refresh);
         setProgress({ uploading: false, percent: 0 });
         if (err?.response?.data?.errors?.restricted_words?.length > 0) {
           setRestrictedWords(err?.response?.data?.errors?.restricted_words)
         }
       });
  };

  return (
    <div className={`fl-bulk__main fl-bulk__main--${userData.currentTheme}`}>
      <div className="fl-container">
        <div className="fl-d-flex fl-feed-row">
          <div className={`fl-col-12 fl-bulk__col fl-feed-column fl-messages-tablet ${restrictedWords.length > 0 ? "fl-restricted-input" : ""}`}>
            {screen === "users" && id === undefined ? (
              <UserSelection
                changeScreen={setScreen}
                users={users}
                setUsers={setUsers}
              />
            ) : (
              <Message
                changeScreen={setScreen}
                users={users}
                setUsers={setUsers}
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
                progress={progress}
                setProgress={setProgress}
                cancel={cancel}
                setCancel={setCancel}
                source={source}
                retry={retry}
                setRetry={setRetry}
                stateBlurPercent={stateBlurPercent}
                setStateBlurPercent={setStateBlurPercent}
                setRestrictedWords={setRestrictedWords}
                restrictedWords={restrictedWords}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkMessage;
