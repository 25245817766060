import {faChevronCircleLeft, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import {Link, useHistory} from 'react-router-dom';
import { Button } from '../components/Button';
import { MInput } from '../components/MInput';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import { useUserContext } from '../context/UserContext';
import './SettingsSecurity.css';
import validator from "validator";
import Watermark from "../components/Watermark"
import TwoFactorOption from "../components/TwoFactorOption"

import verifiedSrc from "../assets/images/icons/verified.svg";
import { EmailConfirmation } from './SettingsAccount';
import ReactLoading from "react-loading";

export const SettingsSecurity:FC<any> = (props) => {

    const modalData = useModalContext();
    const userData = useUserContext();
    const envData = useEnvContext();
    const history = useHistory();

    const [state2fa, setState2fa] = useState({
        type: userData.user.auth_type,
        enable: userData.user.auth_type === "regular" ? false : true ||false
    });

    const [state, setState] = useState({
        old_password: "",
        password: "",
        confirm_password: "",
    })

    const [stateWatermark, setStateWatermark] = useState({
        old_password: "",
        password: "",
        confirm_password: "",
        watermark_color: '#fff',
        watermark_border_color: '#fff',
        watermark_position: '',
    })

    const [stateErrorMatch, setStateErrorMatch] = useState({
        error: false
    })
    const [stateErrorPass, setStateErrorPass] = useState({
        error: false,
    })
    const [forceErrors, setForceErrors] = useState(false);

    const stateRef = useRef(state);
    const [animationChange,setAnimationChange] = useState(false)
    const changePassword = async () => {
        setAnimationChange(true)
        if(state.password !== state.confirm_password) {
            setStateErrorMatch({...stateErrorMatch, error: true})
        }else if(
            !validator.isStrongPassword(state.password, {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 0,
            }) || isNewPasswordSame(state.password)
        ) {
            setForceErrors(true);
        }
        else{
            setForceErrors(false);
            setStateErrorMatch({...stateErrorMatch, error: false})
            try {
                await Axios.post(`${envData.apiUrl}/auth/password/change`, state );
                if(!userData.user.has_password) {
                    window.location.reload();
                }
                modalData.pushToast("success", "Password changed");
                setState({password:"",confirm_password:"",old_password:""});
                setStateErrorPass({...stateErrorPass, error: false})
            } catch(error) {
                setStateErrorPass({...stateErrorPass, error: true})
            }
        }
        setAnimationChange(false)
    }

    useEffect(() => {
        getSettings();
    },[])

    const getSettings = async () => {
        const res = await Axios.get(`${envData.apiUrl}/settings`);
        const {watermark_color, watermark_border_color , watermark_position} = res.data.data.settings;

        setStateWatermark({...stateWatermark, watermark_color,watermark_border_color, watermark_position});
    }

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const disableChangePasswordButton = () => {
        return isNewPasswordSame(state.password) || !(state.password.length > 0 && state.confirm_password.length > 0 && state.password.length === state.confirm_password.length && isPasswordStrong(state.password)) || animationChange;
    }

    const isPasswordStrong = (v: string) => {
        return validator.isStrongPassword(v, {
            minLength: 8,
            minLowercase: 1,
            minNumbers: 1,
            minUppercase: 1,
            minSymbols: 0,
        })
    };

    const isNewPasswordSame = (newPass: string) => {
        return newPass === state.old_password;
    }

    const openAuthentificationModal  = async () => {
        await modalData.push(() => (
            <TwoFactorOption
                setState2fa={setState2fa}
                state2fa={state2fa}
                openAuthentificationModal={openAuthentificationModal}
            />
        ));
    }

    const checkPassword = () => {
        if(userData.user.email){
            modalData.push(() => (
                <EmailPasswordConfirmation
                    title="Verify your password"
                    openAuthentificationModal={openAuthentificationModal}
                />
            ));
        } else {
            openAuthentificationModal();
        }
    }

    const addEmail = () => {
        modalData.push(() => <EmailConfirmation title='Add email address' contentText='Please add an email address' placeholderText='Enter email address' redirectPage='/settings/account'/>);
    }

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    const [animation,setAnimation] = useState(true)

    return (
        <Fragment>
            {animation &&
                <div className="fl-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme} `}>
                          <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                          </span>
                        Change password
                    </div>
                    {userData.user.email ? <>{userData.user.has_password &&
                    (<>
                        <MInput
                            label="Current password"
                            type="password"
                            value={state.old_password}
                            setValue={old_password => setState({...state, old_password})}
                            visiblepassword={true}
                            className={"fl-settings-mt-30"}
                            onFocus={() => stateErrorPass.error ?  setStateErrorPass({...stateErrorPass, error: false}) : ""}
                        />
                        {stateErrorPass.error && (
                            <span className="fl-modal-description-row-error">
                            <FontAwesomeIcon icon={faInfoCircle} /> Wrong password.
                            </span>
                        )}
                    </>)}
                    <MInput
                        forceErrors={forceErrors}
                        validator={[
                            {
                                check: (v) =>
                                    isPasswordStrong(v),
                                message:
                                    "Must be at least 8 characters long, with one uppercase letter, one lowercase letter and a number.",
                            },
                            {
                                check: (v) => !isNewPasswordSame(v),
                                message:
                                    "New password cannot be the same as your current password"
                            }
                        ]}
                        className={"fl-settings-mt-30"}
                        label="New password"
                        type="password"
                        value={state.password}
                        setValue={password => setState({...state, password})}
                        visiblepassword={true}
                    />
                    <MInput
                        label="Confirm new password"
                        type="password"
                        value={state.confirm_password}
                        setValue={confirm_password => setState({...state, confirm_password})}
                        visiblepassword={true}
                        className={"fl-settings-mt-30"}
                    />
                    {stateErrorMatch.error === true && (
                        <span className="fl-modal-description-row-error">
                          <FontAwesomeIcon icon={faInfoCircle} /> Passwords don’t match.
                        </span>
                    )}
                    <Button disabled={disableChangePasswordButton()} type="normal" className="fl-settings-sub-security-button" onClick={changePassword}>
                        Change password
                        {animationChange ? <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/> : ""}
                    </Button>
                    <Link
                        to="/forgot-password"
                        className="fl-settings-account-modal-recoverpass"
                        onClick={() => modalData.close()}
                    >
                        Forgot password?
                    </Link>
                    </>
                    : <p className='fl-settings-sub-account-update-span'>You need to add an <a href='#' className='fl-a-reverse-underline' onClick={addEmail}>email address</a> before you can set a password.</p>}
                </div>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme} fl-security-status`}>
                        Two-factor authentication {" "}
                            <span>{!state2fa.enable ?
                                "(Off)"
                            :
                            <>
                                (On) <img src={verifiedSrc} alt="secure" />
                            </>
                            }
                    </span>
                    </div>
                    <p
                        className="fl-security-edit-authentication"
                        onClick={checkPassword}
                    >
                        Edit Two-Factor Authentication Settings
                    </p>
                </div>
                <br />
                <div className="fl-settings-sub-account-field">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>Watermark</div>
                    <p className={`fl-settings-p-${userData.currentTheme}`}>For your security and to assist with any DMCA takedowns, your content will automatically be watermarked during upload.</p>
                </div> <br />
                <Watermark
                    title="Update watermark"
                    setAnimation={setAnimation}
                />
            </div>
        </Fragment>
    )
}

const EmailPasswordConfirmation: FC<{
    title: string;
    openAuthentificationModal:any;
}> = ({ title,openAuthentificationModal }) => {

    const [statePassword, setStatePassword] = useState<{ password: string; error: any }>({
        password: "",
        error: null,
    });

    const modalData = useModalContext();
    const envData = useEnvContext();
    
    const checkPassword = async () => {
        if (statePassword.password.length !== 0) {
            try {
                await Axios.post(`${envData.apiUrl}/auth/password/check`, {
                    password: statePassword.password
                });
                modalData.close()
                setTimeout(() => {
                    openAuthentificationModal()
                }, 500);
            } catch (error) {
                setStatePassword({
                    ...statePassword,
                    error: "Wrong password. Try again.",
                });
            }
        } else {
            setStatePassword({ ...statePassword, error: "Enter a password" });
        }
    };

    return (
        <Fragment>
            <div className="fl-modal-title">{title}</div>
            <div className="fl-modal-description">
                <div className="fl-modal-description-row">
                    Re-enter your Fluffa password to continue
                </div>
                <div className="fl-modal-description-row">
                    <form autoComplete="off">
                        <MInput
                            visiblepassword={true}
                            type="password"
                            label="Password"
                            value={statePassword.password}
                            setValue={(password: string) =>
                                setStatePassword({ ...statePassword, password: password })
                            }
                        />
                    </form>
                    {statePassword.error && (
                        <span className="fl-modal-description-row-error">
                          <FontAwesomeIcon icon={faInfoCircle} /> {statePassword.error}
                        </span>
                    )}
                </div>
                <Link
                    to="/forgot-password"
                    className="fl-settings-account-modal-recoverpass"
                    onClick={() => modalData.close()}
                >
                    Forgot password?
                </Link>
            </div>
            <div className="fl-d-flex fl-justify-flex-end fl-modal-footer-buttons">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button
                    type="normal"
                    onClick={checkPassword}
                    disabled={statePassword.password.length === 0 ? true : false}
                >
                    Next
                </Button>
            </div>
        </Fragment>
    );
};

