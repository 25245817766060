import React, { FC } from 'react';
import "./Videobanner.css";

import logoSrc from '../assets/images/fluffa-logo-text.png';

export const Videobanner:FC<{src: string, title: string, description: string, button: React.ReactNode}> = (props) => {

    // PROPS
    const { src, title, description, button } = props;

    return (
        <div className="fl-videobanner-main">
            <video className="fl-videobanner-video" muted autoPlay playsInline loop src={src}></video>
            <img className="fl-videobanner-video-watermark" src={logoSrc} alt="fluffa"/>
            <div className="fl-videobanner-inner">
                <div className="fl-container fl-videobanner-contenct">
                    <div className="fl-videobanner-title">{title}</div>
                    <div className="fl-videobanner-description">{description}</div>
                    {button}
                </div>
            </div>
        </div>
    )
}
