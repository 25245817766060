import React, {FC, useEffect, useState,useCallback } from 'react'
import {useUserContext} from "../../context/UserContext";
import {ProfileHolder} from "../../components/ProfileHolder";
import {Link} from "react-router-dom";
import moment from "moment";
import {MessageText} from "./MessageText";
import {MessageFiles} from "./MessageFiles";
import {faCheckDouble, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MessageAudioPreview} from "./MessageAudioPreview";
import ReactLoading from "react-loading";
/*import { useLongPress,LongPressDetectEvents } from 'use-long-press';*/

export const SingleMessage:FC<any> = (props) => {

    const userData = useUserContext()
    const [reportedStatus, setReportedStatus] = useState(props.data.reports || 0)
    const [like, setLike] = useState({
        status: props.data.liked || false,
        animation: false
    })

    const convertDate = () => {
        if(moment(props.data.created_at).format("DD-MM-YYYY") < moment(new Date()).format("DD-MM-YYYY")) {
            return moment(props.data.created_at).format("D MMM YYYY, HH:mm")
        } else {
            return  moment(props.data.created_at).format("HH:mm")
        }
    }

    const checkWhoSend = () => {
        if (userData.user.handle === props.data?.sender?.handle) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if((userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageLiked" || userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageUnliked") && props.data.id ===  userData?.signal?.message.id && userData?.user?.id === userData?.signal?.message.sender.id){
            setLike({...like, status: !like.status})
            userData.clearSignal();
        }
    }, [userData?.signal]);

    const checkType = () => {
        if(props?.data?.attachments?.length > 0 && props?.data?.body?.length > 0) {
            return "all"
        } else if (props?.data?.attachments?.length > 0) {
            return "file"
        } else {
            return "text"
        }
    }

/*    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const callback = React.useCallback(() => {
        alert("Hold message menu!");
    }, []);
    const bind = useLongPress(isMobile ? callback : null, {
        threshold: 500,
        captureEvent: true,
        cancelOnMovement: false,
        detect: LongPressDetectEvents.BOTH
    });*/

    return (
        <div
            className={`fl-message-bubble-main fl-message-bubble-main-${props.direction} fl-message-bubble-main-${props.data.id} `} ref = {el => props.singleMessageRef.current[props.data.id] = el}
        >
            <div
                className={`fl-message-bubble-message ${checkType() !== "all" ? "fl-d-flex" : ""} ${reportedStatus > 0 && props.data?.attachments?.length === 0  ? "fl-inbox-reported" : ""} ${props.data?.attachments?.length > 0 && props.data?.body?.length > 0 ? "fl-message-body-files" : ""} fl-message-bubble-message-${userData.currentTheme}`}
            >
                {props?.data?.attachments?.length > 0 && props?.data?.attachments?.filter((x:any) => x.type === "audio").length === 0 ?
                    <MessageFiles deleteCancelledUpload={props.deleteCancelledUpload} cancel={props.cancel} submitMessage={props.submitMessage}  setReportedStatus={setReportedStatus} reportedStatus={reportedStatus} checkType={checkType} setLike={setLike} like={like} cancelUpload={props.cancelUpload} data={props.data} files={props.data.attachments} direction={props.direction} checkWhoSend={checkWhoSend} progress={props.progress} state={props.state} setState={props.setState}/>
                    : props?.data?.attachments?.length > 0 && props?.data?.attachments?.filter((x:any) => x.type === "audio").length > 0  ? <MessageAudioPreview checkWhoSend={checkWhoSend} checkType={checkType} setLike={setLike} like={like} reportedStatus={reportedStatus} data={props.data} setState={props.setState} state={props.state} direction={props.direction} />
                        : ""
                }
                {props?.data?.body?.length > 0 &&
                <MessageText uploading={props.progress.uploading} lastMessage={props.lastMessage} setLastMessage={props.setLastMessage} direction={props.direction} checkType={checkType} setLike={setLike} like={like} searchTextDefault={props.searchTextDefault} data={props.data} checkWhoSend={checkWhoSend} setState={props.setState} state={props.state} reportedStatus={reportedStatus} setReportedStatus={setReportedStatus} />
                }
            </div>
            <div className="fl-message-bubble-message-info">
                <div className={`fl-message-bubble-message-info-timestamp ${props.data.restricted && "fl-single-message-restricted"}`}>
                    {props.progress.uploading && props.data.fake ? "Sending..." : props.cancel ? "" : props.data.restricted ?
                        <>
                            Unable to send  <FontAwesomeIcon icon={faExclamationCircle}/>
                        </>
                        :
                        <>
                        <span className="fl-message-bubble-message-info-timestamp-time">
                          {convertDate()}
                        </span>
                            {props.data.fake && props.progress.uploading ? "" :
                                <>
                                    {props.data?.attachments?.length > 0 && props?.data?.attachments?.filter((x:any) => x.type === "audio").length === 0 ?
                                        (
                                            props.data.unlocked ? (
                                                <span className={`unlocked-${props.data.unlocked}`}>
                                                   {props.data.price > 0 ? `, $${props.data.price} - ${checkWhoSend() ? "payment received" : "paid"}` : `- ${checkWhoSend() ? "sent for free" : "unlocked for free"}`}
                                                </span>
                                            ) : (
                                                <span>
                                                  {props.data.price > 0 ?  `, $${props.data.price} - ${checkWhoSend() && props.data?.attachments?.length > 0 ? "awaiting payment " : "not paid yet "}` : ` - ${checkWhoSend() ? "sent for free" : "unlock for free"}`}
                                              </span>
                                            )
                                        ) : ""
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                {props.data.restricted ? "" : checkWhoSend() && !props.cancel ?
                    <div className="fl-message-bubble-message-info-status">
                        {checkWhoSend() && props.data.fake && props.progress.uploading ?
                            <ReactLoading type={"spinningBubbles"} color={"#888"} height={10} width={10} /> :
                            <FontAwesomeIcon
                                className={`fl-message-bubble-message-info-status-${props.data.seen ? "pink" : "gray"}`}
                                icon={faCheckDouble}
                            />
                        }
                    </div> : ""}

            </div>
            <Link
                to={`/${props.data?.sender?.handle}`}
                className={`fl-message-bubble-avatar fl-message-bubble-avatar-${props.direction}`}
            >
                <ProfileHolder src={props.data?.sender?.profileImage?.url} userProfile={props.data?.sender} array />
            </Link>
        </div>
    )
}