import React, {FC, useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import "./MobileTopNav.css";
import logoSource from '../assets/images/fluffa-logo-text.png';
import {Line} from "rc-progress";


export const MobileTopNav:FC<any> = () => {
    const userData = useUserContext();
    const userLocation = useLocation();
    const [state, setState] = useState({
        scrollTop: 0,
        mobileNav: false
    })
    useEffect(() => {
        const updateScrollTop = () => {
            setState({...state, scrollTop: window.scrollY});
        }
        updateScrollTop();
        window.addEventListener("scroll", updateScrollTop);
        return () => {
            window.removeEventListener("scroll", updateScrollTop);
        }
    },[]);

    const returnUploadProgress = () => {
        return userData.encodingStatus.percent
    }

    const location = () => {
        const name = userLocation.pathname.split("/")[1]
        const route = ["notifications", "favorites", "settings", "support","purchased", "drive", "subscriptions", "earnings", "contact", "privacy", "terms", "2257","cookies","verify","update", "explore"]
        if(route.includes(name)) {
            return name.charAt(0).toUpperCase() + name.slice(1)
        } else if (name === "feed") {
            return "Home"
        } else if(name === "inbox" || name === "message-create") {
            return "Messages"
        } else if (name === "follow") {
            return "Subscriptions"
        } else if (userLocation.pathname === "/") {
            return "Home"
        } else if (name === "post") {
            return "Post"
        } else {
            return ""
        }
    }

    const checkVisibleUploading = () => {
        if ((location() === "Home" || location() === "Profile") && userData.encodingStatus.status) {
            return true
        } else {
            return false
        }
    }

    const uploadingPost = () => {
        return (
            <div className={`fl-encoding-post`}>
                <span className={"fl-encoding-post-thumb-text"}>
                  Processing
                    <div className="fl-post-spinner">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                 </span>
                <Line percent={returnUploadProgress()} strokeWidth={1} strokeColor="#3e98c7"/>
                <span className={"fl-encoding-post-thumb-text"}>
                   {returnUploadProgress() < 1 ? 1 : returnUploadProgress().toFixed(0) }%
                 </span>
            </div>
        )
    }

    return (
        <>
            <div className={`fl-mobile-top-nav-main fl-mobile-top-nav-main-${userData.currentTheme} ${state.scrollTop > 100 ? `fl-mobile-nav-sticky` : "" }`}>
                <div className={`fl-container fl-mobile-top-navbar-inner`}>
                    <div className={`fl-col-${!checkVisibleUploading() ? "1" : "4"} fl-mobile-navbar-logo-force-min`}>
                        <Link className="fl-mobile-navbar-logo" to={userData.authStatus === 'AUTHENTICATED' ? '/feed' : '/'}>
                            {!checkVisibleUploading () ?
                                <img className="fl-mobile-navbar-logo-img" src={logoSource} alt="Fluffa"/> : (userData.encodingStatus.type === "image" ?
                                    <img className="fl-mobile-navbar-logo-img fl-mobile-upload-navigation" src={userData.encodingStatus.thumb} alt={"fluffa"}/> :
                                    <video>
                                        <source className="fl-mobile-navbar-logo-img fl-mobile-upload-navigation" src={userData.encodingStatus.thumb} type="video/mp4"/>
                                    </video>
                                )
                            }
                        </Link>
                        {!checkVisibleUploading() ?
                            <div className={`fl-mobile-navbar-locations`}>
                                <span>{location()}</span>
                            </div> : ""
                        }
                    </div>
                    {checkVisibleUploading() ?
                        <div className="fl-col-11">
                            {uploadingPost()}
                        </div> : ""
                    }
                </div>
            </div>
        </>
    )
}

