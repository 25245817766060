import React, {FC} from "react";
import "./DriveTable.css";
import moment from "moment";
import {useUserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useModalContext} from "../../context/ModalContext";

export const DriveTable: FC<{
    attachments:any
    humanFileSize:any
    convertDuration:any
    checkSelectStatus:any
    selectedFiles:any
    removeSelected:any
    addSelected:any
    flModalImage:any
    keyboardEventDrive:any
    type:any
    enableDelete:any
    updateFile:any
    selectLabelSort:any
    deleteLabel:any
}> = (props) => {

    const userData = useUserContext()
    const modalData = useModalContext()
    return (
        <div className="fl-drive-sub-section">
            <div
                className={`fl-drive-sub-payment-transactions fl-drive-sub-${userData.currentTheme}`}
            >
                <div className="fl-drive-sub-single fl-drive-sub-single-head">
                    <div className="fl-drive-sub-single-img">

                    </div>
                    <div className="fl-drive-sub-single-date">
                        Date Uploaded
                    </div>
                    <div className="fl-drive-sub-single-labels">
                        Tags
                    </div>
                    <div className="fl-drive-sub-single-size">
                        Size
                    </div>
                    <div className="fl-drive-sub-single-duration">
                        Duration
                    </div>
                </div>
                {props.attachments.files.map((x:any,index:any) =>
                    <div
                        className={`fl-drive-sub-single fl-drive-sub-single-${userData.currentTheme}`}
                        onClick={() => {props.checkSelectStatus() && (props?.selectedFiles?.files?.includes(x)  ? props?.removeSelected(x.id) :  props?.addSelected(x)) }}
                    >
                        <div className="fl-drive-sub-single-img">
                            <div
                                onClick={() => props.flModalImage(index)}
                                onKeyDown={props.keyboardEventDrive}
                                tabIndex={0}
                            >
                                {x.type === "image" &&
                                    <img src={x.url} alt={`fluffa`} />
                                }
                                {x.type === "video" &&
                                // <video  muted autoPlay loop src={x.url} />
                                    <img src={x.thumb_url} alt={`fluffa`} />
                                }
                            </div>
                        </div>
                        <div className="fl-drive-sub-single-date">
                            <div className="fl-drive-sub-single-date-short">
                                {moment(x.created_at).format("D MMM YYYY")}
                            </div>
                        </div>
                        <div className="fl-drive-sub-single-labels">
                            {x.labels.length > 0 ?
                                x.labels.map((label:any,index:any) =>
                                    <span
                                        className={`fl-drive-image-label-name` }
                                        onClick={(e) => !props.enableDelete && props.selectLabelSort(e,label.label.id )}
                                    >
                                             {label.label.name}
                                        <div className={"fa-label-delete"} onClick={(e) => props.deleteLabel(e,label?.label,x?.id)}>  <FontAwesomeIcon icon={faTimes} /> </div>
                                         </span>
                                )
                                : <span className={"fl-drive-image-label-add-tag"} onClick={(e) => props.updateFile(e,x.id,x.labels,x.url,index)}>Add tags</span>
                            }
                        </div>
                        <div className="fl-drive-sub-single-size">
                            {props.humanFileSize(x.filesize)}
                        </div>
                        <div className="fl-drive-sub-single-duration">
                            {props.convertDuration(x)}
                        </div>
                        {props.enableDelete || props.type === "message" ?
                            <div className={`fl-drive-image-svg fl-drive-delete-${props?.selectedFiles?.files?.includes(x) ? "enable" : "disable"}`}>
                                {props?.selectedFiles?.files?.includes(x) &&
                                <FontAwesomeIcon icon={faCheck}/>
                                }
                            </div>
                            : ""
                        }
                    </div>
                )}
            </div>
        </div>
    );
}
