import axios from "axios";
import React, {
    FC,
    Fragment,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import "./SettingsPayment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useModalContext } from "../context/ModalContext";
import moment from "moment";
import {Link, useHistory} from "react-router-dom";

export const SettingsTransactions: FC<any> = (props) => {
    const [state, setState] = useState({
        currentPage: 1,
        last_page: 0,
        transactions: [],
    });

    const stateRef = useRef<any>(state);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();
    const history = useHistory()

    const [animation, setAnimation] = useState(true);

    const getTransactions = async (page: number) => {
        setAnimation(true)
        try {
            const res = await axios.get(
                `${envData.apiUrl}/transactions?page=${page}&order=created_at`
            );
            setState({
                ...stateRef.current,
                transactions: [...stateRef.current.transactions, ...res.data.data],
                last_page: res.data.meta.last_page,
            });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimation(false)
    };

    useLayoutEffect(() => {
        (async () => {
            await getTransactions(stateRef.current.currentPage);
        })();
    }, [state.currentPage]);

    const redirectProfile = (e: any) => {
        history.push(`/settings`);
    };

    const checkTypeAction = (transaction: any) => {
        if (transaction.product?.subscription) {
            return (
                <>
                    Subscription to{" "}
                    <Link
                        className="fl-transaction-link"
                        to={`/${transaction.creatorProfile.handle}`}
                    >
                        @{transaction.creatorProfile.handle}
                    </Link> {transaction.transactionable_type === "App\\Models\\SecurionTransaction" ? `from card ending in ${transaction.last4}` : transaction.transactionable_type === "App\\Models\\WalletTransaction" ? "from wallet balance" : ""}
                </>
            );
        } else if (
            transaction.product?.tip_message === null ||
            transaction.product?.tip_message?.length > 0
        ) {
            return (
                <>
                    Tip to{" "}
                    <Link
                        className="fl-transaction-link"
                        to={`/${transaction.creatorProfile.handle}`}
                    >
                        @{transaction.creatorProfile.handle}
                    </Link> {transaction.transactionable_type === "App\\Models\\SecurionTransaction" ? `from card ending in ${transaction.last4}` : transaction.transactionable_type === "App\\Models\\WalletTransaction" ? "from wallet balance" : ""}
                </>
            );
        } else if (transaction.product?.post) {
            return (
                <>
                    Post unlocked from{" "}
                    <Link
                        className="fl-transaction-link"
                        to={`/${transaction.creatorProfile.handle}`}
                    >
                        @{transaction.creatorProfile.handle}
                    </Link>
                </>
            );
        } else if (transaction.product?.chatMessage) {
            return (
                <>
                    Post unlocked from{" "}
                    <Link
                        className="fl-transaction-link"
                        to={`/${transaction.creatorProfile.handle}`}
                    >
                        @{transaction.creatorProfile.handle}
                    </Link>
                </>
            );
        } else  if (transaction?.type === "subscription_renewal") {
            return (
                <>
                    Subscription renewal to{" "}
                    <Link
                        className="fl-transaction-link"
                        to={`/${transaction.creatorProfile.handle}`}
                    >
                        @{transaction.creatorProfile.handle}
                    </Link> {transaction.transactionable_type === "App\\Models\\SecurionTransaction" ? `from card ending in ${transaction.last4}` : transaction.transactionable_type === "App\\Models\\WalletTransaction" ? "from wallet balance" : ""}
                </>
            );
        } else {
            return <>Wallet top up from card ending {transaction.last4}</>;
        }
    };

    return (
        <Fragment>
            {animation && (
                <div className="fl-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
            <div
                className={`fl-settings-sub-stretch ${
                    animation && "fl-settings-sub-stretch-blur"
                }`}
            >
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                        <span
                            className="fl-direct-messages-title-icon"
                            onClick={(e) => redirectProfile(e)}
                        >
                          <FontAwesomeIcon icon={faChevronCircleLeft} />
                        </span>
                        Transactions
                    </div>
                </div>
                <div className="fl-settings-sub-section">
                    <div
                        className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme}`}
                    >
                        <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-head">
                            <div className="fl-settings-sub-payment-transactions-single-date">
                                Date
                            </div>
                            <div className="fl-settings-sub-payment-transactions-single-amount">
                                Amount
                            </div>
                        </div>
                        {state.transactions.map((x: any, y: number) => (
                            <div
                                key={y}
                                className={`fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-action fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                            >
                                <div className="fl-settings-sub-payment-transactions-single-date">
                                    <p>
                                        {moment(x.created_at).format("D MMM YYYY")},{" "}
                                        {moment(x.created_at).format("HH:mm")}
                                    </p>
                                    <div
                                        className={`fl-transaction-space-${userData.currentTheme}`}
                                    ></div>
                                    <p>{checkTypeAction(x)}</p>
                                </div>
                                <div className="fl-settings-sub-payment-transactions-single-amount">
                                    {x.amount > 0 ? "-" : x.amount === 0 ? "" : "+"}$
                                    {x.amount.toFixed(2)}
                                </div>
                            </div>
                        ))}
                        <div
                            className="fl-settings-sub-payment-transactions-more"
                            onClick={() =>
                                stateRef.current.currentPage >= stateRef.current.last_page
                                    ? null
                                    : setState({
                                        ...stateRef.current,
                                        currentPage: stateRef.current.currentPage + 1,
                                    })
                            }
                        >
                            {state.currentPage >= state.last_page
                                ? ""
                                : "View more transactions"}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
