import React, {FC, useEffect, useState,} from "react";
import './FluffaDrive.css'
import {Button} from "./Button";
import Axios from "axios";
import {useEnvContext} from "../context/EnvContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {DriveFiles} from "../../src/routes/Drive/DriveFiles"
import {useModalContext} from "../context/ModalContext";

export const FluffaDrive: FC<{uploadState:any, setUploadState:any, route:any }> = (props) => {

    const envData = useEnvContext();
    const modalData = useModalContext();

    const [attachments, setAttachments] = useState<any> ({
        files:[]
    })

    const [selectedFiles, setSelectedFiles] = useState<any> ({
        files:[]
    })

    const getAttachments = async  () => {
        const res = await Axios.get(
            `${envData.apiUrl}/attachments`
        );
        setAttachments({...attachments, files:res.data.data})
    }

    const addSelected = (file:any) => {
        let newSelectedFiles = [...selectedFiles.files, file];
        setSelectedFiles({
            ...selectedFiles,
            files: newSelectedFiles,
        });
    }

    const removeSelected = (id: any) => {
        let newSelectedFiles = selectedFiles.files.filter(
            (x: any) => x.id !== id
        );

        setSelectedFiles({
            ...selectedFiles,
            files: newSelectedFiles,
        });

    };

    useEffect(() => {
        (async () => {
            await getAttachments();
        })();
    }, []);

    const selectDriveFiles = () => {
        props.setUploadState({
            ...props.uploadState,
            files: [...props.uploadState.files, ...selectedFiles.files],
        });
        close()
    }

    const close = () => {
        modalData.close()
    }

    const disableBtn = () => {
        if (selectedFiles.files.length === 0) {
            return true
        }
    }

    const keyboardEventDm = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            close()
        }
    }

    const countSelectedFiles = () => {
        return selectedFiles?.files?.length
    }

    const [countFiles, setCountFiles] = useState<any>(0)
    const [setCountRemovedPosts] = useState<any>(0)

    const [load,setLoad] = useState<any>(false)

    const scroll = (event:any) => {
        var maxScroll =
            Math.max(
                event.target.scrollHeight,
                event.target.offsetHeight,
                event.target.clientHeight,
                event.target.scrollHeight,
                event.target.offsetHeight
            ) - event.target.clientHeight;
        if (
            event.target.scrollTop === maxScroll
        ) {
            setLoad(true)
        }
    }
    const countAllFiles = async () => {
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/attachments/count?type=all`
            );
            setCountFiles(res.data.count)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        (async () => {
            await countAllFiles()
        })();
    }, []);
    return (
        <>
            <div
                className={`fl-modal-wrapper`}
                onClick={()=>close()}
                onKeyDown={keyboardEventDm} tabIndex={0}
            >
                <div className="fl-modal-window fl-modal-window-drive" onClick={(e) => e.stopPropagation()}>
                    <React.Fragment>
                        <div className="fl-modal-title">
                            <div className="fl-modal-title-text">Select files to attach</div>
                            <div className='fl-modal-close-button' onClick={async () => close()}> <FontAwesomeIcon icon={faTimes} /> </div>
                        </div>
                        <div className="fl-modal-description fl-modal-drive-files" onScroll={(event) => scroll (event)}>
                            <DriveFiles countFiles={countFiles} setCountRemovedPosts={setCountRemovedPosts} route={props.route}
                                        setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} removeSelected={removeSelected} addSelected={addSelected} type={"message"} setLoad={setLoad} load={load}/>
                        </div>
                        <div className="fl-modal-buttons">
                            <div className="fl-d-flex fl-justify-flex-end">
                                <Button onClick={async () => close()}>Cancel</Button>
                                <Button type="normal" onClick={selectDriveFiles} disabled={disableBtn()}>
                                    Attach { countSelectedFiles() > 1 && `${ countSelectedFiles()} files`}
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </>
    );
}