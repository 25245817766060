import React, {FC} from 'react'
import logoSource from "../../assets/images/fluffa-logo-text.png";
import messagesIcon from "../../assets/images/icons/19.svg";
import {Button} from "../../components/Button";
import messagesIconUnlock from "../../assets/images/icons/23.svg";
import {BalanceModal} from "../../components/BalanceModal";
import {useModalContext} from "../../context/ModalContext";
import axios from "axios";
import {useEnvContext} from "../../context/EnvContext";

export const LockMessage:FC<any> = (props) => {

    const modalData = useModalContext()
    const envData = useEnvContext()

    // const processUnlocking = async () => {
    //     try {
    //         const checkSecure = await axios.post(`${envData.apiUrl}/payments/security/check`, {
    //             amount:props.price
    //         });
    //         if(checkSecure.data.protected) {
    //             modalData.push(() => <SegpayModal url={checkSecure.data.url} addCardFunction={processUnlockingFunction} />)
    //         } else {
    //             processUnlockingFunction()
    //         }
    //     } catch (e) {
    //         const description = e?.response?.data?.errors.user ? e?.response?.data?.errors.user : "Something went wrong!";
    //         await modalData.push({
    //             title: "ERROR",
    //             description: [`${description}`],
    //             buttons: [
    //                 () => (
    //                     <div className="fl-d-flex fl-justify-flex-end">
    //                         <Button type="normal" onClick={() => modalData.closeAll()}>
    //                             Dismiss
    //                         </Button>
    //                     </div>
    //                 ),
    //             ],
    //         });
    //     }
    // };

    const processUnlockingFunction = async () => {
        try {
            const res = await axios.put(
                `${envData.apiUrl}/conversations/${props.conversationId}/messages/${props.messageId}/unlock`
            );
            props.setState({
                ...props.state,
                messages: props.state.messages.map((x:any) => x.id === props.messageId ? res.data.data : x),
            });
            await modalData.close();
        } catch (e) {
            const description:any = []
            const userError =  e?.response?.data?.errors?.user
            const defaultError = e?.response?.data?.errors?.error
            const unlock = e?.response?.data?.errors?.message
            // const creditCard = e?.response?.data?.errors.creditCard
            if(userError) {
                description.push("Limit on this credit card is reached, maximum 4 transactions or $200 per month")
            } else if (defaultError) {
                description.push(defaultError)
            }else if (unlock) {
                description.push(unlock)
            } else {
                description.push("Error")
            }
            // else if (creditCard) {
            //     description.push(creditCard)
            // }
            // console.log(userError)
            // const description = e?.response?.data?.errors?.user ? "Limit on this credit card is reached, maximum 4 transactions or $200 per month" : e?.response?.data?.errors?.error ? e?.response?.data?.errors?.error : e?.response?.data?.errors.creditCard ;
            await modalData.push({
                title: "ERROR",
                description: [`${description}`],
                buttons: [
                    () => (
                        <div className="fl-d-flex fl-justify-flex-end">
                            <Button type="normal" onClick={() => modalData.closeAll()}>
                                Dismiss
                            </Button>
                        </div>
                    ),
                ],
            });
        }
    }

    const unlockAttachments = async () => {
        modalData.push(() => (<BalanceModal
            header={`${props.price > 0 ? "Unlock message" : "Unlock message for free"}`}
            description={`${props.price > 0 ? `You will be charged $${props.price} to unlock this message.` : "Good news! You will NOT be charged to unlock this message."}`}
            action={processUnlockingFunction} amount={props.price} data={props.price} buttonText={props.price > 0 ? "Confirm" : "Unlock"}/>));
    };

    return (
        <div className="fl-message-bubble-message-attachment-unlock">
            <div className="fl-message-bubble-message-attachment-unlock-logo">
                <img src={logoSource} alt="Fluffa"/>
            </div>
            <img
                className="fl-card-icon-padlock-ppv fl-settings-pb-10"
                src={messagesIcon}
                alt="Card icon"
            />
            <img
                className="fl-card-icon-padlock-ppv-unlock fl-settings-pb-10"
                src={messagesIconUnlock}
                alt="Card icon"
            />
            <Button
                className="fl-message-bubble-message-attachment-unlock-button"
                type="normal"
                onClick={() =>unlockAttachments()}
            >
                {props.price === 0 ? "UNLOCK FOR FREE" :
                    `Pay $${props.price} to unlock`
                }
            </Button>
        </div>
    )
}