import React, {FC, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEllipsisH, faExpand,
    faShare,
    faTag, faTimes,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {SimpleCard} from "../../components/SimpleCard";
import {useUserContext} from "../../context/UserContext";
import {useModalContext} from "../../context/ModalContext";
import VideoPlayer from "../../components/video/VideoPlayer";

export const ModalImage: FC<{
    files:any,
    deleteFile:any,
    updateFile:any,
    shareFile:any,
    currentIndex:any,
}> = ({files,deleteFile,updateFile,shareFile,currentIndex}) => {

    const userData = useUserContext();
    const modalData = useModalContext();

    const [imageDropdown,setImageDropdown] = useState(false)
    const [playing,setPlaying] = useState(false)

    const imageOption = (e:any) => {
        e.stopPropagation()
        setImageDropdown(!imageDropdown)
    }

    const returnId = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0].id
    }

    const returnLabels = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0].labels
    }

    const returnFile = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0]
    }

    const returnUrl = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0]?.url
    }

    const returnThumb = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0]?.thumb_url
    }

    const returnDuration = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0]?.duration
    }

    const returnType = () => {
        return files.files.filter((x: any,index:number) => index === currentIndex)[0]?.type
    }

    const keyboardEvent = (event: KeyboardEvent) => {
        switch(event.code) {
            case "Escape":
                modalData.close()
                break;
            case "ArrowRight":
                nextImage()
                break;
        }
    }

    const nextImage = () => {

    }

    useEffect(() => {
        document.addEventListener('keydown', keyboardEvent);
        return function cleanup() {
            document.removeEventListener('keydown', keyboardEvent);
        }
    }, [keyboardEvent]);

    return (
        <div className={`fl-modal-wrapper`}>
            <div
                className="fl-modal-close-button fl-image-close"
                onClick={() => modalData.close()}
            >
                <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="fl-image-modal-window">
                {playing ? "" : <div className={"fa-drive-option-modal"}   onClick={(e) => imageOption(e)} >  <FontAwesomeIcon icon={faEllipsisH} /> </div>}
                {imageDropdown &&
                <div
                    className={`fl-filter-head-actions-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                >
                    <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                        <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select"  onClick={(e) => updateFile(e,returnId(),returnLabels(),returnUrl())}>
                            <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                <FontAwesomeIcon icon={faTag} />
                            </div>
                            <span>
                                        {returnLabels().length > 0 ? "Edit" : "Add"} tags
                                    </span>
                        </div>
                        <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select"  onClick={(e) => shareFile("single",returnFile(),e)}>
                            <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                <FontAwesomeIcon icon={faShare} />
                            </div>
                            <span>
                               Share file
                            </span>
                        </div>
                        <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) =>{e.stopPropagation();modalData.fullScreen(files.files,currentIndex)}}>
                            <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                <FontAwesomeIcon icon={faExpand} />
                            </div>
                            <span>
                                 Full screen
                            </span>
                        </div>
                        <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => deleteFile(e,returnId())}>
                            <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>
                            <span>
                                Delete file
                             </span>
                        </div>
                    </SimpleCard>
                </div>
                }
                <React.Fragment>
                    {/*{files.files.length > 1 &&*/}
                    {/*<>*/}
                    {/*    <div*/}
                    {/*        className={`fl-image-modal-window-prev`}*/}
                    {/*        onClick={prevImageDrive}*/}
                    {/*    >*/}
                    {/*        <FontAwesomeIcon icon={faChevronLeft} />*/}
                    {/*    </div>*/}
                    {/*</>*/}
                    {/*}*/}
                    <div className="fl-modal-image"  onBlur={() => setImageDropdown(false)} tabIndex={1}>
                        {returnType() === "video" ?
                            <VideoPlayer src={returnUrl()} thumbnail={returnThumb()} duration={returnDuration()} setPlaying={setPlaying} playing={playing} /> :
                            <img src={returnUrl()} alt={"fluffa"} />
                        }
                    </div>
                    {/*{files.files.length > 1 &&*/}
                    {/*<>*/}
                    {/*    <div*/}
                    {/*        className={`fl-image-modal-window-next`}*/}
                    {/*        onClick={nextImageDrive}*/}
                    {/*    >*/}
                    {/*        <FontAwesomeIcon icon={faChevronRight} />*/}
                    {/*    </div>*/}
                    {/*</>*/}
                    {/*}*/}
                </React.Fragment>
            </div>
        </div>
    );
}
export default ModalImage;
