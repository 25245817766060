import React, { FC, useState, useEffect } from 'react'
import { Banner } from '../components/Banner';
import { Card } from '../components/Card';
import { ImageSection } from '../components/ImageSection';
import { useUserContext } from '../context/UserContext';
import './Home.css';

// ASSETS
import fileIcon from '../assets/images/icons/21.svg';
import padlockIcon from '../assets/images/icons/24.svg';
import smileyIcon from '../assets/images/icons/18.svg';
import boltIcon from '../assets/images/icons/17.svg';

import {
    faHeadset
} from "@fortawesome/free-solid-svg-icons";

import firstSectionImage from '../assets/images/3.png';
import secondSectionImage from '../assets/images/4.png';

import Axios from 'axios';
import { useEnvContext } from '../context/EnvContext';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import {useModalContext} from "../context/ModalContext";



const cardData = [
    {title: "Increase Engagement", description: "Post content on Fluffa that you can’t post on other social media sites." , icon: faHeart, type: 'fa'},
    {title: "Maximise Revenue", description: "Generate income by selling membership subscriptions and exclusive content.", icon: fileIcon, type: 'file'},
    {title: "DMCA Protection", description: "Your content is safe on Fluffa as we've integrated numerous protective measures, including robust DMCA protection.", icon: boltIcon, type: 'file'},
    {title: "24/7 Support", description: "We’re committed to providing the best customer support possible. Our friendly team are always standing by should you require any help.", icon: faHeadset, type: 'fa'},
    {title: "Updates", description: "We regularly update the platform with new features, so you'll always be ahead of the curve when it comes to maximising your income.", icon: smileyIcon, type: 'file'},
    {title: "Privacy", description: "We provide a secure platform where you own and control all of your content.", icon: padlockIcon, type: 'file'}
];

export const Home:FC<any> = (props) => {

    const [promotions,setPromotions] = useState([])
    const [suggestions,setSuggestions] = useState([])

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext()

    const getPromotion = async () => {
        try {
            const res = await Axios.get(`${envData.apiUrl}/explore/featured`);
            setPromotions(res.data.data)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const getSuggestions = async () => {
        try {
            const res = await Axios.get(`${envData.apiUrl}/suggestions`);
            setSuggestions(res.data.data)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        getPromotion();
        getSuggestions();
    },[]);

    const redirect = () => {
        if (userData.authStatus === "AUTHENTICATED") {
            return "/explore/all"
        } else {
            return "/register"
        }
    }

    return (
        <React.Fragment>
            <Banner/>
            <div className="fl-cards-main">
                <div className="fl-container fl-cards-inner">
                    {cardData.map((x,y) => <div key={y} className="fl-cards-single">
                        <Card data={x}/>
                    </div>)}
                </div>
            </div>
            <div className={`fl-image-section-main fl-image-section-main-${userData.currentTheme}`}>
                <div className="fl-container fl-image-section-inner">
                    <ImageSection title="Become a Creator, it’s easy." description="It’s completely free to join Fluffa and our verification process is quick and simple. We take care of the features and technology, so you can focus on growing your income." image={secondSectionImage} invert={true}/>
                </div>
            </div>
            <div className={`fl-image-section-main fl-image-section-main-${userData.currentTheme}`}>
                <div className="fl-container fl-image-section-inner">
                    <ImageSection title="Support your favourite Creators." description="No ads, no spam! Just a network of the best Content Creators who go above and beyond to create high quality content exclusively for you!" image={firstSectionImage}/>
                </div>
            </div>
            {/*<div className="fl-featured-profiles">*/}
            {/*    <div className="fl-container fl-featured-profiles-inner">*/}
            {/*        <div className={`fl-featured-profiles-title fl-featured-profiles-title-${userData.currentTheme}`}>Fluffa Creators</div>*/}
            {/*        <div className="fl-featured-profiles-list fl-home-page-featured">*/}
            {/*            {promotions.map((x,y) => <div className="fl-profile-card-holder" key={y}>*/}
            {/*                <ProfileCard data={x} type={y % 2 === 0 ? 'pink' : 'orange'} promotion noUrl />*/}
            {/*            </div>)}*/}
            {/*            {promotions.length > 6 ? "" :*/}
            {/*             <>*/}
            {/*                 {suggestions.slice(0, 6 - promotions.length).map((x,y) =>*/}
            {/*                   <div className="fl-profile-card-holder" key={y}>*/}
            {/*                     <ProfileCard data={x} type={y % 2 === 0 ? 'pink' : 'orange'} noUrl />*/}
            {/*                   </div>*/}
            {/*                 )}*/}
            {/*             </>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div className="fl-featured-profiles-button">*/}
            {/*            <Button style={{width: 200}} to={redirect()} type="normal">View More</Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </React.Fragment>
    )}
