import { faChevronCircleLeft, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, Fragment } from 'react'
import { Toggle } from '../components/Toggle';
import { useUserContext } from '../context/UserContext';
import './SettingsAppearance.css';
import {useHistory} from "react-router";

export const SettingsAppearance:FC<any> = (props) => {

    const userData = useUserContext();
    const history = useHistory();

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    return (
        <Fragment>
            <div className="fl-settings-sub-stretch">
                <div className="fl-settings-sub-section fl-pb-0">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                       <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                           <FontAwesomeIcon icon={faChevronCircleLeft} />
                       </span>
                        Appearance
                    </div>
                    <div className={`fl-settings-sub-appearance-dark-mode fl-settings-sub-appearance-dark-mode-${userData.currentTheme}`}>
                        <Toggle rightComponent={<FontAwesomeIcon icon={faSun}/>} leftComponent={<FontAwesomeIcon icon={faMoon}/>} value={userData.currentTheme === "light" ? false : true} onClick={() => userData.changeTheme()}/>
                        <span>{ userData.currentTheme === 'light' ? 'Enable dark mode' : 'Disable dark mode'}</span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}