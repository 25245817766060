import React, {FC, useEffect, useState} from "react";
import { useUserContext } from "../../../context/UserContext";
import {faCheck, faChevronCircleLeft, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components/Button";

import { MInput } from "../../../components/MInput";

import SelectWithNumberInput from "../Common/SelectWithNumberInput";
import {useHistory} from "react-router";
import {Toggle} from "../../../components/Toggle";
import ReactTooltip from "react-tooltip";
import {useModalContext} from "../../../context/ModalContext";
import axios from "axios";
import {useEnvContext} from "../../../context/EnvContext";
import ReactLoading from "react-loading";
import AllSubscriptionPlans from "./AllSubscriptionPlans";

const CreateSubscriptionPlan: FC<any> = ({
                                             checkIfAllAreCreated,
                                             clear,
                                             setClear,
                                             state,
                                             setState,
                                             customDiscountPrice,
                                             setCustomDiscountPrice,
                                             createSubscriptionPlan,
                                             freeAccount,
                                             setFreeAccount,
                                             animationCreate,
                                             requestRemoveDiscount,
                                             requestCreateDiscount,
                                             removeSubscriptionPlan,
                                             deleteSubscriptionPlan,
                                             checkPriceFiled,
                                             anotherButton,
                                             setAnotherButton
                                         }) => {
    const userData = useUserContext();
    const history = useHistory();
    const modalData = useModalContext();


    const disableCreateRegularButton = () => {
        if(state.billing_period !== "" && state.price !== "" && !animationCreate) {
            return false
        } else {
            return true
        }
    };

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    const freeAccountModal = async () => {
        await modalData.push(() => (
            <OpenConfirmationModal  freeAccount={freeAccount} setFreeAccount={setFreeAccount}/>
        ));
    }

    return (
        <div className={`fl-settings-sub-section ${freeAccount ? "fl-pb-0" : "" }`}>
            <div
                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
            >
               <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
               </span>
                {userData.user.profile.free_subscription === 1 ? "Plan settings" : "Create new plan"}
            </div>
            <div className={`fl-settings-sub-section ${freeAccount ? "fl-pb-0" : "" } `}>
                <div className={`fl-settings-plans-free-profile fl-settings-plans-free-profile-${freeAccount ? "open" : "close"} fl-settings-plans-free-profile-${userData.currentTheme}`}>
                    <p>Free profile</p>
                    <span data-tip data-for='free-account' className={"fl-color-grey"}>
                        <FontAwesomeIcon icon={faInfoCircle} className='fl-info-text'/>
                    </span>
                    <ReactTooltip id='free-account'>
                        <p>Free profiles allow fans to follow you without paying a monthly fee. They'll be able to purchase all of your past and current PPV posts.</p>
                        <p>Please note that all content you upload must comply with our Terms.</p>
                    </ReactTooltip>
                    <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={freeAccount} onClick={() => {
                        freeAccountModal()
                    }}
                    />
                </div>
            </div>

            {!freeAccount ?
                <>
                    <AllSubscriptionPlans
                        state={state}
                        setState={setState}
                        requestRemoveDiscount={requestRemoveDiscount}
                        requestCreateDiscount={requestCreateDiscount}
                        removeSubscriptionPlan={removeSubscriptionPlan}
                        deleteSubscriptionPlan={deleteSubscriptionPlan}
                        setAnotherButton={setAnotherButton}
                        anotherButton={anotherButton}
                        checkPriceFiled={checkPriceFiled}
                    />
                    {checkIfAllAreCreated().length > 0 ?
                    <>
                        <div className="fl-settings-sub-section">
                            {state.plans.filter((x: any) => x.type === "regular").length > 0 && !anotherButton ?
                                "" :
                                <>
                                    <MInput
                                        type="select"
                                        label="Billing cycle"
                                        clear={clear}
                                        setClear={setClear}
                                        value={state.billing_period}
                                        setValue={(billing_period) => {
                                            setState({ ...state, billing_period });
                                        }}
                                        options={[
                                            {
                                                options: checkIfAllAreCreated(),
                                            },
                                        ]}
                                        className={" fl-settings-mt-30"}
                                    />
                                    <div  className={" fl-settings-mt-30"}>
                                        <SelectWithNumberInput
                                            stateName="price"
                                            inputTypeSwitched={customDiscountPrice}
                                            inputTypeSwitch={setCustomDiscountPrice}
                                            state={state}
                                            setState={setState}
                                            clear={clear}
                                            setClear={setClear}
                                            labelForSelect="Set your price ($)"
                                            labelForNumber="Set your price"
                                            value={state.price}
                                            options={checkPriceFiled()}
                                            min="5"
                                            hasInfo={true}
                                            infoId="set-your-price"
                                            infoText={["You can set a price from $5 to $100"]}
                                        />
                                    </div>
                                    <Button
                                        type="normal"
                                        className="fl-settings-sub-security-button"
                                        disabled={disableCreateRegularButton()}
                                        onClick={createSubscriptionPlan}
                                    >
                                        Create subscription plan
                                        {animationCreate ?  <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                                    </Button>
                                </>
                            }
                        </div>
                    </> :
                        <p className={`fl-settings-sub-section-p-${userData.currentTheme}`}>Note: You have created the maximum number of plans. If you want to set up a new plan you'll need delete one of your existing plans.</p>
                    }
                </>
                : ""
            }

        </div>
    );
};

export default CreateSubscriptionPlan;

export const OpenConfirmationModal: FC<any> = ({ freeAccount ,setFreeAccount}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const userData = useUserContext()

    const [confirmAnimation, setConfirmAnimation] = useState(false)

    const freeAccountFunction = async () => {
        modalData.clearToasts()
        setConfirmAnimation(true)
        try {
            await axios.put(`${envData.apiUrl}/users/profile`, {
                free_subscription: freeAccount ? 0 : 1
            });
            modalData.close()
            setConfirmAnimation(false)
            setFreeAccount(!freeAccount)
            userData.changeFreeAccount(freeAccount ? 0 : 1)
            modalData.pushToast("success", `Your profile has been set to ${freeAccount ? "PAID" : "FREE"}`);
        } catch (e) {
            setConfirmAnimation(false)
            modalData.pushToast('error', 'Something went wrong!');
        }
    }

    useEffect(() => {
        document.body.classList.add("fl-body-fixed")
        return function cleanup() {
            document.body.classList.remove("fl-body-fixed")
        }
    }, []);

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Switch to {freeAccount ? "paid" : "free"} profile?</div>
            </div>
            <div className="fl-modal-description">
                {freeAccount ?
                    <>
                        <div className="fl-profile-actions-subscribe-modal-description-list-item">
                            Fans are required to pay a monthly subscription fee to view your profile.
                        </div>
                        <div className="fl-profile-actions-subscribe-modal-description-list-item">
                            Fans can purchase your PPV content and send you tips.
                        </div>
                        <div className="fl-profile-actions-subscribe-modal-description-list-item">
                            You can create multiple join options and offer discounts on plans.
                        </div>
                    </>
                    :
                    <>
                      <div className="fl-profile-actions-subscribe-modal-description-list-item">
                         Fans will be able to view your profile without paying a monthly subscription fee.
                      </div>
                      <div className="fl-profile-actions-subscribe-modal-description-list-item">
                         Fans can purchase your PPV content and send you tips.
                      </div>
                    </>
                }
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" disabled={confirmAnimation} onClick={() => freeAccountFunction()}>
                    Switch{confirmAnimation && "ing"} to {freeAccount ? "Paid" : "Free"}
                    {confirmAnimation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </React.Fragment>
    );
};


