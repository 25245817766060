import axios from "axios";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { MInput } from "./MInput";

export const SearchMInput: FC<{
    forceErrors?: boolean;
    validator?: Array<{ check: (v: any) => boolean; message: string }>;
    visiblepassword?: boolean;
    clear?: number;
    setClear?: (x: number) => any;
    preRender?: (x: any) => ReactNode;
    favoriteFilter?: Array<(x: any, y: number) => boolean>;
    options: Array<any>;
    setOptions: any;
    label: string;
    value: any;
    setValue: (v: any) => any;
    filterParams?: Array<string>;
    searchUrl: string;
    category?: string;
    hideArrow?:boolean;
    [key: string]: any;
}> = ({
  style,
  className,
  label,
  value,
  validatingMessage = "Field is not valid",
  validator,
  setValue,
  type,
  options,
  setOptions,
  favoriteFilter,
  preRender,
  ref,
  forceErrors = false,
  hasInfo = false,
  infoId = "",
  infoText = "",
  revertToSelect,
  minIsSet,
  subsInput,
  filterParams = [],
  searchUrl,
  category,
  hideArrow,
  visiblepassword=false,
  ...props
}) => {

    const [stringFilter, setStringFilter] = useState('');
    
    const getFilteredResults = () => {
        let queryFromFilters = '?'
        filterParams.forEach((value, key) => {
            queryFromFilters += key;
            queryFromFilters += `=${value}&`;
        });
        if(!stringFilter.includes(',')) {
            axios.get(`${searchUrl}${queryFromFilters}string=${stringFilter}`).then(response => {
    
                setOptions(response.data.data.map((elem: any) => {
                    return {
                        value: elem.id,
                        label: elem.name_with_state
                    }
                }))
            }).catch(() => {
                // modalData.pushToast("error", "Something went wrong!")
            });
        }
    }
    
    useEffect(() => {
        setOptions([]);
    }, [props.clear]);

    useEffect(() => {
        getFilteredResults();
    }, [stringFilter])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStringFilter(event.target.value);
    }

    return (
        <div onChange={handleChange}>
            <MInput 
                forceErrors={forceErrors}
                validator={validator}
                type={type}
                label={label}
                value={value}
                setValue={setValue}
                options={options}
                clear={props.clear}
                setClear={props.setClear}
                hideArrow
            />
        </div>
    );
}