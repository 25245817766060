import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {FC} from "react";
import {Link} from "react-router-dom";
import { SimpleCard } from "../../components/SimpleCard";
import "./Support.css";
import "../Privacy.css";
import { useUserContext } from "../../context/UserContext";
import {SupportNav} from "./SupportNav";

export const SupportPrivacy: FC<any> = (props) => {

    const userData = useUserContext();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    {!isMobile ?  <SupportNav /> : ""}
                    <div className="fl-col-9 fl-support-col">
                        <SimpleCard>
                            <div className={`fl-privacy-title fl-privacy-title-${userData.currentTheme}`}>Privacy Notice</div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Why do we have this privacy notice?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We are Fluffa.com (Fluffa), a social content platform for adults. We want you to know as much as possible about what we do with your personal information.  You and your personal information are protected by various laws and guidance and we are committed to upholding these and respecting your privacy and keeping your information safe.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>In this privacy notice any reference to "us", "we", "our" or "ourselves" is a reference to Fluffa, and any reference to "you", "your" or “yourself” is a reference to you as someone who has a relationship with us in some way, who has contacted us or interacted with us in some way or your personal information is relevant to our work.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>This privacy notice will cover you if you interact with us or we process your personal information for any other reason.  For example, this privacy notice will cover someone who uses our website, signs up as a user to view or make content or who links to or follows our social media accounts, a member of the public who contacts us or anyone else who is affected by our activities.  This privacy notice provides details in accordance with data protection laws about how we collect and use personal information about you during and after your relationship with us.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>As this privacy notice covers a wide range of individuals and different types of relationships and interactions with us, not all aspects of this privacy notice may apply to you depending upon the nature of your relationship, interactions with us and why we are processing your personal information.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    The controller of your personal information
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>For the purposes of data protection laws and this privacy notice, we are the controller of your personal information for the processing of your personal information. Being a controller of your personal information means that we are responsible for deciding how we hold and use your personal information. Fluffa is a project of NebNine LTD (registered number SC676804 ), a private company limited by shares incorporated in Scotland with our registered office at 272 Bath Street, Glasgow, Scotland, G2 4JR, United Kingdom. </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Your duty to inform us of changes
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during or after the period of your interactions with us.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    What if you do not provide personal information?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Generally, you are not obliged to provide us with any of your personal information and in some cases failing to provide some of it may have an adverse impact on our ability to interact with you.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    If you have queries or concerns just ask!
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We are not required to appoint a data protection officer to oversee our compliance with data protection laws, however if you have any questions about this privacy notice or how we handle your personal information, please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Changes to this notice
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We keep our privacy notice under regular review and we may update this privacy notice from time to time. You can request a copy of this privacy notice from our Support Team, who can be contacted at <a href="mailto: support@fluffa.com">support@fluffa.com</a>. If there are any material changes to this privacy notice in the future we will let you know, usually by updating the version on our website.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    What personal information do we collect?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>In connection with your relationship or interactions with us, we may collect and process a wide range of personal information about you. This includes:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Personal contact details such as name, title, address and email address.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Information about your date of birth, age, gender.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Bank account details, financial transactions, payments.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Any terms and conditions relating to your relationship with us.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Any communications between ourselves and you or you and the users of our website.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Your passport, driving licence or other identification documents. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Details of services carried out by you in connection with our relationship with you and details of your interest in and connection with other users.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Publicly available personal information, including any which you have shared via a public platform, online or on social media and also non-public personal information where you have followed or linked to any of our social media.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>How you use our website as we collect information about the pages you look at and how you use them, usernames, account details and passwords, location, IP addresses, entry and exit data when you look at or leave our website, details of services that may be of interest to you, online user subscription information, when you ask to receive our updates, browser related information, cookies that are set on your device by our website.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Details of any queries, complaints, claims and cases involving both us and yourself including any related communications. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Photographs, video footage, audio recordings, text and other content you create on our website or provide to us.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Any other personal information you provide to us.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We may also in some cases collect and process more sensitive special category personal information including information about sex life or sexual orientation.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    We aim not to collect personal information about children
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Our website, materials and other services we provide are not intended for use by anyone under the age of 18 years and we do not knowingly collect personal information relating to anyone under the age of 18 years old unless for some reason you provide it to us. We have checks to ensure that we do not knowingly offer our services to or collect personal information from anyone under the age of 18 years old or anyone using a false identity. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>For more information, please see the section below on Who Do We Share Your Personal Information With Externally?</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Where do we collect your personal information from?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We collect your personal information in a variety of ways and from a variety of sources including those set out below:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Often most of your personal information is collected directly from you, for example through contact with you, when you create an account, from your passport or other identity documents such as your driving licence, from correspondence with you or other interactions with us or other personal information you provide to us.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>From our website, other websites, the internet, social media or other platforms including public sources of information.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>From our website and information technology and communications systems and suppliers we use in connection with them.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We store personal information relating to you in a range of different places, such as information technology systems (including our email system).</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    What are our bases for processing your personal information?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We will only use your personal information when the law allows us to.  This means we must have one or more legal bases to use your personal information.  The most common legal bases which will apply to our use of your personal information are set out below:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where we need to perform the contract we have entered into with you which covers your relationship with us or to take steps to enter into that contract. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where we need to comply with a legal obligation which applies to us.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where it is necessary for legitimate interests pursued by us or a third party and your interests and fundamental rights do not override those interests.  We have set out in the section below how we use your personal information together with more details on our legitimate interests.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where you have given your consent.  Generally we do not rely on or need your consent for almost all uses we make of your personal information.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Where we are processing any sensitive special category personal information about you (which covers personal information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, genetic data, biometric data, data concerning health or data concerning your sex life or sexual orientation) then we also need to have one or more of the following legal bases for using your personal information.
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where we have your explicit consent to do so.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where it is necessary for us to comply with our obligations and exercising our rights in the field of employment law, social security law and social protection law.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where we need to protect your vital interests (or someone else's vital interests).</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where you have already made public the personal information.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>In establishing, exercising or defending legal claims, whether those claims are against us or by us.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Where it is necessary in the public interest.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Where we process criminal records information about you, then we will either do so either to comply with legal obligations or with your consent.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    How will we use your personal information?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>There are many ways we may need to use your personal information in the context of your relationship with us or our use of your personal information.  We have set out the main uses below, and indicated the main applicable legal bases of processing, but there may be other specific uses which are linked to or covered by the uses below.
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We may process your personal information to conduct any business or other relationship we have with you or an organisation you work for. This may relate to the entry into or performance of a contract with you or your organisation either directly or indirectly, which will be in our legitimate interests and we may also have legal obligations or be exercising a legal right to do this.  We may also in some limited cases rely on your consent.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We may need to process your personal information in order to hold or conduct promotions or campaigns.  This may relate to the entry into or performance of a contract with you either directly or indirectly, it may be in our legitimate interests, and in some cases we may rely on your consent to do this.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>As a business we may have many legal obligations connected to our relationship with you, for example to comply with data protection laws. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We will also need to keep and maintain proper records relating to your relationship with us. As well as relating to the entry into or performance of a contract with you either directly or indirectly, this will also be in our legitimate interests, and we may also have legal obligations to do this.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>In some cases we may need to process your personal information to prevent, detect or prosecute criminal activity.  This will also be in our legitimate interests, we may also have legal obligations or be exercising a legal right to do this and it will also be in the public interest.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>You may have contacted us about a query, complaint or enquiry and we need to be able to respond to you and deal with the points you have raised. This will also be in our legitimate interests, we may also have legal obligations or be exercising a legal right to do this.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We may need to gather evidence for and be involved in possible legal cases.  As well as relating to the entry into a contract with you or an organisation you work for either directly or indirectly, this will also be in our legitimate interests, we may also have legal obligations or be exercising a legal right to do this and it may also be needed to establish, bring or defend legal claims.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>To ensure effective general business administration and to manage our business. As well as relating to the entry into or performance of a contract with you either directly or indirectly, this will also be in our legitimate interests, and we may also have legal obligations or be exercising a legal right to do this.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>To monitor any use you make of our information and communication systems and our website and social media accounts to ensure compliance with our information technology policies, ensure network and information security, including preventing unauthorised access to our computer and electronic communications systems and preventing malicious software distribution and also to monitor your use of our website and social media.  As well as relating to the entry into or performance of a contract with you either directly or indirectly, this will also be in our legitimate interests, and we may also have legal obligations or be exercising a legal right to do this.  In relation to social media you may also have already made the personal information public.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>To conduct data analytics and analysis studies and improve our business, use of our website and social media which relates to us.  This will also be in our legitimate interests, and we may also have legal obligations or be exercising a legal right to do this.  </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We may carry out market research, so that we can better understand our users or provide services to them.  This will also be in our legitimate interests, and we may also have legal obligations or be exercising a legal right to do this.</div>
                                </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Change of purpose
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.  If we need to use your personal information for an unrelated purpose, we will notify you by updating this privacy notice on our website, so please check back regularly for any updates.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Please note that we may process your personal information without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.  We will rarely need to rely on your consent to process any of your personal information.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Automated decision-making
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Automated decision-making takes place when an electronic system uses personal information to make a decision about that person without any human intervention which produces legal effects concerning them or similarly significantly affects them. We may use this type of automated decision making to carry out checks to ensure that we do not knowingly offer our services to or collect personal information from anyone under the age of 18 years old.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You will not be subject to decisions that will have a significant impact on you based solely on automated decision making unless we have a lawful basis for doing so and we have notified you.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Who has internal access to your personal information?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Your personal information may be shared internally with our staff where access to your personal information is necessary for the performance of their roles.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Who do we share your personal information with externally?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>When using your personal information we may share it with third parties but we will only do so when it is appropriate and we have a legal basis for doing so. Third parties that we may share your personal information with include:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Customers of our business where it is appropriate to do so.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Service or product providers to our business, for example information technology services suppliers, credit reference agencies, age verification providers, marketing and public relations service providers.  </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Third parties that process personal information on our behalf and in accordance with our instructions, usually suppliers of services to us like our payment processors. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Our legal and other professional advisers or any professional advisors appointed by you, for example a legal advisor.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Social media and other online platforms where relevant to our relationship with you.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Governmental bodies, agencies, HMRC and other regulators.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We use Google Analytics which sets cookies to collect information about how visitors use our website. See our Cookie Policy <Link to="/cookies">here</Link>. We use the information to compile reports and to help us improve the website. The cookies collect information in an anonymous form, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.  To opt out of being tracked by Google Analytics across all websites visit <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>We may also use other service providers for marketing purposes.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We do not disclose personal information to anyone else except as set out above unless we are legally entitled to do so. </div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    International transfers
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>It will be necessary to share your personal information outside of the UK and the European Economic Area (the EEA). This will typically occur when service providers to our business are located outside the EEA. These transfers are subject to special rules under data protection laws. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If we transfer your personal information outside of the UK and the EEA, we will ensure that the transfer will be compliant with data protection laws and all personal information will be secure.  Our standard practice is to assess the laws and practices of the destination country and relevant service provider and the security measures that are to be taken as regards the personal information in the overseas location; alternatively, we use standard data protection clauses. This means that when a transfer such as this takes place, you can expect a similar degree of protection in respect of your personal information.  </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Our directors and other key staff working for us may in limited circumstances access personal information from outside of the UK and EEA if they are on holiday abroad outside of the UK or EEA.  If they do so they will be using our security measures and the same legal protections will apply that would apply to accessing personal information from our premises in the UK.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>In limited circumstances the people to whom we may disclose personal information may be located outside of the UK and EEA and we will not have an existing relationship with them, for example a foreign police force or a foreign regulator.  In these cases we will impose any legally required protections to the personal information as required by law before it is disclosed.  </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you would like any more details about how we protect your personal information in relation to international transfers, then please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    For how long do we keep your personal information?
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>The duration for which we retain your personal information will differ depending on the type of information and the reason why we collected it from you.  We will hold your personal information for the duration of your relationship with us or the reason we were processing it, and then usually for a further period of up to 6 years after that. </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Whichever time period normally applies, in some cases we may need to keep your personal information for longer, for example if it is still relevant to a dispute or legal case or claim.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We will not retain your personal information for longer than necessary for the purposes for which it was collected and it is being used. We do not guarantee to retain your personal information for the whole of the periods set out above; they are usually the maximum period, and in some cases we may keep your personal information for a much shorter period.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Your rights
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>As an individual whose personal information we collect and process, you have a number of rights.  You may:
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Withdraw any consent you have given to us, although this will only be relevant where we are relying on your consent as a basis to use your personal information, but it is an absolute right. Once we have received notification that you have withdrawn your consent, we will no longer process your personal information for the purpose or purposes for which you originally gave your consent, unless we have another legal basis for doing so, but withdrawing consent will not affect use that has already happened.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Request details about how your personal information is being used.  This right is linked with the right of access mentioned below.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Request access and obtain details of your personal information that we hold (this is commonly known as a “data subject access request”). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.  </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Request correction of the personal information that we hold about you.  This enables you to have any incomplete or inaccurate information we hold about you corrected. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Request erasure of your personal information.  This means that you can ask us to delete or stop processing your personal information, for example where we no longer have a reason to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (set out below).  The right to have data erased does not apply in all circumstances.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Object to the processing of your personal information where we are relying on a legitimate interest (ours or that of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. </div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Object to direct marketing where we are processing your personal information for direct marketing purposes.  This is an absolute right.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Request the restriction of processing of your personal information. This enables you to ask us to stop processing your personal information for a period if data is inaccurate or there is a dispute about whether or not your interests override our legitimate grounds for processing your personal information.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Request the transfer of your personal information to another party in certain circumstances.</div>
                                    <div className={`fl-privacy-sub-row fl-privacy-sub-row-${userData.currentTheme}`}>Object to certain automated decision-making processes using your personal information.</div>
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You should note that some of these rights, for example the right to require us to transfer your personal information to another service provider or the right to object to automated decision making, may not always apply as they have specific requirements and exemptions which apply to them and they may not apply to personal information recorded and stored by us.  For example, we do not use automated decision making in relation to your personal information which has legal or other significant effects for you.  However, some of your rights have no conditions attached, so your right to withdraw consent or object to processing for direct marketing are absolute rights.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you would like to exercise any of these rights, please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights).  This is another appropriate security measure to ensure that personal information is not disclosed to any person or dealt with by a person who has no right to do so.</div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>Whilst this privacy notice sets out a general summary of your legal rights in respect of personal information, this is a complex area of law.  More information about your legal rights can be found on the ICO’s website at <a href="https://ico.org.uk/for-the-public/">https://ico.org.uk/for-the-public/</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Other privacy rights
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You may also have additional rights depending on your location and place of residence. For example, if in California, you will have rights under the California Consumer Privacy Act. If you have any questions regarding this then please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Complaints
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>We hope you don’t have any reason to complain, and we will always try to resolve any issues you have, therefore we hope that you will contact us in the first instance at <a href="mailto: support@fluffa.com">support@fluffa.com</a>.  But you always have the right to make a complaint at any time to the ICO about how we deal with your personal information or your rights in relation to your personal information.  </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>You can make a complaint in writing to the ICO, Wycliffe House, Water Lane, Wilmslow, SK9 5AF, United Kingdom or you can go to <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a>.</div>
                            </div>
                            <div className="fl-privacy-section">
                                <div className="fl-privacy-section-title">
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    Contacting us
                                </div>
                                <div className={`fl-privacy-row fl-privacy-row-${userData.currentTheme}`}>If you have any queries regarding our use of your personal information or this privacy notice then please contact <a href="mailto: support@fluffa.com">support@fluffa.com</a>.</div>
                            </div>
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
