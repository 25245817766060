import React, { FC } from "react";
import { Button } from "../../../components/Button";
import moment from "moment";
import ReactLoading from "react-loading";

const ConfirmDiscountModal: FC<any> = ({
  data,
  starts_at,
  ends_at,
  amount,
  number,
  confirm,
  decline,
  animationDiscount
}) => {
  const confirmModalFields = [
    {
      text: "Starts",
      span: moment(starts_at).format("D MMMM, YYYY"),
    },
    { text: "Ends", span: moment(ends_at).format("D MMMM, YYYY") },
    {
      text: "Duration",
      span:
        moment.duration(moment(ends_at).diff(moment(starts_at))).asDays() > 1
          ? `${moment
              .duration(moment(ends_at).diff(moment(starts_at)))
              .asDays()
              .toFixed(0)} days`
          : `${moment
              .duration(moment(ends_at).diff(moment(starts_at)))
              .asDays()} day`,
    },
    { text: "Original price", span: `$${data?.price}` },
    { text: "Amount of discount", span: `${amount}%` },
    {
      text: "Discounted price",
      span: `$${(data?.price * (1 - amount / 100)).toFixed(2)}`,
    },
    { text: "Number of available discounts", span: Number(number) > 0 ? Number(number) : "UNLIMITED"},
  ];

  const confirmModalLine = (text: any, span: any, key: number) => {
    return (
      <div
        key={key}
        className="fl-settings-sub-plans-card-property"
        style={{ fontSize: "1rem" }}
      >
        {text}: <span>{span}</span>
      </div>
    );
  };

  return (
    <>
      <div className="fl-modal-title" style={{ flexWrap: "wrap" }}>
        Confirm discount
        <br />
        {moment(starts_at).format("D MMMM, YYYY") !==
          moment().format("D MMMM, YYYY") && (
          <p style={{ fontSize: ".9rem", width: "100%", margin: ".5rem 0 0" }}>
            Your discount will start on{" "}
            {moment(starts_at).format("D MMMM, YYYY")}
          </p>
        )}
      </div>
      <div className="fl-modal-description">
        <div className="fl-settings-sub-section">
          <div className="fl-settings-sub-section">
            {confirmModalFields.map((item: any, ind) =>
              confirmModalLine(item.text, item.span, ind)
            )}
          </div>
        </div>
      </div>
      <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
        <Button onClick={decline}>Back</Button>
        <Button onClick={() => confirm(ends_at)} type="normal" disabled={animationDiscount}>
          {moment(starts_at).format("D MMMM, YYYY") ===
          moment().format("D MMMM, YYYY")
            ? "Start now"
            : "Schedule discount"}
          {animationDiscount ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
        </Button>
      </div>
    </>
  );
};

export default ConfirmDiscountModal;
