import React, { FC, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faEllipsisH,
  faTimes,
  faSyncAlt,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

var localStream: MediaStream | null = null;

const Video: FC<any> = ({ message, setMessage }) => {
  const [cameraActive, setCameraActive] = useState(false);

  const cameraRef = useRef<HTMLVideoElement>(null);

  const requestCamera = async () => {
    setCameraActive(true);
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    localStream = stream;
    if (cameraRef.current) {
      cameraRef.current.srcObject = stream;
    }
  };

  const closeCamera = async () => {
    if (localStream) localStream.getTracks().forEach((x) => x.stop());
    setCameraActive(false);
  };

  const snapCamera = () => {
    if (!cameraRef.current) return;

    var canvas = document.createElement("canvas");
    canvas.width = cameraRef.current.offsetWidth;
    canvas.height = cameraRef.current.offsetHeight;
    var ctx = canvas.getContext("2d");

    //@ts-ignore
    if (ctx) {
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(cameraRef.current, 0, 0, canvas.width, canvas.height);
      ctx.restore();
    }
    var src = canvas.toDataURL("image/jpeg");
    var blobBin = atob(src.split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) array.push(blobBin.charCodeAt(i));
    var file = new File([new Uint8Array(array)], "snap.jpeg",{type: "image/jpeg"});

    setMessage({
      ...message,
      files: [...message.files, { type: "image", src, file }],
    });
    closeCamera();
  };

  return (
    <>
      <div
        className="fl-direct-messages-box-input-action-single"
        onClick={requestCamera}
      >
        <FontAwesomeIcon icon={faCamera} data-tip data-for="camera" />
        <ReactTooltip id="camera">Open camera</ReactTooltip>
      </div>

      {cameraActive ? (
        <div className="fl-messages-camera-main">
          <video ref={cameraRef} autoPlay muted></video>
          <div className="fl-messages-camera-controls">
            <div className="fl-messages-camera-controls-top">
              <div className="fl-messages-camera-controls-single">
                <FontAwesomeIcon icon={faEllipsisH} />
              </div>
              <div className="fl-messages-camera-controls-single">
                <FontAwesomeIcon icon={faTimes} onClick={closeCamera} />
              </div>
            </div>
            <div className="fl-messages-camera-controls-bottom">
              <div className="fl-messages-camera-controls-single">
                <FontAwesomeIcon icon={faSyncAlt} />
              </div>
              <div
                className="fl-messages-camera-controls-snap"
                onClick={snapCamera}
              ></div>
              <div className="fl-messages-camera-controls-single">
                <FontAwesomeIcon icon={faImage} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Video;
