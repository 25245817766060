import React, { FC } from 'react';
import "./Toggle.css";



export const Toggle:FC<{leftComponent: React.ReactNode, rightComponent: React.ReactNode, value: boolean, [key:string]:any}> = ({value, leftComponent, rightComponent, ...props}) => {

    return (
        <div className={`fl-toggle-main ${value ? 'fl-toggle-main-active' : ''}`} {...props}>
            <div className="fl-toggle-icons">
                <div className={`fl-toggle-icon ${value === false ? 'fl-toggle-icon-hide' : ''}`}>
                    {leftComponent}
                </div>
                <div className={`fl-toggle-icon ${value === true ? 'fl-toggle-icon-hide' : ''}`}>
                    {rightComponent}
                </div>
            </div>
           <div className={`fl-toggle-dot`}></div>
        </div>
    )
}