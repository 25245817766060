import React, {FC, Fragment, useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import "./Navbar.css";

// ASSETS
import logoSource from '../assets/images/fluffa-logo-text.png';
import { Button } from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignInAlt, faUserEdit } from '@fortawesome/free-solid-svg-icons';

interface NavbarProps {

}

const customRoutesNavbar = ['/','/preregister'];

export const Navbar:FC<NavbarProps> = () => {
    
    // CONTEXT
    const userData = useUserContext();
    // LOCATION
    const userLocation = useLocation();

    // STATE
    const [state, setState] = useState({
        scrollTop: 0,
        mobileNav: false
    })

    useEffect(() => {
        if(state.mobileNav && !document.body.classList.contains("fl-body-lock")){
            document.body.classList.add("fl-body-lock");
        } else if(!state.mobileNav && document.body.classList.contains("fl-body-lock")){
            document.body.classList.remove("fl-body-lock");
        }
    }, [state.mobileNav])

    useEffect(() => {
        // DEFINE
        const updateScrollTop = () => {
            setState({...state, scrollTop: window.scrollY});
        }

        // INITIAL
        updateScrollTop();

        // LISTENERS
        window.addEventListener("scroll", updateScrollTop);
        return () => {
            window.removeEventListener("scroll", updateScrollTop);
        }
    },[]);

    const openMenu = () => {
        const scrollWidth = window.innerWidth - document.body.clientWidth
        if(window.innerWidth > document.body.clientWidth) {
            document.body.classList.add("fl-body-lock-scroll");
            document.body.style.paddingRight = `${scrollWidth}px`
            userData.paddingBodyFunction(`${scrollWidth}`)
        }
        userData.toggleSidebar()
    }

    return (
        <>
            <div className={`fl-navbar-main fl-navbar-main-${userData.currentTheme} ${state.scrollTop > 80 ? `fl-navbar-main-sticky` : "" } ${customRoutesNavbar.includes(userLocation.pathname)  ? `fl-navbar-main-home` : ''} ${userData.mobilePost ? "fl-main-nav-not-visible" : "" }`} style={{ paddingRight: userData.paddingBody ? `${userData.paddingBody}px` : ""}}>
                <div className={`fl-container fl-navbar-inner`}>
                    <div className="fl-col-1 fl-navbar-logo-force-min">
                        <Link className="fl-navbar-logo" to={userData.authStatus === 'AUTHENTICATED' ? '/feed' : '/'}>
                            <img className="fl-navbar-logo-img" src={logoSource} alt="Fluffa"/>
                        </Link>
                    </div>
                    {userData.authStatus === "AUTHENTICATED" ? (
                        <Fragment>
                            {/* <div className="fl-col-6"></div> */}
                            <div className="fl-col-11 fl-d-flex fl-justify-flex-end fl-navbar-locations">
                                <Button onClick={() => openMenu()} className={`fl-navbar-button-mobile-hide fl-navbar-button-icon`} type={(!customRoutesNavbar.includes(userLocation.pathname) || (customRoutesNavbar.includes(userLocation.pathname) && state.scrollTop > 80)) ? "normal" : "outlined-white"}>
                                    <FontAwesomeIcon className="fl-navbar-button-icon fl-navbar-button-icon-show-always" icon={faBars}/>
                                </Button>
                            </div>
                        </Fragment>
                    ) : ""}
                    {userData.authStatus === "UNAUTHENTICATED" ? (
                        <Fragment>
                            {/* <div className="fl-col-6"></div> */}
                            <div className="fl-col-11 fl-d-flex fl-justify-flex-end fl-navbar-locations">
                                <Button to="/login" className={`fl-navbar-button-mobile-hide`} type={(!customRoutesNavbar.includes(userLocation.pathname) || (customRoutesNavbar.includes(userLocation.pathname) && state.scrollTop > 80)) ? "empty" : "empty-white"}>
                                    <FontAwesomeIcon className="fl-navbar-button-icon" icon={faSignInAlt}/>
                                    <span>Log In</span>
                                </Button>
                                <Button to="/register" className={`fl-navbar-button-mobile-hide`} type={(!customRoutesNavbar.includes(userLocation.pathname) || (customRoutesNavbar.includes(userLocation.pathname) && state.scrollTop > 80)) ? "normal" : "outlined-white"}>
                                    <FontAwesomeIcon className="fl-navbar-button-icon" icon={faUserEdit}/>
                                    <span>Sign Up</span>
                                </Button>
                            </div>
                        </Fragment>
                    ) : ""}
                </div>
            </div>
        </>
    )
}

