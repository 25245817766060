import React, { FC } from 'react'
import { Link } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './ComingSoon.css';

export const ComingSoon:FC<{src: string}> = ({src}) => {

    const userData = useUserContext();
    
    return (
        <div className="fl-fullscreen-with-footer fl-fullscreen-with-footer-swap fl-coming-soon-main">
            <div className="fl-fullscreen-with-footer-inner fl-container">
                <div className="fl-fullscreen-with-footer-bg">
                    <div className="fl-fullscreen-with-footer-circle-orange"></div>
                    <div className="fl-fullscreen-with-footer-circle-pink"></div>
                </div>
                <div className="fl-fullscreen-with-footer-content">
                    <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
                        <img className="fl-fullscreen-with-footer-content-image" src={src} alt="Login"/>
                    </div>
                    <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-coming-soon-content">
                        <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Coming soon...</div>
                        <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>Click <Link className="fl-not-found-redirection-link" to="/feed">here</Link> to go home.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}