import {
  faAlignJustify, faBorderAll,
  faIdBadge, faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Post } from "../components/Post";
import { SimpleCard } from "../components/SimpleCard";
import { FavoriteProfile } from "../components/UserProfile";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import postInterface from "../interfaces/PostInterface";
import userInterface from "../interfaces/UserInterface";
import "./Favorites.css";
import allPostsActive from "../assets/images/all-posts-active.svg";
import allPosts from "../assets/images/all-posts.svg";
import photoActive from "../assets/images/photo-active.svg";
import photo from "../assets/images/photo.svg";
import VideoActiveIcon from "../assets/images/video-active.svg";
import VideoIcon from "../assets/images/video.svg";
import {useModalContext} from "../context/ModalContext";
import ReactLoading from "react-loading";

export const Favorites: FC<any> = (props) => {

  const [state, setState] = useState<{
    posts: Array<postInterface>;
    currentPage: number;
    loadingState: boolean;
    lastPage: number;
    profileObject: userInterface | null;
  }>({
    posts: [],
    profileObject: null,
    currentPage: 1,
    loadingState: true,
    lastPage: 0,
  });

  // PARAMS
  const { subroute } = useParams<{ subroute: string }>();

  // CONTEXT
  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext()
  const stateRef = useRef<any>();

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const [typePost, setTypePost] = useState<any>({
    animation: false,
  });
  const typeRef = useRef<any>();
  useEffect(() => {
    typeRef.current = typePost;
  }, [typePost]);

  const getProfilePosts = async (page = 1,type:string) => {
    setState({ ...stateRef.current, loadingState: true });
    setTypePost({ ...typeRef.current, animation: true});
    try {
      const res = await Axios.get(
          `${envData.apiUrl}/favorites/${type}${page ? `?page=${page}` : ""}`
      );
      var { data, meta } = res.data;
      data = data.map((x: any) => ({ postVideos: [], postImages: [], ...x }));
      setState({
        ...stateRef.current,
        posts: page === 1 ? data : [...stateRef.current.posts, ...data],
        loadingState: false,
        lastPage: meta.last_page,
        currentPage: page,
      });
      setTypePost({ ...typeRef.current, animation: false });
    } catch (e) {
      modalData.pushToast('error', 'Something went wrong!');
    }
  };

  const checkForNewFeeds = async () => {
    if (stateRef.current.currentPage >= stateRef.current.lastPage) {
      window.removeEventListener("scroll", checkForNewFeeds);
      return;
    }

    var maxScroll =
        Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        ) - window.innerHeight;
    if (
        window.scrollY > maxScroll - window.innerHeight / 2 &&
        stateRef.current.loadingState === false
    ) {
      getProfilePosts(stateRef.current.currentPage + 1,subroute);
    }
  };

  useEffect(() => {
    getProfilePosts(1,subroute);
    window.addEventListener("scroll", checkForNewFeeds);
    return () => {
      window.removeEventListener("scroll", checkForNewFeeds);
    };
  }, []);

  const emptyFavorites = () => {
    return (
        <SimpleCard>
          <p className={"fl-tickets-empty"}>
            You've not favourited any {subroute === "all" ? "posts" : subroute === "texts" ? "text" : subroute } yet.
          </p>
        </SimpleCard>
    )
  }

  const postCount = () => {
    return state.posts.filter((x:any) => x.meta.is_favorited === true).length
  }

  const renderFavorites = () => {
    if(typePost.animation) {
      return (
          <SimpleCard>
            <div className={"fl-loader-animation"}>
              <ReactLoading
                  type={"spinningBubbles"}
                  height={50}
                  width={50}
                  className="fl-spinningBubbles"
              />
            </div>
          </SimpleCard>
      )
    } else if (subroute !== "profiles" && postCount() > 0) {
      return state.posts.filter((x:any) => x.meta.is_favorited === true).map((x: postInterface, y: number) => (
          <Post
              key={y}
              data={x}
              oldState={state}
              updatePost={setState}
              previousLocation="/favorites"
              typeFavorite={subroute}
          />
      ));
    } else if (subroute === "profiles" && postCount() > 0) {
      return state.posts.filter((x:any) => x.meta.is_favorited === true).map((x: any, y: number) => (
          <FavoriteProfile
              key={y}
              data={x}
              oldState={state}
              updatePost={setState}
              previousLocation="/favorites"
          />
      ));
    } else {
      return emptyFavorites()
    }
  };

  return (
      <div className={`fl-profile-main fl-favorites fl-profile-main-${userData.currentTheme}`}>
        <div className="fl-container">
          <div className="fl-d-flex fl-feed-row">
            <div className="fl-col-12 fl-feed-column fl-favorites-tablet">
              <div className={`fl-profile-sort`} >
                <Link
                    to={"/favorites/profiles"}
                    className={`fl-profile-sort-single ${subroute === "profiles" ? "fl-profile-sort-single-active" : ""}`}
                >
                  <div className={"fl-profile-count-icons fl-profile-count-icons-posts"}>
                    <FontAwesomeIcon
                        icon={faIdBadge}
                        className="fl-profile-sort-single-icon"
                    />
                  </div>
                  <span className={"fl-profile-sort-single-icon-count"} >
                    PROFILES <br/>({userData.user.profile.meta.total_favorites.profiles})
                  </span>
                </Link>
                <Link
                    to={"/favorites/all"}
                    className={`fl-profile-sort-single ${subroute === "all" ? "fl-profile-sort-single-active" : ""}`}
                >
                  <div className={"fl-profile-count-icons fl-profile-count-icons-posts"}>
                    <img
                        src={typePost.type === "all" ? allPostsActive : allPosts}
                        className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                        alt={`favorites`}
                    />
                  </div>
                  <span className={"fl-profile-sort-single-icon-count"} >
                    ALL POSTS  <br/>({userData.user.profile.meta.total_favorites.all})
                  </span>
                </Link>
                <Link
                    to={"/favorites/videos"}
                    className={`fl-profile-sort-single ${subroute === "videos" ? "fl-profile-sort-single-active" : ""}`}
                >
                  <div className={"fl-profile-count-icons"}>
                    <img
                        alt="schedule_icon"
                        src={typePost.type === "videos" && userData.authStatus === 'AUTHENTICATED' ? VideoActiveIcon : VideoIcon}
                        className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                    />
                  </div>
                  <span className={"fl-profile-sort-single-icon-count"} >
                    VIDEOS <br/>({userData.user.profile.meta.total_favorites.videos})
                  </span>
                </Link>
                <Link
                    to={"/favorites/photos"}
                    className={`fl-profile-sort-single ${subroute === "photos" ? "fl-profile-sort-single-active" : ""}`}
                >
                  <div className={"fl-profile-count-icons fl-profile-count-icons-posts"}>
                    <img
                        src={typePost.type === "photos" ? photoActive : photo}
                        className={`fl-profile-sort-single-icon fl-profile-sort-single-image ${userData.currentTheme === "light" ? "" : "invert-image-color"}`}
                        alt={`fluffa`}
                    />
                  </div>
                  <span className={"fl-profile-sort-single-icon-count"} >
                    PHOTOS <br/>({userData.user.profile.meta.total_favorites.photos})
                  </span>
                </Link>
                <Link
                    to={"/favorites/texts"}
                    className={`fl-profile-sort-single ${subroute === "texts" ? "fl-profile-sort-single-active" : ""}`}
                >
                  <div className={"fl-profile-count-icons fl-profile-count-icons-posts"}>
                    <FontAwesomeIcon
                        icon={faAlignJustify}
                        className="fl-profile-sort-single-icon"
                    />
                  </div>
                  <span className={"fl-profile-sort-single-icon-count"} >
                    TEXT <br/>({userData.user.profile.meta.total_favorites.texts})
                  </span>
                </Link>
                {subroute !== "texts" &&
                  <div
                      onClick={() => userData.changeGridView()}
                      className={`fl-profile-sort-single ${subroute === "videos" ? "fl-profile-sort-single-active" : ""}`}
                  >
                    <div className={"fl-profile-count-icons"}>
                      <FontAwesomeIcon icon={userData.gridView ? faWindowMaximize : faBorderAll} />
                    </div>
                    <span className={"fl-profile-sort-single-icon-count"} >
                      {userData.gridView ? "Full" : "Grid" } View
                    </span>
                  </div>
                }
              </div>
              <div className={`fl-favorites-display-${userData.gridView ? "grid" : "full"}`}>
                {renderFavorites()}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
