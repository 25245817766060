import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faTimes,
  faUndo,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import ReactTooltip from "react-tooltip";
import "./MInput.css";

export const MInput: FC<{
  forceErrors?: boolean;
  validator?: Array<{ check: (v: any) => boolean; message: string }>;
  visiblepassword?: boolean;
  clear?: number;
  setClear?: (x: number) => any;
  preRender?: (x: any) => ReactNode;
  favoriteFilter?: Array<(x: any, y: number) => boolean>;
  options?: Array<any>;
  label: string;
  value: any;
  setValue: (v: any) => any;
  regex?:string;
  hideArrow?: any;
  [key: string]: any;
  refInput?:any;
}> = ({
  style,
  className,
  label,
  value,
  validatingMessage = "Field is not valid",
  validator,
  maxLength,
  setValue,
  regex,
  type,
  options,
  favoriteFilter,
  preRender,
  ref,
  forceErrors = false,
  hasInfo = false,
  infoId = "",
  infoText = "",
  revertToSelect,
  minIsSet,
  subsInput,
  hideArrow,
  refInput,
  visiblepassword=false,
  ...props
}) => {
  const [selectValue, setSelectValue] = useState<string>("");
  const [state, setState] = useState<{
    currentItem: number;
    activated: boolean;
  }>({
    activated: false,
    currentItem: -1,
  });

  const validatorTimeoutRef = useRef<any>(null);
  const [validatorState, setValidatorState] = useState({
    showMessage: false,
  });
  const validatorStateRef = useRef<any>(validatorState);
  useEffect(() => {
    validatorStateRef.current = validatorState;
  }, [validatorState]);

  const optionRefs: Array<HTMLDivElement | null> = [];

  // CONTEXT
  const userData = useUserContext();
  const envData = useEnvContext();

  const minputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof props.clear === "number" && props.setClear) {
      if (props.clear === 1) {
        clearValue();
        props.setClear(0);
      }
    }
  }, [props.clear]);

  const filteredProps = Object.keys(props)
    .filter(
      (key) =>
        !["className", "style", "value", "setValue", "label"].includes(key)
    )
    .reduce((obj: any, key: any) => {
      obj[key] = props[key];
      return obj;
    }, {});

  const pickValue = (x: { value: any; [key: string]: any }) => {
    setValue(x.value);
    setSelectValue(x.label);
    setState({ ...state, activated: false });
  };

  useEffect(() => {
    if (type === "select") {
      if (value) {
        setValue(value);
        if (options) {
          options?.forEach((e: any) => {
            if (
              [
                "Age",
                "All Nationalities",
                "All Countries",
                "All Cities",
                "All States",
              ].includes(e.category)
            ) {
              setSelectValue(
                e.options.find((a: any) => a.value === value).label
              );
            }

            if (!e.category) {
              try {
                setSelectValue(
                  e.options.find((a: any) => a.value === value).label
                );
              } catch (e) {}
            }
          });
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (type === "select") {
      if (options) {
        options?.forEach((e: any) => {
          if (["All Cities", "All States"].includes(e.category)) {
            setSelectValue(
              e.options.find((a: any) => a.value === value)?.label ?? ""
            );
          }
        });
      }
    }
  }, [options]);

  const clearValue = () => {
    setValue("");
    setSelectValue("");
    setState({ ...state, activated: false });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!options) return;

    const filteredOpts = options
      .map((a) =>
        a.options.filter(
          (x: any) =>
            Object.keys(x).filter((k) =>
              String(x[k]) !== null
                ? String(x[k]).toLowerCase().includes(selectValue.toLowerCase())
                : false
            ).length > 0
        )
      )
      .flat();
    if (e.key === "ArrowDown") {
      if (state.currentItem < filteredOpts.length - 1) {
        if (
          optionRefs[state.currentItem + 1]?.offsetTop &&
          dropdownRef.current
        ) {
          if (
            //@ts-ignore
            optionRefs[state.currentItem + 1].offsetTop >=
            dropdownRef.current.offsetHeight + dropdownRef.current.scrollTop
          ) {
            dropdownRef.current.scrollTo(
              0,
              //@ts-ignore
              optionRefs[state.currentItem + 1].offsetTop -
                dropdownRef.current.offsetHeight +
                //@ts-ignore
                optionRefs[state.currentItem + 1]?.offsetHeight
            );
          }
        }
        setState({ ...state, currentItem: state.currentItem + 1 });
      }
    } else if (e.key === "ArrowUp") {
      if (state.currentItem > -1) {
        if (
          optionRefs[state.currentItem - 1]?.offsetTop &&
          dropdownRef.current
        ) {
          if (
            //@ts-ignore
            optionRefs[state.currentItem - 1].offsetTop <=
            dropdownRef.current.scrollTop
          ) {
            dropdownRef.current.scrollTo(
              0,
              //@ts-ignore
              optionRefs[state.currentItem - 1].offsetTop
            );
          }
        }
        setState({ ...state, currentItem: state.currentItem - 1 });
      }
    } else if (e.key === "Enter" && state.currentItem !== -1) {
      setValue(filteredOpts[state.currentItem].value);
      setSelectValue(filteredOpts[state.currentItem].label);
      setState({ ...state, activated: false });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    setState({
      ...state,
      activated: e.key !== "Enter" ? true : state.activated,
      currentItem: e.key !== "Enter" ? -1 : state.currentItem,
    });
  };

  useEffect(() => {
    //@ts-ignore
    if (!minputRef.current?.contains(envData.clickedElement)) {
      setState({ ...state, activated: false });
    }
  }, [envData.clickedElement]);

  const getCurrentOptionIndex = (rowIdx: number, optionIdx: number) => {
    return options
      ? options
          .filter((x: any, y: number) => y < rowIdx)
          .map((x: any, y: number) => x.options)
          .filter(
            (a) =>
              Object.keys(a).filter((k) =>
                String(a[k]) !== null
                  ? String(a[k])
                      .toLowerCase()
                      .includes(selectValue.toLowerCase())
                  : false
              ).length > 0
          )
          .flat(2).length + optionIdx
      : 0;
  };

  const [showType, setShowType] = useState<null | "text">(null);
  const toggleShow = () => {
    setShowType(showType ? null : "text");
  };

  const inputType =
    typeof visiblepassword === "boolean"
      ? showType
        ? showType
        : type === "select"
        ? "text"
        : type
      : type === "select"
      ? "text"
      : type;

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {

    if (validator) {
      if (validatorTimeoutRef.current) {
        clearTimeout(validatorTimeoutRef.current);
      }
      setValidatorState({ ...validatorStateRef.current, showMessage: false });
      let value = e.currentTarget.value || "";
      validatorTimeoutRef.current = setTimeout(() => {
        validator.forEach((x) => {
          if (!x.check(value)) {
            setValidatorState({
              ...validatorStateRef.current,
              showMessage: true,
            });
          }
        });
      }, 1000);
    }
    type === "select"
      ? setSelectValue(e.currentTarget.value)
      : (regex === "word" && /^[a-zA-Z\s]*$/.test(e.currentTarget.value[e.currentTarget.value.length - 1]) === false) || (regex === "number" && (/[0-9]/.test(e.currentTarget.value[e.currentTarget.value.length - 1])) === false) ?
        setValue(e.currentTarget.value.slice(0, -1)) : setValue(e.currentTarget.value)
  };

  const pasteFunction = (e:any) => {
    if(( e.clipboardData.getData("text").match(/^[a-zA-Z\s]*$/) === null && regex === "word") || (e.clipboardData.getData("text").match(/[0-9]/) === null && regex === "number")) {
      e.preventDefault()
    } else  {

    }
  }

  const showErrors = () => {
    if (validator)
      validator.forEach((x) => {
        if (!x.check(value)) {
          setValidatorState({
            ...validatorStateRef.current,
            showMessage: true,
          });
        }
      });
  };

  const inputEl = useRef<any>(null);

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.style.height = "100px";
      const scrollHeight = inputEl.current.scrollHeight;
      inputEl.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <div
      className={`fl-minput-main ${
        (validatorState.showMessage || forceErrors) &&
        validator?.find((x) => x.check(value) === false)
          ? "fl-minput-main-not-valid"
          : ""
      } ${className} ${state.activated ? "fl-minput-main-expand" : ""}`}
      style={style}
      ref={minputRef}
    >
      <div className="fl-minput-input-outer">
        {type === "textarea" ? (
          <textarea
            ref={inputEl}
            onBlur={showErrors}
            onClick={() => ""}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            maxLength={maxLength}
            className={`fl-minput-input-inner-${
              userData.currentTheme
            } fl-minput-textarea-inner ${
              (validatorState.showMessage || forceErrors) &&
              validator?.find((x) => x.check(value) === false)
                ? "fl-minput-not-valid"
                : ""
            } ${
              type === "select" ? "fl-minput-input-inner-select" : ""
            } fl-minput-input-inner-${userData.currentTheme}`}
            onFocus={() => setState({ ...state, activated: true })}
            onChange={handleChange}
            value={type === "select" ? selectValue : value}
            {...filteredProps}
          />
        ) : (
          <input
            onPaste={(e:any) => pasteFunction(e)}
            ref={refInput}
            maxLength={maxLength}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            type={inputType}
            min={
              (inputType === "date" || inputType === "number") && minIsSet
                ? minIsSet
                : ""
            }
            readOnly={
              label === "Age" ||
              label === "Discount amount %" ||
              label === "Number of discounted subscriptions:" ||
              label === "Discount duration" ||
              label === "Set your price ($)" ||
              label === "Set new price ($)" ||
              (label === "Number of free trials" && !hasInfo) ||
              (label === "Length of trial" && !hasInfo) ||
              (label === "Expire trial offer after" && !hasInfo)
            }
            className={`fl-minput-input-inner ${
              (validatorState.showMessage || forceErrors) &&
              validator?.find((x) => x.check(value) === false)
                ? "fl-minput-not-valid"
                : ""
            } ${
              type === "select" ? "fl-minput-input-inner-select" : ""
            } fl-minput-input-inner-${userData.currentTheme}`}
            style={{
              paddingRight: inputType === "number" && hasInfo ? "2rem" : "",
            }}
            onFocus={() => setState({ ...state, activated: true })}
            onKeyDownCapture={(e) => {
              if (type === "number") {
                if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8) {
                  return;
                } else {
                }
              }
            }}
            onChange={handleChange}
            value={type === "select" ? selectValue : value}
            {...filteredProps}
          />
        )}
        {type === "select" ? (
          value ? (
            <div
              className={`fl-minput-input-clear fl-minput-input-clear-${userData.currentTheme}`}
              onClick={clearValue}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : !hideArrow ? (
            <div
              className={`fl-minput-input-clear fl-minput-input-clear-${userData.currentTheme}`}
              onClick={() => setState({ ...state, activated: true })}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {type === "number" && hasInfo && revertToSelect ? (
          <div
            className={`fl-minput-input-clear fl-minput-input-clear-${userData.currentTheme}`}
            onClick={() => {
              revertToSelect();
              clearValue();
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </div>
        ) : (
          ""
        )}
        {type === "date" && revertToSelect ? (
          <div
            className={`fl-minput-input-clear fl-minput-input-clear-${userData.currentTheme}`}
            onClick={() => {
              revertToSelect();
              clearValue();
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </div>
        ) : (
          ""
        )}
        {visiblepassword ? (
          <div
            className={`fl-minput-input-clear fl-minput-input-clear-${userData.currentTheme}`}
            onClick={toggleShow}
          >
            <FontAwesomeIcon icon={showType ? faEye : faEyeSlash} />
          </div>
        ) : (
          ""
        )}
        <div
          style={{ overflow: hasInfo && "visible" }}
          className={`fl-minput-label ${
            String(type === "select" ? selectValue : value).trim() !== ""
              ? "fl-minput-label-active"
              : ""
          }`}
        >
          {hasInfo && (
            <>
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-tip
                data-for={infoId}
                style={{ pointerEvents: hasInfo && "all" }}
              />
              <ReactTooltip place="right" id={infoId}>
                {infoText.map((e: any) => (
                  <>
                    {e}
                    <br />
                  </>
                ))}
              </ReactTooltip>
            </>
          )}{" "}
          {label}
        </div>
        {state.activated ||
        String(type === "select" ? selectValue : value).trim() !== "" ? (
          <div className={"fl-minput-label-length"} style={{ float: "right" }}>
            {maxLength ? `${value.length}/${maxLength}` : ""}
          </div>
        ) : (
          ""
        )}
        {type === "select" && options?.length ? (
          <div
            className={`fl-minput-select-dropdown fl-minput-select-dropdown-${userData.currentTheme}`}
            ref={dropdownRef}
            style={{ maxHeight: subsInput ? "fit-content" : "" }}
          >
            <div className="fl-minput-select-options">
              {options.map(
                (x: { category: string; options: Array<any> }, y: number) => (
                  <Fragment key={y}>
                    {x.options.filter(
                      (a) =>
                        Object.keys(a).filter((k) =>
                          String(a[k]) !== null
                            ? String(a[k])
                                .toLowerCase()
                                .includes(selectValue.toLowerCase())
                            : false
                        ).length > 0
                    ).length ? (
                        <>
                          {x.category ?
                              <div className="fl-minput-select-options-title">
                                {x.category}
                              </div> : ""
                          }
                        </>
                    ) : (
                      ""
                    )}
                    {x.options.filter((a) =>
                          Object.keys(a).filter((k) =>
                            String(a[k]) !== null
                              ? a.value !== value
                              : false
                          ).length > 0
                      )
                      .map((a, b) => (
                        <div
                          className={`fl-minput-select-option fl-minput-select-option-${
                            userData.currentTheme
                          } ${
                            state.currentItem === getCurrentOptionIndex(y, b)
                              ? "fl-minput-select-option-active"
                              : ""
                          }`}
                          ref={(ref) =>
                            (optionRefs[getCurrentOptionIndex(y, b)] = ref)
                          }
                          onClick={() => pickValue(a)}
                          key={b}
                        >
                          {preRender ? preRender(a) : ""} {a.label}
                        </div>
                      ))}
                  </Fragment>
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {validator &&
        validator.find((x) => x.check(value) === false) &&
        (validatorState.showMessage || forceErrors) ? (
          <div className="fl-minput-validator">
            <FontAwesomeIcon icon={faExclamationCircle} />{" "}
            {validator.find((x) => x.check(value) === false)?.message}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
