import React, {FC, useEffect, useState} from "react";
import { useUserContext } from "../../../context/UserContext";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components/Button";

import { MInput } from "../../../components/MInput";

import SelectWithNumberInput from "../Common/SelectWithNumberInput";
import axios from "axios";
import {useEnvContext} from "../../../context/EnvContext";

const CreateSubscriptionPlanTrial: FC<any> = ({
                                             clear,
                                             setClear,
                                             state,
                                             setState,
                                             createSubscriptionPlan,
                                             customTrialExpire,
                                             setCustomTrialExpire,
                                             restrictedWords,
                                             selectedPlan,
                                             setSelectedPlan,
                                             setRestrictedWords
                                         }) => {
    const userData = useUserContext();
    const envData = useEnvContext();

    const countTrial = () => {
        if(state.plans.filter((x:any) => x.type === "trial").length < 1) {
            return true
        } else {
            return false
        }
    }

    const disableCreateTrialButton = () => {
        if(state.number_trial !== "" && state.length_trial !== "" && state.expire_trial !== "" && restrictedWords.length === 0) {
            if(state.length_trial < 3  && state.priceTrial === "") {
                return false
            } else if (state.priceTrial > 0 ) {
                return  false
            } else {
                return true
            }
        } else {
            return true
        }
    };

    const [customTrialNumber, setCustomTrialNumber] = useState<any>(false);

    const [customTrialLength, setCustomTrialLength] = useState<any>(false);

    const checkIfAllAreCreated = () => {
        let options = [
            { value: "1", label: "1 day" },
            { value: "3", label: "3 days (recommended)" },
            { value: "7", label: "7 days" },
            { value: "14", label: "14 days" },
            { value: "30", label: "30 days" },
            // { value: "60", label: "60 days" },
            // { value: "90", label: "90 days" },
            // { value: "180", label: "180 days" },
            // { value: "365", label: "365 days" },
            // { value: "custom", label: "Choose a different duration" },
        ];

        if (state.plans) {
            state.plans.map((e: any) => {
                if (
                    options.findIndex((x: any) => x.value === e.billing_period_count) ||
                    options.findIndex((x: any) => x.value === e.billing_period_count) === 0
                ) {
                    if ([1, 3, 7, 14, 30, 60, 90, 180, 365].includes(e.billing_period_count) && e.type === "trial") {
                        options.splice(
                            options.findIndex((x: any) => x.value === e.billing_period_count),
                            1
                        );
                    }
                }
            });
        }

        return options;
    };

    const checkRestrictedWord = async () => {
        try {
            await axios.post(
                `${envData.apiUrl}/restricted-words/check`,{
                    "text": state.description
                })
            setRestrictedWords([])
        } catch (error) {
            if (error?.response?.data?.errors?.restricted_words?.length > 0) {
                setRestrictedWords(error?.response?.data?.errors?.restricted_words)
            }
        }
    };
    useEffect(() => {
        checkRestrictedWord()
    }, [state.description])

    const [customDiscountPrice, setCustomDiscountPrice] = useState<boolean>(false);

    return (
        <div className={`fl-settings-sub-section fl-settings-sub-section-${userData.currentTheme}`}>
            {!countTrial() ?
            <p className={`fl-settings-sub-section-p-${userData.currentTheme}`}>Note: You can only run one free trial at a time. To create a new free trial now you'll need to end the current one (this won't affect anyone currently on a free trial), or wait until the current one ends before creating a new one.</p>
            :
                <>
                    <div
                        className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
                    >
                        Create free trial
                    </div>
                    <p>Note: Free trials lasting 3 days or more will automatically convert to a 1 Month (30 days) paid plan. 1 day free trials do not automatically convert.</p>
                    <div className={"fl-settings-mt-30"}>
                        <SelectWithNumberInput
                            stateName="number_of_trial"
                            state={state}
                            setState={setState}
                            inputTypeSwitched={customTrialNumber}
                            inputTypeSwitch={setCustomTrialNumber}
                            labelForSelect={"Number of free trials"}
                            labelForNumber={"Number of free trials"}
                            value={state.number_trial}
                            clear={clear}
                            setClear={setClear}
                            options={[
                                { value: "0", label: "Unlimited"},
                                { value: "1", label: "1" },
                                { value: "3", label: "3" },
                                { value: "5", label: "5" },
                                { value: "10", label: "10" },
                                { value: "20", label: "20" },
                                { value: "50", label: "50" },
                                { value: "100", label: "100" },
                                { value: "200", label: "200" },
                                { value: "custom", label: "Other amount" },
                            ]}
                            min="1"
                            hasInfo={true}
                            infoId="number_trial"
                            infoText={["Choose any number between 1 and 9,999,999"]}
                        />
                    </div>
                    <div className={"fl-settings-mt-30"}>
                        <SelectWithNumberInput
                            stateName="length_trial"
                            state={state}
                            setState={setState}
                            inputTypeSwitched={customTrialLength}
                            inputTypeSwitch={setCustomTrialLength}
                            labelForSelect={"Length of trial"}
                            labelForNumber={"Length of trial"}
                            value={state.length_trial}
                            clear={clear}
                            setClear={setClear}
                            options={checkIfAllAreCreated()}
                            min="1"
                            hasInfo={true}
                            infoId="length_trial"
                            infoText={["You can set the length of a trial from 1 to 30 days."]}
                        />
                    </div>
                    <div className={"fl-settings-mt-30"}>
                        <SelectWithNumberInput
                            stateName="expire_trial"
                            state={state}
                            setState={setState}
                            inputTypeSwitchedDate={customTrialExpire}
                            inputTypeSwitchDate={setCustomTrialExpire}
                            labelForSelect={"Expire trial offer after"}
                            labelForNumber={"Expire trial offer after"}
                            value={state.expire_trial}
                            clear={clear}
                            setClear={setClear}
                            options={[
                                { value: "0", label: "Never expire" },
                                { value: "1", label: "1 day" },
                                { value: "3", label: "3 days" },
                                { value: "7", label: "7 days" },
                                { value: "14", label: "14 days" },
                                { value: "30", label: "30 days" },
                                { value: "60", label: "60 days" },
                                { value: "90", label: "90 days" },
                                { value: "180", label: "180 days" },
                                { value: "365", label: "365 days" },
                                { value: "date", label: "Choose an expiry date" },
                            ]}
                        />
                    </div>
                    <div className={"fl-settings-mt-30"}>
                        <MInput
                            label="Add message (optional)"
                            type="text"
                            value={state.description}
                            setValue={description => setState({...state, description})}
                            maxLength={55}
                        />
                        {restrictedWords.length > 0 && (
                            <span className="fl-modal-description-row-error">
                            <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {restrictedWords.join(',')}
                        </span>
                        )}
                    </div>
                    {state.length_trial > 2 ?
                        <div className={"fl-settings-mt-30"}>
                            <span>When your free trial period ends it will automatically convert to a 1 Month (30 days) paid plan. Please set your price below:</span>
                            <div  className={" fl-settings-mt-30"}>
                                <SelectWithNumberInput
                                    stateName="priceTrial"
                                    inputTypeSwitched={customDiscountPrice}
                                    inputTypeSwitch={setCustomDiscountPrice}
                                    state={state}
                                    setState={setState}
                                    clear={clear}
                                    setClear={setClear}
                                    labelForSelect="Set your price ($)"
                                    labelForNumber="Set your price"
                                    value={state.priceTrial}
                                    options={[
                                        { label: "$4.99", value: "4.99" },
                                        { label: "$14.99", value: "14.99" },
                                        { label: "$19.99", value: "19.99" },
                                        { label: "$24.99", value: "24.99" },
                                        { label: "$29.99", value: "29.99" },
                                        { label: "$39.99", value: "39.99" },
                                        { label: "$49.99", value: "49.99" },
                                        { label: "Other amount", value: "custom" },
                                    ]}
                                    min="5"
                                    hasInfo={true}
                                    infoId="set-your-price"
                                    infoText={["You can set a price from $5 to $100"]}
                                />
                            </div>
                        </div>
                    :""
                    }
                    <Button
                        type="normal"
                        className="fl-settings-sub-security-button fl-settings-mt-30"
                        disabled={disableCreateTrialButton()}
                        onClick={createSubscriptionPlan}
                    >
                        Create free trial
                    </Button>
                </>
            }
        </div>
    );
};

export default CreateSubscriptionPlanTrial;


