import React, {FC} from "react";
import "./Support.css";
import "../Privacy.css";
import {SupportNav} from "./SupportNav";
import {SimpleCard} from "../../components/SimpleCard";
import {useUserContext} from "../../context/UserContext";

export const SupportHome: FC<any> = (props) => {
    const userData = useUserContext()
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    <SupportNav />
                    {!isMobile ?
                        <div className="fl-col-9 fl-support-col fl-text-align-center">
                            <SimpleCard>
                                <h3>Please select an option from the left.</h3>
                            </SimpleCard>
                        </div> : ""
                    }
                </div>
            </div>
        </div>
    );
};
