import React, {FC} from 'react'
import {useParams} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {SimpleCard} from "../components/SimpleCard";
import {useUserContext} from "../context/UserContext";
import {useHistory} from "react-router-dom";
import {SettingsEarnings} from "./SettingsEarnings";
import {SettingsEarningsStatistics} from "./SettingsEarningsStatistics";


export const SettingsEarningsPage:FC<({} )> = (props) => {

    const { location } = useParams<{ location: string }>();
    const userData = useUserContext()
    const history = useHistory()

    const changePage = (page:string) => {
        history.push(`/earnings/${page}`)
    }

    return (
        <div
            className={`fl-drive-main fl-settings-main fl-settings-main-${userData.currentTheme} fl-notifications-drive-${userData.currentTheme}`}
        >
            <div className="fl-container">
                <div className="fl-d-flex fl-feed-row">
                    <div className="fl-col-3 fl-feed-column fl-earnings-page">
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option ${location === "all" && "fl-drive-menu-active" }`} onClick={() => changePage("all")}>
                                <span>
                                    Earnings
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option ${location === "statistics" && "fl-drive-menu-active" }`} onClick={() => changePage("statistics")}>
                                <span>
                                    Earning Statistics
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option`} onClick={() => history.push(`/settings/payouts/`)}>
                                <span>
                                   Payouts
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                    </div>
                    <div className="fl-col-9 fl-feed-column fl-drive-tablet-show">
                        <SimpleCard>
                            {location === "all" ?
                                <SettingsEarnings /> : <SettingsEarningsStatistics />
                            }
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    )
}