import React, {FC, useEffect, useState} from "react";
import { Button } from "../../../components/Button";
import { useUserContext } from "../../../context/UserContext";
import { useModalContext } from "../../../context/ModalContext";
import moment, {defaultFormat} from "moment";
import {MInput} from "../../../components/MInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {useEnvContext} from "../../../context/EnvContext";
import ReactLoading from "react-loading";
import axios from "axios";

const ListTrialPlans: FC<any> = ({
                                removeSubscriptionPlan,
                                plans,
                                type,
                                setState,
                                state,
                                scrollRef
                            }) => {
    const userData = useUserContext();
    const modalData = useModalContext();
    const envData = useEnvContext();

    const description = () => {
        return plans[0]?.description
    }

    const [edit,setEdit] = useState(false)
    const [message,setMessage] = useState("")
    const [restrictedWords, setRestrictedWords] = useState<any>([])
    const [animation,setAnimation] = useState(false)

    const checkRestrictedWord = async () => {
        try {
            await axios.post(
                `${envData.apiUrl}/restricted-words/check`,{
                    "text": message
                })
            setRestrictedWords([])
        } catch (error) {
            if (error?.response?.data?.errors?.restricted_words?.length > 0) {
                setRestrictedWords(error?.response?.data?.errors?.restricted_words)
            }
        }
    };
    useEffect(() => {
        checkRestrictedWord()
    }, [message])

    const disableCreateTrialButton = () => {
        if(restrictedWords.length === 0 && !animation) {
           return false
        } else {
            return true
        }
    };

    const updateMessage = async (id:any) => {
        setAnimation(true)
        try {
            const data = {
                type:  plans[0]?.type,
                billing_period:  plans[0]?.billing_period,
                expires_at:  plans[0]?.ends_at,
                billing_period_count:  plans[0]?.billing_period_count,
                limit:  plans[0]?.limit,
                price: Number(0),
                upgrade_price:  plans[0]?.upgrade_price,
                description: message
            }
            await Axios.put(`${envData.apiUrl}/subscription-plans/${id}`,JSON.parse(JSON.stringify(data)));
            setState({...state, plans: state.plans.map((x:any) => x.id === id ? {...x, description: message } : x)})
            modalData.pushToast("success", `Your message was ${description() ? "updated" : "added" }.`);
            setEdit(false)
            setMessage("")
            setAnimation(false)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimation(false)
        }
    }

    return (
      <div ref={scrollRef}>
          {description() &&
            <div className={"fl-settings-sub-plans-message"}>
                <p className={`fl-settings-sub-plans-message-p-${userData.currentTheme}`}>Your message:</p>
                <div className={"fl-message-bubble-main fl-message-bubble-main-left"}>
                    <div
                        className={`fl-message-bubble-message  fl-message-bubble-message-${userData.currentTheme}`}
                    >
                        <div className={`fl-message-bubble-message-text`}>
                            {description()}
                        </div>
                    </div>
                </div>
            </div>
          }
          <div
              className={`fl-settings-sub-plans-all-${userData.currentTheme}`}
              style={{ alignItems: "start" }}
          >
              {plans.length > 0 ? (
                  plans
                      .sort((a: any, b: any) =>
                          a.billing_period_count > b.billing_period_count
                              ? 1
                              : b.billing_period_count > a.billing_period_count
                                  ? -1
                                  : 0
                      ).sort((a: any, b: any) =>
                      a.type > b.type
                          ? 1
                          :  b.type > a.type
                              ? -1
                              : 0
                  )
                      .map((x: any, y: number) => {
                          return  (
                              <div
                                  className={`fl-settings-sub-plans-card fl-settings-sub-plans-card-${userData.currentTheme}`}
                                  key={y}
                              >
                                  <div
                                      className={`fl-settings-sub-plans-card-billing-period fl-settings-sub-plans-card-billing-period-${userData.currentTheme}`}
                                  >
                                      {x.billing_period === "month"  ?
                                          <>
                                              {x.billing_period_count}{" "}
                                              {x.billing_period_count !== 1 ? "months" : "month"}
                                          </>
                                          :
                                          <>
                                              {x.billing_period_count}{" "} DAY TRIAL
                                          </>
                                      }
                                  </div>
                                  <div className={`fl-settings-sub-plans-card-property`}>
                                      Start{moment(x.expires_at).format(defaultFormat) > moment(new Date()).format(defaultFormat) ? "s" : "ed"} : {" "}
                                      <span> {moment(x.created_at).format("D MMMM, YYYY")} </span>
                                  </div>
                                  <div className={`fl-settings-sub-plans-card-property`}>
                                      End{moment(x.expires_at).format(defaultFormat) > moment(new Date()).format(defaultFormat) ? "s" : "ed"} : {" "}
                                      <span> {x.expires_at !== null ? moment(x.expires_at).format("D MMMM, YYYY") : "NEVER" } </span>
                                  </div>
                                  <div className={`fl-settings-sub-plans-card-property`}>
                                      {moment(x.expires_at).format(defaultFormat) > moment(new Date()).format(defaultFormat) ? "Duration" : " Trial Ran For"} : {" "}
                                      <span> {x.billing_period_count} day{x.billing_period_count > 1 && "s"}</span>
                                  </div>
                                  {moment(x.expires_at).format(defaultFormat) > moment(new Date()).format(defaultFormat) &&
                                  <div className={`fl-settings-sub-plans-card-property`}>
                                      Created on:
                                      <span> {moment(x.created_at).format("D MMMM, YYYY")} </span>
                                  </div>
                                  }
                                  {x.upgrade_price ?
                                      <div className="fl-settings-sub-plans-card-property">
                                          Convert{moment(x.expires_at).format(defaultFormat) > moment(new Date()).format(defaultFormat) ? "s" : "ed"} to:
                                          <span>${x.upgrade_price}/ month</span>
                                      </div> : ''
                                  }
                                  <div className={`fl-settings-sub-plans-card-property`}>
                                      Available:{" "}
                                      <span> {x.limit === null ? "UNLIMITED" : x.limit}</span>
                                  </div>
                                  <div className={`fl-settings-sub-plans-card-property`}>
                                      Claimed:{" "}
                                      <span>{x.claimed}</span>
                                  </div>
                                  {type !== "completed" ?
                                      <div style={{ display: "flex" }}>
                                          <Button
                                              type="outlined"
                                              className="fl-settings-sub-security-button"
                                              style={{ width: "100%" }}
                                              onClick={() => {setEdit(true);setMessage(x.description || "")}}
                                          >
                                              {description() ? "Edit" : "Add"} Message
                                          </Button>
                                      </div> : ""
                                  }
                                  {type !== "completed" &&
                                  <div style={{ display: "flex" }}>
                                      <Button
                                          type="outlined"
                                          className="fl-settings-sub-security-button"
                                          style={{ width: "100%" }}
                                          onClick={() =>
                                              removeSubscriptionPlan(x.discounted_plan, x.id,x.type)
                                          }
                                      >
                                          End Trial
                                      </Button>
                                  </div>
                                  }
                              </div>
                          )
                      })
              ) : (
                  <p>
                      {type === "completed" ?
                          "Completed free trials will appear here."
                          :
                          "You haven't created any free trials yet. Please create a free trial by following the steps below."
                      }
                  </p>
              )}
          </div>
          {edit ?
              <div className={"fl-settings-mt-30"}>
                  <MInput
                      label="Add message (optional)"
                      type="text"
                      value={message}
                      setValue={description => setMessage(description)}
                      maxLength={55}
                  />
                  {restrictedWords.length > 0 && (
                      <span className="fl-modal-description-row-error">
                            <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {restrictedWords.join(',')}
                      </span>
                  )}
                  <div className={"fl-d-flex"}>
                      <Button
                          type="outlined"
                          className="fl-settings-sub-security-button fl-settings-mt-30"
                          onClick={() => {setEdit(false);setMessage("")}}
                      >
                          Close
                      </Button>
                      <Button
                          type="normal"
                          className="fl-settings-sub-security-button fl-settings-mt-30 fl-ml-5"
                          disabled={disableCreateTrialButton()}
                          onClick={() => updateMessage(plans[0]?.id)}
                      >
                          {description() ? "Update" : "Add"} message
                          {animation && <ReactLoading  className="fl-spinningBubbles" type={"spinningBubbles"} color={"#fff"} height={20} width={20}/>}
                      </Button>
                  </div>
              </div> : ""
          }
      </div>
    );
};

export default ListTrialPlans;

