import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import ReactLoading from "react-loading";

export const TwitterCallback: FC<any> = (props) => {
    const params = new URLSearchParams(useLocation().search)
    const history = useHistory();
    const envData = useEnvContext();
    const userData = useUserContext();

    const [state, setState] = useState({
        taken: false,
    })

     useEffect(() => {
        axios.get(`${envData.apiUrl}/auth/login/twitter/callback?user=${params.get('user')}&oauth_token=${params.get('oauth_token')}&oauth_verifier=${params.get('oauth_verifier')}`)
        .then(async (response: any) => {
            userData.setToken(response.data.access_token);
             let status = await userData.checkAuth();
             if(status === 'AUTHENTICATED') {
                 history.push("/feed");
             }
         }).catch((err => {
             if(err.response.data.twitter === 'This twitter account is taken') {
                 setState({...state, taken: true});
                 setTimeout(() => {
                     history.push("/settings");
                 }, 2000);
             }
         }));
     },[]);
    return (
    <div style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
        <p style={{margin: '40px', fontSize: 'x-large', fontWeight: 'bold'}}>
            {!state.taken ? 'Please wait a few moments, you will be redirected shortly.' :
            "This twitter account is already associated with another person, you will be redirected to settings shortly."}
        </p>
        <div style={{margin: '40px'}}>
            <ReactLoading type={"spinningBubbles"} color={"#ff0d72"} height={200} width={200}/>
        </div>
    </div>);
}