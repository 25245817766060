import * as React from "react";
//@ts-ignore
import videojs from "video.js";
import './Video.css'
// Styles
import "video.js/dist/video-js.css";
import {FC, useEffect, useRef} from "react";
require("videojs-hls-quality-selector");
require("videojs-contrib-quality-levels");

const VideoPlayer: FC<any> = ({...props}) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const src = props.src;
    const thumbnail = props.thumbnail;
    const videoDuration = props.duration;
    useEffect(() => {
        if (videoRef.current) {
            const video = videoRef.current;

            playerRef.current = videojs(video, {
                autoplay: false,
                controls: true,
                preload: "none",
                userActions: {
                    hotkeys: true
                },
                controlBar: {
                    pictureInPictureToggle: true,
                },
                html5: {
                    vhs: {
                        overrideNative: true,
                    }
                },
                poster: thumbnail,
                sources: [{ src }],
                errorDisplay: false,
            });

            // @ts-ignore
            playerRef.current.hlsQualitySelector({
                displayCurrentQuality: true,
            });

            // playerRef.current.chromecast();
            // playerRef.current.airPlay();

            // @ts-ignore
            playerRef.current.on("play", () => {
                props.setPlaying(true)
                // @ts-ignore
                playerRef.current.bigPlayButton.hide();
            });

            // @ts-ignore
            playerRef.current.on("pause", () => {
                // @ts-ignore
                playerRef.current.bigPlayButton.show();
            });
        }

        return () => {
            if (playerRef.current) {
                // @ts-ignore
                playerRef.current.dispose();
            }
        };
    }, [videoRef]);

    const convertDuration = (duration = videoDuration) => {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret
    }

    return <div className={"fl-smooth-video"}><video ref={videoRef} className="video smooth-image img-visible video-js vjs-big-play-centered" />{!props.playing ? <span className={"fl-smooth-video-duration"}>{convertDuration()}</span> : ""}</div>;
}

export default VideoPlayer;
