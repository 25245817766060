import React, {FC, useState} from "react";
import ReactLoading from "react-loading";
import {useEnvContext} from "../context/EnvContext";
import axios from "axios";
import {Button} from "./Button";
import {useModalContext} from "../context/ModalContext";

export const UserProfileRestrictBlock: FC<any> = (props) => {

    const modalData = useModalContext()
    const envData = useEnvContext()

    const [animation,setAnimation] = useState(false)
    const restricted = props.data.meta.restricted_by_me
    const blocked = props.data.meta.is_blocked

    const blockUser = async () => {
        setAnimation(true)
        try {
            await axios.post(
                `${envData.apiUrl}/profile/${props.data.handle}/${blocked ? "unblock" : "block"}`
            );
            props.setUpdateState({...props.updateState, profileObject: {...props.updateState.profileObject, meta: {...props.updateState.profileObject.meta, is_blocked:blocked ? false : true, restricted_by_me: blocked ? false : props.updateState.profileObject.meta.restricted_by_me }}})
            modalData.close();
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    const restrictUser = async () => {
        setAnimation(true)
        try {
            await axios.post(
                `${envData.apiUrl}/messaging/${props.data.handle}/${restricted ? "unblock" : "block"}`
            );
            props.setUpdateState({...props.updateState, profileObject: {...props.updateState.profileObject, meta: {...props.updateState.profileObject.meta, restricted_by_me:restricted ? false : true}}})
            modalData.close();
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{props.action === "restrict" ? <>{restricted ? "Unrestrict" : "Restrict"}</> : <>{blocked ? "Unblock" : "Block"}</>} user</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                Are you sure you want to {props.action === "restrict" ? <>{restricted ? "unrestrict" : "restrict"}</> : <>{blocked ? "unblock" : "block"}</>} this user?
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end fl-settings-mt-30">
                <Button onClick={modalData.close}>No</Button>
                <Button type="normal" onClick={() => props.action === "restrict" ? restrictUser() : blockUser() } disabled={animation}>
                    Yes
                    {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </React.Fragment>
    );
}