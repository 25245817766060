import React, { FC } from "react";

import { MInput } from "../../../components/MInput";
import {DatePicker} from "../../../components/DatePicker";
import moment from "moment";
import {useModalContext} from "../../../context/ModalContext";

const SelectWithNumberInput: FC<any> = ({
                                          stateName,
                                          inputTypeSwitched,
                                          inputTypeSwitch,
                                          inputTypeSwitchedDate,
                                          inputTypeSwitchDate,
                                          state,
                                          setState,
                                          labelForSelect,
                                          labelForNumber,
                                          value,
                                          options,
                                          min,
                                          hasInfo,
                                          infoId,
                                          infoText,
                                          clear,
                                          setClear,
                                        }) => {
    const modalData = useModalContext();

    const pickDob = async (x: any) => {
        // const date = moment(x).format("D MMMM, YYYY")
        setState({
            ...state,
            expire_trial: moment(x).format("D MMMM, YYYY"),
        });
        await modalData.closeAll();
    };

  return (
      <>
        {inputTypeSwitched  === false ? (
            <MInput
                type="select"
                label={labelForSelect}
                value={value}
                clear={clear}
                setClear={setClear}
                subsInput={true}
                setValue={(data) => {
                  if (data === "custom") {
                    inputTypeSwitch(true);
                  }  else {
                    stateName === "priceTrial" && setState({ ...state, priceTrial: data });
                    stateName === "price" && setState({ ...state, price: data });
                    stateName === "number_of_discounts" &&
                    setState({ ...state, number_of_discounts: data });
                    stateName === "duration" &&
                    setState({ ...state, duration: data });
                    stateName === "number_of_trial" &&
                    setState({ ...state, number_trial: data });
                    stateName === "expire_trial" &&
                    setState({ ...state, expire_trial: data });
                    stateName === "length_trial" &&
                    setState({ ...state, length_trial: data });
                  }
                }}
                options={[
                  {
                    options,
                  },
                ]}
            />
        ) : inputTypeSwitched  === true ? (
            <MInput
                min={min}
                type="number"
                clear={clear}
                setClear={setClear}
                label={labelForNumber}
                hasInfo={hasInfo}
                infoId={infoId}
                infoText={infoText}
                revertToSelect={() => {
                  inputTypeSwitch(!inputTypeSwitched);
                  stateName === "price" && setState({ ...state, price: null });
                  stateName === "priceTrial" && setState({ ...state, priceTrial: null });
                  stateName === "number_of_discounts" &&
                  setState({ ...state, number_of_discounts: null });
                  stateName === "duration" && setState({ ...state, duration: null });
                  stateName === "expire_trial" && setState({ ...state, expire_trial: null });
                  stateName === "length_trial" && setState({ ...state, length_trial: null });
                }}
                value={value}
                setValue={(data) => {
                  if (stateName === "number_of_discounts") {
                    if (data > -1 && data <= 1000000) {
                      setState({ ...state, number_of_discounts: data });
                    }
                  }
                  if (stateName === "price") {
                    if (data < 101 && data > -1) {
                      setState({ ...state, price: data });
                    }
                  }
                    if (stateName === "priceTrial") {
                        if (data < 101 && data > -1) {
                            setState({ ...state, priceTrial: data });
                        }
                    }
                  if (stateName === "duration") {
                    if (data > -1 && data <= 5000) {
                      setState({
                        ...state,
                        duration: data,
                      });
                    }
                  }
                  if (stateName === "number_of_trial") {
                      if (data > -1 && data <= 9999999) {
                          setState({
                              ...state,
                              number_trial: data,
                          });
                      }
                  }
                  if (stateName === "expire_trial") {
                            setState({
                                ...state,
                                expire_trial: data,
                            });
                 }
                 if (stateName === "length_trial") {
                        setState({
                            ...state,
                            length_trial: data,
                        });
                 }
                }}
            />
        ): ("")}
          {inputTypeSwitchedDate === false  ? (
              <MInput
                  type="select"
                  label={labelForSelect}
                  value={value}
                  clear={clear}
                  setClear={setClear}
                  subsInput={true}
                  setValue={(data) => {
                      if (data === "date") {
                          inputTypeSwitchDate(true);
                      }  else {
                          stateName === "expire_trial" &&
                          setState({ ...state, expire_trial: data });
                      }
                  }}
                  options={[
                      {
                          options,
                      },
                  ]}
              />
          ) : inputTypeSwitchedDate === true ? (
              <>
                  {/*<MInput*/}
                  {/*    min={min}*/}
                  {/*    type="date"*/}
                  {/*    clear={clear}*/}
                  {/*    setClear={setClear}*/}
                  {/*    label={labelForNumber}*/}
                  {/*    minIsSet={new Date().toISOString().split("T")[0]}*/}
                  {/*    revertToSelect={() => {*/}
                  {/*        inputTypeSwitchDate(!inputTypeSwitchedDate);*/}
                  {/*        stateName === "expire_trial" && setState({ ...state, expire_trial: null });*/}
                  {/*    }}*/}
                  {/*    value={state.expire_trial || new Date()}*/}
                  {/*    setValue={(data) => {*/}
                  {/*        if (*/}
                  {/*            new Date() < new Date(data) ||*/}
                  {/*            new Date().toISOString().split("T")[0] === data*/}
                  {/*        ) {*/}
                  {/*            setState({*/}
                  {/*                ...state,*/}
                  {/*                expire_trial: data,*/}
                  {/*            });*/}
                  {/*        }*/}
                  {/*    }}*/}
                  {/*/>*/}
                  <DatePicker
                      label="DD/MM/YYYY"
                      value={state.expire_trial}
                      onSave={pickDob}
                      className="fl-settings-sub-account-field-input fl-plans-datepicker"
                      inputTypeSwitchedDate={inputTypeSwitchedDate}
                      inputTypeSwitchDate={inputTypeSwitchDate}
                      state={state}
                      setState={setState}
                      min={new Date()}
                  />
              </>
          ) : ("")}
      </>
  );
}

export default SelectWithNumberInput;

