import React, { FC, useEffect, useState } from "react";
import { useEnvContext } from "../context/EnvContext";
import { useUserContext } from "../context/UserContext";
import { MInput } from "../components/MInput";
import validator from "validator";
import { Button } from "../components/Button";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import lightImage from '../assets/images/girl-at-a-canyon.jpg';
import darkImage from '../assets/images/pineapple.jpg';
import './PasswordReset.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheck, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";

export const PasswordReset: FC<any> = (props) => {

    const history = useHistory();

    const [state, setState] = useState({
        email: (new URLSearchParams(useLocation().search)).get('user_email') ?? '',
        password: '',
        password_confirmation: '',
        token: (new URLSearchParams(useLocation().search)).get('token') ?? '',
    });

    const [passReset, setPassReset] = useState(false);

    const [forceErrors, setForceErrors] = useState(false);
    // const [setCaptchaToken] = useState<any>('');
    const [tokenValid, setTokenValid] = useState(true);

    const [errors, setErrors] = useState<any>({
        password: '',
        password_confirmation: '',
        server_state: '',
    });
    const [showPassword] = useState({password: false, confirmation: false});
    const [loading, setLoading] = useState(false);

    const userData = useUserContext();
    const envData = useEnvContext();
    //
    // const recaptcha = useCallback(
    //     (token) => {
    //       setCaptchaToken(token);
    //     },
    //     [],
    // );

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          //add here
        }
      };


    const arePasswordsTheSame = (v: string) =>  {
        return state.password === v;
    }

    const sendData = (event: Event) => {
               setLoading(true);
        setTimeout(() => {
            axios.post(envData.apiUrl + '/auth/password/reset', state).then((response) => {
                setPassReset(true);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                if(err.response.status === 403) {
                    setForceErrors(true);
                    setErrors({...errors, password: `You are not authorized to change password on ${state.email ?? 'this'} account.`});
                } else {
                    let errResult = err.response.data.errors;
                    setForceErrors(true);
                    setErrors({...errors, password: errResult.password ? errResult.password[0] : errResult.error[0]})
                }
            });
            setLoading(false);
        }, 500);
    }

    const disableBtn = () => {
       if(state.password.length === state.password_confirmation.length && state.password.length > 0 && state.password_confirmation.length > 0 && !loading) {
           return false
       } else {
           return true
       }
    }

    const [waitError, setWaitError] = useState<string>("")

    const sendNewRequest = async () => {
        setLoading(true);
        await axios.post(`${envData.apiUrl}/auth/password/forgotten`, {email: state.email}).then(response => {
            history.push('/forgot-password?email=' + state.email);
        }).catch(err => {
            if(err.response.data.errors?.error[0]?.includes("Please wait before retrying.")) {
                setWaitError("Please wait a few minutes before trying to reset your password again.")
            }
            if(err.response.data.errors?.email?.includes('The selected email is invalid.')) {
                setForceErrors(true);
                setErrors({...errors, server_state: 'The email in the link is invalid'});
            } else if(err.response.data.errors?.error[0]?.includes('wait')) {
                setForceErrors(true);
                setErrors({...errors, server_state: 'Please wait a few minutes before trying to reset your password again.'});
            }
        });
        setLoading(false);
    }

    // useEffect(() => {
    //     axios.post(`${envData.apiUrl}/auth/password/reset/check`, {...state}).then(response => {
    //         if(response.data.results) {
    //             setTokenValid(true);
    //         } else {
    //             setTokenValid(false);
    //             setForceErrors(true);
    //         }
    //     }).catch(err => {
    //         modalData.pushToast('error', 'Something went wrong!');
    //     });
    // },[]);

    useEffect(() => {
        setErrors({
            password: '',
            password_confirmation: '',
        });
    }, [state.password, state.password_confirmation]);

    const buttonChoice = () => {
        if(errors.password.includes('expired') || !tokenValid ) {
            return (
            <>
                <Button onClick={sendNewRequest} style={{ margin: "10px 0px", width: "calc(50% - 5px)" }} type="normal">
                    SEND NEW LINK
                    {loading && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                </Button>
            </>
            )
        } else {
        return (
        <Button
            style={{ margin: "20px 0px", width: "calc(50% - 5px)" }}
            type="normal"
            onClick={sendData}
            disabled={disableBtn()}
        >
                RESET PASSWORD
                {loading && <span><ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/></span>}
        </Button>)
        }
    }

    const goBack = () => {
        if(userData.authStatus === 'AUTHENTICATED') {
            history.goBack()
        } else {
            history.push('/')
        }
    }

    return (
        <div className="fl-fullscreen-with-footer"> 
            <div className="fl-fullscreen-with-footer-inner fl-container">
                <div className="fl-fullscreen-with-footer-bg">
                <div className="fl-fullscreen-with-footer-circle-orange"></div>
                <div className="fl-fullscreen-with-footer-circle-pink"></div>
            </div>
            <div className="fl-fullscreen-with-footer-content">
                <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
                {passReset ? 
                <>
                <div className='fl-flex-centered-column'>
                    <div className={`fl-flex-centered-column fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}>
                        <FontAwesomeIcon style={{color: "#53A451"}} icon={faCheck}/>
                        <span>{userData.authStatus === 'AUTHENTICATED' ? 'All set!' : 'All done!'}</span>
                    </div>
                    <div className="fl-forgot-mb">Your password has been updated. {userData.authStatus !== 'AUTHENTICATED' &&' You can now log in with your new password.'}</div>
                        <Button className='fl-fullscreen-with-footer-content-button' style={{margin: "10px 0px", width: "100%"}} type="normal" to={userData.authStatus !== 'AUTHENTICATED' ? '/login' : '/'}>
                            <span style={{paddingTop: '3px'}}>{userData.authStatus !== 'AUTHENTICATED' ? 'CONTINUE TO LOG IN' : 'DONE'}</span>
                            <FontAwesomeIcon icon={faArrowRight} style={{height: '100%'}} className='fl-fullscreen-with-footer-content-button-icon'/>
                        </Button>   
                    </div>
                </>
                
                :<>
                    <div
                    className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}
                    >
                        Reset your password
                    </div>
                    <div className="fl-fullscreen-with-footer-content-form">
                        {tokenValid ?
                            <>
                                <MInput
                                    className={"fl-settings-mt-30"}
                                    forceErrors={forceErrors}
                                    visiblepassword={showPassword.password ? true : false}
                                    validator={[
                                        {
                                            check: (v) => !tokenValid || !validator.isEmpty(v),
                                            message: "Enter a password",
                                        },
                                        {
                                            check: (v) => !tokenValid ||
                                                validator.isStrongPassword(v, {
                                                    minLength: 8,
                                                    minLowercase: 1,
                                                    minNumbers: 1,
                                                    minUppercase: 1,
                                                    minSymbols: 0,
                                                }),
                                            message:
                                                "Must be at least 8 characters long, with one uppercase letter, one lowercase letter and a number.",
                                        },
                                    ]}
                                    onKeyPress={handleKeyPress}
                                    type="password"
                                    label="Enter new password"
                                    value={state.password}
                                    setValue={(password: string) => {setState({ ...state, password })}}
                                />
                                <MInput
                                    className={"fl-settings-mt-30"}
                                    forceErrors={forceErrors}
                                    visiblepassword={showPassword.confirmation ? true : false}
                                    validator={[
                                        {
                                            check: (v) => {return !errors.server_state},
                                            message: errors.server_state,
                                        },
                                        {
                                            check: (v) => {return tokenValid},
                                            message: 'Your password reset link has expired. Please request a new link and try again.',
                                        },
                                        {
                                            check: (v) => !validator.isEmpty(v),
                                            message: "You need to retype your password.",
                                        },
                                        {
                                            check: (v) => arePasswordsTheSame(v),
                                            message: 'Passwords don\'t match.',
                                        },

                                        {
                                            check: (v) => {return !errors.password},
                                            message: errors.password,
                                        },

                                    ]}
                                    onKeyPress={handleKeyPress}
                                    type="password"
                                    label="Confirm new password"
                                    value={state.password_confirmation}
                                    setValue={(password_confirmation) => setState({ ...state, password_confirmation })}
                                />
                            </>
                            :
                            <div className="fl-expire-mb">
                                <span>
                                     <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                                {waitError.length > 0 ? waitError : " Your password reset link has expired. Please request a new link and try again." }
                            </div>
                        }
                        {buttonChoice()}
                        {tokenValid &&
                        <div className="fl-fullscreen-with-footer-content-link-text fl-text-underline">
                            <p className="fl-fullscreen-with-footer-content-link" onClick={() => goBack()}>
                                Cancel
                            </p>
                        </div>
                        }
                    </div>
                </>
                }
                </div>
                <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-fulscreen-with-footer-content-part-image">
                {userData.currentTheme === "light" ? (
                  <img
                      className="fl-fullscreen-with-footer-content-image"
                      src={lightImage}
                      alt="A pineapple in a hand over silky water"
                  />
                 ) : (
                  ""
                )}
                {userData.currentTheme === "dark" ? (
                  <img
                      className="fl-fullscreen-with-footer-content-image"
                      src={darkImage}
                      alt="A fit girl looking at a canyon"
                  />
              ) : (
                  ""
              )}
                </div>
             </div>
            </div>
        </div>
    )
}
