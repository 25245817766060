import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react'
import { Link } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './NotFound.css';
import {faBoxTissue} from "@fortawesome/free-solid-svg-icons";
import {SimpleCard} from "../components/SimpleCard";

export const NotFound:FC<any> = (props) => {

    const userData = useUserContext();
    
    return (
       <SimpleCard>
           <div className="fl-fullscreen-with-footer fl-fullscreen-with-height-fix">
               <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                   <FontAwesomeIcon className="fl-not-found-icon" icon={faBoxTissue}/>
                   <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>404. That's an error.</div>
                   <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>The page you're looking for can't be found. Click <Link className="fl-not-found-redirection-link" to="/feed">here</Link> to go home.</div>
               </div>
           </div>
       </SimpleCard>
    )
}
