import React, {FC, useEffect, useRef, useState} from 'react'
import { useUserContext } from '../../context/UserContext';
import './Drive.css';
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {SimpleCard} from "../../components/SimpleCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModalImage} from "./ModalImage";
import {
    faArrowDown,
    faArrowUp,
    faBorderAll,
    faCheck,
    faChevronCircleLeft,
    faEllipsisH,
    faExpand,
    faInfoCircle,
    faMousePointer,
    faShare,
    faSlidersH,
    faTag,
    faTimes,
    faTrashAlt,
    faWindowMaximize
} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../components/Button";
import {useModalContext} from "../../context/ModalContext";
import {LabelModal} from "./LabelModal";
import ReactTooltip from "react-tooltip";
import {useHistory} from "react-router-dom";
import {ListTags} from "./ListTags";
import {DriveTable} from "./DriveTable";
import ReactLoading from "react-loading";
import {RemovedPosts} from "./RemovedPosts";
import {useParams} from "react-router";
//@ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import VideoPlayer from "../../components/video/VideoPlayer";

export const DriveFiles:FC<({setCountArchive?:any,countArchive?:any,selectedFiles:any,removeSelected:any,addSelected:any,type:any,setSelectedFiles:any,route:any,countFiles:any,setCountRemovedPosts:any,setLoad:any,load:any} )> = (props) => {

    const { location } = useParams<{ location: string; sort: string}>();

    const envData = useEnvContext();
    const userData = useUserContext();
    const modalData = useModalContext();
    const history = useHistory();

    const stateRef = useRef<any>();
    const stateRefFilter = useRef<any>();
    const [attachments, setAttachments] = useState<any> ({
        files: [],
        empty: false,
        page: 0,
        loadingState: true,
        lastPage:false
    })

    useEffect(() => {
        stateRef.current = attachments;
    }, [attachments]);

    const [filterOptions, setFilterOptions] = useState<any>({
        type: "all",
        sort:"",
        label:0
    })

    useEffect(() => {
        stateRefFilter.current = filterOptions;
    }, [filterOptions]);

    const selectedRefFiles = useRef<any>();
    useEffect(() => {
        selectedRefFiles.current = props.selectedFiles;
    }, [props.selectedFiles]);

    const getAttachments = async  (page = 1,type:string ,filter:boolean,labels:number,sort:string,load:boolean, select="") => {
        if(filter) {
            setAttachments({ ...stateRef.current, loadingState: true,files:[],empty:false,last_page:false });
        } else {
            setAttachments({ ...stateRef.current, loadingState: true });
        }

        try {
            const res = await Axios.get(
                //@ts-ignore
                `${envData.apiUrl}/attachments?page=${page}&type=${type}${labels === 0 ? " " : `&labels[]=${labels}`}${sort === "created_at" ? `&order=created_at&direction=desc`: ""}${sort === "created_at_asc" ? `&order=created_at&direction=asc`: ""}${sort === "size" ? `&order=filesize&direction=desc`: ""}${sort === "size_asc" ? `&order=filesize&direction=asc`: ""}`
            );
            const data = res.data.data
            if(data.length > 0) {
                setAttachments({
                    ...stateRef.current,
                    files: [...stateRef.current.files, ...data],
                    empty: data.length > 0 ? 1 : 0,
                    page: page,
                    loadingState: false,
                    last_page: false
                });
                if(select === "all") {
                    let allSelected = data.map((x:any) => {
                        return x
                    })
                    props.setSelectedFiles({
                        ...selectedRefFiles.current,
                        files: [...selectedRefFiles.current.files, ...allSelected],
                        type:"all"
                    });
                }
            }else if ((data.length === 0 && filter) || (data.length === 0 && load)) {
                setAttachments({
                    ...stateRef.current,
                    empty: 0,
                    last_page: true
                });
            } else {
                setAttachments({
                    ...stateRef.current,
                    last_page: true,
                });
            }
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const selectType = (type:string) => {
        setFilterOptions({...stateRefFilter.current,type:type})
        getAttachments(1,type,true,0,"",false)
    }
    const selectSort = (type:string) => {
        if(type === "default") {
            setFilterOptions({ ...stateRefFilter.current, type:"all",sort:"",label:0 });
            setOpenTag(false)
            getAttachments(1,"all",true,0,"",false)
        } else if (type === "tags") {
            setFilterOptions({ ...stateRefFilter.current, type:"all",sort:type,label:0 });
        } else {
            setFilterOptions({...stateRefFilter.current,sort:type})
            getAttachments(1,"all",true,0,type,false)
        }
        setExpandFilter(false)
    }
    const selectLabelSort = (e:any,label:number) => {
        e.stopPropagation()
        setFilterOptions({...stateRefFilter.current,label:label})
        getAttachments(1,"all",true,label,"",false)
    }

    const [openTag,setOpenTag] = useState<any>(false)

    const selectLabelSortSingle = (e:any,label:number) => {
        e.stopPropagation()
        setOpenTag(true)
        setFilterOptions({...stateRefFilter.current,label:label})
        getAttachments(1,"all",true,label,"",false)
    }

    useEffect(() => {
        (async () => {
            await getAttachments(1,stateRefFilter.current.type, false,0,"",true);
        })();
    }, []);

    const checkForNewFeeds = async () => {
        var maxScroll =
            Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            ) - window.innerHeight;

        if (
            window.scrollY > maxScroll - window.innerHeight / 2  &&
            stateRef.current.loadingState === false
        ) {
            getAttachments(stateRef.current.page + 1,stateRefFilter.current.type,false,stateRefFilter.current.label,stateRefFilter.current.sort,false,selectedRefFiles?.current?.type);
        }
    };

    useEffect(() => {
        if(props.load) {
            getAttachments(stateRef.current.page + 1,stateRefFilter.current.type,false,stateRefFilter.current.label,stateRefFilter.current.sort,false);
            props.setLoad(false)
        }
    }, [props.load]);

    useEffect(() => {
        window.addEventListener("scroll", checkForNewFeeds);
        return () => {
            window.removeEventListener("scroll", checkForNewFeeds);
        };
    }, []);

    const deleteFile = async (e:any,id:number) => {
        e.stopPropagation()
        modalData.close()
        setImageDropdown(false)
        await modalData.push(() => (
            <OpenDeleteModal id={id} attachments={attachments} setAttachments={setAttachments} selected={props.selectedFiles} setEnableDelete={setEnableDelete} setSelectedFiles={props.setSelectedFiles} selectedFiles={props.selectedFiles}/>
        ));
    }

    const updateFile = async (e:any,id:number,labels:any,url:string, index:number) => {
        e.stopPropagation()
        modalData.close()
        setImageDropdown(false)
        await modalData.push(() => <LabelModal labelExample={labelExample} setLabelExample={setLabelExample} id={id} attachments={attachments} setAttachments={setAttachments} labels={labels} url={url} flModalImage={flModalImage} index={index} keyboardEventDrive={keyboardEventDrive}/>);
    }

    const [gridView, setGridView] = useState<boolean>(false)

    const humanFileSize = (bytes:any, si=false, dp=1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return "-";
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    const [expandFilter, setExpandFilter] = useState<boolean>(false)

    const convertDuration = (x:any) => {
        const duration =  x.duration

        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        if (ret === "0:00") {
            return "-"
        }else {
            return ret
        }
    }

    const [labelExample, setLabelExample] = useState({
        labels: []
    })


    const getLabel = async () => {
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/labels`
            );
            setLabelExample({...labelExample, labels:res.data.data})
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        getLabel()
    }, []);

    const [enableDelete,setEnableDelete] = useState<boolean>(false)

    const enableDeleteFunction = () => {
        setEnableDelete(!enableDelete)
        setExpandFilter(false)
        if(props.selectedFiles.files.length > 0 && enableDelete) {
            props.setSelectedFiles({
                ...props.selectedFiles,
                files: [],
            });
        }
    }

    const checkSelectStatus = () => {
        if(enableDelete || props.type === "message") {
            return true
        } else {
            return false
        }
    }


    const deleteLabel = async (e:any,label:any,idFile:any) => {
        e.stopPropagation()
        try {
            await Axios.delete(
                `${envData.apiUrl}/labels/${label.id}/attachment/${idFile}`
            );
            let updatedLabel = attachments.files.map((e: any) => {
                e.id === idFile ? (e.labels = e.labels.filter((x:any) => x.label.id !== label.id)) : (e.labels = e.labels);
                return e;
            });
            //@ts-ignore
            setAttachments({ ...attachments, files: updatedLabel });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const shareFile = (type:string,file:any,e:any) => {
        e.stopPropagation()
        modalData.close()
        if(type === "mass") {
            userData.driveFiles(props.selectedFiles.files)
        }
        if(type === "single") {
            userData.driveFiles([file])
        }
        history.push("/message-create")
    }


    const keyboardEventDrive = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            modalData.close()
        }
    }

    const [imageDropdown, setImageDropdown] = useState<boolean> (false)
    const [optionId, setOptionId] = useState<number>(0)
    const imageOption = (e:any,id:any) => {
        e.stopPropagation()
        setOptionId(id)
        setImageDropdown(!imageDropdown)
    }

    const showMoreTags = (e:any,labels:any,id:number, url:string, index:number) => {
        e.stopPropagation()
        modalData.push(() =>  <ListTags labels={labels} id={id} url={url} index={index} updateFile={updateFile} deleteLabel={deleteLabel} />);
    }

    const selectAllFiles = () => {
        let allSelected = attachments?.files?.map((x:any) => {
            return x
        })
        props.setSelectedFiles({
            ...props.selectedFiles,
            files: allSelected,
            type:"all",
            count: userData.user.profile.meta.attachments_in_drive
        });
    }

    const removeAllFiles = () => {
        props.setSelectedFiles({
            ...props.selectedFiles,
            files: [],
            type:"",
            count: 0
        });
    }

    const checkAllSelected = () => {
        if(props.selectedFiles.files.length === attachments?.files?.length) {
            return true
        } else {
            return false
        }
    }

    const flModalImage = async (index:any) => {
        await modalData.push(() => <ModalImage files={attachments} deleteFile={deleteFile} updateFile={updateFile} shareFile={shareFile}  currentIndex={index}  />)
    };

    const [deletePost, setDeletePost] = useState<boolean>(false)

    const [state, setState] = useState<any>({
        posts: [],
        empty: false,
        page: 0,
        loadingState: true,
        lastPage:false
    });

    const stateRefPosts = useRef<any>();
    useEffect(() => {
        stateRefPosts.current = state;
    }, [state]);

    const [selectedPosts, setSelectedPosts] = useState<any> ({
        posts: [],
        type:"",
        count: 0
    })

    const selectedRefPosts = useRef<any>();
    useEffect(() => {
        selectedRefPosts.current = selectedPosts;
    }, [selectedPosts]);

    const getPosts = async  (page = 1, select="") => {
        setState({ ...stateRefPosts.current, loadingState: true});
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/posts/drive/all?page=${page}`
            );

            props.setCountRemovedPosts(res.data.data.length)
            const data = res.data.data
            if(data.length > 0) {
                setState({
                    ...stateRefPosts.current,
                    posts: [...stateRefPosts.current.posts, ...data],
                    empty: res.data.data.length > 0 ? 1 : 0,
                    loadingState:false,
                    page: page,
                    last_page:false
                });
                if(select === "all") {
                    let allSelected = data.map((x:any) => {
                        return x
                    })
                    setSelectedPosts({
                        ...selectedRefPosts.current,
                        posts: [...selectedRefPosts.current.posts, ...allSelected],
                        type:"all"
                    });
                }
            } else if (data.length === 0 && stateRefPosts.current.posts.length > 0) {
                setState({
                    ...stateRefPosts.current,
                    last_page: true,
                });
            } else {
                setState({
                    ...stateRefPosts.current,
                    last_page: true,
                    empty: 0
                });
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        // if(location == "posts") {
        (async () => {
            await getPosts(1);
        })();
        // }
    }, [location]);

    const checkForNewPosts = async () => {

        var maxScroll =
            Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            ) - window.innerHeight;
        if (
            window.scrollY > maxScroll - window.innerHeight / 2 &&
            stateRef.current.loadingState === false
        ) {
            getPosts(stateRefPosts.current.page + 1,selectedRefPosts?.current?.type);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", checkForNewPosts);
        return () => {
            window.removeEventListener("scroll", checkForNewPosts);
        };
    }, []);

    const selectAllPosts = () => {
        let allSelected = state?.posts?.map((x:any) => {
            return x
        })
        setSelectedPosts({
            ...selectedRefPosts.current,
            posts: allSelected,
            type:"all",
            count: props.countArchive
        });
    }

    const deselectAllPosts = () => {
        setSelectedPosts({
            ...selectedPosts,
            posts: [],
            type:"",
            count: 0
        });
    }

    const checkAllSelectedPosts = () => {
        if(selectedPosts?.posts?.length === state?.posts?.length) {
            return true
        } else {
            return false
        }
    }

    const sharePost = async () => {
        userData.driveFiles(selectedPosts.posts)
        history.push("/message-create")
    }

    const deleteArrayPost = async () => {
        const ids = selectedPosts.posts.map((e:any) => e.id)
        const targets = selectedPosts?.posts.map((e: any) => e);
        try {
            await Axios.delete(`${envData.apiUrl}/posts/drive/delete`, {
                data: { post_ids: ids},
            });
            props.setCountArchive(props.countArchive - ids.length)
            setState({
                ...state,
                posts: [...state.posts.filter((x: any) => !targets.includes(x))],
            });
            setSelectedPosts({
                ...selectedPosts,
                posts: [],
            });
            modalData.close()
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const actionDeletePost = async () => {
        await modalData.push({
            title: `Delete forever`,
            description: [
                `Your Post${selectedPosts.posts.length > 1 ? "s" : ""} will be deleted forever and you won't be able to restore ${selectedPosts.posts.length > 1 ? "them" : "it"}.`,
            ],
            buttons: [
                () => (
                    <div className="fl-d-flex fl-justify-flex-end">
                        <Button
                            onClick={async () =>
                                await modalData.close()
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            type="normal"
                            onClick={() =>
                                deleteArrayPost()
                            }
                        >
                            Delete forever
                        </Button>
                    </div>
                ),
            ],
        })
    }

    const addSelected = (post:any) => {
        let newSelectedPost = [...selectedPosts.posts, post];
        setSelectedPosts({
            ...selectedPosts,
            posts: newSelectedPost,
            count: selectedPosts.count + 1
        });
    }

    const removeSelected = (id: any) => {
        let newSelectedPost = selectedPosts.posts.filter(
            (x: any) => x.id !== id
        );

        setSelectedPosts({
            ...selectedPosts,
            posts: newSelectedPost,
            count: selectedPosts.count - 1
        });

    };

    const checkLocation = () => {
        if (location === "files" || props.route === "files") {
            return "files"
        }
        if(location === "posts" || props.route === "files") {
            return "posts"
        }
    }

    const countImageFiles = async () => {
        try {
             await Axios.get(
                `${envData.apiUrl}/attachments/count?type=image`
            );
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const countVideoFiles = async () => {
        try {
             await Axios.get(
                `${envData.apiUrl}/attachments/count?type=video`
            );
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        (async () => {
            await countImageFiles()
            await countVideoFiles()
        })();
    }, []);

    const backToProfile = () => {
        if(checkLocation() === "files") {
            history.push(`/settings`)
        } else {
            history.push(`/drive/files`)
        }
    }

    const checkedLabelName = () => {
        const name = labelExample?.labels?.filter((x:any) => filterOptions.label === x.id)[0]
        if(name) {
            // @ts-ignore
            return  name.name
        }
    }
    const [playing,setPlaying] = useState(false)

    const openImage = (type:string, index:number) => {
        if(type !== "video") {
            modalData.toggleImageDm(
                attachments.files,
                index
            )
        }
    }

    return (
        <>
            <div className="fl-drive-sub-section-title">
                <div
                    className={`fl-drive-tittle fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme} ${location === "posts" && "fl-drive-grid-view"}`}
                >
                   <span className="fl-direct-messages-title-icon fl-drive-back-icon" onClick={() => backToProfile()}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                  </span>
                    <span className={'fl-xsm-font'}>
                       My Drive - {checkLocation() == "files" ? "Files" : checkLocation() === "posts" ? "Archive" : ""}
                        {checkLocation() === "posts" &&
                        <>
                            <FontAwesomeIcon
                                className="fl-file-picker-label-icon fl-drive-tittle-icon"
                                icon={faInfoCircle}
                                data-tip
                                data-for="archive"
                            />
                            <ReactTooltip id="archive">
                                Posts in your archive are only visible to you.
                            </ReactTooltip>
                        </>
                        }
                   </span>
                    {checkLocation() === "posts" &&
                    <>
                        <span className="fl-drive-sort-single-option fl-drive-grid-view-option" onClick={() => userData.changeGridView()}>
                             <FontAwesomeIcon icon={userData.gridView ? faWindowMaximize : faBorderAll}/>
                        </span>
                        <span className="fl-drive-sort-single-option fl-drive-grid-view-option" onClick={() => setDeletePost(!deletePost)} data-tip data-for="select-post" >
                            <FontAwesomeIcon icon={faMousePointer}/>
                             <ReactTooltip id="select-post">{deletePost ? "Deselect" : 'Select'}</ReactTooltip>
                        </span>
                        {deletePost &&
                        <>
                            <div className={"fl-drive-grid-select-posts"}>
                                    <span className="fl-drive-grid-view-option-select" data-for="select-post"  onClick={checkAllSelectedPosts() ? deselectAllPosts : selectAllPosts}>
                                        {checkAllSelectedPosts() ? "Deselect" : "Select"} all
                                    </span>
                                {selectedPosts.count > 0 &&
                                <span className={"fl-drive-grid-view-option-select-count"}>
                                          ({selectedPosts.count})
                                       </span>
                                }
                            </div>
                            {selectedPosts?.posts.length > 0 &&
                            <>
                                   <span className="fl-drive-sort-single-option fl-drive-grid-view-option fl-drive-grid-view-option-delete" onClick={() => actionDeletePost()}>
                                        <FontAwesomeIcon icon={faTrashAlt} data-tip data-for={"delete-post"}/>
                                        <ReactTooltip id="delete-post">
                                              Delete {selectedPosts.count > 1 && selectedPosts.count + " " }file{selectedPosts.count > 1 && "s"}
                                        </ReactTooltip>
                                    </span>
                                {selectedPosts?.posts.filter((x:any) => x?.text?.length > 0 && x?.postImages?.length === 0 && x?.postVideos?.length === 0).length > 0 ?
                                    "" :
                                    <span className="fl-drive-sort-single-option fl-drive-grid-view-option fl-drive-grid-view-option-share" onClick={() => sharePost()}>
                                            <FontAwesomeIcon icon={faShare} data-tip data-for={"share-post"}/>
                                            <ReactTooltip id="share-post">
                                                 Share {selectedPosts.count > 1 && selectedPosts.count + " " }file{selectedPosts.count > 1 && "s"}
                                            </ReactTooltip>
                                           </span>
                                }
                            </>
                            }
                        </>
                        }
                    </>
                    }
                </div>
            </div>
            {checkLocation() === "files" ?
                <div className={"fl-drive-archive-option"}>
                    <div className={"fl-drive-delete-option"}>
                        {props?.selectedFiles?.files?.length > 0 && props.type === "drive" &&
                        <>
                            <div className="fl-drive-sort-single-option"
                                 onClick={(e) => deleteFile(e,0)}
                                 data-for="delete-selected-files"
                                 data-tip
                            >
                                <FontAwesomeIcon icon={faTrashAlt}/>
                                <ReactTooltip id="delete-selected-files">
                                    Delete {props.selectedFiles.count > 1 && props.selectedFiles.count + " " }file{props.selectedFiles.count > 1 && "s"}
                                </ReactTooltip>
                            </div>
                            <div className="fl-drive-sort-single-option"
                                 onClick={(e) => shareFile("mass","",e)}
                                 data-for="share-selected-files"
                                 data-tip
                            >
                                <FontAwesomeIcon icon={faShare}/>
                                <ReactTooltip id="share-selected-files">
                                    Share {props.selectedFiles.count > 1 && props.selectedFiles.count + " " }file{props.selectedFiles.count > 1 && "s"}
                                </ReactTooltip>
                            </div>
                        </>
                        }
                        {enableDelete &&
                        <>
                            <div className="fl-drive-sort-single-option-select" onClick={checkAllSelected() ? removeAllFiles : selectAllFiles}>
                                <span>{checkAllSelected() ? "Deselect" : "Select"} all</span>
                            </div>
                            <div className="fl-drive-sort-single-option-select-count">
                                <span>{props.selectedFiles.count > 0 && `(${props.selectedFiles.count})`}</span>
                            </div>
                        </>
                        }
                    </div>
                    <div className={"fl-drive-sort-option"}
                         tabIndex={1}
                         onBlur={() => setExpandFilter(false)}
                    >
                        <div className="fl-drive-sort-info fl-drive-sort-type">
                            <span className={`${filterOptions.type === "all" && "active"}`} onClick={() => selectType("all")}>All ({userData.user.profile.meta.attachments_in_drive})</span>
                            <span className={`${filterOptions.type === "image" && "active"}`} onClick={() => selectType("image")}>Photos ({userData.user.profile.meta.attachments_in_drive_images})</span>
                            <span className={`${filterOptions.type === "video" && "active"}`} onClick={() => selectType("video")}>Videos ({userData.user.profile.meta.attachments_in_drive_videos})</span>
                        </div>
                        <div className={"fl-drive-option-sort"}>
                            {filterOptions.sort === "created_at" || filterOptions.sort === "created_at_asc" || filterOptions.sort === "size"  || filterOptions.sort === "size_asc"  ?
                                <div className="fl-drive-sort-info">
                                    <div className={`fl-drive-example-label ${filterOptions.sort === "created_at" || filterOptions.sort === "created_at_asc" || filterOptions.sort === "size" || filterOptions.sort === "size_asc" ? "fl-drive-example-label-function" : ""}`}
                                         onClick={() => {filterOptions.sort === "created_at" ? selectSort("created_at_asc") : filterOptions.sort === "created_at_asc" ? selectSort("created_at") : filterOptions.sort === "size" ? selectSort("size_asc") : filterOptions.sort === "size_asc" ? selectSort("size"): selectSort("") }}
                                    >
                                        {filterOptions.sort === "created_at" || filterOptions.sort === "created_at_asc" || filterOptions.sort === "size" || filterOptions.sort === "size_asc" ?
                                            <div className={"fl-drive-example-label"}>
                                                Sort by {filterOptions.sort === "created_at" || filterOptions.sort === "created_at_asc" ? "date" : filterOptions.sort === "size" || filterOptions.sort === "size_asc" ? "size" : "" } <FontAwesomeIcon icon={filterOptions.sort === "created_at" || filterOptions.sort === "size" ? faArrowUp : faArrowDown}/>
                                            </div> : "Sort by"
                                        }
                                    </div>
                                </div> : ""
                            }
                            <div className="fl-drive-sort-single-option"
                                 onClick={() => setExpandFilter(!expandFilter)}
                                 data-tip data-for={"show-more"}
                            >
                                <FontAwesomeIcon icon={faSlidersH}/>
                                {!expandFilter &&
                                <ReactTooltip id="show-more">Show more options</ReactTooltip>
                                }
                            </div>
                            {expandFilter &&
                            <div
                                className={`fl-filter-head-actions-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                            >
                                <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                                    <div className="fl-dropdown-filter-list-option">
                                        <div className="fl-dropdown-filter-option fl-dropdown-filter-option-title">
                                            <span>Sort by</span>
                                        </div>
                                        <div className="fl-dropdown-filter-option" onClick={() => selectSort("default")}>
                                            <div className={`fl-messages-filter-check ${filterOptions.sort.length === 0  && "fl-messages-filter-active"}`}>
                                                {filterOptions.sort.length === 0 &&
                                                <FontAwesomeIcon
                                                    className="fl-bulk__user__list--user--check--added--icon"
                                                    icon={faCheck}
                                                />
                                                }
                                            </div>
                                            <span>Default</span>
                                        </div>
                                        <div className="fl-dropdown-filter-option" onClick={() => selectSort("tags")}>
                                            <div className={`fl-messages-filter-check ${filterOptions.sort === "tags" && "fl-messages-filter-active"}`}>
                                                {filterOptions.sort === "tags" &&
                                                <FontAwesomeIcon
                                                    className="fl-bulk__user__list--user--check--added--icon"
                                                    icon={faCheck}
                                                />
                                                }
                                            </div>
                                            <span>Tags</span>
                                        </div>
                                        <div className="fl-dropdown-filter-option" onClick={() => selectSort("created_at")}>
                                            <div className={`fl-messages-filter-check ${filterOptions.sort === "created_at" || filterOptions.sort === "created_at_asc" ? "fl-messages-filter-active" : ""}`}>
                                                {filterOptions.sort === "created_at" || filterOptions.sort === "created_at_asc" ?
                                                    <FontAwesomeIcon
                                                        className="fl-bulk__user__list--user--check--added--icon"
                                                        icon={faCheck}
                                                    /> : ""
                                                }
                                            </div>
                                            <span>Date Uploaded</span>
                                        </div>
                                        <div className="fl-dropdown-filter-option" onClick={() => selectSort("size")}>
                                            <div className={`fl-messages-filter-check ${filterOptions.sort === "size" && "fl-messages-filter-active"}`}>
                                                {filterOptions.sort ===  "size" &&
                                                <FontAwesomeIcon
                                                    className="fl-bulk__user__list--user--check--added--icon"
                                                    icon={faCheck}
                                                />
                                                }
                                            </div>
                                            <span>Size</span>
                                        </div>
                                    </div>
                                    {props.type === "drive" &&
                                    <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={() => enableDeleteFunction()}>
                                        <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                            <FontAwesomeIcon icon={faMousePointer} />
                                        </div>
                                        <span>
                                    {enableDelete ? "Deselect" : "Select"}
                                </span>
                                    </div>
                                    }
                                </SimpleCard>
                            </div>
                            }
                            <div className="fl-drive-sort-single-option"
                                 onClick={() => {
                                     setGridView(!gridView)
                                 }}
                            >
                                <FontAwesomeIcon
                                    icon={!gridView ? faBorderAll : faWindowMaximize}
                                />
                            </div>
                        </div>
                    </div>
                    {filterOptions.sort === "tags" || openTag ?
                        <div className={`fl-drive-labels-sort`}>
                            <span className={`fl-drive-example-single-label`} onClick={() => selectSort("default")}>All</span>
                            {labelExample.labels.map((x:any) =>
                                <span className={`fl-drive-example-single-label ${filterOptions.label === x.id && "fl-drive-label-selected" }`} onClick={(e) => selectLabelSort(e,x.id)}>
                                {x.name}
                             </span>
                            )}
                        </div> : ""
                    }
                    {checkedLabelName() &&
                    <div className={"fl-drive-labels-sort-function"}>
                        <span>Showing all files tagged "{checkedLabelName()}"</span>
                        <span onClick={() => selectSort("default")}>Exit "{checkedLabelName()}" tag</span>
                    </div>
                    }
                    {attachments.empty === false ?
                        <div className={"fl-loader-animation fl-purchased-empty"}>
                            <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                        </div> : ""
                    }
                    {attachments.empty === 0 &&
                    <div className={"fl-purchased-empty"}>
                        <div className={"fl-purchased-empty"}>
                            <p>No results.</p>
                        </div>
                    </div>
                    }
                    {attachments.empty === 1 &&
                    <>
                        {gridView ?
                            <div className={`${!gridView ? "fl-drive-attachments" : "fl-drive-attachments-grid"}`}
                                 tabIndex={1}
                                 onBlur={() => setImageDropdown(false)}
                            >
                                <DriveTable attachments={attachments} humanFileSize={humanFileSize} selectLabelSort={selectLabelSort}
                                            convertDuration={convertDuration} checkSelectStatus={checkSelectStatus}
                                            selectedFiles={props?.selectedFiles} removeSelected={props?.removeSelected}
                                            addSelected={props?.addSelected} flModalImage={flModalImage} keyboardEventDrive={keyboardEventDrive}
                                            type={props.type} enableDelete={enableDelete}  updateFile={updateFile} deleteLabel={deleteLabel}/>
                            </div> :
                            <div className={`fl-drive-attachments`}
                                 tabIndex={1}
                                 onBlur={() => setImageDropdown(false)}
                            >
                                {attachments.files.map((x:any,index:any) =>
                                    <div className={`fl-drive-image ${!enableDelete ? "fl-drive-image-hover" : ""} ${attachments.files.length === 1 && "fl-drive-image-one-file"} ${props?.selectedFiles?.files?.includes(x) && "fl-drive-image-added"}`}
                                         onClick={() => {checkSelectStatus() ? (props?.selectedFiles?.files?.includes(x)  ? props?.removeSelected(x.id) :  props?.addSelected(x)) :
                                             openImage(x.type,index)
                                         }}
                                         onKeyDown={keyboardEventDrive}
                                         tabIndex={0}
                                    >
                                        {x.type === "image" &&
                                        <RenderSmoothImage src={x.url} />
                                        }
                                        {x.type === "video" &&
                                        <VideoPlayer src={x.url} thumbnail={x.thumb_url} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                        }
                                        {enableDelete || props.type === "message" ?
                                            <div className={`fl-drive-image-svg fl-drive-delete-${props?.selectedFiles?.files?.includes(x) ? "enable" : "disable"}`}>
                                                {props?.selectedFiles?.files?.includes(x) &&
                                                <FontAwesomeIcon icon={faCheck}/>
                                                }
                                            </div>
                                            : ""
                                        }
                                        <div className={"fa-drive-option"}   onClick={(e) => imageOption(e,x.id)} >  <FontAwesomeIcon icon={faEllipsisH} /> </div>
                                        {imageDropdown && optionId === x.id &&
                                        <div
                                            className={`fl-filter-head-actions-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                                        >
                                            <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                                                <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => updateFile(e,x.id,x.labels,x.url,index)}>
                                                    <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                        <FontAwesomeIcon icon={faTag} />
                                                    </div>
                                                    <span>
                                                        {x.labels.length > 0 ? "Edit" : "Add"} tags
                                                    </span>
                                                </div>
                                                <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => shareFile("single",x,e)}>
                                                    <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                        <FontAwesomeIcon icon={faShare} />
                                                    </div>
                                                    <span>
                                                       Share file
                                                    </span>
                                                </div>
                                                <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) =>{e.stopPropagation();modalData.fullScreen(attachments.files, index)}}>
                                                    <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                        <FontAwesomeIcon icon={faExpand} />
                                                    </div>
                                                    <span>
                                                        Full screen
                                                    </span>
                                                </div>
                                                <div className="fl-dropdown-filter-option fl-dropdown-filter-option-delete fl-drive-delete-select" onClick={(e) => deleteFile(e,x.id)}>
                                                    <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </div>
                                                    <span>
                                                       Delete file
                                                    </span>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                        }
                                        <div className={`fl-drive-image-label ${x.labels.length > 0 && "fl-drive-image-tag-grid"}`}>
                                            {x.labels.length > 0 &&
                                            x.labels.slice(0,5).map((label:any,index:any) =>
                                                <span
                                                    className={`fl-drive-image-label-name` }
                                                    onClick={(e) => !enableDelete && selectLabelSortSingle(e,label?.label?.id )}
                                                >
                                                          {label.label.name}
                                                    <div className={"fa-label-delete"} onClick={(e) => deleteLabel(e,label?.label,x?.id)}>  <FontAwesomeIcon icon={faTimes} /> </div>
                                                   </span>
                                            )
                                            }
                                            {x.labels.length > 5 &&
                                            <span className={"fl-drive-more-labels"} onClick={(e) => showMoreTags(e,x.labels,x.id,x.url,index)}>
                                                 <FontAwesomeIcon icon={faTag}  data-tip data-for={"more-labels"}/>
                                                 <ReactTooltip id="more-labels">
                                                  Show all tags
                                                 </ReactTooltip>
                                             </span>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </>
                    }
                </div> : ""
            }
            {checkLocation() === "posts" &&
            <RemovedPosts  setCountArchive={props.setCountArchive} countArchive={props.countArchive} state={state} setState={setState} deletePost={deletePost} setDeletePost={setDeletePost} selectedPosts={selectedPosts} addSelected={addSelected} removeSelected={removeSelected}/>
            }
        </>
    )
}

export const OpenDeleteModal: FC<any> = ({ id,attachments,setAttachments,selected,setEnableDelete, setSelectedFiles,selectedFiles }) => {

    const modalData = useModalContext()
    const envData = useEnvContext()

    const undoDelete = async () => {
        const ids = selected?.files.map((e:any) => e.id)
        try {
            const res = await Axios.delete(`${envData.apiUrl}/attachments/delete?action=undo`, {
                data: { attachments: id === 0 ? ids : [id]},
            });
            let undoFiles = [...attachments.files, res.data.data];
            setAttachments({
                ...attachments,
                files: undoFiles,
            });
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const processDelete = async (id:number) => {

        const targets = selected?.files.map((e: any) => e);
        const ids = selected?.files.map((e:any) => e.id)

        try {
            modalData.close();
            if(id > 0) {
                await Axios.get(`${envData.apiUrl}/attachments/${id}/delete`);
                setAttachments({
                    ...attachments,
                    files: [...attachments.files.filter((x: any) => x.id !== id)],
                });
            }
            if(id === 0 ) {
                await Axios.delete(`${envData.apiUrl}/attachments/delete`, {
                    data: { attachments: ids},
                });
                setAttachments({
                    ...attachments,
                    files: [...attachments.files.filter((x: any) => !targets.includes(x))],
                });
                setEnableDelete(false)
                setSelectedFiles({
                    ...selectedFiles,
                    files: [],
                });
            }
            modalData.pushToast(
                "success",
                <>
                    {id > 0 || selectedFiles?.files?.length === 1 ? "File has been deleted." : "" }
                    {id === 0 && selectedFiles?.files?.length > 0 ?
                        `${selectedFiles?.files?.length} items have been deleted.` : ""
                    }
                    {" "}
                    <span className="fl-welcome-message-undo" onClick={() => undoDelete()}>
                          Undo
                        </span>
                </>
            );
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const countSelectedFiles = () => {
        return selected?.files?.length
    }

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Delete{countSelectedFiles() > 1 && `${" "}${selected?.files?.length}`} file{id === 0 && countSelectedFiles() > 1 && "s"}</div>
            </div>
            <div className="fl-modal-description">
                <div>
                    Are you sure you want to delete {countSelectedFiles() > 1 ? `these ${countSelectedFiles()}` : "this"} file{id === 0 && selected?.files?.length > 1 && "s"} from Fluffa Drive?
                </div><br/>
                <div>
                    Files that you delete can't be restored.
                </div>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>Cancel</Button>
                <Button type="normal" onClick={() => processDelete(id)}>
                    Delete
                </Button>
            </div>
        </React.Fragment>
    );
};
