import React, {FC, Fragment, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import {Button} from "../../components/Button";
import {useModalContext} from "../../context/ModalContext";

export const RestrictUser: FC<any> = (props) => {

    const modalData = useModalContext()
    const [animation, setAnimation] = useState(false)
    return (
        <Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">
                    {props.action === "unblock" ? "Unrestrict" : "Restrict"} user
                </div>
                <div className="fl-modal-close-button">
                    <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => modalData.close()}
                    />
                </div>
            </div>
            <div className="fl-modal-description">
                <div className="fl-modal-description-row">
                    Are you sure you want to {props.action === "unblock" ? "unrestrict" : "restrict"} this user?
                </div>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>No</Button>
                <Button disabled={animation} type="normal" onClick={async () => {setAnimation(true); await props.unblockBlock(); setAnimation(false)}}>
                    Yes
                    {animation && <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>}
                </Button>
            </div>
        </Fragment>
    );
}