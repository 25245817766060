import React, {FC, useEffect, useRef, useState} from 'react'
import {SimpleCard} from "../../components/SimpleCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBug, faCheck, faEnvelope, faEnvelopeOpen, faMousePointer, faPlus, faSlidersH, faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {Link, useHistory, useParams} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import Axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {useModalContext} from "../../context/ModalContext";
import {ListMessages} from "./ListMessages";
import {Message} from "./Message";
import "./Inbox.css"
import {Button} from "../../components/Button";
import "../../components/DirectMessage.css";
import "../../components/MessageBubble.css";
import "../../routes/Messages.css"
import ReactLoading from "react-loading";

export const Inbox:FC<any> = (props) => {

    const userData = useUserContext()
    const envData = useEnvContext()
    const modalData = useModalContext()
    const history = useHistory()

    const { id } = useParams<{id: string}>();

    const [state, setState] = useState<any>({
        messages: [],
        currentPage: 1,
        lastPage: 0,
        loadingState:false
    })
    const stateRef = useRef<any>();
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    const [expandFilter, setExpandFilter] = useState<boolean>(false)
    const [activeFilter, setActiveFilter] = useState<string>("default")
    const [gallery,setGallery] = useState(false)
    const [conversations, setConversations] = useState({
        selectedConversation: [] as any,
    });
    const [enableDelete, setEnableDelete] = useState<boolean>(false)
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [read,setRead] = useState(false)

    const deleteOption = () => {
        setEnableDelete(!enableDelete)
        setExpandFilter(false)
    }

    const countSelected = () => {
        return conversations.selectedConversation.length
    }

    const filtersArray = [
        {
            text: "Recent (default)",
            function: "default"
        },
        {
            text: "Unread first",
            function: "asc"
        },
        {
            text: "Oldest unread first",
            function: "desc"
        }
    ];

    const functionArray = [
        {
            text: `Mark all as ${!read ? "" : "un"}read`,
            icon: !read ? faEnvelopeOpen : faEnvelope,
            class: "read"
        },
        {
            text: `${countSelected() > 0 ? "Delete" : enableDelete ? "Deselect" : "Select"}`,
            icon: countSelected() > 0 ? faTrashAlt : faMousePointer,
            class: "delete",
        }
    ];

    const getChatMessages = async (type = "default",page = 1) => {
        setState({ ...stateRef.current, loadingState: true });
        await Axios.get(`${envData.apiUrl}/conversations${page ? `?page=${page}` : ''}${type !== "default" ? `?last=${type}` : ""}`).then((res) => {
            setState({...stateRef.current, messages: page > 1 ? [...state.messages,...res.data.data] : res.data.data, currentPage: page, lastPage: res.data.meta.last_page,loadingState:false})
            setRead(res.data.data.filter((x:any) => x?.meta?.last_message?.seen === false && x?.meta?.last_message?.sender_id !== userData.user.id ).length > 0 ? false : true)
        }).catch((e) => {
            if( e.response?.data.message === "Unauthenticated.") {
                userData.clearToken(false);
                history.push("/login");
            } else {
                modalData.pushToast("error", "Something went wrong!");
            }
        })
    }

    const scroll = (event:any) => {
        var maxScroll =
            Math.max(
                event.target.scrollHeight,
                event.target.offsetHeight,
                event.target.clientHeight,
                event.target.scrollHeight,
                event.target.offsetHeight
            ) - event.target.clientHeight;
        if (
            event.target.scrollTop === maxScroll && !checkLastPage() && !stateRef.current.loadingState
        ) {
            getChatMessages("default", stateRef.current.currentPage + 1)
        }
    }

    const filterList = (type:string) => {
        setActiveFilter(type)
        getChatMessages(type,1);
        setExpandFilter(false)
    }

    const checkLastPage = () => {
        if (stateRef.current.currentPage === stateRef.current.lastPage && stateRef.current.lastPage) {
            return true
        } else {
            return false
        }
    }

    useEffect( () => {
        let abortController = new AbortController();
        getChatMessages();
        abortController.abort();
    }, []);

    const addSelected = (conversation: any) => {
        let newSelectedConversations = [...conversations.selectedConversation, conversation];
        setConversations({
            ...conversations,
            selectedConversation: newSelectedConversations,
        });
    };

    const removeSelected = (id: any) => {
        let newSelectedConversations = conversations.selectedConversation.filter(
            (x: any) => x !== id
        );

        setConversations({
            ...conversations,
            selectedConversation: newSelectedConversations,
        });

    };

    const selectAllConversations = () => {
        let allSelected = state.messages?.map((x:any) => {
            return x.id
        })
        setConversations({
            ...conversations,
            selectedConversation: allSelected,
        });
    }

    const removeAllConversations = () => {
        setConversations({
            ...conversations,
            selectedConversation: [],
        });
    }

    const checkAllSelected = () => {
        if(conversations.selectedConversation.length === state.messages.length) {
            return true
        } else {
            return false
        }
    }

    const confirmDeleteConversations = async (type:string) => {
        modalData.push(() => <DeleteConversationModal countSelected={countSelected} type={type} conversations={conversations} setState={setState} state={state} id={id} />)
    }

    useEffect(() => {
        if (userData.signal !== null) {
            if (userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageCreated") {
                const {
                    sender,
                    body,
                    created_at,
                    attachments,
                    price,
                    conversation_id
                } = userData.signal.data;
                const fakeMessage = {
                    body: body,
                    attachments: attachments,
                    sender: sender,
                    created_at: created_at,
                    price: price,
                    seen: userData.signal.data.seen === null ? false : userData.signal.data.seen,
                    sender_id: sender.id
                };
                const lastMessage = {last_message: fakeMessage}
                setState({...state, messages: state.messages.map((x:any) => x.id === conversation_id ? {...x, meta: lastMessage} : x)})
            } else if (userData?.signal?.type === "App\\Notifications\\Chat\\ChatMessageDeleted" && userData?.signal?.data?.delete_from !== userData.user.handle) {
                const conversation_id = userData?.signal?.data?.conversation_id
                const last_message = userData?.signal?.data?.last_message
                setState({
                    ...state,
                    messages: state.messages.map((x: any) =>
                        x.id === conversation_id
                            ? { ...x, meta: { ...x, last_message:last_message} }
                            : x
                    ),
                });
            }
        }
    }, [userData.signal]);

    const deleteChat = async (id: number) => {
        modalData.push(() => <DeleteChatModal id={id} state={state} setState={setState} oldState={props.state} />)
    };

    const readUnread = () => {
        try {
            Axios.put(`${envData.apiUrl}/conversations/messages/last/seen`, {
                seen: read ? false : true
            })
            setExpandFilter(false)
            setState({...state, messages: state.messages.map((x:any) => x.meta.last_message.sender_id !== userData.user.id ? {...x, meta: {...x.meta, last_message: {...x.meta.last_message, seen:read ? false : true}}} : x)})
            setRead(!read)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    return (
        <div className={`fl-messages-main fl-messages-main-${userData.currentTheme}`}>
            <div className="fl-container">
                <div className="fl-d-flex fl-feed-row">
                    <div
                        className={`fl-col-4 fl-messages-col fl-feed-column ${!id ? 'fl-messages-mobile-priority' : ''}`}>
                        <SimpleCard className="fl-messages-people fl-messages-tablet"  tabIndex={1} onBlur={() => setExpandFilter(false)}>
                            {!isMobile ?
                                <div className={`fl-messages-title fl-messages-title-${userData.currentTheme}`} tabIndex={1} onBlur={() => setExpandFilter(false)}>
                                    Messages
                                </div> : ""
                            }
                            <div className={"fl-messages-filter-main"}>
                                <div className="fl-messages-filter">
                                    {enableDelete && countSelected() > 0  &&
                                        <div className="fl-messages-count-selected">
                                            <b>{countSelected()}</b>
                                        </div>
                                    }
                                    {enableDelete &&
                                    <span onClick={checkAllSelected() ? removeAllConversations : selectAllConversations} className={`fl-messages-list-check-all ${checkAllSelected() && "fl-messages-list-checked-all"}`}>
                                        {checkAllSelected() &&
                                            <FontAwesomeIcon
                                                className="fl-bulk__user__list--user--check--added--icon"
                                                icon={faCheck}
                                            />
                                        }
                                        {enableDelete &&
                                         <b className={"fl-messages-count-selected-all"}>{!checkAllSelected() && "All"}</b>
                                        }
                                    </span>
                                    }
                                    <span className={"fl-messages-filter-show-more"}
                                          onClick={() => countSelected() > 0 ? confirmDeleteConversations(checkAllSelected() ? "all" : "array") : setExpandFilter(!expandFilter)}
                                          data-tip data-for={"show-more"}>
                                         <FontAwesomeIcon icon={countSelected() > 0 ? faTrashAlt : faSlidersH}/>
                                         <ReactTooltip id="show-more">Show more options</ReactTooltip>
                                    </span>
                                </div>
                            </div>
                            {expandFilter &&
                            <div
                                className={`fl-filter-head-actions-dropdown fl-inbox-filter-dropdown fl-filter-head-actions-dropdown-${userData.currentTheme}`}
                            >
                                <SimpleCard className="fl-filter-head-actions-dropdown-inner">
                                    <div className="fl-dropdown-filter-list-option">
                                        <div className="fl-dropdown-filter-option fl-dropdown-filter-option-title">
                                            <span>Sort by</span>
                                        </div>
                                        {filtersArray.map((x:any, y:number) => (
                                            <div key={y} className="fl-dropdown-filter-option" onClick={() => filterList(x.function)}>
                                                <div
                                                    className={`fl-messages-filter-check ${activeFilter === x.function && "fl-messages-filter-active"}`}>
                                                    {activeFilter === x.function &&
                                                    <FontAwesomeIcon
                                                        className="fl-bulk__user__list--user--check--added--icon"
                                                        icon={faCheck}
                                                    />
                                                    }
                                                </div>
                                                <span>{x.text}</span>
                                            </div>
                                        ))}
                                        {functionArray.map((x:any, y:number) => (
                                            <div className={`fl-dropdown-filter-option fl-dropdown-filter-option-${x.class}`} onClick={() => x.class === "delete" ? ( deleteOption()) : x.class === "read" ? readUnread() : ""}>
                                                <div className={"fl-filter-head-actions-dropdown-option-icon"}>
                                                    <FontAwesomeIcon icon={x.icon} />
                                                </div>
                                                <span>
                                                        {x.text}
                                                     </span>
                                            </div>
                                        ))}
                                    </div>
                                </SimpleCard>
                            </div>
                            }
                            <div className={`fl-messages-all fl-messages-all-${userData.currentTheme}`} onScroll={(event) => scroll (event)}>
                                <div className="fl-messages-all-inner">
                                    <div className="fl-messages-all-convs">
                                        <Link to="/message-create" className="fl-messages-all-convs-new">
                                            <div className="fl-messages-all-convs-new-icon">
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </div>
                                            New message
                                        </Link>
                                        {state?.messages?.map((x:any,y:number) => (
                                            <ListMessages
                                                state={state}
                                                setState={setState}
                                                data={x}
                                                key={y}
                                                messageId={id}
                                                added={conversations.selectedConversation.includes(x.id) || false}
                                                removeSelected={() => removeSelected(x.id)}
                                                addSelected={() => addSelected(x.id)}
                                                enableDelete={enableDelete}
                                                deleteChat={deleteChat}
                                                gallery={gallery}
                                                setGallery={setGallery}
                                                read={read}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </SimpleCard>
                    </div>
                    <div
                        className={`fl-col-8 fl-messages-col fl-feed-column ${id ? 'fl-messages-mobile-priority' : ''}`}>
                        <Message gallery={gallery} setGallery={setGallery} state={state} setState={setState} messageId={id} deleteChat={deleteChat} />
                    </div>
                    {!isMobile ?
                        <Link
                            to="/support/report-bug"
                            className={`fl-support-bug fl-support-bug-${props.side} fl-support-bug-${userData.currentTheme}`}
                            data-place="left"
                            data-tip
                            data-for="bug"
                        >
                            <FontAwesomeIcon className="fl-support-bug-icon" icon={faBug}/>
                            <ReactTooltip id="bug">Report a bug</ReactTooltip>
                        </Link> : ""
                    }
                </div>
            </div>
        </div>
    )
}


export const DeleteConversationModal: FC<any> = ({countSelected,type,conversations,setState,state,id}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const history = useHistory()
    const [animationDelete, setAnimationDelete] = useState(false)

    const confirmDelete = async () => {
        setAnimationDelete(true)
        const targets = conversations.selectedConversation.map((e: any) => e);
        const formData = new FormData();
        conversations.selectedConversation.forEach((x: any) => {
            formData.append("conversation_ids[]", x);
        });
        try {
            if(type === "all") {
                await Axios.delete(`${envData.apiUrl}/conversations/delete/all`);
                setState({
                    ...state,
                    messages: [],
                });
            } else {
                await Axios.post(`${envData.apiUrl}/conversations/delete`, formData);
                setState({
                    ...state,
                    messages: [...state.messages.filter((x: any) => !targets.includes(x.id))],
                });
            }
            if (Number(id) > 0) {
                history.push("/inbox");
            }
            modalData.close();
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimationDelete(false)
    };

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Delete {countSelected() > 1 ? `${countSelected()} chats`  : "chat"}</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                Are you sure you want to delete {countSelected() > 1 ? "these" : "this"} chat{countSelected() > 1 ? "s" : ""}?
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>No</Button>
                <Button type="normal" onClick={() => confirmDelete()} disabled={animationDelete}>
                   Yes
                   {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/> : ""}
                </Button>
            </div>
        </React.Fragment>
    );
}

export const DeleteChatModal: FC<any> = ({id,setState,state,oldState}) => {

    const modalData = useModalContext()
    const envData = useEnvContext()
    const history = useHistory()
    const [animationDelete, setAnimationDelete] = useState(false)

    const confirmDelete = async (id: number) => {
        setAnimationDelete(true)
        try {
            await Axios.delete(`${envData.apiUrl}/conversations/${id}/delete`);
            setState({
                ...oldState,
                messages: [...state.messages.filter((x: any) => x.id !== id)],
            });
            if (id) {
                history.push("/inbox");
            }
            modalData.close();
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimationDelete(false)
    };

    return (
        <React.Fragment>
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Delete chat</div>
            </div>
            <div className="fl-modal-description fl-modal-description-subscription">
                Are you sure you want to delete this chat?
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={modalData.close}>No</Button>
                <Button type="normal" onClick={() => confirmDelete(id)} disabled={animationDelete}>
                    Yes
                    {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/> : ""}
                </Button>
            </div>
        </React.Fragment>
    );
}