import { faChevronCircleLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { FC, Fragment, useEffect, useState } from 'react'
import { useHistory} from 'react-router-dom';
import { ExpandingContent } from '../components/ExpandingContent';
import { Toggle } from '../components/Toggle';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import { useUserContext } from '../context/UserContext';
import './SettingsNotifications.css';

type stringKeys = {
    [key: string]: any // defines that keys can be strings and vlaues are strictly boolean
}

export const SettingsNotifications:FC<any> = (props) => {

    const [state, setState] = useState<stringKeys>({
        push: {
            ChatMessageCreated: true,
            ContentUnlockedChat: true,
            NewPostLike:true,
            NewPostComment: true,
            NewPostFavorite: true,
            NewSubscriber: true,
            NewUserProfileFavorite: true,
            PayoutSentToCreator: true,
            PostUnlocked: true,
            ReportedPostDeleted: true,
            SubscriptionRenewed: true,
            TipReceived: true,
            Verified: true,
            VerifiedUserEmail: true,
            YouAreMentionedInPost: true,
        },
        email: {  
            AccountDeactivated: true,
            AccountDeleted: true,
            AccountReactivated: true,
            ChatMessageTyping: true,
            ChatNotification: true,
            CreatorUploadedContent: true,
            ContentUnlockedChatEmail: true,
            DeletedAccount: true,
            EmailChangeConfirmed: true,
            EmailChangeNotification: true,
            NewMessage: true,
            NewPost: true,
            NewPostCommentEmail: true,
            NewPostCommentLike: true,
            NewReferral: true,
            NewSubscriberEmail: true,
            ManualRequestedPayout: true,
            PayoutSentToCreatorEmail: true,
            PostFinishedEncoding: true,
            PostUnlockedEmail: true,
            RequestedUserVerificationEmail: true,
            ReactivatedAccount: true,
            SubscriptionRenewedEmail: true,
            TemporaryDeactivation: true,
            TipReceivedEmail: true,
            UpdateUserVerificationEmail: true,
            UserActivated: true,
            UserClosed: true,
            UserConnected: true,
            UserDeactivated: true,
            UserDisconnected: true,
            UserTipCreated: true,
            UserUnlockedChatMessage: true,
            VerifiedEmail: true,
            VerifyEmail: true,
            YouAreMentionedInPost: true,
            YouAreMentionedInComment: true,
        },
    });

    const [newsletters, setNewsletters] = useState(true);

    const [groupEnabled, setGroupEnabled] = useState({email: true, push: true});
   
    const [groupHidden, setGroupHidden] = useState({email: false, push: false});

    // indication notifications yet to be implemented on back-end
    const mockNotifications: Array<string> = [
        'AccountReactivated',
        'AccountDeactivated',
        'AccountDeleted',
        'ContentUnlockedChatEmail',
        'NewMessage',
        'NewPostCommentEmail',
        'NewReferral',
        'NewSubscriber',
        'NewSubscriberEmail',
        'PayoutSentToCreatorEmail',
        'PostUnlocked',
        'PostUnlockedEmail',
        'SubscriptionRenewedEmail',
        'TipReceivedEmail',
        'Verified',
        'VerifiedEmail',
    ];

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory();

    // sends new setting to an api and changes corresponding state if successful.
    const sendSettingChange = (group: string, setting: string, value: boolean) => {
        if(mockNotifications.includes(setting)) {
            setState((prevState) => {
                return {...prevState, [group]: {...prevState[group], [setting]: value}}; //sets like, for example {..., push: ..., ChatMessageCreated: true}
            });
        } else {
            axios.post(envData.apiUrl + '/settings/notification', {setting: setting, value: value}).then((response: any) => {
                setState((prevState) => {
                    return {...prevState, [group]: {...prevState[group], [setting]: value}};
                });
            }).catch(err => {
                modalData.pushToast('error', 'Something went wrong!');
            });
        }
    }

    const setGroupToggle = (on: boolean, push: boolean) => {
        if(push) {
            setGroupEnabled((prevState) => {
                return {...prevState, push: on};
            });
        } else {
            setGroupEnabled((prevState) => {
                return {...prevState, email: on};
            });
        }
    }

    const [animation,setAnimation] = useState(true)

    useEffect(() => {
        axios.get(envData.apiUrl + '/settings/notification').then((response) => {
            let data = {...state};
            response.data.forEach((element: any) => {
                let setting = element.setting.match(/.*\\.*\\(.*)/)[1]; //gets a name of the class from full namespace, like NewPostLike from app\notifinations\NewPostLike
                if(state.push.hasOwnProperty(setting)){
                    data = {...data, push: {...data.push, [setting]: element.value}};
                } else {
                    data = {...data, email: {...data.email, [setting]: element.value}};
                }
            });
            setState(data);
            setAnimation(false)
        }).catch(err => {
            modalData.pushToast('error', 'Something went wrong!');
            setAnimation(false)
        })
    }, []);

    const pushToggleAll = (on: boolean) => {
        Object.keys(state.push).forEach((element: string) => {
            if(state.push[element] !== on){
                sendSettingChange('push', element, on);
            }
        });
    }

    const emailToggleAll = (on: boolean) => {
        Object.keys(state.email).forEach((element: string) => {
            if(state.email[element] !== on){
                sendSettingChange('email', element, on);
            }
        });
    }

    useEffect(() => {
        let pushValues = Object.values(state.push);
        if(pushValues.indexOf(true) >= 0 && !pushValues.every((element) => element)) { //checks if some toggle is on but not every is toggled on in array
            setGroupToggle(true, true);
        } else if (pushValues.every((element) => element)) {
            setGroupToggle(false, true)
        } else if (pushValues.every((element) => !element)) {
            setGroupHidden((prevState) => {
                return {...prevState, push: true}
            });
        }
    }, [state.push]);

    useEffect(() => {
        let emailValues = Object.values(state.email);

        if(emailValues.indexOf(true) >= 0 && !emailValues.every((element) => element)) {//check if some toggle is on but not every is toggled on in array
            setGroupToggle(true, false);
        } else if (emailValues.every((element) => element)) {
            setGroupToggle(false, false)
        } else if (emailValues.every((element) => !element)) {
            setGroupHidden((prevState) => {
                return {...prevState, email: true}
            });
        }
    }, [state.email]);

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    return (
        <Fragment>
            {animation &&
                <div className="fl-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className={`fl-settings-sub-section fl-settings-sub-section-${userData.currentTheme}`}>
                    <div className={`fl-settings-sub-title fl-settings-sub-title-svg-${userData.currentTheme}`}>
                          <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                          </span>
                        Notifications
                    </div>
                    <div className={`fl-settings-sub-section  fl-settings-mt-30 fl-settings-sub-section-p-${userData.currentTheme}`}>
                        <p>Please note, we may still send you important notifications about your account outside of your preferred notification settings.</p>
                        <h3 className={"fl-settings-mt-30"}>What notifications you will receive:</h3>
                    </div>
                    <div className="fl-settings-sub-section">
                        <ExpandingContent header='PUSH' content='These are on-site notifications'/>
                        <div className="fl-settings-notification-toggle">
                            <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={!groupHidden.push} onClick={() => {
                                setGroupHidden({...groupHidden, push: !groupHidden.push});
                            }}/>
                        </div>
                        <div className={`fl-settings-notification ${state.push.NewPostComment ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='COMMENTS' content="You'll receive a notification when someone comments on your posts."/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.NewPostComment} onClick={() => {
                                    sendSettingChange('push', 'NewPostComment', !state.push.NewPostComment);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.NewPostComment ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.ReportedPostDeleted ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='DELETED CHATS' content="You'll receive a notification when you delete a chat."/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.ReportedPostDeleted} onClick={() => {
                                    sendSettingChange('push', 'ReportedPostDeleted', !state.push.ReportedPostDeleted);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.ReportedPostDeleted ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.ChatMessageCreated ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='DELETED POSTS' content="You'll receive a notification when you delete a post."/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.ChatMessageCreated} onClick={() => {
                                    sendSettingChange('push', 'ChatMessageCreated', !state.push.ChatMessageCreated);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.ChatMessageCreated ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.VerifiedUserEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='EMAIL VERIFICATION' content='You’ll receive a notification once your email address has been verified.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.VerifiedUserEmail} onClick={() => {
                                    sendSettingChange('push', 'VerifiedUserEmail', !state.push.VerifiedUserEmail);
                                }} />
                                <span className={`fl-settings-toggle-span`}>{state.push.VerifiedUserEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.NewPostFavorite ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='FAVOURITES' content='You’ll receive a notification when someone favorites you or your posts.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.NewPostFavorite} onClick={() => {
                                    sendSettingChange('push', 'NewPostFavorite', !state.push.NewPostFavorite);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.NewPostFavorite ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.NewPostLike ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='LIKES' content='You’ll receive a notification when someone likes your posts.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.NewPostLike} onClick={() => {
                                    sendSettingChange('push', 'NewPostLike', !state.push.NewPostLike);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.NewPostLike ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.YouAreMentionedInPost ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='MENTIONS' content="You'll receive a notification when someone mentions you in a post or comment."/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.YouAreMentionedInPost} onClick={() => {
                                    sendSettingChange('push', 'YouAreMentionedInPost', !state.push.YouAreMentionedInPost);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.YouAreMentionedInPost ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.NewSubscriber ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='NEW SUBSCRIBERS' content='You’ll receive a notification when you get a new subscriber.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.NewSubscriber} onClick={() => {
                                    sendSettingChange('push', 'NewSubscriber', !state.push.NewSubscriber);
                                }} />
                                <span className={`fl-settings-toggle-span`}>{state.push.NewSubscriber ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.PayoutSentToCreator ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PAYOUT SENT' content='You’ll receive a notification when your payout has been sent.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.PayoutSentToCreator} onClick={() => {
                                    sendSettingChange('push', 'PayoutSentToCreator', !state.push.PayoutSentToCreator);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.PayoutSentToCreator ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.ContentUnlockedChat ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PPV MESSAGE UNLOCKED' content='You’ll receive a notification when someone unlocks your PPV messages.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.ContentUnlockedChat} onClick={() => {
                                    sendSettingChange('push', 'ContentUnlockedChat', !state.push.ContentUnlockedChat);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.ContentUnlockedChat ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.PostUnlocked ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PPV POST UNLOCKED' content='You’ll receive a notification when someone unlocks your PPV posts.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.PostUnlocked} onClick={() => {
                                    sendSettingChange('push', 'PostUnlocked', !state.push.PostUnlocked);
                                }} />
                                <span className={`fl-settings-toggle-span`}>{state.push.PostUnlocked ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.SubscriptionRenewed ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='REPEAT SUBSCRIBERS' content='You’ll receive a notification when a subscriber is rebilled.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.SubscriptionRenewed} onClick={() => {
                                    sendSettingChange('push', 'SubscriptionRenewed', !state.push.SubscriptionRenewed);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.SubscriptionRenewed ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.TipReceived ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='TIPS' content='You’ll receive a notification when someone tips you.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.TipReceived} onClick={() => {
                                    sendSettingChange('push', 'TipReceived', !state.push.TipReceived);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.TipReceived ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.push.Verified ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='VERIFICATION' content='You’ll receive a notification once your account has been approved or rejected.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.push.Verified} onClick={() => {
                                    sendSettingChange('push', 'Verified', !state.push.Verified);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.push.Verified ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`fl-group-toggle fl-toogle-${!groupHidden.push ? 'visible' : 'hidden'} fl-settings-mt-10`}>
                            <span >
                                {!groupHidden.push && (!groupEnabled.push ? 'TURN OFF ALL PUSH NOTIFICATIONS' : 'TURN ON ALL PUSH NOTIFICATIONS')}
                            </span>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={!groupEnabled.push} onClick={() => {
                                    pushToggleAll(groupEnabled.push);
                                    setGroupToggle(!groupEnabled.push, true);
                                }}/>
                            </div>
                    </div>
                    

                    <div className="fl-settings-sub-section">
                        <ExpandingContent header='EMAIL' content='These are e-mail notifications'/>
                        <div className="fl-settings-notification-toggle">
                            <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={!groupHidden.email} onClick={() => {
                                setGroupHidden({...groupHidden, email: !groupHidden.email});
                            }}/>
                           
                        </div>
                        <div className={`fl-settings-notification ${state.email.AccountDeactivated ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='ACCOUNT DEACTIVATED' content='You’ll receive an e-mail if you deactivate your account.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.AccountDeactivated} onClick={() => {
                                    sendSettingChange('email', 'AccountDeactivated', !state.email.AccountDeactivated);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.AccountDeactivated ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.AccountDeleted ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='ACCOUNT DELETED' content='You’ll receive an e-mail if you permanently delete your account.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.AccountDeleted} onClick={() => {
                                    sendSettingChange('email', 'AccountDeleted', !state.email.AccountDeleted);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.AccountDeleted ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.AccountReactivated ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='ACCOUNT REACTIVATED' content='You’ll receive an e-mail if you reactive your account.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.AccountReactivated} onClick={() => {
                                    sendSettingChange('email', 'AccountReactivated', !state.email.AccountReactivated);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.AccountReactivated ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.NewPostCommentEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='COMMENTS' content="You'll receive an e-mail when someone comments on your posts."/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.NewPostCommentEmail} onClick={() => {
                                    sendSettingChange('email', 'NewPostCommentEmail', !state.email.NewPostCommentEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.NewPostCommentEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.RequestedUserVerificationEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='ID SUBMITTED' content='You’ll receive an e-mail once you have requested account verification.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.RequestedUserVerificationEmail} onClick={() => {
                                    sendSettingChange('email', 'RequestedUserVerificationEmail', !state.email.RequestedUserVerificationEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.RequestedUserVerificationEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.YouAreMentionedInPost ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='MENTIONS' content="You'll receive an e-mail when someone mentions you in a post or comment."/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.YouAreMentionedInPost} onClick={() => {
                                    sendSettingChange('email', 'YouAreMentionedInPost', !state.email.YouAreMentionedInPost);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.YouAreMentionedInPost ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.NewPost ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='NEW CONTENT' content='You’ll receive an e-mail when a Creator you’re subscribed to uploads new content.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.NewPost} onClick={() => {
                                    sendSettingChange('email', 'NewPost', !state.email.NewPost);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.NewPost ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.NewMessage ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='NEW MESSAGES' content='You’ll receive an e-mail when someone sends you a new message.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.NewMessage} onClick={() => {
                                    sendSettingChange('email', 'NewMessage', !state.email.NewMessage);
                                }} />
                                <span className={`fl-settings-toggle-span`}>{state.email.NewMessage ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.NewSubscriberEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='NEW SUBSCRIBERS' content='You’ll receive an e-mail when you get a new subscriber.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.NewSubscriberEmail} onClick={() => {
                                    sendSettingChange('email', 'NewSubscriberEmail', !state.email.NewSubscriberEmail);
                                }} />
                                <span className={`fl-settings-toggle-span`}>{state.email.NewSubscriberEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.ManualRequestedPayout ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PAYOUT REQUESTS' content='You’ll receive an e-mail when you request a payout.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.ManualRequestedPayout} onClick={() => {
                                    sendSettingChange('email', 'ManualRequestedPayout', !state.email.ManualRequestedPayout);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.ManualRequestedPayout ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.PayoutSentToCreatorEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PAYOUT SENT' content='You’ll receive an e-mail when your payout has been sent.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.PayoutSentToCreatorEmail} onClick={() => {
                                    sendSettingChange('email', 'PayoutSentToCreatorEmail', !state.email.PayoutSentToCreatorEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.PayoutSentToCreatorEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.ContentUnlockedChatEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PPV MESSAGE UNLOCKED' content='You’ll receive an e-mail when someone unlocks your PPV messages.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.ContentUnlockedChatEmail} onClick={() => {
                                    sendSettingChange('email', 'ContentUnlockedChatEmail', !state.email.ContentUnlockedChatEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.ContentUnlockedChatEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.PostUnlockedEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='PPV POST UNLOCKED' content='You’ll receive an e-mail when someone unlocks your PPV posts.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.PostUnlockedEmail} onClick={() => {
                                    sendSettingChange('email', 'PostUnlockedEmail', !state.email.PostUnlockedEmail);
                                }} />
                                <span className={`fl-settings-toggle-span`}>{state.email.PostUnlockedEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.NewReferral ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='REFERRALS' content='You’ll receive an e-mail when someone you referred joins Fluffa.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.NewReferral} onClick={() => {
                                    sendSettingChange('email', 'NewReferral', !state.email.NewReferral);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.TipReceived ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.SubscriptionRenewedEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='REPEAT SUBSCRIBERS' content='You’ll receive an e-mail when a subscriber is rebilled.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.SubscriptionRenewedEmail} onClick={() => {
                                    sendSettingChange('email', 'SubscriptionRenewedEmail', !state.email.SubscriptionRenewedEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.SubscriptionRenewedEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.TipReceivedEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='TIPS' content='You’ll receive an e-mail when someone tips you.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.TipReceivedEmail} onClick={() => {
                                    sendSettingChange('email', 'TipReceivedEmail', !state.email.TipReceivedEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.TipReceivedEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                        <div className={`fl-settings-notification ${state.email.VerifiedEmail ? 'fl-settings-enabled' : ''} fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'} fl-settings-mt-10` }>
                            <ExpandingContent header='VERIFICATION' content='You’ll receive an e-mail once your account has been approved or rejected.'/>
                            <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={state.email.VerifiedEmail} onClick={() => {
                                    sendSettingChange('email', 'VerifiedEmail', !state.email.VerifiedEmail);
                                }}/>
                                <span className={`fl-settings-toggle-span`}>{state.email.VerifiedEmail ? 'On' : 'Off'}</span>
                            </div>
                        </div>
                    </div>
                        <div className={`fl-group-toggle fl-toogle-${!groupHidden.email ? 'visible' : 'hidden'}`}>
                        <span >
                            {!groupHidden.email && (!groupEnabled.email ? 'TURN OFF ALL EMAIL NOTIFICATIONS' : 'TURN ON ALL EMAIL NOTIFICATIONS')}
                        </span>
                        <div className="fl-settings-notification-toggle">
                                <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={!groupEnabled.email} onClick={() => {
                                    emailToggleAll(groupEnabled.email);
                                    setGroupToggle(!groupEnabled.email, false);
                                }}/>
                        </div>
                     </div>
                </div>
            </div>
            <div className={`fl-settings-notification-major fl-settings-notification-major-${userData.currentTheme}`}>
                <p>Would you like to receive Fluffa newsletters? These may include recommendations, reminders, new features, special offers and more. </p>
                <div className='fl-settings-notification-major'>
                    <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={newsletters} 
                    onClick={() => {
                        setNewsletters(!newsletters)
                    }}/>
                    <p >
                        {newsletters ? "Yes please! I'd love to receive Fluffa newsletters." : "No thanks! I don't want to receive Fluffa newsletters."}
                    </p>
                </div>
            </div>
        </Fragment>
    )
}