import React, {FC, useEffect, useState} from "react";
import "./Support.css";
import "../Privacy.css";
import { useEnvContext } from "../../context/EnvContext";
import { useModalContext } from "../../context/ModalContext";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import axios from "axios";
import {SingleProblem} from "./SingleProblem";
import {SimpleCard} from "../../components/SimpleCard";
import ReactLoading from "react-loading";
import {useUserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

export const Support: FC<any> = (props) => {
    const envData = useEnvContext();
    const modalData = useModalContext();
    const userData = useUserContext()
    const history = useHistory()
    const [status, setStatus] = useState<any>(localStorage.getItem("ticket") === "close" ? "resolved" : "in_progress")
    const [animation,setAnimation] = useState(false)
    const getAllReports = (page= 1, statusReports = status) => {
        if(page === 1) {
            setAnimation(true)
        }
        axios.get(`${envData.apiUrl}/problem/reports${props.page === "bug" ? "?" : `?type=${props.page}&` }page=${page}&status=${statusReports}`).then(response => {
            props.setReports({
                ...props.reports,
                reports: page === 1 ? response.data.data : [...props.reports.reports, ...response.data.data],
                page: page,
                last_page: response.data.meta.last_page
            })
            setAnimation(false)
        }).catch(() => {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        });
    }

    useEffect(() => {
        getAllReports()
    },[])

    useEffect(() => {
        if(localStorage.getItem("ticket") === "close") {
            localStorage.removeItem("ticket")
        }
    },[localStorage.getItem("ticket")])
    useBottomScrollListener(() => {
     if(props.reports.page < props.reports.last_page) {
         getAllReports(props.stateRef.current.page + 1)
     }
    });

    const filterReports = async (status:string) => {
        setStatus(status)
        await getAllReports(1,status)
    }

    return (
        <SimpleCard>
            <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                     <span
                         className="fl-direct-messages-title-icon"
                         onClick={(e) => history.push('/support')}
                     >
                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                    </span>
                Report a bug
            </div>
            <div className={"fl-support-filter"}>
                <span className={status === "in_progress" ? "active" : ""} onClick={() => filterReports("in_progress")}>Open {props.location === "my-tickets" ? `(${userData.user.profile.meta.tickets_in_progress})` : ""}</span> <b>|</b> <span className={status === "resolved" ? "active" : ""}  onClick={() => filterReports("resolved")}>Closed {props.location === "my-tickets" ? `(${userData.user.profile.meta.tickets_resolved})` : ""}</span>
            </div>
            {(userData.user.profile.meta.tickets_in_progress === 0 && status === "in_progress") || (userData.user.profile.meta.tickets_resolved === 0 && status === "resolved") ?
                <SimpleCard>
                    <p className={"fl-tickets-empty"}>
                        You don't have any {status === "in_progress" ? "open" : "closed"} tickets.
                    </p>
                </SimpleCard> : animation ?
                <SimpleCard>
                    <div className={"fl-loader-animation"}>
                        <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                    </div>
                </SimpleCard>
                :
                <>
                    {props.reports.reports.map((x:any,y:number) => (
                        <>
                            <SingleProblem data={x} key={y} reports={props.reports} setReports={props.setReports} />
                        </>
                    ))}
                </>
            }
        </SimpleCard>
  );
};
