import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { Subroutes } from '../components/Subroutes';
import { useUserContext } from '../context/UserContext';
import {WritePost} from "../components/WritePost";
import {RouteHolder} from "../routes/RouteHolder";

export const BothRoute:FC<any> = (props) => {

    const userData = useUserContext();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
        <div className={"fl-main-container"}>
            <React.Fragment>
                {userData.authStatus === 'UNAUTHENTICATED' ? <Route {...props}/> : ''}
                {userData.authStatus === 'AUTHENTICATED' ? <React.Fragment>
                    {!isMobile && <Subroutes/> }
                    <div className={`${userData.mobilePost ? "fl-route-not-visible" : ""} fl-main-container-row`}>
                        <Route {...props}/>
                    </div>
                    {userData.mobilePost ? <div className={"fl-mobile-create-post"}><WritePost mobileResponsive={true} onClear={() => userData.mobileCreatePost(false)} quickPost/></div> : "" }
                </React.Fragment> : ''}
                {userData.authStatus === 'CHECKING' ? <RouteHolder/> : ''}
            </React.Fragment>
        </div>
    )
}