import {
    faChartLine,
    faChevronCircleLeft, faHandHoldingUsd, faInfoCircle,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FC, Fragment, useEffect, useLayoutEffect, useRef, useState} from 'react'
import NumberFormat from 'react-number-format';
import {Link, useHistory} from 'react-router-dom';
import { Button } from '../components/Button';
import { MInput } from '../components/MInput';
import { useUserContext } from '../context/UserContext';
import './SettingsEarnings.css';
import padLock from '../assets/images/padlock.svg';
import moment from 'moment';
import axios from 'axios';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import Axios from "axios";
import {SearchMInput} from "../components/SearchMInput";
import ReactLoading from "react-loading";
import ReactTooltip from "react-tooltip";
import DollarBagGrey from "../assets/images/icons/dollarbaggrey.svg";
import DollarBagDark from "../assets/images/icons/dollarbagdark.svg";

export const SettingsEarnings:FC<any> = (props) => {

    const history = useHistory();
    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();

    const [state, setState] = useState<any>({
        wallet_balance: 0,
        banks: [],
        adding: false,
        bank: {
            swift_bic: '',
            iban:"",
            currency:0
        },
        profile: {
            firstname: userData.user.profile.firstname || "",
            lastname: userData.user.profile.lastname || "",
            city_id: Number(userData.user.profile.country_id) || 0,
            country_id: Number(userData.user.profile.country_id) || 0,
            address: userData.user.profile.address || "",
            postcode: userData.user.profile.postcode || ""
        }
    })

    const [transactions, setTransactions] =  useState<any>({
        transactions: [],
        currentPage: 1,
        last_page: 0,
    })

    const stateRef = useRef(state);
    useEffect(() => { stateRef.current = state; }, [state]);

    const formRef = useRef<HTMLFormElement>(null);

    const [animation,setAnimation] = useState(true)

    const getBankData = async () => {
        try {
            const res = await axios.get(`${envData.apiUrl}/creator/payout/account`);
            setState({...state, banks: res.data.data});
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const getTransactions = async (page = 1) => {
        try {
            const res = await axios.get(`${envData.apiUrl}/earnings?page=${page}&order=created_at&direction=desc`);
            setTransactions({
                ...transactions,
                transactions: [...transactions.transactions, ...res.data.data],
                currentPage: page,
                last_page: res.data.meta.last_page,
            });
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useLayoutEffect(() => {
        (async () => {
            await getTransactions();
        })();
    }, []);

    useEffect(() => {
        (async() => {
            await getBankData();
        })()
    },[])

    const clearBank = () => {
        setState({...state, adding: false, bank: {
            iban: '',
            swift_bic: ''
        }})
    }
    const addBankValid = () => {
        if(state.profile.firstname.length > 0 && state.profile.lastname.length > 0 && state.profile.city_id > 0 && state.profile.country_id > 0 && state.profile.address.length > 0
            && state.profile.postcode.length > 0 && !animationAdding && state.bank.swift_bic.length > 0 && state.bank.iban.length > 0 && ["EUR","USD","GBP"].includes(state.bank.currency)) {
            return true
        } else {
            return false
        }
    }
    const [animationAdding,setAnimationAdding] = useState(false)

    const addBank = async () => {
         if(!addBankValid())
             return;
        setAnimationAdding(true)
        axios.post(`${envData.apiUrl}/users/profile/general`, state.profile).then((result: any) => {
            axios.post(`${envData.apiUrl}/creator/payout/account`, state.bank).then((res: any) => {
                setState({...state, banks:  res.data.data, adding: false, bank: {
                        swift_bic: '',
                        iban:'',
                        currency:""
                    }});
                userData.addBank(true)
                setAnimationAdding(false)
                modalData.pushToast("success", "You've added a bank account");
            }).catch(async (error) => {
                const errorBank = error?.response?.data?.errors?.message[0]
                const errorMessage = error?.response?.data?.message

                await modalData.push({
                    title: "ERROR",
                    description: [
                        `${errorMessage ? `${errorMessage}` : "" }`,
                        `${errorBank?.iban ? `${errorBank?.iban}` : "" }`,
                        `${errorBank?._global ? `${errorBank?._global[0]}` : "" }`
                    ],
                    warnings: [
                        `${errorBank?.correct_iban ? `Please copy and paste the following into the SWIFT/BIC field: <b>${errorBank?.correct_iban}</b>` : "" }`,
                    ],
                    buttons: [
                        () => (
                            <div className="fl-d-flex fl-justify-flex-end">
                                <Button type="normal" onClick={() => modalData.closeAll()}>
                                    Dismiss
                                </Button>
                            </div>
                        ),
                    ],
                    copyButton: errorBank?.correct_iban ? `${errorBank?.correct_iban}` : ""
                });
                setAnimationAdding(false)
            });
        }).catch(() => {
            setAnimationAdding(false)
            modalData.pushToast("error", "Something went wrong!")
        });
    }
    const redirectProfile = (e:any) => {
        if (e.ctrlKey || e.metaKey) {
            history.push(`/settings`);
        } else {
            history.push(`/settings`);
        }
    }

    const removeBank = async (bank: number) => {
        modalData.push(() => <RemoveBankModal bank={bank} state={state} setState={setState} />)
    };

    const [countries, setCountries] = useState<
        Array<{ category: string; options: Array<any> }>
        >([]);
    const [cities, setCities] = useState<
        Array<{ category: string; options: Array<any> }>
        >([]);

    const [currency,setCurrency] = useState<any>({
        options: [{value:"USD",label:"USD - US Dollar"},{value:"EUR",label:"EUR - Euro"},{value:"GBP",label:"GBP - British Pound"}]
    })

    const getCountries = async () => {
        const res = await Axios.get(`${envData.apiUrl}/countries`);
        const allCountries = res.data.data;
        const mostPopular1 = allCountries
            .filter((x: { id: number; name: string; code: string }, y: number) =>
                ["GB", "US"].includes(x.code)
            )
            .map((x: { id: number; name: string; code: string }, y: number) => ({
                value: x.id,
                label: x.name,
                code: x.code,
            }));

        const mostPopular2 = allCountries
            .filter((x: { id: number; name: string; code: string }, y: number) =>
                ["AU", "CA", "FR", "DE", "IT", "ES", "SE", "RU"].includes(x.code)
            )
            .map((x: { id: number; name: string; code: string }, y: number) => ({
                value: x.id,
                label: x.name,
                code: x.code,
            }));

        const allOptions = allCountries.map(
            (x: { id: number; name: string; code: string }, y: number) => ({
                value: x.id,
                label: x.name,
                code: x.code,
            })
        );

        setCountries([
            { category: "Most Popular", options: [...mostPopular1, ...mostPopular2] },
            { category: "All Countries", options: allOptions },
        ]);
    };

    useEffect(() => {
        getCountries();
    }, []);

    const [clear3, setClear3] = useState(0);

    const updateCountry = (id: string) => {
        setClear3(1);
        setState({...state, profile: {...state.profile, country_id: Number(id)}})
    };

    const getCities = async (allOptions: Array<any>) => {
        setCities([{ category: 'All cities', options: allOptions }])
    }

    const checkTypeAction = (transaction:any) => {
        if (transaction.product?.subscription) {
            return <>Subscription {transaction?.type === "subscription_renewal" && "renewal"} from <Link className="fl-transaction-link" to={`/${transaction.userProfile?.handle}`}>@{transaction.userProfile?.handle}</Link></>
        } else if(transaction.product?.tip_message === null || transaction.product?.tip_message?.length > 0  ) {
            return <>Tip from <Link className="fl-transaction-link" to={`/${transaction.userProfile?.handle}`}>@{transaction.userProfile?.handle}</Link></>
        } else if(transaction.product?.post) {
            return <>Post unlocked from <Link className="fl-transaction-link" to={`/${transaction.userProfile?.handle}`}>@{transaction.userProfile?.handle}</Link></>
        } else if(transaction.product?.chatMessage) {
            return <>Message unlocked from <Link className="fl-transaction-link" to={`/${transaction.userProfile?.handle}`}>@{transaction.userProfile?.handle}</Link></>
        }
    }
    const [clear, setClear] = useState(0);
    return (
        <Fragment>
            {animation &&
                <div className="fl-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                          <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                          </span>
                          Earnings
                    </div>
                    <div className="fl-settings-sub-payment-balance fl-settings-mt-30 fl-settings-payment-total-earnings">
                        <div className={`fl-settings-sub-payment-balance-single fl-settings-sub-payment-balance-single-${userData.currentTheme}`}>
                            <div className={`fl-settings-sub-payment-balance-single-bg fl-settings-sub-payment-balance-single-bg-${userData.currentTheme}`}>
                                <img alt={"fluffa"} src={userData.currentTheme === "dark" ? DollarBagDark : DollarBagGrey} />
                            </div>
                            <div className="fl-settings-sub-payment-balance-single-content">
                                <div className={`fl-settings-sub-payment-balance-single-label fl-settings-sub-payment-balance-single-label-${userData.currentTheme}`}>
                                    All time earnings
                                </div>
                                <div className="fl-settings-sub-payment-balance-single-value">
                                    <NumberFormat value={userData?.user?.profile?.creatorProfile?.total_earnings || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fl-settings-sub-payment-balance">
                        <div className={`fl-settings-sub-payment-balance-single fl-settings-sub-payment-balance-single-${userData.currentTheme}`}>
                            <div className={`fl-settings-sub-payment-balance-single-bg fl-settings-sub-payment-balance-single-bg-${userData.currentTheme}`}>
                                <FontAwesomeIcon icon={faHandHoldingUsd}/>
                            </div>
                            <div className="fl-settings-sub-payment-balance-single-content">
                                <div className={`fl-settings-sub-payment-balance-single-label fl-settings-sub-payment-balance-single-label-${userData.currentTheme}`}>
                                    Available funds {" "}
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        data-tip
                                        data-for="total"
                                    />
                                    <ReactTooltip id="total">
                                        These funds are available for payout and don't include your processing funds. Payouts are sent automatically on the 1st and 16th of every month to your chosen bank account.
                                    </ReactTooltip>
                                </div>
                                <div className="fl-settings-sub-payment-balance-single-value">
                                    <NumberFormat value={userData?.user?.profile?.creatorProfile?.total_unpaid_earnings || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/>
                                </div>
                            </div>
                        </div>
                        <div className={`fl-settings-sub-payment-balance-single fl-settings-sub-payment-balance-single-${userData.currentTheme}`}>
                            <div className={`fl-settings-sub-payment-balance-single-bg fl-settings-sub-payment-balance-single-bg-${userData.currentTheme}`}>
                                <FontAwesomeIcon icon={faChartLine}/>
                            </div>
                            <div className="fl-settings-sub-payment-balance-single-content">
                                <div className={`fl-settings-sub-payment-balance-single-label fl-settings-sub-payment-balance-single-label-${userData.currentTheme}`}>
                                    Processing {" "}
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        data-tip
                                        data-for="unpaid"
                                    />
                                    <ReactTooltip id="unpaid">
                                        These funds aren't available for payout yet but will be moved to your Available funds shortly.
                                    </ReactTooltip>
                                </div>
                                <div className="fl-settings-sub-payment-balance-single-value">
                                    <NumberFormat value={userData?.user?.profile?.creatorProfile?.total_processing_earnings || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                        {state.banks.iban !== null ?
                            <div>
                                Your Bank Account {" "}
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    data-tip
                                    data-for="bank-info"
                                />
                                <ReactTooltip id="bank-info">
                                    We'll use these details to send your payouts
                                </ReactTooltip>
                            </div>
                            : <div style={{color: "#ff4444"}}>No bank account added - please add a bank account</div>}
                    </div>
                    <div className="fl-settings-sub-section">
                        <div className="fl-settings-sub-payment-secured">
                            <img src={padLock} alt={"fluffa"}/><span>Your bank details are secure</span>
                        </div>
                    </div>
                    <div className="fl-settings-sub-section">
                        <div className={`fl-settings-sub-payment-cards fl-settings-sub-payment-cards-${userData.currentTheme}`}>
                            {userData.user.has_payout_account ?
                                <div className={`fl-settings-sub-payment-card fl-settings-sub-payment-card-${userData.currentTheme}`}>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-number fl-settings-sub-payment-iban-title">
                                        <span className={"fl-settings-grey"}>IBAN:</span>
                                    </div>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-number fl-settings-sub-payment-iban-number">
                                        {state.banks.iban}
                                    </div>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-number">
                                        <span className={"fl-settings-grey"}>SWIFT/BIC:</span>
                                    </div>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-number">
                                        {state.banks.swift_bic}
                                    </div>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-number">
                                        <span className={"fl-settings-grey"}>CURRENCY:</span>
                                    </div>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-number">
                                        {state.banks.currency}
                                    </div>
                                    <div className="fl-settings-sub-payment-card-cell fl-settings-sub-payment-card-remove">
                                        <span
                                            className="fl-settings-sub-payment-card-remove-icon"
                                            onClick={() => removeBank(0)}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                        </span>
                                    </div>
                                </div> : ""
                            }
                            {state.adding ? <div className="fl-settings-sub-payment-card-add">
                                <div className={`fl-settings-sub-payment-card-add-inner fl-settings-sub-payment-card-add-inner-${userData.currentTheme}`}>
                                    <form className="fl-settings-sub-payment-card-add-form" ref={formRef}>
                                        <MInput className={"fl-settings-mt-30"} label="SWIFT/BIC" value={state.bank.swift_bic.length > 15 ? state.bank.swift_bic.slice(0,15) : state.bank.swift_bic } setValue={(swift_bic) => setState({...stateRef.current, bank: {...stateRef.current.bank, swift_bic}})} type="text" size="20"/>
                                        <MInput className={"fl-settings-mt-30"} label="IBAN" value={state.bank.iban.length > 50 ? state.bank.iban.slice(0,50) : state.bank.iban } setValue={(iban) => setState({...stateRef.current, bank: {...stateRef.current.bank, iban}})} type="text" size="20"/>
                                        <MInput
                                            preRender={(x) => (
                                                <div className={`fl-settings-sub-verification-country-img flag-icon-background flag-icon-${x.value.toLowerCase()}`}>
                                                </div>
                                            )}
                                            type="select"
                                            label="Select payout currency"
                                            value={state.bank.currency}
                                            setValue={(currency) => setState({...stateRef.current, bank: {...stateRef.current.bank, currency}})}
                                            options={[
                                                {
                                                    options: currency.options,
                                                },
                                            ]}
                                            clear={clear}
                                            setClear={setClear}
                                            className={" fl-settings-mt-30"}
                                            hasInfo={true}
                                            infoId="payout-currency"
                                            infoText={["You'll get the best rates if you choose your local currency. ","If your local currency isn't shown then choose your preferred currency.","Please note, your receiving bank may charge their own fee, or set their own exchange or FX rate (foreign exchange rate),"," which unfortunately are out with our control."]}
                                        />
                                        <div>
                                            <MInput className={"fl-settings-mt-30"} label="First name" value={state.profile.firstname} setValue={(firstname) => setState({...stateRef.current, profile: {...stateRef.current.profile, firstname}})} type="text" size="20"/>
                                            <MInput className={"fl-settings-mt-30"} label="Last name" value={state.profile.lastname} setValue={(lastname) => setState({...stateRef.current, profile: {...stateRef.current.profile, lastname}})} type="text" size="20"/>
                                            <MInput
                                                preRender={(x) => (
                                                    <div className={`fl-settings-sub-verification-country-img flag-icon-background flag-icon-${x.code.toLowerCase()}`}>
                                                    </div>
                                                )}
                                                type="select"
                                                label="Country"
                                                value={state.profile.country_id}
                                                setValue={(country) => updateCountry(country)}
                                                options={countries}
                                                className={"fl-settings-mt-30"}
                                            />
                                            <MInput className={"fl-settings-mt-30"} label="Address" value={state.profile.address} setValue={(address) => setState({...stateRef.current, profile: {...stateRef.current.profile, address}})} type="text" size="20"/>
                                            <div  className={"fl-settings-mt-30"}>
                                                <SearchMInput
                                                    searchUrl={`${envData.apiUrl}/country/${state.profile.country_id}/cities/search`}
                                                    type="select"
                                                    label="Type to search for your Town/City/State"
                                                    category='All cities'
                                                    value={state.profile.city_id}
                                                    options={cities}
                                                    setOptions={getCities}
                                                    clear={clear3}
                                                    setClear={setClear3}
                                                    setValue={(city_id) => setState({...state, profile: {...state.profile, city_id:city_id}})}
                                                    hideArrow
                                                />
                                            </div>
                                            <MInput className={"fl-settings-mt-30"} label="Postcode" value={state.profile.postcode} setValue={(postcode) => setState({...stateRef.current, profile: {...stateRef.current.profile, postcode}})} type="text" size="20"/>
                                        </div>
                                        <div className="fl-settings-sub-payment-accept-text">
                                            By continuing, you agree to Fluffa's <Link className="fl-settings-sub-payment-accept-text-link" to="/terms">Terms of Service</Link>. <Link className="fl-settings-sub-payment-accept-text-link" to="/privacy">The Privacy Notice</Link> describes how your data is handled.
                                        </div>
                                    </form>
                                    <div className="fl-settings-sub-payment-card-buttons">
                                        <Button className="fl-settings-sub-security-button" onClick={clearBank}>Cancel</Button>
                                        <Button className="fl-settings-sub-security-button" type="normal" onClick={addBank} disabled={!addBankValid()}>
                                            Add{animationAdding ? "ing" : ""} bank
                                            {animationAdding ? <span style={{marginLeft: '5px' }}><ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> </span> : ""}
                                        </Button>
                                    </div>
                                </div>
                            </div> : state.banks.iban == null ? <div className={`fl-settings-sub-payment-card fl-settings-sub-payment-add-bank fl-settings-sub-payment-card-${userData.currentTheme}`}>
                                <div className="fl-settings-sub-payment-card-append" onClick={() => setState({...stateRef.current, adding: true})}>
                                    + ADD BANK ACCOUNT
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>All Earnings</div>
                    <div className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme}`}>
                        <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-head">
                            <div className="fl-settings-sub-payment-transactions-single-date">Date</div>
                            <div className="fl-settings-sub-payment-transactions-single-amount">Amount</div>
                            <div className="fl-settings-sub-payment-transactions-single-amount">Fees</div>
                            <div className="fl-settings-sub-payment-transactions-single-amount">Net</div>
                        </div>
                        {transactions.transactions.map((x: any, y: number) => (
                            <div
                                key={y}
                                className={`fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-action fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                            >
                                <div className="fl-settings-sub-payment-transactions-single-date">
                                    <p>{moment(x.created_at).format("D MMM YYYY")},  {moment(x.created_at).format("HH:mm")}</p>
                                    <div className={`fl-transaction-space-${userData.currentTheme}`}></div>
                                    <p>{checkTypeAction(x)}</p>
                                </div>
                                <div className="fl-settings-sub-payment-transactions-single-amount">
                                    {x.amount > 0 ? "+" : x.amount === 0 ? "" : "-"}$
                                    {x.amount.toFixed(2)}
                                </div>
                                <div className="fl-settings-sub-payment-transactions-single-amount fl-red">
                                    {x.fee > 0 ? "-" : x.fee === 0 ? "" : "-"}{x.fee}%
                                </div>
                                <div className="fl-settings-sub-payment-transactions-single-amount">
                                    ${(x.amount-(x.amount*x.fee/100)).toFixed(2)}
                                </div>
                            </div>
                        ))}
                        {transactions.transactions.length > 0 &&
                        <div
                            className="fl-settings-sub-payment-transactions-more"
                            onClick={() => getTransactions(transactions.currentPage + 1)}
                        >
                            {transactions.currentPage >= transactions.last_page
                                ? ""
                                : "View more transactions"}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const RemoveBankModal: FC<any> = ({ ...props }) => {

    const modalData = useModalContext()
    const userData = useUserContext()
    const envData = useEnvContext()

    const [animationRemoving,setAnimationRemoving] = useState(false)
    const processBankRemove = async (bank: number) => {
        setAnimationRemoving(true)
        try {
            await axios.delete(`${envData.apiUrl}/creator/payout/account`);
            props.setState({...props.state, banks: {iban:null, swift_bic: null}});
            await modalData.close();
            userData.addBank(false)
            modalData.pushToast("success", "You've removed a bank account");
        } catch (e) {
            await modalData.close();
            modalData.pushToast("error", "Something went wrong!");
        }
        setAnimationRemoving(false)
    };

    return (
        <Fragment >
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">Remove bank account</div>
            </div>
            <div className="fl-modal-description">
                <div className="fl-modal-description-row">Are you sure you want to remove this bank account?</div>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={() => modalData.close()}>Cancel</Button>
                <Button type="normal" disabled={animationRemoving} onClick={() => processBankRemove(props.bank)}>
                    Remov{animationRemoving ? "ing" : "e"} bank
                    {animationRemoving ? <span style={{marginLeft: '5px' }}><ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20}/> </span> : ""}
                </Button>
            </div>
        </Fragment>
    )}
