import React, {FC, Fragment, useEffect, useRef, useState} from 'react'
import {useUserContext} from "../../context/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faBan,
    faCamera, faEllipsisH,
    faHdd, faImage, faMicrophoneAlt,
    faMoneyBillWave, faPaperclip,
    faSmile, faSyncAlt, faTimes, faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {useModalContext} from "../../context/ModalContext";
import Picker from "emoji-picker-react";
import {PaperPlaneIcon, RedoAltIcon} from "react-line-awesome";
import ReactLoading from "react-loading";
import {FluffaDrive} from "../../components/FluffaDrive";
import TipModal from "../../components/TipModal/TipModal";
import axios from "axios";
import {useEnvContext} from "../../context/EnvContext";
import {faStopCircle} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import {MessageAudio} from "../../components/MessageAudio";
import { Link } from 'react-router-dom';
//@ts-ignore
import { ReactMic } from 'react-mic';
var localStream: MediaStream | null = null;
var localTimer: any = null;
export const CreateForm:FC<any> = (props) => {

    const envData = useEnvContext()
    const userData = useUserContext()
    const modalData = useModalContext()
    const emojiRef = useRef<HTMLDivElement>(null);
    const cameraRef = useRef<HTMLVideoElement>(null);
    const attachRef = useRef<any>(null);
    const [camera,setCamera] = useState(false)
    const [emojiState, setEmojiState] = useState({
        active: false,
    });

    const requestCamera = async () => {
        setCamera(true)
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
        });
        localStream = stream;
        if (cameraRef.current) {
            cameraRef.current.srcObject = stream;
        }
    };

    const closeCamera = async () => {
        if (localStream) localStream.getTracks().forEach((x) => x.stop());
        setCamera(false)
    };

    const snapCamera = () => {
        if (!cameraRef.current) return;

        var canvas = document.createElement("canvas");
        canvas.width = cameraRef.current.offsetWidth;
        canvas.height = cameraRef.current.offsetHeight;
        var ctx = canvas.getContext("2d");

        //@ts-ignore
        if (ctx) {
            ctx.translate(canvas.width, 0);
            ctx.scale(-1, 1);
            ctx.drawImage(cameraRef.current, 0, 0, canvas.width, canvas.height);
            ctx.restore();
        }
        var src = canvas.toDataURL("image/jpg");
        var blobBin = atob(src.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) array.push(blobBin.charCodeAt(i));
        var file = new File([new Uint8Array(array)], "snap.jpeg",{type: "image/jpeg"});

        props.setUploadState({
            ...props.uploadState,
            files: [...props.uploadState.files, { type: "image", src, file }],
        });
        closeCamera();
    };

    const handleFile = async (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files) return;
        const uploadedFiles: any = e.currentTarget.files || [];
        const filesArray: any = [...uploadedFiles];
        const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
            const fileType = file.type.split("/")[0]
            return {
                type: fileType,
                file,
                src: URL.createObjectURL(file),
            };
        });
        props.setUploadState({
            ...props.uploadState,
            files: [...props.uploadState.files, ...newFiles],
        });
        attachRef.current.value = ""
    };

    const [send,setSend] = useState(false)
    const sendMessage = async () => {
        setSend(true);
        await props.submitMessage()
        setSend(false);
    }

    const handleEnter = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !disableSend() && !props.animationSend && !send) {
            await sendMessage()
        }
    };

    const typeMessage = async (body: string) => {
        if(props.restrictedWords.length > 0){
            props.setRestrictedWords([])
        }
        props.setState({ ...props.stateRef.current, body });
        if(body.length < 2) {
            await axios.post(
                `${envData.apiUrl}/conversations/${props.conversationId}/typing`
            );
        }
/*       if(!props.typingFirst) {
           try {
               await axios.post(
                   `${envData.apiUrl}/conversations/${props.conversationId}/typing`
               );
               props.setTypingFirst(true)
           } catch (e) {
               modalData.pushToast("error", "Something went wrong!");

           }
       }*/
    };

    useEffect(() => {
        const trackClick = (e: MouseEvent) => {
            if (emojiRef.current && e.target !== null) {
                if (
                    e.target !== emojiRef.current &&
                    //@ts-ignore
                    !emojiRef.current.contains(e.target)
                ) {
                    setEmojiState({ active: false });
                }
            }
        };
        window.addEventListener("click", trackClick);
        return () => {
            window.removeEventListener("click", trackClick);
        };
    }, []);

    const disableSend = () => {
        if(props.progress.uploading || (props.state.body.length === 0 && props.uploadState.files.length === 0) || props.animationSend) {
            return true
        }else {
            return false
        }
    }

    const openDrive = () => {
        modalData.push(() => <FluffaDrive route={"files"}  setUploadState={props.setUploadState} uploadState={props.uploadState} />)
    }

    const [state, setState] = useState<any>({
        recording: false,
        timer: 0,
        previewVoice:{},
        record: false
    });
    const waveformRef = useRef<any>();
    const trashRef = useRef<HTMLDivElement>(null);
    const stateRef = useRef<any>();

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const increaseTimer = () => {
        setState({
            ...stateRef.current,
            timer: stateRef.current.timer + 1000
        });
        localTimer = setTimeout(increaseTimer, 1000);
    };

    const [recordstate, setrecordstate] = useState("none");
    const [blobURL, setblobURL] = useState("");

    const deleteRecording = () => {
        setrecordstate("none")
        setblobURL("")
    };

    const recordingSignal = async () => {
         await axios.post(
             `${envData.apiUrl}/conversations/${props.state.otherUser.meta.conversation_id}/recording`
         );
    }
    const start = () => {
        setTimeout(() => {
            recordingSignal()
            increaseTimer()
        }, 1250);
        setState({...state,record:true})
    };

    const stop = () => {
        if (localTimer) clearTimeout(localTimer);
        setState({
            ...stateRef.current,
            timer: 0
        });
        setState({...state,record:false})
    };

    const onStop = (audioData:any) => {
        setblobURL(audioData.blobURL);
        var file = new File([audioData.blob], "record.ogg");
        const fakeMessagePreview = {
            audio: audioData.blobURL,
            file:file
        };
        props.setUploadAudio(fakeMessagePreview)
        setState({
            ...stateRef.current,
            timer: 0
        });
    };

    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // const handleResize = () => {
    //     if(window.innerWidth < 991.98) {
    //         setMobile(true)
    //     }else {
    //         setMobile(false)
    //     }
    // }
    // useEffect(() => {
    //     window.addEventListener("resize", handleResize)
    // })

    const checkBlockedMe = () => {
        return props.state.restricted_me
    }

    const checkBlockedByMe = () => {
        return props.state.restricted_by_me
    }


    const checkSubscribe = () => {
        if(props.state.otherUser.meta.is_subscribed || userData.user.id === props.state.otherUser.id) {
            return true
        } else {
            return false
        }
    }

    return (
        checkBlockedMe() || checkBlockedByMe() || !checkSubscribe() ?
            <div className={`fl-direct-messages-box-input-form-disabled`}>
                <div className="fl-post-head-actions-dropdown-option-icon">
                    <FontAwesomeIcon icon={checkBlockedMe() || checkBlockedByMe() ? faBan : faExclamationTriangle} />
                </div>
                {checkBlockedByMe() ? "You have restricted this user." : checkBlockedMe() ? "You are unable to send messages to this user." :
                <>
                    Please <Link className={"fl-create-form-link"} to={`/${props?.state?.otherUser?.handle}`}>subscribe</Link> to <b>@{props.state.otherUser.handle}</b> send messages.
                </>
                }
            </div>
            :
            <>
                {blobURL ?
                    <div className={"fl-inbox-record"}>
                        <div className={"fl-direct-messages-box-input-form-inner"}>
                            <MessageAudio
                                deleteRecording={deleteRecording}
                                src={
                                    blobURL
                                }
                                recording={true}
                                sendRecording={props.submitMessage}
                                data={""}
                                reportedStatus={""}
                            />
                        </div>
                    </div>
                    : "" }
                <div className={`fl-inbox-record fl-inbox-record-${state.record ? "start" : "none"}`} >
                    <div className="fl-inbox-audio-recording">
                        <div className={"fl-direct-messages-record-wrapper-inner"}>
                            <div
                                className={`fl-direct-messages-box-input-action-single-voice-stop ${state.recording && "fl-voice-record-cursor-enable"}`}
                                ref={trashRef}
                                onClick={stop}
                            >
                                <FontAwesomeIcon icon={faStopCircle} data-tip data-for="stop-recording"/>
                                {mobile ? "" :  <ReactTooltip id="stop-recording">Stop recording</ReactTooltip> }
                            </div>
                            <div
                                className="fl-direct-messages-record-audio-chunks"
                                ref={waveformRef}
                            >
                                <ReactMic
                                    record={state.record}
                                    className="sound-wave"
                                    onStop={onStop}
                                    // onData={onData}
                                    strokeColor="#FFFFFF"
                                    backgroundColor="#007eff"
                                />
                            </div>
                            <div className="fl-direct-messages-record-timer">
                                {moment(state.timer, "x").format("mm:ss")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`fl-direct-messages-box-input-form ${props.animation ? "fl-disable-actions" : ""}`}>
                    <div className={`fl-direct-messages-box-input-form-actions fl-direct-messages-box-input-form-actions-icons ${props.progress.uploading || props.retry ? "fl-button-disabled" : ""}`}>
                        <Fragment>
                            {state.recording ? "" :
                                <>
                                    {!mobile &&
                                        <div className="fl-direct-messages-box-input-action-single"
                                             onClick={() =>
                                                 setEmojiState({active: !emojiState.active})
                                             }
                                             ref={emojiRef}
                                        >
                                            <FontAwesomeIcon
                                                icon={faSmile}
                                                data-tip
                                                data-for="emoji"
                                            />
                                            {mobile ? "" : <ReactTooltip id="emoji">Choose an emoji</ReactTooltip>}
                                        </div>
                                    }
                                    {mobile &&
                                        <div className="fl-direct-messages-box-input-action-single"
                                             onClick={() =>
                                             {attachRef?.current?.click();props.scrollBottom()}
                                             }
                                             ref={attachRef}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPaperclip}
                                            />
                                        </div>
                                    }
                                    <div className="fl-direct-messages-box-input-action-single" onClick={() => openDrive()}>
                                        <FontAwesomeIcon
                                            icon={faHdd}
                                            data-tip
                                            data-for="drive"
                                        />
                                        {mobile ? " " : <ReactTooltip id="drive">Insert files from Drive</ReactTooltip>}
                                    </div>
                                    <div className="fl-direct-messages-box-input-action-single"
                                         onClick={async () =>
                                             await modalData.push(() => (
                                                 <TipModal otherUser={props.state.otherUser.handle} conversation={props.state.otherUser.meta.conversation_id} setState={props.setState}
                                                           stateRef={props.stateRef} fromMessage lastMessage={props.lastMessage} setLastMessage={props.setLastMessage} messageId={props.messageId}
                                                 />
                                             ))
                                         }
                                    >
                                        <FontAwesomeIcon
                                            icon={faMoneyBillWave}
                                            data-tip
                                            data-for="tip"
                                        />
                                        {mobile ? "" : <ReactTooltip id="tip">Send tip</ReactTooltip>}
                                    </div>
                                    <div
                                        className="fl-direct-messages-box-input-action-single"
                                        onClick={requestCamera}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCamera}
                                            data-tip
                                            data-for="camera"
                                        />
                                        {mobile ? "" : <ReactTooltip id="camera">Open camera</ReactTooltip>}
                                    </div>
                                </>
                            }
                            <div
                                className={`fl-direct-messages-box-input-action-single fl-direct-messages-box-input-action-single-voice ${state.record && "fl-direct-messages-box-input-action-single-voice-record"}`}
                                onClick={start}
                            >
                                <FontAwesomeIcon
                                    icon={faMicrophoneAlt}
                                    data-tip
                                    data-for="voice"
                                />
                                {mobile ? "" :
                                    <>
                                        <ReactTooltip id="voice">
                                            {!state.record ? "Send audio" : "Recording..."}
                                        </ReactTooltip>
                                    </>
                                }
                            </div>
                        </Fragment>
                    </div>
                    {emojiState.active ?
                        <div
                            className="fl-post-comment-emojis-picker"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Picker
                                onEmojiClick={(e, emoji) => {
                                    props.setState({
                                        ...props.state,
                                        body: `${props.state.body}${emoji.emoji}`,
                                    });
                                }}
                            />
                        </div> : ""
                    }
                    <div className={`fl-direct-messages-box-input-form-inner fl-direct-messages-box-input-form-inner-${userData.currentTheme} ${props.restrictedWords.length > 0 && props.state.body.length > 0 ? "fl-direct-messages-box-restricted" : ""}`}>
                        {state.recording ? "" :
                            <>
                                <input
                                    ref={attachRef}
                                    multiple
                                    accept="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFile(e)}
                                />
                                {!mobile ?
                                    <label
                                        className={`fl-direct-messages-box-input-form-camera ${props.progress.uploading || props.retry ? "fl-button-disabled" : ""}`}
                                        onClick={() =>  attachRef?.current?.click()}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperclip}
                                            data-tip
                                            data-for="image"
                                        />
                                        {mobile ? "" :  <ReactTooltip id="image">Attach files (you can also drag & drop)</ReactTooltip>}
                                    </label> : ""
                                }
                            </>
                        }
                        <input
                            onFocus={() => props.restrictedWords.length > 0 ? props.setRestrictedWords([]) : ""}
                            autoFocus={true}
                            onKeyDown={handleEnter}
                            className={`fl-direct-messages-box-input-form-input fl-direct-messages-box-input-form-input-${userData.currentTheme} ${props.progress.uploading || props.retry ? "fl-button-disabled" : ""}`}
                            placeholder={"Type a message..."}
                            type="text"
                            value={props.state.body}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                typeMessage(e.currentTarget.value)
                            }
                        />
                        {state.recording ? "" :
                            <>
                                <div className="fl-direct-messages-box-input-form-actions">
                                    <Fragment>
                                        <button className={`fl-direct-messages-box-input-action-single fl-direct-messages-box-input-action-single-send ${disableSend() || send ? 'fl-direct-messages-box-input-true' : ""}`}
                                             onClick={() => sendMessage()}
                                             disabled={send}
                                        >
                                            {props.cancel && !props.progress.uploading? (<RedoAltIcon />): !props.cancel && props.progress.uploading ? (<ReactLoading type={"spinningBubbles"} color={"#ff0d72"} height={20} width={20} />) : (<PaperPlaneIcon />)}
                                            {mobile ? "" :  <ReactTooltip id="msg">Send message</ReactTooltip>}
                                        </button>
                                    </Fragment>
                                </div>
                            </>
                        }
                    </div>
                    {props.restrictedWords.length > 0 && (
                        <span className="fl-modal-description-row-error fl-write-post-restricted">
                            <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {props.restrictedWords.join(',')}
                        </span>
                    )}
                    {camera ? (
                        <div className="fl-messages-camera-main">
                            <video ref={cameraRef} autoPlay muted></video>
                            <div className="fl-messages-camera-controls">
                                <div className="fl-messages-camera-controls-top">
                                    <div className="fl-messages-camera-controls-single">
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </div>
                                    <div className="fl-messages-camera-controls-single">
                                        <FontAwesomeIcon icon={faTimes} onClick={closeCamera} />
                                    </div>
                                </div>
                                <div className="fl-messages-camera-controls-bottom">
                                    <div className="fl-messages-camera-controls-single">
                                        <FontAwesomeIcon icon={faSyncAlt} />
                                    </div>
                                    <div
                                        className="fl-messages-camera-controls-snap"
                                        onClick={snapCamera}
                                    ></div>
                                    <div className="fl-messages-camera-controls-single">
                                        <FontAwesomeIcon icon={faImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </>
    )
}
