import React, {FC, useState} from "react";
import { Button } from "../../../components/Button";
import moment from "moment";
import Axios from "axios";
import {useEnvContext} from "../../../context/EnvContext";
import {useModalContext} from "../../../context/ModalContext";
import ReactLoading from "react-loading";
import {useUserContext} from "../../../context/UserContext";

const ConfirmTrialModal: FC<any> = ({
                                           starts_at,
                                           ends_at,
                                           length,
                                           number,
                                           decline,
                                           state,
                                           setState,
                                           setClear,
                                           expire,
                                           inputTypeSwitchDate,
                                           setRestrictedWords,
                                           setSelectedPlan,
                                           priceTrial
                                       }) => {
    const confirmModalFields = [
        {
            text: "Starts",
            span: moment(starts_at).format("D MMMM, YYYY"),
        },
        {
            text: "Ends",
            span: ends_at,
        },
        {
            text: "Trial length",
            span: `${length} ${length > 1 ? "days" : "day"}`
        },
        { text: "Available Trials", span: number },
        {
            text: "Offer expires after",
            span: `${expire > 0 ? expire : "Never"} ${expire > 1 ? "days" : expire === 1 ? "day" : ""}`
        },
        {
            text: "Converts to",
            span: `$${priceTrial}/ month`
        }
    ];

    const confirmModalLine = (text: any, span: any, key: number) => {
        return (
            <div
                key={key}
                className="fl-settings-sub-plans-card-property"
                style={{ fontSize: "1rem" }}
            >
                {text}: <span>{span}</span>
            </div>
        );
    };

    const modalData = useModalContext();
    const envData = useEnvContext();
    const userData = useUserContext()
    const [animation, setAnimation] = useState(false)

    const createTrial = async () => {
        setAnimation(true)
        try {
            const data = {
                type: "trial",
                billing_period: "day",
                expires_at: ends_at === "Never expires" ? null : moment(ends_at).format("yyyy-MM-DD HH:mm:ss"),
                billing_period_count: Number(length),
                limit: number > 0 ? number : null ,
                price: Number(0),
                upgrade_price: priceTrial === "" ? 0 : priceTrial,
                description: state.description.length > 0 ? state.description : undefined
            }
            const res = await Axios.post(`${envData.apiUrl}/subscription-plans`, JSON.parse(JSON.stringify(data)));
            setState({
                ...state,
                plans: [...state.plans, res.data.data],
                length_trial: "",
                number_trial: "",
                expire_trial: "",
                description:"",
                upgrade_price:""
            });
            modalData.close()
            setSelectedPlan(0)
            setClear(1)
            inputTypeSwitchDate(false)
            setAnimation(false)
            userData.setSubscriptions("add",res.data.data)
            modalData.pushToast("success", "Your free trial has been created");
        } catch (error) {
            setAnimation(false)
            if (error?.response?.data?.errors?.restricted_words?.length > 0) {
                setRestrictedWords(error?.response?.data?.errors?.restricted_words)
                modalData.close()
            } else {
                modalData.pushToast("error", "Something went wrong!");
            }
        }
    }

    return (
        <>
            <div className="fl-modal-title" style={{ flexWrap: "wrap" }}>
                Confirm free trial
                <br />
                {moment(starts_at).format("D MMMM, YYYY") !==
                moment().format("D MMMM, YYYY") && (
                    <p style={{ fontSize: ".9rem", width: "100%", margin: ".5rem 0 0" }}>
                        Your discount will start on{" "}
                        {moment(starts_at).format("D MMMM, YYYY")}
                    </p>
                )}
            </div>
            <div className="fl-modal-description">
                {confirmModalFields.filter((x:any) => priceTrial > 0 ? x : x.text !== "Converts to").map((item: any, ind) =>
                    confirmModalLine(item.text, item.span, ind)
                )}
                {state.description &&
                <p>Your message:  {state.description}</p>
                }
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={decline}>Back</Button>
                <Button onClick={createTrial} type="normal" disabled={animation}>
                    Confirm
                    {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </>
    );
};

export default ConfirmTrialModal;
