import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams} from 'react-router-dom';
import { useEnvContext } from '../context/EnvContext';
import { useUserContext } from '../context/UserContext';
import './Verify.css';
import {Button} from "../components/Button";

export const Verify:FC<{}> = () => {

    const userData = useUserContext();
    const envData = useEnvContext();
    const history = useHistory();

    // STATE
    const [state, setState] = useState("LOADING");

    // PARAMS
    const { id, token } = useParams<{id: string, token: string}>();

    // Init Data
    useEffect(() => {
        const verifyAccount = async () => {
            try {
                await Axios({method: "GET", url: `${envData.apiUrl}/mail/verify?id=${id}&token=${token}`});
                setState("SUCCESS")
            } catch(e) {
                setState("ERROR");
            }
        }        
        verifyAccount();
    },[envData.apiUrl,id,token]);

    const getEmail = () => {
        const url = window.location.href;
        const email = url?.split("=")[1];
        const email_left = email?.split("%")[0];
        const email_domen = email?.split("40")[1];
        return email_left + "@" + email_domen;
    }

    const redirectAccount = () => {
        if(userData.authStatus === 'AUTHENTICATED') {
            history.push(`/${userData.user.handle}`)
        }else {
            history.push(`/login`)
        }
    }

    return (
        <div className="fl-fullscreen-with-footer fl-fullscreen-with-height-fix">
            {state === 'SUCCESS' ? <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                <FontAwesomeIcon className="fl-not-found-icon fl-green-icon" icon={faCheck}/>
                <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Verification successful!</div>
                <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>
                    <div className="fl-verify-text">Congratulations, your email address: <br /><br /> <span>{getEmail()}</span> has been verified.</div>
                    <Button
                        onClick={redirectAccount}
                        type="normal"
                        className="fl-settings-sub-security-button fl-settings-update-mail fl-verify-button"
                    >
                        DONE
                    </Button>
                </div>
            </div> : ''}
            {state === 'ERROR' ?
                <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                    <FontAwesomeIcon className="fl-not-found-icon fl-green-icon" icon={faCheck}/>
                    <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Already verified!</div>
                    <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>
                        <div className="fl-verify-text">Your email address: <br /><br /> <span>{getEmail()}</span> has already been verified.</div>
                        <Button
                            onClick={redirectAccount}
                            type="normal"
                            className="fl-settings-sub-security-button fl-settings-update-mail fl-verify-button"
                        >
                            DONE
                        </Button>
                    </div>
                </div>
                : ''}
            {state === 'LOADING' ? <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                <FontAwesomeIcon className="fl-not-found-icon fl-verify-spin" icon={faSpinner}/>
                <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Please wait</div>
            </div> : ''}
        </div>
    )
}
