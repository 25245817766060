import { useEffect, useRef, useState } from "react";

type OnUpdateCallback<T> = (s: T) => void;
type SetStateUpdaterCallback<T> = (s: T) => T;
type SetStateAction<T> = (newState: T | SetStateUpdaterCallback<T>, callback?: OnUpdateCallback<T>) => void;

export function useAsyncState<T>(init: T): [T, SetStateAction<T>];
export function useAsyncState<T = undefined>(init?: T): [T | undefined, SetStateAction<T | undefined>];
export function useAsyncState<T>(init: T): [T, SetStateAction<T>] {
    const [ state, setState ] = useState(init);

    const callbackFunction = useRef<any>();

    useEffect(() => {
        if(typeof(callbackFunction.current) === 'function'){
            callbackFunction.current();
            callbackFunction.current = null;
        }
    }, [state])

    const setAsyncState = (state: any) => new Promise((resolve: any) => {
        callbackFunction.current = resolve;
        setState(state);
    })
    
    return [ state , setAsyncState ]
}