import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {faGift, faHistory, faLayerGroup} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { NavLink, Redirect, useParams } from 'react-router-dom';
import { Button } from '../components/Button';
import { SimpleCard } from '../components/SimpleCard';
import { SubscriberCard } from '../components/SubscriberCard';
import { useEnvContext } from '../context/EnvContext';
import { useUserContext } from '../context/UserContext';
import './Subscriptions.css';
import moment from 'moment';
import {useModalContext} from "../context/ModalContext";
import ReactLoading from "react-loading";
import Axios from "axios";

export const Subscriptions:FC<any> = (props) => {

    const { filter } = useParams<{filter: string}>();

    const envData = useEnvContext()
    const userData = useUserContext()
    const modalData = useModalContext()
    const [count, setCount] = useState({
        active: 0,
        cancelled: 0,
        expired: 0
    })

    const getCount = async () => {
        try {
            const res = await Axios.get(
                `${envData.apiUrl}/subscriptions/count`
            );
            setCount(res.data.data)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        getCount()
    },[])

    if(!['all','expired','cancelled','active'].includes(filter))return <Redirect to="/subscriptions/all"/>

    const countSubscriptions = (status:string) => {
        const sort = status === "active" ? "active" : status === "expired" ? "expired" : "cancelled"
        if(status === "all") {
            return count.active + count.cancelled + count.expired
        } else {
            return count[sort]
        }
    }

    return (
        <div className={`fl-subscriptions-main fl-subscriptions-main-${userData.currentTheme}`}>
            <div className="fl-subscriptions-inner fl-container">
                <div className="fl-col-3 fl-subscriptions-col fl-subscriptions-filter">
                    {
                        [{ label: "All", icon: faLayerGroup, to: "/subscriptions/all" },
                        { label: "Active", icon: faCheckCircle, to: "/subscriptions/active" },
                        { label: "Cancelled", icon: faTimesCircle, to: "/subscriptions/cancelled" },
                        { label: "Expired", icon: faHistory, to: "/subscriptions/expired" }].map((x,y) => <NavLink className="fl-subscriptions-filter-single-link" to={x.to} activeClassName="fl-subscriptions-filter-single-active">
                            <SimpleCard key={y} className="fl-subscriptions-filter-single">
                                <FontAwesomeIcon className="fl-subscriptions-filter-single-icon" icon={x.icon}/>
                                <span>{x.label}</span>
                                <span className={"fl-subscriptions-filter-count"}>({countSubscriptions(x.label.toLowerCase())})</span>
                            </SimpleCard>
                        </NavLink>)
                    }
                </div>
                <div className="fl-col-9 fl-subscriptions-col fl-subscriptions-tablet">
                    <SubscriptionsSubroute filter={filter} key={filter}/>
                </div>
            </div>
        </div>
    )
}

const SubscriptionsSubroute:FC<any> = ({filter}) => {
    const [state, setState] = useState<any>({
        subscriptions: [],
        currentPage: 1,
        lastPage: 0,
        loadingState:false
    })

    const stateRef = useRef<any>(state);
    const envData = useEnvContext();
    const userData = useUserContext();
    const modalData = useModalContext();

    const [loadingAnimation, setLoadingAnimation] = useState(true)

    useEffect(() => {
        stateRef.current = state;
    }, [state])

    const getSubscriptions = async (page = 1) => {
        setState({...stateRef.current, loadingState:true});
        try {
            const res = await axios.get(`${envData.apiUrl}/subscriptions/latest${filter !== "all" ? `?status=${filter}` : ''}${page ? `${filter !== "all" ? "&" : "?"}page=${page}` : ''}`);
            setState({...stateRef.current, subscriptions: [...stateRef.current.subscriptions, ...res.data.data], currentPage: page, lastPage: res.data.meta.last_page,loadingState:false});
            setLoadingAnimation(false)
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useEffect(() => {
        (async () => {
            await getSubscriptions();
        })()
    },[]);

    const checkLastPage = () => {
        if (stateRef.current.currentPage === stateRef.current.lastPage && stateRef.current.lastPage) {
            return true
        } else {
            return false
        }
    }

    const checkForNewSubscriptions = async () => {
        var maxScroll =
            Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            ) - window.innerHeight;

        if (
            window.scrollY > maxScroll - window.innerHeight / 2  &&
            stateRef.current.loadingState === false && !checkLastPage()
        ) {
            getSubscriptions(stateRef.current.currentPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkForNewSubscriptions);
        return () => {
            window.removeEventListener("scroll", checkForNewSubscriptions);
        };
    }, []);

    const convertDataRemaining = (x:any) => {
        const ends_date = moment(x).format("X")
        const currentData = moment(new Date()).format("X")
        const days = Number(ends_date) - Number(currentData)
        return  Math.ceil(days / 86400)
    }

    return <SimpleCard>
        <div className={`fl-subscriptions-section-title fl-subscriptions-section-title-${userData.currentTheme}`}>{ filter } Subscriptions
        </div>
        <div className={`fl-subscriptions-list ${loadingAnimation && "fl-subscriptions-list-empty"}`}>
            {loadingAnimation ?
                <div className={"fl-loader-animation"}>
                    <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                </div>
                :
                <>
                    {state.subscriptions.length > 0 ?
                        <>
                            {state.subscriptions.map((x:any,y:number) => <div key={y} className="fl-subscriptions-single">
                                <SubscriberCard {...x}>
                                    <Button className={`fl-subscriptions-section-card-button`} type="normal" to={`/${x?.creatorProfile.handle}`}>
                                        View Profile
                                    </Button>
                                    <div className="fl-subscriptions-single-dates">
                                        {x.status === "CANCELLED"  && x.subscriptionPlan !== null ?
                                            <Fragment>
                                                {x?.subscriptionPlan?.price > 0 || convertDataRemaining(x.subscriptionPlan?.expires_at) < 0 ?
                                                    <>
                                                        Subscribed until: {moment(x.ends_at).format("D MMM YYYY")}
                                                    </> :
                                                    <>
                                                        Free trial:
                                                        ({convertDataRemaining(x?.ends_at)} day{convertDataRemaining(x.ends_at) > 1 ? "s" : ""} remaining) <br/>
                                                    </>
                                                }
                                            </Fragment>
                                            : ""
                                        }
                                        {x.status === "ACTIVE" && x.subscriptionPlan !== null ?
                                            <Fragment>
                                                {x?.subscriptionPlan?.price > 0 || convertDataRemaining(x.ends_at) < 0 ?
                                                    <>
                                                        Next payment: ${x?.subscriptionPlan?.discounted_plan !== null ? x?.subscriptionPlan?.discounted_plan?.new_price?.toFixed(2) : x?.subscriptionPlan?.upgrade_price ? x?.subscriptionPlan?.upgrade_price : x?.subscriptionPlan?.price } on {moment(x.ends_at).format("D MMM YYYY")}
                                                    </> :
                                                    <>
                                                        Free trial: ({convertDataRemaining(x?.ends_at) === 0 ? "EXPIRES TODAY" : `${convertDataRemaining(x?.ends_at)} day${convertDataRemaining(x.ends_at) > 1 ? "s" : ""} remaining`}) <br />
                                                        {x?.subscriptionPlan?.billing_period_count > 2 ?  `Automatically converts to $${x.subscriptionPlan.upgrade_price}/ month` : "" }
                                                    </>
                                                }
                                                </Fragment> : ''}
                                        {x.status === "ACTIVE" && x.subscriptionPlan === null ?
                                            <Fragment>
                                                Following for free
                                            </Fragment> : ""
                                        }
                                        {x.status === "FORMER" ? <Fragment>Expired on {moment(x.ends_at).format("D MMMM, YYYY")} ({moment(x.ends_at).fromNow()})</Fragment> : ''}
                                    </div>
                                    {x?.subscriptionPlan?.type === "trial" && convertDataRemaining(x.subscriptionPlan?.created_at) > 0 ?
                                        <div className={"fl-subscriptions-free-trial"}>
                                            <FontAwesomeIcon className={"fl-settings-plan-gift"} icon={faGift} />
                                        </div> : ""
                                    }
                                </SubscriberCard>
                            </div>)}
                        </>
                        :
                        <div className={"fl-subscription-empty"}>
                            <div className={"fl-subscription-empty"}>
                                <p>You {filter !== "all" ? "don't have any" : "haven't"}  {filter === "all" ? "subscribed to anyone yet" : `${filter} subscriptions`}.</p>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    </SimpleCard>
}