import React, {FC, Fragment} from "react";
import {useModalContext} from "../context/ModalContext";
import {useEnvContext} from "../context/EnvContext";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import TwoFactorSms from "../components/TwoFactorSms"
import TwoFactorGoogle from "../components/TwoFactorGoogle"
import {Button} from "./Button";
import smsAuthenticaor from "../assets/images/smsAuthentificator.svg";
import googleAuthenticator from "../assets/images/googleAuthenticator.svg";
import axios from "axios";

const TwoFactorOption: FC<{
    setState2fa:any;
    state2fa:any;
    openAuthentificationModal:any;
}> = ({setState2fa,state2fa}) => {

    const modalData = useModalContext();
    const envData = useEnvContext();

    const openSmsRequestModal = async () => {
        await modalData.push(() => (
            <TwoFactorSms
                title="TEXT MESSAGE"
                setState2fa={setState2fa}
                state2fa={state2fa}
            />
        ));
    }

    const googleVerification = async () => {
        try {
            const res = await Axios.post(`${envData.apiUrl}/auth/2fa/enable`, {
                "auth_type": "2fa_google",
            });
            modalData.push(() => (
                <TwoFactorGoogle
                    title="AUTHENTICATION APP"
                    qr={res.data.image}
                    secret={res.data.secret}
                    setState2fa={setState2fa}
                    state2fa={state2fa}
                />
            ));
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const disable2fa = async (type:any) => {
        if(state2fa.type === type) {
            try {
                axios.get(`${envData.apiUrl}/auth/2fa/disable`)
                setState2fa({...state2fa, type: "regular",enable:false});
            }catch(e) {
                modalData.pushToast('error', 'Something went wrong!')
            }
            modalData.closeAll()
        } else {
            if(type === "2fa_google") {
                modalData.closeAll()
                setTimeout(() => {
                    googleVerification()
                }, 500);
            } else {
                modalData.closeAll()
                setTimeout(() => {
                    openSmsRequestModal()
                }, 500);
            }
        }
    }

    const disableAuthentification = async (type:string) => {
        modalData.close()
        modalData.push(() => (<ChangeMethodModal type={type} state2fa={state2fa} disable2fa={disable2fa} />))
    }

    return (
        <Fragment>
            <div className="fl-modal-title">
                Select authentication method
                <div className="fl-modal-close">
                    <div>
                        <FontAwesomeIcon onClick={()=>modalData.close()} icon={faTimes} />
                    </div>
                </div>
            </div>
            <div className="fl-modal-description fl-simple-card-authentification">
                {/*<p className="fl-authentication-tittle">Select authentication method</p>*/}
                <p>We'll ask for a security code when we need to confirm that it's you logging in.</p>
                <p className="fl-authentication-option">
                    <img src={googleAuthenticator} alt={"google authentification"} />
                    <span className="fl-authentication-option-title">Authentication app:</span>
                    <label className="fl-authentication-option-checkbox">
                        <input
                            type="checkbox"
                            checked={state2fa.type === "2fa_google" && true} onClick={() => state2fa.type === "2fa_google" || state2fa.type === "2fa_sms" ? disableAuthentification("2fa_google") : googleVerification()}
                        />
                            <span className="fl-authentication-option-checkmark"></span>
                    </label>
                </p>
                <p>
                    Use a mobile authentication app to generate a verification code to enter when you log in to Fluffa.
                </p><br />
                <p className="fl-authentication-option">
                    <img src={smsAuthenticaor} alt={"sms factor"} />
                    <span className="fl-authentication-option-title">Text message:</span>
                    <label className="fl-authentication-option-checkbox">
                        <input
                            type="checkbox"
                            checked={state2fa.type === "2fa_sms" && true} onClick={() => state2fa.type === "2fa_google" || state2fa.type === "2fa_sms" ? disableAuthentification("2fa_sms") : openSmsRequestModal()}                        />
                        <span className="fl-authentication-option-checkmark"></span>
                    </label>
                </p>
                <p>Use your mobile phone to receive a text message with an authentication code to enter when you log in to Fluffa.</p><br />
            </div>
        </Fragment>
    );
};

export const ChangeMethodModal: FC<any> = ({type,state2fa,disable2fa}) => {
    const modalData = useModalContext();
    return (
        <Fragment>
            <div className="fl-modal-title">{state2fa.type === type ? "Turn off two-factor authentication" : `Change authentication type`}</div>
            <div className="fl-modal-description">
                {state2fa.type === type ?
                    <p>Turning off two-factor authentication means your account may be more vulnerable to compromise. Are you sure you want to turn it off?</p>
                    :
                   <>
                       <p>Your current authentication method is:</p>
                       <p className="fl-authentication-option">
                           <img src={state2fa.type === "2fa_google" ? googleAuthenticator : smsAuthenticaor} alt={"2fa google"} />
                           <span className="fl-authentication-option-title">{state2fa.type === "2fa_google" ? "Authentication app" : "Text message"}</span>
                       </p>
                       <p>Do you want to change this to:</p>
                       <p className="fl-authentication-option">
                           <img src={state2fa.type === "2fa_google" ? smsAuthenticaor : googleAuthenticator}  alt={"2factor"} />
                           <span className="fl-authentication-option-title">{state2fa.type === "2fa_google" ? "Text message" : "Authentication app"}</span> ?
                       </p>
                   </>
                }
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={async () => await modalData.close()}> {state2fa.type === type ? "Cancel" : "No"}</Button>
                <Button
                    type="normal"
                    onClick={() => disable2fa(type)}
                >
                    {state2fa.type === type ? "Turn off" : "Change"}
                </Button>
            </div>

        </Fragment>
    );
};

export default TwoFactorOption;