import React, { FC, useEffect } from 'react';
import {Route, Redirect, useHistory, useLocation} from 'react-router-dom';
import { Subroutes } from '../components/Subroutes';
import { useUserContext } from '../context/UserContext';
import {WritePost} from "../components/WritePost";
import {RouteHolder} from "../routes/RouteHolder";

export const ProtectedRoute:FC<any> = (props) => {

    const userData = useUserContext();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const history = useHistory()
    const userLocation = useLocation();

    useEffect(() => {
        if(localStorage.getItem("auth-token")) {
        } else {
            userData.clearToken(false);
            history.push("/login");
        }
    },[localStorage.getItem("auth-token")])

    return (
        <div className={`fl-main-${userLocation.pathname.split("/")[1] !== "inbox" ? "container" : "inbox"}`}>
            <React.Fragment>
                {userData.authStatus === 'UNAUTHENTICATED' ? <Redirect to="/login"/> : userData.user.profile.meta.verified_state === ("rejected" || "not_verified" || "pending" ) && window.location.pathname === ("/settings/plans" || "/settings/referrals" || "/settings/payouts") ? <Redirect to="/feed"/> : ''}
                {userData.authStatus === 'AUTHENTICATED' ? <React.Fragment>
                    {!isMobile && <Subroutes/> }
                    <div className={`${userData.mobilePost ? "fl-route-not-visible" : ""} fl-main-container-row`}>
                        <Route {...props}/>
                    </div>
                    {userData.mobilePost ? <div className={"fl-mobile-create-post"}><WritePost mobileResponsive={true} onClear={() => userData.mobileCreatePost(false)} quickPost/></div> : "" }
                </React.Fragment> : ''}
                {userData.authStatus === 'CHECKING' ? <RouteHolder/> : ''}
            </React.Fragment>
        </div>
    )
}