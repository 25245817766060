import Axios from "axios";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnvContext } from "./EnvContext";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";

export interface UserContextData {
  paddingBody:string;
  stopPusher: () => void;
  toggleSidebar: () => void;
  signal: any;
  paddingBodyFunction: (x:string) => void;
  authStatus: string;
  currentTheme: string;
  mobilePost:boolean;
  gridView: boolean;
  themes: string[];
  checkAuth: () => Promise<string>;
  changeTheme: () => void;
  setToken: (x: string) => void;
  setSubscriptions: (x: string,data:any) => void;
  newPostsCreatorFunction: (data:any) => void;
  clearToken: (api?:boolean) => Promise<void>;
  changeGridView: () => void;
  changeFreeAccount: (type: number) => void;
  sendVerification: () => void;
  sendVerificationStatus: boolean;
  goToPost: () => void;
  scrollPost: boolean;
  updateMail: (x: string) => void;
  newMail: string;
  keyboardEvent: (x: string) => void;
  keyName: string;
  messagesFunction: (name: any, username: any, userId: any) => void;
  messageName: any;
  messageUsername: any;
  messageUserId: any;
  driveFiles: (x: any) => void;
  updateHandle: (x: any) => void;
  selectedDriveFiles: any;
  newPostFunction: (x: any, url: any) => void;
  changeStatus: (x: any) => void;
  changeActivity: (x: any) => void;
  addBank: (x: any) => void;
  readNotifications: (action: any) => void;
  readMessages: () => void;
  newMessage: () => void;
  updateCount: (action: any) => void;
  updateBalance: (type: string, amount:number) => void;
  updateVerificationStatus: (action: any) => void;
  addPayment: (action: any) => void;
  encoding: (action: boolean, percent:number, thumb:string,type:string) => void;
  newPost: any;
  quickPostLocation: any;
  clearSignal: () => void;
  clearEncodingStatus: () => void;
  updateWalletBalance: () => void;
  updateCountTickets: (action:any) => void;
  updateProfileImage: (url:string,x:number,y:number) => void;
  updateCountFavorites: (action:string, type:string) => void;
  updateArchivePost: () => void;
  mobileCreatePost: (action:any) => void;
  newPostsCreator:any,
  encodingStatus: {
    status:boolean,
    percent:number,
    thumb: string,
    type: string,
    event:boolean
  }
  user: {
    id: number;
    handle: string;
    name: string;
    profile_image: string;
    background_image: string;
    created_at: string;
    email_verified_at: string;
    updated_at: string;
    email: string;
    auth_type: string;
    subscriptionPlans: any;
    has_password: boolean;
    has_payout_account:boolean;
    biller: string;
    meta: {
      unread_notifications: number;
      new_notifications: number;
      unread_messages: number;
      can_subscribe: boolean;
      posts:any;
    };
    profile: {
      backgroundImage: null;
      bio: string;
      birthday: string;
      created_at: string;
      creatorProfile: { total_earnings: number; total_unpaid_earnings: number, total_processing_earnings: number };
      handle: string;
      location: string;
      verification_status: string;
      was_verified_before:number;
      meta: {
        posts: number;
        likes: number;
        fans: number;
        followers:number;
        following:number;
        verified_state: string;
        attachments_in_drive: number;
        attachments_in_drive_images: number;
        attachments_in_drive_videos: number;
        tickets_in_progress:number;
        tickets_resolved:number;
        posts_in_drive: number;
        posts_scheduled:number;
        posts_with_images:number;
        posts_with_videos:number;
        transactions: number;
        transactions_with_images: number;
        transactions_with_videos: number;
        subscriptions: any;
        total_favorites:any;
        subscriptions_count: {active:number,cancelled:number,expired:number}
      };
      name: string;
      short_bio: string;
      nationality: { id: number; name: string; cca2: string; cca3: string };
      profileImage: {
        created_at: string;
        id: number;
        image_type: string;
        title: string;
        url: string;
        position_x:number;
        position_y:number;
      };
      descriptions: {
        single: any;
      };
      city: { name: string };
      reject_reason: string;
      online_status: boolean;
      last_active: string;
      free_subscription: number;
      firstname: string;
      lastname: string;
      country_id: number;
      city_id: number;
      address: string;
      postcode: string;
    };
    settings: {
      activity_status: boolean;
    };
    wallet_balance: number;
  };
}

export const userContextDefaultValues: UserContextData = {
  toggleSidebar: () => void 1,
  paddingBodyFunction: () => void 0,
  stopPusher: () => void 0,
  signal: null,
  paddingBody:"",
  authStatus: "CHECKING",
  currentTheme: "",
  mobilePost:false,
  gridView: false,
  themes: [],
  checkAuth: async () => "CHECKING",
  changeTheme: () => void 0,
  setToken: () => void 0,
  setSubscriptions: () => void 0,
  newPostsCreatorFunction: () => void 0,
  clearToken: async () => void 0,
  changeGridView: () => void 0,
  changeFreeAccount: () => void 0,
  sendVerification: () => void 0,
  sendVerificationStatus: false,
  goToPost: () => void 0,
  clearSignal: () => void 0,
  clearEncodingStatus: () => void 0,
  scrollPost: false,
  updateMail: () => void 0,
  newMail: "",
  keyboardEvent: () => void 0,
  keyName: "",
  messagesFunction: () => void 0,
  messageName: "",
  messageUsername: "",
  messageUserId: "",
  driveFiles: () => void 0,
  updateHandle: () => void 0,
  selectedDriveFiles: [],
  newPostFunction: () => void 0,
  changeStatus: () => void 0,
  changeActivity: () => void 0,
  addBank: () => void 0,
  readNotifications: () => void 0,
  readMessages: () => void 0,
  newMessage: () => void 0,
  updateCount: () => void 0,
  updateBalance: () => void 0,
  updateVerificationStatus: () => void 0,
  addPayment: () => void 0,
  encoding: () => void 0,
  updateWalletBalance: () => void 0,
  updateCountTickets: () => void 0,
  updateProfileImage: () => void 0,
  updateCountFavorites: () => void 0,
  updateArchivePost: () => void 0,
  mobileCreatePost: () => void 0,
  newPost: [],
  quickPostLocation: "",
  newPostsCreator:[],
  encodingStatus: {
    status:false,
    percent:1,
    thumb: "",
    type: "",
    event:false
  },
  user: {
    id: 0,
    handle: "",
    name: "",
    profile_image: "",
    background_image: "",
    created_at: "",
    email_verified_at: "",
    updated_at: "",
    email: "",
    auth_type: "",
    biller: "",
    subscriptionPlans: [],
    has_password: true,
    has_payout_account:false,
    meta: {
      unread_notifications: 0,
      new_notifications: 0,
      unread_messages: 0,
      can_subscribe: false,
      posts:[]
    },
    profile: {
      backgroundImage: null,
      bio: "",
      birthday: "",
      created_at: "",
      creatorProfile: { total_earnings: 0, total_unpaid_earnings: 0,total_processing_earnings:0 },
      handle: "",
      location: "",
      verification_status: "",
      was_verified_before:0,
      meta: {
        posts: 0,
        likes: 0,
        fans: 0,
        followers:0,
        following: 0,
        verified_state: "not_verified",
        attachments_in_drive: 0,
        attachments_in_drive_images: 0,
        attachments_in_drive_videos: 0,
        tickets_in_progress:0,
        tickets_resolved:0,
        posts_in_drive: 0,
        posts_scheduled:0,
        posts_with_images:0,
        posts_with_videos:0,
        transactions: 0,
        transactions_with_images: 0,
        transactions_with_videos: 0,
        subscriptions: null,
        total_favorites:{ all:0,photos:0,profiles:0,texts:0,videos:0},
        subscriptions_count: {active:0,cancelled:0,expired:0}
      },
      name: "",
      short_bio: "",
      profileImage: {
        created_at: "",
        id: 0,
        image_type: "",
        title: "",
        url: "",
        position_x:0,
        position_y:0
      },
      descriptions: {
        single: [],
      },
      nationality: { id: 0, name: "", cca2: "", cca3: "" },
      city: { name: "" },
      reject_reason: "",
      online_status: false,
      last_active: "",
      free_subscription: 0,
      firstname: "",
      lastname: "",
      city_id: 0,
      country_id: 0,
      address: "",
      postcode: "",
    },
    settings: {
      activity_status: false,
    },
    wallet_balance: 0,
  },
};

const UserContext = React.createContext<UserContextData>(
    userContextDefaultValues
);

export const useUserContext = () => useContext(UserContext);

interface UserProviderProps {
  children: React.ReactNode;
}

var echo: any = null;

export const UserProvider: FC<UserProviderProps> = (props) => {
  // HISTORY
  const history = useHistory();

  // CONTEXT
  const envData = useEnvContext();

  const [state, setState] = useState({
    signal: null,
    paddingBody:"",
    authStatus: "CHECKING",
    currentTheme: "light",
    mobilePost:false,
    gridView: false,
    sendVerificationStatus: false,
    scrollPost: false,
    newMail: "",
    keyName: "",
    messageName: "",
    messageUsername: "",
    messageUserId: "",
    selectedDriveFiles: [],
    newPost: [],
    quickPostLocation: "",
    themes: ["light", "dark"],
    newPostsCreator:[],
    encodingStatus: {
      status:false,
      percent:1,
      thumb: "",
      type: "",
      event:false
    },
    user: {
      id: 0,
      handle: "",
      name: "",
      profile_image: "",
      background_image: "",
      created_at: "",
      email_verified_at: "",
      updated_at: "",
      email: "",
      auth_type: "",
      biller: "",
      subscriptionPlans: [],
      has_password: true,
      has_payout_account:false,
      meta: {
        unread_notifications: 0,
        new_notifications: 0,
        unread_messages: 0,
        can_subscribe: false,
        posts:[]
      },
      profile: {
        backgroundImage: null,
        bio: "",
        birthday: "",
        created_at: "",
        creatorProfile: { total_earnings: 0, total_unpaid_earnings: 0,total_processing_earnings:0 },
        handle: "",
        location: "",
        meta: {
          posts: 0,
          likes: 0,
          fans: 0,
          followers:0,
          following:0,
          verified_state: "not_verified",
          attachments_in_drive: 0,
          attachments_in_drive_images: 0,
          attachments_in_drive_videos: 0,
          tickets_in_progress:0,
          tickets_resolved:0,
          posts_in_drive: 0,
          posts_scheduled:0,
          posts_with_images:0,
          posts_with_videos:0,
          transactions: 0,
          transactions_with_images: 0,
          transactions_with_videos: 0,
          subscriptions: null,
          total_favorites:{ all:0,photos:0,profiles:0,texts:0,videos:0},
          subscriptions_count: {active:0,cancelled:0,expired:0}
        },
        name: "",
        verification_status: "",
        was_verified_before:0,
        nationality: { id: 0, name: "", cca2: "", cca3: "" },
        city: { name: "" },
        short_bio: "",
        profileImage: {
          created_at: "",
          id: 0,
          image_type: "",
          title: "",
          url: "",
          position_x:0,
          position_y:0
        },
        descriptions: {
          single: [],
        },
        reject_reason: "",
        online_status: false,
        last_active: "",
        free_subscription: 0,
        firstname: "",
        lastname: "",
        country_id: 0,
        city_id: 0,
        address: "",
        postcode: "",
      },
      settings: {
        activity_status: false,
      },
      wallet_balance: 0,
    },
  });

  const [paddingBodyState, setPaddingBodyState] = useState({
    paddingBody: "",
  });

  const [mobilePostState, setMobilePostState] = useState({
    mobilePost: false,
  });


  const [newPostState, setNewPostState] = useState({
    newPost: [],
    quickPostLocation: ""
  });

  const [sidebarState, setSidebarState] = useState({
    active: false,
  });


  const [encodingProgress, setEncodingProgress] = useState({
    encodingStatus: {
      status:false,
      percent:1,
      thumb: "",
      type: "",
      event:false
    }
  })
  const stateRef = useRef<any>();
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const [pusherState, setPusherState] = useState<any>({
    connected: false,
  });


  const updateWalletBalance = async () => {
    try {
      const response = await axios.get(`${envData.apiUrl}/payments/wallet`);
      setState({
        ...state,
        user: {
          ...state.user,
          wallet_balance: response.data.data.wallet_balance,
        },
      });
    } catch (e) {

    }
  };

  const updateCountTickets =  (action:string) => {
    setState({...state, user: {...state.user, profile: {...state.user.profile,
          meta: {...state.user.profile.meta, tickets_resolved: action === "close" ? state.user.profile.meta.tickets_resolved + 1 : state.user.profile.meta.tickets_resolved,
            tickets_in_progress: action === "open" ? state.user.profile.meta.tickets_in_progress + 1 :
                action === "close" ? state.user.profile.meta.tickets_in_progress - 1 : state.user.profile.meta.tickets_in_progress}
        }
      }})
  };

  const updateProfileImage =  (url:string,x:number,y:number) => {
    setState({...state, user: {...state.user, profile: {
          ...state.user.profile,
          profileImage: {...state.user.profile.profileImage, url: url, position_x: x, position_y: y}
        }}})
  };

  const updateCountFavorites =  (action:string,type:string) => {
    setState({...state, user: {...state.user, profile: {...state.user.profile, meta: {...state.user.profile.meta, total_favorites:
      {...state.user.profile.meta.total_favorites,
        all: action === "add" && type !== "profiles" ? state.user.profile.meta.total_favorites.all + 1 : action === "remove" && type !== "profiles" ? state.user.profile.meta.total_favorites.all - 1 : state.user.profile.meta.total_favorites.all,
        texts: type === "texts" && action === "add" ? state.user.profile.meta.total_favorites.texts + 1 : type === "texts" && action === "remove" ? state.user.profile.meta.total_favorites.texts - 1 : state.user.profile.meta.total_favorites.texts,
        photos: type === "photos" && action === "add" ? state.user.profile.meta.total_favorites.photos + 1 : type === "photos" && action === "remove" ? state.user.profile.meta.total_favorites.photos - 1 : state.user.profile.meta.total_favorites.photos,
        videos: type === "videos" && action === "add" ? state.user.profile.meta.total_favorites.videos + 1 : type === "videos" && action === "remove" ? state.user.profile.meta.total_favorites.videos - 1 : state.user.profile.meta.total_favorites.videos,
        profiles: type === "profiles" && action === "add" ? state.user.profile.meta.total_favorites.profiles + 1 : type === "profiles" && action === "remove" ? state.user.profile.meta.total_favorites.profiles - 1 : state.user.profile.meta.total_favorites.profiles
      }
    }}}})
  };

  const updateArchivePost =  () => {
    setState({...state, user: {...state.user, profile: {...state.user.profile,
          meta: {...state.user.profile.meta, posts_in_drive: state.user.profile.meta.posts_in_drive + 1}
        }
      }})
  };

  const mobileCreatePost =  (action:boolean) => {
    setMobilePostState({ ...mobilePostState, mobilePost: action });
  };

  const changeTheme = async () => {
    const nextThemeIndex = state.themes.indexOf(state.currentTheme) + 1;
    const bodyColors: any = {
      light: "#fff",
      dark: "#000",
    };
    if (nextThemeIndex === state.themes.length) {
      setState({ ...state, currentTheme: state.themes[0] });
      document.documentElement.style.setProperty(
          "--fl-body-color",
          bodyColors[state.themes[0]]
      );
      try {
        await Axios.post(`${envData.apiUrl}/settings`, {
          dark_mode: state.themes[0] === "dark" ? true : false,
        });
      } catch (e) {}
    } else {
      setState({ ...state, currentTheme: state.themes[nextThemeIndex] });
      document.documentElement.style.setProperty(
          "--fl-body-color",
          bodyColors[state.themes[nextThemeIndex]]
      );
      try {
        await Axios.post(`${envData.apiUrl}/settings`, {
          dark_mode: state.themes[nextThemeIndex] === "dark" ? true : false,
        });
      } catch (e) {}
    }
  };

  const changeGridView = () => {
    setState({ ...state, gridView: !state.gridView });
  };

  const changeFreeAccount = (type: number) => {
    setState({ ...state, gridView: !state.gridView });
    setState({
      ...state,
      user: {
        ...state.user,
        profile: { ...state.user.profile, free_subscription: type },
      },
    });
  };

  const sendVerification = () => {
    setState({
      ...state,
      sendVerificationStatus: !state.sendVerificationStatus,
    });
  };

  const goToPost = () => {
    setState({ ...state, scrollPost: !state.scrollPost });
  };

  const clearSignal = () => {
    if(encodingProgress.encodingStatus.status) {
      setState({ ...state,
        signal:null,
      });
    } else {
      setState({ ...state, signal: null });
    }
  };

  const clearEncodingStatus = () => {
    setEncodingProgress({ ...encodingProgress,
      encodingStatus: {...encodingProgress.encodingStatus, status: false, percent: 1,thumb:"",type:"",event:false}
    });
  }

  const updateMail = (x: string) => {
    setState({ ...state, newMail: x });
  };

  const keyboardEvent = (x: string) => {
    setState({ ...state, keyName: x });
  };

  const messagesFunction = (name: any, username: any, userId: any) => {
    setState({
      ...state,
      messageName: name,
      messageUsername: username,
      messageUserId: userId,
    });
  };

  const driveFiles = (x: any) => {
    setState({ ...state, selectedDriveFiles: x });
  };

  const setToken = (x: string) => {
    Axios.defaults.headers.common = { Authorization: `Bearer ${x}` };
    localStorage.setItem("auth-token", x);
  };

  const setSubscriptions = (x: string,data:any) => {
      // @ts-ignore
      setState({
        ...state,
        user: {
          ...state.user,
          subscriptionPlans: x === "add" ? [...state.user.subscriptionPlans, data] : state.user.subscriptionPlans.filter((x:any) => x.id !== data),
        },
      });
  };

  const newPostsCreatorFunction = (data:any) => {
    // @ts-ignore
    setState({
      ...state,
      newPostsCreator: data ? [...state.newPostsCreator, ...data] : [],
    });
  };

  const [initialUser,setInitialUser] = useState<any>(state.user)

  const clearToken = async (api = true) => {
    setState({ ...state, authStatus: "UNAUTHENTICATED",user: {...state.user, ...initialUser },signal:null});
    setEncodingProgress({ ...encodingProgress,
      encodingStatus: {...encodingProgress.encodingStatus, status: false, percent: 1,thumb:"",type:"",event:false}
    });
    if (api) {
      await Axios.post(`${envData.apiUrl}/auth/logout`);
    }
    Axios.defaults.headers.common = { Authorization: `` };
    localStorage.clear();
    setPusherState({ ...pusherState, connected: false });
  };

  const checkReferral = () => {
    localStorage.setItem(
        "referral_token",
        window.location.href.split("creator_referral_link=")[1]
    );
  };

  useEffect(() => {
    if (window.location.href.split("creator_referral_link=")[1]) {
      checkReferral();
    }
  }, [window.location.href]);

  const checkAuth = async (redirect: boolean = false) => {
    var authStatus = "CHECKING";
    var currentTheme = "light";
    var authResponse = {};
    const token = localStorage.getItem("auth-token") || "";
    if (token !== "") {
      setToken(token);
      try {
        const res = await Axios.get(`${envData.apiUrl}/auth/profile`);
        authResponse = res.data.data;
        currentTheme = res.data.data.settings.dark_mode ? "dark" : "light";
        authStatus = "AUTHENTICATED";
      } catch (e) {
        clearToken();
        authStatus = "UNAUTHENTICATED";
      }
    } else {
      authStatus = "UNAUTHENTICATED";
    }

    if (authStatus === "UNAUTHENTICATED" && redirect === true) {
      history.push("/login");
    }

    setState({
      ...state,
      authStatus,
      currentTheme,
      user: { ...state.user, ...authResponse },
    });
    document.documentElement.style.setProperty(
        "--fl-body-color",
        currentTheme === "dark" ? "#000" : "#fff"
    );
    return authStatus;
  };

  const updateHandle = (x: string) => {
    setState({ ...state, user: { ...state.user, handle: x } });
  };

  const newPostFunction = (x: any, url: any) => {
    setNewPostState({ ...newPostState, newPost: x, quickPostLocation: url });
  };

  const changeStatus = async (x: any) => {
    try {
      const res = await Axios.post(`${envData.apiUrl}/auth/status/change`, {
        status: x,
      });
      setState({
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            online_status: res.data.status === 1 ? true : false,
            last_active: res.data.status === 1 ? "" : res.data.last_active,
          },
        },
      });
    } catch (e) {}
  };

  const changeActivity = async (x: any) => {
    setState({
      ...state,
      user: {
        ...state.user,
        settings: { ...state.user.settings, activity_status: x },
      },
    });
  };

  const addBank = async (x: any) => {
    setState({
      ...state,
      user: {
        ...state.user,
        has_payout_account: x,
      },
    });
  };

  const readNotifications = async (action: any) => {
    setState({
      ...state,
      user: {
        ...state.user,
        meta: {
          ...state.user.meta,
          new_notifications:
              action === "all"
                  ? 0
                  : action === "read"
                      ? state.user.meta.new_notifications - 1
                      : action === "unread"
                          ? state.user.meta.new_notifications + 1
                          : action,
        },
      },
    });
  };

  const readMessages = async () => {
    setState({
      ...state,
      user: {
        ...state.user,
        meta: {
          ...state.user.meta,
          unread_messages: state.user.meta.unread_messages - 1
        },
      },
    });
  };

  const newMessage = async () => {
    setState({
      ...state,
      user: {
        ...state.user,
        meta: {
          ...state.user.meta,
          unread_messages: state.user.meta.unread_messages + 1
        },
      },
    });
  };

  const updateBalance = (type:string, amount:number) => {
    if(type === "earnings") {
      setState({...state, user: {...state.user, profile: {...state.user.profile,
            creatorProfile: {...state.user.profile.creatorProfile, total_earnings: state.user.profile.creatorProfile.total_earnings + amount}
          }
        }})
    } else {
      setState({...state, user: {...state.user, wallet_balance: type === "walletOut" ? state.user.wallet_balance - amount : state.user.wallet_balance + amount}})
    }
  }

  const updateCount = async (action: any) => {
    setState({
      ...state,
      user: {...state.user, profile: {...state.user.profile,
          meta: {...state.user.profile.meta,
            following: action === "following" ? state.user.profile.meta.following + 1 : action === "unfollowing" ? state.user.profile.meta.following - 1 : state.user.profile.meta.following,
            likes: action === "like" ? state.user.profile.meta.likes + 1 : action === "dislike" ? state.user.profile.meta.likes - 1 : state.user.profile.meta.likes,
            posts: action === "createPost" || action === "publishPostScheduled" ? state.user.profile.meta.posts + 1 : action === "deletePost" ? state.user.profile.meta.posts - 1 : state.user.profile.meta.posts,
            posts_scheduled: action === "createPostScheduled" ? state.user.profile.meta.posts_scheduled + 1 : action === "deletePostScheduled" || action === "publishPostScheduled" ? state.user.profile.meta.posts_scheduled - 1  : state.user.profile.meta.posts_scheduled
          }
        },},
    });
  };

  const updateVerificationStatus = async (action: any) => {
    setState({
      ...state,
      user: {...state.user, profile: {...state.user.profile, meta: {...state.user.profile.meta, verified_state: action}}}
    });
  };

  const addPayment = (action: boolean) => {
    setState({
      ...state,
      user: {
        ...state.user,
        meta: { ...state.user.meta, can_subscribe: action },
      },
    });
  };

  const encoding = (action: boolean,percent:number,thumb:string,type:string,event = false) => {
    setEncodingProgress({ ...encodingProgress,
      encodingStatus: {...encodingProgress.encodingStatus,  status: action, percent: percent === 0 ? encodingProgress.encodingStatus.percent : percent,thumb:thumb,type:type,event:event}
    });
  };

  const getEmail = () => {
    const url = window.location.href;
    const email = url?.split("=")[1];
    const email_left = email?.split("%")[0];
    const email_domen = email?.split("40")[1];
    return email_left + "@" + email_domen;
  }

  useEffect(() => {
    //@ts-ignore
    const type = state?.signal?.type;
    const amountSignal = [];
    const reject_message = []
    const previouslyVerified = []
    const newPosts = []
    const commentId = []
    if(type === "App\\Notifications\\DeclinedUserVerificationEmail") {
      // @ts-ignore
      reject_message.push(state?.signal?.reject_message)
      // @ts-ignore
      previouslyVerified.push(state?.signal?.previously_verified)
    }
    if(type === "App\\Notifications\\UserTipCreated") {
      // @ts-ignore
      amountSignal.push(state?.signal?.tip.amount)
    }
    if(type === "App\\Notifications\\NewPostNotification") {
      // @ts-ignore
      newPosts.push(state?.signal?.post?.id)
    }
    if(type === "App\\Notifications\\NewPostComment") {
      // @ts-ignore
      commentId.push(state?.signal?.user_id)
    }
    if (
        [
          "App\\Notifications\\NewPostLike",
          "App\\Notifications\\UserTipCreated",
          "App\\Notifications\\Chat\\DeletedReportedMessageImportant",
          "App\\Notifications\\NewPostComment",
          "App\\Notifications\\DeclinedUserVerificationEmail",
          "App\\Notifications\\NewPostFavorite",
          "App\\Notifications\\UserUnlockedChatMessage",
          "App\\Notifications\\VerifiedUserEmail",
          "App\\Notifications\\NewPostTag",
          "App\\Notifications\\EmailVerify",
          "App\\Notifications\\UserVerificationApproved",
          "App\\Notifications\\UserVerificationRejected",
          "App\\Notifications\\NewTicketResponse",
          "App\\Notifications\\TicketStatusChanged",
          "App\\Notifications\\NewSubscriber"
        ].includes(type)
    ) {
      setState({
        ...state,
        user: {
          ...state.user,
          email_verified_at: type === "App\\Notifications\\EmailVerify" ?  "verified" : "",
          meta: {
            ...state.user.meta,
            new_notifications: type === "App\\Notifications\\EmailVerify" && getEmail() !== "undefined@undefined" || type === "App\\Notifications\\NewPostComment" && Number(commentId) === state?.user.id  ? state.user.meta.new_notifications : state.user.meta.new_notifications + 1,
          },
          profile: {...state.user.profile, reject_reason:  type === "App\\Notifications\\DeclinedUserVerificationEmail" ? reject_message[0] : state.user.profile.reject_reason,was_verified_before:  type === "App\\Notifications\\DeclinedUserVerificationEmail" ? previouslyVerified[0] : state.user.profile.was_verified_before,
            meta: {...state.user.profile.meta, verified_state: type === "App\\Notifications\\DeclinedUserVerificationEmail" ? "rejected" : type === "App\\Notifications\\VerifiedUserEmail" ? "verified" : state.user.profile.meta.verified_state},
            creatorProfile: {...state.user.profile.creatorProfile, total_earnings:type === "App\\Notifications\\UserTipCreated" ? state?.user?.profile?.creatorProfile?.total_earnings + amountSignal[0] : state?.user?.profile?.creatorProfile?.total_earnings }
          }
        },
      });
    } else  {
      // @ts-ignore
      if (["App\\Notifications\\ProcessPostProgress"].includes(type) && state.signal.sent_at.split(" ")[1] >= (localStorage.getItem("uploading")?.split(" ")[1] ? localStorage.getItem("uploading")?.split(" ")[1] : 0) && !state?.user?.meta?.posts?.includes(state?.signal?.post?.id) && state?.signal?.progress >= encodingProgress.encodingStatus.percent) {
            // @ts-ignore
            encoding(true,state?.signal?.progress,state?.signal?.thumbnail,"image",true)
            // @ts-ignore
            localStorage.setItem("uploading",state?.signal?.sent_at)
          } else if (["App\\Notifications\\NewPostNotification"].includes(type)) {
            newPostsCreatorFunction(newPosts)
          } else if (["App\\Notifications\\PostPublished"].includes(type)) {
            updateCount("publishPostScheduled")
          }
    }
  }, [state.signal]);

  const pusherConnection = (id:any) => {
    //@ts-ignore
    window.Pusher = Pusher;

    const echo = new Echo({
      broadcaster: "pusher",
      cluster: "eu",
      forceTLS: true,
      key: envData.pusher.key,
      authEndpoint: envData.pusher.authEndpoint,
      encrypted: true,
      auth: {
        headers: {
          authorization: "Bearer " + (localStorage.getItem("auth-token") || ""),
        },
      },
    });

    echo
        .private(`App.Models.User.${state.user.id}`)
        .listen(
            ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
            (signal: any) => {
              setState({ ...stateRef.current, signal: stateRef?.current?.authStatus === "AUTHENTICATED" ? signal : null });
            }
        );

    return echo;
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (
        state.authStatus === "AUTHENTICATED"
    ) {
      echo = pusherConnection(state.user.id);
      setPusherState({ ...pusherState, connected: true });
    }
  }, [state.authStatus,state.user.id]);

  useEffect(() => {
    return () => {
      if (echo?.stopListening) {
        if (typeof echo.stopListening === "function")
          echo?.stopListening(
              ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated"
          );
      }
    };
  }, []);

  useEffect(() => {
    ["dark", "light"].forEach((theme) =>
        document.body.classList.remove(`fl-theme-${theme}`)
    );
    document.body.classList.add(`fl-theme-${state.currentTheme}`);
  }, [state.currentTheme]);

  const toggleSidebar = () => {
    setSidebarState({ ...sidebarState, active: !sidebarState.active });
  };

  const paddingBodyFunction = (x:string) => {
    setPaddingBodyState({...paddingBodyState, paddingBody:x})
  };

  const stopPusher = () => {
   echo.disconnect()
  };

  return (
      <UserContext.Provider
          value={{
            ...state,
            ...paddingBodyState,
            ...newPostState,
            ...mobilePostState,
            ...encodingProgress,
            checkAuth,
            changeTheme,
            setToken,
            setSubscriptions,
            newPostsCreatorFunction,
            clearToken,
            toggleSidebar,
            paddingBodyFunction,
            stopPusher,
            changeGridView,
            sendVerification,
            goToPost,
            updateMail,
            keyboardEvent,
            driveFiles,
            updateHandle,
            newPostFunction,
            changeStatus,
            changeActivity,
            addBank,
            messagesFunction,
            clearSignal,
            clearEncodingStatus,
            changeFreeAccount,
            readNotifications,
            readMessages,
            newMessage,
            updateCount,
            updateBalance,
            addPayment,
            encoding,
            updateWalletBalance,
            updateCountTickets,
            updateProfileImage,
            updateCountFavorites,
            updateArchivePost,
            mobileCreatePost,
            updateVerificationStatus
          }}
      >
        {props.children}
        <Sidebar state={sidebarState} setState={setSidebarState} />
      </UserContext.Provider>
  );
};

