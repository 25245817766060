import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import { SimpleCard } from "../../components/SimpleCard";
import { useUserContext } from "../../context/UserContext";

export const SupportNav: FC<any> = (props) => {
    const userData = useUserContext()
    return (
        <div className="fl-col-3 fl-support-col-holder fl-col-4-sm">
            {[
                { label: "FAQ's", path: "/support/faq"},
                { label: "Report a bug", path: "/support/report-bug" },
                { label: "Suggest a feature", path: "/support/suggest-feature" },
                { label: "Submit a ticket", path: "/support/submit-ticket" },
                { label: "My tickets", path: "/support/my-tickets" },
                { label: "Terms", path: "/support/terms" },
                { label: "Privacy", path: "/support/privacy" },
            ].map((x, y) => (
                <NavLink
                    to={x.path}
                    key={y}
                    activeClassName="fl-settings-link-active"
                    className={`fl-settings-link fl-settings-link-${userData.currentTheme}`}
                >
                    <SimpleCard className="fl-settings-link-box">
                        {x.label} <FontAwesomeIcon icon={faChevronRight} />
                    </SimpleCard>
                </NavLink>
            ))}
        </div>
    );
};
