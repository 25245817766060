import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react'
import {useHistory} from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './NotFound.css';
import {faHeartBroken} from "@fortawesome/free-solid-svg-icons";

export const DeactivateUser:FC<any> = (props) => {

    const userData = useUserContext()
    const history = useHistory()

    const redirect = () => {
        if(userData.authStatus === 'AUTHENTICATED') {
            history.push("/explore/all")
        } else {
            history.push("/login")
            // localStorage.setItem("explore","explore")
        }

    }

    return (
        <div className="fl-fullscreen-with-footer fl-fullscreen-with-height-fix">
            <div className="fl-fullscreen-with-footer-inner fl-container fl-not-found">
                <FontAwesomeIcon className="fl-not-found-icon" icon={faHeartBroken}/>
                <div className={`fl-not-found-text fl-not-found-text-${userData.currentTheme}`}>Sorry, this user is currently inactive.</div>
                <div className={`fl-not-found-redirection fl-not-found-redirection-${userData.currentTheme}`}>Click <span className="fl-not-found-redirection-link" onClick={() => redirect()}>here</span> to view other Creators.</div>
            </div>
        </div>
    )
}
