import React, { FC } from 'react';
import { useUserContext } from '../context/UserContext';
import "./Card.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Card:FC<any> = (props) => {

    // PROPS
    const { title, description, icon, type } = props.data || { data: {}};

    // CONTEXT
    const userData = useUserContext();

    return (
        <div className={`fl-card-main fl-card-main-${userData.currentTheme}`}>
            <div className="fl-card-icon">
                {type === 'fa' ?
                    <FontAwesomeIcon style={{stroke: userData.currentTheme === 'light' ? '#f4f4f4' : '#1b1b1b', strokeWidth: '12'}} className="fl-card-icon-svg" icon={icon} />
                    : <img className="fl-card-icon-img" src={icon} alt="Card icon"/>
                }
            </div>
            <div className={`fl-card-title fl-card-title-${userData.currentTheme}`}>{title}</div>
            <div className={`fl-card-description fl-card-description-${userData.currentTheme}`}>{description}</div>
        </div>
    )
}
