import React, {FC} from 'react'
import moment from "moment";

export const CheckTime:FC<any> = (props) => {

    const secondsPost = () => {
        const currentMinute = Number(moment(new Date()).format("mm"));
        const currentSeconds = Number(moment(new Date()).format("ss"));
        const postMinute = Number(moment(props.data).format("mm"));
        const postSeconds = Number(moment(props.data).format("ss"));

        const countSeconds =
            currentMinute * 60 + currentSeconds - (postMinute * 60 + postSeconds);

        return countSeconds;
    };

    return (
       <>
           {(moment.utc(props.data).fromNow() ===
               "a few seconds ago" &&
               secondsPost() < 60) ||
           (moment.utc(props.data).fromNow() ===
               "in a few seconds" &&
               secondsPost() < 60)
               ? secondsPost() < 3
                   ? "Now"
                   : secondsPost() + "s"
               : moment(new Date()).format("DD MMM") ===
               moment(props.data).format("DD MMM")
                   ? moment.utc(props.data).fromNow() ===
                   "a minute ago"
                       ? "1m"
                       : moment
                           .utc(props.data)
                           .fromNow()
                           .split(" ")
                           .slice(1)
                           .join(" ") === "minutes ago"
                           ? moment
                           .utc(props.data)
                           .fromNow()
                           .split(" ")[0] + "m"
                           : moment
                               .utc(props.data)
                               .fromNow() === "an hour ago"
                               ? "1h"
                               : moment
                               .utc(props.data)
                               .fromNow()
                               .split(" ")[0] + "h"
                   : moment(props.data).format(
                       "DD MMM"
                   ) ===
                   moment(new Date())
                       .subtract(1, "days")
                       .format("DD MMM")
                       ? "Yesterday"
                       : moment(props.data).format(
                           "YYYY"
                       ) ===
                       moment(new Date())
                           .subtract(1, "years")
                           .format("YYYY")
                           ? moment(props.data).format(
                               "D MMM, YYYY"
                           )
                           : moment(props.data).format("D MMM")}

       </>
    )
}