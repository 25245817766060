import React, {FC, Fragment,useLayoutEffect,useState} from 'react'
import {useHistory} from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './SettingsEarnings.css';
import moment from 'moment';
import axios from 'axios';
import { useEnvContext } from '../context/EnvContext';
import { useModalContext } from '../context/ModalContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faChevronCircleLeft, faSync} from "@fortawesome/free-solid-svg-icons";

export const SettingsPayouts:FC<any> = (props) => {

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory()

    const [payouts, setPayouts] =  useState<any>({
        payouts: [],
        currentPage: 1,
        last_page: 0,
    })

    const [animation,setAnimation] = useState(true)

    const getPayouts = async (page = 1) => {
        try {
            const res = await axios.get(`${envData.apiUrl}/payouts`);
            setPayouts({
                ...payouts,
                payouts: [...payouts.payouts, ...res.data.data],
                currentPage: page,
                last_page: res.data.meta.last_page,
            });
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    useLayoutEffect(() => {
        if(userData.user.profile.meta.verified_state !== "verified") {
            history.push("/feed")
        } else {
            (async () => {
                await getPayouts();
            })();
        }
    }, []);

    const redirectProfile = (e: any) => {
        history.push(`/settings`);
    };


    const paymentAccount = (card:any) => {
        const cardNumber = card.account_number?.slice(-4)
        const bankNumber = card.iban?.slice(-4)
        if(cardNumber) {
            return cardNumber
        } else if (bankNumber) {
            return bankNumber
        } else {
            return ""
        }
    }

    return (
        <Fragment>
            {animation &&
            <div className="fl-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
                <div className="fl-settings-sub-section">
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                        <span
                            className="fl-direct-messages-title-icon"
                            onClick={(e) => redirectProfile(e)}
                        >
                          <FontAwesomeIcon icon={faChevronCircleLeft} />
                        </span>
                        Payout History
                    </div>
                    <div className={`fl-settings-sub-payment-transactions fl-settings-sub-payment-transactions-${userData.currentTheme}`}>
                        <div className="fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-head">
                            <div className="fl-settings-sub-payment-transactions-single-date">Status</div>
                            <div className="fl-settings-sub-payment-transactions-single-date">Date</div>
                            <div className="fl-settings-sub-payout-transactions-single-amount">Total payout</div>
                        </div>
                        {payouts.payouts.map((x: any, y: number) => (
                            <div
                                key={y}
                                className={`fl-settings-sub-payment-transactions-single fl-settings-sub-payment-transactions-single-action fl-settings-sub-payment-transactions-single-${userData.currentTheme}`}
                            >
                                <div className="fl-settings-sub-payment-transactions-single-date  fl-settings-payout-status">
                                    <FontAwesomeIcon
                                        style={{ color: x.state === "completed" ? "green" : "#15c" }}
                                        icon={x.state === "completed" ? faCheckCircle : faSync }
                                    />
                                    <span>{x.state === "completed" ? "Paid" : "Pending"}</span>
                                </div>
                                <div className="fl-settings-sub-payment-transactions-single-date">
                                    <p>{moment(x.created_at).format("D MMM YYYY")}</p>
                                </div>
                                <div className="fl-settings-sub-payout-transactions-single-amount">
                                    ${Number(x.amount).toFixed(2)} {x.payout_account ? `sent to account ending ${paymentAccount(x?.payout_account)}` : ""}
                                </div>
                            </div>
                        ))}
                        {payouts.payouts.length > 0 &&
                        <div
                            className="fl-settings-sub-payment-transactions-more"
                            onClick={() => getPayouts(payouts.currentPage + 1)}
                        >
                            {payouts.currentPage >= payouts.last_page
                                ? ""
                                : "View more payouts"}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}