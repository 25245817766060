import {FC, Fragment, useEffect, useState} from "react";
import "./SegPayModal.css";
import React from "react";
import {useModalContext} from "../../context/ModalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useUserContext} from "../../context/UserContext";

export const SegpayModal: FC<{url:any,addCardFunction:any,amount?:number,planId?:any,action?:any,free?:any,setAnimationAdd?:any,header?:any,loadingHeader?:any;}> = (props) => {

    const modalData = useModalContext();
    const userData = useUserContext()
    const [loadingVisa,setLoadingVisa] = useState(false)

    const closeModalFunction = () => {
        setLoadingVisa(true)
        if(props.planId > 0) {
            props.addCardFunction(props.planId,props.action,props.free).then(() => { setLoadingVisa(false);modalData.closeAll() })
        } else {
            props.addCardFunction(props.amount ? props.amount : "").then(() => { setLoadingVisa(false);modalData.closeAll() })
        }
    }

    const closeModal = () => {
        if(props.setAnimationAdd) {
            props.setAnimationAdd(false)
        }
        modalData.close()
    }

    useEffect(() => {
        if(userData?.signal?.type === "App\\Notifications\\SecurityCodeConfirmed") {
            closeModalFunction()
        } else if (userData?.signal?.type === "App\\Notifications\\SecurityCodeFailed") {
            closeModal()
        } else {

        }
    },[userData?.signal])


    return (
        <Fragment>
            <div className="fl-modal-title">
                {props.header}
                <div className='fl-modal-close-button' onClick={() => closeModal()}> <FontAwesomeIcon icon={faTimes} /> </div>
            </div>
            <div className={`fl-modal-description ${loadingVisa ? "" : "fl-modal-segpay"}`}>
                {loadingVisa ?
                    <>
                        <div className="loader-payment"></div>
                        <p className={"loader-text"}>{props.loadingHeader ? props.loadingHeader : "PROCESSING PAYMENT..."}</p>
                    </> :  <iframe className={"fl-segpay-form"} src={props.url} />
                }
            </div>
        </Fragment>
    );
};
