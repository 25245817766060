import {
    faCamera, faCheck,
    faChevronCircleLeft,
    faDollarSign, faEllipsisH,
    faEye,
    faEyeSlash, faHdd, faImage,
    faInfoCircle, faMicrophoneAlt,
    faPaperclip,
    faSmile, faSyncAlt, faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    FC,
    Fragment,
    useEffect,
    useRef,
    useState,
} from "react";
import { useHistory} from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import "./SettingsMessages.css";
import ReactTooltip from "react-tooltip";
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import { Toggle } from "../components/Toggle";
import { RangeModal } from "../components/RangeModal";
import { Drag } from "../components/Drag";
import { FileDrop } from "react-file-drop";
import Picker from "emoji-picker-react";
import {Button} from "../components/Button";
import ReactLoading from "react-loading";
import {FluffaDrive} from "../components/FluffaDrive";
import {MessageAudio} from "../components/MessageAudio";
import {faStopCircle} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
//@ts-ignore
import { ReactMic } from 'react-mic';
import VideoPlayer from "../components/video/VideoPlayer";
var localStream: MediaStream | null = null;
var localTimer: any = null;

export const SettingsMessages: FC<any> = (props) => {
    const [state, setState] = useState<any>({
        body:"",
        camera: false,
        blurred: "blurred",
        blurredPercent: 50,
        price: 5,
        previewVoice:{},
        recording: false,
        record:false,
        chunks: [],
        timer: 0,
        blobURL:"",
        recordedBlob:null
    });

    const [timer,setTimer] = useState<any>({
        timer:0
    })

    const [welcomeState, setWelcomeState] = useState<any> ({
        welcome_message: "",
        welcome_attachments: [],
        welcome_price: 0,
        type: "",
        welcome_message_enabled: 0
    })

    const [uploadState, setUploadState] = useState<any>({
        files: [],
        price: 0,
        type:""
    });

    const history = useHistory();

    const [free, setFree] = useState<boolean>(false);

    const [blurred, setBlurred] = useState<boolean>(true);

    const stateRef = useRef<any>();
    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const uploadStateRef = useRef<any>(uploadState);
    useEffect(() => {
        uploadStateRef.current = uploadState;
    }, [uploadState]);


    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();

    const handleFile = async (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files) return;
        const uploadedFiles: any = e.currentTarget.files || [];
        const filesArray: any = [...uploadedFiles];
        const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
            const fileType = file.type.split("/")[0]
            return {
                type: fileType,
                file,
                src: URL.createObjectURL(file),
            };
        });
        setUploadState({
            ...uploadState,
            files: [...uploadState.files, ...newFiles],
        });
        attachRef.current.value = ""
    };

    const removeFile = (idx: number) => {
        setUploadState({
            ...uploadState,
            files: uploadState.files.filter((x: any, y: number) => y !== idx),
        });
    };

    const keyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowRight") {
            userData.keyboardEvent("ArrowRight");
        } else if (e.key === "ArrowLeft") {
            userData.keyboardEvent("ArrowLeft");
        } else {
        }
    };

    const openRangeModal = () => {
        modalData.push(() => (
            <RangeModal setState={setState} state={state} uploadState={uploadState} />
        ));
    };

    const blurPreview = () => {
        if (state.blurredPercent < 10) {
            return state.blurredPercent / 40 + 1;
        } else {
            return state.blurredPercent / 40 + 1;
        }
    };

    const [disableBlur, setDisableBlur] = useState<any>(false);
    const [disableClickDown, setDisableClickDown] = useState<any>(false);

    const showBlur = () => {
        if (disableClickDown === false) {
            setDisableBlur(false);
        }
    };

    const hideBlur = () => {
        if (disableClickDown === false) {
            setDisableBlur(true);
        }
    };

    const rightClickBlur = (e: any) => {
        if (disableClickDown === false) {
            setDisableBlur(false);
            setDisableClickDown(false);
        } else {
            setDisableBlur(false);
            setDisableClickDown(false);
        }
        e.preventDefault();
    };

    const dropFiles = async (file: any) => {
        let element = file?.currentTarget?.parentNode as Element;
        element?.classList?.remove('fl-welcome-message-drop');
        file?.preventDefault();
        const uploadedFiles: any = file.dataTransfer.files  || [];
        const filesArray: any = [...uploadedFiles];
        const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
            const fileType = file.type.split("/")[0]
            return {
                type: fileType,
                file,
                src: URL.createObjectURL(file),
            };
        });
        setUploadState({
            ...uploadState,
            files: [...uploadState.files, ...newFiles],
        });
    };

    const attachRef = useRef<any>(null);

    const [emojiState, setEmojiState] = useState({
        active: false,
    });

    const emojiRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const trackClick = (e: MouseEvent) => {
            if (emojiRef.current && e.target !== null) {
                if (
                    e.target !== emojiRef.current &&
                    //@ts-ignore
                    !emojiRef.current.contains(e.target)
                ) {
                    setEmojiState({ active: false });
                }
            }
        };
        window.addEventListener("click", trackClick);
        return () => {
            window.removeEventListener("click", trackClick);
        };
    }, []);

    const [processing, setProcessing] = useState(false);

    const cameraRef = useRef<HTMLVideoElement>(null);

    const requestCamera = async () => {
        setState({ ...state, camera: true });
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
        });
        localStream = stream;
        if (cameraRef.current) {
            cameraRef.current.srcObject = stream;
        }
    };

    const closeCamera = async () => {
        if (localStream) localStream.getTracks().forEach((x) => x.stop());
        setState({ ...state, camera: false });
    };

    const snapCamera = () => {
        if (!cameraRef.current) return;

        var canvas = document.createElement("canvas");
        canvas.width = cameraRef.current.offsetWidth;
        canvas.height = cameraRef.current.offsetHeight;
        var ctx = canvas.getContext("2d");

        //@ts-ignore
        if (ctx) {
            ctx.translate(canvas.width, 0);
            ctx.scale(-1, 1);
            ctx.drawImage(cameraRef.current, 0, 0, canvas.width, canvas.height);
            ctx.restore();
        }
        var src = canvas.toDataURL("image/jpg");
        var blobBin = atob(src.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) array.push(blobBin.charCodeAt(i));
        var file = new File([new Uint8Array(array)], "snap.jpeg",{type: "image/jpeg"});

        setUploadState({
            ...uploadState,
            files: [...uploadState.files, { type: "image", src, file }],
        });
        closeCamera();
    };

    const [animation,setAnimation] = useState(true)

    const getUserProfileData = async () => {
        try {
            const profileRes = await Axios.get(`${envData.apiUrl}/profile/${userData.user.handle}`);
            setWelcomeState({...welcomeState,welcome_message:profileRes.data.data.creatorProfile.welcome_message,welcome_attachments:profileRes.data.data.creatorProfile.welcome_attachments,welcome_price:profileRes.data.data.creatorProfile.welcome_attachment_price,welcome_message_enabled:profileRes.data.data.creatorProfile.welcome_message_enabled})
            setAnimation(false)
        }catch (e) {
            modalData.pushToast("error", "Something went wrong!");
            setAnimation(false)
        }
    };

    useEffect(() => {
        (async () => {
            await getUserProfileData();
        })();
    }, []);

    const [restrictedWords, setRestrictedWords] = useState<any>([])
    const saveWelcomeInformation = async () => {
        setProcessing(true);
        const formData = new FormData();
        formData.append("welcome_message", state.body);
        formData.append("welcome_image_price", state.price);
        if(uploadState?.files?.length > 0) {
            uploadState.files.forEach((x: any) => {
                if(x.file) {
                    formData.append("welcome_images[]", x.file);
                }
                if(x.id) {
                    formData.append("drive_files[]", x.id);
                }
            });
        }
        if(state?.previewVoice.fakeMessagePreview !== undefined) {
            formData.append("welcome_images[]", state.previewVoice.fakeMessagePreview.file);
        }

        if(free) {
            formData.append("blurred", "black");
        }else {
            if (state.blurred === "blurred") {
                formData.append("blurred", state.blurredPercent)
            } else {
                formData.append("blurred", "black")
            }
        }
        if(uploadAudio) {
            formData.append("welcome_images[]", uploadAudio.file);
        }
        try {
            const res = await Axios.post(
                `${envData.apiUrl}/creator/welcome-message`,
                formData,
                {
                    headers: { "content-type": "multipart/form-data" },
                }
            );
            modalData.pushToast("success", `Your welcome message was ${checkSetupWelcomeMessage() ? "created" : "changed"}.`);
            setWelcomeState({...welcomeState, welcome_message:state.body, welcome_price: state.price, welcome_attachments: uploadAudio ? [{url:uploadAudio.audio,type:"audio"}] : uploadState.files, type: "FAKE" })
            setUploadAudio(null)
            setUploadState({...uploadState, files:[],price:5})
            setState({...state,body:"",price:5,blobURL:""})
            setProcessing(false);
            setChangeForm(false)
            if(restrictedWords.length > 0) {
                setRestrictedWords([])
            }
        } catch (e) {
            setProcessing(false);
            if (e?.response?.data?.errors?.restricted_words?.length > 0) {
                setRestrictedWords(e?.response?.data?.errors?.restricted_words)
            } else {
                modalData.pushToast("error", "Something went wrong!");
            }
        }
    }

    const closeControls = () => {
        setChangeForm(false)
        if(restrictedWords.length > 0) {
            setRestrictedWords([])
        }
        setUploadState({...uploadState, files:[],price:5})
        setState({...state,body:"",price:5})
    }

    const flDrive = () => {
        modalData.close()
        modalData.push(() =>  <FluffaDrive  route={"files"} setUploadState={setUploadState} uploadState={uploadState} />)
    };

    const enableDisableMessage  = async () => {
        try {
            await Axios.get(
                `${envData.apiUrl}/creator/welcome-message/toggle`
            );
            if(welcomeState.welcome_message_enabled === 0) {
                setWelcomeState({...welcomeState, welcome_message_enabled:1})
            }
            if(welcomeState.welcome_message_enabled === 1) {
                setWelcomeState({...welcomeState, welcome_message_enabled:0})
            }
        } catch (e) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const deleteRecording = () => {
        setState({
            ...state,
            blobURL:"",
        });
        setUploadAudio(null)
    };


    const [uploadAudio,setUploadAudio] = useState<any>(false)

    const [mobile,setMobile] = useState<boolean>(false)

    const handleResize = () => {
        if(window.innerWidth < 991.98) {
            setMobile(true)
        }else {
            setMobile(false)
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const [changeForm, setChangeForm] = useState<boolean> (false)

    const openChangeForm = async () => {
        setChangeForm(!changeForm)
        if(state.previewVoice) {
            setState({...state, recording: false,previewVoice: {} ,chunks: [],timer: 0})
        }
    }

    const checkSetupWelcomeMessage = () => {
        if(welcomeState.welcome_message === null && welcomeState?.welcome_attachments.length === 0)
        {
            return true
        }
        else {
            return false
        }
    }

    const checkDisableButton = () => {
        if(processing ) {
            return true
        } else {
            if (state.body.length === 0 && uploadState.files.length === 0 && state?.blobURL?.length === 0) {
                return true
            }else if (state.recording && !state?.previewVoice?.fakeMessagePreview?.audio) {
                return true
            }
        }
    }

    const redirectProfile = (e:any) => {
        if (e.ctrlKey || e.metaKey) {
            history.push(`/settings`);
        } else {
            history.push(`/settings`);
        }
    }

    const dragOver = (e:any) => {
        e.preventDefault();
        let element = e.currentTarget.parentNode as Element;
        element.classList.add('fl-welcome-message-drop');
    }


    const dragLeave = (e:any) => {
        e.preventDefault();
        let element = e.currentTarget.parentNode as Element;
        element.classList.remove('fl-welcome-message-drop');
    }

    const dragEnter = (e:any) => {
        e.preventDefault();
    }


    const typeMessage = (text:any) => {
        setState({...stateRef.current, body: text})
        if(restrictedWords.length > 0) {
            setRestrictedWords([])
        }
    }

    const stateRefTimer = useRef<any>();
    useEffect(() => {
        stateRefTimer.current = timer;
    }, [timer]);

    const increaseTimer = () => {
        setTimer({...stateRef.current, timer:stateRefTimer.current.timer + 1000})
        localTimer = setTimeout(increaseTimer, 1000);
    };

    const startRecordingNew = () => {
        setState({...state,record:true})
        setTimeout(() => {
            increaseTimer()
        }, 1800);
    };

    const stopRecordingNew = () => {
        setState({...state,record:false})
    };

    const onStopNew = (recordedBlob:any) => {
        var file = new File([recordedBlob.blob], "record.ogg");
        const fakeMessagePreview = {
            audio: recordedBlob.blobURL,
            file:file
        };
        setUploadAudio(fakeMessagePreview)
        setState({...state, blobURL: recordedBlob.blobURL})
        setTimer({...timer, timer:0})
        if (localTimer) clearTimeout(localTimer);
    };
    const [playing,setPlaying] = useState(false)
    return (
        <Fragment>
            {animation &&
            <div className="fl-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            }
            <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"} `}
                 onDragOver={(e:any) => changeForm ?  dragOver(e) : ""}
                 onDragLeave={(e:any) => changeForm ? dragLeave(e) : ""}
                 onDragEnter={(e:any) => changeForm ? dragEnter(e) : ""}
                 onDrop={(files:any) => changeForm ? dropFiles(files) : ""}
                 style={{position: "relative"}}
            >
                <div className={"fl-direct-message-drop-files"}
                     onDragOver={(e:any) => changeForm ? dragOver(e) : ""}
                     onDragLeave={(e:any) => changeForm ? dragLeave(e) : ""}
                     onDragEnter={(e:any) => changeForm ? dragEnter(e) : ""}
                     onDrop={(files:any) => changeForm ? dropFiles(files) : ""}
                >
                    <span>Drop files here</span>
                </div>
                <div className="fl-settings-sub-section">
                    <div
                        className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
                    >
                          <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                          </span>
                        Welcome message
                    </div>
                </div>
                <div className="fl-settings-sub-section fl-settings-sub-section-welcome-message">
                    <p className={`fl-settings-message-p-${userData.currentTheme}`}>Set up a Welcome Message which automatically sends to new fans when they subscribe to your profile. Your message can be anything from a quick text-only Hello to a fully locked media message for which you can set an unlock price. You can even send an automatic voice clip to make your fans feel extra welcome.</p>
                    <div className={"fl-welcome-message-toggle"}>
                        <Toggle rightComponent={<FontAwesomeIcon icon={faTimes}/>} leftComponent={<FontAwesomeIcon icon={faCheck}/>} value={welcomeState.welcome_message_enabled === 1 ? true : false} onClick={() => enableDisableMessage()}/>
                    </div>
                    {welcomeState.welcome_message_enabled === 1 && checkSetupWelcomeMessage() &&
                    <span className={"fl-welcome-message-set"} onClick={openChangeForm}>Set welcome message </span>
                    }
                </div>
                {welcomeState.welcome_message_enabled === 1 &&
                <>
                    {welcomeState?.welcome_attachments?.length > 0 || welcomeState?.welcome_message?.length > 0 ?
                        <div className="fl-settings-sub-section fl-welcome-message-info-tittle">
                            <div
                                className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
                            >
                                Current welcome message
                            </div>
                        </div> : ""
                    }
                    {welcomeState?.welcome_message?.length > 0 ?
                        <div className={"fl-message-bubble-main fl-message-bubble-main-left"}>
                            <div
                                className={`fl-message-bubble-message  fl-message-bubble-message-${userData.currentTheme}`}
                            >
                                <div className={`fl-message-bubble-message-text`}>
                                    {welcomeState.welcome_message}
                                </div>
                            </div>
                        </div> : ""
                    }
                    {welcomeState?.welcome_attachments?.length > 0 &&
                    <div className={"fl-message-bubble-main fl-message-bubble-main-left"}>
                        <div
                            className={`fl-message-bubble-message fl-message-bubble-message-${userData.currentTheme}`}
                        >
                            {welcomeState.welcome_attachments.map((x:any,y:number) => (
                                <>
                                    {x.type === "image" ?
                                        <div
                                            className={`fl-message-bubble-message-attachment fl-message-bubble-message-attachment-left fl-message-bubble-message-attachment-${
                                                (welcomeState.welcome_attachments || []).length < 5
                                                    ? (welcomeState.welcome_attachments || []).length
                                                    : "more"
                                            }`}
                                        >
                                            {(welcomeState.welcome_attachments || []).length < 5 ? (
                                                (welcomeState.welcome_attachments || []).map((x: any, y: number) => (
                                                    <div
                                                        className={`fl-message-bubble-message-attachment-img`}>
                                                        <img
                                                            src={x.url || x.src}
                                                            alt="Message attachment"
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <Fragment>
                                                    {(welcomeState.welcome_attachments || [])
                                                        .filter((x: any, y: number) => y < 3)
                                                        .map((x: any, y: number) => (
                                                            <div
                                                                className="fl-message-bubble-message-attachment-img">
                                                                <img
                                                                    src={x.url || x.src}
                                                                    alt="Message attachment"
                                                                />
                                                            </div>
                                                        ))}
                                                    <div className={`fl-message-bubble-message-attachment-img ${welcomeState.welcome_attachments.length > 3 && "fl-message-bubble-message-attachment-img-blur"}`}>
                                                        <img
                                                            src={welcomeState.welcome_attachments.filter((x:any,index:number) => index === 3)[0].url}
                                                            alt="Message attachment"
                                                        />
                                                        {welcomeState.welcome_attachments.length > 3 &&
                                                        <span className={"fl-message-bubble-message-attachment-img-blur-count"}> {(welcomeState.welcome_attachments || []).length - 3}+</span>
                                                        }
                                                    </div>
                                                </Fragment>
                                            )}
                                            <div className="fl-dm-sent-box">
                                                <div className="fl-dm-sent-box-angle">
                                        <span>
                                          {welcomeState.welcome_price === "0" ? (
                                              "SENT FOR FREE"
                                          ):(
                                              `SENT FOR $${welcomeState.welcome_price}`
                                          )}
                                        </span>
                                                </div>
                                            </div>
                                        </div> : x.type === "audio" ?
                                            <MessageAudio
                                                deleteRecording={""}
                                                src={x.url}
                                                recording={""}
                                                sendRecording={""}
                                                data={""}
                                                reportedStatus={""}
                                            />
                                            : x.type === "video" ?
                                            <VideoPlayer src={x.url} thumbnail={x.thumbnail} duration={x.duration} setPlaying={setPlaying} playing={playing} />
                                            : ""
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                    }
                    {welcomeState.welcome_message_enabled === 1 && !checkSetupWelcomeMessage() && !changeForm &&
                    <div className={"fl-welcome-message-change"}>
                        <span onClick={openChangeForm}>Change welcome message </span>
                    </div>
                    }
                    {changeForm &&
                    <>
                        <div className={`fl-welcome-settings-store fl-settings-mt-10`}>
                            <div className="fl-settings-sub-section fl-welcome-message-info-tittle">
                                <div
                                    className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
                                >
                                    New welcome message
                                </div>
                            </div>
                            {state.blobURL ?
                                <div className={"fl-inbox-record"}>
                                    <div className={"fl-direct-messages-box-input-form-inner"}>
                                        <MessageAudio
                                            deleteRecording={deleteRecording}
                                            src={state.blobURL}
                                            recording={true}
                                            sendRecording={""}
                                            data={""}
                                            reportedStatus={""}
                                            removeDeleteIcon={false}
                                        />
                                    </div>
                                </div>
                                : "" }
                            <div className={`fl-inbox-record fl-inbox-record-${state.record ? "start" : "none"}`}>
                                <div className="fl-inbox-audio-recording">
                                    <div className={"fl-direct-messages-record-wrapper-inner"}>
                                        <div
                                            className={`fl-direct-messages-box-input-action-single-voice-stop`}
                                            onClick={stopRecordingNew}
                                        >
                                            <FontAwesomeIcon icon={faStopCircle} data-tip data-for="stop-recording"/>
                                            {mobile ? "" :  <ReactTooltip id="stop-recording">Stop recording</ReactTooltip> }
                                        </div>
                                        <div
                                            className="fl-direct-messages-record-audio-chunks"
                                        >
                                            <ReactMic
                                                record={state.record}
                                                className="sound-wave"
                                                onStop={onStopNew}
                                                // onData={onData}
                                                strokeColor="#FFFFFF"
                                                backgroundColor="#007eff"
                                            />
                                        </div>
                                        <div className="fl-direct-messages-record-timer">
                                            {timer.timer === 10000 ? "00:10" : moment(timer.timer, "x").format("mm:ss")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FileDrop onDrop={(files) => dropFiles(files)}>
                                <div
                                    className={`fl-direct-messages-box-input fl-settings-mt-10 fl-direct-messages-box-input-${userData.currentTheme}`}
                                >
                                    {uploadState.files.length ? (
                                        <div className="fl-direct-messages-box-files">
                                            <Drag
                                                uploadState={uploadState}
                                                setUploadState={setUploadState}
                                                state={state}
                                                disableBlur={disableBlur}
                                                blurPreview={blurPreview}
                                                removeFile={removeFile}
                                                showBlur={showBlur}
                                                hideBlur={hideBlur}
                                                rightClickBlur={rightClickBlur}
                                                setDisableBlur={""}
                                            />
                                            {userData.user.profile.creatorProfile ? (
                                                <div
                                                    className={`fl-direct-messages-box-files-price fl-bulk__price fl-bulk__price--${userData.currentTheme}`}
                                                    style={{
                                                        background: "transparent",
                                                    }}
                                                >
                                                    {!free && (
                                                        <div className="fl-bulk__price__toggle fl-bulk__price__toggle-blurred">
                                                            <Toggle
                                                                leftComponent={<FontAwesomeIcon icon={faEye} />}
                                                                rightComponent={<FontAwesomeIcon icon={faEyeSlash} />}
                                                                value={blurred ? true : false}
                                                                onClick={() => {
                                                                    blurred
                                                                        ? setState({ ...state, blurred: "black" })
                                                                        : setState({ ...state, blurred: "blurred" });
                                                                    setBlurred(!blurred);
                                                                }}
                                                            />
                                                            {blurred ? (
                                                                <div>
                                                        <span className="fl-bulk__price__label">
                                                          <span
                                                              onKeyDown={keyboardEvent}
                                                              tabIndex={0}
                                                              className="fl-bulk-blur"
                                                              onClick={openRangeModal}
                                                          >
                                                            Blur thumbnail (
                                                              {state.blurredPercent.toFixed(1).split(".")[0]}
                                                              %)
                                                            </span>{" "}
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                data-tip
                                                                data-for="attachments-blurred"
                                                            />
                                                        </span>
                                                                    <ReactTooltip id="attachments-blurred">
                                                                        Select the level of blur you’d like to add to the
                                                                        thumbnail. Less blur gives the recipient a better
                                                                        idea of what they’re unlocking.
                                                                    </ReactTooltip>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div>
                                                          <span className={`fl-bulk__price__label`}>
                                                            Hide thumbnail{" "}
                                                              <FontAwesomeIcon
                                                                  icon={faInfoCircle}
                                                                  data-tip
                                                                  data-for="m"
                                                              />
                                                          </span>
                                                                    </div>
                                                                    <ReactTooltip id="m">
                                                                        Thumbnail will be fully hidden and only a lock
                                                                        icon will show.
                                                                    </ReactTooltip>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                    <div className="fl-bulk__price__toggle ">
                                                        <div className="fl-bulk__price-set-price">
                                                            {!free ? (
                                                                <>
                                                        <span className="fl-bulk__price__label">
                                                          Set unlock price{" "}
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                data-tip
                                                                data-for="attachments-cost"
                                                            />
                                                        </span>
                                                                    <ReactTooltip id="attachments-cost">
                                                                        You can set a price from $5 to $100 for PPV
                                                                        content
                                                                    </ReactTooltip>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {!free && (
                                                                <>
                                                        <span
                                                            className={`fl-bulk__price__currency fl-bulk__price__currency--${userData.currentTheme}`}
                                                            style={{ bottom: state.price < 5 ? "28px" : "" }}
                                                        >
                                                          <FontAwesomeIcon icon={faDollarSign} />
                                                        </span>
                                                                    <input
                                                                        className={`fl-bulk__price__input--${userData.currentTheme}`}
                                                                        min="5"
                                                                        type="number"
                                                                        value={state.price}
                                                                        onKeyDownCapture={(e) => {
                                                                            if (
                                                                                (e.keyCode >= 48 && e.keyCode <= 57) ||
                                                                                e.keyCode === 8
                                                                            ) {
                                                                                if (
                                                                                    state.price.length === 0 &&
                                                                                    e.keyCode === 48
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                } else {
                                                                                    return;
                                                                                }
                                                                            } else {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(
                                                                            e: React.FormEvent<HTMLInputElement>
                                                                        ) => {
                                                                            if (
                                                                                //@ts-ignore
                                                                                e.currentTarget.value <= 100 &&
                                                                                //@ts-ignore
                                                                                e.currentTarget.value >= 0
                                                                            ) {
                                                                                return setState({
                                                                                    ...state,
                                                                                    price: e.currentTarget.value,
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className={`${free && "fl-bulk__price-free-price"}`}>
                                                            {free ? (
                                                                <>
                                                        <span className="fl-bulk__price__label">
                                                          Sending for FREE{" "}
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                data-tip
                                                                data-for="attachments-cost"
                                                            />
                                                        </span>
                                                                    <ReactTooltip id="attachments-cost">
                                                                        Message will be sent unlocked
                                                                    </ReactTooltip>
                                                                </>
                                                            ) : (
                                                                <>
                                                        <span className="fl-bulk__price__label">
                                                          PPV Message
                                                        </span>
                                                                </>
                                                            )}
                                                            <Toggle
                                                                leftComponent={
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            d="m11.666 1043.52v-1.49c0-2.02-1.645-3.667-3.666-3.667-2.02 0-3.666 1.645-3.666 3.667v1.49c-.289.06-.507.317-.507.624v5.582c0 .352.286.638.638.638h7.07c.352 0 .638-.286.638-.638v-5.582c0-.307-.218-.563-.507-.624m-2.81 4.758c.013.069-.034.125-.104.125h-1.488c-.07 0-.117-.057-.104-.125l.237-1.256c-.179-.164-.292-.399-.292-.661 0-.494.401-.895.895-.895.494 0 .895.4.895.895 0 .255-.107.485-.278.648zm1.534-4.772h-4.782v-1.476c0-1.319 1.073-2.391 2.391-2.391 1.318 0 2.391 1.073 2.391 2.391z"
                                                                            fill="#fff"
                                                                            transform="translate(0-1036.36)"
                                                                        />
                                                                    </svg>
                                                                }
                                                                rightComponent={
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 22 22"
                                                                    >
                                                                        <path
                                                                            d="m299.02 161.26h-185.84v-46.28c0-41.34 33.635-74.979 74.979-74.979 33.758 0 63.51 22.716 72.36 55.24 2.898 10.657 13.888 16.946 24.547 14.05 10.659-2.898 16.949-13.889 14.05-24.548-13.57-49.896-59.2-84.74-110.96-84.74-63.4 0-114.98 51.58-114.98 114.98v46.715c-9.06 1.902-15.888 9.952-15.888 19.571v175.05c0 11.03 8.972 20 20 20h221.73c11.03 0 20-8.972 20-20v-175.05c0-11.03-8.972-20-20-20m-84 149.65c.408 2.162-1.058 3.931-3.258 3.931h-46.677c-2.2 0-3.666-1.769-3.258-3.931l7.432-39.39c-5.626-5.131-9.157-12.52-9.157-20.734 0-15.495 12.561-28.06 28.06-28.06 15.495 0 28.06 12.561 28.06 28.06 0 7.991-3.346 15.195-8.707 20.305z"
                                                                            fill="#fff"
                                                                            transform="matrix(.04241 0 0 .04241 3.047 2.918)"
                                                                        />
                                                                    </svg>
                                                                }
                                                                value={free ? false : true}
                                                                onClick={() => {
                                                                    free
                                                                        ? setState({ ...state, price: "5" })
                                                                        : setState({ ...state, price: "0" });
                                                                    setFree(!free);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: "right" }}>
                                                        {state.price < 5 && !free && (
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    bottom: "-12px",
                                                                    color: "#ff3f47",
                                                                    display: "contents",
                                                                }}
                                                            >
                                                  Minimum $5 USD
                                                </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className={`fl-direct-messages-box-input-form`}>
                                        <div
                                            className={`fl-direct-messages-box-input-form-actions fl-direct-messages-box-input-form-actions-icons`}
                                        >
                                            <Fragment>
                                                <>
                                                    <div
                                                        className="fl-direct-messages-box-input-action-single"
                                                        onClick={() =>
                                                            setEmojiState({ active: !emojiState.active })
                                                        }
                                                        ref={emojiRef}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSmile}
                                                            data-tip
                                                            data-for="emoji"
                                                        />
                                                        {mobile ? "" : <ReactTooltip id="emoji">Choose an emoji</ReactTooltip>}
                                                        <div
                                                            className="fl-post-comment-emojis-picker"
                                                            style={{
                                                                display: emojiState.active ? "block" : "none",
                                                            }}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            <Picker
                                                                onEmojiClick={(e, emoji) => {
                                                                    setState({
                                                                        ...state,
                                                                        body: `${state.body}${emoji.emoji}`,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {!state.recording &&
                                                    <>
                                                        <input
                                                            ref={attachRef}
                                                            multiple
                                                            accept="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
                                                            type="file"
                                                            style={{display: "none"}}
                                                            onChange={(e) => handleFile(e)}
                                                        />
                                                        <div className="fl-direct-messages-box-input-action-single"
                                                             onClick={() => attachRef.current.click()}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPaperclip}
                                                                data-tip
                                                                data-for="image"
                                                            />
                                                            {mobile ? "" : <ReactTooltip id="image">Attach files (you can also drag and drop)</ReactTooltip>}
                                                        </div>
                                                    </>
                                                    }
                                                    {!state?.previewVoice?.fakeMessagePreview?.audio &&
                                                    <div
                                                        draggable={true}
                                                        className={`fl-direct-messages-box-input-action-single fl-direct-messages-box-input-action-single-voice ${state.recording && "fl-direct-messages-box-input-action-single-voice-record"} ${!state.recording && "fl-voice-record-cursor-enable"}`}
                                                        onClick={startRecordingNew}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faMicrophoneAlt
                                                            }
                                                            data-tip
                                                            data-for="voice"
                                                        />
                                                        < ReactTooltip id="voice">
                                                            {!state.recording ? "Send audio" : "Recording..."}
                                                        </ReactTooltip>
                                                    </div>
                                                    }
                                                    <div
                                                        className="fl-direct-messages-box-input-action-single"
                                                        onClick={() => flDrive()}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faHdd}
                                                            data-tip
                                                            data-for="drive"
                                                        />
                                                        {mobile ? "" : <ReactTooltip id="drive"> Insert files from Drive</ReactTooltip>}
                                                    </div>
                                                    <div className="fl-direct-messages-box-input-action-single"
                                                         onClick={requestCamera}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faCamera}
                                                            data-tip
                                                            data-for="camera"
                                                        />
                                                        {mobile ? "" : <ReactTooltip id="camera">Open camera</ReactTooltip>}
                                                    </div>
                                                </>
                                            </Fragment>
                                        </div>
                                        <div
                                            className={`fl-direct-messages-box-input-form-inner ${restrictedWords.length > 0 ? "fl-restricted-box" : ""} ${state.recording && "fl-direct-messages-box-input-form-recording"} fl-direct-messages-box-input-form-inner-${userData.currentTheme}  ${state?.previewVoice?.fakeMessagePreview?.audio && "fl-direct-messages-box-input-form-inner-preview" }`}
                                        >
                                            <input
                                                className={`${
                                                    state.body.length || uploadState.files.length
                                                        ? "fl-direct-messages-box-input-form-input-wide"
                                                        : ""
                                                } fl-direct-messages-box-input-form-input fl-direct-messages-box-input-form-input-${
                                                    userData.currentTheme
                                                }`}
                                                placeholder={"Type a welcome message..."}
                                                type="text"
                                                value={state.body}
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => typeMessage(e.currentTarget.value)}
                                            />
                                        </div>
                                        {restrictedWords.length > 0 && (
                                            <span className="fl-modal-description-row-error">
                                                <FontAwesomeIcon icon={faInfoCircle} /> You have entered restricted words: {restrictedWords.join(',')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </FileDrop>
                        </div>
                        {state.camera ? (
                            <div className="fl-messages-camera-main">
                                <video ref={cameraRef} autoPlay muted></video>
                                <div className="fl-messages-camera-controls">
                                    <div className="fl-messages-camera-controls-top">
                                        <div className="fl-messages-camera-controls-single">
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </div>
                                        <div className="fl-messages-camera-controls-single">
                                            <FontAwesomeIcon icon={faTimes} onClick={closeCamera} />
                                        </div>
                                    </div>
                                    <div className="fl-messages-camera-controls-bottom">
                                        <div className="fl-messages-camera-controls-single">
                                            <FontAwesomeIcon icon={faSyncAlt} />
                                        </div>
                                        <div
                                            className="fl-messages-camera-controls-snap"
                                            onClick={snapCamera}
                                        ></div>
                                        <div className="fl-messages-camera-controls-single">
                                            <FontAwesomeIcon icon={faImage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="fl-welcome-settings-button">
                            <Button type="normal" className="fl-settings-sub-security-button" onClick={() => closeControls()}>Cancel</Button>
                            <Button disabled={checkDisableButton()} type="normal" className="fl-settings-sub-security-button" onClick={saveWelcomeInformation}>{checkSetupWelcomeMessage() ? "Set" : "Change"} {" "}{processing && <span>  <ReactLoading type={"spinningBubbles"} color={"#fff"} height={18} width={18} className="fl-spinningBubbles"/></span>}</Button>
                        </div>
                    </>
                    }
                </>
                }
            </div>
        </Fragment>
    );
};
