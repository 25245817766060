import React, {FC, Fragment} from 'react'
import ReactLoading from "react-loading";
import {MessageGallery} from "../../components/MessageGallery";

export const ConversationGallery:FC<any> = (props) => {

    const checkLastPage = () => {
        if(props.pageFiles.current < props.pageFiles.last) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <div className={`fl-message-bubble-gallery fl-inbox-gallery ${props.pageFiles?.files?.length === 0 || props.animationGallery ? "fl-message-gallery-empty":""}`}>
                {props.animationGallery ?
                    <Fragment>
                        <div className={"fl-loader-animation"}>
                            <ReactLoading type={"spinningBubbles"} height={50} width={50} className="fl-spinningBubbles"/>
                        </div>
                    </Fragment> : props.pageFiles?.files?.length > 0 ?
                        <>
                            {props.pageFiles?.files?.map((x:any,y:number) =>
                                <MessageGallery data={x} key={y} />
                            )}
                        </>
                        :
                        <p>
                            No items found.
                        </p>
                }
            </div>
            {props.animationGallery || (!props.animationGallery && !checkLastPage()) ? "" :
                <div className="fl-messages-show-more">
                    <div className="fl-messages-show-more-link" onClick={(e) =>  props.getFiles(props.pageFiles.action,checkLastPage() ? props.pageFiles.current + 1 : 1)}>
                        {checkLastPage() ? "Show more" : "Hide"} files
                    </div>
                </div>
            }
        </>
    )
}