import React, { FC, useEffect, useRef, useState } from "react";
import Axios from "axios";
import { Button } from "../../components/Button";
import { useEnvContext } from "../../context/EnvContext";
import { useModalContext } from "../../context/ModalContext";
import ConfirmTrialModal from "./Modals/ConfirmTrialModal";
import "./SettingsPlans.css";

import CreateSubscriptionPlanTrial from "./Plans/CreateSubscriptionPlanTrial";
import CreateSubscriptionPlan from "./Plans/CreateSubscriptionPlan";
import CreateDiscountModal from "./Modals/CreateDiscountModal";
import AllSubscriptionPlansTrial from "./Plans/AllSubscriptionPlansTrial";
import moment from "moment";
import {useUserContext} from "../../context/UserContext";
import AllSubscriptionPlansTrialComplete from "./Plans/AllSubscriptionPlansTrialComplete";
import ReactLoading from "react-loading";

export const SettingsPlans: FC<any> = (props) => {
  const [state, setState] = useState<{
    plans: any;
    price: string;
    billing_period: string;
    billing_period_type: string;
    billing_period_count: string;
    offer:string;
    number_trial: string;
    expire_trial: string;
    length_trial: string;
    description:string;
    priceTrial:string
  }>({
    plans: [],
    price: "",
    billing_period: "",
    billing_period_count: "",
    billing_period_type: "",
    offer:"",
    number_trial:"",
    expire_trial:"",
    length_trial: "",
    description:"",
    priceTrial:""
  });

  const userData = useUserContext()

  const [freeAccount, setFreeAccount] = useState<boolean>(userData.user.profile.free_subscription === 0 ? false : true)

  const [customDiscountPrice, setCustomDiscountPrice] =
      useState<boolean>(false);

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const [clear, setClear] = useState(0);

  const modalData = useModalContext();
  const envData = useEnvContext();

  const [animation,setAnimation] = useState(true)

  const getSubscriptionPlans = async () => {
    try {
      const res = await Axios.get(`${envData.apiUrl}/subscription-plans`);
      setState({ ...state, plans: res.data.data });
      setAnimation(false)
    } catch (e) {
      setAnimation(false)
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const [expired,setExpired] = useState<any>([])

  const getSubscriptionExpiredPlans = async () => {
    try {
      const res = await Axios.get(`${envData.apiUrl}/subscription-plans/expired`);
      setExpired(res.data.data)
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const [customTrialExpire, setCustomTrialExpire] = useState<any>(false);
  const [animationCreate,setAnimationCreate] = useState(false)
  const [restrictedWords, setRestrictedWords] = useState<any>([])
  const [anotherButton, setAnotherButton] = useState(false)

  const createSubscriptionPlan = async () => {
    setAnimationCreate(true)
    try {
      const {
        price,
        billing_period,
      } = state;
      if (state.billing_period !== "" && state.price !== "") {
        try{
          const res = await Axios.post(`${envData.apiUrl}/subscription-plans`, {
            type: "regular",
            price: Number(price),
            billing_period: "month",
            billing_period_count: Number(billing_period)
          });
          setState({
            ...state,
            plans: [...state.plans, res.data.data],
            price: "",
            billing_period: "",
            billing_period_count: "",
            billing_period_type: ""
          });
          setClear(1);
          if(state.plans.filter((x: any) => x.type === "regular").length > 0) {
            setAnotherButton(false)
          }
          userData.setSubscriptions("add",res.data.data)
          modalData.pushToast("success", "Your price plan has been created");
        } catch (e) {
          modalData.pushToast("error", "Something went wrong!");
        }
       }
      if (state.number_trial !== "" && state.length_trial !== "" && state.expire_trial !== "") {

        await modalData.push(() =>
            <ConfirmTrialModal
            starts_at={moment(new Date())}
            ends_at={Number(state.expire_trial) > 0 ? moment(new Date()).add("days", Number(state.expire_trial)).format("D MMMM, YYYY") : Number(state.expire_trial) === 0 ? "Never expires" : moment(state.expire_trial).format("D MMMM, YYYY")}
            length={Number(state.length_trial)}
            number={Number(state.number_trial) > 0 ? Number(state.number_trial) : "Unlimited"}
            expire={state.expire_trial}
            decline={() =>modalData.close()}
            state={state}
            setState={setState}
            setClear={setClear}
            inputTypeSwitchedDate={customTrialExpire}
            inputTypeSwitchDate={setCustomTrialExpire}
            setRestrictedWords={setRestrictedWords}
            priceTrial={state.priceTrial}
            setSelectedPlan={setSelectedPlan}
        />);

      }
      setAnimationCreate(false)
    } catch (error) {
      if (
          state.plans.find(
              (e: any) => e.billing_period_count === state.billing_period_count
          )
      ) {
        modalData.pushToast(
            "error",
            <>You've already created a <span>{state.billing_period_count} {state.billing_period_count > "1" ? "months" : "month"} plan </span></>
        );
      } else {
        modalData.pushToast(
            "error",
            <>You've already created a <span>{stateRef.current.billing_period} {stateRef.current.billing_period > "1" ? "months" : "month"} plan </span></>
        );
      }
      setAnimationCreate(false)
    }
  };

  const processRemoveSubscriptionPlan = async (id: number) => {
    try {
          await Axios.delete(
          `${envData.apiUrl}/subscription-plans/${id}`
      );
      setState({
        ...state,
        plans: state.plans.filter((x: any) => x.id !== id),
      });
      await modalData.close();
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
    }
  };

  const deleteSubscriptionPlan = async (id: number) => {
    await processRemoveSubscriptionPlan(id);
  };

  const removeSubscriptionPlan = async (hasActivePlan: any, id: any, type:string) => {
    await modalData.push(() => <RemoveSubscribeModal id={id} type={type} setState={setState} state={state} />);
  };

  useEffect(() => {
    (async () => {
      await getSubscriptionPlans();
      await getSubscriptionExpiredPlans();
    })();
  }, []);

  const requestCreateDiscount = async (subscription_id: number) => {
    await modalData.push(() => (
        <CreateDiscountModal
            setState={setState}
            state={state}
            subscription_id={subscription_id}
        />
    ));
  };

  const requestRemoveDiscount = async (subscription_id: number, discount_id: number) => {
    await modalData.push(() => <StopDiscountModal subscription_id={subscription_id} discount_id={discount_id} setState={setState} stateRef={stateRef} />)
  };

  const checkIfAllAreCreated = () => {
    let options = [
      { label: "1 Month (30 days)", value: "1" },
      { label: "3 Months (90 days)", value: "3" },
      { label: "6 Months (180 days)", value: "6" },
      // { label: "Create free Trial", value: "custom" },
    ];

    if (state.plans) {
      state.plans.map((e: any) => {
        if (
            options.findIndex((x: any) => Number(x.value) === e.billing_period_count) ||
            options.findIndex((x: any) => Number(x.value) === e.billing_period_count) === 0
        ) {
          if ([1, 3, 6].includes(e.billing_period_count) && e.type === "regular") {
            options.splice(
                options.findIndex((x: any) => Number(x.value) === e.billing_period_count),
                1
            );
          }
        }
      });
    }

    return options;
  };

  const checkPriceFiled = () => {
    let options = [
      { label: "$4.99", value: "4.99" },
      { label: "$14.99", value: "14.99" },
      { label: "$19.99", value: "19.99" },
      { label: "$24.99", value: "24.99" },
      { label: "$29.99", value: "29.99" },
      { label: "$39.99", value: "39.99" },
      { label: "$49.99", value: "49.99" },
      { label: "Other amount", value: "custom" },
    ];

    if (state.plans) {
      state.plans.map((e: any) => {
        if (
            options.findIndex((x: any) => x.value === e.price) ||
            options.findIndex((x: any) => x.value === e.price) === 0
        ) {
          if ([4.99,9.99,14.99,19.99,24.99,29.99,39.99,49.99].includes(e.price) && e.type === "regular") {
            options.splice(
                options.findIndex((x: any) => x.value === e.price),
                1
            );
          }
        }
      });
    }

    return options;
  };

  const [selectedPlan,setSelectedPlan] = useState(0)

  return (
     <>
       {animation &&
         <div className="fl-spinner">
           <div></div>
           <div></div>
           <div></div>
           <div></div>
         </div>
       }
       <div className={`fl-settings-sub-stretch ${animation && "fl-settings-sub-stretch-blur"}`}>
         <CreateSubscriptionPlan
             checkIfAllAreCreated={checkIfAllAreCreated}
             clear={clear}
             setClear={setClear}
             state={state}
             setState={setState}
             customDiscountPrice={customDiscountPrice}
             setCustomDiscountPrice={setCustomDiscountPrice}
             createSubscriptionPlan={createSubscriptionPlan}
             freeAccount={freeAccount}
             setFreeAccount={setFreeAccount}
             animationCreate={animationCreate}
             requestRemoveDiscount={requestRemoveDiscount}
             requestCreateDiscount={requestCreateDiscount}
             removeSubscriptionPlan={removeSubscriptionPlan}
             deleteSubscriptionPlan={deleteSubscriptionPlan}
             checkPriceFiled={checkPriceFiled}
             setAnotherButton={setAnotherButton}
             anotherButton={anotherButton}
         />

         {freeAccount ? "" :
             <>
               <AllSubscriptionPlansTrial
                   state={state}
                   setState={setState}
                   expired={expired}
                   requestRemoveDiscount={requestRemoveDiscount}
                   requestCreateDiscount={requestCreateDiscount}
                   removeSubscriptionPlan={removeSubscriptionPlan}
                   deleteSubscriptionPlan={deleteSubscriptionPlan}
               />
               <CreateSubscriptionPlanTrial
                   clear={clear}
                   setClear={setClear}
                   state={state}
                   setState={setState}
                   createSubscriptionPlan={createSubscriptionPlan}
                   customTrialExpire={customTrialExpire}
                   setCustomTrialExpire={setCustomTrialExpire}
                   restrictedWords={restrictedWords}
                   setRestrictedWords={setRestrictedWords}
                   setSelectedPlan={setSelectedPlan}
                   selectedPlan={selectedPlan}
                   checkPriceFiled={checkPriceFiled}
               />
               <AllSubscriptionPlansTrialComplete
                   state={state}
                   setState={setState}
                   expired={expired}
                   requestRemoveDiscount={requestRemoveDiscount}
                   requestCreateDiscount={requestCreateDiscount}
                   removeSubscriptionPlan={removeSubscriptionPlan}
                   deleteSubscriptionPlan={deleteSubscriptionPlan}
               />
             </>
         }
       </div>
     </>
  );
};

export const RemoveSubscribeModal: FC<any> = ({ id,type,setState,state}) => {

  const userData = useUserContext();
  const envData = useEnvContext()
  const [animationDelete,setAnimationDelete] = useState(false)

  const removeFunction = async (id: number) => {
    setAnimationDelete(true)
    try {
      await Axios.delete(
          `${envData.apiUrl}/subscription-plans/${id}`
      );
      setState({
        ...state,
        plans: state.plans.filter((x: any) => x.id !== id),
      });
      userData.setSubscriptions("remove", id)
      await modalData.close();
      setAnimationDelete(false)
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
      setAnimationDelete(false)
    }
  };

  const modalData = useModalContext()
  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">{type === "trial" ? "End free trial" : "Delete paid plan"}</div>
        </div>
        <div className="fl-modal-description fl-modal-description-subscription">
          Are you sure you want to {type === "trial" ? "end" : "delete"} this {type === "trial" ? "free trial" : "paid plan"}?
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}> {type === "trial" ? "No" : "Cancel"}</Button>
          <Button type="normal" onClick={() => removeFunction(Number(id))} disabled={animationDelete}>
            {type === "trial" ? "Yes" :
            <>
              Delet{animationDelete ? "ing" : "e"}
            </>
            }
            {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </React.Fragment>
  );
};

export const StopDiscountModal: FC<any> = ({subscription_id,discount_id,setState,stateRef}) => {

  const envData = useEnvContext()
  const [animationDelete,setAnimationDelete] = useState(false)

  const removeDiscount = async (subscription_id: number, discount_id: number) => {
    setAnimationDelete(true)
    try{
      await Axios.post(
          `${envData.apiUrl}/subscription-plan/discount/delete`,
          { subscription_id, discount_id }
      );
      await modalData.close();
      setState({
        ...stateRef.current,
        plans: stateRef.current.plans.map((x: any) =>
            x.id === subscription_id ? { ...x, discounted_plan: null } : x
        ),
      });
      setAnimationDelete(false)
    } catch (e) {
      modalData.pushToast("error", "Something went wrong!");
      setAnimationDelete(false)
    }
  };

  const modalData = useModalContext()
  return (
      <React.Fragment>
        <div className="fl-modal-title">
          <div className="fl-modal-title-text">Stop discount</div>
        </div>
        <div className="fl-modal-description fl-modal-description-subscription">
          Are you sure you want to stop the discount for this plan?
        </div>
        <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
          <Button onClick={modalData.close}> No</Button>
          <Button type="normal" onClick={() => removeDiscount(subscription_id,discount_id)} disabled={animationDelete}>
            Stop
            {animationDelete ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
          </Button>
        </div>
      </React.Fragment>
  );
};