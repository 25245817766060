import {
  faCheckCircle,
  faChevronRight,
  faClock, faRocket,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {FC, useEffect, useRef} from "react";
import { NavLink, Redirect, useParams } from "react-router-dom";
import { SimpleCard } from "../components/SimpleCard";
import { SupportBox } from "../components/SupportBox";
import { useUserContext } from "../context/UserContext";
import "./Settings.css";
import { SettingsAccount } from "./SettingsAccount";
import { SettingsAppearance } from "./SettingsAppearance";
import { SettingsPayment } from "./SettingsPayment";
import { SettingsPlans } from "./SettingsPlans/SettingsPlans";
import { SettingsPrivacy } from "./SettingsPrivacy";
import { SettingsSecurity } from "./SettingsSecurity";
import { SettingsVerification } from "./SettingsVerification";
import { SettingsNotifications } from "./SettingsNotifications";
import { SettingsMessages } from "./SettingsMessages";
import { SettingsEmail } from "./SettingsEmail";
import ReactTooltip from "react-tooltip";
import { SettingsProfile } from "./SettingsProfile";
import {SettingsPayouts} from "./SettingsPayouts";
import {SettingsTransactions} from "./SettingsTransactions"
import {SettingsReferrals} from "./SettingsReferrals";

export const Settings: FC<any> = (props) => {

  const { subroute } = useParams<{ subroute: string }>();

  const userData = useUserContext();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
   if(containerRef.current) {
     containerRef.current.scrollTo({
       top: 0,
       left: 0
     })
   }
  }, [subroute])

  return (
    <div
      className={`fl-settings-main fl-settings-main-${userData.currentTheme}`}
      ref={containerRef}
    >
      <div className="fl-settings-inner fl-container">
        <div className="fl-d-flex fl-feed-row">
          <div
            className={`fl-col-3 fl-settings-col fl-settings-tablet-show-small ${
              subroute ? "" : "fl-settings-mobile-no-subroute-full"
            }`}
          >
            {[
              {
                label: "Account",
                path: "/settings/account",
                creatorOnly: false,
              },
              {
                label: "Profile",
                path: "/settings/profile",
                creatorOnly: false,
              },
              {
                label: "Privacy",
                path: "/settings/privacy",
                creatorOnly: false,
              },
              {
                label: "Security",
                path: "/settings/security",
                creatorOnly: false,
              },
              {
                label: "Payments",
                path: "/settings/payment",
                creatorOnly: false,
              },
              {
                label: "Transactions",
                path: "/settings/transactions",
                creatorOnly: false,
              },
              {
                label: "Earnings",
                path: "/earnings/all",
                creatorOnly: true,
              },
              {
                label: "Payouts",
                path: "/settings/payouts",
                creatorOnly: true,
              },
              // {
              //   label: "Referrals",
              //   path: "/settings/referrals",
              //   creatorOnly: true,
              // },
              { label: "Plans", path: "/settings/plans", creatorOnly: true },
              {
                label: `ID Verification`,
                path: "/settings/verification",
                creatorOnly: false,
              },
              {
                label: `Email Verification`,
                path: "/settings/email",
                creatorOnly: false,
              },
              {
                label: "Notifications",
                path: "/settings/notifications",
                creatorOnly: false,
              },
              {
                label: "Chats",
                path: "/settings/message",
                creatorOnly: true,
              },
              {
                label: "Content",
                path: "/drive/files",
                creatorOnly: true,
              },
              {
                label: "Appearance",
                path: "/settings/appearance",
                creatorOnly: false,
              },
            ].map((x, y) =>
              !x.creatorOnly ||
              (x.creatorOnly && userData.user.profile.meta.verified_state === "verified") ? (
                <NavLink
                  to={x.path}
                  key={y}
                  activeClassName="fl-settings-link-active"
                  className={`fl-settings-link fl-settings-link-${userData.currentTheme}`}
                >
                  <SimpleCard className="fl-settings-link-box">
                   <span>
                     {x.label}
                     {(x.label === "ID Verification" && userData.user.profile.meta.verified_state === "not_verified") || (x.label === "ID Verification" && userData.user.profile.meta.verified_state === "rejected")  ? (
                         <FontAwesomeIcon
                             className="fl-settings-svg-not-verified"
                             data-tip
                             data-for="not_verified"
                             style={{marginLeft: 4 }}
                             icon={faTimesCircle}
                         />
                     ) : ""}
                     {x.label === "ID Verification" && userData.user.profile.meta.verified_state === "verified" && (
                         <FontAwesomeIcon
                             className="fl-settings-svg-verified"
                             data-tip
                             data-for="verified"
                             style={{marginLeft: 4 }}
                             icon={faCheckCircle}
                         />
                     )}
                     {x.label === "ID Verification" && userData.user.profile.meta.verified_state === "pending" && (
                         <FontAwesomeIcon
                             className="fl-settings-svg-pending"
                             data-tip
                             data-for="pending"
                             style={{marginLeft: 4 }}
                             icon={faClock}
                         />
                     )}
                     {x.label === "Email Verification" &&  userData.newMail !== "" && (
                         <>
                           <FontAwesomeIcon
                               data-tip
                               data-for="send_verification"
                               className="fl-settings-svg-send-verification"
                               style={{marginLeft: 4 }}
                               icon={faRocket}
                               color={"orange"}
                           />
                           <ReactTooltip
                               id="send_verification"
                           >
                             Pending
                           </ReactTooltip>
                         </>
                     )}
                     {x.label === "Email Verification" && userData.sendVerificationStatus && userData?.user?.email_verified_at === null && (
                             <>
                               <FontAwesomeIcon
                                   data-tip
                                   data-for="send_verification"
                                   className="fl-settings-svg-send-verification"
                                   style={{marginLeft: 4 }}
                                   icon={faRocket}
                                   color={"orange"}
                               />
                               <ReactTooltip
                                   id="send_verification"
                               >
                                 Pending
                               </ReactTooltip>
                             </>
                     )}
                     {x.label === "Email Verification" && userData?.user?.email_verified_at === null  && !userData.sendVerificationStatus && userData.newMail === "" && (
                             <FontAwesomeIcon
                                 className="fl-settings-svg-not-verified"
                                 data-tip
                                 data-for="not_verified_email"
                                 style={{marginLeft: 4 }}
                                 icon={faTimesCircle}
                             />
                     )}
                     {x.label === "Email Verification" && userData?.user?.email_verified_at !== null && userData.newMail === "" && (
                         <FontAwesomeIcon
                             className="fl-settings-svg-verified"
                             data-tip
                             data-for="verified_email"
                             style={{marginLeft: 4 }}
                             icon={faCheckCircle}
                         />
                     )}
                   </span>
                    <>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </>
                  </SimpleCard>
                </NavLink>
              ) : (
                ""
              )
            )}
            {/*<SupportBox side="right" />*/}
          </div>
          <div
            className={`fl-col-9 fl-settings-col fl-settings-tablet-show-large ${
              subroute ? "" : "fl-settings-mobile-no-subroute-hide"
            }`}
          >
            <SubSettings key={subroute} route={subroute} />
          </div>
          {/* <div className="fl-col-2 fl-settings-col fl-settings-tablet-hide">
                        <SupportBox/>
                    </div> */}
        </div>
      </div>
      <ReactTooltip
          id={`${userData?.user?.email_verified_at === null ? ("not_verified_email"):("verified_email")}`}
      >
        {userData?.user?.email_verified_at === null ? ("Not verified") : ("Verified")
        }
      </ReactTooltip>
      <ReactTooltip
          id={`${userData.user.profile.meta.verified_state === "not_verified" ? ("not_verified")
              : userData.user.profile.meta.verified_state === "verified" ? ("verified")
                  : userData.user.profile.meta.verified_state === "pending" ? ("pending") : ("")
          }`}>
        {userData.user.profile.meta.verified_state === "not_verified" ? ("Not verified")
            : userData.user.profile.meta.verified_state === "verified" ? ("Verified")
                : userData.user.profile.meta.verified_state === "pending" ? ("In review") : ("")
        }
      </ReactTooltip>
    </div>
  );
};

const SubSettings: FC<{ route: string }> = ({ route }) => {
  //if(route === undefined)return <Redirect to="/settings/account"/>
  if (
    ![
      "account",
      "profile",
      "payment",
      "security",
      "verification",
      "privacy",
      "appearance",
      "earnings",
      "plans",
      "notifications",
      "email",
      "message",
      "payouts",
      "referrals",
      "transactions"
    ].includes(route) &&
    route !== undefined
  )
    return <Redirect to="/404" />;

  const SubSettingsRoutesAll: {
    account: FC;
    profile: FC;
    payment: FC;
    security: FC;
    verification: FC;
    email: FC;
    privacy: FC;
    appearance: FC;
    plans: FC;
    notifications: FC;
    message: FC;
    payouts: FC;
    referrals: FC;
    transactions: FC;
  } = {
    account: SettingsAccount,
    profile: SettingsProfile,
    security: SettingsSecurity,
    payment: SettingsPayment,
    verification: SettingsVerification,
    email: SettingsEmail,
    privacy: SettingsPrivacy,
    plans: SettingsPlans,
    appearance: SettingsAppearance,
    notifications: SettingsNotifications,
    message: SettingsMessages,
    payouts: SettingsPayouts,
    referrals: SettingsReferrals,
    transactions: SettingsTransactions
  };

  //@ts-ignore
  const SubSettingsRoute = SubSettingsRoutesAll[route] || SettingsAccount;

  return (
    <SimpleCard className="fl-settings-sub-main">
      <SubSettingsRoute />
    </SimpleCard>
  );
};
