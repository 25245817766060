import React, { FC } from 'react'
import { useUserContext } from '../context/UserContext';
import './ThirdParty.css';

// ASSETS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const ThirdParty:FC<any> = (props) => {

    const userData = useUserContext();
    
    return (
        <div className={`fl-third-party-main fl-third-party-main-${userData.currentTheme}`}>
            <div className="fl-container fl-third-party-inner">
                <div className={`fl-third-party-title fl-third-party-title-${userData.currentTheme}`}>2257</div>
                <div className="fl-third-party-section">
                    <div className="fl-third-party-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        18 U.S.C. Section 2257 Compliance Notice
                    </div>
                    <div className={`fl-third-party-row fl-third-party-row-${userData.currentTheme}`}>All persons that appear in any visual portrayal of actual sexually explicit conduct appearing or otherwise contained within Fluffa.com were over the age of eighteen (18) years at the time the visual image was created.</div>
                </div>
                <div className="fl-third-party-section">
                    <div className="fl-third-party-section-title">
                        <FontAwesomeIcon icon={faChevronRight}/>
                        Exemption Statement -- Content Produced by Third Parties:
                    </div>
                    <div className={`fl-third-party-row fl-third-party-row-${userData.currentTheme}`}>The owners and operators of Fluffa.com are not the "producers," as such term is defined in 18 U.S.C. 2257 or subsequent case law, of any of the visual content contained in this website. All Third Parties that upload content on to this website must adhere to and comply with USC 2257. Pursuant to Title 18 U.S.C. §2257(h)(2)(B)(v) and 47 U.S.C. §230(c), the operators of this website reserve the right to delete content posted by Third Parties which operators deem to be indecent, obscene, defamatory or inconsistent with their policies and terms of service.</div>
                    <div className={`fl-third-party-row fl-third-party-row-${userData.currentTheme}`}>Questions or comments regarding this Exemption Statement should be sent to: <a href="mailto: compliance@fluffa.com">compliance@fluffa.com</a></div>
                </div>
            </div>
        </div>
    )
}