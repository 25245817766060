import React, {FC, Fragment, useRef, useState} from "react";
import "./Support.css";
import "../Privacy.css";
import { useUserContext } from "../../context/UserContext";
import {SimpleCard} from "../../components/SimpleCard";
import {ProfileHolder} from "../../components/ProfileHolder";
import {Link, useHistory} from "react-router-dom";
import {useEnvContext} from "../../context/EnvContext";
import {useModalContext} from "../../context/ModalContext";
import {SingleComment} from "./SingleComment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//@ts-ignore
import RenderSmoothImage from "render-smooth-image-react";
import {
    faChevronCircleLeft,
    faChevronDown,
     faPaperclip, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {Textarea} from "../../components/Textarea";
import {Button} from "../../components/Button";
import ReactLoading from "react-loading";
import Axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";

export const SingleProblem: FC<any> = (props) => {
    const userData = useUserContext();
    const envData = useEnvContext()
    const modalData = useModalContext()
    const history = useHistory()

    const [state,setState] = useState<any>({
        currentImage:0,
        dropdown:false
    })
    const [showComments, setShowComments] = useState<boolean>(false)
    const [ticketFiles,setTicketFiles] = useState<any>({
        files: []
    })
    const [answer,setAnswer] = useState("")
    const [animation,setAnimation] = useState(false)

    const cancelComment = () => {
        setAnswer("")
    }

    const postAnswer = async (id:number) => {
        setAnimation(true)
        try {
            const formData = new FormData();
            formData.append("body", answer);
            if(ticketFiles?.files?.length > 0) {
                ticketFiles?.files?.forEach((x: any) => {
                    formData.append("images[]", x.file);
                });
            }

            const res = await Axios.post(
                `${envData.apiUrl}/problem/reports/${id}/comments`, formData
            );
            props.setReports({...props.reports, reports: props.reports.reports.map((x:any) => x.id === id ? {...x, comments: [...x.comments, res.data.data] } : x)})
            setAnswer("")
            setTicketFiles({...ticketFiles, files:[]})
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    };

    const images = () => {
        if(props.data.images && props.data.images !== null && props.data.images !== undefined) {
            try {
                return JSON.parse(props.data.images)
            }catch (e) {
                return props.data.images
            }
        } else {
            return ""
        }
    }

    const formatImages = () => {
        const formatImages:any = []
          if(images().length > 0) {
              images().map((x:any) => {
                  formatImages.push({url:x})
              })
          }
        return formatImages
    }

    const videos = () => {
        if(props.data.videos && props.data.videos !== null && props.data.videos !== undefined) {
            try {
                return JSON.parse(props.data.videos)
            }catch (e) {
                return props.data.videos
            }
        } else {
            return ""
        }
    }

    const updateStatus = async (action:string) => {
        setState({ ...state, dropdown: false })
        modalData.push(() => <UpdateStatusModal action={action} id={props.data.id} status={props.data.status} reports={props.reports} setReports={props.setReports}/>)
    }

    const checkTicketPage = () => {
        if(window.location.pathname === "/support/my-tickets" && props.data.status === "in_progress" ) {
            return true
        } else {
            return false
        }
    }

    const checkStatus = () => {
        if(props.data.status === "in_progress" ) {
            return true
        } else {
            return false
        }
    }

    const redirectPage = (e:any) => {
        history.push(`/support/my-tickets`);
    }

    const inputRef = useRef<HTMLInputElement>(null);
    const inputRefAddMore = useRef<HTMLInputElement>(null);

    const removeFile = (e:any, index:number) => {
        e.stopPropagation()
        setTicketFiles({...ticketFiles, files:ticketFiles?.files?.filter((a: any, b: any) => b !== index)})
    }

    const handleFile = async (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files) return;
        const uploadedFiles: any = e.currentTarget.files || [];
        const filesArray: any = [...uploadedFiles];
        const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
            const fileType = file.type.split("/")[0]
            return {
                type: fileType,
                file,
                src: URL.createObjectURL(file),
            };
        });
        setTicketFiles({...ticketFiles, files: [...ticketFiles.files, ...newFiles]})
        if(inputRef.current){
            inputRef.current.value = ""
        }
    };

    return (
        <SimpleCard className={`fl-post-main fl-ticket fl-simple-card-main-${userData.currentTheme} fl-ticket-${!checkStatus() ? "closed" : "open"}-${userData.currentTheme}`}>
            {props.singleProblem ?
                <div
                    className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}
                >
                    <span
                        className="fl-direct-messages-title-icon"
                        onClick={(e) => redirectPage(e)}
                    >
                      <FontAwesomeIcon icon={faChevronCircleLeft} />
                    </span>
                    My tickets
                </div> : ""
            }
            <div className={`fl-post-head`}>
                <div className="fl-post-head-image">
                    <ProfileHolder
                        src={userData.user.profile.profileImage?.url}
                    />
                </div>
                <div className={`fl-post-head-info fl-support-head-info`} onBlur={() => checkTicketPage() && setState({ ...state, dropdown: false })}>
                    <Link
                        to={`/${userData.user.handle}`}
                        className={`fl-post-head-info-name fl-post-head-info-name-${userData.currentTheme}`}
                    >
                        {userData.user.name}
                    </Link>
                    <div className="fl-d-flex fl-align-center">
                        <Link
                            to={`/${userData.user.handle}`}
                            className="fl-post-head-info-username"
                        >
                            @{userData.user.handle}
                        </Link>
                        <div className="fl-post-head-info-dot"></div>
                        {!checkStatus() ?
                            <div className={"fl-support-closed-div"}>
                                <div>
                                    <span>Ticket opened on:</span>
                                    <span>{moment.utc(props?.data?.created_at).local().format('D MMM YYYY HH:mm')}</span>
                                </div>
                                <div>
                                    <span className={"fl-support-closed"}>Ticket closed on:</span>
                                    <span className={"fl-support-closed"}>
                                        {moment.utc(props?.data?.updated_at).local().format('D MMM YYYY HH:mm')}
                                    </span>
                                </div>
                            </div>
                            :
                            <div className={"fl-support-closed-div"}>
                                <div>
                                    <span>Ticket opened on:</span>
                                    <span>{moment.utc(props?.data?.created_at).local().format('D MMM YYYY HH:mm')}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {checkStatus() ?
                    <div
                        className="fl-post-head-actions"
                        tabIndex={1}
                        onBlur={() => setState({ ...state, dropdown: false })}
                    >
                        <FontAwesomeIcon
                            className="fl-post-head-actions-icon"
                            icon={faChevronDown}
                            onClick={() =>
                                setState({ ...state, dropdown: !state.dropdown })
                            }
                        />
                        {state.dropdown ?
                            <div className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}>
                                <SimpleCard className="fl-post-head-actions-dropdown-inner">
                                    <div
                                        className="fl-post-head-actions-dropdown-option"
                                        onClick={() => updateStatus(props.data.status === "in_progress" ? "resolved" : "in_progress")}
                                    >
                                        <div className="fl-post-head-actions-dropdown-option-icon">
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                        {props.data.status === "resolved" ? "Open" : "Close"} ticket
                                    </div>
                                </SimpleCard>
                            </div>
                            : "" }
                    </div> : ""
                }
            </div>
            {images().length > 0 || videos().length > 0 ? (
                <div className={`fl-file-ticket-main`}>
                    <span>
                         <FontAwesomeIcon
                             icon={faPaperclip}
                             data-tip
                             data-for="image"
                         /> Attachments
                    </span>
                   <div className={"fl-ticket-attachments"}>
                       {images().length > 0 ?
                           <>
                               {images().map((e:any,y:any) => (
                                   <div className="fl-file-ticket-image"
                                        onClick={() => modalData.toggleImageDm(formatImages(), y, false)}
                                   >
                                       <RenderSmoothImage src={e} />
                                   </div>
                               ))}
                           </> : ""
                       }
                       {videos().length > 0 ?
                           <>
                               {videos().map((e:any,y:any) => (
                                   <div className="fl-file-ticket-image">
                                       <video
                                           src={e}
                                           controls
                                           controlsList="nodownload"
                                       ></video>
                                   </div>
                               ))}
                           </>
                           : ""
                       }
                   </div>
                </div>
            ) : (
                ""
            )}
            <div className={`fl-problem-subject fl-problem-subject-${userData.currentTheme}`}>
                <span>Subject: {props.data.subject}</span>
            </div>
            <div className={`fl-problem-text fl-problem-text-${userData.currentTheme}`}>
                <p><span>Message:</span> {props.data.body}</p>
            </div>
            {props?.data?.comments?.length > 0 ?
                <div
                    className={`fl-post-counts fl-problem-reply`}
                >
                    <div className="fl-post-counts-comments"
                         onClick={() => setShowComments(!showComments) }
                    >
                        {props?.data?.comments?.length} {props?.data?.comments?.length > 0 ? <>repl{props?.data?.comments?.length > 1 ? "ies" : "y"}</> : ""}
                    </div>
                </div> : ""
            }
            {props?.data?.comments?.length > 0 ?
                <div className={`fl-post-comments fl-problem-comments fl-post-comments-${userData.currentTheme}`}>
                    {(showComments  ? props.data.comments : props.data.comments.slice(0,3)).map((x:any, y:any) => {
                        return (
                            <SingleComment
                                data={x}
                                key={y}
                            />
                        );
                    })}
                    {props.data.comments.length > 3 ? <span className={'fl-support-show-more'} onClick={() => setShowComments(!showComments)}>{showComments ? "View less" : "View more"} replies...</span> : ""}
                </div>
                :
                ""
            }
            {checkStatus() ?
                <div
                    className={`fl-post-comments-make fl-post-comments-make-${userData.currentTheme}`}
                >
                    <div className="fl-post-comments-make-all">
                        <Textarea
                            onKeyDown={(e: any) => {
                                e.key === "Escape" &&
                                cancelComment()
                            }}
                            placeholder="Add a reply..."
                            className={`fl-post-comments-make-input fl-post-comments-make-input-${userData.currentTheme}`}
                            value={answer}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                setAnswer(e.currentTarget.value)
                            }
                        />
                    </div>
                    <div className="fl-settings-verification-file fl-settings-mt-5">
                        <div className={`fl-file-picker-main`}>
                            {ticketFiles?.files.length ? <div className="fl-file-picker-images">
                                    {ticketFiles?.files.map((x: any, y: any) => <div className="fl-file-picker-image">
                                        {x.type === "image" ?
                                            <img key={y} src={x.src} alt={"support"}/> : ""
                                        }
                                        {x.type === "video" ?
                                            <video muted autoPlay loop src={x.src}/> : ""
                                        }
                                        <div
                                            className="fl-bug-preview-img-remove"
                                            onClick={(e) => removeFile(e, y)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                    </div>)}
                                    <div
                                        className="fl-bulk__input__files--line--add"
                                        onClick={() => inputRefAddMore?.current?.click()}
                                        data-tip
                                        data-for="add-media"
                                    >
                                        <input
                                            ref={inputRefAddMore}
                                            id="upload_image"
                                            multiple
                                            accept="image/jpeg, image/png, video/mp4, video/x-m4v, video/*"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) => handleFile(e)}
                                        />
                                        +
                                    </div>
                                    <ReactTooltip id="add-media">Add more</ReactTooltip>
                                </div> :
                                <div className={"fl-d-flex fl-align-center"}>
                                    <div className={"fl-report-bug-attach fl-settings-mt-5"}
                                         onClick={() => inputRef?.current?.click()}>
                                        <FontAwesomeIcon
                                            icon={faPaperclip}
                                            data-tip
                                            data-for="image"
                                        />
                                        <ReactTooltip id="image">Attach files</ReactTooltip>
                                        <input
                                            ref={inputRef}
                                            id="upload_image"
                                            multiple
                                            accept="image/jpeg, image/png"
                                            type="file"
                                            style={{display: "none"}}
                                            onChange={(e) => handleFile(e)}
                                        />
                                    </div>
                                    <span className={"fl-settings-grey fl-ml-10 fl-mt-5"}>Attach screenshot (optional)</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="fl-post-comments-make-buttons">
                        <Button
                            className="fl-post-comments-make-button"
                            type="normal"
                            onClick={() => postAnswer(props.data.id)}
                            disabled={answer.trim() === "" || animation}
                        >
                            Reply
                            {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                        </Button>
                    </div>
                </div> : ""
            }
        </SimpleCard>
    );
};

const UpdateStatusModal: FC<any> = ({ ...props }) => {

    const userData = useUserContext()
    const envData = useEnvContext()
    const modalData = useModalContext()
    const history = useHistory()

    const [animation,setAnimation] = useState(false)
    const updateStatus = async (action:string) => {
        setAnimation(true)
        try {
            await Axios.put(
                `${envData.apiUrl}/problem/reports/${props.id}`,
                { status: action }
            );
            if(action === "resolved") {
                userData.updateCountTickets("close")
                modalData.pushToast('success', 'Your ticket has been closed');
            }
            if(window.location.pathname === `/support/ticket/${props.id}`) {
                props.setReports({...props.reports, reports: props.reports.reports.map((x:any) => x.id === props.id ? {...x,status: action,updated_at: moment(new Date())} : x )})
            } else {
                props.setReports({...props.reports, reports: props.reports.reports.filter((x:any) => x.id !== props.id )})
            }
            modalData.close()
            localStorage.setItem("ticket", "close")
            history.push('/support/my-tickets')
            setAnimation(false)
        } catch (e) {
            setAnimation(false)
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    return (
        <Fragment >
            <div className="fl-modal-title">
                <div className="fl-modal-title-text">{props.status === "resolved" ? "Open" : "Close"} ticket</div>
            </div>
            <div className="fl-modal-description">
                <div className="fl-modal-description-row">Are you sure you want to {props.status === "resolved" ? "open" : "close"} this ticket?</div>
            </div>
            <div className="fl-modal-buttons fl-d-flex fl-justify-flex-end">
                <Button onClick={() => modalData.close()}>No</Button>
                <Button
                    type="normal"
                    onClick={() =>updateStatus(props.status === "in_progress" ? "resolved" : "in_progress")}
                    disabled={animation}
                >
                    Yes, close it
                    {animation ? <ReactLoading className="fl-spinningBubbles" type={"spinningBubbles"} color={"#FFFFFF"} height={20} width={20}/>: ""}
                </Button>
            </div>
        </Fragment>
    )}
