import {
    faCheckCircle,
    faChevronCircleLeft,
    faRocket
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, Fragment, useState } from 'react'
import { Button } from '../components/Button';
import { useUserContext } from '../context/UserContext';
import './SettingsEmail.css';
import axios from "axios";
import {useModalContext} from "../context/ModalContext";
import {useEnvContext} from "../context/EnvContext";
import ReactLoading from "react-loading";
import {useHistory} from "react-router";
import { EmailConfirmation } from './SettingsAccount';

export const SettingsEmail:FC<any> = () => {

    const userData = useUserContext();
    const envData = useEnvContext();
    const modalData = useModalContext();
    const history = useHistory();

    const [form, setForm] = useState(true);
    const [proccessing, setProccessing] = useState(false);

    const sendVerification = async () => {
        try {
            setProccessing(true);
            setTimeout(() => {
                axios.get(`${envData.apiUrl}/auth/email/verification/resend`);
                setProccessing(false);
                setForm(false);
            }, 500);
            userData.sendVerification()
        }catch (error) {
            modalData.pushToast("error", "Something went wrong!");
        }
    }

    const redirectAccount = () => {
        history.push("/settings/account")
    }


    const addEmail = () => {
        modalData.push(() => <EmailConfirmation title='Add email address' contentText='Please add an email address' placeholderText='Enter email address'/>);
    }

    const redirectProfile = (e:any) => {
        history.push(`/settings`);
    }

    return (
        <Fragment>
            <div className="fl-settings-sub-stretch">
                <div className={`fl-settings-sub-section ${userData?.user?.email_verified_at != null && "fl-pb-0"}`}>
                    <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                      <span className="fl-direct-messages-title-icon"  onClick={(e) => redirectProfile(e)}>
                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                      </span>
                        Email verification
                    </div>
                    <div className={`fl-settings-email fl-settings-sub-appearance-dark-mode-${userData.currentTheme}  ${userData?.user?.email_verified_at === null && "fl-settings-mt-30"}`}>
                        {userData.newMail !== "" && (
                            <>
                                <p>
                                    We've sent a verification email to: <span className="fl-settings-email-verification">{userData.newMail}</span>
                                </p>
                                <Button
                                    onClick={redirectAccount}
                                    type="normal"
                                    className="fl-settings-sub-security-button fl-settings-update-mail fl-verify-button"
                                >
                                    DONE
                                </Button>
                                <p>*If you can't find it, check your spam folder.</p>
                            </>
                        )}
                        {!userData.user.email ? 
                        (
                            <span className="fl-settings-sub-account-update-span">
                                    Please add an email address.
                                    <Button onClick={addEmail} className='fl-settings-update-email-button'>Add email address</Button>
                                  </span>
                        )
                        :(userData.newMail === "" &&
                        (userData?.user?.email_verified_at !== null ?
                            (
                                <span className="fl-settings-sub-account-update-span">
                                    Your email address: {userData.user.email} has been verified{" "}
                                    <FontAwesomeIcon
                                        style={{color: "green"}}
                                        icon={faCheckCircle}
                                    />
                                  </span>
                            ) : (
                                (form ?
                                        <>
                                            <p>Your email address has not been verified.</p>
                                            <Button
                                                onClick={sendVerification}
                                                type="normal"
                                                className="fl-settings-sub-security-button fl-settings-update-mail fl-verify-button fl-verify-button-float"
                                            >
                                                Verify my email address
                                            </Button>
                                            {!proccessing ||
                                            <ReactLoading className='fl-loader-animation' type={"spinningBubbles"}
                                                          color={"#ff0d72"} height={35} width={35}/>}
                                            <p className={"fl-settings-email-p"}>We'll send a verification email
                                                to: <span
                                                    className="fl-settings-email-verification">{userData.user.email}</span>
                                            </p>
                                        </>
                                        :
                                        <>
                                            {/*<p>*/}
                                            {/*    Verification email sent <FontAwesomeIcon style={{color: "#53A451", marginLeft: '5px'}} icon={faCheck}/>*/}
                                            {/*</p>*/}
                                            <p>
                                                <FontAwesomeIcon
                                                    className="fl-settings-svg-send-verification"
                                                    style={{marginLeft: 4}}
                                                    icon={faRocket}
                                                    color={"orange"}
                                                /> {" "}
                                                We've sent a verification email to: <span
                                                className="fl-settings-email-verification">{userData.user.email}</span>
                                            </p>
                                            <Button
                                                onClick={redirectAccount}
                                                type="normal"
                                                className="fl-settings-sub-security-button fl-settings-update-mail fl-verify-button"
                                            >
                                                DONE
                                            </Button>
                                            <p>
                                                *If you can't find it, check your spam folder
                                            </p>
                                        </>
                                )
                            ))
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}