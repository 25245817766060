import React, {FC, useEffect, useRef, useState} from "react";
import { Button } from "../../components/Button";
import { SimpleCard } from "../../components/SimpleCard";
import "./Support.css";
import "../Privacy.css";
import { MInput } from "../../components/MInput";
import axios from "axios";
import { useEnvContext } from "../../context/EnvContext";
import { useUserContext } from "../../context/UserContext";
import { useModalContext } from "../../context/ModalContext";
import ReactLoading from "react-loading";
import {SupportNav} from "./SupportNav";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft, faInfoCircle, faPaperclip, faTimes} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

export const SupportTicket: FC<any> = (props) => {
    const userData = useUserContext()
    const envData = useEnvContext()
    const modalData = useModalContext()
    const history = useHistory()
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [ticket,setTicket] = useState<any>("")
    const [subject,setSubject] = useState("")
    const [ticketImages, setTicketImages] = useState<any>({
        files:[]
    })
    const [wrongTypeFile, setWrongTypeFile] = useState<any>(false);
    const handleFile = async (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files) return;
        const uploadedFiles: any = e.currentTarget.files || [];
        const filesArray: any = [...uploadedFiles];
        const newFiles = filesArray.filter((x:any) => x.type !== "").map((file: any) => {
            const fileType = file.type.split("/")[0]
            return {
                type: fileType,
                file,
                src: URL.createObjectURL(file),
            };
        });
        setTicketImages({...ticketImages, files: [...ticketImages.files, ...newFiles ]})
        if(inputRef.current){
            inputRef.current.value = ""
        }
        if(inputRefAddMore.current){
            inputRefAddMore.current.value = ""
        }
    };
    const [animation,setAnimation] = useState(false)
    const submitTicket = async () => {
        modalData.clearToasts()
        setAnimation(true)
        const formData = new FormData();
        formData.append("body", ticket);
        formData.append("subject", subject);
        if(ticketImages?.files?.length > 0) {
            ticketImages?.files?.forEach((x: any) => {
                if (x.type === "image") {
                    formData.append("images[]", x.file);
                }
                if (x.type === "video") {
                    formData.append("videos[]", x.file);
                }
            });
        }

        await axios
            .post(`${envData.apiUrl}/problem/reports?type=support`, formData)
            .then((res) => {
                history.push('/support/my-tickets')
                userData.updateCountTickets("open")
                setTicket("")
                setSubject("")
                modalData.pushToast("success", `Your ticket has been opened`);
                setAnimation(false)
            })
            .catch((err) => {
                modalData.pushToast("error", "Something went wrong!");
                setAnimation(false)
            });
    };
    const inputRef = useRef<HTMLInputElement>(null);
    const inputRefAddMore = useRef<HTMLInputElement>(null);

    const removeFile = (e:any, index:number) => {
        e.stopPropagation()
        setTicketImages({...ticketImages.files, files:ticketImages?.files?.filter((a: any, b: any) => b !== index)})
    }
    const clearForm = () => {
        setTicket("")
        setSubject("")
        setTicketImages({...ticketImages, files:[]})
        if(wrongTypeFile) {
            setWrongTypeFile(false)
        }
    }
    const disableButton = () => {
        if((ticket.length && subject.length) === 0 || animation) {
            return true
        } else {
            return false
        }
    }
    const inputEl = useRef<any>(null);

    useEffect(() => {
        if (inputEl && inputEl.current) {
            inputEl.current.style.height = "0px";
            const scrollHeight = inputEl.current.scrollHeight;
            inputEl.current.style.height = scrollHeight + "px"
        }
    }, [props.value]);

    return (
        <div className={`fl-support-main fl-support-main-${userData.currentTheme}`}>
            {/*{userData.authStatus === "AUTHENTICATED" ? <Subroutes /> : ""}*/}
            <div
                className="fl-support-inner fl-container"
                style={{ paddingTop: userData.authStatus === "AUTHENTICATED" ? 0 : 40 }}
            >
                <div className="fl-d-flex fl-feed-row xsm-block-view">
                    {!isMobile ?  <SupportNav /> : ""}

                    <div className="fl-col-9 fl-support-col">
                        <SimpleCard>
                            {isMobile ?
                                <div className={`fl-settings-sub-title fl-settings-sub-title-${userData.currentTheme}`}>
                                    <span
                                        className="fl-direct-messages-title-icon"
                                        onClick={(e) => history.push('/support')}
                                    >
                                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                                </span>
                                    Submit a ticket
                                </div>
                            :
                                <h3>Submit a ticket</h3>
                            }
                            <MInput
                                type="text"
                                label="Subject"
                                value={subject}
                                setValue={(subject) => setSubject(subject)}
                                className={"fl-settings-mt-30 "}
                                maxLength={20}
                            />
                            <MInput
                                ref={inputEl}
                                type="textarea"
                                label="Tell us what's happening..."
                                value={ticket}
                                setValue={(ticket) => setTicket(ticket)}
                                className={`fl-settings-mt-30 ${wrongTypeFile ? "fl-wrong-type-file" : ""}`}
                                maxLength={2000}
                            />
                            {wrongTypeFile && (
                                <span className="fl-modal-description-row-error">
                                   <FontAwesomeIcon icon={faInfoCircle}/> You have uploaded wrong type of video file (only mp4 support)
                                </span>
                            )}
                            <div className="fl-settings-verification-file fl-settings-mt-5">
                                <div className={`fl-file-picker-main`}>
                                    {ticketImages?.files?.length ? <div className="fl-file-picker-images">
                                            {ticketImages?.files?.map((x: any, y: any) => <div className="fl-file-picker-image">
                                                {x.type === "image" ?
                                                    <img key={y} src={x.src} alt={"support"}/> : ""
                                                }
                                                {x.type === "video" ?
                                                    <video muted autoPlay loop src={x.src}/> : ""
                                                }
                                                <div
                                                    className="fl-bug-preview-img-remove"
                                                    onClick={(e) => removeFile(e, y)}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </div>
                                            </div>)}
                                            <div
                                                className="fl-bulk__input__files--line--add"
                                                onClick={() => inputRefAddMore?.current?.click()}
                                                data-tip
                                                data-for="add-media"
                                            >
                                                <input
                                                    ref={inputRefAddMore}
                                                    id="upload_image"
                                                    multiple
                                                    accept="image/jpeg, image/png, video/mp4"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => handleFile(e)}
                                                />
                                                +
                                            </div>
                                            <ReactTooltip id="add-media">Add more</ReactTooltip>
                                        </div> :
                                        <div className={"fl-d-flex fl-align-center"}>
                                            <div className={"fl-report-bug-attach fl-settings-mt-5"}
                                                 onClick={() => inputRef?.current?.click()}>
                                                <FontAwesomeIcon
                                                    icon={faPaperclip}
                                                    data-tip
                                                    data-for="image"
                                                />
                                                <ReactTooltip id="image">Attach files</ReactTooltip>
                                                <input
                                                    ref={inputRef}
                                                    id="upload_image"
                                                    multiple
                                                    accept="image/jpeg, image/png, video/mp4"
                                                    type="file"
                                                    style={{display: "none"}}
                                                    onChange={(e) => handleFile(e)}
                                                />
                                            </div>
                                            <span className={"fl-settings-grey fl-ml-10 fl-mt-5"}>Attach screenshot (optional)</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"fl-d-flex"}>
                                <Button
                                    type="outlined"
                                    className="fl-settings-sub-security-button"
                                    onClick={() => clearForm()}
                                    disabled={disableButton() || wrongTypeFile}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="normal"
                                    className="fl-settings-sub-security-button fl-ml-10"
                                    onClick={() => submitTicket()}
                                    disabled={disableButton()}
                                >
                                    Submit
                                    {animation &&
                                    <span>
                                       <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>
                                    </span>
                                    }
                                </Button>
                            </div>
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
