import React, {FC, useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import { useUserContext } from "../../../../../../context/UserContext";

import Attach from "./Attach";
import {CircularProgressbar} from "react-circular-progressbar";
import hideImage from "../../../../../../assets/images/hideimage.png";
import PreviewVideoMessage from "../../../../../Messages/PreviewVideoMessage";

const List: FC<any> = ({ message, setMessage,progress,cancel,setCancel,retry, setRetry,cancelUpload,setStateBlurPercent,stateBlurPercent }) => {
  const userData = useUserContext();

  useEffect(() => {
    if(userData.selectedDriveFiles.length > 0) {
      setMessage({
        ...message,
        files: userData.selectedDriveFiles,
      });
    }
  }, []);

  const removeFile = (type:string,idx: any,filesId:any,e:any) => {
    e.stopPropagation()

    if(type === "default") {
      setMessage({
        ...message,
        files: message.files.filter((x: any, y: number) => y !== idx),
      });
    }
    if(type === "post") {
      const post = message.files.find((x:any) => x.id === idx )
        if(post.postImages.length > 1) {
          const removeFile = message.files.map((e: any) => {
            e.id === idx ? (e.postImages = e.postImages.filter((x:any) => x.id !== filesId)) : (e.postImages = e.postImages);
            return e;
          })
          setMessage({
            ...message,
            files: removeFile,
          });
        } else {
          const removePost = message?.files.filter((x:any) => x.id !== idx)
          setMessage({
            ...message,
            files: removePost,
          });
        }
    }

    if (message.files.length < 2) {
      setCancel({ ...cancel, cancel: false });
      setRetry({ ...retry, retry: false });
    }
  };

  const blurPreview = () => {
    if(stateBlurPercent.blurredPercent < 10) {
      return stateBlurPercent.blurredPercent / 40 + 1
    } else {
      return stateBlurPercent.blurredPercent / 40 + 1
    }
  }

  const [disableBlur, setDisableBlur] = useState<any>(false);
  const [disableClickDown, setDisableClickDown] = useState<any>(false);

  const showBlur = (e:any) => {
    e.stopPropagation()
    if(disableClickDown === false) {
      setDisableBlur(false)
    }
  }

  const hideBlur = (e:any) => {
    e.stopPropagation()
    if(disableClickDown === false) {
      setDisableBlur(true)
    }
  }

  const rightClickBlur = (e:any) => {
    if(disableClickDown === false) {
      setDisableBlur(false)
      setDisableClickDown(false)
    } else {
      setDisableBlur(false)
      setDisableClickDown(false)
    }
    e.preventDefault()
  }

  const checkBlur = () => {
    if(message.blurred === "blurred" || message.price === 0 || disableBlur) {
      return true
    }
  }

  return (
    <>
      {message.files.length ? (
        <div
          className={`fl-bulk__input__files fl-bulk__input__files--${userData.currentTheme}`}
        >
          <div className="fl-bulk__input__files--line">
            {message.files.map((x: any, y: number) => {
              if (x.type === "image") {
                return (
                  <div key={y} className="fl-bulk__input__files--line--item">
                  <div onMouseUp={(e) => showBlur(e)} onMouseDown={(e) => hideBlur(e)} onContextMenu={(e) => rightClickBlur(e)}>
                    <img alt={`fluffa`} style={{ filter: `blur(${message.blurred === "blurred" && message.price > 0 && !disableBlur ? blurPreview() : "0"}px)` }} src={checkBlur() && x.src ? x.src : checkBlur() && x.url ? x.url : hideImage} />
                  </div>
                    <div
                        className="fl-bulk__input__files--line--item-remove"
                        onClick={(e) => removeFile("default",y,"",e)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                );
              } else if (x.type === "video") {
                return (
                  <div key={y} className="fl-bulk__input__files--line--item">
                    <div  onMouseUp={(e) => showBlur(e)} onMouseDown={(e) => hideBlur(e)} onContextMenu={(e) => rightClickBlur(e)}>
                      {message.blurred === "blurred" || message.price === 0 || disableBlur ?
                          <>
                            <PreviewVideoMessage state={message} disableBlur={disableBlur} blurPreview={blurPreview} src={x.src}/>
                          </>
                          :
                          <>
                            <img src={hideImage} alt={"fluffa"}/>
                          </>
                      }
                    </div>
                    <div
                        className="fl-bulk__input__files--line--item-remove"
                        onClick={(e) => removeFile("default",y,"",e)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                );
              } else {
                return (
                    x.postImages.map((files:any, y:number) => {
                      return (
                          <div key={y} className="fl-bulk__input__files--line--item" >
                            <div onMouseUp={(e) => showBlur(e)} onMouseDown={(e) => hideBlur(e)} onContextMenu={(e) => rightClickBlur(e)}>
                              <img alt={`fluffa`} style={{ filter: `blur(${message.blurred === "blurred" && message.price > 0 && !disableBlur ? blurPreview() : "0"}px)` }} src={checkBlur() && files.url ? files.url : checkBlur() && files.url ? files.url : hideImage} />
                            </div>
                            <div
                                className="fl-bulk__input__files--line--item-remove"
                                onClick={(e) => removeFile("post",x.id,files.id,e)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </div>
                      )
                    })
                )
              }
            })}
            <Attach message={message} setMessage={setMessage} type="plus" />
            {progress.uploading ? (
            <div className="fl-bulk__sending fl-bulk__sending-progress-bar">
              <CircularProgressbar
                  className="fl-bulk__sending__progress"
                  value={progress.percent}
                  text={`${progress.percent}%`}
              />
              <span className="circural-progress-bar-span" onClick={cancelUpload}>
                CANCEL
              </span>
            </div>
            ) : cancel.cancel ? (
                <div className="fl-bulk__sending fl-bulk__sending-progress-bar-cancel">
                  <CircularProgressbar
                      className="fl-bulk__sending__progress"
                      value={progress.percent}
                      text={`${progress.percent}%`}
                  />
                  <span className="circural-progress-bar-span">
                      {
                         retry.retry === true && message.files.length > 1 ? (
                            `${message.files.length} uploads cancelled! ${" "}`
                             ) :
                             retry.retry === true && message.files.length === 1 ? (
                               `${message.files.length} upload cancelled! ${" "}`
                             ) : ("")
                      }
                </span>
                </div>
            ) : (
                ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
};

export default List;
