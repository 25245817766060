import React, { useState, FC, Fragment, useEffect, useRef } from "react";
import { Button } from "./Button";
import { useUserContext } from "../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { useModalContext } from "../context/ModalContext";
import moment from "moment";

import "./DatePicker.css";

const DatePickerModal: FC<{
  onSave?: (x: Date, y?: string) => void;
  isScheduleModal?: boolean;
  scheduledTimeValue?: string;
  scheduleDateValue: { day: string; year: string; month: string };
  handleCloseOther?: () => void;
  min?: Date;
  max?: Date;
}> = ({
  onSave,
  min,
  max,
  handleCloseOther,
  isScheduleModal,
  scheduleDateValue,
  scheduledTimeValue,
}) => {
  const userData = useUserContext();
  const modalData = useModalContext();
  const [timeValue1, setTimeValue1] = useState<string | number>(
    moment().format("hh")
  );
  const [timeValue2, setTimeValue2] = useState<string | number>(
    moment().format("mm")
  );
  const [state, setState] = useState<{
    valueText: string;
    value: Date;
    calendarValues: Array<{ date: Date; type: string }>;
  }>({
    value: new Date(
      scheduleDateValue?.year
        ? Number(scheduleDateValue?.year)
        : new Date().getFullYear(),
      scheduleDateValue?.month
        ? Number(scheduleDateValue?.month) - 1
        : new Date().getMonth(),
      scheduleDateValue?.month
        ? Number(scheduleDateValue?.day)
        : isScheduleModal
        ? new Date().getDate()
        : new Date().getDate() - 10
    ),
    calendarValues: [],
    valueText: moment(
      new Date(
        scheduleDateValue?.year
          ? Number(scheduleDateValue?.year)
          : new Date().getFullYear(),
        scheduleDateValue?.month
          ? Number(scheduleDateValue?.month) - 1
          : new Date().getMonth(),
        scheduleDateValue?.month
          ? Number(scheduleDateValue?.day)
          : isScheduleModal
          ? new Date().getDate()
          : new Date().getDate() - 10
      )
    ).format("DD/MM/YYYY"),
  });

  useEffect(() => {
    if (moment(state.value).year() === new Date().getFullYear()) {
      setState({
        ...stateRef.current,
        valueText: moment(state.value).format("D MMM YYYY"),
      });
    } else {
      setState({
        ...stateRef.current,
        valueText: moment(state.value).format("D MMM YYYY"),
      });
    }
  }, [state.value]);

  const stateRef = useRef<{
    valueText: string;
    value: Date;
    calendarValues: Array<{ date: Date; type: string }>;
  }>(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    if (timeValue1.toString().length === 1) {
      setTimeValue1(`0${timeValue1}`);
    }
    if (timeValue2.toString().length === 1) {
      setTimeValue2(`0${timeValue2}`);
    }
  }, [timeValue1, timeValue2]);

  useEffect(() => {
    if (scheduledTimeValue) {
      let tempArr = scheduledTimeValue.split(":");
      if (tempArr.length >= 2) {
        setTimeValue1(tempArr[0]);
        setTimeValue2(tempArr[1]);
      }
    }
  }, [scheduledTimeValue]);

  const handleSetHours = (value: number) => {
    if (Number(value) > 24 || typeof value !== "number") return;
    if (value.toString().length > 2) {
      setTimeValue1(value.toString().slice(0, 1));
      return;
    }
    setTimeValue1(value);
  };

  const handleSetMinutes = (value: number) => {
    if (Number(value) > 59 || typeof value !== "number") return;
    if (value.toString().length > 2) {
      setTimeValue2(value.toString().slice(0, 1));
      return;
    }
    setTimeValue2(value);
  };

  const previousMonth = () => {
    if (min) {
      if (stateRef.current.value.getMonth() + 1 <= moment(min).month() + 1)
        return;
    }
    const value = new Date(
      stateRef.current.value.getFullYear(),
      stateRef.current.value.getMonth() - 1,
      stateRef.current.value.getDate()
    );
    setState({ ...stateRef.current, value });
  };

  const nextMonth = () => {
    const value = new Date(
      stateRef.current.value.getFullYear(),
      stateRef.current.value.getMonth() + 1,
      stateRef.current.value.getDate()
    );
    setState({ ...stateRef.current, value });
  };

  useEffect(() => {
    generateCalencarValues(stateRef.current.value);
  }, [state.valueText]);

  useEffect(() => {
    setState({
      ...stateRef.current,
      valueText: moment(state.value).format("DD/MM/YYYY"),
    });
  }, [state.value]);

  const generateCalencarValues = (x: Date = stateRef.current.value) => {
    // VALUES
    var calendarValues = [];

    // CURRENT MONTH
    const startOfMonth = new Date(x.getFullYear(), x.getMonth(), 1);
    const endOfMonth = new Date(x.getFullYear(), x.getMonth() + 1, 0);

    // LAST MONTH
    const endOfLastMonth = new Date(x.getFullYear(), x.getMonth(), 0);

    // NEXT MONTH
    const startOfNextMonth = new Date(x.getFullYear(), x.getMonth() + 1, 1);

    // US START FORMAT
    const dayInWeek = (startOfMonth.getDay() + 6) % 7;

    // PUSH PREVIOUS MONTH
    for (let i = 0; i < dayInWeek; i++) {
      calendarValues.push({
        type: "previous",
        date: new Date(
          endOfLastMonth.getFullYear(),
          endOfLastMonth.getMonth(),
          endOfLastMonth.getDate() - i
        ),
      });
    }

    calendarValues = calendarValues.reverse();

    // PUSH CURRENT MONTH
    for (let i = 0; i < endOfMonth.getDate(); i++) {
      calendarValues.push({
        type: "current",
        date: new Date(
          startOfMonth.getFullYear(),
          startOfMonth.getMonth(),
          startOfMonth.getDate() + i
        ),
      });
    }

    // PUSH NEXT MONTH
    let i = 0;
    while (calendarValues.length < 42) {
      calendarValues.push({
        type: "next",
        date: new Date(
          startOfNextMonth.getFullYear(),
          startOfNextMonth.getMonth(),
          startOfNextMonth.getDate() + i
        ),
      });
      i++;
    }

    setState({ ...stateRef.current, calendarValues });
  };

  const sameDates = (x: Date, y: Date) => {
    return (
      x.getFullYear() === y.getFullYear() &&
      x.getMonth() === y.getMonth() &&
      x.getDate() === y.getDate()
    );
  };

  const isMinDay = (x: string | number) => {
    if (min) {
      if (Number(x) < Number(moment(min).format("D"))) {
        return true;
      } else return false;
    } else return false;
  };

  useEffect(() => {
    generateCalencarValues();
  }, []);

  const preventChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // NO ACTION IF DELETING
    if (e.key === "Backspace") {
      return;
    }

    // PREVENT IF MORE THAN 8 CHARS
    if (e.currentTarget.value.length >= 10) {
      e.preventDefault();
    }

    // SPLITS
    if (
      [2, 5].includes(e.currentTarget.value.length) &&
      !["/", ".", "-"].includes(e.key)
    ) {
      e.preventDefault();
    }

    // NUMBERS
    if (
      [0, 1, 3, 4, 6, 7, 8, 9].includes(e.currentTarget.value.length) &&
      !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const generateValue = (x: string) => {
    const stateAppend: any = {};

    const dateValueRegex = x.match(/^\d{2}([./-])\d{2}\1\d{4}$/);

    if (dateValueRegex) {
      const dateValues = dateValueRegex[0].split(dateValueRegex[1]);

      stateAppend.value = new Date(
        dateValues[2] ? Number(dateValues[2]) : new Date().getFullYear(),
        dateValues[1] ? Number(dateValues[1]) - 1 : new Date().getMonth(),
        dateValues[0] ? Number(dateValues[0]) : new Date().getDate()
      );
    }
    if (stateAppend.value) {
      setState({ ...stateRef.current, ...stateAppend });
    } else {
      setState({ ...stateRef.current, valueText: x });
    }
  };

  const saveDate = async () => {
    if (onSave) {
      if (isScheduleModal) {
        if (
          Number(timeValue1) > 24 ||
          Number(timeValue1) < 1 ||
          Number(timeValue2) < 0 ||
          Number(timeValue2) > 59
        ) {
          modalData.pushToast("error", "Please enter valid time.");
          return;
        }
        let tempData = `${timeValue1}:${timeValue2}`;
        onSave(state.value, tempData);
      } else onSave(state.value);
      //await modalData.close();
    } else await modalData.close();
  };

  const handleMonthChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = new Date(
      state.value.getFullYear(),
      Number(e.currentTarget.value),
      state.value.getDate()
    );

    setState({ ...stateRef.current, value });
  };

  const handleYearChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = new Date(
      Number(e.currentTarget.value),
      state.value.getMonth(),
      state.value.getDate()
    );
    setState({ ...stateRef.current, value });
  };

  const handleModalClose = () => {
    modalData.close();
    if (handleCloseOther) handleCloseOther();
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Backspace") {
      if(e.currentTarget.value.length === 2) {
        const text = stateRef.current.valueText + "/"
        setState({ ...stateRef.current, valueText: text });
      } else if (e.currentTarget.value.length === 5) {
        const text = stateRef.current.valueText + "/"
        setState({ ...stateRef.current, valueText: text });
      }
    } else {
      setState({ ...stateRef.current, valueText: e.currentTarget.value });
      if(state.valueText.length > 9){
        setState({
          ...stateRef.current,
          valueText: "",
        })
      }
    }
  };

  useEffect(() => {
    if (moment(state.value).year() === new Date().getFullYear()) {
      setState({
        ...stateRef.current,
        valueText: moment(state.value).format("D MMM YYYY"),
      });
    } else {
      setState({
        ...stateRef.current,
        valueText: moment(state.value).format("D MMM YYYY"),
      });
    }
  }, [state.value]);

  return (
    <div
      className={`fl-datepicker-modal-main fl-datepicker-modal-main-${userData.currentTheme}`}
    >
      <div
        className={`fl-datepicker-modal-title fl-datepicker-modal-title-${userData.currentTheme}`}
      >
        {isScheduleModal ? "Select date & time" : "Select date"}
      </div>
      <div className="fl-datepicker-modal-content">
        <div className="fl-datepicker-modal-calendar-side">
          <div className="fl-datepicker-modal-month">
            <div
              className={`fl-datepicker-modal-month-current fl-datepicker-modal-month-current-${userData.currentTheme}`}
            >
              <select
                value={state.value.getMonth()}
                onChange={handleMonthChange}
              >
                {[
                  { name: "January", num: 1 },
                  { name: "February", num: 2 },
                  { name: "March", num: 3 },
                  { name: "April", num: 4 },
                  { name: "May", num: 5 },
                  { name: "June", num: 6 },
                  { name: "July", num: 7 },
                  { name: "August", num: 8 },
                  { name: "September", num: 9 },
                  { name: "October", num: 10 },
                  { name: "November", num: 11 },
                  { name: "December", num: 12 },
                ].map((x, y) => (
                  <option
                    disabled={
                      min
                        ? x.num < moment(min).month() + 1
                          ? true
                          : false
                        : false
                    }
                    value={y}
                  >
                    {x.name}
                  </option>
                ))}
              </select>
              <select
                value={state.value.getFullYear()}
                onChange={handleYearChange}
              >
                {new Array(100).fill(undefined).map((x, y) => (
                  <option
                    key={y}
                    disabled={
                      (min && 1930 + y < moment(min).year()) ||
                      (max && 1930 + y > moment(max).year())
                        ? true
                        : false
                    }
                    value={1930 + y}
                  >
                    {1930 + y}
                  </option>
                ))}
              </select>
              {/* {moment(state.value).format("MMMM YYYY")} */}
            </div>
            <div className="fl-datepicker-modal-month-actions">
              <div
                className={`fl-datepicker-modal-month-action fl-datepicker-modal-month-action-${userData.currentTheme}-`}
                onClick={previousMonth}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <div
                className={`fl-datepicker-modal-month-action fl-datepicker-modal-month-action-${userData.currentTheme}-`}
                onClick={nextMonth}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          </div>
          <div className="fl-datepicker-modal-calendar">
            <div className="fl-datepicker-modal-calendar-row fl-datepicker-modal-calendar-row-head">
              {["M", "T", "W", "T", "F", "S", "S"].map(
                (x: string, y: number) => (
                  <div
                    key={y}
                    className={`fl-datepicker-modal-calendar-row-cell fl-datepicker-modal-calendar-row-cell-${userData.currentTheme}`}
                  >
                    {x}
                  </div>
                )
              )}
            </div>
            {state.calendarValues.length === 42
              ? [0, 1, 2, 3, 4, 5].map((x: number, y: number) => (
                  <div className="fl-datepicker-modal-calendar-row" key={y}>
                    {[0, 1, 2, 3, 4, 5, 6].map((a: number, b: number) => (
                      <div
                        key={b}
                        className={`fl-datepicker-modal-calendar-row-cell fl-datepicker-modal-calendar-row-cell-${
                          userData.currentTheme
                        } ${
                          ["previous", "next"].includes(
                            state.calendarValues[x * 7 + a].type
                          )
                            ? "fl-datepicker-modal-calendar-row-cell-invalid"
                            : ""
                        } ${
                          sameDates(
                            state.calendarValues[x * 7 + a].date,
                            state.value
                          )
                            ? "fl-datepicker-modal-calendar-row-cell-active"
                            : ""
                        } ${
                          isMinDay(
                            moment(state.calendarValues[x * 7 + a].date).format(
                              "D"
                            )
                          ) &&
                          stateRef.current.value.getMonth() + 1 <=
                            moment(min).month() + 1
                            ? "fl-datepicker-modal-calendar-row-cell-invalid fl-datepicker-modal-calendar-row-cell-disable"
                            : ""
                        }`}
                        onClick={() =>
                          setState({
                            ...stateRef.current,
                            value:
                              stateRef.current.calendarValues[x * 7 + a].date,
                          })
                        }
                      >
                        <span className="fl-datepicker-modal-calendar-row-cell-value">
                          {moment(state.calendarValues[x * 7 + a].date).format(
                            "D"
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                ))
              : ""}
          </div>
        </div>
        <div className="fl-datepicker-modal-date-side">
          {/*<input*/}
          {/*  placeholder="DD/MM/YYYY"*/}
          {/*  value={state.valueText}*/}
          {/*  className={`fl-datepicker-modal-date-input fl-datepicker-modal-date-input-${userData.currentTheme}-`}*/}
          {/*  type="text"*/}
          {/*  onKeyDown={preventChange}*/}
          {/*  onChange={(e: React.FormEvent<HTMLInputElement>) =>*/}
          {/*    generateValue(e.currentTarget.value)*/}
          {/*  }*/}
          {/*/>*/}
          <div className={"fl-input-datepicker"}>
            <input
                placeholder={``}
                value={state.valueText}
                className={`fl-datepicker-modal-date-input fl-datepicker-modal-date-input-${userData.currentTheme}-`}
                type="text"
                onKeyUp={handleKeyUp}
                onKeyDown={preventChange}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    generateValue(e.currentTarget.value)
                }
            />

            <div
                className={`fl-datepicker-placeholder fl-datepicker-placeholder-${userData.currentTheme}`}
            >
              <div
                  className={`fl-datepicker-placeholder-cover fl-datepicker-placeholder-cover-${userData.currentTheme}`}
              />
              <span style={{visibility: state.valueText.length > 0 ? "hidden" : "visible"}}>D</span>
              <span style={{visibility: state.valueText.length > 1 ? "hidden" : "visible"}}>D</span>
              <span style={{visibility: state.valueText.length > 2 ? "hidden" : "visible"}}>/</span>
              <span style={{visibility: state.valueText.length > 3 ? "hidden" : "visible"}}>M</span>
              <span style={{visibility: state.valueText.length > 4 ? "hidden" : "visible"}}>M</span>
              <span style={{visibility: state.valueText.length > 5 ? "hidden" : "visible"}}>/</span>
              <span style={{visibility: state.valueText.length > 6 ? "hidden" : "visible"}}>Y</span>
              <span style={{visibility: state.valueText.length > 7 ? "hidden" : "visible"}}>Y</span>
              <span style={{visibility: state.valueText.length > 8 ? "hidden" : "visible"}}>Y</span>
              <span style={{visibility: state.valueText.length > 9 ? "hidden" : "visible"}}>Y</span>
            </div>
          </div>
          <br />
          {isScheduleModal && (
            <div className="fl-datepicker-time-picker-container">
              <input
                type="string"
                className="fl-datepicker-time-picker"
                onChange={(e) => handleSetHours(Number(e.target.value))}
                value={timeValue1}
              />
              <b>:</b>
              <input
                type="string"
                className="fl-datepicker-time-picker"
                onChange={(e) => handleSetMinutes(Number(e.target.value))}
                value={timeValue2}
              />
            </div>
          )}
        </div>
      </div>
      <div className="fl-datepicker-modal-buttons">
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button type="normal" onClick={saveDate}>
          {isScheduleModal ? "Schedule Post" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export const DatePicker: FC<{
  inputTypeSwitchDate?: any;
  inputTypeSwitchedDate?: boolean;
  showModalInitialy?: boolean;
  hideInputField?: boolean;
  isScheduleModal?: boolean;
  scheduledTimeValue?: string;
  handleCloseOther?: () => void;
  value: string;
  onSave?: (x: Date, y?: string) => void;
  [x: string]: any;
  min?: Date;
  max?: Date;
  label: string;
}> = (props) => {
  const filteredProps = Object.keys(props)
    .filter(
      (key) =>
        !["className", "style", "type", "value", "defaultValue"].includes(key)
    )
    .reduce((obj: any, key: any) => {
      obj[key] = props[key];
      return obj;
    }, {});

  // CONTEXT
  const userData = useUserContext();
  const modalData = useModalContext();

  // STATE
  const [state, setState] = useState({
    value: props.value,
    focused: false,
  });

  useEffect(() => {}, [props.value]);

  const preventChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // NO ACTION IF DELETING
    if (
      ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace"].includes(
        e.key
      )
    ) {
      return;
    }

    // PREVENT IF MORE THAN 10 CHARS
    if (e.currentTarget.value.length >= 10) {
      e.preventDefault();
    }

    // SPLITS
    if (
      [2, 5].includes(e.currentTarget.value.length) &&
      !["/", ".", "-"].includes(e.key)
    ) {
      e.preventDefault();
    }

    // NUMBERS
    if (
      [0, 1, 3, 4, 6, 7, 8, 9].includes(e.currentTarget.value.length) &&
      !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setState({ ...state, value: e.currentTarget.value });
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Backspace") {
      let input = e.currentTarget.value;
      let out = input.replace(/\D/g, "");
      let len = out.length;

      if (len > 1 && len < 4) {
        out = out.substring(0, 2) + "/" + out.substring(2, 3);
      } else if (len >= 4) {
        out =
          out.substring(0, 2) +
          "/" +
          out.substring(2, 4) +
          "/" +
          out.substring(4, len);
        out = out.substring(0, 10);
      }
      setState({ ...state, value: out });
    } else {
      setState({ ...state, value: e.currentTarget.value });
    }
  };

  var onSaveProps: any = {};
  if (props.onSave) {
    onSaveProps.onSave = (v: string, y?: string) => {
      setState({ ...state, value: moment(v).format("DD/MM/YYYY") });

      //@ts-ignore
      props.onSave(v, y);
    };
  }
  if (props.min) {
    onSaveProps.min = props.min;
  }
  if (props.max) {
    onSaveProps.max = props.max;
  }
  if (props.isScheduleModal) {
    onSaveProps.isScheduleModal = props.isScheduleModal;
  }
  if (props.handleCloseOther) {
    onSaveProps.handleCloseOther = props.handleCloseOther;
  }
  if (props.scheduledTimeValue?.length)
    onSaveProps.scheduledTimeValue = props.scheduledTimeValue;

  if (props.isScheduleModal && props.value?.length) {
    let tempArr = props.value.split("/");
    if (tempArr.length) {
      onSaveProps.scheduleDateValue = {
        year: tempArr[2],
        month: tempArr[1],
        day: tempArr[0],
      };
    }
  }

  useEffect(() => {
    async function showModal() {
      await modalData.push(() => <DatePickerModal {...onSaveProps} />);
    }
    if (props.showModalInitialy) showModal();
  }, [props.showModalInitialy]);

  useEffect(() => {
    var dateValueRegex = state.value.match(/^\d{2}([./-])\d{2}\1\d{4}$/);
    if (dateValueRegex) {
      const dateValues = dateValueRegex[0].split(dateValueRegex[1]);
      if (props.isScheduleModal) return;
      //@ts-ignore
      props.onSave(
        new Date(
          dateValues[2] ? Number(dateValues[2]) : new Date().getFullYear(),
          dateValues[1] ? Number(dateValues[1]) - 1 : new Date().getMonth(),
          dateValues[0] ? Number(dateValues[0]) : new Date().getDate()
        )
      );
    }
  }, [state.value]);

  useEffect(() => {
    if (props.clear === true) {
      setState({ ...state, value: "" });
      props.setClear(false);
    }
  }, [props.clear]);

  return (
    <Fragment>
      {props.hideInputField ? null : (
        <div
          className={`fl-datepicker-outter ${props.className}`}
          style={props.style}
        >
          <input
            onFocus={() => setState({ ...state, focused: true })}
            onBlur={() => setState({ ...state, focused: false })}
            value={state.value}
            className={`fl-datepicker fl-datepicker-${userData.currentTheme}`}
            type="text"
            {...filteredProps}
            onKeyDown={preventChange}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
          />
          {state.focused && (
            <div
              className={`fl-datepicker-placeholder fl-datepicker-placeholder-${userData.currentTheme}`}
            >
              <div
                  className={`fl-datepicker-placeholder-cover fl-datepicker-placeholder-cover-${userData.currentTheme}`}
                  // style={{
                  //   width:
                  //     state.value.length < 10
                  //       ? `calc(${state.value.length}*11px)`
                  //       : "100%",
                  // }}
              />
              <span style={{visibility: state.value.length > 0 ? "hidden" : "visible"}}>D</span>
              <span style={{visibility: state.value.length > 1 ? "hidden" : "visible"}}>D</span>
              <span style={{visibility: state.value.length > 2 ? "hidden" : "visible"}}>/</span>
              <span style={{visibility: state.value.length > 3 ? "hidden" : "visible"}}>M</span>
              <span style={{visibility: state.value.length > 4 ? "hidden" : "visible"}}>M</span>
              <span style={{visibility: state.value.length > 5 ? "hidden" : "visible"}}>/</span>
              <span style={{visibility: state.value.length > 6 ? "hidden" : "visible"}}>Y</span>
              <span style={{visibility: state.value.length > 7 ? "hidden" : "visible"}}>Y</span>
              <span style={{visibility: state.value.length > 8 ? "hidden" : "visible"}}>Y</span>
              <span style={{visibility: state.value.length > 9 ? "hidden" : "visible"}}>Y</span>
            </div>
          )}
          <div
              className={`fl-minput-label ${
                  String(state.value).trim() !== ""
                      ? "fl-minput-label-active"
                      : ""
              }`}
          >
            {props.label}
          </div>
          <div
            className="fl-datepicker-calendar-button"
            onClick={async () =>
              await modalData.push(() => <DatePickerModal {...onSaveProps} />)
            }
            style={{ right: `${props.inputTypeSwitchedDate && "30px"}` }}
          >
            <FontAwesomeIcon
              className="fl-datepicker-calendar-button-icon"
              icon={faCalendarAlt}
            />
          </div>

          {props.inputTypeSwitchedDate && (
            <div
              className="fl-datepicker-calendar-button"
              onClick={() =>
                props.inputTypeSwitchDate(!props.inputTypeSwitchedDate)
              }
            >
              <FontAwesomeIcon
                className="fl-datepicker-calendar-button-icon"
                icon={faUndo}
                style={{ color: "black" }}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
