import React, { FC } from 'react';
import { useUserContext } from '../context/UserContext';
import "./Banner.css";
import { Button } from './Button';

// ASSETS
import bannerImageSourceOne from '../assets/images/1.png';
import bannerImageSourceTwo from '../assets/images/2.png';

interface BannerProps {
    
}

export const Banner:FC<BannerProps> = () => {
    
    // CONTEXT
    const userData = useUserContext();

    return (
        <div className="fl-banner-main">
            <div className="fl-banner-background">
                <div className="fl-banner-background-circle-orange"></div>
                <div className="fl-banner-background-circle-pink"></div>
            </div>
            <div className="fl-container fl-banner-inner">
                <div className="fl-col-5 fl-banner-col-content">
                    <div className={`fl-banner-heading fl-banner-heading-${userData.currentTheme}`}>
                        A new place for Content Creators. Join our exclusive platform.
                    </div>
                    <div className={`fl-banner-description fl-banner-description-${userData.currentTheme}`}>
                        Bring your followers over to a safe space that you own and control. Generate income by selling membership subscriptions and exclusive content, and you won’t need to worry about social media restrictions.
                    </div>
                    <Button style={{display:"inline-flex"}} to="/register" type="normal">Get Started</Button>
                </div>
                <div className="fl-col-7 fl-banner-col-img">
                    <div className="fl-banner-images">
                        <img className="fl-banner-image-first" src={bannerImageSourceOne} alt="Banner 1"/>
                        <img className="fl-banner-image-second" src={bannerImageSourceTwo} alt="Banner 2"/>
                    </div>
                </div>
            </div>
        </div>
    )
}