import React, {FC} from 'react'
import {useParams} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {SimpleCard} from "../../components/SimpleCard";
import {useUserContext} from "../../context/UserContext";
import {useHistory} from "react-router-dom";
import {FollowingPage} from "./FollowingPage";
import {FollowersPage} from "./FollowersPage";


export const FollowPage:FC<({} )> = (props) => {

    const { location } = useParams<{ location: string }>();
    const userData = useUserContext()
    const history = useHistory()

    const changePage = (page:string) => {
        history.push(`/follow/${page}`)
    }

    return (
        <div
            className={`fl-drive-main fl-settings-main fl-settings-main-${userData.currentTheme} fl-notifications-drive-${userData.currentTheme}`}
        >
            <div className="fl-container">
                <div className="fl-d-flex fl-feed-row">
                    <div className="fl-col-3 fl-feed-column fl-following-page">
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option ${location === "followers" && "fl-drive-menu-active" }`} onClick={() => changePage("followers")}>
                                <span>
                                    Followers ({userData.user.profile.meta.followers})
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                        <SimpleCard className={`fl-settings-link-box fl-drive-menu-option ${location === "following" && "fl-drive-menu-active" }`} onClick={() => changePage("following")}>
                                <span>
                                    Following ({userData.user.profile.meta.following})
                                </span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </SimpleCard>
                    </div>
                    <div className="fl-col-9 fl-feed-column fl-drive-tablet-show">
                        <SimpleCard>
                            {location === "following" ?
                                <FollowingPage /> : <FollowersPage />
                            }
                        </SimpleCard>
                    </div>
                </div>
            </div>
        </div>
    )
}