import React, {FC, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import { useUserContext } from "../context/UserContext";
import validator from "validator";
import "./Login.css";

// ASSETS
import imageSourceLight from "../assets/images/7.jpg";
import imageSourceDark from "../assets/images/8.jpg";
import authenticationDark from "../assets/images/authentication_dark.jpg"
import authenticationLight from "../assets/images/authentication_light.jpg"
import Axios from "axios";
import { useEnvContext } from "../context/EnvContext";
import { useModalContext } from "../context/ModalContext";
import {faArrowRight, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MInput } from "../components/MInput";
import axios from "axios";
import { TwitterIcon } from "react-line-awesome";
import ReactLoading from "react-loading";

export const Login: FC<any> = (props) => {
  // STATE
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [stateAuthentification , setStateAuthentification] = useState({
    type: "regular",
    access_token:""
  })

  const [stateCode , setStateCode] = useState({
    code: ""
  })

  const [proccessing, setProccessing] = useState({login: false, twitter: false});

  // HISTORY
  const history = useHistory();

  // CONTEXT
  const userData = useUserContext();
  const envData = useEnvContext();
  const modalData = useModalContext();

  const [forceErrors, setForceErrors] = useState(false);
  const [errorLogin, setErrorLogin] = useState({
    error: ""
  });

  const processLogin = async () => {
    const errorsArray: Array<any> = [];
    setProccessing({...proccessing, login:true});
    if (
        Object.keys(state)
            //@ts-ignore
            .map((x: string) => state[x])
            .filter((x: string) => x.trim().length === 0).length
    )
      errorsArray.push("Please fill in all fields.");

    if (
        !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
            state.email
        )
    )
      errorsArray.push("Not a valid email format.");

    if (
        !validator.isStrongPassword(state.password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 0,
        })
    )
      errorsArray.push("Not a valid password format.");

    if (/.con$/.test(state.email))
      errorsArray.push("Not a valid email format.");

    setForceErrors(false);
    await Axios.post(`${envData.apiUrl}/auth/login`, state).then( async (res) => {
      setStateAuthentification({...stateAuthentification, type: res.data.auth_type,access_token:res.data.access_token})
      const { access_token } = res.data;
      userData.setToken(access_token);
      await userData.checkAuth();
      const { restored } = res.data;
      if (restored) {
        modalData.pushToast("reactivated", "Your Fluffa Account has been reactivated. Welcome back!");
      }
      if(res.data.auth_type === "regular") {
        if(localStorage.getItem("redirectAfterLogin") === null) {
          if(localStorage.getItem("explore") === "explore") {
            history.push("/explore/all")
          } else if(localStorage.getItem("profile") === "profile") {
              history.goBack()
          }  else {
            history.push("/feed")
          }
        } else {
          const redirectTo = localStorage.getItem("redirectAfterLogin");
          localStorage.removeItem("redirectAfterLogin");
          if (redirectTo) {
            history.push(redirectTo);
          } else {
            history.push("/feed")
          }
        }
      }
    }).catch((err) => {
      setState({...state,password: ""})
      if(err?.response?.data?.errors?.account?.length > 0) {
        history.push('/account-disabled')
      } else if (err?.response?.data?.message === "IP address banned. Too many login attempts.") {
        setErrorLogin({...errorLogin, error: "Too many login attempts. Try again in 1 hour."})
      } else {
        errorsArray.push(err.response.data.errors);
        setForceErrors(true);
        if(errorsArray.some((element) => {return (typeof element.email !== 'undefined' && element.email.includes("The selected email is invalid."))})) {
          setErrorLogin({...errorLogin, error: "Couldn't find your Fluffa Account."});
        } else if (
            !errorsArray.includes("Please fill in all fields.") &&
            !errorsArray.includes("Not a valid email format.")
        ) {
          setErrorLogin({...errorLogin, error: "Wrong password. Try again or click ‘Forgot password’ to reset it."})
        } else {
          setErrorLogin({...errorLogin, error: ""})
        }
      }
    });
    setProccessing({login: false, twitter: false});
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      processLogin();
    }
  };

  const handleKeyPressCode = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      confirmCode();
    }
  };

  const [codeError, setCodeError] = useState<{ error:boolean }>({
    error: false
  });
  const [validCodeError, setValidCodeError] = useState<{ error:boolean }>({
    error: false
  });

  const [codeAnimation,setCodeAnimation] = useState(false)
  const confirmCode = async () => {
    setCodeAnimation(true)
    if (stateCode.code === "") {
      setCodeAnimation(false)
      setCodeError({...codeError, error: true})
      setValidCodeError({...validCodeError, error: false})
    } else {
      setCodeError({...codeError, error: false})
      try {
        userData.setToken(stateAuthentification.access_token);
        await Axios.post(`${envData.apiUrl}/auth/2fa/validate`, {
          "totp": stateCode.code,
          "access_token" : stateAuthentification.access_token
        })
        await userData.checkAuth();
        if(localStorage.getItem("explore") === "explore") {
          history.push("/explore/all")
        } else {
          history.push("/feed")
        }
      } catch (e) {
        setValidCodeError({...validCodeError, error: true})
      }
      setCodeAnimation(false)
    }
  }

  const goBack = () => {
    setStateAuthentification({...stateAuthentification, type:"regular"})
  }

  return (
      <div className="fl-fullscreen-with-footer">
        <div className="fl-fullscreen-with-footer-inner fl-container">
          <div className="fl-fullscreen-with-footer-bg">
            <div className="fl-fullscreen-with-footer-circle-orange"></div>
            <div className="fl-fullscreen-with-footer-circle-pink"></div>
          </div>
          <div className="fl-fullscreen-with-footer-content">
            <div className="fl-col-6 fl-fullscreen-with-footer-content-part">
              {stateAuthentification.type === "regular" ? (
                  <>
                    <div
                        className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}
                    >
                      Log in to Fluffa
                    </div>
                    <div className={`fl-fullscreen-with-footer-content-form`}>
                      <MInput
                          className={`fl-settings-mt-30 ${errorLogin.error === "Couldn't find your Fluffa Account." && "fl-minput-main-not-valid" }`}
                          forceErrors={forceErrors}
                          validator={[
                            {
                              check: (v) => validator.isEmail(v),
                              message: "Must be in a valid email format",
                            },
                            {
                              check: (v) => !/.con$/.test(v),
                              message: "Not valid, did you mean .com or something?",
                            },
                          ]}
                          onKeyPress={handleKeyPress}
                          type="email"
                          label="Email"
                          value={state.email}
                          setValue={(email: string) => setState({ ...state, email })}
                          onFocus={() => errorLogin.error === "Couldn't find your Fluffa Account." && setErrorLogin({...errorLogin, error: ""})}
                      />
                      {errorLogin.error === "Couldn't find your Fluffa Account." && (
                          <span className="fl-modal-description-row-error">
                            <FontAwesomeIcon icon={faInfoCircle} /> {" "} {errorLogin.error}
                          </span>
                      )}
                      <MInput
                          className={`fl-settings-mt-30 ${errorLogin.error === "Wrong password. Try again or click ‘Forgot password’ to reset it." ? "fl-minput-main-not-valid" : ""}`}
                          forceErrors={forceErrors}
                          onKeyPress={handleKeyPress}
                          visiblepassword={true}
                          type="password"
                          label="Password"
                          value={state.password}
                          setValue={(password: string) =>
                              setState({ ...state, password })
                          }
                          onFocus={() => errorLogin.error === "Wrong password. Try again or click ‘Forgot password’ to reset it." && setErrorLogin({...errorLogin, error: ""})}
                      />
                      {errorLogin.error === "Wrong password. Try again or click ‘Forgot password’ to reset it." || errorLogin.error === "Too many login attempts. Try again in 1 hour." ? (
                          <span className="fl-modal-description-row-error">
                            <FontAwesomeIcon icon={faInfoCircle} /> {" "} {errorLogin.error}
                          </span>
                      ) : ""}
                    </div>
                    <Button
                        className="fl-fullscreen-with-footer-content-button"
                        type="normal"
                        onClick={processLogin}
                        disabled={proccessing.login || state.password.length === 0 || state.email.length === 0 ? true : false}
                    >
                      LOG IN
                      {proccessing.login ?
                          <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>
                          :
                          <FontAwesomeIcon
                              className="fl-fullscreen-with-footer-content-button-icon"
                              icon={faArrowRight}
                          />}
                    </Button>
                    <Link
                        className="fl-fullscreen-with-footer-content-link underline"
                        to="/forgot-password"
                    >
                      Forgot password?
                    </Link>
                    <div className="fl-fullscreen-with-footer-content-link-text">
                      Don’t have an account?{" "}
                      <Link
                          className="fl-fullscreen-with-footer-content-link underline"
                          to="/register"
                      >
                        Sign up
                      </Link>
                    </div>
                    <hr className='fl-login-button-break' />
                    <Button
                        className="fl-fullscreen-with-footer-content-button fl-twitter-button"
                        type="normal"
                        onClick={() => {setProccessing({...proccessing, twitter: true}); twitterLogin(setErrorLogin, errorLogin, envData)}}
                    >
                      <TwitterIcon style={{transform: 'scale(1.5)', marginRight: '5px'}}/>
                      LOG IN WITH TWITTER
                      {proccessing.twitter ?
                          <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles" />
                          :
                          <FontAwesomeIcon
                              className="fl-fullscreen-with-footer-content-button-icon"
                              icon={faArrowRight}
                          />}
                    </Button>
                  </>
              ) : stateAuthentification.type === "2fa_sms" || stateAuthentification.type === "2fa_google" ? (
                  <>
                    <div
                        className={`fl-fullscreen-with-footer-content-heading fl-fullscreen-with-footer-content-heading-${userData.currentTheme}`}
                    >
                      {stateAuthentification.type === "2fa_google" ? "Log in with authentication app" : "Log in with text message"}
                    </div>
                    <div className="fl-fullscreen-with-footer-content-link-text">
                      {stateAuthentification.type === "2fa_sms" ? (
                          "Check your phone with the number ending in 25 for a text message, and enter the authentication code below to log in to Fluffa."
                      ) : stateAuthentification.type === "2fa_google" ? (
                          "Use your app to get an authentication code, and enter it below to log in to Fluffa."
                      ) : ("") }
                    </div>
                    <MInput
                        type="text"
                        className={"fl-settings-mt-30"}
                        label="Enter authentication code"
                        onKeyPress={handleKeyPressCode}
                        value={stateCode.code}
                        setValue={(code) => setStateCode({code:/[0-9]/.test(code[code.length - 1])
                              ? code
                              : code.slice(0, -1)})}
                    />
                    {codeError.error  && (
                        <span className="fl-modal-description-row-error">
                         <FontAwesomeIcon icon={faInfoCircle} /> {" "} Enter an authentication code.
                        </span>
                    )}
                    {validCodeError.error  && (
                        <span className="fl-modal-description-row-error">
                         <FontAwesomeIcon icon={faInfoCircle} /> {" "} The code you entered is incorrect, please try again.
                        </span>
                    )}
                    <Button
                        className="fl-fullscreen-with-footer-content-button fl-settings-mt-30"
                        type="normal"
                        onClick={confirmCode}
                        disabled={stateCode.code.length === 6 && !codeAnimation ? false : true}
                    >
                      LOG IN
                      {codeAnimation ?
                          <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="fl-spinningBubbles"/>
                          :
                          <FontAwesomeIcon
                              className="fl-fullscreen-with-footer-content-button-icon"
                              icon={faArrowRight}
                          />
                      }
                    </Button>
                    <div className="fl-fullscreen-with-footer-content-link-text">
                      Need help? {" "}
                      <Link
                          className="fl-fullscreen-with-footer-content-link underline"
                          to="/contact"
                      >
                        Contact Fluffa Support
                      </Link>
                    </div>
                    <div className="fl-fullscreen-with-footer-content-link-text fl-text-underline"><p className="fl-fullscreen-with-footer-content-link" onClick={() => goBack()}>Cancel</p></div>
                  </>
              ) :  ("")}
            </div>
            <div className="fl-col-6 fl-fullscreen-with-footer-content-part fl-fulscreen-with-footer-content-part-image">
              {userData.currentTheme === "light" ? (
                  <img
                      className="fl-fullscreen-with-footer-content-image"
                      src={stateAuthentification.type !== "regular" ? authenticationLight : imageSourceLight}
                      alt="Login"
                  />
              ) : (
                  ""
              )}
              {userData.currentTheme === "dark" ? (
                  <img
                      className="fl-fullscreen-with-footer-content-image"
                      src={stateAuthentification.type !== "regular" ? authenticationDark : imageSourceDark}
                      alt="Login"
                  />
              ) : (
                  ""
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export const twitterLogin =  (setErrorLogin: any, errorLogin: any, envData: any) => {
  axios.get(`${envData.apiUrl}/auth/login/twitter/redirect`).then((result: any) => {
    window.location.replace(result.data.url);
  }).catch(() => {
    setErrorLogin({...errorLogin, error: "Failed to authenticate with twitter."})
  });
}
