import React, { FC, useState } from "react";
import "./SingleNotification.css";
import { useUserContext } from "../../context/UserContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEye,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { SimpleCard } from "../SimpleCard";

const Dropdown: FC<any> = (props) => {
  const [state, setState] = useState<boolean>(false);

  const userData = useUserContext();

  return (
    <div
      className="fl-post-head-actions"
      tabIndex={1}
      onBlur={() => setState(false)}
      onClick={(e: any) => e.stopPropagation()}
    >
      <FontAwesomeIcon
        className={`fl-post-head-actions-icon fl-singlenotification__ellipsish--${userData.currentTheme}`}
        icon={faEllipsisH}
        onClick={() => setState(!state)}
      />
      {state ? (
        <div
          className={`fl-post-head-actions-dropdown fl-post-head-actions-dropdown-${userData.currentTheme}`}
        >
          <SimpleCard className="fl-post-head-actions-dropdown-inner">
            <div
              className="fl-post-head-actions-dropdown-option"
              onClick={() => {props.readNotification(props.read_at ? "unread" : "read", props.id,props.read_before);setState(false)}}
            >
              <div className="fl-post-head-actions-dropdown-option-icon">
                {props.read_at ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </div>
              {props.read_at ? "Mark as unread" : "Mark as read"}
            </div>
          </SimpleCard>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dropdown;
